import Footer from '../Footer'
import NavigationHandler from '../NavigationHandler'
import PersonalDocsUpload from '../PersonalDocsUpload'
import * as Style from './styles'

export default function ConfirmationDocsUpload() {
  return (
    <>
      <NavigationHandler step={3} />

      <Style.Container>
        <PersonalDocsUpload />
      </Style.Container>

      <Footer />
    </>
  )
}
