import styled from 'styled-components'

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;

  padding: 1rem;
  background: #5b945b;
  color: white;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 210px;
  z-index: 500;

  img {
    max-width: 190px;
    max-height: 32px;
  }

  p {
    text-align: center;
    font-weight: 300;
  }
`
