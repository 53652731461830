import axios from 'axios'
import { Address } from './types'

const api = axios.create({
  baseURL: 'https://viacep.com.br/ws',
  headers: {
    'Content-Type': 'application/json'
  }
})

const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const getAddressByZipCode = async (zipCode: string): Promise<Address> => {
  const response = await api.get<Address>(`/${zipCode}/json/`)

  // await 1 second delay
  await delay(1000)

  if (response.data.erro) {
    throw new Error('Erro ao buscar endereço')
  }
  return response.data
}
