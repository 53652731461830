import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div<any>`
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  text-align: left;
  margin-bottom: 16px;

  .heading-component {
    margin-top: 0;
    margin-bottom: 16px;
  }
`

export const Content = styled.div`
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #3d3d3d;
  font-weight: 400;
  span {
    font-size: 14px;
    font-weight: 700;
  }

  @media screen and ${MOBILE_WIDTH} {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
`

export const Link = styled.a`
  font-family: 'Spartan', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${temporaryTheme.colors.link};
  margin-left: 4px;
  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }

  @media screen and ${MOBILE_WIDTH} {
    font-size: 12px;
  }
`
