export const FilterBadgeMock = [
  { type: 'cronica', link: 'category_id=103', id: '1' },
  { type: 'mental', link: 'category_id=104', id: '2' },
  { type: 'oncologia', link: 'category_id=105', id: '3' },
  { type: 'neurologia', link: 'category_id=106', id: '4' },
  { type: 'pediatria', link: 'category_id=107', id: '5' },
  { type: 'intimo', link: 'category_id=108', id: '6' },
  { type: 'pele', link: 'category_id=109', id: '7' },
  { type: 'esportiva', link: 'category_id=110', id: '8' },
  { type: 'odontologia', link: 'category_id=11', id: '9' }
]
