import { useLocation } from 'react-router'

export const usePublicRender = () => {
  const { pathname } = useLocation()
  const renderFooter = pathname.includes('wa')
  const isHigherThanMd = window.innerWidth > 768
  const isLowerEqualMd = window.innerWidth <= 768

  return {
    isPublic: renderFooter,
    isHigherThanMd,
    isLowerEqualMd
  }
}
