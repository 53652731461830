import { Link } from 'react-router-dom'
import {
  pngPessoa,
  cannectEduca,
  logoCannectCiencia,
  CannectProdutos,
  CannectCiencia,
  CannectAssistente,
  CannectGene,
  playBtn,
  cannectProfissionais
} from 'assets/img'
import Texts from 'components/Text'
import DescribedLink from 'components/CannectEducate/DescribedLink'
import { svgAssistente, svgProdutos, svgCuida, svgEduca, svgGene } from 'assets/svg'
import Button from 'components/Button'
import BreadCrumb from 'components/BreadCrumb'
import * as Style from './styles'

export default function PatientHome() {
  return (
    <Style.Container>
      {/* 
      <BreadCrumb paths={[{ label: 'Pacientes', link: '/area-paciente' }]} />
      <Style.MainContent>
        <Style.LinksWrapper>
          <DescribedLink
            url="/profissionais-parceiros-cannabis-medicinal"
            imgUrl={cannectProfissionais}
            imgAlt="Cannect Profissionais"
            description="Precisa de ajuda para prescrever cannabis medicinal? A cannecte te assiste."
            buttonDescription="Agende uma consulta"
          />
          <DescribedLink
            url="/produtos/1"
            imgUrl={svgProdutos}
            imgAlt="Cannect Produtos"
            description="Os melhores produtos com os melhores preços e a maior facilidade para chegar na casa do seu paciente."
          />
          <DescribedLink
            url="/cuida"
            imgUrl={svgCuida}
            imgAlt="Cannect Cuida"
            description="Vamos juntos com o paciente do começo ao fim, para um tratamento efetivo e de qualidade."
          />
        </Style.LinksWrapper>
        <Style.LinksWrapper>
          <img src={gene} alt="" />
          <DescribedLink
            url="/teste-genetico"
            imgUrl={svgGene}
            imgAlt="Cannect Gene"
            // descriptionImgUrl={svgGeneExemplo}
            descriptionImgAlt="Gene"
            description="O Sistema Endocanabinoide é unico para cada um de nós. Conhecer sua genética vai ajudar na escolha do tratamento mais adequado e efetivo para você.
            1º teste genético de Cannabis medicinal 100% brasileiro em todas as suas etapas."
          />
        </Style.LinksWrapper>
      </Style.MainContent> */}
      {/* <Footer /> */}
      {/* <Style.Video>
        <img src={playBtn} alt="" />
        <div>
          <Texts type="h2" bold color="white">
            Cannect
          </Texts>
          <Texts type="h2" bold color="tertiary">
            AREA MÉDICA
          </Texts>
          <Texts type="md" bold color="white">
            O Sistema Endocanabinoide é unico para cada um de nós. Conhecer sua genética vai ajudar na escolha do
            tratamento mais adequado e efetivo para você. 1º teste genético de Cannabis medicinal 100% brasileiro em
            todas as suas etapas
          </Texts>
          <Button outlined style={{ background: 'none', color: 'white', border: '1px solid white' }}>
            Assita ao vídeo
          </Button>
        </div>
      </Style.Video> */}
      <div className="headText">
        <Texts type="h2" color="tertiary" bold>
          Comece por aqui
        </Texts>
      </div>
      <div className="accordion">
        <div className="box a1">
          <div className="image_1">
            <img className="logo" src={cannectProfissionais} alt="Cannect Profissionais" />
            <div className="text">
              <p>
                Oferecemos cursos, podcasts, artigos e workshops para você conhecer sobre a cannabis medicinal e seus
                benefícios a seus pacientes
              </p>
              <Link to="/educa">
                <Button>Acesse</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="box a3">
          <div className="image_3">
            <img className="logo" src={CannectProdutos} alt="Cannect Produtos" />
            <div className="text">
              <p>
                Oferecemos cursos, podcasts, artigos e workshops para você conhecer sobre a cannabis medicinal e seus
                benefícios a seus pacientes
              </p>
              <Link to="/produtos/1">
                <Button>Acesse</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="box a4">
          <div className="image_4">
            <img className="logo" src={CannectAssistente} alt="Cannect Assistente" />
            <div className="text">
              <p>
                Oferecemos cursos, podcasts, artigos e workshops para você conhecer sobre a cannabis medicinal e seus
                benefícios a seus pacientes
              </p>
              <Link to="/assistente">
                <Button>Acesse</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="box a5">
          <div className="image_5">
            <img className="logo" src={CannectGene} alt="Cannect Gene" />
            <div className="text">
              <p>
                Oferecemos cursos, podcasts, artigos e workshops para você conhecer sobre a cannabis medicinal e seus
                benefícios a seus pacientes
              </p>
              <Link to="/teste-genetico">
                <Button>Acesse</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Style.Container>
  )
}
