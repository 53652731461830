import { useScheduling } from 'hooks/useScheduling'
import { useFormContext } from 'react-hook-form'
import { formatAndCapitalizeDate } from 'utils/formatBrazilianDate'
import * as Styled from '../styles'

interface AppointmentDetailsProps {
  showHeader?: boolean
}

function AppointmentDetails({ showHeader = true }: AppointmentDetailsProps) {
  const { watch } = useFormContext()
  const { appointmentFromB2C } = useScheduling()

  const selectedPrescribeWatch = watch('prescriber')
  const scheduling_date = watch('scheduling_date')
  const scheduling_time = watch('scheduling_time')
  const patient = watch('patient')

  const formattedDateTime = `${formatAndCapitalizeDate(scheduling_date)} às ${scheduling_time}`
  const patientName = appointmentFromB2C?.patient?.person?.name ?? patient?.name ?? '-'
  const prescriberName =
    appointmentFromB2C?.prescriber?.professional_name ?? selectedPrescribeWatch?.professional_name ?? '-'
  const prescriberCouncilNumber =
    appointmentFromB2C?.prescriber?.nr_council ?? selectedPrescribeWatch?.nr_council ?? '-'
  const prescriberCouncilType = appointmentFromB2C?.prescriber?.council ?? selectedPrescribeWatch?.council ?? '-'

  return (
    <Styled.Box style={{ gridArea: 'details' }}>
      {showHeader && <Styled.BoxTitle>Detalhes do agendamento de consulta</Styled.BoxTitle>}
      <>
        <Styled.TextDetail>
          <p>
            Nome do paciente: <span>{patientName}</span>
          </p>
        </Styled.TextDetail>
        <Styled.TextDetail flexDirection="column">
          <Styled.TextDetail>
            <p>
              Profissional da saúde: <span>{prescriberName}</span>
            </p>
          </Styled.TextDetail>
          <Styled.TextDetail>
            <p>{prescriberCouncilType}:</p> <span>{prescriberCouncilNumber}</span>
          </Styled.TextDetail>
        </Styled.TextDetail>
        <Styled.TextDetail>
          <p>Data:</p>
          <span>{formattedDateTime}</span>
        </Styled.TextDetail>
        <Styled.TextDetail>
          <p>Tipo de consulta:</p>
          <span>Consulta Online</span>
        </Styled.TextDetail>
      </>
    </Styled.Box>
  )
}

export default AppointmentDetails
