import { Link } from 'react-router-dom'
import PreviewRecipe from './PreviewRecipe'
import * as Style from './styles'
import SectionsWrapper from '../../SectionsWrapper'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../../../utils/constants'

export default function PrescriptionInfo() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <SectionsWrapper>
      <Style.SectionContainer isMobile={isMobile}>
        <Style.Title>Informações do Profissional Prescritor:</Style.Title>
        <Style.Texts>
          <p>
            Não se preocupe, <b>você só precisa preencher estes dados uma vez</b> para configurar sua prescrição médica,
            por isso, utilize seus dados comerciais ou de prescritor.
          </p>
          <p style={{ marginTop: '16px', marginBottom: '35px' }}>
            Caso precise alterar esses dados no futuro, acesse suas configurações de prescrição em{' '}
            <Link
              to="/conta?pagina=perfil-config-prescricoes"
              style={{ color: '#4380E6', textDecoration: 'underline' }}
            >
              seu painel do usuário.
            </Link>
          </p>
        </Style.Texts>
        <PreviewRecipe />
      </Style.SectionContainer>
    </SectionsWrapper>
  )
}
