import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FiUploadCloud } from 'react-icons/fi'
import { Input } from 'design-cannect'
import { BsCheckLg } from 'react-icons/bs'
import api from 'services/api'
import { getUserUuid } from 'hooks/useSessionUser'
import { Container } from './styles'
import Text from '../Text'

interface InputUpdateProps {
  disabled?: boolean
  title?: string
  onChangeDate?: (value: string) => void
  fullWidth?: boolean
  type?: string
}

function InputUpdate({ disabled, title, onChangeDate, fullWidth, type }: InputUpdateProps) {
  const uuid = getUserUuid()

  const [date, setDate] = useState('')
  const [files, setFiles] = useState([])
  const [error, setError] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/png': [], 'image/jpeg': [] },
    onDrop: (acceptedFiles: any, fileRejections) => {
      setError(fileRejections.length > 0)
      setFiles(
        // eslint-disable-next-line array-callback-return
        acceptedFiles.map((file: any) => {
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        })
      )
      uploadFiles(acceptedFiles)
    }
  })

  const uploadFiles = async (acceptFiles: string | any[]) => {
    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < acceptFiles.length; i++) {
      const file = acceptFiles[i]
      formData.append('file', file)
    }

    await api.post(`my_account/${uuid}/documents?type=${type}&emission=18%2F11%2F2022`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const onDrop = useCallback(() => {
    // Do something with the files
  }, [])

  return (
    <Container disabled={disabled} fullWidth={fullWidth}>
      {title && (
        <div className="header">
          <Text type="md" bold color="tertiary">
            {title}
          </Text>
        </div>
      )}
      <div>
        <div className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!error ? (
              files.length === 0 ? (
                <>
                  <input {...getInputProps()} />
                  <button type="button" className="action">
                    <FiUploadCloud />
                    Adicione ou arraste o documento aqui
                  </button>
                </>
              ) : (
                <div className="error">
                  <BsCheckLg />
                  <Text type="md" bold color="tertiary">
                    Arquivo enviado com sucesso. Deseja excluir e enviar um novo?
                  </Text>
                  <Text type="md" color="secondary">
                    Excluir documento
                  </Text>
                </div>
              )
            ) : (
              <div className="error">
                <Text type="md" bold color="secondary">
                  Formato de Arquivo inválido
                </Text>
                <Text type="md">* tipo de aquivos validos png ou jpge</Text>
              </div>
            )}
          </div>
        </div>
      </div>
      {onChangeDate && (
        <div className="emissao">
          <Text type="md" bold>
            Emissão
          </Text>
          <div className="input-emissao">
            <Input type="date" value={date} onChange={(e: any) => setDate(e.target.value)} disabled={disabled} />
          </div>
        </div>
      )}
    </Container>
  )
}

export default InputUpdate
