import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  gap: 2rem;
  margin-bottom: 2rem;
`

export const ContainerLeft = styled.div``
export const ContainerRight = styled.div`
  overflow-x: scroll;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 36px;
  color: #3d3d3d;
`

export const Content = styled.div``

export const UploadContainer = styled.div<any>`
  display: flex;
  background: #f5f7fb;
  border-radius: 6.3466px;
  align-items: center;
  width: ${props => (props.isMobile ? '100%' : '522px')};
  height: 156.13px;
  padding: 1.5rem 0rem 1.5rem 1.4rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  cursor: pointer;
  border: ${({ hasError }) => (!hasError ? 'none' : '2px solid #e24444')};
`

export const ButtonUpload = styled.button`
  border: none;
  background-color: transparent;
  height: 100%;
`

export const ContentUpload = styled.div<any>`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;

  h3 {
    font-weight: 400;
    font-size: ${props => (props.isMobile ? '14px' : '15px')};
    color: #3d3d3d;
  }

  h4 {
    margin-top: 1rem;
    font-weight: 300;
    font-size: ${props => (props.isMobile ? '14px' : '15px')};
    color: #3d3d3d;
  }
  span {
    margin-top: 1rem;
    font-weight: 200;
    font-size: 13px;
    color: #3d3d3d;
  }
`

export const SelectLogoPosition = styled.select`
  width: 45%;
  height: 45px;
  margin-top: 1rem;
  background: #ffffff;
  border: 2.25331px solid #d9d9d9;
  border-radius: 5.57017px;
  padding-left: 1rem;
  color: #3d3d3d;
  font-weight: 400;
`

export const LogoWrapper = styled.div`
  display: flex;
  gap: 1rem;

  margin-bottom: 4rem;

  p {
    color: #e24444;
    margin-top: 1rem;
  }
`

interface LogoContainerProps {
  hasError: boolean
}
export const LogoContainer = styled.div<LogoContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 180px;
  width: 200px;

  border: 2px solid ${({ hasError }) => (!hasError ? '#d9d9d9' : '#e24444')};
  color: ${({ hasError }) => (!hasError ? 'black' : '#e24444')};
  border-radius: 5px;
  overflow: hidden;

  img {
    object-fit: cover;
    max-height: 180px;
    max-width: 200px;
  }
`

export const ContainerButtons = styled.div`
  margin: 42px 0 24px 0;
  display: flex;
  justify-content: end;
  gap: 15px;
  width: 100%;

  ${media.mobileWithTablet`
    flex-direction: column-reverse;
    button {
      width: 100%;
    }
  `}
`
export const CancelButton = styled.button`
  background: #8f8f8f;
  width: 138.79px;
  height: 40px;
  border-radius: 8px;
  border: none;
  color: white;
`
export const SaveButton = styled.button`
  background: #21976e;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  border: none;
  color: white;
`
