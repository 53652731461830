import styled from 'styled-components'
import { theme } from 'design-cannect'

interface Props {
  isDesktop?: boolean
  isMobile?: boolean
}

export const Article = styled.article<any>`
  /* margin-left: ${({ isDesktop }) => (isDesktop ? '64px' : '0')}; */
  width: 100%;
  header {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }
`
export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isDesktop }) => (isDesktop ? 'center' : 'flex-start')};
  div {
    background-color: ${theme.colors.gray};
    padding: 1.5rem;
    width: 100%;
    margin: 1.3rem 0;
  }
  table {
    width: 100%;
    margin: 50px 0;
  }
  .tg thead tr {
    background: #ecece2;
    padding: 2px 6px;
    min-width: 300px;
    .tg-0lax,
    .tg-0pky {
      color: ${theme.colors.black};
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      border: none;
      height: 64px;
      vertical-align: middle;
    }
  }
  .tg tbody tr {
    .tg-baqh {
      color: ${theme.colors.black};
      font-weight: 300;
      border: none;
      text-align: left;
      vertical-align: middle;
      height: 64px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  Button {
    margin-top: 25px;
  }
  .tg {
    border-collapse: collapse;
    border-spacing: 0;
  }
  .tg td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
  }
  .tg th {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 10px 15px 10px 5px;
    word-break: normal;
  }
  .tg .tg-baqh {
    text-align: center;
    vertical-align: top;
  }
  .tg .tg-c3ow {
    border-color: inherit;
    text-align: center;
    vertical-align: top;
  }
  .tg .tg-0pky {
    border-color: inherit;
    text-align: left;
    vertical-align: top;
  }
  .tg .tg-0lax {
    text-align: left;
    vertical-align: top;
  }
`

export const Body = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 2px solid ${theme.colors.pathway};
`

export const CardContainer = styled.div`
  width: 100%;
`
export const Card = styled.div<any>`
  max-width: ${({ isDesktop }) => (isDesktop ? '297px' : '100%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem;
  border: 1px solid ${theme.colors.gray};
  border-radius: 8px;
  margin: 15px;
  text-align: center;
  @media (max-width: 800px) {
    width: ${({ isDesktop }) => (isDesktop ? '297px' : '100%')};
  }
`
export const CardWrapp = styled.div<any>`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  @media (max-width: 800px) {
    flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  }
`
export const Table = styled.div<any>`
  width: 100%;
  overflow-x: ${({ isDesktop }) => (isDesktop ? 'none' : 'scroll')};
`
export const BtnArticle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    a {
      text-decoration: none;
      color: ${theme.colors.white};
    }
    margin: 1.3rem 0;
  }
`
export const CardPathologySynopsis = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;

  border: 3px solid ${theme.colors.pathway};
`
