import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Card = styled.div`
  width: 100%;
  height: auto;
  padding: 36px 32px 28px 32px;
  margin-bottom: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f7fc;
  border-radius: 20px;
  flex-direction: column;
  .weight-600 {
    font-weight: 600;
  }
  .weight-700 {
    font-weight: 700;
  }

  @media screen and (max-width: 900px) {
    padding: 15px;
  }
`

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const PrescriberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 18px;
  margin-right: 12px;

  @media screen and (max-width: 900px) {
    margin-right: 0px;
    gap: 0px;
    p {
      margin: 6px 0;
    }
  }
`

export const ConsultationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  span {
    font-family: 'Poppins', sans-serif;
    color: #433F3E;
    font-size: 24px;
  }
`

export const NameRow = styled.div`
  display: flex;
  align-items: center;
`

export const Photo = styled.img`
  width: 92px;
  height: 92px;
  border-radius: 50%;
  object-fit: cover;

  @media screen and (max-width: 900px) {
    width: 48px;
    height: 48px;
  }
`

export const Text = styled.p<{ fontWeight?: string; marginBottom?: string; color?: string;}>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  color: ${({color}) => color || '#898483'};
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  @media screen and (max-width: 900px) {
    font-size: 13px;
  }
`

export const PhotoRow = styled.div`
  display: flex;
  align-self: center;
  margin-right: 26px;
  @media screen and (max-width: 900px) {
    align-self: flex-start;
    margin-right: 12px;
  }
`

export const Name = styled.h2`
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  color: ${temporaryTheme.colors.link};
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`

export const Separator = styled.span`
  display: inline-block;
  width: 9px;
  height: 9px;
  background-color: #898483;
  border-radius: 50%;
  @media screen and (max-width: 900px) {
    width: 4px;
    height: 4px;
  }
`

export const Expandable = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  cursor: pointer;

  p {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #3d3d3d;
    margin: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;

    height: 1px;
    opacity: 0.4;
    background-image: linear-gradient(to right, transparent, #3d3d3d, transparent);
    background-repeat: repeat-x;
    background-size: 8px 1px;
    background-position: 0 0;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    opacity: 0.4;
    background-image: linear-gradient(to right, transparent, #3d3d3d, transparent);
    background-repeat: repeat-x;
    background-size: 8px 1px;
    background-position: 0 0;
  }
`

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1198px;
  width: 100%;
  margin-top: 32px;

  @media screen and (max-width: 900px) {
    margin: 12px;
  }
`

export const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;

  p {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
  }
  .return-text {
    color: ${temporaryTheme.colors.link};
  }
`

export const PathologyContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @media screen and (max-width: 900px) {
    gap: 6px;
  }
`
export const PathologyTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-height: 28px;
  max-height: 52px;
  padding: 3px 16px;
  background-color: transparent;
  border: 1px solid ${temporaryTheme.colors.link};
  border-radius: 16px;
  font-family: 'Spartan', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #3d3d3d;
`
