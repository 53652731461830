/* eslint-disable @typescript-eslint/ban-ts-comment */
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { defaultConfig } from 'tailwind-variants'
import tailwindConfig from '../../tailwind.config.js'

export const twMergeConfig = {
  classGroups: {
    // @ts-ignore
    'font-size': [{ text: Object.keys(tailwindConfig.theme.fontSize) }],
    // @ts-ignore
    'font-family': [{ font: Object.keys(tailwindConfig.theme.fontFamily) }]
  }
}

defaultConfig.twMergeConfig = twMergeConfig

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
type VariantProps<T> = {
  [key: string]: T
}

export const getTailwindVariantsOptions = <T extends object>(variants: VariantProps<T>, key: keyof VariantProps<T>) => {
  return Object.keys(variants[key])
}
