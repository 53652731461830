/* eslint-disable jsx-a11y/label-has-associated-control */
import { WhatsAppIcon } from 'assets/svg'
import { formatZipCode } from 'utils/formatters'
import { OrderAddress, OrderPayment } from 'pages/Account/components/pages/MyOrders/types'
import * as Styles from './styles'

interface OtherSummaryInfoProps {
  payment?: OrderPayment
  address?: OrderAddress
}

const PAYMENT_METHOD_TRANSLATED_LABELS = {
  credit_card: 'Cartão de crédito',
  pix: 'Pix'
}

export default function OtherSummaryInfo({ payment, address }: OtherSummaryInfoProps) {
  return (
    <Styles.OtherSummaryInfoContainer>
      <Styles.OtherSummaryInfoContent>
        <label>Método de pagamento:</label>
        <span>{PAYMENT_METHOD_TRANSLATED_LABELS[payment?.method ?? 'credit_card']}</span>
        <span>{payment?.instalments}x</span>
      </Styles.OtherSummaryInfoContent>

      <Styles.OtherSummaryInfoContent>
        <label>Endereço de entrega:</label>
        <span>
          {address?.street}, {address?.address_number}
        </span>
        <span>
          {address?.city}, {address?.state}, {formatZipCode(address?.cep ?? '')}
        </span>
      </Styles.OtherSummaryInfoContent>

      <Styles.OtherSummaryInfoContent>
        <label>Alguma dúvida? Fale conosco:</label>
        <Styles.TalkToCannecterContainer href="https://whts.co/Cannect" target="_blank">
          <WhatsAppIcon />
          Falar com atendente
        </Styles.TalkToCannecterContainer>
      </Styles.OtherSummaryInfoContent>
    </Styles.OtherSummaryInfoContainer>
  )
}
