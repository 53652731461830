import { theme } from 'design-cannect'
import styled from 'styled-components'

export const ConfirmationDialogueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  text-align: center;

  > h1 {
    color: ${theme.colors.secondary};
    font-size: 1.5rem;
  }

  > p {
    color: ${theme.colors.tertiary};
    font-size: 1rem;
    font-weight: 300;
  }

  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
`
