import { treatments01, treatments02 } from 'assets/img'
import BreadCrumb from 'components/BreadCrumb'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import useMediaQuery from 'hooks/useMediaQuery'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import BadgeTreatmentType from 'new-components/TreatmentsComponents/BadgeTreatmentType'
import { useState } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import {
  BadgesMedicalTreatments,
  BadgesTreatmentsWellBeing,
  TextStartTreatment,
  TextTreatmentSection,
  TextWellBeingSection
} from './mockTreatment'
import * as Styles from './styles'
import { useHistory } from 'react-router'

function Tratamentos() {
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Styles.Wrapper>
      <BreadCrumb paths={[{ label: 'Tratamentos', link: `/tratamentos` }]} />

      <Styles.SectionMedicalTreatments>
        <Heading elementType="h2">
          <span>Tratamentos</span> médicos
        </Heading>
        <TextDanger text={TextTreatmentSection} />

        <Styles.ListIcons>
          {BadgesMedicalTreatments.map(item => (
            <BadgeTreatmentType icon={item.icon} title={item.title} slug={item.slug} key={item.id} />
          ))}
        </Styles.ListIcons>
      </Styles.SectionMedicalTreatments>

      <Styles.SectionWellBeign>
        <Heading elementType="h2">
          <span>Saúde</span> e bem-estar
        </Heading>
        <TextDanger text={TextWellBeingSection} />

        <Styles.ListIcons>
          {BadgesTreatmentsWellBeing.map(item => (
            <BadgeTreatmentType icon={item.icon} title={item.title} slug={item.slug} key={item.id} />
          ))}
        </Styles.ListIcons>
      </Styles.SectionWellBeign>

      <Styles.SectionStartTreatment>
        <Styles.StartTreatmentImageContent>
          <Styles.StartTreatmentImage01 image={treatments01} role="img" aria-label="cannect desk image " />
          <Styles.StartTreatmentImage02 image={treatments02} role="img" aria-label="cannect desk image " />
        </Styles.StartTreatmentImageContent>

        <Styles.StartTreatmentTextContent>
          <Heading elementType="h2" fontWeight={400}>
            Inicie <strong>seu tratamento</strong> <span>com cannabis medicinal </span>
          </Heading>
          <TextDanger text={TextStartTreatment} />

          <Styles.StartTreatmentActionContent>
            <Button background="black" onClick={() => history.push('/agendamento')}>
              Agende sua consulta
            </Button>
            <Button isOutlined background="green" onClick={() => setModal(true)}>
              Inicie seu tratamento
            </Button>
          </Styles.StartTreatmentActionContent>
        </Styles.StartTreatmentTextContent>
      </Styles.SectionStartTreatment>

      <ModalHome isOpen={modal} setOpenModal={setModal}>
        <BeginCheckout />
      </ModalHome>
    </Styles.Wrapper>
  )
}

export default Tratamentos
