import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  justify-content: ${props => (props.isMobile ? 'center' : 'space-between')};
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

export const Card = styled.div<any>`
  border: 1px solid #ecece2;
  border-radius: 15px;
  /* margin: 20px; */
  height: 30rem;
  width: 27rem;
  padding: 1.7rem;
  margin: ${props => (props.isMobile ? '10px' : '0')};
  h1 {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }

  p {
    line-height: 1.6rem;
    font-size: 1.1rem;
    font-weight: 300;
  }
`
