import React from 'react'

export default function IconePessoa({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M23.9813 14C26.8808 14 29.2313 11.6495 29.2313 8.75C29.2313 5.85051 26.8808 3.5 23.9813 3.5C21.0818 3.5 18.7313 5.85051 18.7313 8.75C18.7313 11.6495 21.0818 14 23.9813 14Z"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8563 25.7562L35.3813 30.8187C35.8126 31.1778 36.3626 31.3627 36.9233 31.3371C37.484 31.3115 38.0148 31.0773 38.4117 30.6804C38.8086 30.2836 39.0428 29.7527 39.0684 29.192C39.0939 28.6314 38.9091 28.0814 38.55 27.65L31.125 18.1063C30.779 17.7567 30.3673 17.479 29.9136 17.2892C29.4599 17.0993 28.9731 17.0011 28.4813 17H19.4813C18.483 17.0053 17.5269 17.4026 16.8188 18.1063L9.39376 27.65C9.00715 28.0791 8.80039 28.6405 8.81628 29.2179C8.83218 29.7953 9.06951 30.3444 9.47914 30.7517C9.88877 31.1589 10.4393 31.393 11.0168 31.4055C11.5943 31.418 12.1544 31.2079 12.5813 30.8187L19.1063 25.7562L13.8938 42.1813C13.7682 42.4496 13.6967 42.7401 13.6834 43.0361C13.6701 43.3322 13.7152 43.6279 13.8162 43.9065C13.9172 44.1851 14.072 44.441 14.2719 44.6597C14.4718 44.8785 14.7129 45.0557 14.9813 45.1813C15.2496 45.3068 15.5401 45.3783 15.8362 45.3916C16.1322 45.4049 16.4279 45.3598 16.7065 45.2588C16.9851 45.1578 17.241 45.003 17.4597 44.8031C17.6785 44.6032 17.8557 44.3621 17.9813 44.0938L23.9813 34.6438L29.9813 44.0938C30.0985 44.3739 30.2711 44.6273 30.4889 44.8389C30.7067 45.0505 30.965 45.2159 31.2484 45.325C31.5317 45.4341 31.8342 45.4847 32.1377 45.4738C32.4411 45.4629 32.7392 45.3907 33.014 45.2615C33.2888 45.1324 33.5346 44.9489 33.7366 44.7222C33.9387 44.4955 34.0927 44.2303 34.1895 43.9425C34.2863 43.6547 34.3238 43.3503 34.2999 43.0476C34.2759 42.7449 34.1909 42.4502 34.05 42.1813L28.8563 25.7562Z"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
