import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const ShareWaysWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${media.mobileWithTablet`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 27px;
  `}
`
export const Button = styled.button`
  background-color: ${temporaryTheme.colors.sitePrimary};
  border: 1.40868px solid #21976e;
  border-radius: 8.7279px;
  color: white !important;
  padding: 10px 1rem;

  ${media.mobileWithTablet`
    width: 100%;
  `}
`
