import { Product } from '@cannect/hooks/useCannectAssistant/types'
import { FetchLatestRecipeWithPatientResponse, Person } from '../../services'

interface PersonDetails extends Person {
  age: number
}

export const formatPatientPersonalData = (data: Person): PersonDetails => {
  const formattedData = { ...data, age: 0 }

  if (data.birthday) {
    const today = new Date()
    const birthdayDate = new Date(data.birthday)
    birthdayDate.setFullYear(today.getFullYear())

    let age = 0
    if (today < birthdayDate) {
      age = today.getFullYear() - 1 - new Date(data.birthday).getFullYear()
    } else {
      age = today.getFullYear() - new Date(data.birthday).getFullYear()
    }

    const birthday = data.birthday.split('T')[0].split('-').reverse().join('/')

    formattedData.age = age
    formattedData.birthday = birthday
  }

  const cpf = data.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  const whatsapp = data.whatsapp?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  const phone = data.phone?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')

  return { ...formattedData, cpf, whatsapp, phone }
}

interface LatestRecipeWithAssistantProducts extends Omit<FetchLatestRecipeWithPatientResponse['recipe'], 'products'> {
  products: Product[]
}
export const formatLatestRecipe = ({
  recipe
}: FetchLatestRecipeWithPatientResponse): LatestRecipeWithAssistantProducts => {
  return {
    ...recipe,
    products: recipe.products.map(product => ({
      id: product.id,
      name: product.name,
      translated_name: product.translated_name,
      bottlesPerYear: product.required_bottles,
      howToUse: product.prescription,
      administrationWay: 'systemic',
      cannectChoice: false,
      costPerMg: 0,
      estimatedMonthlyCost: 0,
      stepsOfHowToUse: [],
      image: '',
      includedInPrescription: true,
      mgPerDay: '',
      posology: '',
      stock: 0,
      timesPerDay: '',
      unitaryPriceBrl: 0
    }))
  }
}
