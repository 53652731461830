import * as Style from './styles'

interface LinkButtonProps {
  img: string
  imgAlt: string
  label: string
  onClick: () => void
  observation?: string
  isImageBig?: boolean
}

export default function LinkButton({ img, label, imgAlt, onClick, isImageBig, observation }: LinkButtonProps) {
  return (
    <Style.LinkButtonContainer onClick={() => onClick()} isImageBig={isImageBig} observation={observation}>
      <img src={img} alt={imgAlt} />
      <p>{label}</p>
      {observation && <span>{observation}</span>}
    </Style.LinkButtonContainer>
  )
}
