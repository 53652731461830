import { theme } from 'design-cannect'
import { useEffect, useState } from 'react'
import * as Style from './styles'

interface ConnectorProps {
  active: boolean
  disabled: boolean
}

export default function Connector({ active, disabled }: ConnectorProps) {
  const [background, setBackground] = useState('#ecece2')

  useEffect(() => {
    if (active) {
      setBackground(`${theme.colors.secondary}`)
    } else if (!active) {
      setBackground(`${theme.colors.primary}`)
    }

    if (disabled) {
      setBackground(`#ecece2`)
    }
  }, [active, disabled])

  return <Style.ConnectorContainer style={{ background }} />
}
