import PrescriberInfo from 'components/PrescriberInfo'
import { PrescriberInfoTypes } from 'components/PrescriberInfo/PrescriberInfo'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MOBILE_WIDTH} {
    overflow: inherit;
  }
`

function PrescriberList({ prescribers }: { prescribers: PrescriberInfoTypes[] }) {
  return (
    <Container id="prescribers-list">
      {prescribers.map(prescriber => (
        <PrescriberInfo key={prescriber.id} {...prescriber} />
      ))}
    </Container>
  )
}

export default PrescriberList
