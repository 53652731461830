import styled from 'styled-components'

interface ContainerProps {
  isColumn?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  width: 70%;

  padding: 25px 10px 25px 10px;
`
export const Button = styled.button`
  border: none;
  background: none;
  text=decoration: underline;
  color: #f98e2c;
  font-weight: bold;
  font=size: 15px;
`
