import { SVGProps } from 'react'

export const ClipboardIcon = ({ width = '48', height = '48', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6665 28C18.6665 26.7113 19.7112 25.6667 20.9998 25.6667H34.9998C36.2885 25.6667 37.3332 26.7113 37.3332 28C37.3332 29.2887 36.2885 30.3333 34.9998 30.3333H20.9998C19.7112 30.3333 18.6665 29.2887 18.6665 28Z"
      fill="#748351"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6665 37.3333C18.6665 36.0447 19.7112 35 20.9998 35H27.9998C29.2885 35 30.3332 36.0447 30.3332 37.3333C30.3332 38.622 29.2885 39.6667 27.9998 39.6667H20.9998C19.7112 39.6667 18.6665 38.622 18.6665 37.3333Z"
      fill="#748351"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3335 14C15.0448 14 14.0002 15.0447 14.0002 16.3333V44.3333C14.0002 45.622 15.0448 46.6667 16.3335 46.6667H39.6668C40.9555 46.6667 42.0002 45.622 42.0002 44.3333V16.3333C42.0002 15.0447 40.9555 14 39.6668 14H35.0002C33.7115 14 32.6668 12.9553 32.6668 11.6667C32.6668 10.378 33.7115 9.33334 35.0002 9.33334H39.6668C43.5328 9.33334 46.6668 12.4673 46.6668 16.3333V44.3333C46.6668 48.1993 43.5328 51.3333 39.6668 51.3333H16.3335C12.4675 51.3333 9.3335 48.1993 9.3335 44.3333V16.3333C9.3335 12.4673 12.4675 9.33334 16.3335 9.33334H21.0002C22.2888 9.33334 23.3335 10.378 23.3335 11.6667C23.3335 12.9553 22.2888 14 21.0002 14H16.3335Z"
      fill="#748351"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6665 11.6667C18.6665 7.80067 21.8005 4.66666 25.6665 4.66666H30.3332C34.1992 4.66666 37.3332 7.80067 37.3332 11.6667V16.3333C37.3332 17.622 36.2885 18.6667 34.9998 18.6667H20.9998C19.7112 18.6667 18.6665 17.622 18.6665 16.3333V11.6667ZM25.6665 9.33333C24.3778 9.33333 23.3332 10.378 23.3332 11.6667V14H32.6665V11.6667C32.6665 10.378 31.6218 9.33333 30.3332 9.33333H25.6665Z"
      fill="#748351"
    />
  </svg>
)
