import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

interface IsItemChecked {
  isChecked: boolean
}

export const StatusRuleItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  min-height: 145px;
  height: 100%;

  @media ${MOBILE_WIDTH} {
    min-height: unset;
  }
`

export const CircleAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const StatusRuleItemCircle = styled.div<IsItemChecked>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.75rem;
  height: 2.75rem;
  min-height: 2.75rem;
  min-width: 2.75rem;

  background: ${({ isChecked }) => (isChecked ? '#21976e' : '#fff')};
  border-radius: 999px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

  svg {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
  }
`

interface StatusLineProps {
  isInvisible: boolean
}
export const StatusLine = styled.div<StatusLineProps>`
  width: 100%;
  border-top: 1px solid ${({ isInvisible }) => (isInvisible ? 'transparent' : '#858585')};
  margin-top: 1px;

  height: 0;
  min-height: 0;
  max-height: 0;
`

export const StatusRuleItemText = styled.p<IsItemChecked>`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ isChecked }) => (isChecked ? '#185D55' : '#858585')};

  padding: 0 0.75rem;
  word-wrap: break-word;
  text-align: center;
`
