import { ReactNode, ButtonHTMLAttributes, useEffect, useState } from 'react'
import { theme } from 'design-cannect'
import * as Style from './styles'

type DefaultButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
interface InvisibleButtonProps {
  children: ReactNode
  color?: 'primary' | 'secondary' | 'tertiary' | 'darkGrey' | 'white' | string
  alignSelf?: 'flex-start' | 'center' | 'flex-end'
}

export default function InvisibleButton({
  children,
  style,
  color,
  alignSelf,
  ...rest
}: InvisibleButtonProps & DefaultButtonProps) {
  const [componentColor, setComponentColor] = useState('')

  useEffect(() => {
    if (color) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setComponentColor(theme.colors[color] ?? color)
    }
  }, [color])

  return (
    <Style.InvisibleButtonContainer {...rest} style={{ ...style, color: componentColor, alignSelf }}>
      {children}
    </Style.InvisibleButtonContainer>
  )
}
