import { Tooltip } from 'antd'
import useMediaQuery from 'hooks/useMediaQuery'
import { useScheduling } from 'hooks/useScheduling'
import { useFormContext } from 'react-hook-form'
import { GiStethoscope } from 'react-icons/gi'
import { IoCalendarClearOutline } from 'react-icons/io5'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'
import { formatBrazilianDate } from 'utils/formatBrazilianDate'
import { NameRow, Text, TruncatedText } from '../../styles'
import { AppointmentInfoWrapper, CheckoutCard } from './styles'

export default function AppointmentResumeCard() {
  const { appointmentFromB2C } = useScheduling()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { watch } = useFormContext()

  const selectedPrescriber = watch('prescriber')

  const selectedDate = watch('scheduling_date')
  const selectedHour = watch('scheduling_time')

  const formattedDateTime = `${formatBrazilianDate(selectedDate)} às ${selectedHour}`
  const expertiseText = (selectedPrescriber?.prescriber_expertises || []).map((e: any) => e.name).join(' • ')

  const appointmentValue = appointmentFromB2C
    ? appointmentFromB2C?.amount_with_discount
    : selectedPrescriber?.online_appointment_value

  return (
    <CheckoutCard>
      <NameRow>
        <GiStethoscope />
        <div>
          <Text fontSize="20px" color={temporaryTheme.colors.link}>
            {selectedPrescriber?.professional_name}
          </Text>

          <Tooltip overlayInnerStyle={{ fontFamily: 'Poppins' }} placement="bottomLeft" title={expertiseText}>
            <TruncatedText>{expertiseText}</TruncatedText>
          </Tooltip>
        </div>
      </NameRow>
      <Text>
        <IoCalendarClearOutline size={isMobile ? 18 : 26} />
        {formattedDateTime}
      </Text>
      <AppointmentInfoWrapper>
        <Text>Consulta Online</Text>
        <Text fontWeight={700}>
          {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(appointmentValue))}
        </Text>
      </AppointmentInfoWrapper>
    </CheckoutCard>
  )
}
