import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { forEach } from 'lodash'
import * as queryString from 'querystring'
import { Button } from 'design-cannect'
import { useFilterProduct } from '../../../hooks/FilterProductContext'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { FILTER_NAME_PRODUCTS, MOBILE_WIDTH } from '../../../utils/constants'
import { transformFilterProductsUrl } from '../../../utils/formatedParamsUrl'
import AccordionFilter from '../../AccordionFilter'
import Checkbox from '../../Checkbox'
import Loading from '../../Loading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import * as Styles from './styles'

function NewFilter({ reset, setReset, clearFilters, validateClearFilters, pathFilter = '/produtos/1' }: any) {
  const { inputValue, setInputValue, isFetching, keyFilter, filter } = useFilterProduct()

  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState<any>(true)

  const validateFilterProductsUrl = () => {
    const listFilter = {} as any
    const keyParamsSearchUrl = transformFilterProductsUrl(location.search) as any
    forEach(keyFilter, key => {
      const newFilter = filter[key]
      listFilter[key] = newFilter.map((items: any) => {
        return {
          ...items,
          checked: keyParamsSearchUrl[key]?.includes(
            items?.id?.toString() || keyParamsSearchUrl[key] === items?.id?.toString()
          )
        }
      })
    })

    return listFilter
  }

  useEffect(() => {
    if (reset) {
      setLoading(true)
      setInputValue(validateFilterProductsUrl())
      setLoading(false)
      setReset(false)
    }
  }, [reset])
  useEffect(() => {
    if (!isFetching && keyFilter) {
      setLoading(true)
      setInputValue(validateFilterProductsUrl())
      setLoading(false)
    }
  }, [isFetching, keyFilter])

  const dataKeysFiltersValidate = (newInput: any) => {
    const inputValuesFilter = {} as any
    forEach(keyFilter, key => {
      const newFilter = newInput[key]
      const inputValuesFilterTransform = newFilter
        .filter((item: any) => {
          return item.checked
        })
        ?.map((items: any) => items?.id)
      if (inputValuesFilterTransform.length > 0) {
        inputValuesFilter[key] = inputValuesFilterTransform
      }
    })
    const search = queryString.stringify(inputValuesFilter)
    history.push({ pathname: pathFilter, search })
  }

  const handleChange = (nameKey: any, item: any) => {
    const newFilter = inputValue[nameKey]
    const newInputValue = newFilter?.map((input: any) => {
      if (input?.id === item?.id) {
        return { ...input, checked: !input.checked }
      }
      return input
    })
    const newInput = { ...inputValue, [nameKey]: newInputValue }
    setInputValue(newInput)
    dataKeysFiltersValidate(newInput)
  }

  const handleCheck = (nameKey: any) => {
    return (
      <div>
        {inputValue[nameKey]?.map((item: any) => {
          return (
            <Styles.CheckBoxContainer withDescription={!!item?.description} key={item.id}>
              <Checkbox
                className="checkbox-filter"
                checked={item?.checked}
                disabled={item?.disabled || false}
                label={
                  <span style={{ position: 'relative' }}>
                    {item?.name || 'name'}
                    {nameKey === 'supplier_id' && item?.name === 'Nutracêuticos' && (
                      <Styles.NewBadge>NOVO!</Styles.NewBadge>
                    )}
                  </span>
                }
                onClick={() => handleChange(nameKey, item)}
              />
              {nameKey === 'supplier_id' && (
                <Styles.TextBox>
                  <TextDanger text={item?.description} fontWeight={500} fontSize="12px" />
                </Styles.TextBox>
              )}
            </Styles.CheckBoxContainer>
          )
        })}
      </div>
    )
  }

  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <div style={{ paddingBottom: '10px' }}>
      {!(!isFetching && !loading) ? (
        <Loading loading />
      ) : (
        keyFilter?.map((nameKey: any, index: number) => {
          const defaultOpen = nameKey === 'supplier_id'
          if (nameKey === 'routeofadministrations' || nameKey === 'shapes') {
            return null
          }
          return (
            <AccordionFilter key={index} title={FILTER_NAME_PRODUCTS[nameKey]} defaultOpen={defaultOpen}>
              {handleCheck(nameKey)}
            </AccordionFilter>
          )
        })
      )}
      {isMobile && validateClearFilters && (
        <div style={{ paddingTop: '10px' }}>
          <Button outlined onClick={clearFilters}>
            Limpar Filtros
          </Button>
        </div>
      )}
    </div>
  )
}

export default NewFilter
