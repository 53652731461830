import api from 'services/api'
import { TAchievementCategory, THistoryListItem } from '../../MyPatients/types'
import { TResponseStandard } from 'types/common'

export interface FetchPatientHistoryParams {
  patientId: number
  page: number
  categories?: TAchievementCategory
  search?: string
}

export const fetchPatientHistory = async ({ patientId, page, categories, search }: FetchPatientHistoryParams) => {
  return api
    .get<TResponseStandard<THistoryListItem[], 'achievements'>>(`/prescriber/patients/${patientId}/achievements`, {
      params: { page, limit: 8, categories, search }
    })
    .then(({ data }) => data)
}

export interface Person {
  id: number
  birthday: string
  phone: string
  whatsapp: string
  name: string
  cpf: string
  email: string
  current_status: {
    name: string
    color: string
  }
}
export interface FetchPatientDetailsResponse {
  patient: Person
  success: boolean
}

export const fetchPatientPersonalData = async (patientId: number) => {
  return api.get<FetchPatientDetailsResponse>(`/prescriber/patients/${patientId}/details`).then(({ data }) => data)
}

export interface FetchLatestRecipeWithPatientResponse {
  recipe: {
    id: number
    token: string
    recommendation: string
    link_pdf: string
    patient: {
      id: number
      name: string
      cpf: string
      email: string
      phone: string
    }
    products: {
      id: number
      name: string
      translated_name: string
      details: string
      prescription: string
      required_bottles: number
    }[]
  }
  success: boolean
}

export const fetchLatestRecipeWithPatient = async (patientId: number) => {
  return api.get<FetchLatestRecipeWithPatientResponse>(`/recipe-by-patient/${patientId}`).then(({ data }) => data)
}
