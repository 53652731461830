import { pngCannabis } from 'assets/img'
import { svgWorkshops } from 'assets/svg'
import EducateAside from 'components/CannectEducate/EducateAside'
import EducateNavigator from 'components/CannectEducate/EducateNavigator'
import SocialMedia from 'components/SocialMedia'
import TabContent, { Post } from 'components/CannectEducate/TabContent'
import TabContentTitle from 'components/CannectEducate/TabContentTitle'
import TabsNavigator from 'components/TabsNavigator'
import YouTubeFrame from 'components/YouTubeFrame'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import api from 'services/api'
import * as Style from 'styles/cannectEducate'
import InfiniteScroll from 'react-infinite-scroll-component'
import LessonTitle from 'components/LessonTitle'
import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'

export interface Workshop {
  image: string | undefined
  id: number
  title: string
  description: string
  status: string
  path: string
  createdAt: Date
  updatedAt: Date
}

export interface FetchWorkshopsResponse {
  workshops: {
    count: number
    rows: Workshop[]
  }
  success: boolean
}

export function formatWorkshops(workshops: Workshop[]): Post[] {
  const newPosts: Post[] = []
  workshops.forEach(workshop => {
    newPosts.push({
      id: workshop.id,
      description: workshop.description,
      img: workshop.image,
      title: workshop.title,
      url: `/educa/workshops/${workshop.id}`,
      externalUrl: workshop.path
    })
  })

  return newPosts
}

export default function WorkshopsHome() {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [posts, setPosts] = useState<Post[]>([])
  const [loading, setLoading] = useState(false)
  const [postsQuantity, setPostsQuantity] = useState(0)
  const location = useLocation()
  const selectedWorkshop = handleWorkshopSelection()

  useEffect(() => {
    fetchPosts()
  }, [page])

  async function fetchPosts() {
    setLoading(true)
    try {
      const response = await api.get<FetchWorkshopsResponse>(`/workshops/${page}?limit=12`)
      const newPosts = formatWorkshops(response.data.workshops.rows)
      setPosts([...posts, ...newPosts])

      if (page === 1) {
        setPostsQuantity(response.data.workshops.count)
        setTotalPages(Math.ceil(response.data.workshops.count / 12))
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  function handleWorkshopSelection(): Post | undefined {
    const newPathname = location.pathname.split('/educa/workshops/')[1]

    if (newPathname) {
      const selectedPost = posts.find(post => post.id === parseInt(newPathname, 10))
      return selectedPost
    }

    return undefined
  }

  return (
    <Style.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Educa', link: '/educa' },
          { label: 'Workshops', link: `/educa/workshops` }
        ]}
      />
      <TabsNavigator />
      <EducateNavigator />

      <Style.Content>
        <EducateAside />

        <Style.Wrapper>
          <SocialMedia message="Acesse já diversos workshops sobre o uso da Cannabis medicinal!" />

          <TabContentTitle
            title="Workshops"
            titleImg={svgWorkshops}
            postsCountText={`${postsQuantity} workshops encontrados`}
          />

          {selectedWorkshop && (
            <>
              <LessonTitle>{selectedWorkshop.title}</LessonTitle>
              <YouTubeFrame url={selectedWorkshop.externalUrl} title={selectedWorkshop.title} />
            </>
          )}
          <InfiniteScroll
            dataLength={posts.length}
            next={() => setPage(state => state + 1)}
            hasMore={page !== totalPages}
            loader={<div />}
            endMessage={<div />}
          >
            <TabContent posts={posts.filter(post => post.id !== selectedWorkshop?.id)} actionButtonText="ASSISTIR" />
          </InfiniteScroll>

          {loading && <Loading loading={loading} />}
        </Style.Wrapper>
      </Style.Content>
    </Style.Container>
  )
}
