import React from 'react'
import SlickSLider, { Settings } from 'react-slick'
import * as Styles from './styles'

export type SliderSettings = Settings

export type SliderProps = {
  children: React.ReactNode
  settings: SliderSettings
}

export default function Slider({ settings, children }: SliderProps) {
  return (
    <Styles.Wrapper>
      <SlickSLider {...settings}>{children}</SlickSLider>
    </Styles.Wrapper>
  )
}
