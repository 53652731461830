import React from 'react'
import { MdError, MdCheckCircle, MdDangerous, MdInfo } from 'react-icons/md'
import { Container } from './styles'

interface BannerProps {
  title: string
  variant?: 'positive' | 'negative' | 'warning' | 'info'
  children?: React.ReactNode
}

function Banner({ title, children, variant = 'info' }: BannerProps) {
  return (
    <Container variant={variant}>
      <div className="banner_target" />
      <div className="banner_wrapper">
        <div className="banner_icon">
          {variant === 'info' && <MdInfo />}
          {variant === 'warning' && <MdError />}
          {variant === 'positive' && <MdCheckCircle />}
          {variant === 'negative' && <MdDangerous />}
        </div>
        <p className="banner_text">
          <p className="banner_body">
            <b>{title}</b>
          </p>
          <span>{children}</span>
        </p>
      </div>
    </Container>
  )
}

export default Banner
