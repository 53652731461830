import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import Select, { Theme } from 'react-select';
import { keepOnlyDigits } from 'utils/formatters';

import {
  Container,
  EndAdornmentWrapper,
  ErrorMessage,
  HelperText,
  Input,
  InputContainer,
  Label,
  selectStyles,
} from './styles';

export interface IOption {
  value?: string | number | boolean;
  label: string;
}

interface ICustomInput {
  name: string;
  label: string;
  select?: boolean;
  options?: IOption[];
  placeholder?: string;
  type?: string; // Adjusted to be a string for simplicity, or create an enum for type if needed
  maxLength?: number;
  endAdornment?: React.ReactNode;
  helperText?: string;
  disabled?: boolean;
  maxWidth?: string;
  className?: string;
  isLoading?: boolean;
  outlined?: boolean;
  onSelect?: (value: any) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Updated to specifically use React.ChangeEvent<HTMLInputElement>
  value?: string; // Added value prop to allow controlled input
}

function CustomInput({
  name,
  label,
  select,
  onSelect,
  outlined = false,
  options,
  placeholder,
  type = 'text',
  maxLength,
  endAdornment,
  helperText,
  disabled = false,
  isLoading = false,
  maxWidth,
  className,
  onKeyPress,
  onChange,
  value, // Added value prop
}: ICustomInput) {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const getNestedError = useCallback((errors: FieldErrors, name: string): any => {
    const keys = name.split('.');
    let current: any = errors;
    keys.every(key => {
      if (current && current[key]) {
        current = current[key];
        return true;
      }
      current = undefined;
      return false;
    });

    return current;
  }, []);

  const error = getNestedError(errors, name);

  const [loading, setLoading] = useState<boolean>(isLoading);

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }, [isLoading]);

  const customTheme = useCallback(
    (theme: Theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        text: 'neutral20',
        primary25: 'neutral20',
        primary: 'neutral20'
      }
    }),
    []
  );

  const customSelectStyles = useMemo(() => selectStyles, []);

  return (
    <Container maxWidth={maxWidth} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const formattedValue = keepOnlyDigits(e.target.value);
            field.onChange(e); // Pass the event to the field's onChange handler
            if (onChange) onChange(e); // Call the onChange prop with the event
          };

          return (
            <>
              {label && <Label>{label}</Label>}
              {select ? (
                <Select
                  classNamePrefix={outlined ? 'outlined' : ''}
                  theme={customTheme}
                  styles={customSelectStyles}
                  isDisabled={disabled || loading}
                  options={options}
                  onChange={(selectedOptions: any) => {
                    const value = selectedOptions ? selectedOptions.value : '';
                    field.onChange(value);
                    if (onSelect) {
                      onSelect(value);
                    }
                  }}
                  placeholder={placeholder || 'Selecione'}
                  value={options?.find(option => option.value === field.value)}
                />
              ) : (
                <InputContainer>
                  <Input
                    disabled={disabled || loading}
                    outlined={outlined}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onKeyPress={onKeyPress}
                    onChange={handleInputOnChange}
                    value={value ?? field.value} // Use the value prop if provided, else use field.value
                  />
                  {endAdornment && <EndAdornmentWrapper>{endAdornment}</EndAdornmentWrapper>}
                </InputContainer>
              )}
              {helperText && <HelperText>{helperText}</HelperText>}
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </>
          );
        }}
      />
    </Container>
  );
}

export default CustomInput;
