import blogCannect from '@cannect/services/blog'
import strapi from '@cannect/services/strapi'
import { TStrapiResponseStandardWithPagination } from '@cannect/services/types'
import { TBrandListItem, TCannectPostListItem, TStrapiParams, TStrapiPostListItem } from './types'

export const getStrapiPostsPaginated = async (params: TStrapiParams) => {
  return strapi
    .get<TStrapiResponseStandardWithPagination<TStrapiPostListItem[]>>(`/api/posts?${params.filterQuery}`)
    .then(({ data }) => data)
}

export const getCannectPostsPaginated = async (params: TStrapiParams) => {
  return blogCannect.get<TCannectPostListItem[]>('/blog_cannect').then(({ data }) => data)
}

export const getStrapiBrandsList = async (params: TStrapiParams) => {
  return strapi
    .get<TStrapiResponseStandardWithPagination<TBrandListItem[]>>(`/api/marcas?${params.filterQuery}`)
    .then(({ data }) => data)
}
