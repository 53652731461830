import { theme } from 'design-cannect'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 40px 0px;

  .div-input {
    .message {
      color: ${theme.colors.tertiary};
      font-size: 1.4rem;
    }
  }

  span {
    margin: 30px 0;
  }

  h1 {
    text-align: left;
    margin: 0px 0;
  }
`
