import styled, { css } from 'styled-components';

export const Wrapper = styled.main`
  width: 750px; // Aqui é onde você define a largura do wrapper
  padding-left: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  * {
    font-family: 'Nunito', sans-serif;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
  }
`;

export const Title = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  color: #555358;
  text-align: center;
  border-bottom: 1px solid #dadada;
  width: 100%;
  padding-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0 10px;
  }
`;

export const ContentText = styled.h3`
  font-size: 1.3rem;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border-bottom: 1px solid #dadada;
  width: 100%;
  padding: 15px;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0 10px;
  }
`;

export const ActionsContent = styled.div`
  margin-top: 25px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 10px;
    button {
      flex: 1;
      width: auto;
    }
  }
`;

const mobileStyles = css`
  ${Wrapper} {
    width: 100%;
    padding: 10px;
  }

  ${Title} {
    font-size: 1.1rem;
  }

  ${ActionsContent} {
    flex-direction: row;
    gap: 10px;
    button {
      flex: 1;
      width: auto;
    }
  }

  .unit_price_header {
    text-align: left;
  }

`;

const applyMobileStyles = css`
  @media (max-width: 768px) {
    ${mobileStyles}
  }
`;

export const GlobalStyle = styled.div`
  ${applyMobileStyles}
`;

