import useMediaQuery from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

interface YouTubeFrameProps {
  url: string
  title: string
}

export default function YouTubeFrame({ url, title }: YouTubeFrameProps) {
  const [height, setHeight] = useState('535px')
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const isTablet = useMediaQuery(TABLET_WIDTH)

  useEffect(() => {
    if (isMobile) {
      setHeight('190px')
    } else if (isTablet) {
      setHeight('360px')
    }
  }, [isMobile, isTablet])

  return (
    <iframe
      width="100%"
      height={height}
      src={url}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      style={{ marginBottom: '2rem' }}
      allowFullScreen
    />
  )
}
