import { Button, Form } from '@cannect/new-components/atoms'
import { TFormValues } from '@cannect/pages/WebinarSubscription/types'
import { STATE_LIST } from '@cannect/utils/constants'
import { motion } from 'framer-motion'
import { Controller, useFormContext } from 'react-hook-form'
import { TYPE_OF_BOARD_LIST } from '@cannect/pages/Registration/components/Forms/components/ValidationForm/constants'
import { InputField, SelectField } from '@cannect/new-components/molecules'

type StepTwoProps = {
  allowOnlyDoctors?: boolean
}

export const StepTwo = ({ allowOnlyDoctors }: StepTwoProps) => {
  const {
    control,
    formState: { isSubmitting },
    watch
  } = useFormContext<TFormValues>()

  const normalizedStateOptions = STATE_LIST.map((state) => ({
    value: state,
    label: state
  }))
  const { council, uf_council } = watch()

  const shouldShowCrmPrefix = uf_council?.value === 'RJ' && council === 'CRM'

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center gap-4">
      {!allowOnlyDoctors && (
        <SelectField name="council" placeholder="Tipo de conselho" control={control} options={TYPE_OF_BOARD_LIST} />
      )}
      <div className="flex w-full flex-col items-start gap-2 md:flex-row">
        <div className="w-full">
          <Controller
            control={control}
            name="uf_council"
            render={({ field, fieldState }) => (
              <Form.Select
                {...field}
                value={field.value}
                onChange={field.onChange}
                isInvalid={fieldState.invalid}
                options={normalizedStateOptions}
                placeholder="Estado"
              />
            )}
          />
        </div>
        <div className="relative w-full">
          <InputField
            name="nr_council"
            control={control}
            mask="onlyNumbers"
            className={`${shouldShowCrmPrefix ? 'pl-8' : ''}`}
            placeholder={`${allowOnlyDoctors ? 'Número do CRM' : 'Número do Conselho'}`}
          />
          {shouldShowCrmPrefix && (
            <span className="absolute left-4 top-[14px] text-xs font-medium text-gray-500">52 -</span>
          )}
        </div>
      </div>

      <Button full type="submit" isLoading={isSubmitting}>
        Avançar
      </Button>
    </motion.div>
  )
}
