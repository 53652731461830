import { Button } from 'design-cannect'
import { useHistory, useLocation } from 'react-router-dom'
import * as Style from './styles'

export default function CienciaNavigator() {
  const history = useHistory()
  const location = useLocation()

  const links = [
    {
      path: '/cannect-ciencia',
      label: 'Cannect Ciência'
    },
    {
      path: '/whitepapers',
      label: 'Whitepapers'
    }
  ]

  function handleRedirect(path: string) {
    history.push(path)
  }

  return (
    <Style.EducateNavigatorContainer>
      {links.map(link => (
        <Button
          key={link.path}
          outlined
          onClick={() => handleRedirect(link.path)}
          secondary={location.pathname.includes(link.path)}
        >
          {link.label}
        </Button>
      ))}
    </Style.EducateNavigatorContainer>
  )
}
