import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { PrivacyText, SchedulingPrivacyText } from './mockPrivacy'
import * as Styles from './styles'
import { useHistory } from 'react-router'

interface IPrivacyPolicyProps {
  isSchedulingPrivacy?: boolean
}

function PrivacyPolicy({ isSchedulingPrivacy: schedulingPrivacy }: IPrivacyPolicyProps) {
  const history = useHistory()
  const isSchedulingPrivacy = history.location.pathname.includes('agendamento') || schedulingPrivacy
  return (
    <Styles.Container>
      <Heading defaultColor="darkGrey">
        {isSchedulingPrivacy ? 'Política de uso da plataforma de agendamento de consulta' : 'Política de privacidade'}
      </Heading>
      <TextDanger text={isSchedulingPrivacy ? SchedulingPrivacyText : PrivacyText} />
    </Styles.Container>
  )
}

export default PrivacyPolicy
