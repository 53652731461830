const FROM_PATH = '@CANNECT_FROM_PATH'

interface RedirectToProps {
  pathname: string
}

const setPathFrom = async (redirectTo: RedirectToProps) => {
  await localStorage.setItem(FROM_PATH, JSON.stringify(redirectTo))
}

const getPathFrom = async () => {
  const redirectTo = localStorage.getItem(FROM_PATH)

  if (redirectTo) {
    return JSON.parse(redirectTo)
  }

  return null
}

const removePahtFrom = async () => {
  await localStorage.removeItem(FROM_PATH)
}

export { setPathFrom, getPathFrom, removePahtFrom }
