import React, { useEffect, useState } from 'react'

import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'

import Text from 'components/Text'
import { Input } from 'design-cannect'
import { clearMask, formatZipCode } from 'utils/formatters'
import Loading from 'components/Loading'

import axios from 'axios'
import { getUserUuid } from 'hooks/useSessionUser'
import { useHistory } from 'react-router'
import { useTokenApi } from 'hooks/useTokenApi'
import api from '../../../services/api'

import * as Style from './styles'
import NeedHelp from '../NeedHelp'

const keys = ['name', 'cep', 'address', 'number', 'complement', 'district', 'city', 'state']

export default function Form() {
  const { deleteTokenApi } = useTokenApi()
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const orderId = localStorage.getItem('@CANNECT:ORDERID')
  const uuid = getUserUuid()

  const [loading, setLoading] = useState(false)

  const [inputValue, setInputValue] = useState<any>({
    nome: '',
    dependentName: '',
    dependentCpf: '',
    email: '',
    dependentEmail: '',
    dependentPhone: '',
    homePhone: '',
    cep: '',
    address: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    number: ''
  })

  const {
    dependentName,
    dependentCpf,
    dependentEmail,
    dependentPhone,
    cep,
    address,
    complement,
    district,
    city,
    state,
    number
  } = inputValue

  const [checkBoxSelect, setCheckBoxSelect] = useState<{
    first: boolean
    second: boolean
    third: boolean
  }>({
    first: true,
    second: false,
    third: false
  })

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    setInputValue((inputValue: any) => ({ ...inputValue, [id]: value }))

    if (id === 'cep') {
      setInputValue((inputValue: any) => ({ ...inputValue, [id]: value }))
      if (value.length === 9) {
        fetchZipCode(clearMask(value))
      }
    }
  }

  const handleSubmit = async () => {
    setLoading(true)

    if (checkBoxSelect.second === true || checkBoxSelect.third === true) {
      localStorage.setItem('@CANNECT:ISDEPENDENT', 'true')

      const payload = {
        shippingAddress: {
          cep: inputValue.cep,
          state: inputValue.state,
          city: inputValue.city,
          street: inputValue.address,
          district: inputValue.district,
          complement: inputValue.complement,
          address_number: inputValue.number
        },
        dependent: {
          name: inputValue.dependentName,
          email: inputValue.dependentEmail,
          cpf: inputValue.dependentCpf,
          phoneMobile: inputValue.dependentPhone
        }
      }

      if (orderId) {
        try {
          await api.put(`order/${+orderId}`, payload)

          history.push('/receita-branca/documentos-pessoais')
        } catch (e: any) {
          if (e?.response?.status === 401) {
            deleteTokenApi()
          }
        } finally {
          setLoading(false)
        }
      }
      return
    }

    const payload = {
      shippingAddress: {
        cep: inputValue.cep,
        state: inputValue.state,
        city: inputValue.city,
        street: inputValue.address,
        district: inputValue.district,
        complement: inputValue.complement,
        address_number: inputValue.number
      }
    }

    if (orderId) {
      try {
        await api.put(`order/${+orderId}`, payload)

        history.push('/receita-branca/documentos-pessoais')
      } catch (e: any) {
        if (e?.response?.status === 401) {
          deleteTokenApi()
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const handleClickCheckBox = (value: string): void => {
    switch (true) {
      case value === 'first':
        setCheckBoxSelect({
          first: true,
          second: false,
          third: false
        })
        break
      case value === 'second':
        setCheckBoxSelect({
          first: false,
          second: true,
          third: false
        })
        break
      default:
        setCheckBoxSelect({
          first: false,
          second: false,
          third: true
        })
        break
    }
  }

  async function fetchZipCode(cep: string) {
    setLoading(true)
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      setInputValue((state: any) => ({
        ...state,
        cep: response?.data?.cep ? formatZipCode(response?.data?.cep) : '',
        address: response.data.logradouro,
        district: response.data.bairro,
        city: response.data.localidade,
        state: response.data.uf
      }))
    } catch (err: any) {
      setLoading(false)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const getMyAccount = async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`my_account/${uuid}`)
      const newInput = inputValue
      let personalInformation = data?.personalInformation
      personalInformation = { ...personalInformation, ...data?.personalInformation?.addresses[0] }
      // eslint-disable-next-line no-return-assign
      keys?.map((key: any) => (newInput[key] = personalInformation[key] || ''))
      setInputValue(newInput)
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyAccount()
  }, [])

  return (
    <Style.Container isColumn>
      <Loading loading={loading} />

      <Text type="lg" bold color="tertiary">
        Endereço de entrega
      </Text>
      <Text type="md" color="tertiary">
        *Vamos precisar de um comprovante de endereço na etapa seguinte.
      </Text>

      {isMobile ? (
        <Style.DeliveryAddress isMobile={isMobile}>
          <Style.InputWrapper className="full">
            <Text type="md" bold color="tertiary">
              CEP
            </Text>
            <Input required value={formatZipCode(cep)} id="cep" onChange={e => changeValue(e)} placeholder="CEP" />
            <Style.Button>
              <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="__blank">
                <h3>Não sei meu CEP</h3>
              </a>
            </Style.Button>
            &nbsp; &nbsp;
            <Text type="md" bold color="tertiary">
              Endereço
            </Text>
            <Input required value={address} id="address" onChange={e => changeValue(e)} placeholder="Rua, avenida..." />
            <Text type="md" bold color="tertiary">
              Cidade
            </Text>
            <Input required value={city} id="city" onChange={e => changeValue(e)} placeholder="Cidade" />
          </Style.InputWrapper>
          <Style.InputWrapper className="full">
            <Text type="md" bold color="tertiary">
              Complemento
            </Text>
            <Input
              value={complement}
              id="complement"
              onChange={e => changeValue(e)}
              placeholder="Ex: Bloco C, Ap 125"
            />

            <Text type="md" bold color="tertiary">
              UF
            </Text>
            <Input required value={state} id="state" onChange={e => changeValue(e)} placeholder="Ex: RJ, SP, SC" />
          </Style.InputWrapper>
          <Style.InputWrapper className="full">
            <Text type="md" bold color="tertiary">
              Bairro
            </Text>
            <Input value={district} id="district" onChange={e => changeValue(e)} placeholder="Ex: Jd Nova Europa" />
            <Text type="md" bold color="tertiary">
              Número
            </Text>
            <Input value={number} id="number" onChange={e => changeValue(e)} placeholder="nº" />
          </Style.InputWrapper>
        </Style.DeliveryAddress>
      ) : (
        <Style.DeliveryAddress isMobile={isMobile}>
          <Style.InputWrapper className="full">
            <Text type="md" bold color="tertiary">
              CEP
            </Text>
            <Input required value={formatZipCode(cep)} id="cep" onChange={e => changeValue(e)} placeholder="CEP" />
            &nbsp;
            <Text type="md" bold color="tertiary">
              Endereço
            </Text>
            <Input required value={address} id="address" onChange={e => changeValue(e)} placeholder="Rua, avenida..." />
            &nbsp;
            <Text type="md" bold color="tertiary">
              Cidade
            </Text>
            <Input required value={city} id="city" onChange={e => changeValue(e)} placeholder="Cidade" />
          </Style.InputWrapper>
          <Style.InputWrapper className="full">
            <Style.Button>
              <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="__blank">
                <h3>Não sei meu CEP</h3>
              </a>
            </Style.Button>
            &nbsp;
            <Text type="md" bold color="tertiary">
              Número
            </Text>
            <Input value={number} id="number" onChange={e => changeValue(e)} placeholder="nº" />
            &nbsp;
            <Text type="md" bold color="tertiary">
              UF
            </Text>
            <Input required value={state} id="state" onChange={e => changeValue(e)} placeholder="Ex: RJ, SP, SC" />
          </Style.InputWrapper>
          <Style.InputWrapper className="full">
            <div className="teste">
              <Text type="md" bold color="tertiary">
                Complemento
              </Text>
              <Input
                value={complement}
                id="complement"
                onChange={e => changeValue(e)}
                placeholder="Ex: Bloco C, Ap 125"
              />
              &nbsp;
              <Text type="md" bold color="tertiary">
                Bairro
              </Text>
              <Input value={district} id="district" onChange={e => changeValue(e)} placeholder="Ex: Jd Nova Europa" />
            </div>
          </Style.InputWrapper>
        </Style.DeliveryAddress>
      )}

      {/* <Text type="lg" bold color="tertiary">
        Dados do paciente
      </Text>
      <Style.DeliveryAddress>
        <Style.InputWrapper className="full" isMobile={isMobile}>
          <div className="rowitems">
            <Text type="md" bold color="tertiary">
              Nome
            </Text>
            <Input value={name} id="name" onChange={e => changeValue(e)} placeholder="Seu nome completo" />
          </div>
          <div className="rowitems">
            <Text type="md" bold color="tertiary">
              Email
            </Text>
            <Input value={email} id="email" onChange={e => changeValue(e)} placeholder="Seu endereço de e-mail" />
          </div>
        </Style.InputWrapper>
      </Style.DeliveryAddress> */}

      {/* <Text type="lg" bold color="tertiary">
        Contato do paciente ou responsável
      </Text>
      <Style.DeliveryAddress isColumn>
        <Style.InputWrapper className="full" isMobile={isMobile}>
          <div className="rowitems">
            <Text type="md" bold color="tertiary">
              Celular
            </Text>
            <Input value={formatPhone(phone)} id="phone" onChange={e => changeValue(e)} placeholder="DDD + número" />
          </div>
          <div className="rowitems">
            <Text type="md" bold color="tertiary">
              Residencial
            </Text>
            <Input
              value={formatPhone(homePhone)}
              id="homePhone"
              onChange={e => changeValue(e)}
              placeholder="DDD + número"
            />
          </div>
        </Style.InputWrapper>
      </Style.DeliveryAddress> */}

      <Text type="md" bold color="tertiary">
        A receita médica que constam os produtos que estou comprando está nominal
      </Text>

      <Style.CheckboxContainer isMobile={isMobile}>
        <Style.Checkbox checked={checkBoxSelect.first} onClick={() => handleClickCheckBox('first')} />
        <Text type="md" color="tertiary">
          Para mim
        </Text>
        <Style.Checkbox checked={checkBoxSelect.second} onClick={() => handleClickCheckBox('second')} />
        <Text type="md" color="tertiary">
          Para mim e dependentes
        </Text>
        <Style.Checkbox checked={checkBoxSelect.third} onClick={() => handleClickCheckBox('third')} />
        <Text type="md" color="tertiary">
          Para dependentes
        </Text>
      </Style.CheckboxContainer>

      {!checkBoxSelect.first ? (
        <>
          <Text type="lg" bold color="tertiary">
            Contato do dependente
          </Text>
          <Style.DeliveryAddress isColumn>
            <Style.InputWrapper className="full" isMobile={isMobile}>
              <div className="rowitems">
                <Text type="md" bold color="tertiary">
                  Nome Completo
                </Text>
                <Input
                  value={dependentName}
                  id="dependentName"
                  onChange={e => changeValue(e)}
                  placeholder="Nome completo"
                />
              </div>
              &nbsp;
              <div className="rowitems">
                <Text type="md" bold color="tertiary">
                  E-mail
                </Text>
                <Input value={dependentEmail} id="dependentEmail" onChange={e => changeValue(e)} placeholder="e-mail" />
              </div>
              &nbsp;
              <div className="rowitems">
                <Text type="md" bold color="tertiary">
                  CPF
                </Text>
                <Input value={dependentCpf} id="dependentCpf" onChange={e => changeValue(e)} placeholder="Cpf" />
              </div>
              &nbsp;
              <div className="rowitems">
                <Text type="md" bold color="tertiary">
                  Celular
                </Text>
                <Input
                  value={dependentPhone}
                  id="dependentPhone"
                  onChange={e => changeValue(e)}
                  placeholder="Celular"
                />
              </div>
            </Style.InputWrapper>
          </Style.DeliveryAddress>
        </>
      ) : null}

      <Style.SubmitButtonWrapper isMobile={isMobile}>
        <Style.SubmitButton disabled={inputValue.cep.length < 8} onClick={handleSubmit}>
          Continuar
        </Style.SubmitButton>
      </Style.SubmitButtonWrapper>
      <NeedHelp />
    </Style.Container>
  )
}
