/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import { linkSimple, receiptBlue, whats2 } from 'assets/img'

import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { AiOutlinePrinter } from 'react-icons/ai'
import { toast } from 'react-toastify'
import api from 'services/api'
import * as Style from './styles'
import { removeSpecialCharacters } from 'utils/formatters'

type ShareButtonProps = {
  type: 'whatsapp' | 'email' | 'copy' | 'showRecipe' | 'print'
  link: string
  linkPdf?: string
}

export default function ShareButton({ type, link, linkPdf }: ShareButtonProps) {
  const { prescription, createdRecipe } = useCannectAssistant()
  const [emailAlreadySent, setEmailAlreadySent] = useState(false)
  const isButtonDisabled = type === 'email' && emailAlreadySent
  const message = `Acesse já a sua receita: ${link}`
  const whatsapp = `https://api.whatsapp.com/send?phone=55${removeSpecialCharacters(
    prescription.patient.phone
  )}&text=${message}`
  console.log(removeSpecialCharacters(prescription.patient.phone))
  // console.log(removeSpecialCharacters(prescription))

  function handleShare() {
    if (type === 'print') {
      handlePrint()
    } else if (type === 'whatsapp') {
      const link = document.createElement('a')
      link.href = whatsapp
      link.rel = 'noreferrer'
      link.target = '_blank'
      link.click()
    } else if (type === 'email') {
      if (prescription.patient.email.length === 0) {
        toast.error('Não foi possível compartilhar a receita, pois o e-mail do paciente não foi informado')
        return
      }
      shareRecipeOnEmail()
    } else if (type === 'showRecipe') {
      const link = document.createElement('a')
      link.href = linkPdf ?? ''
      link.rel = 'noreferrer'
      link.target = '_blank'
      link.click()
    } else {
      navigator.clipboard.writeText(link)
      toast.success('Link copiado com sucesso!')
    }
  }

  async function shareRecipeOnEmail() {
    try {
      await api.post(`/recipe_email`, {
        patient_email: prescription.patient.email,
        patient_name: prescription.patient.name,
        token: createdRecipe?.token
      })
      toast.success('A receita foi enviada para o e-mail do paciente com sucesso!')
      setEmailAlreadySent(true)
    } catch (err) {
      console.error(err)
      toast.error('Houve um erro ao compartilhar receita por e-mail. Tente novamente.')
    }
  }

  const handlePrint = () => {
    window.open(linkPdf, 'PRINT', 'height=500,width=700')
  }

  return (
    <Style.ShareButtonContainer onClick={() => (!isButtonDisabled ? handleShare() : {})} disabled={isButtonDisabled}>
      <Style.ButtonItself disabled={isButtonDisabled}>
        {type === 'whatsapp' && <img src={whats2} alt="Whatsapp" />}
        {type === 'copy' && <img src={linkSimple} alt="copy" />}
        {type === 'showRecipe' && <img src={receiptBlue} alt="copy" />}
        {type === 'print' && <AiOutlinePrinter />}
      </Style.ButtonItself>
      <span>
        {type === 'whatsapp' && 'Whatsapp'}
        {type === 'copy' && 'Copiar link'}
        {type === 'showRecipe' && 'Visualizar Receita'}
        {type === 'print' && 'Imprimir receita'}
      </span>
    </Style.ShareButtonContainer>
  )
}
