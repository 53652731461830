import { theme } from 'design-cannect'
import styled from 'styled-components'

export const FilterWrapper = styled.div<any>`
  width: ${({ isMobile }) => (isMobile ? '327px' : '700px')};
`
export const NoProduct = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 20px;

  margin-top: 36px;
`

export const SeeProducts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonWrapper = styled.div``
export const Button = styled.a`
  display: flex;

  text-decoration: none;

  background-color: #115850;

  color: #ecece2;

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #0b3b35;

  margin-top: 50px;
  padding: 1rem;

  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.7px;

  cursor: pointer;

  transition: transform 0.2s;

  a:link {
    text-decoration: none;
  }

  h3 {
    color: #ecece2;
  }

  p {
    padding-top: 5px;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
export const SubmitButton = styled.button<any>`
  display: flex;

  justify-content: center;


  background-color: ${theme.colors.tertiary};
  color: ${theme.colors.white};

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: ${theme.colors.tertiary};

  width: ${({ isMobile }) => (isMobile ? '130px' : null)};

  margin-top: 50px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
export const SubmitButtonWrapper = styled.div<any>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: center;

  gap: 20px;

  width: ${({ isMobile }) => (isMobile ? '50%' : '100%')};
`
