import React, { useEffect, useState } from 'react'
import Input from 'components/Input'
import { FaSpinner } from 'react-icons/fa'
import { sendTokenForgotPassword } from 'services/user'
import { useScheduling } from 'hooks/useScheduling'
import * as Styles from './styles'
import { Button, Typography } from '@cannect/new-components/atoms'

function FormForgotPassword() {
  const { isSchedulingFlow, setCheckoutFormState } = useScheduling()

  const [email, setEmail] = useState<any>('')

  const [loading, setLoading] = useState(false)

  const [disable, setDisable] = useState(false)

  const [timer, setTimer] = useState(0)

  const [message, setMessage] = useState('')

  const handleValue = (target: any) => {
    const { id, value } = target
    setEmail(value)
  }

  function disableButton() {
    setDisable(true)
    setTimer(30)
  }

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1)
      } else {
        setDisable(false)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [timer])

  async function handleSubmit(e: any) {
    if (!disable) {
      e.preventDefault()
      setLoading(true)
      setMessage('')
      sendTokenForgotPassword({
        email
      })
        .then((res) => {
          setLoading(false)
          disableButton()
          setMessage('Email enviado com sucesso')
        })
        .catch((err) => {
          setLoading(false)
          setMessage('Email não encontrado')
        })
    }
  }

  return (
    <Styles.Container>
      <Styles.Wrapper>
        <br />
        <br />
        <Typography.Heading type="headingTwo" className="mt-10 text-primary-700 lg:mt-4">
          Esqueci a senha
        </Typography.Heading>
        <Typography.Text className="m-6 pb-6 text-sm font-light text-primary-800">
          Vamos te enviar um email para você poder redefinir sua senha
        </Typography.Text>
        <form className="w-full" onSubmit={(e: any) => handleSubmit(e)}>
          <Input
            value={email}
            placeholder="Insira seu email"
            className="m-4 w-full sm:max-w-lg"
            width="100%"
            onChange={(e: any) => handleValue(e.target)}
          />

          <div className="m-4 flex justify-center">
            {isSchedulingFlow && (
              <Button onClick={() => setCheckoutFormState('login')}>
                <span>Voltar</span>

                {loading && (
                  <span className="spinner">
                    <FaSpinner />
                  </span>
                )}
              </Button>
            )}
            <Button type="submit" disabled={disable}>
              {disable ? <span>Aguarde {timer} segundos para enviar novamente</span> : <span>Enviar Email</span>}

              {loading && (
                <span className="spinner">
                  <FaSpinner />
                </span>
              )}
            </Button>
          </div>
          {message && <span className="flex justify-center text-critical-700">{message}</span>}
        </form>
      </Styles.Wrapper>
    </Styles.Container>
  )
}

export default FormForgotPassword
