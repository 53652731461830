import { Modal } from 'components/Modal'
import { theme } from 'design-cannect'
import Button, { ButtonProps } from 'new-components/Button'
import { ReactNode } from 'react'
import * as Style from './styles'

interface ConfirmationDialogueProps {
  isOpened: boolean
  onRequestClose: () => void
  title: string
  description: string | ReactNode
  confirmationButtonText?: string
  cancelButtonText?: string
  confirmationOnlyDialogue?: boolean
  titleColor?: string
  buttonsBackground?: ButtonProps['background']
  onConfirmationClick: () => void
  onCancelClick?: () => void
}

export default function ConfirmationDialogue({
  isOpened,
  onRequestClose,
  title,
  description,
  confirmationButtonText = 'Confirmar',
  cancelButtonText = 'Cancelar',
  titleColor = theme.colors.secondary,
  buttonsBackground = 'green',
  confirmationOnlyDialogue,
  onConfirmationClick,
  onCancelClick
}: ConfirmationDialogueProps) {
  return (
    <Modal isOpened={isOpened} onRequestClose={onRequestClose}>
      <Style.ConfirmationDialogueContainer>
        <h1 style={{ color: titleColor }}>{title}</h1>
        {typeof description === 'string' ? <p>{description}</p> : description}
        <div>
          {!confirmationOnlyDialogue && onCancelClick && (
            <Button type="button" background={buttonsBackground} isOutlined onClick={() => onCancelClick()}>
              {cancelButtonText}
            </Button>
          )}
          <Button type="button" background={buttonsBackground} onClick={() => onConfirmationClick()}>
            {confirmationButtonText}
          </Button>
        </div>
      </Style.ConfirmationDialogueContainer>
    </Modal>
  )
}
