/* eslint-disable no-nested-ternary */
import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { Typography, Button } from '@cannect/new-components/atoms'

import { motion, useAnimation } from 'framer-motion'
import { LuArrowRightCircle } from 'react-icons/lu'
import {
  desktopContainerVariants,
  mobileContainerVariants,
  blurVariants,
  contentVariants,
  titleVariants
} from './variants'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'

type TCardPhotoExpand = {
  items: {
    title: string
    path: string
    imagePath: string
    description: string
  }[]
}
export const CardPhotoExpand = ({ items }: TCardPhotoExpand) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const history = useHistory()
  const [openedIndex, setOpenedIndex] = useState<number | null>(isMobile ? 0 : null)
  const controls = useAnimation()

  useEffect(() => {
    if (isMobile) {
      setOpenedIndex(0)
    }
  }, [isMobile])

  const handleCardClick = async (index: number) => {
    if (openedIndex !== index) {
      setOpenedIndex(index)
      await controls.start('open')
    }
  }

  return (
    <div className="w-full overflow-hidden md:overflow-x-auto">
      <div className="m-0 flex flex-col space-y-4 p-4 md:h-[400px] md:w-full md:flex-row md:space-x-4 md:space-y-0">
        {items.map((item, index) => (
          <motion.div
            key={index}
            initial="initial"
            whileHover={isMobile ? undefined : 'hover'}
            animate={isMobile ? (openedIndex === index ? 'open' : 'closed') : controls}
            onClick={() => isMobile && handleCardClick(index)}
            variants={isMobile ? mobileContainerVariants : desktopContainerVariants}
            style={{
              backgroundImage: `url(${item.imagePath})`
            }}
            className="relative flex-grow rounded bg-cover bg-top bg-no-repeat shadow-md md:h-full">
            <motion.div
              initial={{ opacity: 0 }}
              variants={blurVariants}
              className="absolute inset-0 h-full w-full rounded backdrop-blur-md"
            />
            <motion.div
              className="absolute bottom-0 left-0 z-10 flex w-full justify-center p-2"
              variants={titleVariants}>
              <Typography.Heading type="headingThree" weight="semibold" className="text-gray-50">
                {item.title}
              </Typography.Heading>
            </motion.div>
            <motion.div
              className="absolute inset-0 z-10 flex w-full flex-col items-center justify-end pb-4"
              variants={contentVariants}>
              <Typography.Heading type="headingThree" weight="semibold" className="text-gray-50">
                {item.title}
              </Typography.Heading>
              <Typography.Heading type="headingSix" className="-mt-2 font-normal text-gray-50">
                {item.description}
              </Typography.Heading>
              <Button
                className="mt-2 text-gray-50"
                unstyled
                icon={<LuArrowRightCircle />}
                iconPlacement="right"
                onClick={() => history.push(item.path)}>
                Saiba mais
              </Button>
            </motion.div>
          </motion.div>
        ))}
        <motion.div
          key={9999}
          initial="initial"
          variants={isMobile ? mobileContainerVariants : desktopContainerVariants}
          className="relative flex-grow rounded bg-cover bg-top bg-no-repeat md:h-full"
        />
      </div>
    </div>
  )
}
