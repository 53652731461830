import styled, { css } from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'
import { temporaryTheme } from '../../../styles/theme'

export const Wrapper = styled.div`
  ${() => css`
    min-height: 350px;
    width: 429px;
    display: flex;
    flex-direction: column;
    background: #eef6fe;
    border-radius: 19.9781px;
    padding: ${temporaryTheme.spacing.space4};
    gap: ${temporaryTheme.spacing.space4};
    box-shadow: ${temporaryTheme.shadow.shad1};
    .icon-box {
      filter: invert(33%) sepia(16%) saturate(651%) hue-rotate(174deg) brightness(96%) contrast(92%);
      width: 100%;
      svg {
        width: 50px;
        height: 50px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 300px;
    }
  `}
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  color: ${temporaryTheme.colors.dark};
  gap: ${temporaryTheme.spacing.space5};

  .card-title {
    font-weight: 700;
    font-size: 20px;
    font-family: 'Spartan', sans-serif;
  }

  .card-text {
    font-weight: 400;
    font-size: 16px;
    font-family: 'Spartan', sans-serif;
    line-height: 31px;
  }

  @media ${MOBILE_WIDTH} {
    font-size: 15px;

    .card-text {
      font-size: 12px;
      line-height: 19px;
    }
  }
`
