import Texts from 'components/Text'
import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import * as Styles from './styles'
import { classesbanner } from '../../../assets/img/index'

function SessionPrimary() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Styles.Container isMobile={isMobile}>
      <img src={classesbanner} alt="" />
      <div className="content">
        <Texts type="h1" color="primary">
          Isolado, espectro amplo ou espectro completo?
        </Texts>
        <Texts type="md" color="tertiary">
          Entender estas diferenças é importante no momento de escolher qual produto será utilizado. A escolha entre
          qual tipo usar em cada tratamento deve ser realizada pelo médico.
        </Texts>
      </div>
    </Styles.Container>
  )
}

export default SessionPrimary
