import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 42px;
  justify-content: space-between;
  align-items: flex-start;
  button {
    min-width: 150px;
  }

  @media ${MOBILE_WIDTH} {
    gap: 18px;
    height: auto;

    button {
      min-width: fit-content;
    }
  }
`
