import React from 'react'

export default function IconeCartao({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M26 7H6C4.89543 7 4 7.89543 4 9V23C4 24.1046 4.89543 25 6 25H26C27.1046 25 28 24.1046 28 23V9C28 7.89543 27.1046 7 26 7Z"
        stroke="#3D3D3D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12.5H11.1C11.3303 12.4987 11.5538 12.578 11.7318 12.7241C11.9099 12.8702 12.0313 13.0739 12.075 13.3C12.266 14.1994 12.76 15.0059 13.4745 15.5846C14.189 16.1633 15.0806 16.4791 16 16.4791C16.9194 16.4791 17.811 16.1633 18.5255 15.5846C19.24 15.0059 19.734 14.1994 19.925 13.3C19.9687 13.0739 20.0901 12.8702 20.2682 12.7241C20.4462 12.578 20.6697 12.4987 20.9 12.5H28"
        stroke="#3D3D3D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
