import { QRCodeCanvas } from 'qrcode.react'

import { Skeleton, Spin } from 'antd'

import { Header, PixContainer, QrCode, QrCodeContainer, QrCodeTutorial } from './styles'
import CopyButton from 'components/CopyButton'
import When from 'utils/when'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'

interface PixProps {
  qrCode: string | null
  loadingPayment: boolean
}

export function PixMethod({ qrCode, loadingPayment }: PixProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <PixContainer>
      {!loadingPayment && qrCode ? (
        <>
          <Header>
            <span>Escaneie este código para pagar</span>
            <span>
              <When expr={!isMobile}>
                <Spin size="small" />
                aguardando pagamento..
              </When>
            </span>
          </Header>
          <QrCodeContainer>
            <QRCodeCanvas
              value={qrCode}
              size={168}
              bgColor="#f6f6f6"
              fgColor="#000000"
              level="L"
              includeMargin={false}
            />

            <QrCodeTutorial>
              <p>
                <span>1.</span> Copia a chave pix.
              </p>
              <p>
                <span>2.</span> Acesse seu Internet Banking ou app de pagamentos.
              </p>
              <p>
                <span>3.</span> Escolha a opção de Copia e Cola e insira o código Pix.
              </p>
              <p>
                <span>4.</span> Verifique as informações e confirme o pagamento.
              </p>
              <p>
                <span>5.</span> Após validado o pagamento você será automaticamente direcionado para a página de resumo
                de compra.
              </p>
              <QrCode>
                <p>{qrCode}</p>
              </QrCode>
              <CopyButton dataToCopy={qrCode} buttonText="Copiar código" copiedText="Código copiado" />
            </QrCodeTutorial>
          </QrCodeContainer>
        </>
      ) : (
        <Skeleton active avatar />
      )}
    </PixContainer>
  )
}

export default PixMethod
