import ReactGA from 'react-ga4'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

interface ClickLoadObjectTypes {
  name: string
  category: string
  action: string
  value?: string
  mocked?: boolean
}

interface VisitedPageTypes {
  path: string
  title: string
}

interface DataLayerEventProps {
  eventName: string
  [key: string]: any
}

const isProduction = () =>
  !window?.location?.host?.includes('homolog') && !window?.location?.host?.includes('localhost')

const SendVisitedPage = ({ path, title }: VisitedPageTypes) => {
  if (isProduction()) {
    ReactGA.send({ hitType: 'pageview', page: path, title })
  }
}

const ClickSendObjectEvent = ({ name, action, category, value, mocked }: ClickLoadObjectTypes) => {
  if (isProduction()) {
    ReactGA.event(name, {
      category,
      action,
      value,
      label: mocked ? 'Data Mock' : 'Real Data'
    })
  }
}

const SendDataLayer = (props: DataLayerEventProps) => {
  if (isProduction()) {
    const { eventName, ...eventData } = props
    window.dataLayer = window.dataLayer || []

    const dataLayerEvent = {
      event: eventName,
      ...eventData
    }

    window.dataLayer.push(dataLayerEvent)
  }
}

const getEvents = {
  eventVisitedPage: (data: VisitedPageTypes) => SendVisitedPage(data),
  eventLoadObjects: (data: ClickLoadObjectTypes) => ClickSendObjectEvent(data),
  eventSendDataLayer: (data: DataLayerEventProps) => SendDataLayer(data)
}

export { getEvents }
