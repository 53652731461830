/**
 * This function is necessary to format number to currency
 * Ex: R$ 10,00
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * @param number Number to format
 * @param hiddenPrefix not show ex: R$
 * @returns string
 */
export function formatToReal(number: number | string, hiddenPrefix = false): string {
  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  const formatted = formatter.format(Number(number))

  return hiddenPrefix ? formatted.replace('R$', '') : formatted
}
