import styled from 'styled-components'
import { theme } from 'design-cannect'
import { ReactNode } from 'react'

const LessonTitleContainer = styled.h2`
  font-size: 1.25rem;
  color: ${theme.colors.tertiary};
`

interface LessonTitleProps {
  children: ReactNode
}

export default function LessonTitle({ children }: LessonTitleProps) {
  return <LessonTitleContainer>{children}</LessonTitleContainer>
}
