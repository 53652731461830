import { Modal } from 'components/Modal'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const SaveTemplateModalContainer = styled(Modal)`
  min-width: 500px;

  @media ${MOBILE_WIDTH} {
    min-width: 95%;
  }
`

export const SaveTemplateForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;

  h1 {
    margin-bottom: 0.5rem;
  }
`

export const SaveTemplateButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  margin-top: 1.5rem;
`
