/* eslint-disable no-nested-ternary */
import { InputHTMLAttributes } from 'react'
import * as Styles from './styles'

export interface MinimalistInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  width?: string
  maxWidth?: string
  errorMessage?: string
}

export default function MinimalistInput({
  id,
  label,
  width,
  maxWidth,
  errorMessage,
  type,
  ...rest
}: MinimalistInputProps) {
  return (
    <Styles.MinimalistInputContainer style={{ width: width ?? '100%', maxWidth: maxWidth ?? '100%' }}>
      <Styles.MinimalistInputWrapper>
        {label && <label htmlFor={id}>{label}</label>}
        <Styles.MinimalistInputItself
          id={id}
          hasError={errorMessage !== undefined && errorMessage.length > 0}
          {...rest}
        />
      </Styles.MinimalistInputWrapper>

      {errorMessage !== undefined && errorMessage.length > 0 && (
        <Styles.MinimalistInputErrorMessage>{errorMessage}</Styles.MinimalistInputErrorMessage>
      )}
    </Styles.MinimalistInputContainer>
  )
}
