import styled from 'styled-components'

export const Container = styled.div<any>`
  text-align: center;

  img {
    width: ${props => (props.isMobile ? '100%' : '100%')};
  }

  .content {
    margin: 8rem 0;
    padding: 0 ${props => (props.isMobile ? '1rem' : '15rem')};

    h1 {
      margin-bottom: 2rem;
      font-weight: 800;
    }
  }
`
