import { Input } from 'design-cannect'
import { Symptom, SymptomType, useCannectCare } from 'hooks/useCannectCare'
import { useState } from 'react'
import EpisodesSelector from '../EpisodesSelector'
import IntensitySelector from '../IntensitySelector'
import * as Style from './styles'
import ToggleType from './ToggleType'

interface OtherDiseaseOccurrenceProps {
  symptom: Symptom
}

export default function OtherDiseaseOccurrence({ symptom }: OtherDiseaseOccurrenceProps) {
  const { changeOtherSymptomName, changeOtherSymptomType } = useCannectCare()
  const [selectedType, setSelectedType] = useState<SymptomType>('episodes')

  function handleTypeChange() {
    if (selectedType === 'episodes') {
      setSelectedType('intensity')
      changeOtherSymptomType(symptom.id, 'intensity')
    } else {
      setSelectedType('episodes')
      changeOtherSymptomType(symptom.id, 'episodes')
    }
  }

  return (
    <Style.OtherDiseaseOccurrenceContainer>
      <h4>Outro</h4>
      <Input
        width="100%"
        value={symptom.label}
        placeholder="Nome do sintoma"
        onChange={event => changeOtherSymptomName(symptom.id, event.target.value)}
      />
      <ToggleType type={selectedType} onTypeChange={() => handleTypeChange()} />
      {symptom.type === 'episodes' ? (
        <>
          <p>
            Quantos episódios você experimentou <strong>ontem</strong>?
          </p>
          <EpisodesSelector symptom={symptom} isExistingSymptom={false} />
        </>
      ) : (
        <>
          <p>
            Em uma escala de 0, que significa nenhuma a 10, alta intensidade, como você classifica seu sintoma{' '}
            <strong>ontem</strong>?
          </p>
          <IntensitySelector symptom={symptom} isExistingSymptom={false} />
        </>
      )}
    </Style.OtherDiseaseOccurrenceContainer>
  )
}
