import { useCannectAssistant } from 'hooks/useCannectAssistant'
import ChooseCertificateButton from './ChooseCertificateButton'
import * as Style from './styles'

type ChooseCertificateProps = {
  onCertificateChoose: (choice: string) => void
  handleAnotherSignature?: () => void
}

export default function ChooseCertificate({ onCertificateChoose, handleAnotherSignature }: ChooseCertificateProps) {
  const { changeDigitalSignature } = useCannectAssistant()

  const handleCertificateType = (signatureType: string) => {
    onCertificateChoose(signatureType)
    changeDigitalSignature(true)
  }

  return (
    <Style.ChooseCertificateContainer>
      <h1>Assinatura Digital</h1>
      <p style={{ marginBottom: '16px' }}>Esta prescrição só terá validade se for assinada.</p>

      <ChooseCertificateButton label="BirdID" onClick={() => handleCertificateType('birdID')} />
      <ChooseCertificateButton label="VidaaS" onClick={() => handleCertificateType('vidaasQrCode')} />
      <ChooseCertificateButton label="Vou assinar de outra forma" onClick={handleAnotherSignature} />
    </Style.ChooseCertificateContainer>
  )
}
