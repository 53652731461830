import SchedulingRefundImage from 'assets/img/scheduling-policy-image.png'

export const PrivacyText = `
<p>Termos de Uso</p>
<br />
<p>Seja bem-vindo (a) !</p>
<br />

<p>Ficamos muito felizes por <strong>Você </strong>escolher a Plataforma <strong>CANNECT !</strong></p>
<br />

<p>A Plataforma <strong>CANNECT </strong>oferece a <strong>Você</strong>, através de seus sites na internet e seus subdomínios, consultoria para acesso ao produto derivado de cannabis, mediante solicitação de formulário para a importação junto a ANVISA (Agência Nacional de Vigilância Sanitária) e intermediação com o fornecedor, para acompanhar o procedimento do pedido de compra e coordenar e rastrear a entrega.</p>
<p>Por isso, criamos este Termo, que irá regular a relação para utilização da Plataforma<strong>, </strong>permitindo que <strong>Você </strong>tenha acesso ao conteúdo e serviços da <strong>CANNECT</strong>, disponibilizada via Internet para determinados dispositivos conectados, tais como computadores, aparelhos móveis e/ou outros aparelhos compatíveis (“Tablets e Smartphones”).</p>
<p>A <strong>CANNECT</strong> segue as determinações legais vigentes, bem como as determinações da ANVISA (Agência Nacional de Vigilância Sanitária), nos termos da RDC 660/2022 e demais aplicáveis.</p>
<p>Nossos procedimentos de obtenção e utilização de informações pessoais dos usuários da Plataforma está disciplinada na Política de Privacidade localizada em<a href="https://www.cannect.life/politica-de-privacidade">https://www.cannect.life/politica-de-privacidade</a></p>
<p><br /><br /></p>
<p>Ao utilizar a Plataforma, <strong>Você</strong> está concordando com este Termo. Por isso, é importante que <strong>Você</strong> o leia com muita atenção.</p>
<p><strong>Capítulo I – CONDIÇÕES GERAIS</strong></p>
<p><strong>1.1. Âmbito</strong>. O presente Termo e Condições Gerais de Uso (denominado somente “Termo” ou “Termo de Uso”) vinculam todas as atividades desenvolvidas e serviços oferecidos pela <strong>CANNECT </strong>(doravante assim denominada ou somente “Plataforma”), que engloba o website e seus subdomínios, aplicativos, programas e demais extensões. A Plataforma é de propriedade de <strong>CANNECT SERVIÇOS DE INTERNET S/A</strong>, pessoa jurídica inscrita no CNPJ sob o nº 41.418.943/0001-16, com sede na Rua Doutor Renato Paes de Barros, n.º 33, 8º andar, Bairro Itaim Bibi, São Paulo/SP, CEP 04.530-000 (“<strong>CANNECT</strong>”), e está sob sua responsabilidade e gestão.</p>
<p><strong>1.2. Atuação</strong>. A Plataforma desenvolvida é de propriedade exclusiva da <strong>CANNECT</strong> e oferece consultoria para acesso ao produto derivado de cannabis, mediante solicitação de formulário para a importação junto a ANVISA (Agência Nacional de Vigilância Sanitária) e intermediação com o fornecedor, para acompanhar o procedimento do pedido de compra e coordenar e rastrear a entrega.</p>
<p><strong>1.2.1.</strong> Os Pacientes, por intermédio da plataforma <strong>CANNECT</strong>, poderão buscar Profissionais de Saúde para a prestação dos seguintes serviços: </p>

<li>I Cannect Cuida;</li>

<p>Programa de cuidado coordenado que conta com um time de especialistas da saúde dedicado ao acompanhamento do paciente sob prescrição de Cannabis medicinal.</p>

<li>II Cannect Gene;</li>

<p>Utilizando tecnologias avançadas de medicina de precisão, o Cannect Gene realiza a testagem para o metabolismo dos canabinóides e potenciais respostas do organismo nos sistemas relacionados a sobrepeso, obesidade e propensão à dependência quimica</p>

<li>III Cannect Produtos; </li>

<p>Nosso marketplace, que contém os principais produtos do mercado, com critérios pré estabelecidos de qualidade e confiabilidade.</p>

<li>IV Cannect Profissionais.<br />Nesta função nossos pacientes podem encontrar profissionais parceiros que estão aptos a trabalhar com cannabis medicinal.<br /><br /></li>

<p><strong>1.2.2.</strong> A Plataforma se destina apenas aos serviços descritos na cláusula acima.</p>
<p><strong>1.3. Possibilidade de Integração.</strong> A<strong> CANNECT </strong>pode, a qualquer momento, integrar a sua Plataforma com outras, podendo o Usuário utilizar o mesmo cadastro em cada uma delas.</p>
<p><strong>1.4. Definições. </strong>As palavras e expressões abaixo descritas, quando empregadas nestes Termos de Uso, terão os seguintes significados:</p>
<p>“<strong>USUÁRIO” (Você)</strong>: Pessoas físicas maiores de 18 anos, que possuem prescrição emitida por profissional legalmente habilitado para uso de produto derivado de cannabis e que se cadastram, gratuitamente, na Plataforma. No caso de pessoas físicas menores de idade e incapazes, o uso da plataformadeverá ser restrito, sob as condições de ser assistido ou representado, conforme norma civil aplicável.</p>
<p>“<strong>ANVISA</strong>”: significa a Agência Nacional de Vigilância Sanitária, agência reguladora, sob a forma de autarquia de regime especial, vinculada ao Ministério da Saúde do Brasil;</p>
<p>“<strong>RDC 660/2022</strong>”: significa a Resolução ANVISA <strong>Nº 660, de 30 de março de 2022 </strong>e correspondentes alterações;</p>
<p>“<strong>CPF</strong>”: significa o Cadastro da Pessoa Física perante a Secretaria da Receita Federal brasileira;</p>
<p>“<strong>FORMULÁRIO”</strong>: ato exercido pela ANVISA, por meio da emissão de documento que autoriza a importação de Produto derivado de Cannabis por pessoa física, para uso próprio para tratamento de saúde, além do seu respectivo cadastro na ANVISA;</p>
<p>“<strong>PRODUTO DERIVADO DE CANNABIS”</strong>: produto industrializado, destinado à finalidade medicinal, contendo derivados da planta Cannabis spp.</p>
<p><strong>1.5. Adesão e Aceite</strong>. Pelo presente instrumento, <strong>Você</strong> concorda que: </p>
<p>1.5.1. Deverá aceitar os Termos da <strong>CANNECT</strong>, para fins de cadastro, controle de acesso e execução de melhorias na Plataforma;</p>
<p>1.5.2. Clicando na caixa de aceitação dos Termos deve aderir e concordar em se submeter ao disposto no presente instrumento.</p>
<p>1.5.3 Concorda em fornecer seus dados para o cadastro na CANNECT e com as finalidades e objetivos aqui determinados, bem como na Política de Privacidade.</p>
<p><strong>1.6. Violação das Condições</strong>. Caso <strong>Você </strong>venha a descumprir qualquer das condições aqui previstas, a <strong>CANNECT </strong>reserva-se o direito de encerrar sua conta e suspender ou anular seu acesso à Plataforma, sem necessidade de aviso prévio.</p>
<p><strong>1.7. Modificações</strong>. A <strong>CANNECT </strong>se reserva o direito de, a qualquer momento, modificar o Termo de Uso, sempre com o envio de avisos a <strong>Você</strong>, para ciência, sem qualquer responsabilização da <strong>CANNECT </strong>devido a quaisquer modificações efetuadas na Plataforma, que entrarão em vigor imediatamente após sua publicação <em>online</em>.</p>
<p><strong>1.8. Alterações e descontinuidades</strong>. A <strong>CANNECT </strong>poderá, a qualquer momento, de acordo com a sua disponibilidade negocial, alterar qualquer aspecto da Plataforma, inclusive quanto às suas características ou funcionalidades, bem como suspender, cancelar ou descontinuá-lo, no todo ou em parte, temporariamente ou em caráter definitivo, a seu critério exclusivo, mediante comunicação a <strong>Você</strong>, sem que isso caracterize inadimplemento deste Termo, ou direito de indenização ou multa em favor do mesmo, salvo estorno de valores que porventura tenham sido adimplidos antecipadamente.</p>
<p><strong>1.8.1</strong>. O serviço que <strong>Você</strong> escolher permanecerá sem alterações até a data de encerramento de sua vigência.</p>
<p><strong>1.9. Procuração para Solicitação de Formulário junto a ANVISA. Você </strong>deverá outorgar procuração com poderes especiais para a<strong> CANNECT </strong>representá-lo perante Instituições Governamentais, mais especificamente paraacessar o Portal de Serviços do Governo Federal, obter e consultar informações necessárias à obtenção dos documentos requeridos para importação dos produtos pretendidos, como também parasolicitar o formulário de importação de produto derivado de cannabis em seu nome.</p>
<p><strong>1.9.1</strong>. <strong>Você</strong> irá outorgar poderes específicos para que a <strong>CANNECT</strong> informe, no Portal de Serviços do Governo Federal, os dados solicitados no formulário de “Autorização para Importação de Canabidiol”, tais como:</p>
<p><strong>(i)</strong> Dados pessoais do Paciente/Responsável Legal (quando houver), como nome completo, número do documento de identificação, data de nascimento e endereço e demais informações que venham a ser solicitadas;</p>
<p><strong>(ii)</strong> Dados do produto, como nome comercial, composição e empresa fabricante, e demais informações que venham a ser solicitadas;</p>
<p><strong>(iii)</strong> Dados do prescritor do produto, tais como nome do profissional legalmente habilitado, especialidade, número de cadastro no Conselho/UF, e-mail, telefone e anexação da receita médica, e demais informações que venham a ser solicitadas; e</p>
<p><strong>(iv)</strong><strong>Você </strong>outorga poderes específicos para que a<strong> CANNECT</strong> proceda à assinatura do termo de responsabilidade, solicitado no formulário de “Autorização para Importação de Canabidiol”, e demais assinaturas que venham a ser solicitadas.</p>
<p><strong>1.9.2.</strong><strong>Você</strong> concorda que, ao outorgar poderes acima especificados, por uma limitação tecnológica do Portal de Serviços do Governo Federal, é possível que haja o acesso, por parte da <strong>CANNECT</strong>, de outros dados ali cadastrados, mas que não serão utilizados, para nenhum outro fim diverso do que o já exposto.</p>
<p><strong>1.9.2.</strong> Caso <strong>Você</strong> prefira realizar, pessoalmente, o processo de formulário junto à Anvisa, precisará enviar apenas a autorização emitida pelo órgão, para continuar o processo de compra dos medicamentos através da plataforma. </p>
<p><strong>Capítulo II – MODO DE UTILIZAÇÃO</strong></p>
<p><strong>2.1. Cadastro</strong>. A Plataforma <strong>CANNECT</strong> ficará disponível para <strong>Você </strong>através de seus websites e subdomínios. Ao acessar, <strong>Você </strong>se cadastrará, colocando as informações necessárias para o uso, tais como: nome, e-mail e senha.</p>
<p>2.1.1. Os dados coletados serão tratados conforme finalidades e bases legais descritas na Política de Privacidade.</p>
<p><strong>2.2. Dinâmica.</strong> Após <strong>Você </strong>realizar o Cadastro, terá acesso a Plataforma da <strong>CANNECT, </strong>que dispõe de um número limitado de conteúdos gratuitos. Caso queira, <strong>Você </strong>poderá realizar o pagamento para ter acesso à consultoria para acesso ao produto derivado de cannabis.</p>
<p><strong>2.2.1. Comunicação. </strong>As notificações e comunicação entre <strong>Você </strong>e a <strong>CANNECT serão</strong>  realizadas  pelo e-mail cadastrado por <strong>Você</strong> e notificação push.</p>
<p><strong>2.3. Incorreção de Informações Cadastrais</strong>. Para acessar e utilizar as funcionalidades da Plataforma, <strong>Você </strong>deverá prestar as informações exigidas no momento do cadastro, conforme disposto na Política de Privacidade.</p>
<p><strong>2.3.1.</strong><strong>Critérios de recusa</strong>. A Plataforma<strong> CANNECT</strong> se reserva o direito de impedir, a seu critério, novos cadastros, ou cancelar os já efetuados, em caso de ser detectada anomalia que considere, em sua análise, revestida de erro grave ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todo Usuário, sem que este seja ressarcido das quantias já pagas.</p>
<p><strong>2.4. Uso de Cadastro por Terceiros</strong>. Os cadastros na Plataforma são individuais e intransferíveis, assim, <strong>Você </strong>deverá imediatamente notificar a <strong>CANNECT </strong>sobre qualquer uso não autorizado de sua conta, em tempo hábil, não sendo a <strong>CANNECT </strong>responsável por quaisquer prejuízos ou danos oriundos do uso de “login” e senha por terceiro, com ou sem o seu consentimento.</p>
<p><strong>2.4.1.</strong><strong>Cuidados a serem observados</strong>. Para utilização adequada da Plataforma, <strong>Você </strong>deverá: (i) Ser cuidadoso com os dados de sua identificação individual sempre que acessar a Internet; (ii) Tomar outras medidas necessárias para se protegerem de danos, inclusive fraudes <em>online</em> e <em>off-line</em>; (iii) Ser diligente no uso do conteúdo disponibilizado na Plataforma da<strong> CANNECT</strong>, sabendo de suas responsabilidades; e (iv) Cumprir rigorosamente todas as determinações deste Termo.</p>
<p><strong>2.5. Limitações de Uso</strong>.A licença de uso da Plataforma é concedida de forma limitada, não customizável, não exclusiva, intransferível e não passível de sub licenciamento, cessão, de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, os serviços contratados através destes Termos, incluindo acessar e visualizar os conteúdos pela internet, exclusivamente para uso pessoal, sem fins comerciais e desde que integralmente atendidas às condições previstas neste Termo.</p>
<p><strong>2.5.1. Finalidade.</strong> Dessa forma, <strong>Você </strong>não poderá utilizar nem permitir o uso da Plataforma para qualquer outra finalidade que não seja aquela descrita no presente Termo. Caso seja constatada alguma ofensa ao Termo e a Política de Privacidade, a Plataforma da<strong> CANNECT </strong>poderá suspender o seu acesso por completo, sem que isso implique no inadimplemento deste Termo, de modo que <strong>Você </strong>deverácontinuar cumprindo com tod</p>
<p>as as suas obrigações, até que se verifique ou sane, no prazo de 15 (quinze) dias, a irregularidade constatada.</p>
<p><strong>2.6. Obrigações do Usuário</strong>. <strong>Você </strong>tem as seguintes responsabilidades:</p>
<ul>
<li><strong>Não utilizar a Plataforma <strong>CANNECT </strong>para qualquer propósito que seja ilícito ou proibido pelos Termos e/ou pelas normas aplicáveis;</strong></li>
</ul>
<ul>
<li>Autorizar que a <strong>CANNECT </strong>possa enviar comunicações por e-mail, telefone ou mensagens instantâneas, inclusive de caráter publicitário;</li>
</ul>
<ul>
<li>Apresentar comportamento honesto e de boa fé;</li>
</ul>
<ul>
<li>Informar corretamente e com precisão todos os seus dados, para fins cadastrais;</li>
</ul>
<ul>
<li>Não compartilhar sua senha com terceiros, a fim de evitar o uso indevido dos serviços por terceiros;</li>
</ul>
<ul>
<li>Responder pelas informações inseridas e mantidas na Plataforma, pelo cadastramento, permissões e modo de utilização, isentando a <strong>CANNECT </strong>de toda e qualquer responsabilidade com relação ao conteúdo inserido e sua forma de utilização;</li>
</ul>
<ul>
<li>Fornecer todas as informações e documentos necessários para a realização da consultoria para acesso ao produto derivado de cannabis;</li>
</ul>
<ul>
<li>Assinar a procuração anexa a estes Termos, no intuito de outorgar poderes especiais a <strong>CANNECT </strong>para o pedido do formulário para importação junto a ANVISA;</li>
</ul>
<ul>
<li>Pagar o valor referente aos serviços contratados.</li>
</ul>
<p><strong>2.7.</strong><strong>Obrigações da</strong><strong>CANNECT</strong>. A <strong>CANNECT</strong> têm as seguintes responsabilidades:</p>
<ul>
<li><strong>Requisitar o formulário para a importação do produto derivado de cannabis junto a ANVISA;</strong></li>
</ul>
<ul>
<li>Intermediar a relação do Usuário com uma clínica médica / médico, para este realizar a consulta e, caso o profissional entenda adequado, a prescrição de produto derivado de cannabis;</li>
</ul>
<ul>
<li>Intermediar a relação do Usuário com o fornecedor, para efetuar o pedido de compra do produto derivado de cannabis; e</li>
</ul>
<ul>
<li>Coordenar e rastrear o pedido.</li>
</ul>
<p><strong>2.7.1.</strong> A <strong>CANNECT </strong>não realiza consulta médica, e nem possui médicos que revisam as prescrições emitidas aos Usuários.</p>
<p><strong>2.7.2.</strong> A <strong>CANNECT </strong>não vende o produto derivado de cannabis. O fornecedor fatura o pedido diretamente para o Usuário.</p>
<p><strong>2.8. Idoneidade</strong>. <strong>Você</strong> declara, ao consentir com este documento para utilizar a Plataforma, que detém idoneidade incólume, ficando ciente, desde já, que as informações cadastrais falsas ou inverídicas registradas ou fornecidas durante o processo eletrônico de contratação de serviços podem constituir crime.</p>
<p><strong>Capítulo III – ACESSO E RESTRIÇÕES DE FUNCIONALIDADE</strong></p>
<p><strong>3.1. </strong>Todas aplicações tecnológicas envolvidas no funcionamento da plataformaestão protegidas por direitos autorais e de propriedade industrial de exclusiva autoria, de modo que a licença de utilização da plataforma não configura qualquer cessão ou transmissão de direitos.</p>
<p><strong>3.2. Restrições</strong>. Não é permitido acessar as áreas de programação da Plataforma <strong>CANNECT</strong>, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de <em>webmastering</em>, realizar ou permitir que se realize qualquer tipo de engenharia reversa, tradução, decompilação, cópia, modificação, reprodução, locação, sublocação, sub licenciamento, publicação, divulgação, transmissão, empréstimo, distribuição ou, de qualquer outra forma, a disposição de ferramentas de consulta deste website, aplicativos, programas e de suas funcionalidades para terceiros sem a prévia e expressa anuência da <strong>CANNECT</strong>, ficando sujeito quem o fizer a penalidades da legislação brasileira, sem prejuízo da obrigação de reparar os danos que causarem. Esta restrição inclui qualquer tentativa de incorporar quaisquer informações da plataforma em qualquer outro diretório, produto ou serviço.</p>
<p><strong>Capítulo IV – REMUNERAÇÃO E CANCELAMENTO </strong></p>
<p><strong>4.1. Remuneração</strong>. Para utilização do serviço de consultoria para acesso ao produto derivado de cannabis, <strong>Você </strong>poderá realizar o pagamento através da plataforma Pagar.me.</p>
<p><strong>4.1.1. Responsabilidade de pagamento.</strong> Desde já, <strong>Você </strong>está ciente que o pagamento e a aprovação deste se dará pela plataforma Pagar.me, não havendo qualquer ingerência e, consequentemente, responsabilidade da <strong>CANNECT</strong>.</p>
<p><strong>4.2. Cancelamento do serviço</strong>. <strong>Você </strong>poderá cancelar seu cadastro na Plataforma a qualquer tempo por meio do endereço eletrônico faleconosco@cannect.com.br.</p>
<p><strong>4.2.1. Desobrigação de reter dados</strong>. <strong>Você </strong>reconhece e concorda que a Plataforma <strong>CANNECT </strong>não possui obrigação de reter ou manter arquivados quaisquer dados/conteúdo de sua propriedade armazenados na Plataforma <strong>CANNECT</strong> após o término da relação contratual.</p>
<p><strong>4.2.2</strong>. <strong>Pendências</strong>. Caso haja valores em aberto, o interessado no cancelamento deverá efetuar a sua quitação para que seja efetuado. Se, a qualquer tempo, <strong>Você</strong> deixar de efetuar o pagamento na data devida, o seu acesso será bloqueado a qualquer momento e seu Plano poderá ser cancelado.</p>
<p><strong>Capítulo V – EXCLUSÃO DE GARANTIAS</strong></p>
<p><strong>5.1. Disponibilidade</strong>. A Plataforma está disponível para o seu uso e é oferecida “no estado em que se encontra” e “conforme disponível”, de acordo com o Plano que <strong>Você</strong> escolher. A <strong>CANNECT </strong>declina qualquer responsabilidade por garantias implícitas de comerciabilidade, adequação a um determinado fim, de retorno financeiro e qualquer responsabilidade pelas perdas de dados em caso de problemas no backup efetuado por <strong>Você</strong>. Além disso, a <strong>CANNECT </strong>não garante que os serviços serão fornecidos sem interrupções ou erros. Sendo assim, <strong>Você </strong>aceita que todo o risco decorrente da utilização da Plataforma <strong>CANNECT</strong> é de sua exclusiva responsabilidade.</p>
<p><strong>Capítulo VI – LIMITAÇÃO DE RESPONSABILIDADE </strong></p>
<p><strong>6.1. Limitações e Vedações. </strong>A relação entre as partesé pautada pelas seguintes limitações de responsabilidades e vedações: </p>
<ul>
<li><strong>A <strong>CANNECT </strong>não se responsabiliza pelo cumprimento, pelo usuário, de exigências, limitações ou restrições legais e/ou vedações impostas pela legislação aplicável quanto aos produtos derivados de cannabis.</strong></li>
</ul>
<ul>
<li>A <strong>CANNECT </strong>não fornece qualquer assistência médica, não viabiliza a realização de consultas médicas presenciais e não pratica a telemedicina, como também não se responsabiliza pela recomendação de uso de produtos derivados de cannabis em prescrições emitidas por quaisquer profissionais de saúde.</li>
</ul>
<ul>
<li>A <strong>CANNECT </strong>não comercializa nenhum produto derivado de cannabis, como também não se responsabiliza pela logística de importação, expedição, aprovação, e entrega do produto, que será realizada diretamente pelo fornecedor, sendo a <strong>CANNECT</strong> responsável apenas pela requisição do pedido de compra e rastreamento do pedido.</li>
</ul>
<ul>
<li>A <strong>CANNECT </strong>não será responsável por quaisquer danos, prejuízos ou perdas relacionadas ao produto derivado de cannabis durante o serviço de transporte executado pelo fornecedor.</li>
</ul>
<ul>
<li>Em toda a extensão permitida nos termos da lei aplicável, a <strong>CANNECT </strong>não confere quaisquer garantias, expressas ou implícitas, incluindo, mas não se limitando a garantias de comerciabilidade, qualidade, adequação a uma finalidade específica, validade, eficácia dos produtos derivados de cannabis.</li>
</ul>
<ul>
<li>Em nenhuma hipótese a <strong>CANNECT</strong> será responsável por quaisquer danos de qualquer natureza decorrentes do uso abusivo, incapacidade de uso, resultados do uso ou quaisquer efeitos colaterais em razão do uso de produto derivado de cannabis.</li>
</ul>
<ul>
<li>A <strong>CANNECT </strong>ressalta, nos termos do artigo 15 da RDC 660/2022, que é vedada a alteração de finalidade da importação de produtos derivados do canabidiol. o usuário reconhece que o uso de tais produtos é estritamente pessoal e intransferível, sendo proibida a entrega a terceiros, doação, venda ou qualquer outra utilização diferente da indicada.</li>
</ul>
<p><strong>6.2. Atendimento integral</strong>.A <strong>CANNECT </strong>não garante a <strong>Você </strong>que: (a) as funções contidas na Plataforma atenderão plenamente as suas necessidades; (b) que a operação da Plataforma será ininterrupta ou livre de erros; ou (c) que a Plataforma será compatível ou funcione com qualquer outro software, aplicações ou serviços de terceiros. Dessa forma, a <strong>CANNECT </strong>responderá exclusiva e limitadamente pelos serviços por si prestados.</p>
<p><strong>6.2.1.</strong><strong>Independência entre as partes</strong>. A <strong>CANNECT </strong>reitera que não constitui com <strong>Você</strong> nenhum tipo de: (i) sociedade, associação ou representação; (ii) prestação de qualquer serviço diverso do objeto deste instrumento particular, e/ou (iii) relação empregatícia.</p>
<p><strong>6.3. Responsabilidade</strong>. A <strong>CANNECT </strong>não será responsável por quaisquer perdas ou danos sofridos, resultantes de: (i) qualquer informação errada ou incompleta que seja fornecida por<strong> Você</strong>; (ii) qualquer fraude, declaração fraudulenta ou violação do dever, ou violação de qualquer uma das condições por outrosUsuários; (iii) falhas nas conexões; (iv) problemas no processamento de dados por culpa de terceiros; (v) produtos ou serviços de terceiros, mesmo que adquiridos através da Plataforma; (vi) pelo conteúdo inserido por <strong>Você</strong>; (vii) por danos ou prejuízos decorrentes de decisões tomadas com base nas informações fornecidas pela Plataforma; e (viii) por problemas definidos como “caso fortuito” ou “força maior” contemplados pelo artigo 393 do Código Civil Brasileiro.</p>
<p><strong>6.4. Violação de Direito de Terceiros</strong>. A <strong>CANNECT </strong>não se responsabilizará perante qualquer Usuárioem relação a qualquer violação de direito de terceiros.</p>
<p><strong>6.5. Danos</strong>. Em nenhum caso, a <strong>CANNECT </strong>será responsável por danos indiretos, incidentais, especiais, exemplares, punitivos ou consequentes, incluindo, danos pessoais ou à propriedade, relacionados com, ou de outra forma resultantes, de qualquer uso da Plataforma que <strong>Você </strong>possa sofrer devido às ações realizadas ou não realizadas através desta, ainda que decorrentes da conduta de terceiros.</p>
<p><strong>6.6. Indenização</strong>. <strong>Você </strong>deverá indenizar a <strong>CANNECT</strong>, seus diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por terceiros decorrentes de suas atividades na Plataforma, e pelo descumprimento dos termos de uso e condições e demais políticas da mesma, e ainda pela violação de qualquer lei ou direitos de terceiros, incluindo honorários advocatícios.</p>
<p><strong>Capítulo VII – INATIVAÇÃO DO CADASTRO, DÚVIDAS E SUGESTÕES</strong></p>
<p><strong>7.1. Inativação Do Cadastro, Dúvidas e Sugestões</strong>. Em caso de dúvidas ou sugestões relacionadas a este Termo de Uso da Plataforma ou quaisquer outras informações, <strong>Você</strong> pode entrar em contato com os administradores através do e-mail: faleconosco@cannect.com.br, disponível na Plataforma, que será respondido em horário comercial, de segunda-feira a sexta-feira, das 9h00 às 18h00.</p>
<p><strong>7.2.</strong><strong>Vedação e inativação de cadastro</strong>. Como forma de manter a confiança e respeito nesta Plataforma, serão vedados atos ilegais ou incompatíveis com a proposta de zelar pela prestação dos serviços de qualidade. Assim, poderá resultar em suspensão ou inativação do seu cadastro caso <strong>Você </strong>incorra em qualquer das hipóteses elencadas abaixo:</p>
<ul>
<li><strong>Promoção da violência e/ou discriminação, baseada em questões de raça, sexo, religião, nacionalidade, orientação sexual ou de qualquer outro tipo;</strong></li>
</ul>
<ul>
<li>Violação de quais leis, principalmente no que tange aos direitos autorais, propriedade intelectual, tais como cópias não autorizadas, utilização de imagens, sons, movimentos ou textos sem anuência do autor, sejam elas marcas, réplicas e/ou falsificações;</li>
</ul>
<ul>
<li> Violação do disposto na Política de Privacidade da Plataforma;</li>
</ul>
<ul>
<li> Não utilização da Plataforma por mais de 6 (seis) meses.</li>
</ul>
<p><strong>Capítulo VIII – ENGENHARIA DA PLATAFORMA</strong></p>
<p><strong>8.1. Aperfeiçoamento</strong>. <strong>Você </strong>concorda que todo e qualquer sistema, plataforma ou serviço, independentemente de seu produtor ou características, é uma espécie de produto que está sempre em atualização e aperfeiçoamento, possuindo, constante e incondicionalmente, aspectos a serem melhorados, o que não pode ser considerado em si como falha ou defeito.</p>
<p><strong>8.2. Erros de Funcionamento</strong>. Eventuais erros no funcionamento da Plataforma serão corrigidos o mais breve possível, durante o período que for necessário para manutenção. A <strong>CANNECT </strong>não pretende que o funcionamento do servidor da Plataforma, bem como de seu sistema, do seu banco de dados, software e de seu website, sejam livres de erros, falhas ou de interrupções.</p>
<p><strong>Capítulo IX – PROPRIEDADE INTELECTUAL </strong></p>
<p><strong>9.1. Utilização da Propriedade Intelectual</strong>. O uso comercial da expressão “<strong>CANNECT</strong>”, como marca, nome empresarial ou nome de domínio, e o logotipo são de propriedade exclusiva dos proprietários da <strong>CANNECT</strong>, conforme disposto no respectivo ato constitutivo da organização. Da mesma forma, direitos autorais e outros direitos passíveis de proteção por propriedade industrial como estrutura, os conteúdos das telas relativas à Plataforma, assim como os programas relacionados a mesma, software, website, bancos de dados, redes e arquivos de todos os domínios da <strong>CANNECT</strong>. Todos os direitos estão protegidos, no Brasil e internacionalmente por leis e tratados internacionais de direito autoral, marcas, patentes e desenhos industriais. Aos proprietários da <strong>CANNECT </strong>reservam-se todos os direitos relativos a autorias, marcas, patentes e desenhos industriais, quer sejam de sua propriedade ou licenciados em seu favor, a si.</p>
<p><strong>9.1.1.</strong><strong>Vedação</strong>. Não é permitido reproduzir, duplicar, copiar, vender, revender, visitar, ou de qualquer outra forma explorar para fins comerciais o conteúdo da Plataforma sem o prévio consentimento escrito dos proprietários da <strong>CANNECT</strong>. Particularmente, é expressamente proibida a utilização de mineração de dados, robôs, ou outras ferramentas de coleta e extração de dados para extrair, de forma isolada ou recorrente, qualquer parte substancial da Plataforma visando sua reutilização.</p>
<p><strong>9.2. Relações com Terceiros. </strong>A Plataforma poderá fornecer links de acesso a outros websites, o que não significa que esses sites sejam de propriedade ou operados pela <strong>CANNECT</strong>. Como não possui controle sobre esses sites, a <strong>CANNECT </strong>não será responsável pelos conteúdos, práticas e serviços ofertados nos mesmos. A presença de links para outros sites não implica relação de sociedade, de supervisão, de cumplicidade ou solidariedade da <strong>CANNECT </strong>para com esses sites e seus conteúdos, salvo exceção que será expressamente divulgada.</p>
<p><strong>Capítulo X – CONDIÇÕES FINAIS </strong></p>
<p><strong>10.1. Suspensão e Cancelamento do Acesso</strong>. Na eventualidade de <strong>Você </strong>descumprir qualquer disposição do Termo, desde já, reconhece e aceita que a <strong>CANNECT </strong>pode, sem aviso prévio, interromper ou suspender, temporária ou permanentemente, parte ou a totalidade, do seu acesso à Plataforma.</p>
<p><strong>10.2. Advertências e suspensão</strong>. Sem prejuízo de outras medidas cabíveis, a <strong>CANNECT </strong>poderá te advertir, suspender, temporária ou definitivamente seu acesso, a qualquer tempo, se <strong>Você</strong>:</p>
<ul>
<li><strong>não cumprir com qualquer dispositivo do Termo ou da Política de Privacidade;</strong></li>
</ul>
<ul>
<li>descumprir com quaisquer de suas obrigações ou proibições assumidas ao utilizar os serviços da plataforma;</li>
</ul>
<ul>
<li>utilizar, sem autorização, nome e/ou marca da <strong>CANNECT</strong>, em parte ou na sua totalidade, e violar direitos de propriedade intelectual da <strong>CANNECT</strong>;</li>
</ul>
<ul>
<li>não oferecer condições que possibilitem a verificação da sua identidade ou se qualquer informação fornecida estiver incorreta.</li>
</ul>
<p><strong>10.3. Tratamento dos Dados Pessoais</strong>. Para que a Plataforma tenha qualidade e para que <strong>Você </strong>possa obter resultados de forma rápida e segura, é necessário o fornecimento de dados pessoais, nos termos dispostos na Política de Privacidade da Plataforma <strong>CANNECT</strong>.</p>
<p><strong>10.4. Nulidades</strong>. Se qualquer cláusula deste instrumento for considerada ilegal, inválida ou inaplicável, no seu todo ou em parte, ao abrigo de qualquer lei, essa cláusula ou parte do mesmo será nesta exata medida entendida como não fazendo parte dos Termos de Uso, sendo que a legalidade, validade e aplicabilidade das demais cláusulas não serão afetadas. </p>
<p><strong>Capítulo XI – DA PROCURAÇÃO</strong></p>
<p><strong>11.1.</strong> Você autoriza a CANNECT a lhe representar perante Instituições governamentais, com poderes especiais para acessar o Portal de Serviços do Governo Federal, obter e consultar informações necessárias referentes à obtenção dos documentos requeridos para a importação dos produtos pretendidos e para solicitar o formulário de importação de produto derivado de cannabis no Portal de Serviços do Governo Federal em seu nome.</p>
<p><strong>11.2.</strong> Você confere poderes específicos para que a CANNECT informe, no Portal de Serviços do Governo Federal, os dados solicitados no formulário de “Autorização para Importação de Canabidiol” tais como:</p>
<p>(i) Dados pessoais do Paciente/Responsável Legal (quando houver), como nome completo, número do documento de identificação, data de nascimento e endereço e demais informações que venham a ser solicitadas;</p>
<p>(ii) Dados do produto, como nome comercial, composição e empresa fabricante, e demais informações que venham a ser solicitadas;</p>
<p>(iii) Dados do prescritor do produto, tais como nome do profissional legalmente habilitado, especialidade, número de cadastro no Conselho/UF, e-mail, telefone e anexação da receita médica, e demais informações que venham a ser solicitadas.</p>
<p><strong>11.3.</strong> Você confere, ainda, poderes específicos para que a CANNECT proceda à assinatura do termo de responsabilidade, solicitado no formulário de “Autorização para Importação de Canabidiol”, e demais assinaturas que venham a ser solicitadas.</p>
<p><strong>11.4</strong>. Pode, ainda, a CANNECT realizar todos os atos necessários para o bom cumprimento desta procuração, não podendo ser substabelecido. </p>
<p><strong>11.5.</strong> A presente procuração possui validade de 12 (doze) meses a contar da data do aceite deste Termo de Uso.</p>
<p><strong>Capítulo XII – LEI APLICÁVEL E JURISDIÇÃO</strong></p>
<p><strong>11.1.</strong><strong>Foro</strong>. Os presentes Termos de Uso são regidos pelas leis brasileiras, tendo como definido o foro da capital do Estado de São Paulo, como competente para dirimir eventuais controvérsias oriundas destes, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser, devendo-se dar prioridade, sempre, às tentativas de resolução por auto composição.</p>
<p><strong>Anexo I – DA IRREGULARIDADE DO CADASTRO DE PESSOAS FÍSICAS (CPF) E RATIFICAÇÃO DA ANVISA</strong></p>
<p>O paciente ao fazer a compra deve estar ciente que seu CPF esteja regular.</p>
<p>Caso haja irregularidade do CPF, a ANVISA solicita a regularização do CPF através da Receita Federal, com prazos estabelecidos e sendo informado pela Cannect.</p>
<p>O paciente deverá fazer a regularização do CPF junto a Receita Federal, para dar continuidade do processo, sendo de inteira responsabilidade do paciente.</p>
<p>Em caso de retorno ao estoque devido ao não cumprimento do prazo para envio da documentação adicional solicitada pela ANVISA, serão cobrados os custos do retorno do pacote de R$ 140,00 (cento e quarenta reais) e do custo de importação do reenvio de R$ 140,00 (cento e quarenta reais), totalizando R$ 280,00 (duzentos e oitenta reais) que deverá ser pago pelo paciente.</p>
<p><strong>Anexo I – POLÍTICA DE ESTORNO E/OU REENVIO DE PRODUTOS</strong></p>
<p><strong>DO ESTORNO</strong></p>
<p>O estorno deverá cumprir exatamente os termos que serão doravante apresentados.</p>
<p>O estorno poderá ocorrer pelos seguintes motivos:</p>
<ul>
<li>Defeitos de fabricação;</li>
<li>Produto em desacordo com o pedido;</li>
<li>Produto vencido;</li>
<li>Avaria (estrago) ocasionado no transporte;</li>
<li>Produto com lacre violado;</li>
<li>Produto com entrega atrasada, salvo em casos que não for constatada a culpa da Cannect;</li>
<li>Falecimento do paciente antes da entrega do produto;</li>
<li>Compra de medicamento, que durante o processo de efetivação desta, seja descontinuado pelo fabricante.</li>
<li>Extravio do produto, desde que comprovado pela transportadora.</li>
</ul>
<p>A lista de compras deve se manter em conformidade com a prescrição médica.</p>
<p><strong>IMPORTANTE:</strong> Você deverá recusar o produto em qualquer uma das situações abaixo, bem como, poderá solicitar o estorno dos valores pagos:</p>
<ul>
<li>Embalagem aberta ou avariada;</li>
<li>Produto avariado;</li>
<li>Produto em desacordo com o pedido;</li>
<li>Pedido incompleto, exceto quando o pedido for enviado de forma desmembrada.</li>
</ul>
<br />
<br />
<p><strong>ESTORNO POR ARREPENDIMENTO OU DESISTÊNCIA</strong></p>
<br />

<p>MEDICAMENTOS DE USO CONTROLADO, CONFORME A LEI RDC 20/2011 CAP.VII ART. 20, ART. 44 DA PORTARIA 344/98 E ART. 90 DA PORTARIA 6/99 SVS/MS – SECRETARIA DE VIGILÂNCIA EM SAÚDE/MINISTÉRIO DA SAÚDE. NÃO PODEM SER DEVOLVIDOS/TROCADOS, MESMO SENDO COMPRA ONLINE OU FÍSICA.</p>
<p>Em caso de dúvidas, estas poderão ser esclarecidas através da Portaria n° 344/1988 e RDC n° 20/2021, no site da ANVISA ou através do telefone da Ouvidoria da Vigilância Sanitária: 0800 61 1997. </p>
<p><strong>PRODUTOS COM ENTREGA ATRASADA POR EXIGÊNCIAS DA ANVISA</strong></p>
<p>Informamos que não processaremos reembolsos devido a atrasos causados pela Agência Nacional de Vigilância Sanitária (Anvisa) nos processos de importação de medicamentos, principalmente, mas não exclusivamente, em caso de atraso por documento irregular apresentado por você. A regulamentação e a fiscalização da Anvisa são fundamentais para garantir a segurança e a qualidade desses produtos, mas, não temos controle sobre os prazos de aprovação e liberação. A Cannect se compromete em ser transparente quanto à orientação durante o processo de importação.</p>
<br />
<p><strong>DO REENVIO DE PRODUTOS</strong></p>
<br />
<p>Em caso do paciente decidir por receber o estorno dos valores pagos, poderá requerer o reenvio dos produtos, após a efetiva comprovação de descarte dos produtos recebidos, nos termos das Portarias n° 344/98 e 06/99 da Anvisa.</p>
<p>Neste caso, a Cannect não realizará a cobrança de novo custo de importação e cumprirá o mesmo prazo de entrega anteriormente informado.</p>
<br />
<br />
<p><strong>PROCEDIMENTOS E PRAZOS PARA REALIZAÇÃO</strong></p>
<br />

<p>Todas as ocorrências devem ser comunicadas à nossa Central de Relacionamento, com o prazo de 07 (sete) dias corridos do recebimento da mercadoria. Solicitações que estejam fora dessa comunicação, fora do prazo estabelecido, não serão aceitos. </p>
<p>De acordo com a legislação brasileira, no caso de produtos com defeito de fabricação, você tem o direito de solicitar a devolução em até 30 (trinta) dias, contados da data do recebimento do pedido no seu endereço.</p>
<p>Em cumprimento aos termos das portarias n° 344/98 e 06/99 em caso de interrupção do tratamento ou necessidade de devolução do produto por defeito de fabricação, o próprio paciente deverá entrar em contato com a Vigilância Sanitária de sua região para realizar o correto descarte dos produtos, sob pena de cometer crime sanitário. Note-se, esse ato não exclui a responsabilidade da Cannect em realizar a reposição do produto defeituoso, auxiliando no que for necessário para o correto descarte dos produtos.</p>
<p>Para realizar sua solicitação, envie um WhatsApp para (11) 9 8271-1992, informando seu nome completo, número de pedido e informações sobre o defeito de fabricação (descrição com fotos ou vídeos).</p>
<p>Toda solicitação é primeiramente analisada por toda equipe, para certificar de que está nas mesmas condições daquelas que foi vendida ou se realmente apresenta o defeito relatado.</p>
<p>Estabelecemos o prazo de 5 (cinco) dias úteis para análise do produto, elaboração do relatório de aceite ou recusa do produto para estorno.</p>
<p>A Cannect tem o compromisso e o interesse em realizar os estornos pelo menor tempo possível, contudo, em casos extremos, limitando-se ao prazo máximo de 30 (trinta) dias corridos a partir do recebimento da solicitação de estorno. </p>
<br />

<p><strong>MÉTODOS DE ESTORNO</strong></p>
<br />

<p>Toda e qualquer solicitação de estorno será efetuada no mesmo formato que foi efetuado o pagamento do pedido em nossa plataforma de venda, sendo assim, não é possível a escolha do formato do estorno.</p>
<p>Para pedido cancelado ou estornado a restituição de valores será realizada das seguintes formas:</p>
<p>Cartão de crédito: A solicitação de cancelamento será efetuada junto a administradora do cartão e o estorno deve ocorrer em até duas faturas subsequentes, de uma só vez, independentemente do número de parcelas escolhidas na compra.</p>
<p>Boleto bancário: O reembolso será realizado em até 15 dias úteis, através da conta corrente informada no ato da solicitação de devolução. Lembrando que a conta informada deve ser obrigatoriamente no nome do titular do pedido.</p>
<p>Pix: O reembolso será realizado em até 5 dias úteis, através da chave PIX informada no ato da solicitação de devolução. Lembrando que a chave PIX informada deve ser obrigatoriamente no nome do titular do pedido.</p>
<p>Troca de produtos: Caso seja escolha do paciente, poderá solicitar o reenvio do medicamento, com os prazos estabelecidos pela plataforma da Cannect, mantendo-se a conformidade com a sua prescrição médica.</p>
<p>Quaisquer dúvidas quanto a esta política de estorno, por favor, entre em contato pelo WhatsApp (11) 9 8271-1992. Horário de atendimento: de segunda a sexta-feira das 9h00 às 18h00 (exceto feriados) ou através do e-mail faleconosco@cannect.com.br</p>
<br />
<br />
<p><strong>Equipe Cannect.</strong></p>
<p><br /><br /></p>
`

export const SchedulingPrivacyText = `


<strong>1. <u>Objeto.</u></strong>
<br/>
<br/>

Os presentes termos e condições regulamentam o uso da plataforma por qualquer pessoa física ou jurídica, independente de sua finalidade de uso.
A navegação na plataforma e utilização dos serviços para agendamento de consultas, avaliações, comentários ou uso como paciente atribui o status de USUÁRIO a você e implica em sua aceitação total das condições aqui expostas, alertando que essas condições podem ser modificadas sem notificação prévia.
Aos profissionais de saúde que se cadastrarem será atribuído o status de PROFISSIONAL DE SAÚDE.
O usuário e profissional de saúde também se comprometem a fazer uso correto do site, de acordo com as leis, boa-fé e ao presente instrumento e serão responsáveis perante a outra parte, por quaisquer danos que possam ser causados em decorrência da violação de eventuais obrigações ou orientações aqui impostas.
Se você discordar de todos ou parte destes Termos, deve abster-se de continuar a usar o site e/ou seus serviços.

<br/>
<br/>

<strong>2. <u>Identificação.</u></strong>
<br/>
<br/>

A Cannect, opera por meio de sua empresa CANNECT SERVIÇOS DE INTERNET S.A., inscrita no CNPJ/MF sob o n° 41.418.943/0001-16, com sede na Rua Dr. Renato Paes de Barros, n° 33, 8° andar, Itaim Bibi, CEP: 04530-000, São Paulo/SP, criadora e proprietária do site: www.cannect.life/agendamento.

<br/>
<br/>
<strong>3. <u>Comunicações</u></strong>
<br/>
<br/>

Para entrar em contato conosco, fornecemos diferentes meios de contato que detalhamos abaixo:
<br/>

• faleconosco@cannect.com.br
<br/>
•  Endereço postal: Rua Dr. Renato Paes de Barros, n° 33, 8° andar, Itaim Bibi, CEP: 04530-000, São Paulo/SP
<br/>

Todas as comunicações e notificações entre usuários e Cannect serão consideradas eficazes, para todos os efeitos, quando feitas por qualquer meio detalhado acima.

<br/>
<br/>
<strong>4. <u>Serviços Disponíveis.</u></strong>
<br/>
<br/>

O site é uma plataforma que expõe os perfis de profissionais de saúde, viabilizando a possibilidade de consulta às informações, espaço para avaliação por parte dos usuários e a depender do contrato do profissional, a disponibilidade de horários para agendamento online de consultas presenciais e/ou teleconsultas.
O uso do site da Cannect pelos usuários é gratuito, sendo possível a contratação de serviços pagos.
A Cannect reserva-se ao direito de alterar a qualquer momento os serviços oferecidos aos Clientes. Desta forma, a Cannect poderá, a qualquer momento, agregar novos serviços aos atualmente oferecidos. Da mesma forma, a Cannect reserva-se o direito de retirar ou deixar de oferecer, a qualquer momento e sem prévio aviso, qualquer dos serviços oferecidos.
Sendo a Cannect uma plataforma que disponibiliza espaço para os profissionais exporem suas informações, toda informação e conteúdo exposto na plataforma quanto aos serviços e informações profissionais, sobre valores, formas de pagamento, são fornecidos pelos próprios profissionais, sendo exclusiva a responsabilidade dos mesmos pela veracidade e exatidão das informações publicadas.
Uma vez dentro de www.cannect.life/agendamento, para proceder com a utilização dos diversos serviços e dispositivos disponíveis, o usuário e os profissionais de saúde devem seguir todas as instruções indicadas no próprio site e as informações que vão surgindo, o que significará a leitura e aceitação de todas as condições de uso estabelecidas em www.cannect.life/agendamento.
A Cannect não se responsabiliza por quaisquer danos que possam ocorrer ao referido usuário e aos profissionais de saúde em consequência de falhas ou desligamentos nas redes de telecomunicações e que causem suspensão, cancelamento ou interrupção do serviço de www.cannect.life/agendamento durante a prestação delas.
Vale ressaltar que, as consultas serão realizadas em plataforma de videoconferência indicada pelo profissional de saúde, não se responsabilizando a Cannect pela estabilidade desses softwares.

<br/>
<br/>
<strong>5. <u>Condições.</u></strong>
<br/>
<br/>

<strong>5.1 <u>Condições de adesão.</u></strong>
A adesão do contrato está vinculada ao consentimento e ciência de todas as condições aqui estabelecidas, sem a necessidade de fornecer informações adicionais, sendo que, os usuários e profissionais de saúde se comprometem a respeitar os Termos de Uso.
<br/>
Ao aderir ao contrato, o usuário e os profissionais de saúde:
<br/>

• Consente com o processamento de dados pessoais de acordo com os Termos de Uso e em especial a nossa política de privacidade.
<br/>
• Confirma a veracidade das informações fornecidas no cadastro; e
<br/>
• Autoriza o recebimento, em um endereço de e-mail especificado, de mensagens do sistema sobre interrupções técnicas na operação do Site, atualizações dos Termos de Uso, novas funcionalidades, informações de marketing, solicitações de avaliações, confirmação de agendamentos e outras comunicações similares.
<br/>
• Autoriza o uso dos dados obtidos em consultas para futuras pesquisas científicas, sem exposição de dados pessoais.

<br/>
<br/>

O contrato é celebrado por um período indeterminado.
<br/>
O profissional de saúde deverá completar suas informações no site www.cannect.life/cadastro ou pelo setor de relacionamento médico. Vale ressaltar que o cadastro não induz em sua ativação na plataforma, sendo que o profissional de saúde deverá passar pela triagem do setor responsável.
<br/>
Já o usuário pode completar o registro agendando uma consulta com um profissional de saúde através da plataforma de agendamento ou através do e-mail onde receberá a senha para ativação do cadastro, caso este ocorra por whatsapp.
<br/>
Se o consentimento de qualquer das disposições contidas nos Termos de Uso for revogado, a Cannect terá a obrigação de deletar imediatamente a sua conta ou de informar a Cannect tal revogação.
<br/>
<br/>
<strong>5.2 <u>Condições de uso.</u></strong>
<br/>
<br/>

O usuário terá acesso às informações e avaliações sobre profissionais de saúde e se comprometem expressamente a fazer uso adequado dos conteúdos e serviços da Cannect e não usá-los para, entre outros:
<br/>

• Disseminar conteúdo que seja criminoso, violento, pornográfico, racista, xenófobo, ofensivo, apologia ao terrorismo ou, em geral, contrário à lei ou à ordem pública.
<br/>
• Introduzir vírus de computador na rede ou executar ações que possam alterar, danificar, interromper ou gerar erros ou danos aos documentos eletrônicos, dados ou sistemas físicos e lógicos da Cannect ou de terceiros; além de dificultar o acesso de outros usuários ao site e seus serviços através do consumo maciço dos recursos de computador através dos quais a Cannect presta seus serviços.
<br/>
• Acessar as contas de e-mail de outros usuários ou áreas restritas dos sistemas de computador da Cannect ou de terceiros e, se for o caso, extrair informações.
<br/>
• Violar os direitos de propriedade intelectual ou industrial, bem como violar a confidencialidade das informações da Cannect ou de terceiros. Personificar qualquer outro usuário.
<br/>
• Reproduzir, copiar, distribuir, disponibilizar ou qualquer outra forma de comunicação pública, transformar ou modificar o conteúdo, a menos que a autorização do proprietário dos direitos correspondentes esteja disponível ou legalmente permitida.
<br/>
• Coletar dados para fins publicitários e enviar publicidade de qualquer tipo e/ou comunicações para vendas ou outros fins comerciais sem sua solicitação ou consentimento prévio.
<br/>
• O usuário é único responsável por todas as informações e condições de acesso fornecidas pela Cannect, não havendo qualquer responsabilidade da plataforma em caso de divulgação a terceiros.

<br/>
<br/>

A inclusão de um hiperlink de sites de terceiros não implica, em qualquer caso, a existência de relações entre a Cannect e o proprietário do site no qual está estabelecido, nem a aceitação e aprovação pela Cannect de seu conteúdo ou serviços, em especial se relacionados com os perfis de profissionais ou estabelecimentos de saúde.
<br/>
<br/>

A Cannect não se responsabiliza pelo uso que cada usuário dá aos materiais disponibilizados neste site ou pelas ações que realiza com base nisso.
<br/>
<br/>

<strong>6. <u>Termos de Uso - Usuário</u></strong>
<br/>
<br/>


O usuário poderá agendar uma consulta com o profissional, utilizando a plataforma de agendamento ou canal do whatsapp, desde que as informações de contato dessa pessoa sejam verificadas pela Cannect e que o profissional seja assinante de serviços que incluem a disponibilização de agenda online.
<br/>
<br/>

O usuário poderá:
<br/>
<br/>


• acessar o Perfil do Profissional de Saúde. O Usuário acessa as informações relacionadas a esses profissionais, suas especialidades, arquivos, opiniões, imagens, etc<br/>
• fornecer avaliações e opiniões sobre Profissionais e Instituições;<br/>
• agendar consultas online com o profissional de saúde escolhido que dispor dessa funcionalidade em seu perfil; e<br/>
• denunciar abuso.<br/>

<br/>
<br/>

A Cannect reserva-se o direito de suspender, temporariamente, a prestação dos Serviços durante a atualização e/ou reconstrução do Site, ou ainda, durante trabalhos de manutenção de sistemas.
<br/>
<br/>

Este website, sua plataforma e aplicativos não são uma ferramenta de diagnóstico on-line, assim, não substitui a consulta médica, sendo que o profissional de saúde é o único responsável pelo diagnóstico nosológico e instauração da terapêutica adequada, não havendo qualquer responsabilidade da Cannect.

<br/>
<br/>
A Cannect presta serviços de informação sobre médicos, com base em seus cadastros pessoais, ou em dados públicos obtidos na rede ou nos Conselhos Profissionais.

<br/>
<br/>
A Cannect não se responsabiliza pela constatação de diagnóstico diverso, pois não se presta a indicar diagnóstico, sendo que apenas o profissional de saúde, através de consulta, pode constatar diagnóstico através de análise clínica e de exames, exceto em casos de emergência ou impossibilidade ou excepcionalidades, ou naqueles em que seja autorizada a telemedicina.

<br/>
<br/>
Em caso de emergência, o usuário deverá procurar um Hospital ou Nosocômio mais próximo, não devendo utilizar a plataforma de agendamento de consultas, sendo que a Cannect não se responsabiliza caso o usuário procure a plataforma em casos de emergência.

<br/>
<br/>
O usuário consente expressamente o tratamento de seus dados para o uso dos serviços da plataforma e aplicativos da Cannect e autoriza a transmissão dos dados para os profissionais de saúde com os quais venha a agendar consulta.

<br/>
<br/>
<strong>6.1 <u>Opiniões, avaliações e comentários na plataforma.</u></strong>
<br/>
<br/>

A Cannect não detém obrigação de realizar o monitoramento de comentários e opiniões, não tendo qualquer obrigação ou responsabilidade de verificar a exatidão e/ou veracidade dos comentários ou opiniões atribuídas à experiência havida pelo paciente.

<br/>
<br/>
Todavia, a Cannect se reserva ao direito de editar, excluir ou adequar avaliações de acordo com seus próprios critérios, caso violem nossa política, sejam ofensivas, contrárias aos nosso Termos e Condições Gerais, à lei ou em cumprimento de uma ordem judicial, administrativa ou de autoridade competente.

<br/>
<br/>
Aquele que publicar uma opinião se responsabiliza inteiramente pelo seu conteúdo, por isso aconselha-se que o usuário seja cauteloso, honesto e sincero em suas opiniões, já que poderá responder por eventuais ações legais de um profissional de saúde que se sentir lesado em razão de violação de direitos.

A Cannect poderá vedar, editar ou solicitar a adequação de opiniões ou comentários nos seguintes casos:

<br/>
<br/>

• Quando a opinião ou comentário não estiver relacionada com um agendamento realizado pela plataforma.<br/>
• Quando a opinião for dada por pessoa que não traga sua identificação completa.
<br/>
• Quando a opinião ou comentário contiver linguagem inapropriada, vulgar, ofensiva ou vexatória.
<br/>
• Quando a opinião ou comentário violar a lei, direitos de terceiros, honra ou a liberdade profissional, que contenha frases que suscitem o terrorismo, crime organizado ou grupos organizados, propagação de ódio, conteúdo sexual, discriminatório de raça, etnia, nacionalidade, gênero, identidade de gênero, orientação sexual, religião, deficiências ou doenças.
<br/>
• Quando contiver propaganda, anúncio, spam ou similares.
<br/>
• Quando a opinião trouxer acusação de violação à lei ou ao ato técnico do profissional, sugira negligência, erro médico, falha técnica, conduta criminosa. A Cannect não é um órgão julgador da conduta médica ou dos demais profissionais de saúde, e em razão de sua imparcialidade não publicará opiniões que tragam acusações em tal sentido, sugerindo que as mesmas reclamações sejam sempre direcionadas aos conselhos profissionais, autoridades e ao poder judiciário.
<br/>
• Quando a opinião não for clara ou compreensível.
<br/>
• Quando a opinião tratar de experiência de terceiro, em exceção daqueles casos em que a pessoa seja responsável legal pelo paciente.
<br/>
• Que não seja relacionada ao profissional de saúde proprietário do perfil no qual a opinião está sendo deixada, ou seja, que seja sobre terceiros alheios à relação do usuário e profissional de saúde.
<br/>
• Opiniões e avaliações em seus próprios perfis de modo a manipular opiniões, informações e classificações;
<br/>
• Opiniões e comentários que sejam falsos; e
<br/>
• Ou quaisquer outros que ofendam os usuários e/ou profissionais de saúde cadastrados ou não plataforma.

<br/>

A Cannect terá o direito de exigir que, ao publicar opinião e/ou avaliação, o usuário seja adicionalmente verificado, por exemplo, com o uso de uma conta no Facebook ou por meio de validação em outros dispositivos.

<br/>
<br/>
<strong>7. <u>Termos de Uso – Profissional de saúde</u></strong>

<br/>
<br/>
Para cadastro do profissional de saúde, o usuário deverá fornecer informações básicas de cadastro, sendo necessário: número de registro profissional, primeiro nome e sobrenome, endereço e detalhes de contato para o local onde os serviços de saúde são realizados, a especialização do profissional e o(s) número(s) de registro profissional, além de outras informações que se fizerem necessárias.

<br/>
<br/>
A Cannect reserva-se ao direito exclusivo de inserir e editar informações relativas ao número de telefone e outros dados de contato do (a) profissional de saúde, podendo ser solicitada a remoção/alteração pelo profissional de saúde. Outros dados importantes para a utilização da Plataforma poderão ser requeridos, sendo que o profissional de saúde se responsabiliza civil e criminalmente, inclusive perante terceiros, pela veracidade dos dados informados.
Os campos de nome e sobrenome de um perfil não podem ser preenchidos com nenhuma outra informação além do primeiro nome e sobrenome de um profissional.

<br/>
<br/>
Cada profissional possui direito a apensa 1 (um) perfil, sendo vedada a criação de um perfil comum para vários profissionais. Se verificada a existência de perfis duplicados, a Cannect se reserva no direito de excluir a página.
É facultado ao profissional criar o seu próprio perfil ou confirmar informações encontradas em um perfil existente, sendo para tanto necessário o cadastro no site.
<br/>
<br/>
O profissional concede à Cannect uma licença não exclusiva, permanente e não paga para utilizar a fotografia através de campos de utilização, tais como, reprodução por meios analógicos ou digitais; memória do computador; redes de informática ou multimídia; circulação pública, disponibilizar o trabalho publicamente em um local e horário selecionados pela Cannect; rastreio, difusão, reemissão e reprodução; arquivar a fotografia em bases de dados; e utilização da fotografia para promoção da Cannect, sendo que a remoção da conta pelo profissional não afetará a validade da referida licença.

<br/>
<br/>
Ao fornecer seus dados e realizar cadastro para uso dos serviços fornecidos pela plataforma Cannect, gratuitos ou pagos, o profissional autoriza o uso de seus dados pela plataforma, bem como, a outras ferramentas e plataformas, aplicativos e similares, criados pela Cannect ou por esta em parceria com outras empresas, para os mesmos fins a que se destina.

<br/>
<br/>
<strong>8. <u>Exclusão de garantias e responsabilidade no acesso e uso.</u></strong>
<br/>
<br/>
O conteúdo deste site é de natureza geral e tem um propósito puramente informativo, sem garantir totalmente o acesso a todo o conteúdo, sua completude, correção, validade ou pontualidade, nem sua adequação ou utilidade para um propósito específico.
<br/>
<br/>

A Cannect não é responsável por danos de qualquer tipo decorrentes de:
<br/>
<br/>

• Impossibilidade de acesso ao site ou a falta de veracidade, exatidão, completude e/ou atualidade dos conteúdos, bem como a existência de defeitos e defeitos de todos os tipos de conteúdo transmitido, divulgado, armazenado, disponibilizado, acessado através do site ou dos serviços oferecidos.
<br/>
• Presença de vírus ou outros elementos no conteúdo que possam causar alterações em sistemas de computador, documentos eletrônicos ou dados do usuário.
<br/>
• Descumprimento das leis, boa-fé, ordem pública, do presente instrumento, em decorrência do uso indevido do site. Em particular, e por exemplo, a Cannect não é responsável pelas ações de terceiros que violam direitos de propriedade intelectual e industrial, segredos de negócios, direitos à honra, privacidade pessoal e familiar e autoimagem, bem como regulamentos sobre concorrência desleal e publicidade ilícita, ou tampouco pela prestação dos serviços de saúde agendados por meio da plataforma.


<br/>
<br/>
Da mesma forma, a Cannect não detém qualquer responsabilidade pelas informações que estão fora deste site e não são gerenciadas diretamente pelo nosso webmaster. A Cannect não é responsável pela inclusão de hiperlink por terceiros ou de qualquer conteúdo que não seja gerado ou replicado por ela diretamente.
<br/>
<br/>
É de exclusiva responsabilidade do usuário e dos profissionais de saúde:
<br/>
<br/>

• manter o ambiente de seus computadores e demais dispositivos de acesso aos Sites e redes seguras, valendo-se de ferramentas específicas para tanto, tais como antivírus, firewall, entre outras, de modo a contribuir para a prevenção de riscos eletrônicos;
<br/>
• equipar-se e responsabilizar-se pelos computadores e dispositivos de hardware necessários para o acesso ao Site, bem como pelo acesso desses computadores e dispositivos à internet;
<br/>
• o uso, a guarda, a manutenção e o sigilo de todas as senhas e logins que lhe forem cedidos para acesso ao Site, garantindo à Cannect que não compartilhará as senhas com quaisquer terceiros, exceto seus empregados autorizados, que as utilizarão somente em seu benefício e nos termos aqui previstos; e
<br/>
• a utilização de informações verdadeiras em relação às atividades exercidas, ao conteúdo e de seus dados pessoais, sob pena de serem aplicadas as sanções legais cabíveis além daquelas previstas neste Termo de Uso.

<br/>
<br/>

<strong>9. <u>Proteção de dados.</u></strong>
<br/>
<br/>

A disponibilização de informações de cunho pessoal e imagens do Usuário à Cannect visam o funcionamento das ferramentas que compõem o Site, não constituindo uso indevido de imagem, não podendo a Cannect ser responsabilizada pela sua divulgação quando no contexto de uso na plataforma ou da prestação de serviços.
<br/>
<br/>
É de responsabilidade da Cannect proteger e conservar todos os dados pessoais e senhas dos Usuários e Profissionais, de modo que as informações fornecidas estão sujeitas a medidas de segurança para impedir o acesso, o uso e a divulgação não autorizados.
<br/>
<br/>
A Cannect disponibilizará um espaço na plataforma para ser utilizado como “repositório de documentos”, sendo de responsabilidade exclusiva do Profissional as informações ali depositadas.
<br/>
<br/>
A Cannect garantirá que os Usuários e Profissionais tenham acesso aos seus próprios dados e possam corrigi-los, editá-los e complementá-los. A Cannect não faz uso de dados sensíveis de pacientes que receba, ou que sejam incluídos em seus sistemas, prontuários e demais funcionalidades, para divulgação de qualquer forma, mantendo o sigilo protegido pela legislação brasileira, salvo nos casos em que haja ordem judicial.
<br/>
<br/>
<strong>10. <u>Procedimento em caso de realização de atividades de natureza ilícita</u></strong>
<br/>
<br/>
Caso qualquer usuário ou terceiro considere que existem fatos ou circunstância que revelam a ilegalidade do uso de qualquer conteúdo e/ou o desempenho de qualquer atividade nas páginas da Web inclusas ou acessíveis através do site, deve enviar uma notificação à Cannect identificando-se adequadamente e especificando as supostas violações.
<br/>
<br/>
<strong>11. <u>Lei aplicável</u></strong>
<br/>
<br/>

As condições atuais serão regidas pela legislação brasileira vigente e o idioma aplicado em sua plataforma e demais comunicações será o português.
<br/>
<br/>
<br/>
<br/>
<strong><u>ANEXO A</u></strong>
<br/>
<br/>
<strong>1. <u>Política de cancelamento e reembolso para o serviço de agendamento de consulta
Usuário:</u></strong>
<br/>
<br/>
• Você pode reagendar uma consulta online em até 6 (seis) horas antes do horário agendado.<br/>
• Você poderá reagendar a sua consulta até 2 vezes caso esse número de reagendamentos seja superado, sem justificativa plausível, considerar-se-á cancelada a consulta e os valores não serão reembolsados<br/>
• Caso você atrase mais do que 10 (dez) minutos, a consulta será cancelada e não terá direito à reembolso.<br/>
• Para cancelar a consulta online você deverá recorrer à plataforma virtual utilizada para agendamento da consulta, ou (caso aplicável) entrar em contato diretamente com a equipe da CANNECT. <a href="https://whts.co/SiteCannectPink" target="_blank">(https://whts.co/SiteCannectPink)</a><br/>
• Em caso de cancelamento da consulta, os valores pagos via PIX serão reembolsados pela CANNECT em até 1 dia útil a contar do recebimento do seu pedido de cancelamento. Já os valores pagos via cartão de crédito serão reembolsados em até duas faturas do cartão. Você não terá direito ao reembolso automático dos valores pagos: se (I) você tiver que cancelar a consulta online em menos de vinte e quatro (24) horas antes do horário de início programado, (II) você não comparecer a consulta online, a mesma será cancelada e, independentemente de sua realização, reagendamento ou cancelamento. Dessa forma, para solicitar a análise do reembolso, você deverá contatar a equipe da CANNECT, que lhe retornará em até 2 (dois) dias úteis com um posicionamento. <a href="https://whts.co/SiteCannectPink" target="_blank">(https://whts.co/SiteCannectPink)</a>

<br/>
<br/>


<strong>Cancelamentos, ausências ou atrasos <u>pelo profissional:</u></strong>
<br/>
<br/>


• Se o profissional cancelar a consulta, não comparecer, ou atrasar mais 5 minutos (consulta 30 min) e 10 minutos (1h), a consulta será reagendada sem custos extras com o mesmo médico. Caso não tenha interesse no reagendamento, o reembolso será realizado no prazo de até duas faturas após o seu cancelamento usando o mesmo método de pagamento usado por você. Caso a forma de pagamento tenha sido PIX, o reembolso será realizado no prazo de 1 dia útil.
<br/>
• Não será possível o reagendamento da consulta com outro profissional, neste caso, você deverá aguardar o reembolso dos valores da primeira consulta agendada e buscar o reagendamento com o novo profissional escolhido.

<br/>
<br/>


<strong>Devoluções e reembolsos <u>pelo paciente</u> (“você”):</strong>

<br/>
<br/>
<img style="width: 100%" src=${SchedulingRefundImage} alt="refund-1" />
<br/>
<br/>

<strong>Demais condições:</strong>
<br/>
<br/>

• Caso deseje algum comprovante fiscal da sua consulta, você deverá solicitá-lo diretamente ao médico/cirurgião-dentista durante o ato da consulta. A plataforma de atendimento e o seu detentor não possuem responsabilidade pela sua emissão.
<br/>
• Em casos diversos dos específicos estabelecidos nos pontos 1 e 2, acima, você não terá direito a reembolso automático e deverá buscar a equipe da CANNECT, que será a responsável por aplicar a política.
<br/>
• Se você tiver alguma dúvida ou reclamação relacionada ao processamento de um reembolso, você deve entrar em contato diretamente com a equipe da CANNECT.
<br/>
• Em caso de abuso, ou de uso fraudulento, ilícito ou injusto por você, os direitos concedidos a você sob esta política (por exemplo, a fim de obter uma vantagem indevida), você não terá direito a qualquer reembolso ou compensação, e o profissional reserva todos os direitos disponíveis a eles sob a lei aplicável e/ou sob seu contrato com você.
<br/>
• A CANNECT não é responsável pela gestão dos valores pagos pelos pacientes aos médicos/cirurgiões-dentistas, que são exclusivamente responsáveis pelo estabelecimento de valores, cobrança através do sistema de pagamento e devoluções.
<br/>
• A presente política se dá no intuito de facilitar e estabelecer regras mínimas para a relação entre as partes, em razão da intermediação e prestação de serviços tecnológicos providos pela CANNECT.
<br/>
• A confirmação de agendamento da consulta, somente ocorrerá após a efetiva confirmação do pagamento, seja por PIX ou cartão de crédito.
<br/>



`
