import styled from 'styled-components'
import { theme } from 'design-cannect'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 380px;
  border: 1px solid ${theme.colors.gray};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  .input {
    padding: 16px;
  }
  .header {
    display: flex;
    padding: 32px;
    height: 120px;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.gray};
    svg {
      font-size: 25px;
      color: ${theme.colors.tertiary};
    }

    .title {
      display: flex;
      align-items: center;
      svg {
        margin-right: 16px;
        font-size: 50px;
        color: ${theme.colors.secondary};
        width: 60px;
      }
    }
  }
`
