import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { useSendAnvisaAuthorizationStep } from './useSendAnvisaAuthorizationStep'
import { FileField } from '@cannect/new-components/molecules'

export const SendAnvisaAuthorizationStepUI = ({
  sendAnvisaDocumentForm,
  onSubmit
}: ReturnType<typeof useSendAnvisaAuthorizationStep>) => {
  return (
    <div className="flex flex-col justify-center gap-4">
      <div className="mb-4 mt-auto flex flex-col items-center gap-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Já tem autorização? Ótimo! Insira o documento aqui
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Lembrete de amigo: sua autorização precisa conter as mesmas marcas que as que constam em sua prescrição.
        </Typography.Text>
      </div>

      <FormController.Form {...sendAnvisaDocumentForm}>
        <FileField
          isDisabled={sendAnvisaDocumentForm.formState.isSubmitting}
          name="anvisa_authorization_file"
          control={sendAnvisaDocumentForm.control}
          showDescriptionFilesAccept
          canDownload
          showListFiles
          acceptFormats={['.pdf', '.png', '.jpeg', '.jpg']}
        />
        <Button
          className="mt-auto font-medium"
          full
          onClick={sendAnvisaDocumentForm.handleSubmit(onSubmit)}
          isLoading={sendAnvisaDocumentForm.formState.isSubmitting}
          size="lg"
          disabled={!sendAnvisaDocumentForm.formState.isValid}>
          Continuar
        </Button>
      </FormController.Form>
    </div>
  )
}
