import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    height: 100px;
    display: flex;
    align-items: center;
    p {
      margin-right: 18px;
    }
  }
`
