import styled from 'styled-components'

export const PicturesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10rem;
  min-width: 10rem;
  height: 10rem;
  min-height: 10rem;

  border-radius: 999px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -0.5rem;
    right: -0.5rem;

    background: #d9d9d9;
    color: #3d3d3d;
    border-radius: 999px;

    border: none;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;

    transition: 0.3s;

    &:hover {
      transform: scale(1.04);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    aspect-ratio: auto;
    border-radius: 999px;
  }
`

export const SelectImageInput = styled.input`
  display: none;
`

export const SelectNewImageLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10rem;
  min-width: 10rem;
  height: 10rem;
  min-height: 10rem;

  border-radius: 999px;
  font-size: 1.25rem;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

  color: #3d3d3d;
  transition: 0.3s;

  &:hover {
    transform: scale(1.04);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;

  margin-top: 3rem;
`
