import axios, { AxiosError, AxiosResponse } from 'axios'
import { error } from 'console'
import { promise, string } from 'zod'

const locationHost = window.location.host
export const baseURL =
  locationHost.includes('homolog') || locationHost.includes('localhost')
    ? import.meta.env.VITE_APP_HOMOL_BASE_URL
    : import.meta.env.VITE_APP_BASE_URL

const api = axios.create({ baseURL })

export const defaultsHeadersAxios = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`
}

api.interceptors.request.use(config => {
  const storedParams = sessionStorage.getItem('utmParams')
  let utmParams: Record<string, string> = {}

  if (storedParams) {
    utmParams = JSON.parse(storedParams) as Record<string, string>
  }

  if (config.method == 'post' || config.method == 'put') {
    if (config.data instanceof FormData) {
      Object.keys(utmParams).forEach(key =>{
        config.data.append(key, utmParams[key])
      })
    }else {
      config.data = {
        ...config.data, 
        utmParams
      }
    }
  }

  return config
  }, error => {
    return error
})

const responseSuccessInterceptor = (response: AxiosResponse) => response

const responseErrorInterceptor = async (error: AxiosError) => {
  const responseStatus = error.response?.status

  if (responseStatus === 401) {
    window.location.replace(`${window.location.origin}/login`)
    localStorage.removeItem('@CANNECT_TOKEN_API')
  }

  return Promise.reject(error)
}

api.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor)

export default api
