import Texts from 'components/Text'
import { IGetAllCategoriesByPostResponse } from 'dtos/Category'
import { useEffect, useState } from 'react'
import { getAllPostsByCategories } from 'usecases/categories'
import FormSendHistory from './FormSendHistory'
import Historia from './History'
import * as Styles from './styles'

function CommunityComponent(storyId: any) {
  const [histories, setHistories] = useState<IGetAllCategoriesByPostResponse>({} as IGetAllCategoriesByPostResponse)

  const loadHistories = async () => {
    try {
      const responsePosts = await getAllPostsByCategories({
        category: 'historias-reais'
      })

      setHistories(responsePosts)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadHistories()
  }, [])

  return (
    <Styles.Container>
      <Texts type="h1" color="primary">
        Conheça as histórias de pessoas que mudaram suas vidas com a cannabis medicinal
      </Texts>
      <Historia history={histories?.data} storyId={storyId.storyId} />
      <FormSendHistory />
    </Styles.Container>
  )
}
export default CommunityComponent
