import { useCannectAssistant } from 'hooks/useCannectAssistant'

import SectionsWrapper from '../../SectionsWrapper'
import Section from '../../SectionsWrapper/Section'
import * as Style from './styles'
import OtherIndications from './OtherIndications'
import Button from 'new-components/Button'
import Loading from '@cannect/components/Loading'

export default function CompositionSuggestion() {
  const { prescription, setStep, loading } = useCannectAssistant()

  return (
    <Style.InitialQuestionsContainer>
      <SectionsWrapper>
        <Section>
          <h2>Produtos selecionados</h2>
          <OtherIndications />
        </Section>
      </SectionsWrapper>
      <div className="buttons_actions">
        <Button background="black" onClick={() => setStep(0)}>
          Voltar
        </Button>
        {prescription.products.length !== 0 && (
          <Button background="green" onClick={() => setStep(state => state + 1)}>
            Continuar
          </Button>
        )}
      </div>
      {loading && <Loading loading={loading} />}
    </Style.InitialQuestionsContainer>
  )
}
