import {
  faleComCannecter,
  faleComEnfermeiro,
  pngArtigo1,
  pngArtigo2,
  pngArtigo3,
  pngArtigo4,
  pngEfeitosColaterais,
  pngQualidadeDeVida,
  pngSintomas
} from 'assets/img'
import BreadCrumb from 'components/BreadCrumb'

import Loading from 'components/Loading'
import Texts from 'components/Text'
import { Path, useCannectCare } from 'hooks/useCannectCare'
import { useEffect } from 'react'
import LifeQuality from './LifeQuality'
import LinkButton from './LinkButton'
import PathButton from './PathButton'
import SideEffects from './SideEffects'
import * as Style from './styles'
import Symptoms from './Symptoms'

export default function CannectCare() {
  const { path, setPath, setStep, loading, lifeQualityQuestionsAnswers, fetchLifeQualityQuestionsAnswers } =
    useCannectCare()

  useEffect(() => {
    async function verifyButtonsActivation() {
      await fetchLifeQualityQuestionsAnswers()
    }

    setPath('')
    setStep(0)
    verifyButtonsActivation()
  }, [])

  function handlePathChoice(path: Path) {
    setPath(path)
  }

  function handleCallToAction(choice: 'nurse' | 'cannecter') {
    if (choice === 'cannecter') {
      window.open(`https://whts.co/Cannect`, '_blank')
    }
    if (choice === 'nurse') {
      window.open(
        `https://api.whatsapp.com/send?phone=5511956283030&text=Gostaria%20de%20tirar%20uma%20duvida%20sobre%20o%20cannect%20cuida!`,
        '_blank'
      )
    }
  }

  function handleGoBackClick() {
    setPath('')
    setStep(0)
  }

  return (
    <Style.CannectCareContainer>
      <BreadCrumb
        paths={[
          {
            label: 'Pacientes',
            link: '/area-paciente'
          },
          {
            label: 'Cannect Cuida',
            link: '/cannect-cuida',
            onClick: handleGoBackClick
          }
        ]}
      />
      {path === '' && (
        <>
          <h1 style={{ marginTop: '-2rem' }}>Olá! Seja bem vindo ao Cannect Cuida!</h1>
          <div className="texts-info">
            <Texts color="tertiary" type="md">
              Aqui você pode inserir suas informações de saúde, registrar seu progresso e eventuais efeitos colaterais.
            </Texts>
            <Texts color="tertiary" type="md">
              1. Quando você preenche a avaliação da qualidade de vida, começamos a entender ainda mais sobre seu estado
              atual. Esta é a primeira coisa que você deve fazer neste espaço. Daqui pra frente, pediremos que você
              repita esta avaliação em alguns momentos e, assim, criamos juntos a sua curva de evolução neste
              tratamento.
            </Texts>
            <Texts color="tertiary" type="md">
              2. O registro de sintomas também é fundamental neste processo! Trata-se de um dos principais indicadores
              para checarmos se você já alcançou sua dose ideal (dose terapêutica) da Cannabis Medicinal. Você poderá
              reportar neste campo os seus sintomas diariamente, caso deseje. Por exemplo, se você tem dor, é aqui que
              você deverá indicar a presença e intensidade deste sintoma ou, se o seu filho tem epilepsia, você poderá
              reportar o número de episódios de convulsão que ele apresentou nas últimas 24h. O ideal é que faça o
              registro de sintomas pelo menos a cada três dias.
            </Texts>
            <Texts color="tertiary" type="md">
              3. Preocupados com o seu bem-estar, ao sinalizar um efeito colateral, ou seja, qualquer mal-estar que
              possa estar relacionado ao uso da Cannabis Medicinal, uma de nossas enfermeiras é acionada automaticamente
              e você será contatado para uma avaliação mais precisa, bem como para te apoiarmos neste momento. Você
              poderá reportá-lo a qualquer momento!
            </Texts>
            <Texts color="tertiary" type="md">
              Ah!!! Esta é apenas a nossa versão beta! Logo teremos ainda mais ferramentas desenvolvidas especialmente
              para cuidarmos de você!
            </Texts>
          </div>

          <Style.HorizontalContent>
            <PathButton
              img={pngQualidadeDeVida}
              imgAlt="Qualidade de vida"
              label="Pesquisa de qualidade de vida"
              onClick={() => handlePathChoice('lifeQuality')}
              disabled={false}
            />
            <PathButton
              img={pngSintomas}
              imgAlt="Sintomas"
              label="Sintomas"
              onClick={() => handlePathChoice('symptoms')}
              disabled={lifeQualityQuestionsAnswers.length === 0}
            />
            <PathButton
              img={pngEfeitosColaterais}
              imgAlt="Efeitos colaterais"
              label="Efeitos colaterais"
              onClick={() => handlePathChoice('sideEffects')}
              disabled={lifeQualityQuestionsAnswers.length === 0}
            />
          </Style.HorizontalContent>
          <Style.HorizontalContent>
            <LinkButton
              img={faleComEnfermeiro}
              imgAlt="Enfermeira"
              label="Fale com uma enfermeira"
              onClick={() => handleCallToAction('nurse')}
            />
            <LinkButton
              img={faleComCannecter}
              imgAlt="Cannecter"
              label="Fale com um cannecter"
              onClick={() => handleCallToAction('cannecter')}
            />
          </Style.HorizontalContent>
          <Style.HorizontalContent>
            <LinkButton
              img={pngArtigo1}
              imgAlt="Revolução Verde"
              label="Higiene Do Sono: Um Termo Na Moda, Mas Você Sabe O Que Quer Dizer?"
              observation="25 de abril • 4 min de leitura"
              onClick={() =>
                window.open(
                  `https://www.cannect.life/blog/bem-estar/higiene-do-sono-um-termo-na-moda-mas-voce-sabe-o-que-quer-dizer`,
                  '_self'
                )
              }
              isImageBig
            />
            <LinkButton
              img={pngArtigo2}
              imgAlt="Revolução Verde"
              label="A Descoberta Do THCP E CBDP E O Futuro Dos Canabinóides"
              observation="25 de abril • 4 min de leitura"
              onClick={() =>
                window.open(
                  `https://www.cannect.life/blog/novidades/a-descoberta-do-thcp-e-cbdp-e-o-futuro-dos-canabinoides`,
                  '_self'
                )
              }
              isImageBig
            />
            <LinkButton
              img={pngArtigo3}
              imgAlt="Revolução Verde"
              label="Saúde Mental E Tratamentos Alternativos: 5 Opções De Suplementos Naturais"
              observation="25 de abril • 4 min de leitura"
              onClick={() =>
                window.open(
                  `https://www.cannect.life/blog/saude/saude-mental-e-tratamentos-alternativos-5-opcoes-de-suplementos-naturais`,
                  '_self'
                )
              }
              isImageBig
            />
            <LinkButton
              img={pngArtigo4}
              imgAlt="Revolução Verde"
              label="Enxaquecas: 6 Pequenos Hábitos Que Vão Melhorar Sua Qualidade De Vida"
              observation="25 de abril • 4 min de leitura"
              onClick={() =>
                window.open(
                  `https://www.cannect.life/blog/saude/enxaquecas-6-pequenos-habitos-que-vao-melhorar-sua-qualidade-de-vida`,
                  '_self'
                )
              }
              isImageBig
            />
          </Style.HorizontalContent>
        </>
      )}
      {path === 'lifeQuality' && <LifeQuality />}
      {path === 'symptoms' && <Symptoms />}
      {path === 'sideEffects' && <SideEffects />}

      {loading && <Loading loading={loading} />}
    </Style.CannectCareContainer>
  )
}
