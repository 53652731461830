import { SVGProps } from 'react'

interface GlobeHemisphereWestIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function GlobeHemisphereWestIcon({ isChecked, ...rest }: GlobeHemisphereWestIconProps) {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14.8309 25.5816C20.6942 25.5816 25.4474 20.8285 25.4474 14.9651C25.4474 9.1018 20.6942 4.34863 14.8309 4.34863C8.96752 4.34863 4.21436 9.1018 4.21436 14.9651C4.21436 20.8285 8.96752 25.5816 14.8309 25.5816Z"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3636 19.6537L18.3587 15.96C18.2553 15.8936 18.138 15.852 18.0158 15.8384L15.4833 15.4956C15.2944 15.4684 15.1018 15.5047 14.9357 15.5988C14.7696 15.6929 14.6394 15.8394 14.5655 16.0153L13.0504 19.4104C12.9792 19.5683 12.9559 19.7437 12.9834 19.9147C13.0109 20.0857 13.0881 20.2449 13.2052 20.3725L15.2843 22.6175C15.3783 22.7208 15.4471 22.8444 15.4855 22.9786C15.5239 23.1129 15.5307 23.2542 15.5055 23.3916L15.0742 25.5813"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.86409 6.95752L6.86879 9.302C6.7821 9.51013 6.77815 9.74352 6.85774 9.95447L8.1295 13.3385C8.17921 13.4795 8.26428 13.6055 8.37659 13.7042C8.4889 13.803 8.62467 13.8712 8.77092 13.9025L11.1375 14.4112C11.2689 14.4378 11.3922 14.4946 11.4978 14.5771C11.6034 14.6596 11.6883 14.7657 11.7457 14.8867L12.166 15.7604C12.2407 15.9081 12.3543 16.0326 12.4947 16.1203C12.635 16.208 12.7967 16.2556 12.9622 16.258H14.4552"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5402 4.68994L18.5686 6.54783C18.6636 6.7225 18.6978 6.92379 18.6657 7.12001C18.6337 7.31622 18.5373 7.4962 18.3917 7.6316L15.4169 10.3189C15.3671 10.3665 15.3113 10.4073 15.251 10.4405L13.8907 11.1925C13.7611 11.2613 13.6172 11.2992 13.4705 11.3031H11.1039C10.9296 11.304 10.7593 11.3558 10.6139 11.452C10.4686 11.5483 10.3544 11.6849 10.2856 11.845L9.36768 14.0236"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
