import styled from 'styled-components'

export const Container = styled.div<any>`
  border: 2px solid #8f8f8f;
  border-radius: 5px;
  padding: ${props => (props.isMobile ? '1rem 1rem 3rem 1rem' : '1rem 2rem 3rem 3rem')};
  margin-top: 1.2rem;
  width: 100%;
`

export const Header = styled.div<any>`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  justify-content: ${props => (props.positionLogo ? props.positionLogo : 'flex-start')};

  img {
    max-width: 110px;
    max-height: 110px;
  }
`

export const PatientData = styled.div<any>`
  display: flex;
  flex-direction: column;

  span {
    color: #3d3d3d;
  }

  h2 {
    color: #3d3d3d;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 2rem;
  }
`

export const RecipeContent = styled.div<any>`
  margin-top: 2rem;
  h2 {
    color: #3d3d3d;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
  }
`

export const InfoMedical = styled.div`
  display: flex;
  margin-top: 2rem;

  .product-info {
    display: flex;
    flex-direction: column;
  }

  .product-name {
    text-decoration: underline;
    color: #3d3d3d;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
  }

  .posology {
    color: #3d3d3d;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0.7rem;
  }

  .required-bottles {
    display: flex;
    margin-left: 1rem;
    flex-direction: column;
    width: 35%;
    .bottles {
      color: #3d3d3d;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
    }

    .bottles-per-year {
      color: #3d3d3d;
      text-align: left;
      font-size: 14px;
      font-weight: 300;
      margin-top: 5px;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
`

export const Date = styled.span`
  color: #3d3d3d;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
`

export const PrescriberData = styled.div`
  display: flex;
  margin-top: 2.5rem;
  justify-content: space-between;

  img {
    width: 230px;
    height: 70px;
  }
`

export const PrescriberInfo = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #3d3d3d;
    font-size: 13px;
  }
`
export const Rodape = styled.span`
  margin-top: 2rem;
  font-weight: 300;
  color: #3d3d3d;
  font-size: 9px;
`
