/* eslint-disable react/function-component-definition */
import React, { useMemo } from 'react'

import { useScheduling } from 'hooks/useScheduling'
import { useFormContext } from 'react-hook-form'

import { formatToBRL } from 'utils/formatters'
import * as Styled from './styles'
import { Box, BoxTitle } from '../styles'

const Resume: React.FC = () => {
  const { appointmentFromB2C, validCoupon, selectedPaymentMethod } = useScheduling()

  const { watch } = useFormContext()

  const amountWithDiscountWatch = watch('amountWithDiscount')
  const creditAmountWithDiscountWatch = watch('creditAmountWithDiscount')
  const selectedPrescribeWatch = watch('prescriber')

  const pixAmount = useMemo(() => {
    if (!appointmentFromB2C) return selectedPrescribeWatch?.online_appointment_value

    return parseFloat(appointmentFromB2C.amount_with_discount)
  }, [appointmentFromB2C, selectedPrescribeWatch])

  const creditCardAmount = useMemo(() => {
    if (!appointmentFromB2C) return selectedPrescribeWatch?.online_appointment_credit_value

    return parseFloat(appointmentFromB2C.credit_amount)
  }, [appointmentFromB2C, selectedPrescribeWatch])

  const appoitmentValue = useMemo(() => {
    if (selectedPaymentMethod === 'pix') return pixAmount

    return creditCardAmount
  }, [pixAmount, creditCardAmount, selectedPaymentMethod])

  const appointmentValueWithDiscount = useMemo(() => {
    if (selectedPaymentMethod === 'pix') return amountWithDiscountWatch

    return creditAmountWithDiscountWatch
  }, [amountWithDiscountWatch, creditAmountWithDiscountWatch, selectedPaymentMethod])

  return (
    <Box style={{ gridArea: 'resume', gap: '12px' }}>
      <BoxTitle>Revisão da cobrança</BoxTitle>
      <Styled.Prices>
        <Styled.LeftContainer>
          <span>Subtotal</span>
          <span>Desconto</span>
        </Styled.LeftContainer>
        <Styled.RightContainer>
          <span className="mb-4">{formatToBRL(appoitmentValue)}</span>

          <span className="mb-4">
            -{formatToBRL(appointmentFromB2C?.coupon ? appointmentFromB2C?.discount : validCoupon?.discount)}
          </span>
        </Styled.RightContainer>
      </Styled.Prices>

      <Styled.Total>
        <span className="total-title">Total</span>
        <span className="total">{formatToBRL(appointmentValueWithDiscount)}</span>
      </Styled.Total>
    </Box>
  )
}

export default Resume
