import { ReactNode } from 'react'
import * as Style from './styles'

interface SectionProps {
  children: ReactNode
}

export default function Section({ children }: SectionProps) {
  return <Style.SectionContainer>{children}</Style.SectionContainer>
}
