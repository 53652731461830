import { useState, useEffect, useCallback } from 'react'
import { cn } from '@cannect/lib/utils'

type CountDownTextProps = {
  initialTime: number
  onExpire?: () => void
  className?: string
}

export const CountDownText = ({ initialTime, onExpire, className }: CountDownTextProps) => {
  const [timeLeft, setTimeLeft] = useState(initialTime)

  const formatTime = useCallback((time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}m:${seconds.toString().padStart(2, '0')}s`
  }, [])

  useEffect(() => {
    if (timeLeft <= 0) {
      onExpire?.()
      return
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(timer)
    }
  }, [timeLeft, onExpire])

  return <span className={cn(className)}>{formatTime(timeLeft)}</span>
}
