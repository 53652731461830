import styled from 'styled-components'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'

export const SectionsWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid #ecece2;
  border-radius: 0.25rem;

  margin-bottom: 4rem;
  min-width: 55vw;
  max-width: ${MAX_PAGE_WIDTH};

  @media ${MOBILE_WIDTH} {
    width: 98vw;
  }
`
