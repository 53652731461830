import PatientInputs from './PatientInputs'
import * as Style from './styles'

export default function PatientData() {
  return (
    <Style.Wrapper>
      <Style.SectionContainer>
        <h2>Identificação do paciente</h2>
      </Style.SectionContainer>
      <Style.PacientDataForm>
        <Style.SectionContainer className="form_box">
          <PatientInputs />
        </Style.SectionContainer>
      </Style.PacientDataForm>
    </Style.Wrapper>
  )
}
