import styled from 'styled-components'
import { theme } from 'design-cannect'

interface ContainerProps {
  isColumn?: boolean
  isMobile?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;

  padding: 30px 0 0 0;

  .button-title {
    color: #115850;
    text-decoration: none;
  }

  .submit-button {
    display: flex;
    justify-content: flex-end;

    width: 100%;
  }

  .advertisement {
    display: flex;
    flex-direction: row;

    padding-top: 96px;
    gap: 5px;

    .href {
      text-decoration: underline;
    }
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`

export const NavigatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;

  float: right;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  cursor: pointer;
`

export const Reference = styled.a`
  color: ${theme.colors.tertiary} !important;
  text-decoration: none;
`

export const SubmitButton = styled.button`
  display: flex;

  background-color: #ecece2;
  color: ${theme.colors.tertiary} !important;

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #DADAC2;

  margin-top: 50px;
  padding: 1rem;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
