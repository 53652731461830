import { z } from 'zod'

export const registerAddressSchema = z
  .object({
    zipCode: z.string().trim().min(9).max(9),
    street: z.string().trim().min(1),
    number: z.string().trim().min(1),
    district: z.string().trim().min(1),
    city: z.string().trim().min(1),
    fu: z.string().trim().min(1)
  })
  .required()
