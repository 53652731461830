import { FiltersList } from 'hooks/useQueryString/types'
import { sortItemsByName } from 'utils/sortItemByName'
import { Post } from 'components/CannectEducate/TabContent'
import { LEVEL_OF_EVIDENCE, YEARS } from './cienciaConstants'

export interface Item {
  name: string
  id: string | number
  checked: boolean
  disabled: boolean
}

const defineCheckedFilters = (filters: any[], selectedFilters: FiltersList): Item[] => {
  return filters.map(filter => {
    const checked = Object.keys(selectedFilters).some(selectedKey => {
      if (Array.isArray(selectedFilters[selectedKey])) {
        return selectedFilters[selectedKey].includes(filter.id.toString())
      }
      return selectedFilters[selectedKey] === filter.id.toString()
    })

    return {
      ...filter,
      checked
    }
  })
}

export const formatInitialFilters = (filtersList: any, dataPathologies: any, selectedFilters: FiltersList) => {
  let newFilter = {} as any
  const { class_id, cannabidiol_id, routeofadministrations, shapes } = filtersList

  const allFilterItemsDefined = [class_id, cannabidiol_id, routeofadministrations, shapes].every(
    item => item !== undefined
  )

  if (allFilterItemsDefined) {
    newFilter = {
      pathology: defineCheckedFilters(sortItemsByName(dataPathologies), selectedFilters),
      years: defineCheckedFilters(YEARS, selectedFilters),
      shapes: defineCheckedFilters(sortItemsByName(shapes), selectedFilters),
      level: defineCheckedFilters(LEVEL_OF_EVIDENCE, selectedFilters),
      routeofadministrations: defineCheckedFilters(sortItemsByName(routeofadministrations), selectedFilters),
      class_id: defineCheckedFilters(sortItemsByName(class_id), selectedFilters),
      cannabidiol_id: defineCheckedFilters(sortItemsByName(cannabidiol_id), selectedFilters)
    }
    delete newFilter.brand_id
    delete newFilter.thc_concentration
    delete newFilter.cbd_concentration
    delete newFilter.shape_id

    return newFilter
  }
  return newFilter
}

export const formatArticles = (articles: any): Post[] => {
  const newArticles: Post[] = []
  articles.forEach((article: any) => {
    newArticles.push({
      id: article.id,
      description: article.synopsis,
      title: article.name,
      url: `/bases-cientificas/${article.id}`,
      externalUrl: article.link
    })
  })

  return newArticles
}

export const unselectAllFilters = (filters: any) => {
  const newFilters = {} as any
  Object.entries(filters).forEach(([key, value]: any) => {
    newFilters[key] = value.map((items: any) => {
      return { ...items, checked: false }
    })
  })

  return newFilters
}
