import { Navigation } from '@cannect/new-components/atoms'
import { LinkItem } from '@cannect/new-components/molecules'
import { LuLogOut, LuUserCircle } from 'react-icons/lu'

type TAccountMenuItemProps = {
  signedUserName?: string
  handleLogout: () => void
  isLogged: boolean
  className?: string
  withSheetClose?: boolean
}
export const AccountMenuItem = ({
  signedUserName,
  handleLogout,
  isLogged,
  className,
  withSheetClose
}: TAccountMenuItemProps) => {
  return (
    <Navigation.MenuList className={className}>
      {isLogged ? (
        <Navigation.MenuItem>
          <Navigation.MenuTrigger>
            <div className="flex items-center gap-2">
              <LuUserCircle size={26} />
              <div className="inline-block max-w-[130px] truncate font-sans text-lg font-semibold xl:text-base">
                {signedUserName ? `Olá, ${signedUserName}` : ''}
              </div>
            </div>
          </Navigation.MenuTrigger>
          <Navigation.MenuContent className="ml-12 gap-4 xl:ml-0">
            <LinkItem
              className="!text-sm xl:!text-primary-700"
              withSheetClose={withSheetClose}
              title="Minha conta"
              path="/painel-do-usuario"
            />
            <LinkItem
              className="!text-sm xl:!text-primary-700"
              withSheetClose={withSheetClose}
              title="Favoritos"
              path="/meus-favoritos"
            />
            <LinkItem
              withSheetClose={withSheetClose}
              className="hidden !text-sm xl:block xl:!text-primary-700"
              icon={<LuLogOut size={16} className="xl:hidden" />}
              onClick={handleLogout}
              title="Sair"
            />
          </Navigation.MenuContent>
        </Navigation.MenuItem>
      ) : (
        <Navigation.MenuItem>
          <LinkItem
            withSheetClose={withSheetClose}
            iconPlacement="right"
            icon={<LuUserCircle size={20} />}
            title="Entrar"
            path="/login"
            className="!no-underline"
          />
          <span className="sr-only">Login</span>
        </Navigation.MenuItem>
      )}
    </Navigation.MenuList>
  )
}
