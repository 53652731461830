import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const ActionsContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  width: 100%;
  margin-top: -2rem;
  margin-bottom: 3rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    justify-content: center;
  }
`
