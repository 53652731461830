import { useHistory } from 'react-router-dom'
import Connector from './Connector'
import StepButton from './StepButton'

import * as Style from './styles'

interface NavigatorProps {
  step: number
}

export default function Navigator({ step }: NavigatorProps) {
  const history = useHistory()

  return (
    <Style.StepsContainer>
      <StepButton number="1" active={step === 0} onClick={() => history.push('/receita-branca/inserir-receita')} />

      <Connector active={step === 1 || step === 2 || step === 3 || step === 4 || step === 5} disabled={step < 1} />
      <StepButton
        number="2"
        active={step === 1}
        disabled={step < 1}
        onClick={() => history.push('/receita-branca/selecao-produtos')}
      />

      <Connector active={step === 2 || step === 3 || step === 4 || step === 5} disabled={step < 2} />
      <StepButton
        number="3"
        active={step === 2}
        disabled={step < 2}
        onClick={() => history.push('/receita-branca/endereco-entrega')}
      />

      <Connector active={step === 3 || step === 4 || step === 5} disabled={step < 3} />
      <StepButton
        number="4"
        active={step === 3}
        disabled={step < 3}
        onClick={() => history.push('/receita-branca/documentos-pessoais')}
      />

      <Connector active={step === 4 || step === 5} disabled={step < 4} />
      <StepButton
        number="5"
        active={step === 4}
        disabled={step < 4}
        onClick={() => history.push('/receita-branca/aprovacao-anvisa')}
      />

      {/* <Connector active={step === 7} disabled={maxStep < 7} />
      <StepButton number="6" active={step === 7} disabled={maxStep < 7} onClick={() => setStep(7)} /> */}
    </Style.StepsContainer>
  )
}
