import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const OrderDetailsSummaryContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 2rem;

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
  }
`

export const OrderDetailsSummaryContent = styled.div`
  display: flex;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  }
`
