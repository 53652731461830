import { InputHTMLAttributes } from 'react'
import * as Style from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  props?: any
  width?: string
}

export function TextArea({ value, width, ...props }: InputProps) {
  return (
    <Style.WrapperInput width={width}>
      <Style.ContainerTextArea value={value} {...props} />
    </Style.WrapperInput>
  )
}

export default TextArea
