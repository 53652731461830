import { Symptom, useCannectCare } from 'hooks/useCannectCare'
import SelectorButton from './SelectorButton'
import * as Style from './styles'

interface EpisodesSelectorProps {
  symptom: Symptom
  isExistingSymptom: boolean
}

export default function EpisodesSelector({ symptom, isExistingSymptom }: EpisodesSelectorProps) {
  const { changeSymptomOccurrences } = useCannectCare()

  return (
    <Style.EpisodesSelectorContainer>
      <SelectorButton
        type="decrease"
        onClick={() => changeSymptomOccurrences(symptom.id, symptom.occurrences - 1, isExistingSymptom)}
        disabled={symptom.occurrences === 0}
      />
      <Style.Counter>{symptom.occurrences}</Style.Counter>
      <SelectorButton
        type="increase"
        onClick={() => changeSymptomOccurrences(symptom.id, symptom.occurrences + 1, isExistingSymptom)}
      />
    </Style.EpisodesSelectorContainer>
  )
}
