import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.main`
  span,
  p {
    background-color: transparent !important;
    color: ${temporaryTheme.colors.darkGrey} !important;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
  }
`

export const Title = styled.h2`
  color: ${temporaryTheme.colors.dark};
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: ${temporaryTheme.spacing.space3};
`
