export const FilterOrder = [
  {
    id: 1,
    title: 'Relevância',
    type: 'relevance',
    enableWithLogin: false
  },
  {
    id: 6,
    title: 'Mais vendidos',
    type: 'best-seller',
    enableWithLogin: true
  },
  {
    id: 2,
    title: 'Ordenar de A a Z',
    type: 'AtoZ',
    enableWithLogin: false
  },
  {
    id: 3,
    title: 'Ordenar de Z a A',
    type: 'ZtoA',
    enableWithLogin: false
  },
  {
    id: 4,
    title: 'Menor preço',
    type: 'lowest-price',
    enableWithLogin: true
  },
  {
    id: 5,
    title: 'Maior preço',
    type: 'high-price',
    enableWithLogin: true
  }
]

export const VisualizationMode = [
  {
    id: 1,
    title: 'Em lista',
    type: 'list'
  },
  {
    id: 2,
    title: 'Em grade',
    type: 'grid'
  }
]

// Ajustar a category_id em Odontologia

/* export const FilterBadgeMock = [
  { id: 1, title: 'Dor Crônica', icon: svgCronica, slug: '1?category_id=103' },
  { id: 8, title: 'Saúde mental', icon: svgMental, slug: '1?category_id=104' },
  { id: 9, title: 'Odontologia', icon: svgOdontologia, slug: '1?category_id=104' },
  { id: 5, title: 'Oncologia', icon: svgOncologia, slug: '1?category_id=105' },
  { id: 4, title: 'Neurologia', icon: svgNeurologia, slug: '1?category_id=106' },
  { id: 6, title: 'Pediatria', icon: svgPediatria, slug: '1?category_id=107' },
  { id: 7, title: 'Cuidados da Pele', icon: svgPele, slug: '1?category_id=109' },
  { id: 2, title: 'Bem-Estar íntimo', icon: svgBemEstar, slug: '1?category_id=108' },
  { id: 3, title: 'Medicina Esportiva', icon: svgEsportiva, slug: '1?category_id=110' }
]
 */
