import styled from 'styled-components'
import { theme } from 'design-cannect'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  button[type='submit'] {
    display: none;
  }
`

const TextInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem 0;

  // all child div elements must have a margin bottom of 1rem when min-width is less than 691px
  @media (max-width: 910px) {
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 1rem;
      width: 100%;
    }

    // the last child div element must have a margin bottom of 0
    > :last-child {
      margin-bottom: 0;
    }
  }
`

const CheckboxContainer = styled(TextInputContainer)`
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  justify-content: flex-start;
`

const CheckboxOption = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.tertiary};
  flex-wrap: wrap;

  :first-child {
    margin-right: 64px;
  }

  p {
    margin-bottom: 10px;
  }

  @media (max-width: 910px) {
    :first-child {
      margin-right: 0;
    }
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export { Container, TextInputContainer, CheckboxContainer, CheckboxOption, CheckboxWrapper }
