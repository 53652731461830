import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const ChangePasswordContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 4rem;
  background: #f8f8f8;
  width: 100%;
  height: 100%;
`

export const ChangePasswordInputs = styled.div`
  display: flex;
  gap: 2.5rem;
`

export const ChangePasswordInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  min-width: 360px;

  @media ${MOBILE_WIDTH} {
    min-width: 100%;
  }
`
