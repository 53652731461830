import { theme } from 'design-cannect'
import styled from 'styled-components'

export const SelectorButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  min-width: 50px;
  height: 40px;
  min-height: 40px;

  background: ${theme.colors.tertiary};
  border-radius: 0.5rem;
  border: none;
  outline: none;

  font-size: 1.5rem;
  color: white;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`
