import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const PaymentStatusCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  border-radius: 0.5rem 0 0 0.5rem;
  background: #fff;
  padding: 1.5rem;

  height: 100%;
  width: 100%;
  max-width: 16rem;

  label {
    font-weight: 600;
    font-size: 1rem;
  }

  span {
    font-size: 0.85rem;
    color: #3d3d3d;
  }

  @media ${MOBILE_WIDTH} {
    max-width: 100%;
    border-radius: 0.5rem 0.5rem 0 0;
  }
`

export const PaymentStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`

interface PaymentStatusSpanProps {
  color: string
}
export const PaymentStatusSpan = styled.span<PaymentStatusSpanProps>`
  font-weight: 600;
  color: ${({ color }) => `${color} !important`};
`
