import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'

import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import FilterForm from './FilterForm'

import { CARD_MOCK, ONBOARDING_MOCK } from './filterByStepData'

import { redirectLink } from 'utils/redirectLink'
import * as Styles from './styles'
import { CommonQuestionsSection } from '@cannect/new-components/organisms'

export default function FilterByStep({ showFilterForm = true }) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Styles.Wrapper>
      {showFilterForm && <FilterForm />}

      <Styles.HeadingBox>
        <Heading verticalSpace="xsmall">
          Faça parte da <span>#revoluçãoverde</span>
        </Heading>
      </Styles.HeadingBox>

      <Styles.CardList>
        {CARD_MOCK.map((item) => (
          <Styles.Card key={item.id}>
            <Styles.IconCard alt={item.text} src={item.icon} />
            <Styles.TextCard>{item.text}</Styles.TextCard>
          </Styles.Card>
        ))}
      </Styles.CardList>

      <Styles.OnboardingSection>
        <Styles.Overlay />
        <Styles.OnboardingContent>
          <Styles.OnboardingTitle>Seja um Profissional da Saúde Parceiro da Cannect</Styles.OnboardingTitle>
          {!!isMobile && (
            <Styles.OnBoardImage src={ONBOARDING_MOCK.image} alt="Seja um Profissional da Saúde Parceiro da Cannect" />
          )}
          <TextDanger text={ONBOARDING_MOCK.text} fontFamily="Poppins" color="white" fontWeight={400} fontSize="16px" />

          <Button
            onClick={() => redirectLink('/area-profissionais')}
            background="primary"
            isOutlined>
            Saiba mais
          </Button>
        </Styles.OnboardingContent>

        {!isMobile && (
          <Styles.OnboardingImagebox>
            <Styles.OnBoardImage src={ONBOARDING_MOCK.image} alt="Seja um Profissional da Saúde Parceiro da Cannect" />
          </Styles.OnboardingImagebox>
        )}
      </Styles.OnboardingSection>

      <CommonQuestionsSection />
    </Styles.Wrapper>
  )
}
