import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const OrderDetailsContainer = styled.main`
  display: flex;
  flex-direction: column;

  width: 100%;
  background: #f7f7f7;
  padding: 2rem;

  border-radius: 0.5rem;

  @media ${MOBILE_WIDTH} {
    padding: 0.5rem;
  }
`

export const OrdersContainer = styled.section`
  display: flex;
  flex-direction: column;

  background: #e0e7ec;
  padding: 2rem;
  border-radius: 0.5rem;
`
