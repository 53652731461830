import { useEffect } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { useCannectCare } from './useCannectCare'

function ScrollToTop({ history, children }: any) {
  const { pathname } = useLocation()
  const { path, step } = useCannectCare()

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
      document?.getElementById('page-wrapper')?.scrollTo(0, 0)
      document?.getElementById('main-app-container')?.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [pathname])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default withRouter(ScrollToTop)
