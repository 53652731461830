/* eslint-disable no-nested-ternary */
import InvisibleButton from 'components/InvisibleButton'
import { InputHTMLAttributes, ReactNode, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import MinimalistInput from './components/MinimalistInput'
import * as Styles from './styles'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  width?: string
  maxWidth?: string
  height?: string
  errorMessage?: string
  isBig?: boolean
  isMinimalist?: boolean
  subtitle?: ReactNode
  prefix?: string
}

export default function Input({
  id,
  label,
  width,
  height,
  disabled = false,
  maxWidth,
  errorMessage,
  type,
  isBig = false,
  isMinimalist = false,
  subtitle,
  prefix,
  ...rest
}: InputProps) {
  const [showPassword, setShowPassword] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleShowPasswordClick = () => {
    setShowPassword((state) => !state)
  }

  if (isMinimalist) {
    return (
      <MinimalistInput
        {...rest}
        disabled={disabled}
        label={label}
        id={id}
        type={type}
        errorMessage={errorMessage}
        width={width}
        maxWidth={maxWidth}
      />
    )
  }
  return (
    <Styles.InputContainer style={{ width: width ?? '100%', maxWidth: maxWidth ?? '100%' }}>
      {label && <label htmlFor={id}>{label}</label>}

      <Styles.InputWrapper
        hasError={errorMessage !== undefined && errorMessage.length > 0}
        isFocused={isInputFocused}
        isBig={isBig}
        height={height}
        disabled={disabled}
        className="input_wrapper">
        {!!prefix && <Styles.Prefix>{prefix}</Styles.Prefix>}
        <Styles.InputItself
          isBig={isBig}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          disabled={disabled}
          withPrefix={!!prefix}
          {...rest}
        />
        {type === 'password' && (
          <InvisibleButton type="button" onClick={handleShowPasswordClick}>
            {showPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
          </InvisibleButton>
        )}
        {type === 'search' && (
          <Styles.IconContainer>
            <BiSearch />
          </Styles.IconContainer>
        )}
      </Styles.InputWrapper>

      {subtitle}

      {errorMessage !== undefined && errorMessage.length > 0 && (
        <Styles.InputErrorMessage>{errorMessage}</Styles.InputErrorMessage>
      )}
    </Styles.InputContainer>
  )
}
