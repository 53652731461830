import styled from 'styled-components'

interface ContainerProps {
  isColumn?: boolean
}

interface HeaderProps {
  justify?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : null)};
  align-items: center;

  width: 100%;

  .button-title {
    color: #115850;
    text-decoration: none;
  }

  .spacing-field {
    padding-top: 25px;
  }
`

export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? 'start' : 'center')};

  width: 70%;
`

export const NavigatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;

  float: right;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  cursor: pointer;
`

export const InputWrapper = styled.div`
  display: flex;

  gap: 90px;
`

export const FileWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  padding-right: ${({ isMobile }) => (isMobile ? '60px' : 'null')};
`
