import React, { useState, useCallback } from 'react'
import { Dialog, ScrollArea, Button } from '@cannect/new-components/atoms'
import { Document, Page, pdfjs } from 'react-pdf'
import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { ImSpinner2 } from 'react-icons/im'
import { ScrollBar } from '@cannect/new-components/atoms/ScrollArea'
import { LuZoomIn, LuZoomOut } from 'react-icons/lu'
import { motion, AnimatePresence } from 'framer-motion'
import { TFileItem } from '../FileItem/types'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface TModalPreviewFileProps {
  fileItem: TFileItem
  fileType: string
  children: React.ReactNode
}

export const ModalPreviewFile: React.FC<TModalPreviewFileProps> = ({ fileType, children, fileItem }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const [pdfNumPages, setPdfNumPages] = useState(0)
  const [scale, setScale] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPdfNumPages(numPages)
  }

  const handleZoomIn = useCallback(() => {
    setScale((prevScale) => Math.min(prevScale + 0.2, 3))
  }, [])

  const handleZoomOut = useCallback(() => {
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.5))
  }, [])

  const contentWidth = isMobile ? 'w-[calc(100vw-2rem)]' : 'w-[calc(100vw-4rem)] max-w-[800px]'

  const renderContent = () => {
    if (fileType === '.pdf' && fileItem?.file_url) {
      return (
        <Document
          file={{
            url: fileItem?.file_url
          }}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<ImSpinner2 size={20} className="animate-spin text-primary-700" />}
          error={<div>Failed to load PDF. Please try again.</div>}>
          {Array.from(new Array(pdfNumPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={scale}
              className={`${contentWidth}`}
              renderMode="canvas"
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={<ImSpinner2 size={20} className="animate-spin text-primary-700" />}
            />
          ))}
        </Document>
      )
    }
    return (
      <motion.div
        animate={{ scale }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        className={`${contentWidth} h-auto origin-center`}>
        <img
          src={fileItem?.file_url}
          alt="Preview do documento"
          className="h-full w-full object-contain"
          crossOrigin="anonymous"
        />
      </motion.div>
    )
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content className="flex h-[90vh] w-[90vw] max-w-4xl flex-col overflow-hidden p-0">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="flex h-full flex-col">
            <ScrollArea data-testid="preview_pdf" className="h-[calc(100%-5rem)] flex-grow px-4 py-2">
              <div className="flex min-h-full w-full items-center justify-center">{renderContent()}</div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
            <Dialog.Footer className="!justify-center bg-secondary-100 p-4">
              <motion.div
                className="flex items-center justify-center space-x-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}>
                <Button onClick={handleZoomOut} variant="outline_light">
                  <LuZoomOut size={20} />
                </Button>
                <Button onClick={handleZoomIn} variant="outline_light">
                  <LuZoomIn size={20} />
                </Button>
              </motion.div>
            </Dialog.Footer>
          </motion.div>
        </AnimatePresence>
      </Dialog.Content>
    </Dialog.Root>
  )
}
