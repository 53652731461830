import styled from 'styled-components'
import { theme } from 'design-cannect'

interface InputFileProps {
  disabled?: boolean
  fullWidth?: boolean
  isMobile?: boolean
}

export const Container = styled.div<InputFileProps>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  display: flex;
  flex-direction: column;
  .header {
    margin-bottom: 16px;
  }
  button {
    background: none;
    border: none;
  }

  .error {
    svg {
      font-size: 40px;
      color: ${theme.colors.tertiary} !important;
    }
    width: 80%;
    text-align: center;
    p {
      margin-top: 4px;
    }
  }

  .emissao {
    margin-top: 16px;
    display: flex;

    align-items: center;
    p {
      margin-right: 16px;
    }
    input {
      padding: 0 8px;
    }
  }

  .dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed ${theme.colors.tertiary};
    border-radius: 8px;
    width: ${({ isMobile }) => (isMobile ? '350px' : '572px')};
    height: 210px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
    background-color: #ecece2;

    .action {
      width: 150px;
      color: ${theme.colors.tertiary};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: normal;
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

      svg {
        font-size: 70px;
      }
    }
  }
  aside {
    margin-top: 18px;
    margin-left: 4px;
    font-size: 12px;
  }
`
