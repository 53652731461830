import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    text-align: center;
  }
`
export const Content = styled.div`
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  span {
    font-size: 18px;
    font-weight: 700;
  }

  @media screen and ${MOBILE_WIDTH} {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
`

export const Link = styled.a`
  font-family: 'Spartan', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${temporaryTheme.colors.link};
  margin-left: 4px;
  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }

  @media screen and ${MOBILE_WIDTH} {
    font-size: 12px;
  }
`

export const ContentActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${temporaryTheme.spacing.space4};
  margin-top: 32px;

  button {
    font-family: 'Spartan', sans-serif;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    width: calc(100% - 22px);
    font-family: 'Spartan', sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  @media screen and ${MOBILE_WIDTH} {
    flex-direction: column;
    button {
      width: 100%;
      text-align: center;
      justify-content: center;
      gap: 8px;
    }

    p {
      width: calc(100% - 42px);
      margin-left: -20px;
    }

    svg {
      width: 38px;
    }
  }
`
