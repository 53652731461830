import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const RecipeProductContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;

  width: 100%;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ProductMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  p {
    font-weight: 300;
  }

  span {
    font-weight: 300;
    font-size: 0.75rem;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-left: auto;

  p {
    font-weight: 300;
  }

  @media ${MOBILE_WIDTH} {
    margin-left: 0;
  }
`
