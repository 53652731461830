import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const TemplateHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  margin-bottom: 2rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const LabelAndInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h2 {
    font-weight: 500;
  }
`
