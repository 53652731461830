import { useLocation, useHistory } from 'react-router-dom'
import { serializeQueryToObject } from '@cannect/utils/query'

// Define the structure for update parameters
type UpdateParams<T> = {
  [K in keyof T]?: T[K] | T[K][] | undefined | null
}

// Custom hook for managing query parameters
export const useQueryFilters = <T extends Record<string, string | string[]>>() => {
  const location = useLocation()
  const history = useHistory()

  // Update existing query parameters
  const updateQueryParams = (params: UpdateParams<T>) => {
    const searchParams = new URLSearchParams(location.search)

    Object.entries(params as Record<string, unknown>).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        // Remove parameter if value is undefined or null
        searchParams.delete(key)
      } else if (Array.isArray(value)) {
        // Handle array values
        searchParams.delete(key)
        value.forEach((item) => searchParams.append(key, String(item)))
      } else {
        // Set or update single value
        searchParams.set(key, String(value))
      }
    })

    history.replace({ search: searchParams.toString() })
  }

  // Replace all existing query parameters with new ones
  const replaceSearchParams = (params: UpdateParams<T>) => {
    const searchParams = new URLSearchParams()

    Object.entries(params as Record<string, unknown>).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // Handle array values
        value.forEach((item) => searchParams.append(key, String(item)))
      } else if (value !== undefined && value !== null) {
        // Set single value if it's not undefined or null
        searchParams.set(key, String(value))
      }
    })

    history.replace({ search: searchParams.toString() })
  }

  // Return hook values and functions
  return {
    queryParams: new URLSearchParams(location.search) as URLSearchParams & T,
    allQueryParams: serializeQueryToObject(new URLSearchParams(location.search)) as T,
    updateQueryParams,
    replaceSearchParams
  }
}
