import useMediaQuery from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { BiCart, BiCheckShield, BiFile } from 'react-icons/bi'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'

interface StepButtonProps {
  step: string
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export function StepButton({ step, active, disabled, onClick }: StepButtonProps) {
  const [background, setBackground] = useState('#ecece2')

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  useEffect(() => {
    if (active) {
      setBackground(`#748351`)
    } else if (!active) {
      setBackground(`#748351`)
    }

    if (disabled) {
      setBackground(`rgba(17, 88, 80, 0.2)`)
    }
  }, [active, disabled])

  return (
    <Style.StepButtonContainer
      style={disabled ? { background, color: 'black' } : { background, color: 'white' }}
      disabled={disabled}
      onClick={onClick}
      isMobile={isMobile}>
      {step === 'cart' && <BiCart size={25} />}
      {step === 'documents' && <BiFile size={25} />}
      {step === 'analytics' && <BiCheckShield size={25} />}
    </Style.StepButtonContainer>
  )
}
