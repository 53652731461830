import * as Accordion from '@radix-ui/react-accordion'
import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { DESKTOP_WIDTH, MOBILE_WIDTH } from 'utils/constants'
import { AccordionPartnesProps } from '.'
import { AnimationResponsive } from './animations'

type WrapperStyleProps = Pick<AccordionPartnesProps, 'size'>

const WrapperModifier = {
  small: () => css`
    ${AccordionContent} {
      height: 200px;
      &[data-state='open'] {
        animation: ${AnimationResponsive.smallDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }

      &[data-state='closed'] {
        animation: ${AnimationResponsive.smallUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
    }
  `,
  medium: () => css`
    ${AccordionContent} {
      height: 230px;
      &[data-state='open'] {
        animation: ${AnimationResponsive.mediumDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }

      &[data-state='closed'] {
        animation: ${AnimationResponsive.mediumUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
    }
  `,
  large: () => css`
    ${AccordionContent} {
      overflow: auto;
      height: 280px;
      &[data-state='open'] {
        animation: ${AnimationResponsive.largeDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }

      &[data-state='closed'] {
        animation: ${AnimationResponsive.largeUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
    }
  `,
  full: () => css`
    ${AccordionContent} {
      overflow: auto;
      width: 100%;
      height: fit-content;
      &[data-state='open'] {
        animation: ${AnimationResponsive.fullDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }

      &[data-state='closed'] {
        animation: ${AnimationResponsive.fullUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
    }
  `
}

export const AccordionRoot = styled(Accordion.Root)``
export const AccordionItem = styled(Accordion.Item)`
  overflow: hidden;
  margin-top: 1px;
`
export const AccordionTrigger = styled(Accordion.Trigger)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${temporaryTheme.spacing.space2} 0;
  background: transparent;
  border: none;
  color: ${temporaryTheme.colors.secondaryTailwind['700']};
  border-bottom: 1px solid ${temporaryTheme.colors.secondaryTailwind['700']};

  .icon-close {
    display: none;
    svg {
      width: 32px;
      height: 32px;
    }
  }

  .icon-open {
    svg {
      width: 32px;
      height: 32px;
    }
  }

  &[data-state='open'] {
    .icon-close {
      display: block;
    }
    .icon-open {
      display: none;
    }
    h2 {
      span {
        color: ${temporaryTheme.colors.primary['700']};
      }
    }
  }

  svg {
    width: 32px;
    height: 32px;
  }
`

export const AccordionContent = styled(Accordion.Content)`
  overflow: auto;
  transition: all 0.5s;
  padding-top: 21px;
  text-align: left;

  @media (${MOBILE_WIDTH}) {
    padding: 20px 16px;
  }
`

export const AccordionHeader = styled(Accordion.Header)``
export const Tittle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: ${temporaryTheme.colors.secondaryTailwind['700']};
  text-align: left;
  width: calc(100% - 34px);
  padding: 8px 0;
  span {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  @media (${MOBILE_WIDTH}) {
    font-size: 18px;
    span {
      font-size: 18px;
    }
  }
`

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ size }) => css`
    width: 100%;
    @media (${DESKTOP_WIDTH}) {
      width: ${size === 'full' ? '100%' : '579px'};
    }

    ${!!size && WrapperModifier[size]};
  `}
`
