import RadioInput from 'components/RadioInput'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import Section from 'pages/CannectAssistant/components/SectionsWrapper/Section'
import * as NeedHelpStyle from '../../NeedHelp/styles'

export default function CbdForm() {
  const {
    canContainThc,
    setCanContainThc,
    canContainOtherCannabinoid,
    setCanContainOtherCannabinoid,
    setIsChosenQuestionsFinished
  } = useCannectAssistant()

  function handleLastQuestionAnswer(newValue: boolean | 'low' | 'moderate') {
    if (typeof newValue === 'boolean') {
      setCanContainOtherCannabinoid(newValue)
    } else {
      setCanContainThc(newValue)
    }
    setIsChosenQuestionsFinished(true)
  }

  return (
    <>
      <Section>
        <h2>Pode conter THC?</h2>
        <NeedHelpStyle.InputsWrapper>
          <RadioInput
            name="canContainThc-low"
            id="canContainThc-low"
            label="Sim, em doses baixas (< 0,3%)"
            checked={canContainThc === 'low'}
            onChange={() => handleLastQuestionAnswer('low')}
          />
          <RadioInput
            name="canContainThc-moderate"
            id="canContainThc-moderate"
            label="Sim, em doses moderadas (> 0,3%)"
            checked={canContainThc === 'moderate'}
            onChange={() => handleLastQuestionAnswer('moderate')}
          />
          <RadioInput
            name="canContainThc-no"
            id="canContainThc-no"
            label="Não"
            checked={canContainThc === 'no'}
            onChange={() => setCanContainThc('no')}
          />
        </NeedHelpStyle.InputsWrapper>
      </Section>
      {canContainThc === 'no' && (
        <Section>
          <h2>Pode conter outros canabinoides?</h2>
          <NeedHelpStyle.InputsWrapper>
            <RadioInput
              name="canContainOtherCannabinoid-yes"
              id="canContainOtherCannabinoid-yes"
              label="Sim"
              checked={canContainOtherCannabinoid === true}
              onChange={() => handleLastQuestionAnswer(true)}
            />
            <RadioInput
              name="canContainOtherCannabinoid-no"
              id="canContainOtherCannabinoid-no"
              label="Não"
              checked={canContainOtherCannabinoid === false}
              onChange={() => handleLastQuestionAnswer(false)}
            />
          </NeedHelpStyle.InputsWrapper>
        </Section>
      )}
    </>
  )
}
