import { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'

export interface FetchLogoPositionResponse {
  logoPosition: 'left' | 'center' | 'right'
  success: boolean
}

export interface FetchClinicLogoResponse {
  clinic_logo: null | string | undefined
  success: boolean
}

export default function usePrescriptionConfig() {
  const [selectedImgStr, setSelectedImgStr] = useState('')
  const [logoPosition, setLogoPosition] = useState('left')
  const [clinicLogo, setClinicLogo] = useState<File>()

  const [loading, setLoading] = useState(false)

  const onFormSubmit = async (event: FormEvent) => {
    event.preventDefault()
    await makeUpdates()
  }

  const makeUpdates = async () => {
    setLoading(true)
    try {
      const promises = []

      const logoPositionData = {
        logoPosition
      }

      promises.push(api.put('/prescriber/logo-position', logoPositionData))

      if (clinicLogo) {
        const formData = new FormData()
        formData.append('file', clinicLogo)

        promises.push(api.put('/prescriber/prescriber_clinic_logo', formData))
      }

      await Promise.all(promises)

      toast.success('Informações atualizadas com sucesso!')
    } catch (err) {
      toast.error('Houve um erro ao atualizar as informações. Tente novamente')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const makeFetches = async () => {
      try {
        setLoading(true)
        const fetchLogoPosition = await api.get<FetchLogoPositionResponse>(`/prescriber/logo-position`)
        const fetchLogo = await api.get<FetchClinicLogoResponse>(`/prescriber/prescriber_clinic_logo`)

        await Promise.all([fetchLogoPosition, fetchLogo]).then(responses => {
          const fetchLogoPositionResponse = responses[0]
          const fetchLogoResponse = responses[1]

          setLogoPosition(fetchLogoPositionResponse.data.logoPosition)
          setSelectedImgStr(fetchLogoResponse.data.clinic_logo ?? '')
        })
      } catch (err) {
        toast.error('Houve um erro ao buscar todas as informações da página. Tente novamente')
      } finally {
        setLoading(false)
      }
    }
    makeFetches()
  }, [])

  return {
    selectedImgStr,
    setSelectedImgStr,
    logoPosition,
    setLogoPosition,
    setClinicLogo,
    onFormSubmit,
    loading
  }
}
