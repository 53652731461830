import Option from './Option'
import * as Style from './styles'

interface QuestionProps {
  title: string
  options: {
    id: string
    labelId: string
    label: string
    checked: boolean
  }[]
  onOptionChoice: (id: string) => void
}

export default function Question({ options, title, onOptionChoice }: QuestionProps) {
  return (
    <Style.QuestionContainer>
      <h2>{title}</h2>
      <Style.QuestionsWrapper>
        {options.map(option => (
          <Option
            key={option.id}
            id={option.labelId}
            label={option.label}
            checked={option.checked}
            onChange={() => onOptionChoice(option.id)}
          />
        ))}
      </Style.QuestionsWrapper>
    </Style.QuestionContainer>
  )
}
