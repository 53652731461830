const zIndex = {
  z1: 100,
  z2: 200,
  z3: 300,
  z4: 400,
  z5: 500,
  z6: 600,
  z7: 700,
  z8: 800,
  z9: 900
}
export const spacing = {
  space1: '4px',
  space2: '8px',
  space3: '16px',
  space4: '24px',
  space5: '32px',
  space6: '40px',
  space7: '48px',
  space8: '56px',
  space9: '64px',
  space10: '72px'
}
const shadow = {
  shad1: '0px 2px 5px 1px rgba(0, 0, 0, 0.05)'
}

export const border = {
  radius: {
    none: '0px',
    small: '4px',
    medium: '8px',
    large: '12px',
    xlarge: '20px',
    pill: '100px',
    circle: '100%'
  },
  style: {
    solid: 'solid',
    dashed: 'dashed'
  },
  width: {
    none: '0px',
    thinner: '1px',
    thin: '2px',
    thick: '4px'
  }
}

const colors = {
  sitePrimary: '#748351',
  secondary: '#F98E2C',
  tertiary: '#115850',
  dark: '#4A596C',
  pathway: '#ECECE2',
  white: '#FFFFFF',
  whiteText: '#FAFAFA',
  whiteBorder: '#D9D9D9',
  link: '#748351',
  linkHover: '#748351',
  blue: '#2FA3DA',
  darkBlue: '#1C2960',
  black: '#000000',
  darkGrey: '#3D3D3D',
  gray: '#E5E5E5',
  lightGrey: '#898483',
  lightDark: '#858585',
  lightHoney: '#F0780A',
  special1: '#D2DDAE',
  special2: '#AFDDAE',
  special3: '#AEDDD2',
  special4: '#AEC6DD',
  special5: '#D9AEDD',
  special6: '#DDAEB9',
  special7: '#DDB9AE',
  special8: '#DDCDAE',
  bgDark: '#424242',
  red: '#E24444',

  // temporary
  primary: {
    50: '#F9F4E7',
    100: '#ECEFE5',
    200: '#D9DFCB',
    300: '#C5CEB0',
    400: '#B2BE96',
    500: '#9FAE7C',
    600: '#8C9E62',
    700: '#748351',
    800: '#5D6941',
    900: '#383F27',
    1000: '#2A2F1D'
  },
  secondaryTailwind: {
    100: '#EDECE7',
    200: '#DBD8D0',
    300: '#C9C5B8',
    400: '#B7B1A1',
    500: '#A69E89',
    600: '#948B72',
    700: '#7d755f',
    800: '#585343',
    900: '#464135',
    1000: '#333027'
  }
}

export const temporaryTheme = {
  zIndex,
  colors,
  spacing,
  shadow,
  border,
  fontSize: {
    h1: '3rem',
    h2: '2.5rem',
    disable1: '6rem',
    disable2: '4rem',
    lg: '1.25rem',

    md: '1rem',
    sml: '0.625rem'
  }
}
