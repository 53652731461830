import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { TABLET_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1px;
  height: 121.11px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${temporaryTheme.shadow.shad1};

  @media (${TABLET_WIDTH}) {
    overflow: auto;
  }
`
export const BaseBox = styled.div`
  background: #f5f5f5;
  height: 100%;
  padding: 9px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ImageBox = styled(BaseBox)`
  width: 57.05px;
`
export const ImageProduct = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`
export const FavoriteBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

export const FavoriteIcon = styled.div`
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
  }
`

export const TitleBox = styled(BaseBox)`
  width: 131.12px;
  min-width: 131.12px;
  max-width: 131.12px;
  justify-content: space-around;
`
export const TitleProduct = styled.p`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 10.01px;
  color: ${temporaryTheme.colors.darkGrey};
  text-align: center;
  flex: 1;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
export const Description = styled.p`
  font-family: 'Spartan', sans-serif;
  font-weight: 400;
  font-size: 10.01px;
  color: #858585;
  text-align: center;
`

export const InfoBox = styled(BaseBox)`
  max-width: 59.06px;
  min-width: 52.44px;
`
export const InfoTitle = styled.div`
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: #3d3d3d;
  flex: 0.5;
`
export const InfoValue = styled.div`
  font-family: 'Spartan', sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: #424242;
  word-break: break-all;
  text-align: center;
`

export const PriceBox = styled(BaseBox)`
  width: 68px;
  min-width: 68px;
  max-width: 68px;
`
export const Price = styled.h2`
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  font-size: 8px;
  color: #3d3d3d;

  span {
    font-family: 'Spartan', sans-serif;
    font-weight: 400;
    font-size: 9px;
  }
`
export const PromotionalPrice = styled.p`
  font-family: 'Spartan', sans-serif;
  font-size: 9px;
  font-weight: 500;
  color: #e24444;
  text-decoration: line-through;
`

export const ActionBox = styled.div`
  max-width: 96px;
  width: 96px;
  background: #f5f5f5;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ButtonProduct = styled.button`
  padding: 4px;
  border-radius: 5px;
  background: ${temporaryTheme.colors.secondary};
  outline: none;
  border: none;
  text-transform: uppercase;
  font-family: 'Nunito', sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: ${temporaryTheme.colors.white};
`
