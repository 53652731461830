import { Button } from 'design-cannect'
import { useHistory } from 'react-router-dom'
import * as Style from './styles'
import TabContentPost from '../TabContentPost'

interface TabContentProps {
  actionButtonText: string
  seeMorePath?: string
  posts: Post[]
  externalRedirect?: boolean
  inLine?: boolean
}

export interface Post {
  id: number
  url: string
  img?: string | undefined
  title: string
  description: string
  externalUrl: string
}

export default function TabContent({
  seeMorePath,
  actionButtonText,
  externalRedirect,
  posts,
  inLine
}: TabContentProps) {
  const history = useHistory()

  function handleRedirect() {
    history.push(seeMorePath as string)
  }

  return (
    <Style.TabContentContainer>
      <Style.ContentItself>
        {posts.map(post => (
          <TabContentPost
            key={post.id}
            title={post.title}
            description={post.description}
            img={post.img}
            url={post.url}
            actionButtonText={actionButtonText}
            externalRedirect={externalRedirect}
            inLine={inLine}
          />
        ))}
      </Style.ContentItself>

      {seeMorePath && (
        <Button outlined onClick={() => handleRedirect()}>
          Mais
        </Button>
      )}
    </Style.TabContentContainer>
  )
}
