import { z } from 'zod'

export const validationFormSchema = z
  .object({
    typeOfBoard: z.string().min(1),
    boardNumber: z.string().trim().min(1),
    boardFu: z.string().min(2),
    hasPrescribedBefore: z.string(),
    typeOfBoardOther: z.string()
  })
  .required()
  .refine(
    data => {
      if (data.typeOfBoard === 'OTHER') {
        return data.typeOfBoardOther.trim().length > 0
      }
      return true
    },
    {
      message: 'Field is required',
      path: ['typeOfBoardOther']
    }
  )
  .refine(
    data => {
      if (
        data.typeOfBoard === 'CRM' ||
        data.typeOfBoard === 'CRO' ||
        data.typeOfBoard === 'CRMV' ||
        data.typeOfBoard === 'RMS'
      ) {
        return data.hasPrescribedBefore.length > 0
      }
      return true
    },
    {
      message: 'Field is required',
      path: ['hasPrescribedBefore']
    }
  )
