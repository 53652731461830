import React from 'react'
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom'
import { defaultsHeadersAxios } from '../services/api'
import { useTokenApi } from '../hooks/useTokenApi'

function Route({ isPrivate = true, component: Component, ...rest }: any) {
  const { tokenApi } = useTokenApi()
  defaultsHeadersAxios(tokenApi as string)
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/login',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

export default Route
