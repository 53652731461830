import { IRecipeProduct, LogicalOperatorsBetweenProducts, Product } from 'hooks/useCannectAssistant/types'
import { Template } from './hooks/useTemplate'

export const fromTemplateToPrescription = (template: Template, prescriptionProducts: Product[]) => {
  const newPrecriptionProducts: Product[] = []
  const newLogicalOperators: LogicalOperatorsBetweenProducts[] = []

  template.products.forEach(product => {
    if (prescriptionProducts.find(p => p.id === product.product.id)) return

    newPrecriptionProducts.push({
      ...product.product,
      includedInPrescription: true,
      bottlesPerYear: product.required_bottles,
      howToUse: product.prescription
    })

    if (product.alternatives.length > 0) {
      newLogicalOperators.push('or')
    } else {
      newLogicalOperators.push('and')
    }

    product.alternatives.forEach((alternative, alternativeIndex) => {
      newPrecriptionProducts.push({
        ...alternative.product,
        includedInPrescription: true,
        bottlesPerYear: alternative.required_bottles,
        howToUse: alternative.prescription
      })
      if (alternativeIndex === product.alternatives.length - 1) {
        newLogicalOperators.push('and')
      } else {
        newLogicalOperators.push('or')
      }
    })
  })

  if (newLogicalOperators.length === newPrecriptionProducts.length) {
    newLogicalOperators.pop()
  }

  return {
    products: newPrecriptionProducts,
    logicalOperatorsBetweenProducts: newLogicalOperators
  }
}

export const fromPrescriptionToApiProducts = (
  products: Product[],
  logicalOperators: LogicalOperatorsBetweenProducts[]
): IRecipeProduct[] => {
  const newRecipeRequestProducts: IRecipeProduct[] = []
  let newRecipeRequestProduct: IRecipeProduct = {
    product_id: 0,
    prescription: '',
    required_bottles: 0,
    alternatives: []
  }
  products.forEach((product, index) => {
    if (index === 0) {
      newRecipeRequestProduct = {
        product_id: product.id,
        prescription: product.howToUse ?? '',
        required_bottles: Number(product.bottlesPerYear),
        alternatives: []
      }
      return
    }
    if (logicalOperators[index - 1] === 'or') {
      newRecipeRequestProduct = {
        ...newRecipeRequestProduct,
        alternatives: [
          ...newRecipeRequestProduct.alternatives,
          {
            product_id: product.id,
            prescription: product.howToUse ?? '',
            required_bottles: Number(product.bottlesPerYear)
          }
        ]
      }
    } else {
      newRecipeRequestProducts.push(newRecipeRequestProduct)
      newRecipeRequestProduct = {
        product_id: product.id,
        prescription: product.howToUse ?? '',
        required_bottles: Number(product.bottlesPerYear),
        alternatives: []
      }
    }
  })
  newRecipeRequestProducts.push(newRecipeRequestProduct)

  return newRecipeRequestProducts
}
