import * as Style from './styles'

interface PathButtonProps {
  img: string
  imgAlt: string
  label: string
  onClick: () => void
  disabled: boolean
}

export default function PathButton({ img, label, imgAlt, onClick, disabled }: PathButtonProps) {
  return (
    <Style.PathButtonContainer onClick={() => onClick()} disabled={disabled}>
      <img src={img} alt={imgAlt} />
      <span>{label}</span>
    </Style.PathButtonContainer>
  )
}
