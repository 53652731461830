import styled from 'styled-components'

export const CheckboxInputContainer = styled.div<any>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: #424242;
  font-weight: 300;

  input {
    display: none;
  }

  label {
    cursor: pointer;
  }
`

export const VisualInput = styled.label<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  border: 1px solid #424242;
  border-radius: 0.25rem;

  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;

  cursor: pointer;
`
