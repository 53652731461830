export const FIELDS_LABELS = {
  name: 'nome completo',
  nationality: 'nacionalidade',
  genderIdentity: 'identidade de gênero',
  cpf: 'CPF',
  rne: 'RNE',
  rg: 'RG',
  birthdate: 'data de nascimento',
  specialties: 'especialidades',
  cellphone: 'celular',
  email: 'e-mail',
  password: 'senha',
  listAsPrescriber: 'listar como prescritor',
  agreedToUseTerms: 'termos de uso',
  emailConfirmation: 'confirmação de e-mail',
  passwordConfirmation: 'confirmação de senha'
}
