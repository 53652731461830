/* eslint-disable no-console */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Button, FormController } from '@cannect/new-components/atoms'
import { InputField, SwitchField } from '@cannect/new-components/molecules'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify'
import { useAuth } from 'hooks/AuthContext'
import { useTokenApi } from 'hooks/useTokenApi'
import { authenticate } from 'usecases/user'
import { defaultsHeadersAxios } from '@cannect/services/api'
import { setUser } from '@cannect/hooks/useSessionUser'
import formsFeedback from '@cannect/constants/formsFeedback'
import { LuEye, LuEyeOff } from 'react-icons/lu'

const loginSchema = z.object({
  email: z.string({ required_error: formsFeedback.requiredField }).email(formsFeedback.invalidEmail),
  password: formsFeedback.nonEmptyStringSchema,
  remindMe: z.boolean().optional()
})

type TLoginForm = {
  onSuccessLogin: (user_id: number) => void
  onLoginError?: (error: Error) => void
  isLoading?: boolean
}

export const LoginForm = ({ onSuccessLogin, onLoginError, isLoading }: TLoginForm) => {
  const { getRoles } = useAuth()
  const { setTokenApi } = useTokenApi()
  const [showPassword, setShowPassword] = useState(false)

  const loginForm = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
      remindMe: false
    }
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = loginForm

  const onSubmit = async (data: z.infer<typeof loginSchema>) => {
    try {
      const response = await authenticate({ email: data.email, password: data.password })
      setTokenApi(response.token)
      defaultsHeadersAxios(response.token)
      await setUser(response.user)
      getRoles()
      onSuccessLogin(response?.user?.id)
    } catch (error) {
      toast.error('E-mail ou senha incorretos.')
      onLoginError?.(error as Error)
    }
  }

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  return (
    <FormController.Form {...loginForm}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-1 flex-col gap-4">
        <InputField variant="filledLight" name="email" label="E-mail" placeholder="Insira o e-mail" control={control} />
        <InputField
          variant="filledLight"
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          placeholder="Insira sua senha"
          control={control}
          rigthElement={React.createElement(showPassword ? LuEye : LuEyeOff, {
            size: 20,
            onClick: togglePasswordVisibility
          })}
        />

        <SwitchField name="remindMe" label="Lembrar de mim" control={control} />

        <Button className="font-medium" full type="submit" isLoading={isSubmitting || isLoading} size="lg">
          Continuar
        </Button>

        {/* <Button unstyled size="lg" full className="font-medium text-primary-700">
          Esqueci minha senha
        </Button> */}

        <ReCAPTCHA
          sitekey="6LfI-rcjAAAAAGtDe1_H4sxhfEXy-gjGIXWY_VL_"
          size="invisible"
          badge="bottomleft"
          onChange={() => console.log('sucesso!')}
        />
      </form>
    </FormController.Form>
  )
}
