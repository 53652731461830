import { barker, belcher, blueBird, cannaRiver, cbdmd } from 'assets/img'
import RocketLauch from 'assets/svg/rocket_lauch_icon.svg'
import UsersThreeIcon from 'assets/svg/UsersThreeIcon.svg'
import { CiDollar } from 'react-icons/ci'

export const AboutUs = `
<strong>A Cannect é o maior ecossistema de cannabis medicinal da América Latina.</strong>
<br/>
<br/>
Com muito <strong>conhecimento científico, tecnologia e postura acolhedora,</strong>
conectamos pacientes e profissionais da
saúde a um portfólio de produtos de qualidade com a missão de ajudar
pessoas na conquista de uma vida melhor através do tratamento
com cannabis medicinal.
<br/>
<br/>
Contamos com uma ampla comunidade de médicos e
dentistas parceiros especializados, um <strong>marketplace</strong> com
mais de 800 produtos, uma <strong>farmácia</strong> especializada em cannabis,  hubs de <strong>distribuição</strong> nos EUA e na
LatAm, uma vertical de <strong>educação</strong> (Dr. Cannabis) e uma
vertical de <strong>conteúdo</strong> (Cannalize).
<br/>
<br/>
Temos muito a construir, JUNTOS!
`

export const Services = `
Criamos soluções para que nossos parceiros possam focar no desenvolvimento de
seu portfólio de produtos e nas suas estratégias de posicionamento e go to market,
 aproveitando nosso conhecimento de mercado e escala operacional para oferecer uma
 jornada integrada e de grande qualidade no cuidado de seus pacientes.
<br/>
<br/>
<strong>Afinal, estamos cuidando da saúde de pessoas!</strong>

`

export const dr_cannabis = `
<strong>Cursos destinados a Profissionais da Saúde, Representantes  Comerciais e Pacientes.</strong>
<br/>
<br/>
Sabemos que sua empresa tem muito a
ensinar, e somos a plataforma de
educação mais adequada para
impulsionar
<br/>
<br/>
este conhecimento, inserindo
sua marca em nossos cursos
<br/>
ou
<br/>
até
mesmo criando cursos personalizados
com a sua identidade.
`

export const education = `
Estamos construindo um modelo único e integrado, associando tecnologia e sensibilidade humana para apoiar a comunidade médica e pacientes.
<br/>
<br/>
Venha atuar conosco e construa uma carreira de sucesso nessa <strong>#revoluçãoverde!</strong>
`

export const DiferencePillars = [
  {
    title: 'Medicina personalizada',
    text: `Medicina Personalizada
    Conectamos pacientes, profissionais da saúde e marcas, oferecendo conhecimento científico,
    inteligência de mercado, tecnologia e produtos de qualidade com muito menos burocracia e
    liberdade de escolha na busca do melhor tratamento para cada indíviduo.`,
    icon: <img src={RocketLauch} alt="rocket lauch icon" />
  },
  {
    title: ' Visibilidade',
    text: `Oferecemos benefícios exclusivos, incluindo portfólio de produtos com curadoria Cannect, preços acessíves, custo de importação reduzido`,
    icon: <CiDollar />
  },
  {
    title: 'Cuidado com o paciente',
    text: `Garantia de entrega em melhor tempo e experiência de atendimento 100% humanizado.`,
    icon: <img src={UsersThreeIcon} alt="users three icon" />
  }
]

export const partnesLogos = [
  {
    image: cbdmd,
    alt: 'logo da CBDMD',
    path: '/marcas/cbd-md'
  },
  {
    image: cannaRiver,
    alt: 'logo da Canna River',
    path: '/marcas/canna-river'
  },
  {
    image: blueBird,
    alt: 'logo da Blue Bird',
    path: '/marcas/bluebird-botanicals'
  },
  {
    image: belcher,
    alt: 'logo da Belcher',
    path: '/marcas/Belcher'
  },
  {
    image: barker,
    alt: 'logo da Barker',
    path: '/marcas/barker'
  }
]

export const AccordionDataPartnes = [
  {
    title: 'Indica',
    text: `
    Descubra o poder transformador do nosso programa que redefine o sucesso das marcas
    e produtos. Com nossa expertise em curadoria técnica e comercial, sua marca se
    destacará como referência no mercado. Você terá à disposição ferramentas exclusivas
    e campanhas direcionadas para médicos e pacientes, fortalecendo laços essenciais.
    Nossa certificação de qualidade reforçará a confiança do público, enquanto diversos formatos
    de conteúdo e campanhas personalizadas impulsionarão o engajamento. Com suporte estratégico em
    redes sociais e assistência dedicada de representantes comerciais, sua marca conquistará voos
    mais altos. Essa solução completa não apenas elevará sua visibilidade, mas também diferenciará
    sua marca, garantindo uma posição de destaque que se destaca na multidão.

    `
  },
  {
    title: 'Soluções Financeiras',
    text: `Impulsione seu negócio com o Cannect Soluções
    Financeiras! Oferecemos uma gama abrangente de ferramentas
    financeiras para otimizar suas operações. Aceite pagamentos
    nacionais e internacionais com segurança e conveniência,
    protegendo-se contra fraudes. Ofereça aos seus clientes opções
    de pagamento flexíveis, como Pix, cartão de crédito e boleto,
    com a facilidade de parcelamento. Gerencie suas vendas e representantes
    de forma eficiente por meio de nossos painéis de controle intuitivos.
    Adapte as políticas comerciais para atender às necessidades exclusivas dos
    seus clientes e maximize sua receita com divisão de pagamentos e antecipação
    de repasse. Impulsione sua marca com nossa plataforma white label de gestão de
    vendas. Aumente a eficiência financeira e alavanque seus resultados com o
    Cannect Soluções Financeiras!`
  },
  {
    title: 'Logistica',
    text: `
    Apresentamos o Cannect Logística, o guia para uma gestão de estoque revolucionária.
    Através do acesso privilegiado aos melhores custo de importação do mercado, sua cadeia de suprimentos
    atingirá níveis inéditos de eficiência. Sua tranquilidade está garantida com nosso seguro
     para pedidos acima de US$1,5 mil, resguardando suas operações de ponta a ponta. Com a
     customização de pacotes para atender às necessidades únicas de cada cliente, você se destacará
     pela excelência no atendimento. Deixe-nos elevar sua logística a um novo patamar, tornando-a
     um diferencial competitivo que redefine os padrões do mercado.
    `
  },
  {
    title: 'Concierge',
    text: `
    Apresentamos o Cannect Concierge, o serviço que coloca o atendimento
    ao cliente e a gestão de pedidos em um novo patamar. Nossa equipe
    de acolhimento proporciona um serviço excepcional para clientes e representantes,
    garantindo que cada pedido seja tratado com a máxima atenção. A curadoria de
    receitas e documentos adiciona um toque de sofisticação, otimizando processos essenciais.
    Simplificamos a gestão e aprovação de documentos, oferecendo uma experiência livre de
    complicações para o cliente. A avaliação do serviço e venda através do NPS nos permite
    continuamente aprimorar o que oferecemos. E você ainda pode contar com uma equipe de acolhimento
    exclusiva, onde sua marca se destacará com um atendimento personalizado de alto nível. Seja sinônimo
    de excelência com o Cannect Concierge, onde cada interação se torna uma experiência memorável.
    `
  },
  {
    title: 'Cuida',
    text: `
    Bem-vindo ao CannectCuida, onde cuidar é nossa missão! Oferecemos suporte médico
    da mais alta qualidade, dando-lhe acesso privilegiado ao Comitê Médico Cannect
    para discussões de casos e orientações valiosas. Com nosso Programa de Cuidado
    Coordenado, garantimos que cada etapa do tratamento de seu paciente seja cuidadosamente
    planejado e executado. Nosso prontuário digital de evolução clínica coloca informações vitais
    ao alcance do médico, simplificando o acompanhamento do paciente. Explore a oportunidade de
    desenvolver estudos clínicos de vida real para tratamentos com seus produtos, desenvolvendo
    uma base de conhecimento que irá  influenciar diretamente a prática médica. Com o CannectCuida,
    estamos ao seu lado, proporcionando um cuidado excepcional que transcende expectativas e
    redefine padrões de saúde, qualidade de vida e bem-estar.
    `
  },
  {
    title: 'Educa',
    text: `
    Apresentamos o CannectEduca, sua fonte definitiva de conhecimento e aprendizado.
    Oferecemos acesso privilegiado à nossa base científica, o que impulsiona a tomada
    de decisões pelos médicos na escolha do tratamento mais adequado para cada indivíduo.
    Com soluções exclusivas voltadas para a Comunidade Médica, como o CannabiCast, webinars, workshops e eventos,
    você cria relacionamentos de qualidade para se diferenciar no mercado. Nossa parceria com o Dr.
    Cannabis garante soluções educacionais de qualidade incomparável. Explore nosso catálogo de
    cursos sob medida para profissionais de saúde e representantes. Crie o seu portal de educação
    exclusivo e torne a sua marca um sinônimo de conhecimento. Personalize cursos de acordo com suas
    necessidades e acelere seu crescimento através do CannectEduca, onde a aprendizagem é a chave para o sucesso duradouro.
    `
  }
]
