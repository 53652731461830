/* eslint-disable import/no-duplicates */
/* eslint-disable react/function-component-definition */
import { parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useScheduling } from 'hooks/useScheduling'
import DatePickerPopup from 'new-components/CustomDatePicker'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { IoCalendarClearOutline } from 'react-icons/io5'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { formatBrazilianDate } from 'utils/formatBrazilianDate'
import { useQueries } from '../../hooks/useQueries'

export const DateSelector = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
  align-items: center;
`

export const DateSelected = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: #3d3d3d;
`

interface DateNavigationProps {
  prescriber_id: number
}

const DateNavigation = ({ prescriber_id }: DateNavigationProps) => {
  const { availableDatesState, loading, loadingPrescribers, getAvailableDates } = useQueries()
  const { watch, setValue } = useFormContext()
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const queries_date = watch('queries_date')

  const availableDatesFiltered = availableDatesState
    .map(date => new Date(date).toISOString().split('T')[0])
    .filter((date, index, self) => self.indexOf(date) === index)

  const getPreviousDate = (currentDate: string, availableDates: string[]) => {
    const currentIndex = availableDates.indexOf(currentDate)
    return currentIndex > 0 ? availableDates[currentIndex - 1] : null
  }

  const getNextDate = (currentDate: string, availableDates: string[]) => {
    const currentIndex = availableDates.indexOf(currentDate)
    return currentIndex < availableDates.length - 1 ? availableDates[currentIndex + 1] : null
  }

  const previousDate = getPreviousDate(queries_date, availableDatesFiltered)
  const nextDate = getNextDate(queries_date, availableDatesFiltered)

  const handleSelectDate = (date: string) => {
    setValue('queries_time', null)
    setValue('queries_date', date)
    getAvailableDates(date, prescriber_id)
  }

  const handleSelectPreviousDate = () => {
    if (previousDate) {
      handleSelectDate(previousDate)
    }
  }

  const handleSelectNextDate = () => {
    if (nextDate) {
      handleSelectDate(nextDate)
    }
  }

  return (
    <DateSelector>
      <BsFillArrowLeftCircleFill
        onClick={handleSelectPreviousDate}
        size={18}
        color={!previousDate || loading || loadingPrescribers ? '#E0E0E0' : '#3D3D3D'}
        style={{
          cursor: !previousDate || loading || loadingPrescribers ? 'not-allowed' : 'pointer'
        }}
      />
      <DateSelected onClick={() => setIsPopupVisible(state => !state)}>
        <IoCalendarClearOutline color={temporaryTheme.colors.sitePrimary} size={18} />
        {!!queries_date && formatBrazilianDate(queries_date)}
        {isPopupVisible && (
          <DatePickerPopup
            withHeading={false}
            availableDates={availableDatesState}
            onClose={() => setIsPopupVisible(false)}
            onSelectDate={handleSelectDate}
          />
        )}
      </DateSelected>
      <BsFillArrowRightCircleFill
        onClick={handleSelectNextDate}
        size={18}
        color={!nextDate || loading || loadingPrescribers ? '#E0E0E0' : '#3D3D3D'}
        style={{
          cursor: !nextDate || loading || loadingPrescribers ? 'not-allowed' : 'pointer',
          pointerEvents: !nextDate || loading || loadingPrescribers ? 'none' : 'auto'
        }}
      />
    </DateSelector>
  )
}

export default DateNavigation
