import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div<any>`
  margin-top: 16px;
  padding: ${props => (props.isMobile ? '0 1rem' : '')};
  .resume {
    display: flex;
  }
`
export const WrapperModal = styled.main`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
  }
`

export const ContentModal = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media screen and ${MOBILE_WIDTH} {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
`
export const ContainerResume = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const ButtonContinue = styled.button`
  border: none;
  background: #748351;
  border-radius: 8px;
  width: 187px;
  height: 40px;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  text-align: center;

  color: #ffffff;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

export const Navigator = styled.div`
  display: flex;
  justify-content: center;
`

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;

  @media ${MOBILE_WIDTH} {
    max-width: 99%;
    overflow-x: auto;
  }
`
export const Line = styled.span`
  margin-left: 5%;
  width: 90%;
  height: 1px;
  background: rgba(119, 119, 119, 0.5);
`
export const ModalContent = styled.div`
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: ${temporaryTheme.spacing.space2};

  .icon-box {
    padding: ${temporaryTheme.spacing.space2};
    margin-bottom: ${temporaryTheme.spacing.space3};
    svg {
      width: 90px;
      height: 90px;
      color: ${temporaryTheme.colors.sitePrimary};
    }
  }

  span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: ${temporaryTheme.colors.darkGrey};
  }

  .action-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1;
  }

  button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
`
