import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const RegistrationContainer = styled.main`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  height: 100%;
  width: 100%;
  padding: 2.5rem 10rem 0;

  & * {
    font-family: 'Spartan', sans-serif;
  }
  & label {
    font-weight: 500;
  }

  @media ${MOBILE_WIDTH} {
    padding: 6rem 1rem 1rem 1rem;
  }

  h1:first-child {
    font-weight: 600;
  }

  h1 {
    font-weight: 400;

    strong {
      font-weight: 600;
    }
  }
`

export const AlreadyHaveAccount = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 1rem;
  margin-bottom: -2rem;
`

export const RegistrationPathsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 3rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    margin-bottom: 5rem;
  }
`
