import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { rolesDoctor } from 'hooks/useSessionUser'
import { useTokenApi } from 'hooks/useTokenApi'
import { Redirect } from 'react-router-dom'
import ShareRecipe from '../ShareRecipe'
import PrescriptionReview from '../PrescriptionReview'
import CompositionSuggestion from './CompositionSuggestion'
import InitialQuestions from '../InitialQuestions'
import PrescriptionInfo from '../AlreadyKnow/PrescriptionInfo'
import * as Style from './styles'

export default function NeedHelp() {
  const { step } = useCannectAssistant()
  const { tokenApi } = useTokenApi()

  return (
    <Style.NeedHelpContainer>
      {step === 1 && <InitialQuestions />}
      {step === 2 && <CompositionSuggestion />}
      {step === 3 && <PrescriptionInfo />}
      {step === 4 && <PrescriptionReview />}
      {step === 5 &&
        (!rolesDoctor() || tokenApi === null ? (
          <Redirect to={{ pathname: '/login', state: { from: '/assistente' } }} />
        ) : (
          <ShareRecipe />
        ))}
    </Style.NeedHelpContainer>
  )
}
