import React from 'react'
import { RiAlertLine } from 'react-icons/ri'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { Container } from './styles'
import Text from '../../Text'
import InputUpdate from '../../InputUpdate'

interface CardDocumentProps {
  title: string
}
function CardDocument({ title }: CardDocumentProps) {
  return (
    <Container>
      <div className="header">
        <div className="title">
          <RiAlertLine />
          <Text type="md" bold color="tertiary">
            {title}
          </Text>
        </div>
        <AiFillQuestionCircle />
      </div>
      <div className="input">
        <InputUpdate fullWidth />
      </div>
    </Container>
  )
}

export default CardDocument
