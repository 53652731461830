/* eslint-disable prettier/prettier */
import { AxiosResponse } from 'axios'
import { IGetPostContent, IGetPostContentRequest, IGetPostSimiliar, IGetSimiliarPost } from 'dtos/Posts'
import strapi from 'services/strapi'

export const getPostBySlug = async (params: IGetPostContentRequest): Promise<IGetPostContent> => {
  const result: AxiosResponse<IGetPostContent> = await strapi.get(
    `/api/posts?filters[$and][0][slug][$eq]=${params.slug}&populate=author&populate=categories`
  )

  return result.data
}

export const getSimiliarPost = async (params: IGetSimiliarPost): Promise<IGetPostSimiliar> => {
  const result: AxiosResponse<IGetPostSimiliar> = await strapi.get(
    `/api/posts?filters[$and][0][categories][slug][$eq]=${params.category}&populate=image&pagination[pageSize]=4`
  )

  return result.data
}
