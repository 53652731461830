/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react'

interface SelectedValueProps {
  title: string
  type: string
}

export interface marketPlaceContextValues {
  selectedValue: SelectedValueProps
  setSelectedValue: (value: SelectedValueProps) => void
  filterOrderList: SelectedValueProps
  setFilterOrderList: (value: SelectedValueProps) => void
  coaFile: any[]
  ChangeCoaFile: (value: any) => void
}

const MarketPlaceContext = createContext<marketPlaceContextValues>({
  setSelectedValue: () => null,
  selectedValue: { title: '', type: '' },
  filterOrderList: { title: '', type: '' },
  setFilterOrderList: () => null,
  coaFile: [],
  ChangeCoaFile: () => null
})

MarketPlaceContext.displayName = 'MarketPlaceContext'

function MarketPlaceProvider({ children }: any) {
  const [selectedValue, setSelectedValue] = useState({ title: 'Em Grade', type: 'grid' })
  const [filterOrderList, setFilterOrderList] = useState({ title: '', type: '' })
  const [coaFile, setCoaFile] = useState([])

  const ChangeCoaFile = (value: any) => {
    setCoaFile(value)
  }

  return (
    <MarketPlaceContext.Provider
      value={{ coaFile, ChangeCoaFile, selectedValue, setSelectedValue, filterOrderList, setFilterOrderList }}
    >
      {children}
    </MarketPlaceContext.Provider>
  )
}

const useMarketPlace = () => useContext(MarketPlaceContext)

export { MarketPlaceProvider, useMarketPlace }
