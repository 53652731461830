import { theme } from 'design-cannect'
import styled from 'styled-components'

interface CustomButtonContainerProps {
  active: boolean
}
export const CustomButtonContainer = styled.div<CustomButtonContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  border: none;
  outline: none;
  font-size: 1.1rem;

  background: transparent;
  color: ${props => (props.active ? `${theme.colors.tertiary}` : 'black')};
  font-weight: ${props => (props.active ? 700 : 300)};

  cursor: pointer;
`
