import React from 'react';
import { Modal, Button } from 'antd';
import * as S from './styles';

interface SelectFlavorModalProps {
  open: boolean;
  handleCancel: () => void;
  isAuthenticated?: boolean;
}

export function ProductModalDisabledNutraceutico({
  open,
  handleCancel,
}: SelectFlavorModalProps) {

  const redirectLink = (link = 'https://atendimento.cannect.life/atendimento-cannect') => {
    window.open(link, '_blank', 'noopener')
  }

  const handleClose = () => {
    handleCancel();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      centered
      footer={null}
      zIndex={1005}
      width={open ? '90%' : 850}
      style={{ maxWidth: 850 }}
    >
      <S.Wrapper>
        <S.Title>Cannect informa</S.Title>

        <S.ContentText>
          No momento, este produto está disponível apenas para compra com nossa equipe de atendimento. O valor de frete para esse produto é R$25,00
        </S.ContentText>

        <S.ActionsContent>
          <Button
            style={{ width: '200px', height: '40px', backgroundColor: '#21976E', color: 'white' }}
            onClick={() => redirectLink('https://api.whatsapp.com/send/?phone=5511982711992&text=Oi%2C+gostaria+de+iniciar+meu+tratamento%21&type=phone_number&app_absent=0 ')}
          >
            Clique aqui para saber mais
          </Button>
        </S.ActionsContent>
      </S.Wrapper>
    </Modal>
  );
}
