import DOMPurify from 'dompurify'
import * as Styles from './styles'
import { cn } from '@cannect/lib/utils'

export type TextDangerProps = {
  text: string
  width?: string
  withMaxLengthText?: boolean
  maxLineText?: number
  fontSize?: string
  fontFamily?: 'Nunito' | 'Spartan' | 'Poppins'
  fontWeight?: number
  justify?: 'left' | 'center' | 'right' | 'justify'
  color?: string
  className?: string
}

export default function TextDanger({
  text,
  width,
  withMaxLengthText,
  maxLineText,
  fontSize,
  fontFamily,
  fontWeight,
  justify,
  color,
  className
}: TextDangerProps) {
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(text).replace(/href/g, "target='_blank' href")
  })

  return (
    <Styles.Container
      justify={justify}
      className={cn('danger-text', className)}
      style={{ padding: '0.5rem' }}
      color={color}
      width={width}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      dangerouslySetInnerHTML={sanitizedData()}
      withMaxLengthText={withMaxLengthText}
      maxLineText={maxLineText}
    />
  )
}
