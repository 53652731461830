/* eslint-disable react/jsx-no-useless-fragment */
import { Badge, Button, Form, Separator, Typography } from '@cannect/new-components/atoms'
import { Product } from '@cannect/new-components/molecules'
import { LuShoppingCart } from 'react-icons/lu'
import { DigitalJourney } from '@cannect/new-components/organisms'

import { useCartStep } from './useCartStep'
import Loading from '@cannect/components/Loading'

export const CartStepUI = ({
  isEmptyCart,
  productsQuantity,
  handleAddProduct,
  handleRemoveProduct,
  handleChangeProductQuantity,
  handleChangeProductFlavor,
  handleApplyCoupon,
  summaryFields,
  productsFields,
  isUpdatingCart,
  loadingProducts,
  applyCouponLoading,
  estimatedArrivalDays,
  couponValue,
  setCouponValue,
  handleNextStep
}: ReturnType<typeof useCartStep>) => {
  return (
    <div className="flex flex-col">
      <Loading loading={isUpdatingCart} />
      <div className="mb-4 flex flex-col items-center gap-2">
        {!isEmptyCart && (
          <>
            <div className="mb-4 flex flex-col items-center gap-2">
              <Typography.Heading className="text-center text-primary-700" type="headingSix">
                Nosso assistente virtual gerou um carrinho automático
              </Typography.Heading>
              <Typography.Text type="paragraphTwo" className="text-center">
                Este carrinho está certo? Você pode alterá-lo de acordo com sua receita atual
              </Typography.Text>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-6 rounded bg-white p-4 shadow-md">
          <>
            <Badge variant="warning" size="lg" rounded="md" className="text-xs">
              Recomendamos a compra de até <span className="font-semibold">3 meses de duração</span> para que você não
              fique sem seu produto!
            </Badge>

            <Typography.Text weight="semibold" className="!-mb-2">
              {!isEmptyCart && 'Produtos adicionados'}
            </Typography.Text>
            {isEmptyCart ? (
              <div className="flex h-full flex-col items-center justify-center">
                <div className="rounded-full bg-primary-100 p-6">
                  <LuShoppingCart className="h-12 w-12 text-primary-700" />
                </div>
                <div className="my-4 space-y-2 text-center">
                  <Typography.Heading type="headingSix" className="text-2xl font-bold">
                    Seu carrinho está vazio
                  </Typography.Heading>
                  <Typography.Text type="paragraphTwo" className="text-[#6C757D]">
                    Para continuar é necessário adicionar pelo menos um produto.
                  </Typography.Text>
                </div>
              </div>
            ) : (
              <>
                {productsFields?.map((product, index) => (
                  <DigitalJourney.CartProductItem
                    key={product.product_id}
                    product={product}
                    handleRemoveProduct={handleRemoveProduct}
                    handleChangeProductQuantity={handleChangeProductQuantity}
                    handleChangeProductFlavor={handleChangeProductFlavor}
                    loadingProducts={loadingProducts}
                    isLastItem={index === productsFields.length - 1}
                  />
                ))}
              </>
            )}

            <DigitalJourney.SheetProductsList
              onAddToCart={handleAddProduct}
              isLoadingAction={(product_id) => loadingProducts[product_id]}
            />
            <Separator />

            <Product.FreeShippingProgress summary={summaryFields} />
            <Separator />

            <div className="flex flex-col gap-5">
              <Badge variant="warning" size="lg" rounded="md" className="text-xs">
                Chegará entre os dias <span className="font-semibold">{estimatedArrivalDays.startDate}</span> e{' '}
                <span className="font-semibold">{estimatedArrivalDays.endDate}</span>.
              </Badge>
              <div className="flex items-end gap-2">
                <div className="flex flex-col gap-2">
                  <Form.Label>Cupom</Form.Label>
                  <Form.Input
                    isDisabled={isEmptyCart}
                    variant="filledLight"
                    placeholder="Insira seu cupom aqui"
                    size="md"
                    onChange={(e) => setCouponValue(e.target.value)}
                    value={couponValue}
                  />
                </div>
                <Button
                  variant="outline"
                  disabled={isEmptyCart}
                  onClick={() => {
                    handleApplyCoupon(couponValue)
                  }}
                  isLoading={applyCouponLoading}
                  className="max-w[86px] h-[38px] min-w-[86px]">
                  {applyCouponLoading ? '' : 'Aplicar'}
                </Button>
              </div>
              <DigitalJourney.CartSummary summaryFields={summaryFields} productsQuantity={productsQuantity} />
            </div>
          </>
        </div>
        <Button
          className="mt-2 font-medium"
          full
          disabled={isUpdatingCart || isEmptyCart}
          size="lg"
          onClick={handleNextStep}>
          Continuar
        </Button>
      </div>
    </div>
  )
}
