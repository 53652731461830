/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
import { useScheduling } from 'hooks/useScheduling'
import { BackButton } from 'pages/AppointmentScheduling/styles'
import { size } from 'lodash'

import { Skeleton } from 'antd'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import PrescriberCard from './PrescriberCard'
import * as Styles from './styles'
import DateNavigation from './DateNavigation'
import { FaArrowLeft } from 'react-icons/fa'

export default function SchedulePrescriber() {
  const { back, prescribersList, loadingPrescribers } = useScheduling()
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const handleGoBack = () => {
    back()
  }

  return (
    <Styles.Container>
      <Styles.HeaderContainer>
        <Styles.HeaderContent>
        <BackButton onClick={handleGoBack}>
          <FaArrowLeft style={{ marginRight: '8px' }} />
            Voltar
          </BackButton>
           <Styles.NavigationContainer>
            {!isMobile && (
              <Styles.Text>
                {size(prescribersList)} profission{size(prescribersList) > 1 ? 'ais' : 'al'} encontrado
                {size(prescribersList) > 1 && 's'}
              </Styles.Text>
            )}

            <DateNavigation />
          </Styles.NavigationContainer>
        </Styles.HeaderContent>
      </Styles.HeaderContainer>
      {loadingPrescribers ? (
        <div style={{ width: '100%' }}>
          <Skeleton active paragraph={{ rows: 4 }} round />
        </div>
      ) : (
        <>
          {prescribersList.length > 0 ? (
            prescribersList.map(prescriber => <PrescriberCard key={prescriber.id} prescriberInfo={prescriber} />)
          ) : (
            <Styles.EmptyPrescriberContainer>Nenhum profissional encontrado.</Styles.EmptyPrescriberContainer>
          )}
        </>
      )}
    </Styles.Container>
  )
}
