import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import { FormEvent, useEffect, useState } from 'react'
import { pngBirdIdLogo } from 'assets/img'
import { clearMask, formatCpf, keepOnlyDigits } from 'utils/formatters'
import api from 'services/api'
import Loading from 'components/Loading'
import { DigitalSignatureToken } from 'hooks/useCannectAssistant/types'
import * as Style from './styles'

const expirationOptions = [
  {
    value: 3600, // expiração em segundos: 1 hora * 60 minutos * 60 segundos
    label: 'Expirar login em 1 hora'
  },
  {
    value: 7200, // expiração em segundos: 2 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 2 horas'
  },
  {
    value: 14400, // expiração em segundos: 4 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 4 horas'
  },
  {
    value: 28800, // expiração em segundos: 8 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 8 horas'
  },
  {
    value: 43200, // expiração em segundos: 12 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 12 horas'
  }
]

const BIRD_ID_TOKEN = '@CANNECT_BIRDID_TOKEN'

type Expiration = 3600 | 7200 | 14400 | 28800 | 43200

type BirdIDProps = {
  onSign: (success: boolean) => void
}

type FetchAuthenticationResponse = {
  token: string
}

export default function BirdID({ onSign }: BirdIDProps) {
  const [expirationTime, setExpirationTime] = useState<Expiration>(3600)
  const [cpf, setCpf] = useState('')
  const [birdIdToken, setBirdIdToken] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem(BIRD_ID_TOKEN)) {
      const birdIdToken: DigitalSignatureToken = JSON.parse(localStorage.getItem(BIRD_ID_TOKEN) as string)
      const currentDate = new Date().getTime()
      if (currentDate < Number(birdIdToken.expiration)) {
        onSign(true)
      } else {
        localStorage.removeItem(BIRD_ID_TOKEN)
      }
    }
  }, [])

  async function handleSignature(event?: FormEvent) {
    if (event) {
      event.preventDefault()
    }
    setLoading(true)
    try {
      const response = await api.post<FetchAuthenticationResponse>(`/signature_authentication`, {
        username: clearMask(cpf),
        password: birdIdToken,
        lifetime: expirationTime,
        provider: 'bird_id'
      })
      const tokenObject = {
        token: response.data.token,
        expiration: calculateExpiration()
      }
      localStorage.setItem(BIRD_ID_TOKEN, JSON.stringify(tokenObject))
      onSign(true)
    } catch (err) {
      console.error(err)
      onSign(false)
    }
    setLoading(false)
  }

  function calculateExpiration() {
    let currentDate = new Date().getTime()
    currentDate += expirationTime * 1000
    return currentDate
  }

  return (
    <Style.BirdIDContainer onSubmit={event => handleSignature(event)}>
      <img src={pngBirdIdLogo} alt="birdID" />
      <h1>Assinar digitalmente</h1>
      <p>
        Acesse o birdID e informe seu nome de
        <br />
        usuário e o token de 6 digitos gerados
      </p>
      <Select
        value={expirationTime}
        onChange={event => setExpirationTime(Number(event.target.value) as Expiration)}
        width="100%"
      >
        {expirationOptions.map(expirationOption => (
          <Style.OptionSelect key={expirationOption.value} value={expirationOption.value}>
            {expirationOption.label}
          </Style.OptionSelect>
        ))}
      </Select>

      <Input
        width="100%"
        placeholder="CPF"
        value={cpf}
        onChange={event => setCpf(formatCpf(event.target.value))}
        required
      />
      <Input
        width="100%"
        placeholder="Insira o token que aparece eu seu app birdID"
        value={birdIdToken}
        required
        onChange={event => setBirdIdToken(keepOnlyDigits(event.target.value))}
      />

      <Button type="submit" style={{ marginTop: '2rem' }}>
        Autenticar
      </Button>
      {loading && <Loading loading={loading} />}
    </Style.BirdIDContainer>
  )
}
