import { pngCannabis } from 'assets/img'
import { svgCannabicast } from 'assets/svg'
import BreadCrumb from 'components/BreadCrumb'
import EducateAside from 'components/CannectEducate/EducateAside'
import EducateNavigator from 'components/CannectEducate/EducateNavigator'
import TabContent, { Post } from 'components/CannectEducate/TabContent'
import TabContentTitle from 'components/CannectEducate/TabContentTitle'
import SocialMedia from 'components/SocialMedia'
import TabsNavigator from 'components/TabsNavigator'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import api from 'services/api'
import * as Style from 'styles/cannectEducate'

export interface Podcast {
  description: string
  id: number
  link: string
  title: string
  img: string
  createdAt: Date
  updatedAt: Date
}

export interface FetchPodcastsResponse {
  success: boolean
  podcasts: {
    count: number
    rows: Podcast[]
  }
}

export function formatPodcasts(podcasts: Podcast[]): Post[] {
  const newPodcasts: Post[] = []
  podcasts.forEach((podcast) => {
    newPodcasts.push({
      id: podcast.id,
      description: podcast.description,
      img: podcast.img ? podcast.img : pngCannabis,
      title: podcast.title,
      url: podcast.link,
      externalUrl: podcast.link
    })
  })

  return newPodcasts
}

export default function CannabicastHome() {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [posts, setPosts] = useState<Post[]>([])
  const [postsQuantity, setPostsQuantity] = useState(0)

  useEffect(() => {
    fetchPosts()
  }, [])

  async function fetchPosts() {
    try {
      const response = await api.get<FetchPodcastsResponse>(`/podcasts/${page}?limit=12`)

      const newPosts = formatPodcasts(response.data.podcasts.rows)
      setPosts([...posts, ...newPosts])

      if (page === 1) {
        setPostsQuantity(response.data.podcasts.count)
        setTotalPages(Math.ceil(response.data.podcasts.count / 12))
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Style.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Educa', link: '/educa' },
          { label: 'Cannabicast', link: '/educa/podcasts' }
        ]}
      />
      <TabsNavigator />
      <EducateNavigator />

      <Style.Content>
        <EducateAside />

        <Style.Wrapper>
          <SocialMedia message="Acesse já o Cannabicast!" />

          <TabContentTitle
            title="Cannabicast"
            titleImg={svgCannabicast}
            postsCountText={`${postsQuantity} podcasts encontrados`}
          />
          <InfiniteScroll
            dataLength={posts.length}
            next={() => setPage((state) => state + 1)}
            hasMore={page !== totalPages}
            loader={<div />}
            endMessage={<div />}>
            <TabContent posts={posts} actionButtonText="ESCUTAR" externalRedirect />
          </InfiniteScroll>
        </Style.Wrapper>
      </Style.Content>
    </Style.Container>
  )
}
