/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    font-size: 1.5rem;
  }

  & * {
    color: #3d3d3d;
  }
`

interface InputWrapperProps {
  isBig?: boolean
  hasError: boolean
  isFocused: boolean
  disabled: boolean
  height?: string
}
export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  align-items: center;
  background: #ffffff;

  border-radius: ${({ isBig }) => (isBig ? '0.5rem 0.5rem 0 0' : '0.5rem')};
  padding: ${({ isBig }) => (isBig ? '1.6rem 2rem' : '1rem')};
  box-shadow: ${({ isBig }) => (isBig ? '0 1px 2px rgba(0, 0, 0, 0.2)' : 'none')};

  border: ${({ hasError, isFocused }) =>
    isFocused ? '1px solid #3d3d3d' : hasError ? '1px solid #e24444' : '1px solid #d9d9d9'};
  height: ${({ isBig, height }) => (isBig ? 'fit-content' : height || '3.7rem')};

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  width: 100%;

  svg {
    width: ${({ isBig }) => (isBig ? '1.75rem' : '1.15rem')};
    height: ${({ isBig }) => (isBig ? '1.75rem' : '1.15rem')};
    min-width: ${({ isBig }) => (isBig ? '1.75rem' : '1.15rem')};
    min-height: ${({ isBig }) => (isBig ? '1.75rem' : '1.15rem')};
  }
`

interface InputItselfProps {
  isBig?: boolean
  withPrefix?: boolean
}
export const InputItself = styled.input<InputItselfProps>`
  height: 100%;

  border: none;
  background: transparent;
  font-weight: 400;

  font-size: ${({ isBig }) => (isBig ? '1.25rem' : '1rem')};
  height: ${({ isBig }) => (isBig ? '1.6rem' : 'fit-content')};
  width: ${({ withPrefix }) => (withPrefix ? 'fit-content' : '100%')};

  &::placeholder {
    color: #d9d9d9;
    opacity: 1;
    font-weight: 300;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const InputErrorMessage = styled.p`
  font-size: 1rem;
  color: #e24444;
  margin-top: -0.5rem;
  font-weight: 300;
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

export const Prefix = styled.p`
  margin-right: ${temporaryTheme.spacing.space1};
  color: rgb(157 157 157);
`
