import Texts from 'components/Text'
import { IStrapiPostContent } from 'dtos/Posts'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import * as Styles from './styles'

interface AuthorIdProps {
  posts: IStrapiPostContent[]
}

function CardAuthor({ posts }: AuthorIdProps) {
  return (
    <Styles.Container>
      {posts?.map(post => {
        return (
          <Styles.CardAuthor>
            <img src={post.attributes.author.data.attributes.thumb} alt="foto autor" />
            <Styles.InfoBox>
              <Texts type="md" color="black" bold>
                {post.attributes.author.data.attributes.username}
              </Texts>
              <TextDanger text={post.attributes.author.data.attributes.bio} />
            </Styles.InfoBox>
          </Styles.CardAuthor>
        )
      })}
    </Styles.Container>
  )
}

export default CardAuthor
