import { useCannectCare } from 'hooks/useCannectCare'
import { useEffect, useState } from 'react'
import Loading from 'components/Loading'
import ConfirmationDialogue from 'components/ConfirmationDialogue'
import * as Style from './styles'
import { HorizontalContent } from '../styles'
import { ProgressBar, Progress } from './ProgressBar'
import QualityOfLifeAssessment from './QualityOfLifeAssessment'
import DeactivatableButton from '../DeactivatableButton'
import HealthSatisfactionAssessment from './HealthSatisfactionAssessment'
import LifeAssessment from './LifeAssessment'
import GeneralInfoAssessment from './GeneralInfoAssessment'
import LocomotionAssessment from './LocomotionAssessment'
import BadFeelingsAssessment from './BadFeelingsAssessment'
import LifeSatisfactionAssessment from './LifeSatisfactionAssessment'
import Finished from './Finished'

export default function LifeQuality() {
  const { step, setStep, setPath, fetchLifeQualityQuestions, loading } = useCannectCare()
  const [progress, setProgress] = useState<Progress>('6.25%')
  const [isErrorDialogueOpened, setIsErrorDialogueOpened] = useState(false)

  useEffect(() => {
    async function fetchLifeQuality() {
      const successfulyLoaded = await fetchLifeQualityQuestions()

      if (!successfulyLoaded) {
        setIsErrorDialogueOpened(true)
      }
    }
    fetchLifeQuality()
  }, [])

  useEffect(() => {
    switch (step) {
      case 0:
        setProgress('6.25%')
        return
      case 1:
        setProgress('14.75%')
        return
      case 2:
        setProgress('33%')
        return
      case 3:
        setProgress('50%')
        return
      case 4:
        setProgress('62.5%')
        return
      case 5:
        setProgress('70%')
        return
      case 6:
        setProgress('83%')
        return
      case 7:
        setProgress('96.25%')
        return
      case 8:
        setProgress('100%')
        break
      default:
        break
    }
  }, [step])

  function handleGoBack() {
    setPath('')
    setStep(0)
  }

  return (
    <Style.LifeQualityContainer>
      <ProgressBar progress={progress} />
      {step === 0 && (
        <>
          <h1>Avaliação de qualidade de vida</h1>
          <p>
            Este questionário é sobre como você se sente a respeito de sua qualidade de vida, saúde e outras áreas de
            sua vida. Por favor, responda a todas as questões. Se você não tem certeza sobre que resposta dar em uma
            questão, por favor, escolha entre as alternativas a que lhe parece mais apropriada. Por favor, tenha em
            mente seus valores, aspirações, prazeres e preocupações. Nós estamos perguntando o que você acha de sua
            vida, tomando como referência as duas últimas semanas.
          </p>
        </>
      )}

      {step === 1 && <QualityOfLifeAssessment />}
      {step === 2 && <HealthSatisfactionAssessment />}
      {step === 3 && <LifeAssessment />}
      {step === 4 && <GeneralInfoAssessment />}
      {step === 5 && <LocomotionAssessment />}
      {step === 6 && <LifeSatisfactionAssessment />}
      {step === 7 && <BadFeelingsAssessment />}
      {step === 8 && <Finished />}

      {step === 0 && (
        <HorizontalContent style={{ justifyContent: 'space-between' }}>
          <DeactivatableButton outlined onClick={() => setPath('')}>
            Voltar
          </DeactivatableButton>
          <DeactivatableButton onClick={() => setStep(state => state + 1)}>Próximo</DeactivatableButton>
        </HorizontalContent>
      )}

      {loading && <Loading loading={loading} />}

      <ConfirmationDialogue
        isOpened={isErrorDialogueOpened}
        onRequestClose={() => handleGoBack()}
        onConfirmationClick={() => handleGoBack()}
        title="Erro ao carregar as perguntas!"
        description="Houve um erro ao carregar as perguntas do formulário. Tente novamente mais tarde."
        confirmationButtonText="Entendi!"
        confirmationOnlyDialogue
      />
    </Style.LifeQualityContainer>
  )
}
