import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const TabContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  button {
    align-self: flex-end;
  }
`

export const ContentItself = styled.div`
  display: flex;
  gap: 0.7rem;
  flex-wrap: wrap;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    align-items: center;
  }
`
