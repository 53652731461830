import styled, { keyframes } from 'styled-components'
import 'react-calendar/dist/Calendar.css'

const slideIn = keyframes`
from {
  transform: translateX(100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
`

const checkmarkCircle = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke-width: 3px;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35px;
    stroke-width: 3px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124px;
    stroke-width: 3px;
  }
`

const checkmark = keyframes`
  0% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 0;
  }
`

export const TimeButton = styled.button`
  width: 48.5%;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  animation: ${slideIn} 0.3s ease forwards;
  font-size: 0.9rem;
`

export const NextButton = styled.button`
  width: 48.5%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  animation: ${slideIn} 0.3s ease forwards;
  font-size: 0.9rem;
  position: relative;
  transform: translateX(100%);
  opacity: 0;
  animation: ${slideIn} 0.3s ease forwards 0.3s;
`

export const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  width: 80%;

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
    @media (max-width: 1052px) {
      flex-direction: column;
      padding-left: 0;
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -10rem;
    margin-right: 2rem;
    text-align: justify;

    @media (max-width: 1052px) {
      margin-top: 0;
      margin-right: 0;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      color: #1a1a1a;
      margin-top: 140px;
      margin-bottom: 20px;
      text-align: center;
    }

    .icon-text {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        color: #1a1a1a9c;
      }

      .hours-icon {
        background-image: url('/path/to/hours-icon.png');
      }

      .camera-icon {
        background-image: url('/path/to/camera-icon.png');
      }
    }

    .timezone-text {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      font-style: italic;
      color: #555;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .confirmation-text {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #555;
    }

    .help-text {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
      color: #555;
    }
  }

  .calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 1052px) {
      margin-left: 0;
      width: 100%;
    }
  }

  .time-list-or-form {
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 20px;
    width: 300px;
    height: 100%;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;

    @media (max-width: 1052px) {
      position: static;
      margin-left: 0;
      width: 100%;
    }
  }

  .time-list {
    width: 100%;
  }

  .time-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .time-list li {
    padding: 10px;
    border: 1px solid #007bff;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 52px;
    position: relative;
    transition: border 0.1s ease;
  }

  .time-list li.selected {
    background-color: #f0f0f0;
    border: none;
  }

  .time-list li:hover {
    background-color: #e6f7ff;
    border: 2px solid #007bff;
  }

  .dot {
    height: 14px;
    width: 14px;
    background-color: #28a745;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9em;
  }

  .legend .dot {
    margin-right: 5px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3%;
  }

  .form-wrapper {
    width: 100%;
  }

  @media (max-width: 900px) {
    .content-wrapper {
      font-size: 0.875rem;
    }

    .info-container h2 {
      font-size: 24px;
      line-height: 28px;
    }

    .info-container .icon-text {
      font-size: 12px;
    }

    .info-container .timezone-text,
    .info-container .confirmation-text {
      font-size: 12px;
    }

    .calendar-container {
      font-size: 0.875rem;
    }

    .time-list li {
      font-size: 0.875rem;
      padding: 8px;
    }

    .legend {
      font-size: 0.75rem;
    }
  }
`

export const SelectDateContent = styled.div`
  max-width: 500px;
  .select-date-title-content {
    font-size: 21.277px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`

export const Header = styled.h4`
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  color: #333;
`

export const Label = styled.label`
  width: 100%;
  font-size: 14px;
  color: #333;
  margin-bottom: 15px;
`

export const Input = styled.input<{ hasError: boolean }>`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid ${({ hasError }) => (hasError ? 'red' : '#ccc')};
  background-color: #f5f5f5;
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  box-sizing: border-box;
  transition: border-bottom-color 0.3s ease-in-out;

  &:focus {
    border-bottom-color: ${({ hasError }) => (hasError ? 'red' : 'rgb(33, 151, 110)')};
  }
`

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
  display: block;
`

export const ConfirmButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: auto;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
`

export const ConfirmationTitle = styled.h4`
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    margin-left: 15px;
    width: 40px;
    height: 40px;
    stroke: green;
    stroke-width: 3px;
    fill: none;
  }

  .circle {
    stroke: green;
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: ${checkmarkCircle} 0.6s ease-in-out forwards;
  }

  .check {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: ${checkmark} 0.6s ease-in-out forwards 0.6s;
  }
`

export const ConfirmationSubtitle = styled.p`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  text-align: center;
`

export const EventTitle = styled.h4`
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;
`

export const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-top: 10px;

  .event-detail {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    svg {
      margin-right: 8px;
    }
    span {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`
