/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import Checkbox from 'components/Checkbox'
import AccordionFilter from 'components/AccordionFilter'
import Loading from 'components/Loading'
import Input from 'new-components/Input'
import { sortItemsByName } from 'utils/sortItemByName'
import * as Styled from './styles'
import { FILTER_NAME } from '../BasesCientificas/cienciaConstants'

interface FilterProps {
  handleChange: (key: string, itemId: string | number) => void
  filtersSections: any
  isFetching: boolean
  filtersSectionsKeys: string[]
  clearFilters: () => void
  selectedFilters: { [x: string]: any }
}

function Filters({
  handleChange,
  filtersSections,
  isFetching,
  filtersSectionsKeys,
  clearFilters,
  selectedFilters
}: FilterProps) {
  const [pathologySearch, setPathologySearch] = useState<string>('')
  const [filteredPathologies, setFilteredPathologies] = useState<any>(filtersSections.pathology)

  const handleSearch = (e: any) => {
    const { value } = e.target
    setPathologySearch(value)
    setFilteredPathologies(
      filtersSections.pathology.filter((el: any) => el.name.toUpperCase().includes(value.toUpperCase()))
    )
  }

  useEffect(() => {
    setFilteredPathologies(filtersSections.pathology)
  }, [filtersSections.pathology])

  const renderCheckBox = (nameKey: any) => {
    return (
      <div>
        {nameKey === 'pathology' ? (
          filteredPathologies && filteredPathologies.length > 0 ? (
            sortItemsByName(filteredPathologies)?.map((item: any) => {
              return (
                <Styled.CheckBoxContainer style={{ marginTop: '14px' }}>
                  <Checkbox
                    className="checkbox-filter"
                    checked={item?.checked || false}
                    disabled={item?.disabled || false}
                    label={item?.name || ''}
                    onClick={() => handleChange(nameKey, item.id)}
                  />
                </Styled.CheckBoxContainer>
              )
            })
          ) : (
            <Styled.NoResultsMessage>
              Nenhum resultado encontrado,
              <br /> tente novamente
            </Styled.NoResultsMessage>
          )
        ) : (
          filtersSections[nameKey]?.map((item: any) => {
            return (
              <Styled.CheckBoxContainer>
                <Checkbox
                  className="checkbox-filter"
                  checked={item?.checked || false}
                  disabled={item?.disabled || false}
                  label={item?.name || 'name'}
                  onClick={() => handleChange(nameKey, item.id)}
                />
              </Styled.CheckBoxContainer>
            )
          })
        )}
      </div>
    )
  }

  return (
    <Styled.Container style={{ paddingBottom: '10px' }}>
      {Object.keys(selectedFilters).length > 0 && (
        <Styled.ClearButtonContainer>
          <button onClick={clearFilters}>Limpar Filtros</button>
        </Styled.ClearButtonContainer>
      )}
      {!isFetching ? (
        filtersSectionsKeys?.map((nameKey: any) => {
          return (
            <AccordionFilter title={FILTER_NAME[nameKey]}>
              {nameKey === 'pathology' && (
                <Input
                  type="search"
                  width="240px"
                  height="40px"
                  placeholder="Pesquisar termos"
                  value={pathologySearch}
                  onChange={e => handleSearch(e)}
                />
              )}
              {renderCheckBox(nameKey)}
            </AccordionFilter>
          )
        })
      ) : (
        <Loading loading />
      )}
    </Styled.Container>
  )
}

export default Filters
