import styled from 'styled-components'
import { theme } from 'design-cannect'

interface ContainerProps {
  isColumn?: boolean
  isMobile?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : null)};
  align-items: center;

  gap: 10px;
  padding: 40px 0 40px 0;

  .button-title {
    color: #115850;
    text-decoration: none;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: center;

  text-align: center;
`

export const NavigatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;

  float: right;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  cursor: pointer;
`

export const SubmitButton = styled.button`
  display: flex;

  background-color: ${theme.colors.tertiary};
  color: ${theme.colors.white};
  text-align: center;

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: ${theme.colors.tertiary};

  margin-top: 50px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`

export const SubmitButtonWrapper = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;

  width: 100%;
`

export const InformationWrapper = styled.div<any>``
