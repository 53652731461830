import { forwardRef, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDropzone } from 'react-dropzone'
import { LuAlertCircle as AlertCircle, LuDownloadCloud } from 'react-icons/lu'
import { InputFileProps, TErrorType, TItemError, typeErrors } from './types'

import { Typography } from '../../Typography'
import { ImSpinner2 } from 'react-icons/im'
import { ListFiles } from '@cannect/new-components/molecules'
import { TAcceptFormats, TFileItem } from '@cannect/new-components/molecules/FileItem/types'
import animation from '@cannect/constants/animation'
import { filterAcceptFormats } from '@cannect/new-components/molecules/FileItem/utils'
import { validateFile } from './utils'

const minimumLoadingDuration = 700

export const FileComponent = forwardRef(
  (
    {
      acceptFormats = ['.png', '.jpg', '.jpeg', '.pdf'],
      showListFiles = true,
      canRemove = true,
      canDownload = false,
      canView = true,
      isDisabled = false,
      isInvalid = false,
      maxFiles = 1,
      message,
      onChange,
      showDescriptionFilesAccept,
      showDescriptionLimit,
      value = [],
      onUpload,
      label,
      CustomInputComponent
    }: InputFileProps,
    _
  ) => {
    const [error, setError] = useState<TItemError>()
    const [isLoading, setIsLoading] = useState(false)

    const accept = filterAcceptFormats(acceptFormats)
    const achievedMaxFiles = useMemo(() => value?.length >= maxFiles, [value, maxFiles])

    const { getRootProps, getInputProps } = useDropzone({
      validator: (file) => validateFile(file, accept),
      onError: (err) => {
        if (err) {
          setError(typeErrors.DEFAULT)
          setTimeout(() => {
            setError(undefined)
          }, 3000)
        }
      },
      onDrop: async (acceptFiles, fileRejections) => {
        if (acceptFiles.length) {
          const [itemFile] = acceptFiles

          try {
            setIsLoading(true)

            const item: TFileItem = {
              file: itemFile,
              name: itemFile.name,
              size: itemFile.size,
              type: itemFile?.type as TAcceptFormats,
              file_url: URL.createObjectURL(itemFile)
            }

            if (onUpload) {
              await onUpload(item)
            }
            if (!onUpload) {
              await new Promise((resolve) => {
                // avoid loading flickering
                setTimeout(resolve, minimumLoadingDuration)
              })
            }

            if (value?.length === maxFiles) {
              onChange([item])
            } else if (value?.length < maxFiles) {
              onChange([...(value ?? []), item])
            } else {
              onChange([item])
            }
          } catch (error) {
            setError(typeErrors.DEFAULT)
            setTimeout(() => {
              setError(undefined)
            }, 3000)
          } finally {
            setIsLoading(false)
          }
        }

        if (fileRejections.length) {
          const firstFileRejected = fileRejections.at(0)

          if (firstFileRejected?.errors.length) {
            // I do this, because I think we need to catch error in validator before
            // so if a error is not test before, need to create a new condition on validator
            const errors = firstFileRejected.errors.filter((error) => Object.keys(typeErrors).includes(error.code))

            if (errors.length) {
              const error = errors.at(0)
              const key = error && Object.keys(typeErrors).includes(error.code) ? error.code : 'DEFAULT'
              setError(typeErrors[key as TErrorType])
              setTimeout(() => {
                setError(undefined)
              }, 3000)
            }
          }
        }
      },
      accept,
      maxFiles,
      disabled: isDisabled || isLoading || achievedMaxFiles
    })

    const onRemove = (index: number) => {
      value.splice(index, 1)
      onChange(value)
    }

    return (
      <AnimatePresence initial={false}>
        <motion.div layout className="w-full">
          <AnimatePresence initial={false} mode="popLayout">
            {error && (
              <motion.div
                key="error_file"
                {...animation.slideInRight}
                className="mb-4 flex items-center space-x-4 rounded-2xl bg-critical-100 px-4 py-2">
                <div>
                  <AlertCircle className="text-error-700" />
                </div>
                <div className="flex flex-col">
                  <Typography.Text type="paragraphTwo" weight="bold" className="text-gray-700">
                    {error.title}
                  </Typography.Text>
                  <Typography.Text type="paragraphTwo" className="text-gray-600">
                    {error.subtitle}
                  </Typography.Text>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {label && (
            <Typography.Text type="paragraphOne" weight="regular" className="mb-4 text-gray-500">
              {label}
            </Typography.Text>
          )}

          {CustomInputComponent ? (
            <div
              data-testid="input_file"
              className={`${isDisabled || isLoading || achievedMaxFiles ? 'pointer-events-none cursor-not-allowed opacity-60' : 'cursor-pointer'}`}
              {...getRootProps()}>
              <input data-testid="file_input" {...getInputProps()} />
              {CustomInputComponent}
            </div>
          ) : (
            <div
              data-testid="input_file"
              className={`${isDisabled || isLoading || achievedMaxFiles ? 'pointer-events-none cursor-not-allowed opacity-60' : 'cursor-pointer'} flex h-36 flex-col items-center justify-center rounded border border-dashed border-primary-700 bg-primary-100 px-4`}
              {...getRootProps()}>
              <input data-testid="file_input" {...getInputProps()} />
              {isLoading ? (
                <ImSpinner2 size={22} className="mb-2 animate-spin fill-primary-700" />
              ) : (
                <LuDownloadCloud size={22} className="mb-2 text-primary-700" />
              )}

              <Typography.Text type="paragraphTwo" weight="regular" className="text-center text-muted">
                Clique para selecionar ou arraste e solte o{' '}
                <span className="font-medium text-primary-700">arquivo</span>.
              </Typography.Text>
            </div>
          )}

          {!!message && (
            <small>
              <Typography.Text type="captionOne" className={`${isInvalid ? 'text-error-700' : 'text-gray-500'} my-2`}>
                {message}
              </Typography.Text>
            </small>
          )}

          <div className="mt-3 space-y-3">
            {showDescriptionFilesAccept && value?.length < maxFiles && (
              <Typography.Text type="captionOne" className="text-gray-500">
                É possível fazer o upload de arquivos do tipo{' '}
                <span className="font-sans font-medium text-gray-700">
                  {acceptFormats
                    .map((item) => item?.slice(1)?.toUpperCase())
                    .slice(0, acceptFormats.length - 1)
                    .join(', ')}{' '}
                </span>
                e{' '}
                <span className="font-sans font-medium text-gray-700">
                  {acceptFormats
                    .at(acceptFormats.length - 1)
                    ?.slice(1)
                    ?.toUpperCase()}
                </span>
                .
              </Typography.Text>
            )}

            {showDescriptionLimit && maxFiles > 0 && (
              <Typography.Text type="paragraphTwo" muted>
                Limite de arquivos: {`${value?.length ?? 0}/${maxFiles}`}
              </Typography.Text>
            )}
          </div>

          {showListFiles && (
            <div className="mt-4">
              <ListFiles
                list={value}
                onRemove={onRemove}
                canRemove={canRemove}
                canDownload={canDownload}
                canView={canView}
              />
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    )
  }
)
