/* eslint-disable import/no-duplicates */

import { useScheduling } from 'hooks/useScheduling'

import Resume from './Resume'
import BillingAddress from './BillingAddress'
import PaymentMethod from './PaymentMethod'

import TextDanger from 'new-components/partnesComponents/TextDanger'
import Button from 'new-components/Button'
import { useHistory } from 'react-router'

import ContinueYourJourney from 'pages/AppointmentScheduling/ContinueYourJourney'
import AppointmentDetails from './AppointmentDetails'
import AppointmentConditions from './AppointmentConditions'
import * as Styled from './styles'

function PaymentStep() {
  const history = useHistory()
  const { paidOrCanceledScheduling, resetContextValues } = useScheduling()

  const statusName = paidOrCanceledScheduling?.current_status?.status_description?.name

  const statusTitle = statusName === 'canceled' ? 'Esse agendamento foi cancelado.' : 'Essa consulta já foi paga!'
  const statusDescription =
    statusName === 'canceled'
      ? 'O link de pagamento expirou. Para agendar uma nova consulta, clique no botão abaixo. <br/><br/> Se tiver alguma dúvida ou necessitar de auxílio, nossa equipe de suporte está disponível para ajudá-lo(a). <br/>Não hesite em entrar em contato conosco através do e-mail faleconosco@cannect.com.br'
      : 'Você receberá um e-mail de confirmação contendo o horário agendado e o link para acessar a sua consulta. <br/><br/>Se tiver alguma dúvida ou precise de assistência, nossa equipe de suporte está disponível para ajudá-lo(a). <br/>Não hesite em entrar em contato conosco através do e-mail faleconosco@cannect.com.br'

  const handleClick = () => {
    history.push('/agendamento')
    resetContextValues()
  }

  return (
    <Styled.Container>
      {paidOrCanceledScheduling ? (
        <Styled.StatusWrapper style={{ gridArea: 'status' }}>
          <Styled.StatusTitle>{statusTitle}</Styled.StatusTitle>
          <TextDanger justify="center" text={statusDescription} />
          <Button onClick={handleClick}>Agendar outra consulta</Button>
          <ContinueYourJourney />
        </Styled.StatusWrapper>
      ) : (
        <>
          <BillingAddress />
          <AppointmentDetails />
          <Resume />
          <AppointmentConditions />
          <PaymentMethod />
        </>
      )}
    </Styled.Container>
  )
}

export default PaymentStep
