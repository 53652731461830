import Button from 'new-components/Button'
import { useQueries } from 'pages/MyQueries/hooks/useQueries'
import { ButtonAnimated } from './ButtonAnimated'
import * as Styles from './styles'

interface CancelProps {
  onReschedule?: () => void
  onCancel?: () => void
}

export function CancelBefore24hours({ onReschedule, onCancel }: CancelProps) {
  const { loadingConsult } = useQueries()
  return (
    <Styles.Wrapper>
      <Styles.Heading>Parece que você deseja cancelar a consulta. </Styles.Heading>

      <Styles.Description>
        <strong>Você pode reagendar sua consulta gratuitamente. </strong> No entanto, caso realmente deseje cancelar,
        <strong> o valor da consulta será estornado. </strong> Verifique as regras de reembolso{' '}
        <Styles.LinkAction href="https://www.cannect.life/politica-de-agendamento" target="_blank">
          {' '}
          clicando aqui.
        </Styles.LinkAction>
      </Styles.Description>

      <Styles.ActionsBox>
        <p>Como deseja prosseguir?</p>

        <div className="content_button">
          <ButtonAnimated
            bgColor="#E24444"
            text="Cancelar a consulta"
            textLoading="Cancelando a consulta..."
            isLoading={loadingConsult.loadCancel}
            disabled={loadingConsult.loadCancel}
            onClick={onCancel}
          />
          <Button background="green" onClick={onReschedule}>
            Reagendar a consulta
          </Button>
        </div>
      </Styles.ActionsBox>
    </Styles.Wrapper>
  )
}
