import styled from 'styled-components'
import { theme } from 'design-cannect'
import { MOBILE_WIDTH } from '../../../../../utils/constants'

export const Container = styled.div`
  width: 100%;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 36px;
  color: #3d3d3d;
`

export const Texts = styled.div`
  width: 100%;
  margin-top: 35px;
  p {
    width: 80%;
    color: #3d3d3d;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
  }
`

export const SectionContainer = styled.section<any>`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  padding: 2.5rem 1.5rem;
  border-bottom: 1px solid #ecece2;

  width: 90%;

  h2 {
    color: #424242;
  }

  p.product-explanation {
    color: ${theme.colors.tertiary};
    font-weight: 300;
    max-width: 600px;

    text-align: center;
    margin-top: 2rem;
  }

  > button {
    color: ${theme.colors.tertiary};
    margin-top: 2rem;
  }

  > object {
    border: 1px solid #ecece2;
    border-radius: 20px;
    margin-top: 1rem;
  }

  textarea {
    resize: none;
  }

  @media ${MOBILE_WIDTH} {
    width: 98%;
  }
`
