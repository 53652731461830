import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main``
export const SectionCategory = styled.div`
  margin-bottom: 90px;
`

export const HeadingCategory = styled.div`
  color: ${temporaryTheme.colors.sitePrimary};
  font-family: 'Spartan', sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: ${temporaryTheme.spacing.space7};
`

interface CardListProps {
  isFiltered?: boolean
}

export const CardList = styled.div<CardListProps>`
  ${({ isFiltered }) => css`
  display: flex;
  align-items: center;
  justify-content: ${isFiltered ? 'flex-start' : 'space-between'};
  gap ${temporaryTheme.spacing.space4};
  flex-wrap: ${isFiltered ? '' : 'wrap'};

@media ${MOBILE_WIDTH} {
    flex-direction: column;
    justify-content: center;
  }

`}
`

export const AcctionCategory = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${temporaryTheme.spacing.space4};
`
