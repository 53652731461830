import { tv } from 'tailwind-variants'

import React from 'react'
import { motion } from 'framer-motion'
import { RotateBetweenText } from '@cannect/new-components/atoms/RotateBetweenText'
import { ProgressBar, Typography } from '@cannect/new-components/atoms'
import { PHRASES, STATUS_CONFIG } from './constants'

import { TDocumentReadingProgress } from './types'
import { useProgressUpdate } from './useProgressUpdate'

const progressStyles = tv({
  slots: {
    container: 'rounded border-solid border-neutral-500 bg-white px-6 py-4',
    icon: 'text-4xl',
    title: 'mt-1 text-xs font-semibold',
    content: 'flex items-center gap-2'
  },
  variants: {
    status: {
      loading: {
        container: 'border-information-300',
        icon: 'animate-spin text-information-300'
      },
      error: {
        container: 'border-error-700',
        icon: 'text-error-700'
      },
      success: {
        container: 'border-success-700',
        icon: 'text-success-700'
      }
    }
  }
})

export const DocumentReadingProgress = ({ status, onError, onSuccess }: TDocumentReadingProgress) => {
  const progress = useProgressUpdate({ status, onError, onSuccess })

  if (status === 'idle') return null

  const { container, icon, title, content } = progressStyles({ status })
  const { icon: IconComponent, title: titleText, description: descriptionText } = STATUS_CONFIG[status] || {}

  // @TODO voltar aqui para ajustar
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3 }}
      className={container()}>
      <div className={content()}>
        {IconComponent && <IconComponent className={icon()} />}
        {status === 'loading' ? (
          <div>
            <RotateBetweenText words={PHRASES} className={title()} />
            <ProgressBar value={progress} className="mt-2 w-full" />
            <Typography.Text className="mt-1" type="captionOne">
              {Math.round(progress)}% concluído
            </Typography.Text>
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}>
            <Typography.Text className={title()}>{titleText}</Typography.Text>
            <Typography.Text type="captionOne">{descriptionText}</Typography.Text>
          </motion.div>
        )}
      </div>
    </motion.div>
  )
}
