import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import useMediaQuery from 'hooks/useMediaQuery'
import axios from 'axios'
import { noop } from 'lodash'
import { MOBILE_WIDTH } from 'utils/constants'
import { clearMask, formatZipCode } from 'utils/formatters'
import * as Style from './styles'
import FormAddress from './FormAddress'
import { StyledCheckbox } from '@cannect/pages/AppointmentScheduling/FormSteps/CheckoutStep/PaymentStep/styles'

export interface AddressProps {
  cep?: string
  state?: string
  city?: string
  address_number?: string
  street?: string
  district?: string
  complement?: string
}

interface AddressType {
  setIsAllAddress: Dispatch<SetStateAction<boolean>>
  handleAddress: any
}
export function Address({ setIsAllAddress, handleAddress }: AddressType) {
  const [activeBilling, setActiveBilling] = useState(false)
  const [billingAddress, setBillingAddress] = useState<AddressProps>({ address_number: '' })
  const [shippingAddress, setShippingAddress] = useState<AddressProps>({ address_number: '' })
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const changeShippingAddress = ({ fieldToChange, newValue, openLoading = noop, closeLoading = noop }: any) => {
    const newShippingAddress = { ...shippingAddress, [fieldToChange]: newValue }
    setShippingAddress(newShippingAddress)

    handleAddress('shippingAddress', newShippingAddress)
    if (fieldToChange === 'cep' && newValue.length === 9) {
      fetchZipCode(clearMask(newValue), 'shipping', openLoading, closeLoading)
    }
  }

  const changeBillingAddress = ({ fieldToChange, newValue, openLoading = noop, closeLoading = noop }: any) => {
    const newBillingAddress = { ...billingAddress, [fieldToChange]: newValue }
    setBillingAddress(newBillingAddress)

    handleAddress('billingAddress', newBillingAddress)

    if (fieldToChange === 'cep' && newValue.length === 9) {
      fetchZipCode(clearMask(newValue), 'billing', openLoading, closeLoading)
    }
  }

  async function fetchZipCode(
    cep: string,
    type: 'shipping' | 'billing',
    openLoading: () => void,
    closeLoading: () => void
  ) {
    openLoading()
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      const data = response?.data ?? {}
      if (type === 'shipping') {
        setShippingAddress(prevShippingAddress => ({
          ...prevShippingAddress,
          cep: formatZipCode(data.cep ?? ''),
          state: data.uf,
          street: data.logradouro,
          district: data.bairro,
          address_number: '',
          city: data.localidade
        }))
      }
    } catch (err: any) {
      console.error(err)
    } finally {
      closeLoading()
    }
  }

  function isAddressValid(address: AddressProps): boolean {
    const { complement, ...mandatoryFields } = address
    return Object.values(mandatoryFields).every(value => value !== undefined && value.trim() != "")
  }

  const isValidAllAdress = () => {
    const isShippingAddressComplete = isAddressValid(shippingAddress)
    if (!activeBilling && isShippingAddressComplete) {
      setIsAllAddress(true)
    } else if (activeBilling && isShippingAddressComplete && isAddressValid(billingAddress)) {
      setIsAllAddress(true)
    } else {
      setIsAllAddress(false)
    }
  }

  useEffect(() => {
    isValidAllAdress()
  }, [shippingAddress, billingAddress, activeBilling])

  return (
    <Style.Wrapper isMobile={isMobile}>
      <Style.Container isMobile={isMobile}>
        <Style.Title>
          Endereço de entrega
          <p>*Endereço de entrega precisa ser o mesmo do comprovante de residência</p>
        </Style.Title>
        <FormAddress isMobile={isMobile} address={shippingAddress} changeAddress={changeShippingAddress} />
        <>
          <Style.Title>Endereço de cobrança</Style.Title>
          <StyledCheckbox checked={activeBilling} onChange={() => setActiveBilling(!activeBilling)}>
            <span>Adicionar endereço diferente do endereço de entrega</span>
          </StyledCheckbox>
        </>
      </Style.Container>
      {activeBilling && (
        <Style.Container isMobile={isMobile}>
          <Style.Title>Endereço de cobrança</Style.Title>
          <FormAddress isMobile={isMobile} address={billingAddress} changeAddress={changeBillingAddress} />
        </Style.Container>
      )}
    </Style.Wrapper>
  )
}
