import InvisibleButton from 'components/InvisibleButton'
import { useHistory } from 'react-router-dom'
import { temporaryTheme } from 'styles/theme'
import { useScheduling } from 'hooks/useScheduling'
import Forms from './components/Forms'
import RegistrationPathButton from './components/RegistrationPathButton'
import useRegistration, { RegistrationContextProvider } from './hooks/useRegistration'
import * as Styles from './styles'

function Registration() {
  const { path, setPath } = useRegistration()
  const { push } = useHistory()

  const handleGoToLogin = () => {
    push('/login')
  }

  return (
    <Styles.RegistrationContainer>
      {!path ? (
        <>
          <Styles.AlreadyHaveAccount>
            Já possui uma conta?
            <InvisibleButton
              type="button"
              color={temporaryTheme.colors.link}
              style={{ textDecoration: 'underline' }}
              onClick={handleGoToLogin}
            >
              Faça login aqui.
            </InvisibleButton>
          </Styles.AlreadyHaveAccount>
          <h1>
            Você é <strong>paciente</strong> ou <strong>profissional da saúde</strong>?
          </h1>

          <Styles.RegistrationPathsWrapper>
            <RegistrationPathButton
              onClick={() => setPath('patient')}
              icon="patient"
              title="Paciente"
              text="Sou paciente, quero encontrar os melhores produtos do mercado e conhecer seus benefícios, encontrar uma
          rede de médicos qualificados e participar de um programa de acompanhamento de cuidado coordenado de saúde."
            />
            <RegistrationPathButton
              onClick={() => setPath('professional')}
              icon="professional"
              title="Profissional da saúde"
              text="Sou profissional de saúde, quero fazer parte desta comunidade encontrando artigos cientificos, apoio à
            prescrição de cannabinoide, os mais variados produtos e o programa Cannect Cuida."
            />
          </Styles.RegistrationPathsWrapper>
        </>
      ) : (
        <Forms onGoBack={() => setPath(undefined)} path={path} />
      )}
    </Styles.RegistrationContainer>
  )
}

export default function RegistrationWrapper() {
  return (
    <RegistrationContextProvider>
      <Registration />
    </RegistrationContextProvider>
  )
}
