import { Button } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.main``
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.horizontal {
    flex-direction: row;
  }
`

export const Heading = styled.h3`
  font-size: 26px;
  font-weight: 700;
  color: #3d3d3d;
`

export const TextDocument = styled.h4`
  font-size: 18px;
  color: #777777;
  font-weight: 500;

  strong {
    color: rgb(45 45 45);
  }
`

export const ImageExempleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
`

export const ImageExemple = styled.img``

export const ActionsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
`
export const AcceptTypeFiles = styled.div`
  color: #3d3d3d;
  width: 100%;
  text-align: center;

  strong {
    color: rgb(45 45 45);
  }
`
export const loadingFileButton = styled.span`
  background: #3d3d3d;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  p {
    color: white;
  }
`

export const HorizontalContent = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
`

export const Box = styled.div``
