import { Button } from 'design-cannect'
import { useHistory, useLocation } from 'react-router-dom'
import * as Style from './styles'

export default function EducateNavigator() {
  const history = useHistory()
  const location = useLocation()

  const links = [
    {
      path: '/educa/cursos-introdutorios',
      label: 'Curso Introdutório'
    },
    {
      path: '/educa/podcasts',
      label: 'Cannabicast'
    },
    {
      path: '/cannect-ciencia',
      label: 'Bases Científicas'
    },
    {
      path: '/whitepapers',
      label: 'Whitepapers'
    },
    {
      path: '/educa/webnars',
      label: 'Webinars'
    },
    {
      path: '/educa/workshops',
      label: 'Workshops'
    }
  ]

  function handleRedirect(path: string) {
    history.push(path)
  }

  return (
    <Style.EducateNavigatorContainer>
      {links.map(link => (
        <Button
          key={link.path}
          outlined
          onClick={() => handleRedirect(link.path)}
          secondary={location.pathname.includes(link.path)}
        >
          {link.label}
        </Button>
      ))}
    </Style.EducateNavigatorContainer>
  )
}
