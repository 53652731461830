import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { DESKTOP_WIDTH, MAX_PAGE_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

interface ImageContentStyle {
  image?: string
}

export const Wrapper = styled.main`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};

  @media (${DESKTOP_WIDTH}) {
    padding: 0 ${temporaryTheme.spacing.space4};
  }

  @media (${TABLET_WIDTH}) {
    padding: 0 ${temporaryTheme.spacing.space4};
  }

  @media (${MOBILE_WIDTH}) {
    padding: 0 ${temporaryTheme.spacing.space2};
  }
`

export const SectionMedicalTreatments = styled.div``

export const SectionWellBeign = styled.div``

export const SectionStartTreatment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${temporaryTheme.spacing.space4};
  gap: ${temporaryTheme.spacing.space7};
`

export const TextSection = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  color: ${temporaryTheme.colors.darkGrey};
`

export const ListIcons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow-x: auto;
  gap: 6%;
  margin: 85px 0;
  margin-top: -0.8rem;
  margin-bottom: -1rem;

  @media (${TABLET_WIDTH}) {
    gap: ${temporaryTheme.spacing.space5};
  }

  @media (${MOBILE_WIDTH}) {
    gap: ${temporaryTheme.spacing.space3};
    justify-content: flex-start;
    padding: 0 ${temporaryTheme.spacing.space3};
  }
`

export const StartTreatmentImageContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${temporaryTheme.spacing.space3};
  width: 50%;

  @media (${MOBILE_WIDTH}) {
    display: none;
  }
`

export const StartTreatmentImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: ${temporaryTheme.spacing.space4} 0;
`

export const StartTreatmentImage01 = styled.div<ImageContentStyle>`
  ${({ image }) => css`
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 22px;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    aspect-ratio: 16 / 9;
  `}
`

export const StartTreatmentImage02 = styled.div<ImageContentStyle>`
  ${({ image }) => css`
    width: 400px;
    height: 400px;
    border-radius: 22px;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  `}
`

export const StartTreatmentTextContent = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space3};
  flex-direction: column;
  @media (${MOBILE_WIDTH}) {
    width: 100%;
  }
`

export const StartTreatmentActionContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${temporaryTheme.spacing.space3};

  @media (${MOBILE_WIDTH}) {
    flex-direction: column;
  }
`
