import * as Style from './styles'

interface Props {
  closeModal: () => void
}

export function FinishedUpdate({ closeModal }: Props) {
  return (
    <Style.Container>
      <span>Seus dados foram atualizados com sucesso!</span>

      <button onClick={() => closeModal()}>FECHAR</button>
    </Style.Container>
  )
}
