import { Button, Illustrations, Typography } from '@cannect/new-components/atoms'
import { useHistory } from 'react-router'

import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useTokenApi } from '@cannect/hooks/useTokenApi'

export const JourneyFinishedStep = () => {
  const history = useHistory()
  const { formData } = useDigitalJourney()
  const { tokenApi } = useTokenApi()

  const handleGoToOrderDetails = () => {
    if (!formData?.common_fields?.order_id) return
    const publicUrl = `/analise-cannect-wa?cart_id=${formData?.common_fields?.order_uuid}`
    const privateUrl = `/detalhes-do-pedido/${formData.common_fields.order_id.toString()}`

    history.push(tokenApi ? privateUrl : publicUrl)
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8 pt-14">
      <Typography.Heading type="headingThree" className="text-center text-primary-700">
        Pedido feito!
      </Typography.Heading>
      <Illustrations.JourneyFinished />
      <Typography.Text type="paragraphTwo" muted weight="semibold" className="text-center">
        Prontinho! Seus produtos chegarão em sua casa em até 25 dias.
      </Typography.Text>

      <Button size="lg" full onClick={handleGoToOrderDetails} className="-mb-4 mt-auto font-medium">
        Acompanhar meu pedido
      </Button>
      <Button
        size="lg"
        full
        className="font-medium"
        variant="outline_light"
        onClick={() => window.open('https://api.whatsapp.com/send?phone=5511916398162', '_blank', 'noopener')}>
        Apoio terapêutico
      </Button>
    </div>
  )
}
