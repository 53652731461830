import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const BirdIDContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;
  width: 100%;

  img {
    width: 215px;
    min-width: 215px;
    height: 120px;
    min-height: 120px;

    margin-bottom: 1rem;
  }

  h1 {
    color: ${theme.colors.tertiary};
  }

  p {
    font-weight: 300;
    margin-bottom: 2rem;
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const OptionSelectContent = styled.div``

export const OptionSelect = styled.option`
  font-family: 'Spartan', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${temporaryTheme.colors.darkGrey};
`
