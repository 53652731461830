import { Button, Separator, Typography, Badge } from '@cannect/new-components/atoms'
import { Product } from '@cannect/new-components/molecules'
import { useHistory } from 'react-router'
import { DIGITAL_JOURNEY_ROUTES } from '../constants'
import { useMemo } from 'react'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { DigitalJourney, ProductDetailsDialog } from '@cannect/new-components/organisms'
import { useConfirmOrderSummaryStepMutation } from '@cannect/services/resources/digitalJourney'

export const OrderSummaryStep = () => {
  const { formData } = useDigitalJourney()

  const { mutate: confirmOrderSummary, isPending } = useConfirmOrderSummaryStepMutation()

  const productsList = useMemo(() => {
    return formData?.cart?.products
  }, [formData])

  const summaryFields = useMemo(() => {
    return formData?.cart?.summary
  }, [formData?.cart?.summary])

  const productsQuantity = productsList?.reduce((acc, curr) => acc + curr.product_quantity, 0)

  const deliveryAddressFields = useMemo(() => {
    return formData?.confirm_proof_of_address_document
  }, [formData?.confirm_proof_of_address_document])

  const history = useHistory()

  const onSubmit = async () => {
    if (!formData.common_fields?.digital_journey_id) return

    await confirmOrderSummary(
      {
        digital_journey_id: formData.common_fields?.digital_journey_id
      },
      {
        onSuccess: (response) => {
          if (response?.next_step) {
            history.push(response.next_step)
          }
        }
      }
    )
  }

  return (
    <div className="mx-auto flex max-w-4xl flex-col gap-2">
      <Typography.Heading className="text-center text-primary-700" type="headingSix">
        Certo, aqui está o resumo do seu pedido.
      </Typography.Heading>

      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
          <Typography.Text weight="semibold" className="!-mb-2">
            Produtos adicionados
          </Typography.Text>
          <Separator />

          {productsList?.map((product) => {
            const isNationalProduct = product?.supplier?.id === 18
            return (
              <Product.Root key={product.product_id} className="gap-4">
                <ProductDetailsDialog productId={product.product_id}>
                  <Product.Header className="gap-x-6">
                    <Product.HeaderInfo
                      name={product?.name}
                      volume={product?.medical_data?.volume}
                      unitOfMeasurement={product?.medical_data?.unit_of_measurement}
                      brandName={product?.brand?.name}
                      className="[&>#product-name]:text-xs"
                    />
                    <Product.HeaderImage
                      className="size-16 justify-self-end border-solid border-neutral-200 shadow-none"
                      src={product?.media?.filter((value) => value.format === 'IMG')?.[0]?.path}
                      alt={product?.name}
                      isNationalProduct={isNationalProduct}
                    />
                    <Product.HeaderBadges>
                      {product?.class?.name && (
                        <Badge variant="neutral" className="text-xxs">
                          {product.class.name}
                        </Badge>
                      )}
                      {product?.flavor && (
                        <Badge className="bg-flavors-default-100 text-xxs text-flavors-default-900">
                          {product?.flavor?.name}
                        </Badge>
                      )}
                    </Product.HeaderBadges>
                  </Product.Header>
                </ProductDetailsDialog>
                <div>
                  <Product.Footer className="-mt-1">
                    <Typography.Text type="paragraphTwo">
                      Qtde:{' '}
                      <span className="font-semibold">
                        {product.product_quantity} {product.product_quantity > 1 ? 'itens' : 'item'}
                      </span>
                    </Typography.Text>
                    <Product.Price
                      currentPrice={product?.real_price}
                      promotionalPrice={product?.valid_promotion?.promotional_price}
                      className="[&>#product-price]:text-base"
                    />
                  </Product.Footer>
                </div>
                <Separator className="mb-0" />
              </Product.Root>
            )
          })}

          <div className="grid grid-cols-1">
            <div className="grid w-full grid-cols-2 gap-3">
              <Typography.Text className="col-span-full" weight="semibold">
                Endereço de entrega
              </Typography.Text>
              <div className="col-span-full">
                <Typography.Text weight="semibold" type="captionOne" muted>
                  Rua
                </Typography.Text>
                <Typography.Text type="captionOne">{deliveryAddressFields?.address_street}</Typography.Text>
              </div>
              <div>
                <Typography.Text weight="semibold" type="captionOne" muted>
                  Número
                </Typography.Text>
                <Typography.Text type="captionOne">{deliveryAddressFields?.address_number}</Typography.Text>
              </div>
              <div>
                <Typography.Text weight="semibold" type="captionOne" muted>
                  Complemento
                </Typography.Text>
                <Typography.Text type="captionOne">{deliveryAddressFields?.address_complement}</Typography.Text>
              </div>
              <div>
                <Typography.Text weight="semibold" type="captionOne" muted>
                  CEP
                </Typography.Text>
                <Typography.Text type="captionOne">{deliveryAddressFields?.address_cep}</Typography.Text>
              </div>
              <div>
                <Typography.Text weight="semibold" type="captionOne" muted>
                  Bairro
                </Typography.Text>
                <Typography.Text type="captionOne">{deliveryAddressFields?.address_neighborhood}</Typography.Text>
              </div>
              <div>
                <Typography.Text weight="semibold" type="captionOne" muted>
                  Cidade
                </Typography.Text>
                <Typography.Text type="captionOne">{deliveryAddressFields?.address_city}</Typography.Text>
              </div>
              <div>
                <Typography.Text weight="semibold" type="captionOne" muted>
                  UF
                </Typography.Text>
                <Typography.Text type="captionOne">{deliveryAddressFields?.address_state}</Typography.Text>
              </div>
            </div>
            <Separator className="my-4" />

            <DigitalJourney.CartSummary summaryFields={summaryFields} productsQuantity={productsQuantity} />
          </div>
        </div>
        <Button
          className="mt-5 font-medium text-primary-700"
          full
          unstyled
          size="lg"
          onClick={() =>
            history.push({
              pathname: DIGITAL_JOURNEY_ROUTES.cart,
              state: { from: DIGITAL_JOURNEY_ROUTES.order_summary }
            })
          }>
          Alterar pedido
        </Button>
        <Button className="mt-1 font-medium" full size="lg" onClick={() => onSubmit()} isLoading={isPending}>
          Ir para o pagamento
        </Button>
      </div>
    </div>
  )
}
