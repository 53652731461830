import axios from 'axios'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import { clearMask } from 'utils/formatters'
import { ZodIssue } from 'zod'
import { RegisterAddressProps } from '..'
import { Address } from '../../ProfessionalAddresses/types'
import { getFieldsWithErrorsMessage } from '../helpers'
import { registerAddressSchema } from '../schemas/registerAddress'

interface FetchAddressWithZipCodeResponse {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  erro: boolean
}

interface FetchAddressResponse {
  address: Address
  success: boolean
}

export default function useRegisterAddress({ addressId }: RegisterAddressProps) {
  const history = useHistory()
  const [zipCode, setZipCode] = useState('')
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [district, setDistrict] = useState('')
  const [city, setCity] = useState('')
  const [fu, setFu] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [loading, setLoading] = useState(false)
  const [isZipCodeInvalid, setIsZipCodeInvalid] = useState(false)
  const [errors, setErrors] = useState<ZodIssue[]>()
  const [fetchedAddress, setFetchedAddress] = useState<Address>()

  useEffect(() => {
    const fetchAddress = async () => {
      setLoading(true)
      try {
        const {
          data: { success, address }
        } = await api.get<FetchAddressResponse>(`/my-account/address/${addressId}`)
        if (!success) {
          toast.error('Houve um erro ao buscar o endereço. Tente novamente mais tarde.')
          return
        }
        setFetchedAddress(address)
        setZipCode(address.cep)
        setStreet(address.address)
        setNumber(String(address.number))
        setComplement(address.complement)
        setDistrict(address.district)
        setCity(address.city)
        setFu(address.state)
        setAdditionalInfo(address.additional_information)
      } catch (err) {
        toast.error('Houve um erro ao buscar o endereço. Tente novamente mais tarde.')
        console.error(err)
      }
      setLoading(false)
    }

    if (addressId) {
      fetchAddress()
    }
  }, [addressId])

  const setFunctions = {
    zipCode: setZipCode,
    street: setStreet,
    number: setNumber,
    district: setDistrict,
    city: setCity,
    fu: setFu
  }

  const removeFieldFromErrors = (fieldName: string) => {
    setErrors(prevErrors => prevErrors?.filter(error => !error.path.includes(fieldName)))
  }

  const changeFieldValue = useCallback(
    (fieldName: string, newValue: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const setFunction = setFunctions[fieldName]
      setFunction(newValue)
      if (errors) {
        removeFieldFromErrors(fieldName)
      }
    },
    [errors]
  )

  const onRegisterAddressSubmit = async (event: FormEvent, goBackLink: string) => {
    event.preventDefault()

    if (isZipCodeInvalid) {
      toast.error('O CEP informado é inválido. Verifique e tente novamente.')
      return
    }

    const requiredFieldsValidation = await registerAddressSchema.safeParseAsync({
      zipCode,
      street,
      number,
      district,
      city,
      fu
    })

    if (!requiredFieldsValidation.success) {
      const newErrors = requiredFieldsValidation.error.errors
      setErrors(newErrors)
      toast.dismiss()
      toast.error(getFieldsWithErrorsMessage(newErrors), {
        autoClose: false
      })
      return
    }
    setErrors(undefined)

    setLoading(true)
    await saveOrUpdateAddress(goBackLink)
    setLoading(false)
  }

  const saveOrUpdateAddress = async (goBackLink: string) => {
    try {
      const url = fetchedAddress ? `/my-account/address/${addressId}` : '/my-account/address'
      await api({
        method: fetchedAddress ? 'PUT' : 'POST',
        url,
        data: {
          address: street,
          number,
          cep: zipCode,
          complement,
          main: true,
          isDelivery: localStorage.getItem('@CANNECT_NEW_ADDRESS_TYPE') === 'delivery',
          isBilling: localStorage.getItem('@CANNECT_NEW_ADDRESS_TYPE') === 'billing',
          district,
          city,
          state: fu,
          additional_information: additionalInfo
        }
      })
      const message = fetchedAddress ? 'Endereço atualizado com sucesso!' : 'Endereço cadastrado com sucesso!'
      toast.success(message)
      history.push(goBackLink)
    } catch (err) {
      console.error(err)
      toast.error('Houve um erro ao tentar cadastrar o endereço. Tente novamente mais tarde.')
    }
  }

  const handleDeleteAddress = async (goBackLink: string) => {
    try {
      await api.delete(`/my-account/address/${addressId}`)
      toast.success('Endereço excluído com sucesso!')
      history.push(goBackLink)
    } catch (err) {
      console.error(err)
      toast.error('Houve um erro ao tentar excluir o endereço. Tente novamente mais tarde.')
    }
  }

  useEffect(() => {
    const fetchAddressThroughZipCode = async () => {
      setLoading(true)
      try {
        const response = await axios.get<FetchAddressWithZipCodeResponse>(
          `https://viacep.com.br/ws/${clearMask(zipCode)}/json/`
        )
        const { data } = response
        if (data.erro) {
          toast.error('CEP não encontrado')
          setIsZipCodeInvalid(true)
          return
        }
        if (data.logradouro?.length > 0) changeFieldValue('street', data.logradouro)
        if (data.bairro?.length > 0) changeFieldValue('district', data.bairro)
        if (data.localidade?.length > 0) changeFieldValue('city', data.localidade)
        if (data.uf?.length > 0) changeFieldValue('fu', data.uf)

        setIsZipCodeInvalid(false)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    if (zipCode.length === 9) {
      fetchAddressThroughZipCode()
    }
  }, [zipCode])

  return {
    fetchedAddress,
    zipCode,
    street,
    number,
    complement,
    setComplement,
    district,
    city,
    fu,
    additionalInfo,
    setAdditionalInfo,
    isZipCodeInvalid,
    errors,
    setErrors,
    changeFieldValue,
    handleDeleteAddress,
    onRegisterAddressSubmit,
    loading
  }
}
