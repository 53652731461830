import { OrderStatusObject, StatusLogs } from '../../../MyOrders/types'
import StatusRule from './components/StatusRule'
import { getGeneralStatus } from './helpers'
import * as Styles from './styles'

interface OrderStatusProps {
  statusHistory: StatusLogs[]
  currentStatus: OrderStatusObject
  isNational: boolean
}
export default function OrderStatus({ statusHistory, currentStatus, isNational }: OrderStatusProps) {
  return (
    <Styles.OrderStatusContainer>
      <h3>{getGeneralStatus(currentStatus.status)}</h3>
      <p>{currentStatus.translated_name.toLowerCase()}</p>

      <StatusRule statusHistory={statusHistory} isOrderNational={isNational} />
    </Styles.OrderStatusContainer>
  )
}
