import { FloatButton } from 'antd'
import { useAuth } from 'hooks/AuthContext'
import { BsWhatsapp } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router-dom'
import { redirectLink } from 'utils/redirectLink'

function WrapperWhatsapp() {
  const urlParams = useLocation().search
  const { role } = useAuth()
  const history = useHistory()

  const isOnTriagemPage = history.location.pathname === '/triagem'
  const isAssistantPage = history.location.pathname === '/assistente'

  const validateText = role === 1 || role === 0 ? 'Fale com um cannecter' : 'Fale com o relacionamento médico'

  const validLink = () => {
    let validateAction
    if (isOnTriagemPage) {
      validateAction = 'https://atendimento.cannect.life/triagem'
    } else if (role === 1 || role === 0) {
      validateAction = urlParams
        ? `https://atendimento.cannect.life/atendimento-cannect/${urlParams}`
        : 'https://atendimento.cannect.life/atendimento-cannect?utm_medium=organic&utm_source=whatsapp&utm_campaign=202409_home_institucional__conversao_continuo_whatsapp_cannect_organic_geral&utm_content=cannect&utm_term=botao'
    } else if (isAssistantPage) {
      validateAction = 'https://wa.me/5511964065425'
    } else {
      validateAction = 'https://wa.me/5511992208271'
    }

    return validateAction
  }

  return !window.location.pathname.includes('receitas') ? (
    <FloatButton.Group type="primary" style={{ right: 40, bottom: 20 }}>
      <FloatButton
        icon={<BsWhatsapp />}
        onClick={() => redirectLink(validLink())}
        tooltip={<div>{validateText}</div>}
      />
      <FloatButton.BackTop />
    </FloatButton.Group>
  ) : null
}

export default WrapperWhatsapp
