import api from 'services/api'
import { DOCTOR_ID_ROLES, PATIENT_ID_ROLES } from '../utils/constants'

const USER = '@CANNECT_USER'

const setUser = async (user: any) => {
  await localStorage.setItem(USER, JSON.stringify(user))
}

const rolesDoctor = () => getUserRoles() === DOCTOR_ID_ROLES
const rolesPatientOrDoctor = () => getUserRoles() >= PATIENT_ID_ROLES

const getUserID = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return JSON.parse(userStorage).id
  }
  return null
}

const getUserUuid = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return JSON.parse(userStorage).uuid
  }
  return null
}

const getUserRoles = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return Number(JSON.parse(userStorage).registration_type)
  }
  return 0
}

const getPrescriberID = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return JSON.parse(userStorage)?.prescriber_id
  }

  return null
}

const getUserEmail = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return JSON.parse(userStorage).email
  }
  return null
}

const deleteUser = () => {
  localStorage.removeItem(USER)
}

const getUserStatus = async () => {
  try {
    const userId = getUserID()
    const { data } = await api.post(`/user_status/${userId}`)
    return data
  } catch (error) {
    return error
  }
}

export {
  getUserID,
  deleteUser,
  setUser,
  getUserRoles,
  rolesDoctor,
  rolesPatientOrDoctor,
  getUserUuid,
  getUserEmail,
  getPrescriberID,
  getUserStatus
}
