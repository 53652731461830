import { theme } from 'design-cannect'
import styled from 'styled-components'

interface LinkButtonContainerProps {
  isImageBig?: boolean
  observation?: string
}

export const LinkButtonContainer = styled.button<LinkButtonContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 0.5rem;
  border: none;
  background: transparent;

  height: 100%;
  transition: transform 0.2s;

  img {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    width: ${props => (props.isImageBig ? '292px' : '207px')};
    min-width: ${props => (props.isImageBig ? '292px' : '207px')};
    height: ${props => (props.isImageBig ? '245px' : '207px')};
    min-height: ${props => (props.isImageBig ? '245px' : '207px')};
  }

  p {
    color: ${theme.colors.tertiary};
    font-weight: 700;
    font-size: 1.2rem;
    max-width: ${props => (props.isImageBig ? '280px' : '205px')};
    text-align: center;

    margin-bottom: ${props => (props.observation ? '1rem' : '0')};
  }

  span {
    font-size: 0.9rem;
    font-weight: 300;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`
