import axios from 'axios'
import LoadingContainer from 'components/LoadingContainer'
import { IGetAllCategoriesHome } from 'dtos/Category'
import { useBlogFilter } from 'hooks/useBlogFilter'
import { debounce } from 'lodash'
import Button from 'new-components/Button'
import { Card } from 'new-components/blogComponents/Card'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { getAllPostsPaginateByCategories } from 'usecases/categories'
import Base, { regexInContent } from './Base'
import * as Styles from './styles'

export function NewBlog() {
  const [posts, setPosts] = useState<any[]>([] as IGetAllCategoriesHome[])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { categorySelected, handleSelectedCategoryName, setSlugCategory } = useBlogFilter()
  const [filterItems, setFilterItems] = useState([])

  useEffect(() => {
    loadPosts()
    handleSelectedCategoryName('')
  }, [])

  const updateQuery = () => {
    if (categorySelected.length > 3) {
      getSearchItems()
    }
  }
  const delayedQuery = useCallback(debounce(updateQuery, 500), [categorySelected])

  useEffect(() => {
    if (categorySelected.length === 0) {
      setFilterItems([])
    }
    delayedQuery()
    return delayedQuery.cancel
  }, [categorySelected, delayedQuery])

  const getSearchItems = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${import.meta.env.VITE_APP_STRAPI_URL}/api/posts?_q=${categorySelected}&populate=author&populate=categories&populate=image`
      )
      setFilterItems(data?.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const loadPosts = async () => {
    setLoading(true)
    try {
      const responsePosts = await getAllPostsPaginateByCategories()
      setPosts(responsePosts)
    } catch (e) {
      toast.error('Erro ao buscar postagens')
    } finally {
      setLoading(false)
    }
  }

  const selectCategory = ({ slug, name }: any) => {
    history.push(`/blog/${slug}`)
    handleSelectedCategoryName(name)
    setSlugCategory(slug)
  }

  const handleSelectPost = ({ slug, post }: any) => {
    history.push(`/blog/${slug}/${post}`)
  }

  return (
    <Base>
      {loading ? (
        <div className="loading-box">
          <LoadingContainer loading />
        </div>
      ) : (
        <div>
          {filterItems.length > 0 ? (
            <Styles.SectionCategory>
              <Styles.HeadingCategory>Resultados de: {`"${categorySelected}"`}</Styles.HeadingCategory>
              <Styles.CardList isFiltered={filterItems.length < 4}>
                {filterItems?.map((post: any) => (
                  <Card
                    key={post?.id}
                    title={post?.attributes?.title}
                    text={regexInContent(post?.attributes?.content)}
                    image={`${import.meta.env.VITE_APP_STRAPI_URL}${post?.attributes?.image?.data?.attributes?.url}`}
                    handleSelectPost={() =>
                      handleSelectPost({
                        slug: post?.categories?.data[0]?.attributes?.slug,
                        post: post?.attributes?.slug
                      })
                    }
                  />
                ))}
              </Styles.CardList>
            </Styles.SectionCategory>
          ) : (
            posts?.map(item => (
              <Styles.SectionCategory key={item.slug}>
                <Styles.HeadingCategory>{item.name}</Styles.HeadingCategory>
                <Styles.CardList>
                  {item?.posts.map((post: any) => (
                    <Card
                      key={post.id}
                      title={post.title}
                      text={regexInContent(post.content)}
                      image={`${import.meta.env.VITE_APP_STRAPI_URL}${post.image.url}`}
                      imageLoading={`${import.meta.env.VITE_APP_STRAPI_URL}${post?.image?.formats?.thumbnail?.url}`}
                      handleSelectPost={() => handleSelectPost({ slug: item.slug, post: post.slug })}
                    />
                  ))}
                </Styles.CardList>
                <Styles.AcctionCategory>
                  <Button background="black" onClick={() => selectCategory({ slug: item.slug, name: item.name })}>
                    Veja mais
                  </Button>
                </Styles.AcctionCategory>
              </Styles.SectionCategory>
            ))
          )}
        </div>
      )}
    </Base>
  )
}
