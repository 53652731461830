import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { TDigitalJourneyFormData } from '../../types'
import { useHistory } from 'react-router'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useConfirmIdentityMutation } from '@cannect/services/resources/digitalJourney'
import { zodResolver } from '@hookform/resolvers/zod'
import { FORM_SCHEMAS } from '../../constants'

type TFormFields = TDigitalJourneyFormData['confirm_identity_document']
export const useConfirmIdentificationStep = () => {
  const { formData, updateFormData } = useDigitalJourney()
  const confirmIdentityMutation = useConfirmIdentityMutation()

  const history = useHistory()
  const confirmIdentificationForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.confirm_identity_document
    },
    resolver: zodResolver(FORM_SCHEMAS.confirm_identity_document)
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = confirmIdentificationForm

  const watchedFields = useWatch({ control })

  useEffect(() => {
    updateFormData({
      confirm_identity_document: {
        full_name: watchedFields.full_name || '',
        birthday: watchedFields.birthday || '',
        cpf: watchedFields.cpf || '',
        rg: watchedFields.rg || ''
      }
    })
  }, [watchedFields])

  const onSubmit = (data: TFormFields) => {
    if (!formData.common_fields?.digital_journey_id) return

    // eslint-disable-next-line consistent-return
    return confirmIdentityMutation.mutateAsync(
      {
        digital_journey_id: formData.common_fields?.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            confirm_identity_document: {
              ...data
            }
          })
          if (response?.next_step) {
            history.push(response.next_step)
          }
        }
      }
    )
  }
  return {
    confirmIdentificationForm,
    onSubmit,
    control,
    isSubmitting,
    handleSubmit
  }
}
