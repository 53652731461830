import Table, { ColDef } from 'new-components/atoms/Table'
import { TPatientListItem } from 'pages/Account/components/pages/MyPatients/types'
import { FaUserCircle } from 'react-icons/fa'

import useQueryString from 'hooks/useQueryString'
import { TResponseStandard } from 'types/common'
import PatientStatusCell from './components/PatientStatusCell'
import { Link } from '@cannect/new-components/atoms'

interface MyPatientsListProps {
  data?: TResponseStandard<TPatientListItem[], 'patients'>
  isLoading: boolean
}

const PersonIconCell = () => <FaUserCircle className="mt-1 h-5 min-h-5 w-7 min-w-5 text-gray-300" />
const DetailsAnchorCell = ({ row }: { row: TPatientListItem }) => (
  <Link variant="primary" to={`?pagina=pacientes-${row.id}`}>
    ver histórico
  </Link>
)

const MyPatientsList = ({ data, isLoading }: MyPatientsListProps) => {
  const { filters, changeFilters } = useQueryString()

  const colDefs: ColDef<TPatientListItem>[] = [
    {
      id: 1,
      label: '',
      cellRenderer: PersonIconCell,
      className: 'min-w-16 w-16 max-w-16'
    },
    {
      id: 2,
      label: 'nome',
      field: 'name',
      className: 'font-medium text-nowrap'
    },
    {
      id: 3,
      label: 'status',
      cellRenderer: PatientStatusCell
    },
    {
      id: 4,
      label: 'vínculo',
      field: 'binding_date',
      className: 'text-xs text-nowrap'
    },
    {
      id: 5,
      label: '',
      cellRenderer: DetailsAnchorCell,
      className: 'text-right'
    }
  ]

  return (
    <Table
      colDefs={colDefs}
      data={data?.patients?.rows}
      isLoading={isLoading}
      pagination={{
        page: Number(filters.page ?? 1),
        onPageChange: (newPage) => changeFilters({ page: newPage }),
        totalItems: data?.patients?.count ?? 0
      }}
    />
  )
}

export default MyPatientsList
