import { Checkbox } from 'antd'
import styled from 'styled-components'

export const Container = styled.div<any>`
  margin: 16px;
  padding: ${props => (props.isMobile ? '0 1rem' : '')};
  .column-cards {
    display: flex;
    flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
    gap: 13px;
  }
  .status_pedido {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`
export const ContainerResumePay = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`

export const ContainerModal = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  text-align: center;

  svg {
    margin: 16px;
    color: #4579ff;
  }
  .action {
    margin-top: 16px;
    width: 100%;
    background: #4579ff;
    border: none;
    color: #fff;
    padding: 8px 0;
  }
`

export const DocumentNotificationBox = styled.div`
  background: rgb(246, 246, 246);
  border-radius: 4px;
  width: 100%;
  padding: 26px 35.17px 16px 26px;
  margin-bottom: 16px;
`

export const TextDocument = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
`

export const BackCart = styled.button`
  border: 1px solid #748351;
  background: #fff;
  border-radius: 2px;
  width: 187px;
  height: 40px;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-align: center;

  color: #748351;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`

