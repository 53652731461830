import React from 'react'

import { StepsContainer } from './styles'
import { StepButton } from './StepButton'
import { Connector } from './Connector'

interface StepProps {
  step?: 1 | 2 | 3
}
function Step({ step = 1 }: StepProps) {
  return (
    <StepsContainer>
      <StepButton step="cart" active />
      <Connector disabled />
      <StepButton step="documents" disabled={step === 1} active={step === 2} />
      <Connector disabled />
      <StepButton step="analytics" disabled={step < 3} active={step === 3} />
    </StepsContainer>
  )
}

export default Step
