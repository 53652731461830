import { ReactChildren, useEffect, useRef, useState } from 'react'

type target = {
  scrollHeight: number
  clientHeight: number
  scrollTop: number
}

interface propsScroll {
  target: target
}

export default () => {
  const ref = useRef<EventTarget | HTMLDivElement | HTMLElement | ReactChildren | any>(null)
  const [scrollBottom, setScrollBottom] = useState<boolean>(false)

  const onScroll = ({ target }: propsScroll) =>
    requestAnimationFrame(() => {
      const { scrollHeight, clientHeight, scrollTop } = target
      if (scrollBottom) setScrollBottom(false)

      const limit = scrollHeight - clientHeight
      const total = parseInt(String(scrollTop), 10) === limit
      if (total) {
        setScrollBottom(total)
      }
    })

  const changeScrollTop = () => {
    const scrollContainer = ref.current
    scrollContainer.scrollTop += 30
  }

  const changeScrollBottom = () => {
    const scrollContainer = ref.current
    scrollContainer.scrollTop -= 30
  }

  const handleScrollTop = () => {
    const scrollContainer = ref.current
    scrollContainer.scrollTop = 0
  }

  useEffect(() => {
    const scrollContainer = ref?.current
    scrollContainer?.addEventListener('scroll', onScroll)
    return () => scrollContainer?.removeEventListener('scroll', onScroll)
  }, [scrollBottom, ref])

  return { scrollBottom, ref, handleScrollTop, changeScrollTop, changeScrollBottom }
}
