import FormForgotPassword from 'components/ForgotPasswordComponents/FormForgotPassword.index'
import * as Styles from './styles'

function ForgotPassword() {
  return (
    <Styles.Container>
      <FormForgotPassword />
    </Styles.Container>
  )
}

export default ForgotPassword
