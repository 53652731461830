import styled from 'styled-components'
import { theme } from 'design-cannect'

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 999px;
  background: ${theme.colors.tertiary};

  outline: none;
  padding: 6px;

  > img {
    width: 9px;
    height: 9px;
  }
`
