/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import GlobalStyles from './styles/resetCSS'
import './styles/index.css'
import App from './App'

import './assets/fonts/itc-avant-garde-gothic-demi.ttf'
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom'

const container = document.getElementById('root')
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  container
)

reportWebVitals()
