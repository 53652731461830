import { svgSmileFace } from 'assets/svg'
import { Button } from 'design-cannect'
import { useCannectCare } from 'hooks/useCannectCare'
import * as Style from './styles'

export default function ThankYou() {
  const { setPath, setStep } = useCannectCare()

  function handleConfirmClick() {
    setPath('')
    setStep(0)
  }

  return (
    <Style.ThankYouContainer>
      <img src={svgSmileFace} alt="Obrigado!" />
      <h1>Obrigado pelos registros!</h1>
      <p>
        Com essas informações, podemos cuidar melhor de você. Fique tranquilo, caso seja necessário, entraremos em
        contato.
      </p>
      <Button onClick={() => handleConfirmClick()}>Confirmar</Button>
    </Style.ThankYouContainer>
  )
}
