import Text from 'components/Text'
import { MdFilePresent } from 'react-icons/md'
import { RiQrCodeFill } from 'react-icons/ri'

import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import File from '../File'

import * as Style from './styles'

interface UploadedFileProps {
  files?: { name: string }[]
  recipeCode?: string
  removeFile: (file: string, recipeCode: any) => void
}

export default function UploadedFileInfo({ files, recipeCode, removeFile }: UploadedFileProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const handleRemove = (file: string, recipeCode: any) => {
    removeFile(file, recipeCode)
  }

  return (
    <Style.Container isColumn>
      <Style.Header>
        <Text type="lg" bold>
          Arquivos enviados:
        </Text>
      </Style.Header>

      <Style.FileWrapper isMobile={isMobile}>
        {files &&
          files?.map(file => (
            <File
              key={file.name}
              removeFile={handleRemove}
              icon={<MdFilePresent size={40} />}
              fileName={file.name}
              recipeCode={recipeCode}
            />
          ))}
        {recipeCode && (
          <File
            key={recipeCode}
            removeFile={handleRemove}
            icon={<RiQrCodeFill size={40} />}
            fileName={recipeCode}
            recipeCode={recipeCode}
          />
        )}
      </Style.FileWrapper>
    </Style.Container>
  )
}
