import Button from 'new-components/Button'
import { BiLoaderCircle } from 'react-icons/bi'
import When from 'utils/when'
import * as Styles from './styles'

export interface ButtonAnimatedProps {
  onClick?: () => void
  isLoading: boolean
  text: string
  textLoading: string
  bgColor: string
  disabled?: boolean
}

export function ButtonAnimated({ isLoading, onClick, text, bgColor, textLoading, disabled }: ButtonAnimatedProps) {
  return (
    <Styles.Wrapper bgColor={bgColor}>
      <Button onClick={onClick} disabled={disabled}>
        <When expr={!!isLoading}>
          <div className="load_box">
            <BiLoaderCircle />
          </div>
          {textLoading}
        </When>

        <When expr={!isLoading}>{text}</When>
      </Button>
    </Styles.Wrapper>
  )
}
