import useSignedUser from 'hooks/useSignedUser'
import { useMemo } from 'react'
import CollapsableMenu from './components/CollapsableMenu'
import HelloMessage from './components/HelloMessage'
import { CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL, PROFESSIONAL_SIDE_PANEL } from './constants/professional'
import { PATIENT_SIDE_PANEL } from './constants/patient'
import * as Styles from './styles'

function AccountSidePanel() {
  const { signedUser } = useSignedUser()

  const isCRMOrCRO = useMemo(() => {
    if (
      signedUser?.prescriber?.council === 'CRO' ||
      signedUser?.prescriber?.council === 'CRM' ||
      signedUser?.prescriber?.council === 'RMS'
    ) {
      return true
    }
    return false
  }, [signedUser])

  const profissionalSidePanel = isCRMOrCRO ? CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL : PROFESSIONAL_SIDE_PANEL

  return (
    <Styles.AccountSidePanelContainer>
      <HelloMessage />

      {signedUser?.registration_type === 1
        ? PATIENT_SIDE_PANEL.map(item => (
            <CollapsableMenu title={item.title} subItems={item?.subItems} key={item.title.label} />
          ))
        : profissionalSidePanel.map(item => (
            <CollapsableMenu title={item.title} subItems={item?.subItems} key={item.title.label} />
          ))}
    </Styles.AccountSidePanelContainer>
  )
}

export default AccountSidePanel
