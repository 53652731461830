import { theme } from 'design-cannect'
import styled from 'styled-components'

export const SelectContainer = styled.select`
  padding: 12px 8px;
  border-radius: 8px;
  font-weight: 400;
  font-family: 'Spartan', sans-serif;
  font-size: 14px;
  border: 2px solid rgb(17 88 80 / 59%);

  &:focus {
    outline: none;
    border-color: ${theme.colors.tertiary};
    transition: all 100ms ease;
  }
`
