import styled, { css, keyframes } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

interface ItemFlavourProps {
  isActive: boolean
  isLoading?: boolean
  isRecommended?: boolean
}

interface TextFlavorProps {
  isActive: boolean
}

const ModifierFlavorContent = {
  isLoading: () => css`
    opacity: 0.4;
    pointer-events: none;
  `
}

const rotate = keyframes`
  to {
    transform: rotateZ(360deg);
  }
`

export const Wrapper = styled.main`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (${MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: center;
  }
`

export const ImageContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 449px;
  @media (${MOBILE_WIDTH}) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`
export const SelectedImageBox = styled.div`
  min-width: 449px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${temporaryTheme.shadow.shad1};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  @media (${MOBILE_WIDTH}) {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: unset;
    max-width: 300px;
  }
`
export const SelectedImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  box-shadow: ${temporaryTheme.shadow.shad1};
  @media (${MOBILE_WIDTH}) {
    height: auto;
    max-width: 350px;
  }
`
export const GridImageBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
  gap: ${temporaryTheme.spacing.space3};
  padding: 18px 0;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }

  @media (${MOBILE_WIDTH}) {
    width: 100%;
    justify-content: space-between;
  }
`

interface ImageGridProps {
  isSelected?: boolean
}

export const ImageGrid = styled.img<ImageGridProps>`
  ${({ isSelected }) => css`
    width: 80px;
    height: 80px;
    object-fit: contain;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: ${temporaryTheme.shadow.shad1};
    padding: 4px;
    &:hover {
      border: 1px solid ${temporaryTheme.colors.sitePrimary};
    }
    border: ${!!isSelected && `1px solid ${temporaryTheme.colors.sitePrimary}`};
  `}
`

export const DetailsContent = styled.div`
  width: calc(100% - 500px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .heading-component {
    margin-top: 0;
    margin-bottom: 20px;
  }
  h1 {
    margin-top: 0;
  }

  @media (${MOBILE_WIDTH}) {
    width: 100%;
    margin-top: 25px;
  }
`

export const InfoBox = styled.div`
  margin-bottom: 30px; /* Aumenta o espaço abaixo de cada caixa de informações */

  &:last-child {
    margin-bottom: 50px; /* Aumenta ainda mais para a última caixa antes dos sabores, se necessário */
  }
`

export const SeparatorCircle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #858585;
  margin: 0 18px;
`

export const SkuDetailsBox = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: #858585;
  display: flex;
  align-items: center;
`

export const InfoProductBox = styled.div``

export const TextDetails = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #433F3E;
  width: 100%;
  word-wrap: break-word;
  span {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #433F3E;

    &.list {
      color: #4579ff;
      text-decoration: underline;
    }
  }
`

interface BadgePromotionProps {
  isNational: boolean
}

export const BadgePromotion = styled.p<BadgePromotionProps>`
  ${({ isNational }) => css`
    padding: 8px;
    border-radius: 4px;
    color: ${temporaryTheme.colors.sitePrimary};
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: ${isNational ? '#3D3D3D' : '#E1ECC9'};
    width: fit-content;
    span {
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      font-weight: 700;
    }
  `}
`

export const PriceBox = styled.div`
  margin: 20px 0 30px 0;

  span {
    font-family: 'Spartan', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #858585;
  }
`

export const Price = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #433F3E;

  span {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 34px;
    color: #21976e;
  }
`

export const ActionsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;
  gap: ${temporaryTheme.spacing.space3};
`

export const QuantityBox = styled.div<any>`
  ${({ notVolume }) => css`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: ${notVolume && '0.4'};
    p {
      background: #ffffff;
      border: 1.5484px solid #c4c4c4;
      width: 48px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Spartan', sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: #4e4e4e;
    }

    svg {
      width: 15px;
      height: 15px;
      color: #424242;
    }
  `}
`

export const ButtonAdd = styled.div`
  background: #ffffff;
  border: 1.5484px solid #c4c4c4;
  border-radius: 0px 5px 5px 0px;
  width: 26px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export const ButtonRemove = styled.div`
  background: #ffffff;
  border: 1.5484px solid #c4c4c4;
  border-radius: 5px 0px 0px 5px;
  width: 26px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
interface ButtonAddCartProps {
  isLoading?: boolean
}

export const ButtonAddCart = styled.button<ButtonAddCartProps>`
  ${({ isLoading }) => css`
    width: 312px;
    height: 50px;
    background: #748351;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${temporaryTheme.spacing.space3};
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 8px;
    svg {
      width: 20px;
      height: 20px;
      margin-top: -6px;
    }

    .load-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;

      svg {
        animation: ${rotate} 2s linear infinite;
      }
    }
    pointer-events: ${!!isLoading && 'none'};
    opacity: ${!!isLoading && 0.5};
  `}
`
export const ButtonMoreInfo = styled.button`
  width: 312px;
  height: 50px;
  background: transparent;
  border: 1.1085px solid #748351;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #748351;
`

export const PricePromotionalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space3};
  margin: 23px 0;
`

export const PromotionalPrice = styled.p`
  font-family: 'Spartan', sans-serif;
  font-size: 23px;
  font-weight: 700;
  color: #4e4e4e;
  text-decoration: line-through;
`

export const BadgePromotionalPrice = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 21px;
  color: ${temporaryTheme.colors.white};
  background: #858585;
  border-radius: 2px;
  padding: 2px 8px;
  margin-top: -13px;
  text-transform: uppercase;
`

export const FlavorBox = styled.div`
  padding: ${temporaryTheme.spacing.space4} ${temporaryTheme.spacing.space1};
`

export const FlavorList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 30px;
  gap: ${temporaryTheme.spacing.space4};
`
export const RecommendedLabel = styled.div`
  color: #E0A401;
  padding: 0px 8px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  z-index: 10;
  margin-bottom: 10px;
`

export const LowStockLabel = styled.div`
  color: #858585;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  z-index: 10;
  margin-bottom: 10px; /* Ajuste para espaçamento entre o rótulo e o botão */
`

export const TextFlavor = styled.p<TextFlavorProps>`
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: ${({ isActive }) => (isActive ? 'white' : '#424242')};
`

export const ImageFlavor = styled.img`
  border: 3px solid transparent;
  border-radius: 50%;
  object-fit: cover;
`

export const ItemFlavor = styled.button<ItemFlavourProps>`
  ${({ isActive, isLoading, isRecommended }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: ${temporaryTheme.spacing.space2};
    border: none;
    cursor: pointer;
    color: ${isActive ? temporaryTheme.colors.white : temporaryTheme.colors.darkGrey};
    border: 2px solid transparent;
    border-radius: 31px;
    padding: ${temporaryTheme.spacing.space2} ${temporaryTheme.spacing.space4};
    min-width: 136px;
    background: ${isActive ? temporaryTheme.colors.sitePrimary : 'transparent'};
    opacity: ${isActive ? 1 : 0.7};
    margin-bottom: 20px; /* Adiciona um espaçamento extra para alinhar com os rótulos acima */
    border: 2px solid #858585;

    ${isRecommended &&
    css`
      background: transparent;
      color: ${temporaryTheme.colors.darkGrey};
      opacity: 0.7;
    `}

    ${isLoading && ModifierFlavorContent.isLoading()};

    .load-box {
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    }
  `}
`

export const PathologyText = styled.a`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color:  ${temporaryTheme.colors.link};
  margin: 0 2px;
  cursor: pointer;
  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`

export const ProductHeadingWrapper = styled.div``
