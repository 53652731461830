import styled, { css, keyframes } from 'styled-components'

export const createSlideDownAnimation = (animationHeight: number) => {
  const slideDown = keyframes`
    0% {
      visibility: hidden;
      height: 0;
    }

    95% {
      visibility: visible;
      height: ${animationHeight}px;
    }

    100% {
      visibility: visible;
      height: auto;
    }
  `

  return css`
    animation: ${slideDown} 0.3s linear both;
  `
}

export const createSlideUpAnimation = (animationHeight: number) => {
  const slideUp = keyframes`
    from {
      visibility: visible;
      height: ${animationHeight}px;
    }

    to {
      visibility: hidden;
      height: 0;
    }
  `

  return css`
    animation: ${slideUp} 0.3s linear both;
  `
}

interface CollapsableMenuContainerProps {
  animationHeight: number
}
export const CollapsableMenuContainer = styled.div<CollapsableMenuContainerProps>`
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;

  .slide-down {
    ${({ animationHeight }) => createSlideDownAnimation(animationHeight)}
  }

  .slide-up {
    ${({ animationHeight }) => createSlideUpAnimation(animationHeight)}
  }
`

interface LabelContainerProps {
  isSelected: boolean
}
export const LabelContainer = styled.button<LabelContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  background: transparent;
  color: ${({ isSelected }) => (isSelected ? '#21976E !important' : '#3d3d3d')};
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 600)};

  border: none;
  font-size: 1.25rem;
  cursor: pointer;

  transition: filter 0.3s;
  flex-wrap: wrap;
  text-align: left;

  svg {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;

    path {
      stroke: ${({ isSelected }) => (isSelected ? '#21976e' : '#3d3d3d')};
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    filter: brightness(0.9);
  }
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 1rem 0 0 2rem;
  height: 0;
  overflow: hidden;
  visibility: hidden;
`

export const MenuOption = styled.button<LabelContainerProps>`
  display: flex;

  background: transparent;
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  border: none;

  font-size: 1rem;
  cursor: pointer;
  transition: filter 0.3s;

  color: #3d3d3d;
  text-align: left;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    filter: brightness(0.9);
  }
`
