import { Button } from '@cannect/new-components/atoms'

type TAutoCarouselProps = {
  items: {
    path: string
    onClick?: () => void
  }[]
}

export const AutoCarousel = ({ items }: TAutoCarouselProps) => {
  return (
    <div className="flex gap-4 overflow-hidden">
      {Array.from({ length: 5 }).map((_, index) => (
        <div className="flex shrink-0 animate-auto-carousel gap-4" key={index}>
          {items?.map(({ path, onClick }, index) => (
            <Button
              key={`${path}-${index}`}
              unstyled
              full
              onClick={onClick}
              className="flex h-[110px] w-[240px] items-center justify-center rounded bg-white grayscale filter transition-all duration-300 ease-in-out hover:bg-white hover:grayscale-0">
              <img width={130} src={path} height={60} alt="item carousel" className="object-contain" />
            </Button>
          ))}
        </div>
      ))}
    </div>
  )
}
