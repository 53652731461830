import { Typography } from '@cannect/new-components/atoms'
import { TWebinarItem } from '@cannect/services/resources/webinars'
import { formatDate } from '@cannect/utils/date'
import { FiChevronRight } from 'react-icons/fi'

type TimelineSectionProps = {
  webinarDetails: TWebinarItem
}
export const TimelineSection = ({ webinarDetails }: TimelineSectionProps) => {
  return (
    <section className="relative left-1/2 right-1/2 -mb-[20px] -ml-[50vw] -mr-[50vw] w-screen bg-[url('@cannect/assets/img/new_home/bg_triagem.webp')] bg-cover py-20">
      <div className="mx-auto max-w-[1366px] px-12">
        <div className="flex flex-col items-start gap-14">
          <Typography.Heading className="font-serif text-lg tracking-tight text-white" type="headingThree" level={3}>
            Cronograma
          </Typography.Heading>
          <div className="flex flex-col items-start gap-8 md:flex-row">
            <div className="flex flex-none flex-col gap-14 md:flex-row md:items-center md:gap-24">
              <div className="left-gradient-divider-secondary relative flex min-w-fit flex-col gap-1 rounded-xl pl-6 [&_.ant-typography]:text-white">
                <Typography.Text weight="bold">{webinarDetails.day}</Typography.Text>
                <Typography.Text>{formatDate(webinarDetails.date, "dd 'de' MMMM")}</Typography.Text>
                <Typography.Text>{webinarDetails.time}</Typography.Text>
              </div>
              <FiChevronRight className="tranform flex-none rotate-90 md:rotate-0" size={26} color="#fff" />
            </div>

            <div className="flex flex-col gap-4 rounded-3xl bg-primary-200 p-8 shadow-lg">
              {webinarDetails.host_name && (
                <div>
                  <Typography.Text weight="bold">Host:</Typography.Text>
                  <Typography.Text>{webinarDetails.host_name}</Typography.Text>
                  {webinarDetails.host_description && (
                    <Typography.Text className="italic">{webinarDetails.host_description}</Typography.Text>
                  )}
                </div>
              )}
              {webinarDetails.guest_name && (
                <div>
                  <Typography.Text weight="bold">Convidado:</Typography.Text>
                  <Typography.Text>{webinarDetails.guest_name}</Typography.Text>
                  {webinarDetails.guest_description && (
                    <Typography.Text className="italic">{webinarDetails.guest_description}</Typography.Text>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
