import { Button, Image } from 'antd'
import { RG_ICON, RG_ICON2 } from 'assets/svg/documents'

import { Content, Heading, TextDocument, ImageExempleBox, AcceptTypeFiles, ActionsBox } from './styles'

export function TipIdentify() {
  return (
    <Content>
      <Heading>Orientações para envio de fotos</Heading>

      <TextDocument>
        1. Tire uma foto da <strong>frente e do verso</strong> do documento que <strong>contenha RG e CPF</strong>,
        preferencialmente sobre uma <strong>superfície neutra</strong>, sem cores fortes ou estampas visíveis, conforme
        exemplo abaixo:
      </TextDocument>
      <ImageExempleBox>
        <Image src={RG_ICON} alt="imagem exemplo do envio de documento." preview={false} />
        <Image src={RG_ICON2} alt="imagem exemplo do envio de documento." preview={false} />
      </ImageExempleBox>

      <TextDocument>
        2. Certifique-se de que o documento esteja bem posicionado, ocupando a maior parte da tela, e que as informações
        estejam nítidas e legíveis.
      </TextDocument>

      <AcceptTypeFiles>
        Formatos permitidos: <strong>jpg, png, jpeg e pdf.</strong>
      </AcceptTypeFiles>
    </Content>
  )
}
