import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.main`
  width: 100%;
  margin-bottom: 2rem;
`

export const PacientDataForm = styled.form`
  display: flex;
  flex-direction: column;
  background: #E3E3E3;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  padding: 25px 50px;

  ${media.mobile`
    padding: 15px 25px;
  `}
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;

  label {
    color: #B5B3B3;
    font-weight: 400;

  }

  input {
    background: #ffffff;
    border: 2.38323px solid #d9d9d9;
    border-radius: 5.89135px;
    height: 38px;
    padding-left: 1rem;

    font-weight: 300;

    &[disabled] {
      opacity: 0.4;
      background-color: #f0f0f0;
      color: #999;
      cursor: not-allowed;
    }
  }

  select {
    background: #ffffff;
    border: 2.38323px solid #d9d9d9;
    border-radius: 5.89135px;
    height: 38px;
    padding-left: 1rem;

    font-weight: 300;
  }
`

export const HorizontalContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  .input-label {
    width: 100%;
    display: flex;
    gap: 0.75rem;
    flex-direction: column;

    label {
      color: #424242;
      font-weight: 600;
    }

    .search-cep {
      color: #4b5dff;
      text-decoration: underline;
      font-size: 12px;
      font-weight: 400;
      margin-top: -5px;
      margin-bottom: 10px;
    }

    .ant-select-selecto {
      background: rgb(255, 255, 255);
      border: 2.3px solid #d9d9d9 !important;
      border-radius: 5px;
      height: 38px !important;
      padding-left: 1rem;
      font-weight: 300;
    }

    .ant-select {
      * {
        font-weight: 400;
      }
      .ant-select-selector {
        background: rgb(255, 255, 255);
        border: 2.3px solid #d9d9d9 !important;
        border-radius: 5px;
        height: 38px !important;
        padding-left: 1rem;
      }

      .ant-select-clear {
        width: fit-content;

        span {
          color: ${temporaryTheme.colors.red};
          text-decoration: underline;
        }
      }
    }
  }

  .box_default-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  &.actions {
    ${media.mobileWithTablet`
      flex-direction: column-reverse;

      button {
        width: 100%;
      }
    `}
  }

  ${media.mobile`
    flex-direction: column-reverse;
  `}
`

export const PrescriberSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  ${media.mobile`
    flex-direction: column;
  `}
`
export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  padding: 1rem 0;

  width: 100%;

  &.form_box {
    gap: 2rem;
  }

  h2 {
    color: #424242;
    text-align: left;
    margin-bottom: 2rem;
  }

  h3 {
    color: ${theme.colors.tertiary};
    width: 100%;
    font-size: 2rem;
  }

  p.product-explanation {
    color: ${theme.colors.tertiary};
    font-weight: 300;
    max-width: 600px;

    text-align: center;
    margin-top: 2rem;
  }

  > button {
    color: ${theme.colors.tertiary};
    margin-top: 2rem;
  }

  > object {
    border: 1px solid #ecece2;
    border-radius: 20px;
    margin-top: 1rem;
  }

  textarea {
    resize: none;
  }

  ${media.mobileWithTablet`
    width: 100%;
  `}
`

export const CancelButton = styled.button`
  background: #8f8f8f;
  width: 138.79px;
  height: 40px;
  border-radius: 8px;
  border: none;
  color: white;
`
export const SaveButton = styled.button`
  background: #21976e;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  border: none;
  color: white;
`
export const Title = styled.h2`
  color: #424242;
  font-weight: 400;
`
