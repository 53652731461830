/* eslint-disable react/jsx-no-bind */
import { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from 'new-components/Modal'
import BirdID from './BirdID'
import VidaasQrCode from './VidaasQrCode'
import VidaaS from './Vidaas'
import ChooseCertificate from './ChooseCertificate'
import * as Style from './styles'

type DigitalSignatureModalProps = {
  isOpen: boolean
  onRequestClose: (successfulySigned: boolean) => void
  handleSignature?: () => void
  handleAnotherSignature?: () => void
}

type Step = 'chooseCertificate' | string

export type Certificates = 'birdID'

const LINK_CONTACT =
  'https://api.whatsapp.com/send?phone=5511950811547&text=Ol%C3%A1!%20Gostaria%20de%20saber%20como%20conseguir%20uma%20assinatura%20digital%20gratuita'

export default function DigitalSignatureModal({
  isOpen,
  onRequestClose,
  handleSignature = () => null,
  handleAnotherSignature = () => null
}: DigitalSignatureModalProps) {
  const [certificateStep, setCertificateStep] = useState<Step>('chooseCertificate')

  function handleCertificateChoice(choice: string) {
    setCertificateStep(choice)
  }

  async function handleSign(success: boolean) {
    if (!success) {
      toast.error('Houve um erro ao assinar digitalmente a receita. Tente novamente.')
      return
    }

    handleSignature()
    onRequestClose(true)
  }

  const validationReturnAction = certificateStep !== 'chooseCertificate' ? 'Voltar' : undefined

  return (
    <Modal
      setOpenModal={() => onRequestClose(false)}
      isOpen={isOpen}
      returnText={validationReturnAction}
      returnAction={() => setCertificateStep('chooseCertificate')}
    >
      <Style.Wrapper>
        {certificateStep === 'chooseCertificate' && (
          <ChooseCertificate
            onCertificateChoose={handleCertificateChoice}
            handleAnotherSignature={handleAnotherSignature}
          />
        )}
        {certificateStep === 'birdID' && <BirdID onSign={handleSign} />}
        {certificateStep === 'vidaasQrCode' && (
          <VidaasQrCode onSign={handleSign} onCertificateChoose={handleCertificateChoice} />
        )}
        {certificateStep === 'vidaas' && <VidaaS onSign={handleSign} />}
        <Style.NotificationText>
          <Style.LinkNotification href={LINK_CONTACT} target="_blank">
            Fale com a Cannect
          </Style.LinkNotification>
          caso ainda não possua uma assinatura digital.
        </Style.NotificationText>
      </Style.Wrapper>
    </Modal>
  )
}
