import { useEffect, useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useScheduling } from 'hooks/useScheduling'
import api from 'services/api'

import CustomInput from 'components/CustomInput'
import Button from 'new-components/Button'
import { Container } from './styles'

function CouponInput() {
  const { appointmentFromB2C, setValidCoupon, checkoutActiveStep, setShouldUpdateQrCode } = useScheduling()
  const { watch, setValue } = useFormContext()
  const [disabledCouponField, setDisabledCouponField] = useState(false)

  const selectedPrescriber = watch('prescriber')
  const patient = watch('patient')
  const coupon = watch('coupon')

  const [checkingCoupon, setCheckingCoupon] = useState(false)

  const getValue = (fromB2C?: string | number, fallback?: string | number) => (appointmentFromB2C ? fromB2C : fallback)

  const pixAmount = useMemo(
    () => getValue(appointmentFromB2C?.amount_with_discount, selectedPrescriber?.online_appointment_value),
    [appointmentFromB2C, selectedPrescriber]
  )

  const creditCardAmount = useMemo(
    () =>
      getValue(appointmentFromB2C?.credit_amount_with_discount, selectedPrescriber?.online_appointment_credit_value),
    [appointmentFromB2C, selectedPrescriber]
  )

  const patientCpf = useMemo(
    () => getValue(appointmentFromB2C?.patient?.person?.cpf, patient?.cpf),
    [appointmentFromB2C, patient]
  )

  const patientId = useMemo(() => getValue(appointmentFromB2C?.patient?.id, patient?.id), [appointmentFromB2C, patient])

  const prescriberId = useMemo(
    () => getValue(appointmentFromB2C?.prescriber?.user?.id, selectedPrescriber?.user?.id),
    [appointmentFromB2C, selectedPrescriber]
  )

  const handleDiscount = async () => {
    if (!coupon) {
      setValidCoupon(null)
      setValue('amountWithDiscount', pixAmount)
      setValue('creditAmountWithDiscount', creditCardAmount)
      return
    }

    setCheckingCoupon(true)
    const endpoint = `/scheduling/coupon/validate?name=${coupon}&prescriberUserId=${prescriberId}&cpf=${patientCpf}&patientId=${patientId}`
    try {
      const { data } = await api.get(endpoint)

      if (data.success) {
        setValidCoupon(data.coupon)
        setValue('amountWithDiscount', data.coupon.amount_with_discount)
        setValue('creditAmountWithDiscount', data.coupon.credit_amount_with_discount)
      } else {
        toast.error('Cupom inválido!')
      }
    } catch (error) {
      setValue('coupon', '')
      setValidCoupon(null)
      setValue('amountWithDiscount', pixAmount || null)
      setValue('creditAmountWithDiscount', creditCardAmount || null)
      toast.error('Cupom inválido!')
    } finally {
      setCheckingCoupon(false)
      setShouldUpdateQrCode(true)
    }
  }

  useEffect(() => {
    if (appointmentFromB2C?.coupon?.name && checkoutActiveStep === 3) {
      setDisabledCouponField(true)
    }
  }, [])

  return (
    <Container>
      <CustomInput
        disabled={disabledCouponField}
        isLoading={checkingCoupon}
        outlined
        name="coupon"
        placeholder="Digite aqui seu cupom"
        label=""
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleDiscount()
          }
        }}
      />
      <Button background="green" onClick={handleDiscount} disabled={disabledCouponField}>
        Aplicar
      </Button>
    </Container>
  )
}

export default CouponInput
