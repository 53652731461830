import styled, { css } from 'styled-components'

interface BannerProps {
  variant?: 'positive' | 'negative' | 'warning' | 'info'
}

const colorVariant = {
  positive: () => css`
    background-color: #b8eec8;
    color: #0f5132;

    .banner_target {
      background: #0f5132;
    }
  `,
  negative: () => css`
    background-color: #efc6c6;
    color: #9f0b26;

    .banner_target {
      background: #9f0b26;
    }
  `,
  warning: () => css`
    background-color: #FEDE84;
    color: #433F3E;

    .banner_target {
      background: #9d8009;
    }
  `,
  info: () => css`
    background-color: #d1e7f3;
    color: #1a699f;

    .banner_target {
      background: #1a699f;
    }
  `
}

export const Container = styled.div<BannerProps>`
  border-radius: 8px;
  padding: 0 16px;
  margin: 16px 0;
  width: 100%;
  font-size: 13px;

  .banner_body {
    margin-bottom: 8px;
  }

  .banner_icon {
    width: 30px;
    height: 100%;
    font-size: 20px;
  }
  .banner_text {
    font-weight: 650;
    margin-left: 8px;
    font-family: 'Poppins', sans-serif;
  }
  .banner_target {
    height: 4px;
    border-radius: 4px;
    width: 20px;
  }
  .banner_wrapper {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }
  ${({ variant }) => colorVariant[variant || 'info']};
`
