import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const ConfirmRegistrationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  margin-top: 2rem;

  @media ${MOBILE_WIDTH} {
    margin-bottom: 5rem;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`
