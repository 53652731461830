import React from 'react'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { CardProduct } from './cardProduct'
import * as Style from './styles'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../utils/constants'

interface iProductList {
  isNotEditingFlavor?: boolean
  isNotEditing?: boolean
  modal?: boolean
  productList?: any
}
function ProductList({ isNotEditing, modal, productList, isNotEditingFlavor }: iProductList) {
  const { lastOrderCreated } = usePrepareCart()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const products = productList || lastOrderCreated?.items

  return (
    <>
      {!isMobile && (
        <Style.TableHeader>
          <div className="product">
            <p>produto</p>
          </div>
          <div className="product-properties">
            <p>quantidade</p>
            <p className="sabor">sabor</p>
            <p>valor unitário</p>
            <p>valor total</p>
          </div>
        </Style.TableHeader>
      )}
      <Style.Products>
        {products
          .sort((a: any, b: any) => (b.product_id > a.product_id ? 1 : -1))
          .map((product: any, index: any) => (
            <>
              {index > 0 && isMobile && <Style.Line />}
              <CardProduct
                isNotEditingFlavor={isNotEditingFlavor}
                product={product}
                isNotEditing={isNotEditing}
                key={product?.id}
              />
            </>
          ))}
      </Style.Products>
    </>
  )
}

export default ProductList
