/* eslint-disable react/jsx-no-constructed-context-values */
import { IUserAuthenticatedResponse } from 'dtos/User'
import React, { createContext, useState, useContext, useEffect } from 'react'
import { getUserRoles } from './useSessionUser'

interface AuthProviderProps {
  children: React.ReactNode
}
interface AuthContextData {
  session?: IUserAuthenticatedResponse
  tokenSaved?: string | null
  role?: any
  getRoles?: any
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

function AuthProvider({ children }: AuthProviderProps) {
  const [isLogged, setIsLogged] = useState<boolean>(false)

  const [role, setRole] = useState(1)

  const alterLogged = () => {
    setIsLogged(!isLogged)
  }

  const getRoles = () => {
    setRole(getUserRoles())
  }

  useEffect(() => {
    getRoles()
  }, [])

  const tokenSaved = ''

  return <AuthContext.Provider value={{ tokenSaved, getRoles, role }}>{children}</AuthContext.Provider>
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
