import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

interface DebouncedSearchOptions {
  searchTerm: string
  handleSearchChange: (value: string) => void
}

export function useDebouncedSearch(callback: (term: string) => void, delay: number): DebouncedSearchOptions {
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedCallback = useCallback(
    debounce((term: string) => {
      callback(term)
    }, delay),
    []
  )

  const handleSearchChange = (value: string) => {
    setSearchTerm(value)
    debouncedCallback(value)
  }

  useEffect(() => {
    return () => {
      debouncedCallback.cancel()
    }
  }, [debouncedCallback])

  return { searchTerm, handleSearchChange }
}
