import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Button, Modal, Typography } from '@cannect/new-components/atoms'
import { TModalActions } from '@cannect/new-components/atoms/Modal'
import { TModalConfirmMethods, TModalConfirmProps } from './types'

export const ModalConfirm = forwardRef<TModalConfirmMethods, TModalConfirmProps>(
  ({ title, text, onConfirm, okButtonText = 'Confirmar', cancelButtonText = 'Cancelar' }, ref) => {
    const refModal = useRef<TModalActions>(null)

    const onShow = () => {
      refModal.current?.open()
    }

    const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false)

    const onClose = () => refModal.current?.close()

    useImperativeHandle(ref, () => ({
      onShow,
      onClose
    }))

    const handleConfirm = async () => {
      setIsLoadingConfirmation(true)

      await onConfirm()

      setIsLoadingConfirmation(false)

      onClose()
    }

    return (
      <Modal ref={refModal} hiddenCloseButton data-testid="modal_confirm">
        <Typography.Heading type="headingSix" weight="medium" className="text-gray-900">
          {title}
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="mt-2 text-gray-800">
          {text}
        </Typography.Text>
        <div className="flex items-center justify-center space-x-6 pt-10">
          <Button disabled={isLoadingConfirmation} variant="outline" onClick={onClose}>
            {cancelButtonText}
          </Button>
          <Button isLoading={isLoadingConfirmation} disabled={isLoadingConfirmation} onClick={handleConfirm}>
            {okButtonText}
          </Button>
        </div>
      </Modal>
    )
  }
)
