import React from 'react'
import { FaUser, FaCalendar, FaClock, FaMapMarkerAlt } from 'react-icons/fa'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import * as S from './styles'

interface EventConfirmationProps {
  eventData: any
}

const EventConfirmation: React.FC<EventConfirmationProps> = ({ eventData }) => {
  if (!eventData) {
    return <div>Dados do evento não disponíveis.</div>
  }

  const { mainGuestName, startTimeUtc, guests, durationMinutes } = eventData

  if (!mainGuestName || !startTimeUtc || !guests || guests.length < 2 || !durationMinutes) {
    console.error('Informações incompletas do evento:', eventData)
    return <div>Informações incompletas do evento.</div>
  }

  const formattedDate = format(new Date(startTimeUtc), 'dd/MM/yyyy', { locale: ptBR })
  const formattedTime = format(new Date(startTimeUtc), 'HH:mm', { locale: ptBR })
  const secondGuestEmail = guests[1]

  const emailToNameMap: { [key: string]: string } = {
    guilherme: 'Guilherme Salgueiredo',
    julia: 'Julia Moreira',
    rafaela: 'Rafaela Landucci'
  }

  const getRepresentativeName = (email: string): string => {
    const emailPrefix = email.split('@')[0]
    const nameKey = Object.keys(emailToNameMap).find(key => emailPrefix.startsWith(key))
    return nameKey ? emailToNameMap[nameKey] : email
  }

  const secondGuestName = getRepresentativeName(secondGuestEmail)

  return (
    <S.ConfirmationContainer>
      <S.ConfirmationTitle>
        Evento confirmado!
        <svg viewBox="0 0 24 24">
          <circle className="circle" cx="12" cy="12" r="10" />
          <path className="check" d="M5 13l4 4L19 7" />
        </svg>
      </S.ConfirmationTitle>
      <S.ConfirmationSubtitle>Em breve, você receberá um e-mail com os detalhes da reunião.</S.ConfirmationSubtitle>
      <div className="confirmation-container">
        <S.EventTitle>{`${mainGuestName}: Boas vindas + Suporte Farma`}</S.EventTitle>
        <S.EventDetails>
          <div className="event-detail">
            <FaUser />
            <span>{secondGuestName}</span>
          </div>
          <div className="event-detail">
            <FaCalendar />
            <span>{formattedDate}</span>
          </div>
          <div className="event-detail">
            <FaClock />
            <span>{`${formattedTime} hrs. durante ${durationMinutes} minutos`}</span>
          </div>
          <div className="event-detail">
            <FaMapMarkerAlt />
            <span>Vídeoconferência Web</span>
          </div>
        </S.EventDetails>
      </div>
    </S.ConfirmationContainer>
  )
}

export default EventConfirmation
