import styled from 'styled-components'

export const Container = styled.div<any>`
  width: 287px;
  padding-bottom: 10px;
`

export const CheckBoxContainer = styled.div`
  .checkbox-filter {
    margin: 0 0 10px 0;
  }
  .checkbox-filter > div:first-child {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;

    border: 1px solid #21976e;
    background-color: #f3f3f3;
    margin-right: 2px;
  }
`
export const ClearButtonContainer = styled.div`
  display: flex;
  align-self: flex-start;

  button {
    width: 144px;
    height: 50px;
    font-size: 15px;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    border: 1px solid #3d3d3d;
    border-radius: 8px;
    text-decoration: none;
    background-color: transparent;
    margin: 0;
  }
`

export const NoResultsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 14px;
  height: 140px;
  text-align: center;
  font-size: 14px;
  font-family: 'Spartan', sans-serif;
`
