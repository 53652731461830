import React, { useMemo } from 'react'
import Texts from 'components/Text'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { formatPriceToNumber } from 'utils/formatters'
import { Container, Title } from './styles'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../utils/constants'
import { OrderTypes } from '../../../types/OrderTypes'

const FREE_SHIPPING_MINIMUM = 2500

interface OrderSummaryProps {
  subtotal: number
  total: number
}

const getShippingCost = (shippingValue: string): number => {
  if (parseFloat(shippingValue) === 0) return 0
  return 140
}

const computeOrderSummary = (items: OrderTypes[], shipping: number, discount: number): OrderSummaryProps => {
  const subtotal = items.reduce((acc, item) => acc + item.unitPrice * item.quantity, 0)
  const total = subtotal - discount
  return { subtotal, total }
}

function Frete() {
  const { lastOrderCreated } = usePrepareCart()
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  if (!lastOrderCreated) return null

  const isFreeShippingFromAPI = parseFloat(lastOrderCreated.shipping || '0') === 0
  const shipping = getShippingCost(lastOrderCreated.shipping || '0')
  const discount = parseFloat(lastOrderCreated.discount || '0')

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const orderSummary = useMemo(
    () => computeOrderSummary(lastOrderCreated.items, shipping, discount),
    [lastOrderCreated.items, shipping, discount]
  )

  const remainingForFreeShipping = isFreeShippingFromAPI ? 0 : FREE_SHIPPING_MINIMUM - orderSummary.total

  return (
    <Container isMobile={isMobile}>
      <Title>CUSTO DE IMPORTAÇÃO</Title>
      <div className="input">
        <input
          type="range"
          value={remainingForFreeShipping <= 0 ? FREE_SHIPPING_MINIMUM : orderSummary.total}
          max={FREE_SHIPPING_MINIMUM}
        />
        <Texts>
          Faltam <b>{formatPriceToNumber(Math.max(remainingForFreeShipping, 0))}</b> para{' '}
          <b>Custo de importação grátis</b>
        </Texts>
      </div>
      <Texts>
        Compre acima de R$ {FREE_SHIPPING_MINIMUM},00 e tenha custo de importação grátis em qualquer canal de pagamento
        disponível.
      </Texts>
    </Container>
  )
}

export default Frete
