import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'
import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const ProductsTableContainer = styled.div`
  display: flex;
  max-width: 95vw;
  overflow-x: auto;
  padding: 1rem;
  margin-bottom: 1rem;

  @media ${MOBILE_WIDTH} {
    padding: 0;
  }
`

export const DeliveryWarning = styled.span`
  font-size: 12px;
  color: #555358;
  text-align: start;
  margin: 0;
  width: 100%;
  margin-left: 65px;
`

export const Table = styled.table`
  text-align: center;
  border-spacing: 0 1.5rem;
  max-width: 100%;
  min-width: max-content;

  th {
    color: '#3D3D3D';
    font-family: 'Spartan', sans-serif;
    font-size: 12px;
    font-weight: 600;
  }

  .filter-header-cell {
    display: flex;
    align-items: center;
  }

  tr {
    td {
      padding: 0.5rem;
      align-content: center;
      font-weight: 300;
      color: black; /* Ensure all table data text is black */

      border-top: 1px;
      border-bottom: 1px;
      border-left: 0;
      border-right: 0;
      border-color: #ecece2;
      border-style: solid;

      /* Nested styling for price span */
      .prices-wrapper {
        color: inherit; /* Maintain the default color for the PriceSpan */
      }
    }

    .first-tbody-cell {
      border-left: 1px;
      border-top: 1px;
      border-bottom: 1px;
      border-right: 0;
      border-color: #ecece2;
      border-style: solid;
      border-radius: 0.5rem 0 0 0.5rem;
    }

    .last-tbody-cell {
      border-right: 1px;
      border-top: 1px;
      border-bottom: 1px;
      border-left: 0;
      border-color: #ecece2;
      border-style: solid;
      border-radius: 0rem 0.5rem 0.5rem 0;
    }

    div.prices-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
  }
`

export const Td = styled.td`
  padding: 0.5rem;
  align-content: center;
  font-weight: bold;
  font-size: 1rem;
  color: black;

  border-top: 1px;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
  border-color: #ecece2;
  border-style: solid;

  .prices-wrapper {
    color: inherit; /* Maintain the default color for the PriceSpan */
  }

  .horizontal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 372px;

    img {
      width: min(65px, 65px);
      object-fit: cover;
    }

    .product_name {
      font-family: 'Nunito', sans-serif;
      font-weight: 500;
      font-size: 12px;
      text-align: start;
      display: -webkit-box; /* Para navegadores baseados no WebKit */
      -webkit-box-orient: vertical; /* Para navegadores baseados no WebKit */
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3; /* Limita a 3 linhas */
      line-clamp: 3;
      color: black; /* Ensure product name is black */
    }

    p {
      width: min(300px, 300px);
      a {
        text-decoration: none;
        color: inherit;
        font-weight: 300;
        text-align: start;
        min-width: 120px;
        color: black; /* Ensure link text is black */
      }
      a:hover {
        color: ${theme.colors.tertiary};
      }
    }
  }
`

interface ButtonAssistantProps {
  prescribedProduct: boolean
}

const assistantModifier = {
  selected: () => css`
    background: transparent;
    border: 1px solid #b5b5b5;
    color: #858585;

    svg {
      color: red;
    }
  `
}

export const ButtonPrescription = styled.button<ButtonAssistantProps>`
  ${({ prescribedProduct }) => css`
    padding: 7px;
    border-radius: 5px;
    background: ${temporaryTheme.colors.link};
    min-width: 120px;

    outline: none;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${temporaryTheme.spacing.space2};
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: ${temporaryTheme.colors.white};

    svg {
      width: 14px;
      min-width: 14px;
      height: 14px;
    }

    ${media.mobileWithTablet`
    min-width: 84px;
    width: 84px;
      font-size: 10px;
    `}

    ${!!prescribedProduct && assistantModifier.selected()};
  `}
`
