import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 24px;
    text-align: center;
    color: #115850;
    margin-bottom: 16px;
  }

  button {
    border: none;
    background-color: #115850;
    font-family: 'Lato';
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: white;
    height: 40px;
  }
`
