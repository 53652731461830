import styled from 'styled-components'

export const Container = styled.div<any>`
  .horizontal-uploads {
    display: flex;
    flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
    justify-content: center;
    width: 100%;
    gap: 12px;
    margin-bottom: 16px;
  }
`
export const Title = styled.div`
  h1 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 10px;
    margin-bottom: 16px;
    margin-top: 24px;
    color: #555358;
  }
`

interface ButtonUploadProps {
  isValue: boolean
}

export const ButtonUpload = styled.button<ButtonUploadProps>`
  background: ${({ isValue }) => (isValue ? '#115850' : 'rgba(196,113,18,0.71)')};
  display: flex;
  font-family: 'Lato';
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  width: 100px;
  height: 100%;
  color: white;
  border-radius: 0 8px 8px 0;

  svg {
    color: white;
    width: 19px;
    height: 19px;
    margin-bottom: 16px;
  }
`

export const CheckboxContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
    color: #777777;
  }
`
