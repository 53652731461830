import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  .real_history {
    padding: 0;
  }
`

export const HeadingBox = styled.div`
  margin-top: 22px;
`

export const CardList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  gap: ${temporaryTheme.spacing.space3};
  padding: 15px 0;

  @media ${MOBILE_WIDTH} {
    padding: 15px;
    min-width: 100%;
    width: 100%;
  }
`

export const Card = styled.div`
  width: 320px;
  height: 217px;
  border-radius: 10px;
  background: #fafafa;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space4};
  padding: ${temporaryTheme.spacing.space5};
  flex-direction: column;

  &:hover {
    box-shadow: ${temporaryTheme.shadow.shad1};
  }
  @media ${MOBILE_WIDTH} {
    min-width: 320px;
    box-shadow: ${temporaryTheme.shadow.shad1};
    width: 320px;
  }
`

export const IconCard = styled.img`
  width: 24px;
`

export const TextCard = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #433f3e;
`

export const OnboardingSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 120px 0 90px 0;
  position: relative;
  z-index: 2;
  background: transparent;
  padding: ${temporaryTheme.spacing.space8} 0;
`

export const Overlay = styled.div`
  width: 200vw;
  background: #748351;
  position: absolute;
  z-index: -1;
  top: 0;

  transform: translateX(-20%);
  height: 100%;
`

export const OnboardingContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: ${temporaryTheme.spacing.space2};

  button {
    font-family: 'Spartan', sans-serif;
  }

  .danger-text {
    line-height: 31px;
    letter-spacing: 4.5%;
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
    gap: ${temporaryTheme.spacing.space4};
    align-items: center;

    button {
      width: 100%;
      min-width: 100%;
    }
  }
`
export const OnboardingTitle = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: white;
  span {
    font-weight: 600;
    font-family: Poppins;
    font-size: inherit;
  }
`
export const OnboardingText = styled.p``
export const OnboardingImagebox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`
export const OnBoardImage = styled.img`
  @media ${MOBILE_WIDTH} {
    width: 333px;
    object-fit: cover;
  }
`
