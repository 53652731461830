import { MdArrowBack } from 'react-icons/md'
import * as Styles from './styles'

interface BackToPanelLinkProps {
  page?: string
}

function BackToPanelLink({ page }: BackToPanelLinkProps) {
  // TODO: refactor pages logic on Settings
  const isDetailsPage = page?.includes('pacientes-')

  return (
    <Styles.BackToPanelLinkContainer>
      <Styles.BackToPanelLinkItself to={!isDetailsPage ? '/painel-do-usuario' : '?pagina=pacientes'}>
        <MdArrowBack /> {!isDetailsPage ? 'Voltar ao painel' : 'Voltar à lista de pacientes'}
      </Styles.BackToPanelLinkItself>
    </Styles.BackToPanelLinkContainer>
  )
}

export default BackToPanelLink
