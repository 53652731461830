import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { cn } from '@cannect/lib/utils'
import * as TabsPrimitive from '@radix-ui/react-tabs'

const { Root } = TabsPrimitive

const List = forwardRef<ElementRef<typeof TabsPrimitive.List>, ComponentPropsWithoutRef<typeof TabsPrimitive.List>>(
  ({ className, ...props }, ref) => (
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        'inline-flex h-12 items-center justify-center gap-2 rounded bg-secondary-300 p-1 text-secondary-800 md:h-[44px] lg:h-[48px] lg:text-base',
        className
      )}
      {...props}
    />
  )
)
List.displayName = TabsPrimitive.List.displayName

const Trigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center whitespace-nowrap rounded border-transparent bg-transparent px-2.5 py-2.5 text-sm font-semibold text-secondary ring-offset-gray-500 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-700 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-gray-50 data-[state=active]:text-secondary-800 data-[state=active]:shadow-sm',
      className
    )}
    {...props}
  />
))
Trigger.displayName = TabsPrimitive.Trigger.displayName

const Content = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'focus-visible:gray-500-ring mt-2 ring-offset-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
))
Content.displayName = TabsPrimitive.Content.displayName

export { Root, List, Trigger, Content }
