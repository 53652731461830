import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const PageTitleContainer = styled.h1`
  display: flex;
  align-items: center;
  gap: 1rem;

  font-size: 3rem;
  color: #748351 !important;
  margin-bottom: 3rem;

  font-family: 'Poppins', sans-serif;

  svg {
    path {
      stroke: #748351 !important;
    }
  }

  @media ${MOBILE_WIDTH} {
    gap: 0.5rem;
    font-size: 2rem;

    svg {
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      min-height: 2.5rem;
    }
  }
`
