import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import TextDanger from '../TextDanger'
import * as Styles from './styles'

export type accordionDataProps = {
  title: string
  text: string
}

export type AccordionPartnesProps = {
  data: accordionDataProps[]
  withPrefix?: boolean
  size?: 'small' | 'medium' | 'large' | 'full'
}

export default function AccordionPartnes({ data = [], withPrefix = true, size = 'medium' }: AccordionPartnesProps) {
  return (
    <Styles.Wrapper size={size}>
      <Styles.AccordionRoot type="single" defaultValue={data[0].title} collapsible>
        {data.length > 0 &&
          data.map((item) => (
            <Styles.AccordionItem value={item.title} key={item.title}>
              <Styles.AccordionTrigger>
                <Styles.Tittle style={{ fontWeight: 500 }}>
                  {withPrefix ? (
                    <>
                      Cannect <span>{item.title}</span>
                    </>
                  ) : (
                    item.title
                  )}
                </Styles.Tittle>
                <IoAddCircleOutline className="icon-open" />
                <IoRemoveCircleOutline className="icon-close" />
              </Styles.AccordionTrigger>
              <Styles.AccordionContent className="contentAccordion">
                <TextDanger fontFamily="Poppins" className="!text-sm lg:!text-base" text={item.text} width="100%" />
              </Styles.AccordionContent>
            </Styles.AccordionItem>
          ))}
      </Styles.AccordionRoot>
    </Styles.Wrapper>
  )
}
