/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'

import Input from 'components/Input'
import api from 'services/api'
import { currencyMask } from 'utils/formatters'
import Item from '../Item'
import Text from '../../../../components/Text'

import * as Style from './styles'

interface ListProps {
  products: []
  isMobile: boolean
  handleSavedItems: (product: any) => void
}

export interface CouponProps {
  name: string
  type_shipping: string
  value_shipping: number
  type: string
  value: number
  infos: CouponInfoProps[]
}

export interface CouponInfoProps {
  id: number
  product_id: number
  brand_id: number
}

export interface ProductProps {
  product_id: number
  quantity: number
  brand_id: number
  cannect_choice: boolean
  class_id: number
  country_id: number
  createdAt: string
  currency: string
  details: string
  image: string
  name: string
  price: number
  unitPrice: number
  route_of_administration_id: number
  shape_id: number
  status: string
  status_assistant: string
  subclass_id: number
  supplier_id: number
  translated_name: string
  updatedAt: string
  uuid: string
  weight: null
}

function List({ products, handleSavedItems }: ListProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [productValue, setProductValue] = useState<number>(0)

  const [isPercentage, setIsPercentage] = useState(false)
  const [isShippingPercentage, setIsShippingPercentage] = useState(false)

  const [shippingPercentageValue, setShippingPercentageValue] = useState(0)
  const [shippingValue, setShippingValue] = useState<number>(110)

  const [discountValue, setDiscountValue] = useState(0)
  const [finalDiscountValue, setFinalDiscountValue] = useState(0)
  const [shippingDiscountValue, setShippingDiscountValue] = useState(0)

  const [totalProductsValue, setTotalProductsValue] = useState(0)
  const [totalDiscountsValue, setTotalDiscountsValue] = useState(0)

  const [finalValue, setFinalValue] = useState(0)

  const [couponActive, setCouponActive] = useState(false)

  const [inputValue, setInputValue] = useState<any>({
    coupon: ''
  })

  const { coupon } = inputValue

  const totalValueWithDiscount = productValue + shippingValue - finalDiscountValue

  let totalProductValue = 0
  let totalDiscountValue = 0

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    if (value.length <= 0) {
      setCouponActive(false)
      getProductsValue()
      setFinalDiscountValue(0)
      localStorage.removeItem('@CANNECT:COUPON')
    }

    setInputValue((inputValue: any) => ({ ...inputValue, [id]: value }))
  }

  const getProductsValue = () => {
    let total = 0
    // eslint-disable-next-line array-callback-return
    products?.map((product: ProductProps) => {
      total += product.unitPrice * product.quantity
    })
    setProductValue(total)
    setFinalValue(total + shippingValue)
  }

  useEffect(() => {
    getProductsValue()

    if (couponActive) {
      getDiscount()
    }

    // if (products.length === 0) {
    //   setProductValue(0)
    //   setIsPercentage(false)
    //   setIsShippingPercentage(false)
    //   setShippingPercentageValue(0)
    //   setShippingValue(110)
    //   setDiscountValue(0)
    //   setFinalDiscountValue(0)
    //   setShippingDiscountValue(0)
    //   setTotalProductsValue(0)
    //   setTotalDiscountsValue(0)
    //   setCouponActive(false)
    // }
  }, [products])

  const getDiscount = async () => {
    const payload = {
      coupon: {
        name: inputValue.coupon
      },
      items: [...products]
    } as any

    try {
      setLoading(true)
      const {
        data: { coupon, items }
      } = await api.post(`/coupon/valid-items`, payload)

      localStorage.setItem('@CANNECT:COUPON', coupon.name)

      setCouponActive(true)

      const { totalDiscount, totalProduct } = calculateDiscounts(coupon, products)
      totalProductValue = totalProduct
      totalDiscountValue = totalDiscount

      setProductValue(totalProductValue)
      setFinalDiscountValue(totalDiscountValue)
      setFinalValue(totalProductValue + shippingValue - totalDiscountValue)
    } catch (error: any) {
      setErrorMsg(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const calculateDiscounts = (coupon: CouponProps, products: ProductProps[]) => {
    totalProductValue = products.reduce((sum, product) => sum + product.unitPrice * product.quantity, 0)

    if (coupon.infos && coupon.infos.length > 0) {
      totalDiscountValue = calculateDiscountByInfos(coupon, products)
    } else {
      totalDiscountValue = coupon.type === 'percent' ? (coupon.value / 100) * totalProductValue : coupon.value
    }

    const shippingDiscountValue =
      coupon.value_shipping > 0
        ? coupon.type_shipping === 'percent'
          ? (coupon.value_shipping / 100) * 110
          : coupon.value_shipping
        : 0

    return { totalDiscount: totalDiscountValue + shippingDiscountValue, totalProduct: totalProductValue }
  }

  const calculateDiscountByInfos = (coupon: CouponProps, products: ProductProps[]): number => {
    let brandsFinded: number[] = []
    let productsFinded: number[] = []
    let discount = 0

    brandsFinded = coupon.infos.filter(info => info.brand_id).map(info => info.brand_id)
    productsFinded = coupon.infos.filter(info => info.product_id).map(info => info.product_id)

    products.forEach(product => {
      if (
        productsFinded.length <= 0 &&
        brandsFinded.length > 0 &&
        brandsFinded.some(brandId => product.brand_id === brandId)
      ) {
        discount += calculateDiscountPerProduct(coupon, product)
      } else if (productsFinded.length > 0 && productsFinded.some(productId => product.product_id === productId)) {
        discount += calculateDiscountPerProduct(coupon, product)
      }
    })

    return discount
  }

  const calculateDiscountPerProduct = (coupon: CouponProps, product: ProductProps): number => {
    return coupon.type === 'percent'
      ? (coupon.value / 100) * product.unitPrice * product.quantity
      : coupon.value * product.quantity
  }

  useEffect(() => {
    if (inputValue.coupon === '') {
      setErrorMsg('')
    }
  }, [inputValue])

  return (
    <Style.ContentWrapper>
      {products && products?.length > 0 && (
        <>
          {isMobile ? null : (
            <>
              <Style.Container isMobile={isMobile}>
                <Style.TableHeader>
                  <div className="product">
                    <Text type="md" bold>
                      Produto
                    </Text>
                  </div>
                  <div className="product-properties">
                    <Text type="md" bold>
                      Quantidade
                    </Text>
                    <Text type="md" bold>
                      Valor Unitário
                    </Text>
                    <Text type="md" bold>
                      Valor Total
                    </Text>
                  </div>
                </Style.TableHeader>

                <div className="white-space" />
              </Style.Container>
            </>
          )}

          {products?.map((product: ProductProps) => {
            return (
              <Item
                key={product.product_id}
                product={product}
                isMobile={isMobile}
                handleSavedItems={handleSavedItems}
              />
            )
          })}
          <div className="white-space" />
          <Style.OrderResume isMobile={isMobile}>
            <div className="row-items">
              <Text type="lg">Resumo do pedido</Text>
              <div className="coupon-container">
                <Text type="md" color="secondary">
                  Cupom de desconto
                </Text>
                <Input
                  value={coupon}
                  placeholder="Código do cupom"
                  id="coupon"
                  onChange={e => changeValue(e)}
                  style={{
                    marginTop: '5px',
                    border: ' 1px solid rgba(197, 88, 27, 0.5)',
                    borderRadius: 'none'
                  }}
                />
                <Style.SubmitButton disabled={inputValue.coupon === ''} onClick={getDiscount}>
                  Adicionar Cupom
                </Style.SubmitButton>
                {errorMsg && (
                  <Text type="lg" color="secondary">
                    {errorMsg}
                  </Text>
                )}
              </div>
            </div>

            <div className="row-items">
              <Text type="lg">Subtotal</Text>
              <Text type="lg"> {currencyMask(productValue)}</Text>
            </div>
            <div className="row-items">
              <Text type="lg">Custo de importação</Text>

              <Text type="lg">{currencyMask(110)}</Text>
            </div>
            <div className="row-items">
              <Text type="lg">Descontos</Text>
              <Text type="lg" color={finalDiscountValue ? 'primary' : undefined}>
                {currencyMask(finalDiscountValue)}
              </Text>
            </div>
            <div className="total-display">
              <Text type="lg" bold>
                Total
              </Text>

              <Text type="lg" bold>
                {currencyMask(finalValue)}
              </Text>
            </div>
          </Style.OrderResume>
        </>
      )}
    </Style.ContentWrapper>
  )
}

export default List
