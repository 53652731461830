/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { FileError } from 'react-dropzone'
import { TAcceptDefaults } from '@cannect/new-components/molecules/FileItem/types'
import { formatFileSize } from '@cannect/new-components/molecules/FileItem/utils'

export function validateFile(
  file: File | DataTransferItem,
  accept: Partial<TAcceptDefaults>
): FileError | FileError[] | null {
  if (file instanceof File) {
    const [size, unit] = formatFileSize(file.size).split(' ')

    if (['GB', 'TB'].includes(unit) || (unit === 'MB' && parseInt(size, 10) > 20)) {
      return { code: 'SIZE', message: 'File size is too large.' }
    }

    if (!Object.keys(accept).includes(file.type)) {
      return { code: 'UPLOAD', message: 'Unsupported file type.' }
    }
  }

  if (file instanceof DataTransferItem) {
    if (!Object.keys(accept).includes(file.type)) {
      return { code: 'UPLOAD', message: 'Unsupported file type.' }
    }
  }

  return null
}
