import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const SubmitButton = styled.button`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #464F31;
  border: 1px solid #464F31;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  border-radius: 5px;
  letter-spacing: 0.04em;
`

export const MethodsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 30px;
  margin: 28px 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -36px;
    right: -36px;
    height: 0.7px;
    background-color: #9FAE7C;
    @media ${MOBILE_WIDTH} {
      left: -25px;
      right: -25px;
    }
  }
`
export const MethodBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  background-color: ${({selected}) => (selected ? '#ecefe5' : '#fff' )};
  border: 1px solid ${temporaryTheme.colors.whiteBorder};
  border-radius: 8px;
  width: 100%;
  height: 60px;
  padding: 12px 14px;
  cursor: pointer;

  @media ${MOBILE_WIDTH} {
      height: 50px;
    }


  .ant-radio-wrapper > span:last-child {
    display: flex;
    align-items: center;
    gap: 6px;

    @media ${MOBILE_WIDTH} {
      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .ant-radio-checked .ant-radio-inner {
        background-color: ${temporaryTheme.colors.sitePrimary};
        border-color: ${temporaryTheme.colors.sitePrimary};
    }
    .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus+.ant-radio-inner {
    border-color: ${temporaryTheme.colors.sitePrimary}; !important;
  }

  .ant-radio-indeterminate .ant-radio-inner::after {
    background-color: ${temporaryTheme.colors.sitePrimary};
  }



  > span {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: ${temporaryTheme.colors.darkGrey};

    @media ${MOBILE_WIDTH} {
      font-size: 12px;


    }
  }
`

export const EconomyText = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #858585;

  > span {
    color: ${temporaryTheme.colors.sitePrimary};
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    font-size: 10px;
    line-height: 10px;
  }
`

export const ButtonCopy = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 285px;
  margin-top: 22px;
  background-color: #f0780a;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
`

export const ButtonCopied = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;

  background-color: #464F31;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
`

export const PaymentError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 700;
  }
`
