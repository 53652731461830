import { ReactNode } from 'react'
import * as Styles from './styles'

interface StatusRuleItemProps {
  isFirstItem: boolean
  isLastItem: boolean
  isChecked: boolean
  icon: ReactNode
  label: string
}
export default function StatusRuleItem({ isFirstItem, isLastItem, icon, label, isChecked }: StatusRuleItemProps) {
  return (
    <Styles.StatusRuleItemContainer>
      <Styles.CircleAndIconWrapper>
        <Styles.StatusLine isInvisible={isFirstItem} />
        <Styles.StatusRuleItemCircle isChecked={isChecked}>{icon}</Styles.StatusRuleItemCircle>
        <Styles.StatusLine isInvisible={isLastItem} />
      </Styles.CircleAndIconWrapper>
      <Styles.StatusRuleItemText isChecked={isChecked}>{label}</Styles.StatusRuleItemText>
    </Styles.StatusRuleItemContainer>
  )
}
