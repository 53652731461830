import { usePrepareCart } from 'hooks/usePrepareCart'
import React, { useState } from 'react'
import api from 'services/api'

const useResume = () => {
  const [couponIsValid, setCouponIsValid] = useState(false)
  const [statusDiscount, setStatusDiscount] = useState('')
  const { lastOrderCreated, updateCartCoupon } = usePrepareCart()
  const [inputCoupon, setInputCoupon] = useState<any>({
    couponValue: lastOrderCreated?.coupon?.name
  })
  const { couponValue } = inputCoupon

  const changeValueCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    if (!value) {
      setCouponIsValid(false)
      setStatusDiscount('')
    }
    setInputCoupon((inputValue: any) => ({ ...inputValue, [id]: value }))
  }

  async function getDiscount(coupon: string) {
    try {
      setStatusDiscount('')
      const payload = {
        coupon: {
          name: coupon
        },
        items: lastOrderCreated?.items?.map((item: any) => {
          return {
            product_id: item?.product_id,
            ...item
          }
        })
      }

      const { data } = await api.post(`/coupon_public/valid-items`, payload)
      setCouponIsValid(true)
      setStatusDiscount('Cupom valido!')
      updateCartCoupon(data?.coupon, data?.items)
    } catch (e) {
      setStatusDiscount('Cupom inválido')
      setCouponIsValid(false)
    }
  }
  return { getDiscount, statusDiscount, couponIsValid, couponValue, changeValueCoupon }
}

export default useResume
