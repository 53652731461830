export const breakpoints = {
  mobile: '767px',
  tablet: '1023px',
  desktop: '1024px'
}

export const breakpointsToHook = {
  mobile: `(max-width: ${breakpoints.mobile})`,
  tablet: `(min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet})`,
  desktop: `(min-width: ${breakpoints.desktop})`
}
