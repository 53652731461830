import React from 'react'
import Modal from 'new-components/Modal'
import { ButtonAction, Container, ContainerSabor, ProductSabor } from './styles'
import { usePrepareCart } from '../../hooks/usePrepareCart'
import LoadingContainer from '../LoadingContainer'
import { produto_sem_imagem } from '../../assets/img'

interface ModalEditProductProps {
  isOpen: boolean
  closeModal: any
  product: any
  flavors: any
  flavorSelected: any
}

function ModalEditProduct({ isOpen, closeModal, product, flavors, flavorSelected }: ModalEditProductProps) {
  const { addToCartFlavor } = usePrepareCart()
  const [selectedSabor, setSelectedSabor] = React.useState(flavorSelected?.id)
  const [loading, setLoading] = React.useState(false)

  const updateProduct = async () => {
    if (product.product.id !== selectedSabor) {
      setLoading(true)
      await addToCartFlavor(product.product.id, selectedSabor, 1)
      setLoading(false)
      closeModal()
    }
  }

  const imagePath = product?.product?.media
    ? product?.product?.media?.filter((value: any) => value.format === 'IMG')[0]?.path
    : product?.image
  return (
    <Modal isOpen={isOpen} setOpenModal={closeModal} title="Opções de edição do produto:">
      <Container>
        {loading ? (
          <LoadingContainer loading />
        ) : (
          <>
            <p>{product?.product?.name}</p>
            <div className="constainer-image">
              <div className="image">
                <img src={imagePath || produto_sem_imagem} alt="foto produto" />
              </div>
            </div>
            <ContainerSabor>
              <p>Escolha o sabor:</p>
              <div className="wrapper">
                {flavors?.map((sabor: any) => (
                  <ProductSabor isActive={selectedSabor === sabor.id} onClick={() => setSelectedSabor(sabor.id)}>
                    {sabor.flavor}
                  </ProductSabor>
                ))}
              </div>
            </ContainerSabor>
            <ButtonAction onClick={() => updateProduct()}>Atualizar</ButtonAction>
          </>
        )}
      </Container>
    </Modal>
  )
}

export default ModalEditProduct
