import { ReactNode } from 'react'
import * as Style from './styles'

interface CustomButtonProps {
  children: ReactNode
  active: boolean
  onClick: () => void
}

export default function CustomButton({ children, active, onClick }: CustomButtonProps) {
  return (
    <Style.CustomButtonContainer active={active} onClick={onClick}>
      {children}
    </Style.CustomButtonContainer>
  )
}
