import { svgCursoIntrodutorio } from 'assets/svg'
import EducateAside from 'components/CannectEducate/EducateAside'
import EducateNavigator from 'components/CannectEducate/EducateNavigator'
import SocialMedia from 'components/SocialMedia'
import TabContent from 'components/CannectEducate/TabContent'
import TabContentTitle from 'components/CannectEducate/TabContentTitle'
import TabsNavigator from 'components/TabsNavigator'
import * as Style from 'styles/cannectEducate'
import { useIntrodutoryCourse } from 'hooks/useIntrodutoryCourse'
import { useEffect } from 'react'
import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'
import TeacherBiography from './TeacherBiography'

export default function IntrodutoryCoursesHome() {
  const { lessons, loading, postsAOA, course, uniqueTeachers, fetchCourse } = useIntrodutoryCourse()

  useEffect(() => {
    fetchCourse()
  }, [])

  return (
    <Style.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Educa', link: '/educa' },
          { label: 'Curso Introdutório', link: `/educa/cursos-introdutorios` }
        ]}
      />
      <TabsNavigator />
      <EducateNavigator />

      <Style.Content>
        <EducateAside />

        <Style.Wrapper>
          <SocialMedia message="Acesse já o curso introdutório da Cannect!" />

          <TabContentTitle
            title="Curso Introdutório"
            titleImg={svgCursoIntrodutorio}
            postsCountText={`${lessons?.length} aulas encontradas`}
          />

          {!loading && course ? (
            <>
              <p className="course-description">{course?.description}</p>
              {postsAOA.map((posts, index) => (
                <>
                  <h2 className="module-title">Módulo {index + 1}</h2>
                  <TabContent key={`posts-${index}`} posts={posts} actionButtonText="ASSISTIR" />
                </>
              ))}
              <Style.TeachersContainer>
                {uniqueTeachers.map(teacher => (
                  <TeacherBiography key={teacher.id} teacher={teacher} />
                ))}
              </Style.TeachersContainer>
            </>
          ) : (
            <Loading loading={loading} />
          )}
        </Style.Wrapper>
      </Style.Content>
    </Style.Container>
  )
}
