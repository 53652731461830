import { google, outlook, yahoo, ics, CalendarEvent } from 'calendar-link'
import { SiApple, SiGooglecalendar, SiMicrosoftoutlook } from 'react-icons/si'
import { CiCalendar } from 'react-icons/ci'
import { Button, buttonVariants } from '../Button'
import { Dropdown } from '..'
import { VariantProps } from 'tailwind-variants'
import { ComponentProps } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

type AddToCalendarButtonProps = {
  event: CalendarEvent
} & VariantProps<typeof buttonVariants> &
  ComponentProps<'button'>

export const AddToCalendarButton = ({ event, ...triggerProps }: AddToCalendarButtonProps) => {
  const googleUrl = google(event)
  const outlookUrl = outlook(event)
  const yahooUrl = yahoo(event)
  const icsUrl = ics(event)

  const calendarItems = [
    {
      title: 'Google',
      url: googleUrl,
      icon: <SiGooglecalendar />
    },
    {
      title: 'Outlook',
      url: outlookUrl,
      icon: <SiMicrosoftoutlook />
    },
    {
      title: 'iCal',
      url: icsUrl,
      icon: <SiApple />
    },
    {
      title: 'Yahoo',
      url: yahooUrl,
      icon: <CiCalendar />
    }
  ]

  return (
    <Dropdown.Menu modal={false}>
      <Dropdown.MenuTrigger className="border-none">
        <Button {...triggerProps}>Adicionar ao calendário</Button>
      </Dropdown.MenuTrigger>
      <AnimatePresence>
        <Dropdown.MenuContent asChild>
          <motion.div
            initial={{ opacity: 0, y: 8 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 8 }}
            transition={{ duration: 0.3 }}
          >
            {calendarItems.map(item => (
              <Dropdown.MenuItem
                className="w-[--radix-dropdown-menu-trigger-width]"
                key={item.url}
                onSelect={() => window.open(item.url, '_blank', 'noopener')}
              >
                <span className="mr-2">{item.title}</span>
                {item.icon}
              </Dropdown.MenuItem>
            ))}
          </motion.div>
        </Dropdown.MenuContent>
      </AnimatePresence>
    </Dropdown.Menu>
  )
}
