import { Skeleton } from '@cannect/new-components/atoms'

export const SkeletonProductItem = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-start gap-4">
        <Skeleton className="h-[100px] w-full max-w-[100px] sm:max-w-xs" />
        <div className="flex w-full flex-col gap-2">
          <Skeleton className="h-4 w-3/6" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
        </div>
      </div>
      <div className="flex h-[42px] w-[120px] items-center gap-2">
        <Skeleton className="h-full w-full rounded-md" />
        <Skeleton className="h-full w-12 rounded-md" />
      </div>
    </div>
  )
}
