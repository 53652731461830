import Button from 'components/Button'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import { theme } from 'design-cannect'
import useMediaQuery from 'hooks/useMediaQuery'
import { useState } from 'react'
import ReactPlayer from 'react-player'
import { DESKTOP_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'
import When from 'utils/when'
import * as Styles from './style'
import { useHistory } from 'react-router'

function FirstSession() {
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const isTablet = useMediaQuery(TABLET_WIDTH)
  const isDesktop = useMediaQuery(DESKTOP_WIDTH)
  const [modal, setModal] = useState(false)
  function setModalOpen() {
    setModal(!modal)
  }

  const videoUrl = '../assets/img/VIDEO-INSTITUCIONAL-CANNECT-SITE.mp4'

  const renderVideo = () => {
    return <ReactPlayer url={videoUrl} width="100%" height="auto" loop />
  }
  return (
    <Styles.Container>
      <Styles.LeftWrapper>
        <Styles.TextDiv>
          <Styles.Title isMobile={isMobile}>
            Somos a <span>Cannect</span>
          </Styles.Title>
          <When expr={!isDesktop}>
            <Styles.VideoDiv>{renderVideo()}</Styles.VideoDiv>
          </When>
          <Styles.Subtitle>
            Um ecossistema digital que conecta pacientes, médicos, instituições de saúde, pesquisadores e fornecedores
            que busca a melhor experiência do paciente no uso da Cannabis medicinal.
          </Styles.Subtitle>
        </Styles.TextDiv>
        <Styles.WhiteWrapp isDesktop={isDesktop || isTablet}>
          <Button
            width="252px"
            style={{
              borderRadius: '5px',
              backgroundColor: `${theme.colors.darkGrey}`
            }}
            onClick={() => history.push('/agendamento')}
          >
            <Styles.ButtonText color="#fff">Agende sua consulta</Styles.ButtonText>
          </Button>
          <When expr={!isMobile}>
            <Button
              outlined
              width="252px"
              style={{
                borderRadius: '5px',
                border: '2px solid #21976E',
                background: '#fff'
              }}
              onClick={() => setModalOpen()}
            >
              <Styles.ButtonText>Inicie seu tratamento</Styles.ButtonText>
            </Button>
          </When>
        </Styles.WhiteWrapp>

        <ModalHome isOpen={modal} setOpenModal={setModal}>
          <BeginCheckout />
        </ModalHome>
      </Styles.LeftWrapper>
      <When expr={isDesktop}>
        <Styles.RightWrapper>{renderVideo()}</Styles.RightWrapper>
      </When>
    </Styles.Container>
  )
}
export default FirstSession
