import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main``
export const SectionCategory = styled.div`
  width: 100%;
`

export const IconCategory = styled.img`
  margin-top: -5px;
`

export const TitleCategory = styled.h2`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Spartan', sans-serif;
  font-size: 22px;
  color: ${temporaryTheme.colors.darkGrey};
  gap: ${temporaryTheme.spacing.space2};
  margin-bottom: 26px;

  span {
    color: ${temporaryTheme.colors.sitePrimary};
  }
  @media (${MOBILE_WIDTH}) {
    flex-direction: column;
  }
`

export const DescriptionCategory = styled.div`
  padding: ${temporaryTheme.spacing.space4} 0;
`

export const CardGrid = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
`

export const EmptyState = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
`

export const ActionsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button {
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    border-width: 2px;
  }

  @media (${MOBILE_WIDTH}) {
    justify-content: center;
  }
`

export const LoadingBox = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LinkExternal = styled(Link)`
  color: unset;
  text-decoration: none;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
`
