import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Balloon } from './styles'

const offsetElementPosition = (element: any) => {
  const rect = element.getBoundingClientRect()
  const scrollLeft = window?.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window?.pageYOffset || document.documentElement.scrollTop
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    width: rect.width,
    height: rect.height,
    right: rect.right + scrollLeft,
    bottom: rect.bottom + scrollTop
  }
}

function Tooltip({ content, placement, children, disableFocus, disableHover }: any) {
  const [showTip, setShowTip] = useState<any>(false)
  const [isTriggerHovered, setIsTriggerHovered] = useState<any>(false)
  const [isTipHovered, setIsTipHovered] = useState<any>(false)
  const [triggerPosition, setTriggerPosition] = useState<any>({})
  const [triggerElement, setTriggerElement] = useState<any>(null)
  const timerShowTip = useRef<any>()
  const timerHideTip = useRef<any>()

  const stopHiddenTimer = () => {
    clearTimeout(timerHideTip.current)
  }

  const stopShowTimer = () => {
    clearTimeout(timerShowTip.current)
  }

  useEffect(() => {
    if (isTriggerHovered || isTipHovered) {
      stopHiddenTimer()

      if (isTipHovered) {
        setShowTip(true)
      } else {
        timerShowTip.current = setTimeout(() => {
          setShowTip(true)
        }, 100)
      }
    }

    if (!isTriggerHovered && !isTipHovered) {
      stopShowTimer()
      timerHideTip.current = setTimeout(() => setShowTip(false), 200)
    }

    return () => {
      stopShowTimer()
      stopHiddenTimer()
    }
  }, [isTriggerHovered, isTipHovered])

  const onHovered = (elem?: string, value?: boolean, event?: any, propEvent?: any) => {
    if (event) {
      setTriggerPosition(offsetElementPosition(event.target))
    }
    switch (elem) {
      case 'trigger':
        setIsTriggerHovered(value)
        break
      case 'tip':
        setIsTipHovered(value)
        break
      default:
        break
    }
    if (propEvent) {
      propEvent()
    }
  }

  useEffect(() => {
    const Elem = () =>
      React.cloneElement(children, {
        ...children.props,
        onMouseEnter: (event: any) => !disableHover && onHovered('trigger', true, event, children.props.onMouseEnter),
        onMouseLeave: (event: any) => !disableHover && onHovered('trigger', false, event, children.props.onMouseLeave),
        onFocus: (event: any) => !disableFocus && onHovered('trigger', true, event, children.props.onFocus),
        onBlur: (event: any) => !disableFocus && onHovered('trigger', false, event, children.props.onBlur),
        'data-testid': 'trigger'
      })
    setTriggerElement(Elem)
  }, [disableHover, disableFocus])

  return (
    <>
      {triggerElement}

      {showTip && (
        <Tip
          onMouseEnter={() => onHovered('tip', true)}
          onMouseLeave={() => onHovered('tip', false)}
          position={triggerPosition}
          placement={placement}
          data-testid="balloon"
        >
          {content}
        </Tip>
      )}
    </>
  )
}

export default Tooltip

const dynamicPositionTooltip = (placement: any, tooltip: any, setCallback: any) => {
  if (tooltip.top - 5 < 0) {
    switch (placement) {
      case 'top':
        setCallback('bottom')
        break
      case 'topLeft':
        setCallback('bottomLeft')
        break
      case 'topRight':
        setCallback('bottomRight')
        break
      case 'left':
        setCallback('leftTop')
        break
      case 'right':
        setCallback('rightTop')
        break
      case 'leftTop':
        setCallback('leftBottom')
        break
      case 'rightTop':
        setCallback('rightBottom')
        break
      default:
        break
    }
  }

  if (tooltip.left - 5 < 0) {
    switch (placement) {
      case 'top':
        setCallback('topLeft')
        break
      case 'bottom':
        setCallback('bottomLeft')
        break
      case 'left':
        setCallback('bottomLeft')
        break
      case 'leftTop':
        setCallback('topLeft')
        break
      case 'leftBottom':
        setCallback('bottomLeft')
        break
      case 'topRight':
        setCallback('topLeft')
        break
      case 'bottomRight':
        setCallback('bottomLeft')
        break
      default:
        break
    }
  }

  if (tooltip.right + 20 > window.innerWidth) {
    switch (placement) {
      case 'top':
        setCallback('topRight')
        break
      case 'bottom':
        setCallback('bottomRight')
        break
      case 'right':
        setCallback('bottomRight')
        break
      case 'rightTop':
        setCallback('topRight')
        break
      case 'rightBottom':
        setCallback('bottomRight')
        break
      default:
        break
    }
  }

  if (tooltip.bottom + 5 > window.innerHeight) {
    switch (placement) {
      case 'bottom':
        setCallback('top')
        break
      case 'bottomLeft':
        setCallback('topLeft')
        break
      case 'bottomRight':
        setCallback('topRight')
        break
      case 'right':
        setCallback('rightBottom')
        break
      case 'left':
        setCallback('leftBottom')
        break
      case 'leftTop':
        setCallback('leftBottom')
        break
      case 'rightTop':
        setCallback('rightBottom')
        break
      default:
        break
    }
  }
}

const Tip = ({ placement, ...props }: any) => {
  const [fade, setFade] = useState<any>(false)
  const [dynamicPlacement, setDynamicPlacement] = useState<any>(placement)
  const tooltipElement = useRef<any>(null)
  const timerShow = useRef<any>()

  useEffect(() => {
    timerShow.current = setTimeout(() => setFade(true), 50)
    const tooltipPosition = tooltipElement.current.getBoundingClientRect()
    const repositionTooltip = (pos: any) => {
      setDynamicPlacement(pos)
    }
    dynamicPositionTooltip(dynamicPlacement, tooltipPosition, repositionTooltip)

    return () => {
      clearTimeout(timerShow.current)
    }
  }, [dynamicPlacement])

  const component = <Balloon ref={tooltipElement} fade={fade} placement={dynamicPlacement} {...props} />

  return ReactDOM.createPortal(component, typeof document !== 'undefined' && (document.querySelector('body') as any))
}
