import { NothingToExtremely, Satisfaction, useCannectCare } from 'hooks/useCannectCare'
import { HorizontalContent } from 'pages/CannectCare/styles'
import DeactivatableButton from '../../DeactivatableButton'
import Question from '../Question'

export default function LifeAssessment() {
  const {
    setStep,
    physicalPain,
    setPhysicalPain,
    medialTreatment,
    setMedialTreatment,
    enjoyLife,
    setEnjoyLife,
    lifeMakesSense,
    setLifeMakesSense,
    concentration,
    setConcentration,
    safety,
    setSafety,
    physicalEnvironment,
    setPhysicalEnvironment
  } = useCannectCare()

  function handlePhysicalPainChoice(id: string) {
    setPhysicalPain(id as NothingToExtremely)
  }

  function handleMedicalTreatmentChoice(id: string) {
    setMedialTreatment(id as NothingToExtremely)
  }

  function handleEnjoyLifeChoice(id: string) {
    setEnjoyLife(id as NothingToExtremely)
  }

  function handleLifeMakesSenseChoice(id: string) {
    setLifeMakesSense(id as NothingToExtremely)
  }

  function handleConcentrationChoice(id: string) {
    setConcentration(id as NothingToExtremely)
  }

  function handleSafetyChoice(id: string) {
    setSafety(id as NothingToExtremely)
  }

  function handlePhysicalEnvironmentChoice(id: string) {
    setPhysicalEnvironment(id as NothingToExtremely)
  }

  return (
    <>
      <span>As questões seguintes são sobre o quanto você tem sentido algumas coisas nas últimas duas semanas.</span>
      <Question
        title="Em que medida você acha que sua dor (física) impede você de fazer o que você precisa?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handlePhysicalPainChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'physicalPain-nothing',
            checked: physicalPain === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'physicalPain-veryLittle',
            checked: physicalPain === 'veryLittle'
          },
          {
            id: 'moreOrLess',
            label: 'mais ou menos',
            labelId: 'physicalPain-moreOrLess',
            checked: physicalPain === 'moreOrLess'
          },
          {
            id: 'quite',
            label: 'bastante',
            labelId: 'physicalPain-quite',
            checked: physicalPain === 'quite'
          },
          {
            id: 'extremely',
            label: 'extremamente',
            labelId: 'physicalPain-extremely',
            checked: physicalPain === 'extremely'
          }
        ]}
      />
      <Question
        title="O quanto você precisa de algum tratamento médico para levar sua vida diária?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleMedicalTreatmentChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'medialTreatment-nothing',
            checked: medialTreatment === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'medialTreatment-veryLittle',
            checked: medialTreatment === 'veryLittle'
          },
          {
            id: 'moreOrLess',
            label: 'mais ou menos',
            labelId: 'medialTreatment-moreOrLess',
            checked: medialTreatment === 'moreOrLess'
          },
          {
            id: 'quite',
            label: 'bastante',
            labelId: 'medialTreatment-quite',
            checked: medialTreatment === 'quite'
          },
          {
            id: 'extremely',
            label: 'extremamente',
            labelId: 'medialTreatment-extremely',
            checked: medialTreatment === 'extremely'
          }
        ]}
      />
      <Question
        title="O quanto você aproveita a vida?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleEnjoyLifeChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'enjoyLife-nothing',
            checked: enjoyLife === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'enjoyLife-veryLittle',
            checked: enjoyLife === 'veryLittle'
          },
          {
            id: 'moreOrLess',
            label: 'mais ou menos',
            labelId: 'enjoyLife-moreOrLess',
            checked: enjoyLife === 'moreOrLess'
          },
          {
            id: 'quite',
            label: 'bastante',
            labelId: 'enjoyLife-quite',
            checked: enjoyLife === 'quite'
          },
          {
            id: 'extremely',
            label: 'extremamente',
            labelId: 'enjoyLife-extremely',
            checked: enjoyLife === 'extremely'
          }
        ]}
      />
      <Question
        title="Em que medida você acha que a sua vida tem sentido?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleLifeMakesSenseChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'lifeMakesSense-nothing',
            checked: lifeMakesSense === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'lifeMakesSense-veryLittle',
            checked: lifeMakesSense === 'veryLittle'
          },
          {
            id: 'moreOrLess',
            label: 'mais ou menos',
            labelId: 'lifeMakesSense-moreOrLess',
            checked: lifeMakesSense === 'moreOrLess'
          },
          {
            id: 'quite',
            label: 'bastante',
            labelId: 'lifeMakesSense-quite',
            checked: lifeMakesSense === 'quite'
          },
          {
            id: 'extremely',
            label: 'extremamente',
            labelId: 'lifeMakesSense-extremely',
            checked: lifeMakesSense === 'extremely'
          }
        ]}
      />
      <Question
        title="O quanto você consegue se concentrar?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleConcentrationChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'concentration-nothing',
            checked: concentration === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'concentration-veryLittle',
            checked: concentration === 'veryLittle'
          },
          {
            id: 'moreOrLess',
            label: 'mais ou menos',
            labelId: 'concentration-moreOrLess',
            checked: concentration === 'moreOrLess'
          },
          {
            id: 'quite',
            label: 'bastante',
            labelId: 'concentration-quite',
            checked: concentration === 'quite'
          },
          {
            id: 'extremely',
            label: 'extremamente',
            labelId: 'concentration-extremely',
            checked: concentration === 'extremely'
          }
        ]}
      />
      <Question
        title="Quão seguro(a) você se sente em sua vida diária?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleSafetyChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'safety-nothing',
            checked: safety === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'safety-veryLittle',
            checked: safety === 'veryLittle'
          },
          {
            id: 'moreOrLess',
            label: 'mais ou menos',
            labelId: 'safety-moreOrLess',
            checked: safety === 'moreOrLess'
          },
          {
            id: 'quite',
            label: 'bastante',
            labelId: 'safety-quite',
            checked: safety === 'quite'
          },
          {
            id: 'extremely',
            label: 'extremamente',
            labelId: 'safety-extremely',
            checked: safety === 'extremely'
          }
        ]}
      />
      <Question
        title="Quão saudável é o seu ambiente físico (clima, barulho, poluição, atrativos)?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handlePhysicalEnvironmentChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'physicalEnvironment-nothing',
            checked: physicalEnvironment === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'physicalEnvironment-veryLittle',
            checked: physicalEnvironment === 'veryLittle'
          },
          {
            id: 'moreOrLess',
            label: 'mais ou menos',
            labelId: 'physicalEnvironment-moreOrLess',
            checked: physicalEnvironment === 'moreOrLess'
          },
          {
            id: 'quite',
            label: 'bastante',
            labelId: 'physicalEnvironment-quite',
            checked: physicalEnvironment === 'quite'
          },
          {
            id: 'extremely',
            label: 'extremamente',
            labelId: 'physicalEnvironment-extremely',
            checked: physicalEnvironment === 'extremely'
          }
        ]}
      />
      <HorizontalContent style={{ justifyContent: 'space-between' }}>
        <DeactivatableButton outlined onClick={() => setStep(state => state - 1)}>
          Voltar
        </DeactivatableButton>
        <DeactivatableButton
          onClick={() => setStep(state => state + 1)}
          disabled={
            physicalPain === '' ||
            medialTreatment === '' ||
            enjoyLife === '' ||
            lifeMakesSense === '' ||
            concentration === '' ||
            safety === '' ||
            physicalEnvironment === ''
          }
        >
          Próximo
        </DeactivatableButton>
      </HorizontalContent>
    </>
  )
}
