import { useEffect, useState } from 'react'

import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import { useFetch } from 'hooks/useFetch'

import { semImagem } from 'assets/svg'
import { cannectDefaultProduct } from 'assets/img'

import { currencyMask } from 'utils/formatters'
import Text from '../../../../components/Text'

import * as Style from './styles'

interface ProductProps {
  product_id: number
  quantity: number
  brand_id: number
  cannect_choice: boolean
  class_id: number
  country_id: number
  createdAt: string
  currency: string
  details: string
  image: string
  name: string
  price: number
  unitPrice: number
  route_of_administration_id: number
  shape_id: number
  status: string
  status_assistant: string
  subclass_id: number
  supplier_id: number
  translated_name: string
  updatedAt: string
  uuid: string
  weight: null
}

interface GetItemProps {
  product: ProductProps
  isMobile?: boolean
  handleSavedItems: (product: any) => void
}

function Item({ product, handleSavedItems }: GetItemProps) {
  const [getProduct, setGetProduct] = useState<ProductProps | null>(product)
  const [productValue, setProductValue] = useState<number>(0)
  const [registrationType, setRegistrationType] = useState<any>(0)
  const [isSelectedItem, setIsSelectedItem] = useState<boolean>(false)
  const [COA, setCOA] = useState('')

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  function getRegistrationType() {
    const userData: any = localStorage.getItem('@CANNECT_USER')

    setRegistrationType(JSON.parse(userData)?.registration_type)
  }

  function renderImage() {
    if (!getProduct?.image) {
      return semImagem
    }
    if (registrationType === 1 && getProduct?.supplier_id !== 18) {
      return cannectDefaultProduct
    }
    return getProduct?.image
  }

  const incrementQuantity = () => {
    const getLocalStorageToSave = localStorage.getItem('@CANNECT:PRODUCT')
    const getItemToSave: ProductProps[] = getLocalStorageToSave && JSON.parse(getLocalStorageToSave)

    if (getItemToSave && getProduct) {
      for (let i = 0; i < getItemToSave.length; i += 1) {
        if (getItemToSave[i].product_id === product.product_id) {
          getItemToSave[i].quantity += 1
          break
        }
      }

      const holdProduct = {
        ...getProduct,
        quantity: getProduct.quantity + 1
      }

      setGetProduct(holdProduct)
      handleSavedItems(getItemToSave)
      localStorage.setItem('@CANNECT:PRODUCT', JSON.stringify(getItemToSave))
    }
  }

  const decrementQuantity = () => {
    const getLocalStorageToSave = localStorage.getItem('@CANNECT:PRODUCT')
    const getItemToSave: ProductProps[] = getLocalStorageToSave && JSON.parse(getLocalStorageToSave)

    let removeItem = false

    if (getItemToSave && getProduct) {
      for (let i = 0; i < getItemToSave.length; i += 1) {
        if (getItemToSave[i].product_id === product.product_id) {
          getItemToSave[i].quantity -= 1

          if (getItemToSave[i].quantity <= 0) {
            removeItem = true
          }
        }
      }

      if (removeItem) {
        const filteredItems = getItemToSave.filter(item => item.product_id !== product.product_id)

        localStorage.setItem('@CANNECT:PRODUCT', JSON.stringify(filteredItems))
        handleSavedItems(filteredItems)
        setGetProduct(null)
        return
      }

      handleSavedItems(getItemToSave)
      localStorage.setItem('@CANNECT:PRODUCT', JSON.stringify(getItemToSave))

      const holdProduct = {
        ...getProduct,
        quantity: getProduct.quantity - 1
      }

      setGetProduct(holdProduct)
    }
  }

  useEffect(() => {
    getRegistrationType()
  }, [])

  useEffect(() => {
    if (getProduct && getProduct.quantity >= 1) {
      const totalvalue = +getProduct.quantity * +getProduct.unitPrice
      setProductValue(+totalvalue)
    }
  }, [getProduct])

  const { data } = useFetch({
    url: `product/${product.product_id}`
  })

  useEffect((): any => {
    data?.product?.media?.map((media: { path: string; format: string }) => {
      if (media.format === 'PDF') setCOA(media.path)
      return null
    })
  }, [data])

  return (
    getProduct && (
      <Style.Container isMobile={isMobile} isSelected={isSelectedItem}>
        <div className="product">
          <Style.ImageWrapper>
            <img src={renderImage()} alt="produtos" />
          </Style.ImageWrapper>

          <Style.DescriptionWrapper isMobile={isMobile}>
            <Text type="md" bold>
              {getProduct?.translated_name}
            </Text>
            <Style.RefButton href={COA} target="_blank" rel="noreferrer">
              ler COA
            </Style.RefButton>
          </Style.DescriptionWrapper>
        </div>

        <div className="product-properties">
          <div className="row">
            <Style.AddOrRemoveButton onClick={decrementQuantity}>
              <Text type="lg" bold color="tertiary">
                -
              </Text>
            </Style.AddOrRemoveButton>

            <Style.QuantityWrapper>
              <Text type="lg" bold>
                {getProduct.quantity}
              </Text>
            </Style.QuantityWrapper>

            <Style.AddOrRemoveButton onClick={incrementQuantity}>
              <Text type="lg" bold color="tertiary">
                +
              </Text>
            </Style.AddOrRemoveButton>
          </div>

          {isMobile ? (
            <div className="align-item">
              <Text type="lg" color="tertiary">
                Valor Total
              </Text>
              <Text type="lg" color="tertiary">
                {currencyMask(productValue)}
              </Text>
            </div>
          ) : (
            <>
              <div className="align-item">
                <Text type="lg">{currencyMask(getProduct.unitPrice)}</Text>
              </div>

              <div className="align-item">
                <Text type="lg">{currencyMask(productValue)}</Text>
              </div>
            </>
          )}
        </div>
      </Style.Container>
    )
  )
}

export default Item
