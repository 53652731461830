import styled from 'styled-components'

interface ButtonMethodProps {
  isActive?: boolean
}

export const Container = styled.div<any>`
  background: #f6f6f6;
  border-radius: 4px;
  width: ${props => (props.isMobile ? '100%' : '50%')};
  padding: 26px 35.17px 16px 26px;
  margin-left: ${props => (props.isMobile ? '0' : '16px')};
`

export const Title = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;

  color: #433F3E;
  margin-bottom: 30px;
`
export const Methods = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
`
export const ButtonMethod = styled.button<ButtonMethodProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => (props.isActive ? '#748351' : '#ffffff')};
  border: ${props => (props.isActive ? 'none' : '1px solid #748351')};
  border-radius: 2px;
  width: 50%;
  height: 35px;
  color: ${props => (props.isActive ? '#FFFFFF' : '#748351')};
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;
  text-align: center;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
