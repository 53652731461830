/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import PageTitle from 'components/PageTitle'
import useMediaQuery from 'hooks/useMediaQuery'
import useSignedUser from 'hooks/useSignedUser'
import HelloMessage from 'pages/Account/components/template/AccountSidePanel/components/HelloMessage'
import {
  PATIENT_LINKS_DESCRIPTIONS,
  PATIENT_SIDE_PANEL
} from 'pages/Account/components/template/AccountSidePanel/constants/patient'
import {
  CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL,
  PROFESSIONAL_LINKS_DESCRIPTIONS,
  PROFESSIONAL_SIDE_PANEL
} from 'pages/Account/components/template/AccountSidePanel/constants/professional'
import { useMemo } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import When from 'utils/when'
import Banner from './components/Banner'
import CollapsableMenus from './components/CollapsableMenus'
import PathSelectorButton from './components/PathSelectorButton'
import * as Styles from './styles'
import { BANNER_CRM_CRO, BANNER_PROFESSIONAL, PATIENT_BANNERS } from './bannersDataMock'

function UserPanel() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { signedUser } = useSignedUser()

  const isCRMOrCRO = useMemo(() => {
    if (
      signedUser?.prescriber?.council === 'CRO' ||
      signedUser?.prescriber?.council === 'CRM' ||
      signedUser?.prescriber?.council === 'RMS'
    ) {
      return true
    }
    return false
  }, [signedUser])

  const profissionalSidePanel = isCRMOrCRO ? CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL : PROFESSIONAL_SIDE_PANEL

  const DataBanners = isCRMOrCRO ? BANNER_CRM_CRO : BANNER_PROFESSIONAL

  return (
    <Styles.UserPanelContainer>
      <PageTitle>Painel do usuário</PageTitle>

      <Styles.PathsWrapper>
        {!isMobile ? (
          signedUser?.registration_type === 1 ? (
            PATIENT_SIDE_PANEL.map(item => (
              <PathSelectorButton
                to={item.title.to ?? item.subItems?.[0].to ?? '/conta'}
                icon={item.title.icon}
                title={item.title.label} // @ts-ignore
                description={PATIENT_LINKS_DESCRIPTIONS[item.title.label]}
                enabled={item.title.enabled}
              />
            ))
          ) : (
            profissionalSidePanel.map(item => (
              <PathSelectorButton
                to={item.title.to ?? item.subItems?.[0].to ?? '/conta'}
                icon={item.title.icon}
                title={item.title.label} // @ts-ignore
                description={PROFESSIONAL_LINKS_DESCRIPTIONS[item.title.label]}
                enabled={item.title.enabled}
              />
            ))
          )
        ) : (
          <>
            <HelloMessage />
            <CollapsableMenus />
          </>
        )}
      </Styles.PathsWrapper>

      <When expr={!isMobile}>
        <When expr={!(signedUser?.registration_type === 1)}>
          <PageTitle>Novidades Cannect</PageTitle>
          <Banner data={DataBanners} />
        </When>
      </When>

      {/* <When expr={signedUser?.registration_type === 1}>
        <PageTitle>Novidades Cannect</PageTitle>
        <Banner data={PATIENT_BANNERS} />
      </When> */}
    </Styles.UserPanelContainer>
  )
}

export default UserPanel
