import React from 'react'

export default function WhatsAppIcon({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M9.27408 2.65012e-08C7.66355 -0.000121194 6.08075 0.415617 4.68152 1.20628C3.28229 1.99695 2.11488 3.13528 1.29422 4.5092C0.473565 5.88312 0.0279573 7.44525 0.00127322 9.0418C-0.0254108 10.6384 0.367749 12.2143 1.14204 13.6144L0.384123 16.2665C0.313418 16.5082 0.309444 16.7643 0.372614 17.008C0.435785 17.2517 0.563784 17.4741 0.743249 17.6521C0.922714 17.83 1.14706 17.9569 1.39288 18.0195C1.63869 18.0822 1.89696 18.0782 2.14071 18.0081L4.81573 17.2567C6.0554 17.9303 7.43401 18.3141 8.84618 18.3785C10.2584 18.443 11.6667 18.1865 12.9636 17.6287C14.2605 17.0709 15.4115 16.2265 16.3288 15.16C17.2461 14.0936 17.9054 12.8333 18.2561 11.4755C18.6069 10.1178 18.64 8.69849 18.3528 7.32614C18.0655 5.95378 17.4657 4.6647 16.599 3.55741C15.7323 2.45013 14.6217 1.55395 13.3522 0.937391C12.0827 0.320827 10.6877 0.000194145 9.27408 2.65012e-08ZM11.423 14.8521H11.4141C9.3322 14.8497 7.33638 14.0281 5.86509 12.5677C4.39381 11.1074 3.56739 9.12768 3.56739 7.06357C3.56975 6.22103 3.90899 5.4138 4.51073 4.81886C5.11246 4.22392 5.9276 3.88982 6.7774 3.88983C7.011 3.89033 7.24033 3.95189 7.44228 4.0683C7.64422 4.18471 7.81164 4.35185 7.92766 4.55287L8.97091 6.36517C9.0969 6.58222 9.16172 6.82885 9.15858 7.07925C9.15545 7.32966 9.08447 7.57462 8.95308 7.78849L8.31999 8.84052C8.63832 9.37718 9.08946 9.82447 9.63075 10.1401L10.6918 9.51239C10.9076 9.38213 11.1546 9.31176 11.4072 9.30865C11.6598 9.30554 11.9085 9.3698 12.1274 9.49471L13.9554 10.5291C14.1581 10.6441 14.3267 10.8101 14.4441 11.0103C14.5615 11.2105 14.6236 11.4379 14.6241 11.6695C14.6241 12.512 14.2871 13.3202 13.6871 13.9168C13.087 14.5134 12.2728 14.8497 11.423 14.8521Z"
        fill="#115850"
      />
      <path
        d="M10.0306 11.6184C9.93141 11.6759 9.81978 11.7091 9.70502 11.7153C9.59026 11.7214 9.47567 11.7004 9.37079 11.6538C8.24953 11.1611 7.35323 10.2724 6.85627 9.16074C6.80929 9.05675 6.78802 8.94315 6.79422 8.82936C6.80042 8.71558 6.83393 8.60491 6.89194 8.50655L7.73011 7.12743L6.72252 5.36816C6.25969 5.3797 5.81973 5.57013 5.49654 5.89881C5.17335 6.22749 4.99254 6.6684 4.99268 7.12743C4.99151 7.96406 5.1567 8.79271 5.47882 9.56599C5.80093 10.3393 6.27364 11.042 6.86991 11.634C7.46618 12.226 8.17432 12.6956 8.95381 13.0161C9.73331 13.3365 10.5689 13.5014 11.4127 13.5014H11.4216C11.8846 13.5016 12.3293 13.3223 12.6608 13.0019C12.9923 12.6815 13.1844 12.2453 13.1961 11.7864L11.4216 10.7874L10.0306 11.6184Z"
        fill="#115850"
      />
    </svg>
  )
}
