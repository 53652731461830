import Button from 'new-components/Button'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import * as Styles from './styles'

export interface TreatmentSymptomProps {
  text: string
  onClickPrimary?: () => void
  onClickSecondary?: () => void
}

export default function TreatmentSymptom({ text, onClickPrimary, onClickSecondary }: TreatmentSymptomProps) {
  return (
    <Styles.Wrapper>
      <Styles.Title>Tratamento </Styles.Title>
      <TextDanger text={text} />

      <Styles.ActionsContent>
        <Button background="black" onClick={onClickPrimary}>
          Agende sua consulta
        </Button>
        <Button background="green" isOutlined onClick={onClickSecondary}>
          Inicie seu tratamento
        </Button>
      </Styles.ActionsContent>
    </Styles.Wrapper>
  )
}
