export function Zip() {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      data-testid="file_icon_zip"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9749 0.208313H4.12533C3.07551 0.208313 2.06869 0.62535 1.32636 1.36768C0.584029 2.11001 0.166992 3.11683 0.166992 4.16665V35.8333C0.166992 36.8831 0.584029 37.8899 1.32636 38.6323C2.06869 39.3746 3.07551 39.7916 4.12533 39.7916H27.8753C28.9251 39.7916 29.932 39.3746 30.6743 38.6323C31.4166 37.8899 31.8337 36.8831 31.8337 35.8333V13.0671C31.8337 12.4372 31.5834 11.8331 31.138 11.3877L20.6543 0.903934C20.2089 0.458535 19.6048 0.208313 18.9749 0.208313ZM17.9795 14.0625V4.16669L27.8753 14.0625H17.9795Z"
        fill="#9F9EAD"
      />
      <path
        d="M6.68814 31.8669C6.68814 31.647 6.76373 31.4632 6.91492 31.3154C7.06954 31.1677 7.27399 31.0938 7.52825 31.0938C7.78252 31.0938 7.98525 31.1677 8.13644 31.3154C8.29106 31.4632 8.36837 31.647 8.36837 31.8669C8.36837 32.0868 8.29106 32.2706 8.13644 32.4184C7.98525 32.5661 7.78252 32.64 7.52825 32.64C7.27399 32.64 7.06954 32.5661 6.91492 32.4184C6.76373 32.2706 6.68814 32.0868 6.68814 31.8669ZM15.5142 31.3773V32.5833H10.1952V31.3773H15.5142ZM15.4369 25.9397L10.8807 32.5833H9.79316V31.6968L14.3854 25.079H15.4369V25.9397ZM14.906 25.079V26.2902H9.79832V25.079H14.906ZM18.4594 25.079V32.5833H16.9184V25.079H18.4594ZM23.1468 29.9083H21.2346V28.7023H23.1468C23.4423 28.7023 23.6828 28.6542 23.8683 28.558C24.0539 28.4583 24.1896 28.3209 24.2755 28.1456C24.3614 27.9704 24.4044 27.7728 24.4044 27.5529C24.4044 27.3296 24.3614 27.1217 24.2755 26.9293C24.1896 26.7369 24.0539 26.5822 23.8683 26.4654C23.6828 26.3486 23.4423 26.2902 23.1468 26.2902H21.7706V32.5833H20.2244V25.079H23.1468C23.7343 25.079 24.2377 25.1855 24.6569 25.3985C25.0795 25.6081 25.4025 25.8985 25.6259 26.2696C25.8492 26.6407 25.9609 27.065 25.9609 27.5426C25.9609 28.0271 25.8492 28.4463 25.6259 28.8002C25.4025 29.1541 25.0795 29.4273 24.6569 29.6197C24.2377 29.8121 23.7343 29.9083 23.1468 29.9083Z"
        fill="white"
      />
    </svg>
  )
}
