import { SVGProps } from 'react'

interface MagnifyingGlassIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function MagnifyingGlassIcon({ isChecked, ...rest }: MagnifyingGlassIconProps) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M13.8192 22.9275C18.9496 22.9275 23.1087 18.7685 23.1087 13.6381C23.1087 8.50766 18.9496 4.34863 13.8192 4.34863C8.68881 4.34863 4.52979 8.50766 4.52979 13.6381C4.52979 18.7685 8.68881 22.9275 13.8192 22.9275Z"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3877 20.2075L25.7623 25.5821"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
