import { useFindLastJourney, useSendPrescriptionDocumentMutation } from '@cannect/services/resources/digitalJourney'
import { useEffect, useState } from 'react'
import { DIGITAL_JOURNEY_ROUTES } from '../../constants'
import { useHistory } from 'react-router'
import { TFileItem } from '@cannect/new-components/molecules/FileItem/types'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'

export const useSendPrescriptionStep = () => {
  const history = useHistory()
  const { formData, updateFormData, resetFormData, continueJourney } = useDigitalJourney()

  const [isOpenExistingJourneyWarning, setIsOpenExistingJourneyWarning] = useState(false)
  const [hasOcrError, setHasOcrError] = useState(false)

  const {
    data: userLastJourney,
    isLoading: isLoadingUserLastJourney,
    isSuccess
  } = useFindLastJourney(formData?.common_fields?.user_id)

  const handleContinueJourney = () => {
    if (!userLastJourney?.form_data) return
    continueJourney(userLastJourney)
  }

  const { mutate, isPending } = useSendPrescriptionDocumentMutation()

  const handleSubmitPrescription = async (fileItem: TFileItem) => {
    if (!fileItem.file) return

    await mutate(
      {
        user_id: formData?.common_fields?.user_id,
        prescription_file: fileItem?.file
      },
      {
        onSuccess: (response) => {
          const fileData = response?.form_data?.send_prescription_document?.prescription_file[0]
          updateFormData({
            send_prescription_document: {
              prescription_file: [
                {
                  file: fileItem.file!,
                  id: fileData?.id,
                  name: fileData?.name,
                  size: fileData?.size || 0,
                  type: fileData?.type || '',
                  file_url: fileData?.file_url || ''
                }
              ]
            },
            common_fields: {
              ...formData?.common_fields,
              form_steps_order: response?.form_steps_order,
              digital_journey_id: response?.digital_journey_id,
              order_id: response?.order_id,
              user_id: response?.user_id
            },
            cart: {
              generatedByOcr: (response?.ocr?.send_prescription_document?.products?.length ?? 0) > 0,
              products: response?.form_data?.cart?.products || [],
              summary: response?.form_data?.cart?.summary ?? formData?.cart.summary
            }
          })
          if (response?.ocr?.send_prescription_document?.has_error) {
            return setHasOcrError(true)
          }
          if (response?.next_step) {
            return history.push(response.next_step)
          }
          return null
        }
      }
    )
  }

  const handleErrorRedirect = () => {
    if (formData?.common_fields?.user_id) {
      history.push(DIGITAL_JOURNEY_ROUTES.cart)
      return
    }
    history.push(DIGITAL_JOURNEY_ROUTES.auth_informations)
  }

  useEffect(() => {
    if (isSuccess && userLastJourney?.form_data) {
      setIsOpenExistingJourneyWarning(true)
      return
    }
    setIsOpenExistingJourneyWarning(false)
  }, [isSuccess, userLastJourney])

  useEffect(() => {
    resetFormData()
    setHasOcrError(false)
  }, [])

  const handleTryAgain = () => {
    setHasOcrError(false)
    resetFormData()
  }

  return {
    hasOcrError,
    handleSubmitPrescription,
    handleErrorRedirect,
    isPending,
    isOpenExistingJourneyWarning,
    setIsOpenExistingJourneyWarning,
    handleContinueJourney,
    isLoadingUserLastJourney,
    handleTryAgain
  }
}
