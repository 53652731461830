/* eslint-disable no-nested-ternary */
import { produto_sem_imagem } from 'assets/img'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { Product } from 'hooks/useCannectAssistant/types'
import PriceSpan from 'pages/CannectAssistant/components/PriceSpan'
import { MinusCircle, Receipt } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import FilterButton from './FilterButton'
import * as Style from './styles'

type FilterBy = 'bottlesPerYear' | 'estimatedMonthlyCost' | 'costPerMg' | 'unitaryPrice'

interface ProductsTableProps {
  products: Product[]
  addToPrescriptionMode: 'changePrescriptionList' | 'changeInclusion'
  page: number
}

export default function ProductsTable({ products, addToPrescriptionMode, page}: ProductsTableProps) {
  const { prescription, addProductToPrescription, removeProductFromPrescription, changeProductIncludedInPrescription } =
    useCannectAssistant()
  const [filterBy, setFilterBy] = useState<FilterBy>('estimatedMonthlyCost')
  const [orderByCrescent, setOrderByCrescent] = useState(true)
  const [orderedProducts, setOrderedProducts] = useState<Product[]>([])
  const productsPerPage = 5 

  function filterProducts(products: Product[], filterBy: FilterBy, orderByCrescent: boolean): Product[] {
    const sanitatedProducts = products.filter(p => p !== undefined)
    const newProducts: Product[] = [...sanitatedProducts]

    switch (filterBy) {
      case 'bottlesPerYear':
        if (orderByCrescent) {
          newProducts.sort((a: any, b: any) => a.bottlesPerYear - b.bottlesPerYear)
        } else {
          newProducts.sort((a: any, b: any) => b.bottlesPerYear - a.bottlesPerYear)
        }
        break
      case 'estimatedMonthlyCost':
        if (orderByCrescent) {
          newProducts.sort((a: any, b: any) => a.estimatedMonthlyCost - b.estimatedMonthlyCost)
        } else {
          newProducts.sort((a: any, b: any) => b.estimatedMonthlyCost - a.estimatedMonthlyCost)
        }
        break
      case 'costPerMg':
        if (orderByCrescent) {
          newProducts.sort((a: any, b: any) => a.costPerMg - b.costPerMg)
        } else {
          newProducts.sort((a: any, b: any) => b.costPerMg - a.costPerMg)
        }
        break
      case 'unitaryPrice':
        if (orderByCrescent) {
          newProducts.sort((a: any, b: any) => a.unitaryPriceBrl - b.unitaryPriceBrl)
        } else {
          newProducts.sort((a: any, b: any) => b.unitaryPriceBrl - a.unitaryPriceBrl)
        }
        break
      default:
        break
    }

    return newProducts
  }

  function handleAddToPrescriptionToggle(id: number) {
    const product = orderedProducts.find(product => product.id === id)
    if (!product) return

    const productAlreadyAdded = prescription.products.find(product => product.id === id)

    if (productAlreadyAdded) {
      removeProductFromPrescription(id)
    } else {
      addProductToPrescription(product)
    }
  }

  function handleFilter(newFilterBy: FilterBy) {
    if (newFilterBy === filterBy) {
      setOrderByCrescent(state => !state)
      return
    }
    setFilterBy(newFilterBy)
  }

  useEffect(() => {
    setOrderedProducts(filterProducts(products, filterBy, orderByCrescent))
  }, [filterBy, orderByCrescent])

  useEffect(() => {
    setOrderedProducts(filterProducts(products, filterBy, orderByCrescent))
  }, [products])

  const indexOfLastProducts = page * productsPerPage
  const indexOfFirstPage = indexOfLastProducts - productsPerPage
  const currentProducts = orderedProducts.slice(indexOfFirstPage, indexOfLastProducts)

  return (
    <Style.ProductsTableContainer>
      <Style.Table>
        <thead>
          <tr>
            <th>Produto</th>
            <th style={{ minWidth: '80px', maxWidth: '80px' }}>Posologia</th>
            <th>
              <FilterButton
                className="filter-header-cell"
                active={filterBy === 'bottlesPerYear'}
                onClick={() => handleFilter('bottlesPerYear')}
              >
                Frascos por ano
                {orderByCrescent ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
              </FilterButton>
            </th>
            <th>
              <FilterButton
                className="filter-header-cell"
                active={filterBy === 'estimatedMonthlyCost'}
                onClick={() => handleFilter('estimatedMonthlyCost')}
              >
                Custo por mês
                {orderByCrescent ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
              </FilterButton>
            </th>
            <th>
              <FilterButton
                className="filter-header-cell"
                active={filterBy === 'costPerMg'}
                onClick={() => handleFilter('costPerMg')}
              >
                Custo por mg
                {orderByCrescent ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
              </FilterButton>
            </th>
            <th>
              <FilterButton
                className="filter-header-cell"
                active={filterBy === 'unitaryPrice'}
                onClick={() => handleFilter('unitaryPrice')}
              >
                Preço unitário
                {orderByCrescent ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
              </FilterButton>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentProducts.map((product: any) => (
            <tr key={product.id}>
              <Style.Td className="first-tbody-cell">
                <div className="horizontal-content">
                  <img src={product.image || produto_sem_imagem} alt="Produto" />
                  <p className="product_name">
                    <Link
                      to={`/detalhes-produtos/${product.id}`}
                      style={{ fontSize: '1rem', fontWeight: '500' }}
                      target="_blank"
                    >
                      {product.name}
                    </Link>
                  </p>
                  {product.stock <= 0 && <Style.DeliveryWarning>possível maior tempo de entrega</Style.DeliveryWarning>}
                </div>
              </Style.Td>
              <Style.Td>{product.administrationWay !== 'topic' ? product?.posology : 'Tópico'}</Style.Td>
              <Style.Td>{product.administrationWay !== 'topic' ? product.bottlesPerYear : 'Tópico'}</Style.Td>
              <Style.Td>
                {product.administrationWay !== 'topic'
                  ? typeof product.estimatedMonthlyCost === 'string'
                    ? product.estimatedMonthlyCost
                    : new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(product.estimatedMonthlyCost)
                  : 'Tópico'}
              </Style.Td>
              <Style.Td>
                {product.administrationWay !== 'topic'
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(product.costPerMg as number)
                  : 'Tópico'}
              </Style.Td>
              <Style.Td>
                <div className="prices-wrapper">
                  <PriceSpan type="BRL" value={product.unitaryPriceBrl} />
                </div>
              </Style.Td>
              <Style.Td className="last-tbody-cell">
                {addToPrescriptionMode === 'changePrescriptionList' ? (
                  <Style.ButtonPrescription
                    onClick={() => handleAddToPrescriptionToggle(product.id)}
                    prescribedProduct={prescription.products.some(p => p.id === product.id)}
                  >
                    {prescription.products.some(p => p.id === product.id) ? 'Selecionado' : 'Prescrever'}
                    {prescription.products.some(p => p.id === product.id) ? (
                      <MinusCircle size={18} />
                    ) : (
                      <Receipt size={18} />
                    )}
                  </Style.ButtonPrescription>
                ) : (
                  <Style.ButtonPrescription
                    onClick={() => changeProductIncludedInPrescription(product.id)}
                    prescribedProduct={product.includedInPrescription}
                  >
                    {product.includedInPrescription ? 'Selecionado' : 'Prescrever'}
                    {product.includedInPrescription ? <MinusCircle size={18} /> : <Receipt size={18} />}
                  </Style.ButtonPrescription>
                )}
              </Style.Td>
            </tr>
          ))}
        </tbody>
      </Style.Table>
    </Style.ProductsTableContainer>
  )
}
