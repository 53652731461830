import useStorageState from 'hooks/useStorageState'
import { ReactNode, useMemo } from 'react'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { useHistory } from 'react-router'
import * as Styles from './styles'

interface CollapsableMenuProps {
  title: string
  icon: ReactNode
  to?: string
  enabled: boolean
  subItems?: {
    title: string
    to: string
    enabled: boolean
  }[]
}

function CollapsableMenu({ title, icon, to, subItems = [], enabled }: CollapsableMenuProps) {
  const history = useHistory()
  const { pathname, search } = document.location
  const [isOpen, setIsOpen] = useStorageState(`@CANNECT_USER_PANEL_TAB-${title}`, false)

  const subItemsContainerHeight = useMemo(() => {
    if (to) return 0
    return subItems.length * 39.5
  }, [subItems, to])

  const onCollapseButtonClick = () => {
    if (to) {
      history.push(to)
      return
    }
    setIsOpen(state => !state)
  }

  return (
    <Styles.CollapsableMenuContainer animationHeight={subItemsContainerHeight}>
      <Styles.CollapseButton onClick={onCollapseButtonClick} disabled={!enabled}>
        <Styles.CollapsableButtonTitle>
          {icon}
          <span>{title}</span>
          {!enabled && <Styles.CommingSoon>(em breve)</Styles.CommingSoon>}
        </Styles.CollapsableButtonTitle>
        {to !== undefined ? (
          <FiChevronRight color="#3d3d3d" />
        ) : (
          <FiChevronDown
            style={{
              transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
              transition: 'transform 300ms linear'
            }}
            color="#3d3d3d"
          />
        )}
      </Styles.CollapseButton>

      <Styles.SubItemsContainer className={isOpen ? 'slide-down' : 'slide-up'}>
        {subItems.map(item => (
          <Styles.SubItem key={item.title} to={item.enabled ? item.to : `${pathname}${search}`} enabled={item.enabled}>
            {item.title}
            {!item.enabled && '(em breve)'}
          </Styles.SubItem>
        ))}
      </Styles.SubItemsContainer>
    </Styles.CollapsableMenuContainer>
  )
}

export default CollapsableMenu
