import Footer from '../Footer'
import NavigationHandler from '../NavigationHandler'
import Form from '../PersonalDataForm'
import * as Style from './styles'

export default function PersonalDataForm() {
  return (
    <>
      <NavigationHandler step={2} />

      <Style.Container>
        <Form />
      </Style.Container>

      <Footer />
    </>
  )
}
