import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH } from 'utils/constants'

type SectionStylesProps = {
  background?: string
  invertRow?: boolean
  lastSection?: boolean
  fullWidth?: boolean
}

export type CardImageProps = {
  image: string
}

type BoxStyleProps = {
  justify?: 'start' | 'center' | 'end'
  fullWidth?: boolean
}

const modifierStyles = {
  start: () => css`
    align-items: flex-start;
  `,
  center: () => css`
    align-items: center;
  `,
  end: () => css`
    align-items: flex-end;
  `
}

export const Wrapper = styled.main`
  width: 100%;
  background: linear-gradient(338deg, rgba(223, 255, 251, 1) 42%, rgba(249, 249, 249, 1) 84%);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Section = styled.section<SectionStylesProps>`
  ${({ invertRow, background, lastSection, fullWidth }) => css`
    display: flex;
    gap: ${temporaryTheme.spacing.space6};
    flex-direction: ${!!invertRow && ' row-reverse'};
    background: ${background};
    max-width: ${fullWidth ? '100%' : '1366px'};
    width: 100%;

    .action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 13px;
    }

    .action-link {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        color: #4579ff;
      }
    }

    .card-box {
      width: 100%;
    }

    .content-box {
      display: flex;
      align-items: stretch;
      gap: ${temporaryTheme.spacing.space3};
      justify-content: flex-start;
      margin-bottom: ${temporaryTheme.spacing.space3};
    }

    @media (min-width: 1250px) {
      padding: ${temporaryTheme.spacing.space10} ${temporaryTheme.spacing.space4} ${temporaryTheme.spacing.space10}
        ${temporaryTheme.spacing.space4};
      width: ${fullWidth && '100%'};
    }
    @media (min-width: 900px) and (max-width: 1249px) {
      padding: ${temporaryTheme.spacing.space10} ${temporaryTheme.spacing.space4} ${temporaryTheme.spacing.space10}
        ${temporaryTheme.spacing.space4};
      width: 100%;

      .card-box {
        overflow-x: auto;
      }

      .content-box {
        width: ${MAX_PAGE_WIDTH};
      }
    }
    @media (max-width: 900px) {
      padding: ${temporaryTheme.spacing.space7} ${temporaryTheme.spacing.space5} ${temporaryTheme.spacing.space7}
        ${temporaryTheme.spacing.space5};
      flex-direction: column-reverse;
      width: 100%;
      .content-box {
        width: 950px;
      }

      .card-box {
        overflow-x: auto;
        width: 100%;
      }

      .action-link {
        justify-content: flex-start;
      }
    }
  `}
`

export const Box = styled.div<BoxStyleProps>`
  ${({ justify, fullWidth }) => css`
    width: ${fullWidth ? '100%' : '50%'};
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: left;
    }
    ${!!justify && modifierStyles[justify]()};
  `}
`

export const Image = styled.img`
  width: 600px;

  @media (min-width: 900px) and (max-width: 1249px) {
    width: 100%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const CardImage = styled.div<CardImageProps>`
  ${({ image }) => css`
    width: 544px;
    height: 344px;
    border-radius: 22px;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 900px) and (max-width: 1249px) {
      width: 100%;
    }
    @media (max-width: 900px) {
      width: 100%;
    }
  `}
`

export const SectionCannabiCast = styled(Section)`
  flex-direction: column;
`

export const TextContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-box {
    display: flex;
    flex-direction: column;
  }

  p {
    text-align: right;
  }

  @media (max-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    text-align: center;
    p {
      text-align: center;
    }
  }
`

export const PodcastContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${temporaryTheme.spacing.space1};
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const SocialLinkContent = styled.div`
  @media (max-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
`
export const ListLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${temporaryTheme.spacing.space4};
  svg {
    width: 26px;
    height: 26px;
    cursor: pointer;

    &:hover {
      color: ${temporaryTheme.colors.sitePrimary};
    }
  }
`
