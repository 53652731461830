import { noop } from 'lodash'
import Button from 'new-components/Button'
import When from 'utils/when'
import { Reschedule } from '../../Reschedule'
import { CancelBefore24hours } from '../CancelBefore24Hours'
import { CancelWithin24hours } from '../CancelWithin24Hours'
import { Prescriber } from '../../../types/appointment'
import { useConsultation } from 'pages/MyQueries/hooks/useConsultation'
import { toast } from 'react-toastify'
import { Confetti } from 'phosphor-react'

import * as Styles from './styles'
import { useValidations } from 'pages/MyQueries/hooks/useValidations'

interface TabContentModalQUeriesProps {
  date: string
  hour: string
  value?: 'info' | 'reschedule' | 'rescheduled'
  handleTab?: (value: 'info' | 'reschedule' | 'rescheduled') => void
  prescriberInfos: Prescriber
  appointmentId: number
  closeModal?: () => void
}

export function TabContentModalQueries({
  date,
  hour,
  value = 'info',
  handleTab = noop,
  prescriberInfos,
  appointmentId,
  closeModal
}: TabContentModalQUeriesProps) {
  const { isWithinByHours } = useValidations()
  const { passed, within } = isWithinByHours(date, hour, 24)
  const { cancelConsultation } = useConsultation()

  const tabNavigation = (value: 'info' | 'reschedule' | 'rescheduled') => {
    handleTab(value)
  }

  const handleCancelAction = async () => {
    const resultCancel = await cancelConsultation(appointmentId)
    if (resultCancel.success) {
      toast.success(resultCancel?.appointment?.message)
    } else {
      toast.error(resultCancel?.message)
    }
  }

  return (
    <Styles.Wrapper value={value} defaultValue="info">
      <Styles.Content value="info" id="info">
        <Styles.AnimatedContent>
          <When expr={!!within}>
            <CancelWithin24hours
              date={date}
              hour={hour}
              onReschedule={() => tabNavigation('reschedule')}
              onCancel={handleCancelAction}
              onClose={closeModal}
            />
          </When>
          <When expr={!!passed}>
            <CancelBefore24hours onReschedule={() => tabNavigation('reschedule')} onCancel={handleCancelAction} />
          </When>
        </Styles.AnimatedContent>
      </Styles.Content>

      <Styles.Content value="reschedule" id="reschedule">
        <Styles.AnimatedContent>
          <Reschedule
            prescriber={prescriberInfos}
            currentDate={date}
            currentTime={hour}
            appointMentId={appointmentId}
            tabNavigator={() => tabNavigation('rescheduled')}
          />
        </Styles.AnimatedContent>
      </Styles.Content>

      <Styles.Content value="canceled">
        <Styles.AnimatedContent>
          O que te levou a cancelar a consulta?
          <Button onClick={() => tabNavigation('info')}>Enviar</Button>
        </Styles.AnimatedContent>
      </Styles.Content>

      <Styles.Content value="rescheduled">
        <Styles.AnimatedContent>
          <Styles.SuccessReschedule>
            <Styles.Heading>Reagendamento concluido.</Styles.Heading>
            <Styles.IconBox>
              <Confetti size={62} />
            </Styles.IconBox>

            <p>
              Agora é só buscar pelo seu agendamento atual na listagem e efetuar o pagamento. (em caso de já ter
              efetuado o pagamento, desconsidere a mensagem)
            </p>

            {/*  <Styles.Actions>
              <Button background="black" onClick={() => history.push('/conta?pagina=consultas')}>
                Meus agendamentos
              </Button>
              <Button background="green" onClick={() => history.push('/agendamento')}>
                Novo agendamento
              </Button>
            </Styles.Actions> */}
          </Styles.SuccessReschedule>
        </Styles.AnimatedContent>
      </Styles.Content>
    </Styles.Wrapper>
  )
}
