import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .pagination-prescribers {
    margin: 1rem 0 2rem 0;
  }
`

export const HeaderPrescribers = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  justify-content: ${props => (props.isMobile ? 'center' : 'space-between')};
  align-items: ${props => (props.isMobile ? 'center' : 'initial')};
  width: 100%;
  margin-bottom: 2rem;
`

export const Title = styled.h1`
  color: #3d3d3d;
  font-size: 17px;
  font-weight: 400;
  margin-top: 1rem;
`

export const ButtonBeList = styled.button`
  font-weight: 700;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding: 0px 16px;
  background: #3d3d3d;
  color: #ecece2;
  transition: transform 0.2s;
  width: 201px;
  height: 48px;
  font-size: 14px;
  margin-top: 2rem;
`

export const ContainerButtons = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: ${props => (props.isMobile ? 'center' : 'flex-end')};
`
