import React, { InputHTMLAttributes } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import * as Style from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  icon?: React.ReactNode
  props?: any
  width?: string
}

export function Input({ value, icon, width, type, ...props }: InputProps) {
  // const [showPassword, setShowPassword] = React.useState(false)
  const [inputType, setInputType] = React.useState('text')

  const togglePassword = () => setInputType(inputType === 'password' ? 'text' : 'password')
  // Create an useEffect to handle eye icon when input type is password
  React.useEffect(() => {
    if (type) {
      setInputType(type)
      return
    }
    setInputType('text')
  }, [])

  return (
    <Style.WrapperInput width={width}>
      <Style.ContainerInput value={value} type={inputType} {...props} />
      <span className="wrapper-icon">{icon}</span>
      {type === 'password' && (
        <Style.WrapperEye onClick={togglePassword} role="button">
          {inputType === 'password' ? <FaEye /> : <FaEyeSlash />}
        </Style.WrapperEye>
      )}
    </Style.WrapperInput>
  )
}

export default Input
