import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import api from '@cannect/services/api'
import * as S from './styles'

interface FormConfirmationProps {
  eventDetails: { date: string; time: string; userId: string } | null
  onSuccess: (data: any) => void
}

const FormConfirmation: React.FC<FormConfirmationProps> = ({ eventDetails, onSuccess }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [availableDateTimes, setAvailableDateTimes] = useState<{ date: string; times: string[] }[]>([])

  const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]{2,}$/
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^\(\d{2}\)\d{5}-\d{4}$/

  useEffect(() => {
    if (eventDetails) {
      const fetchAvailability = async () => {
        try {
          const response = await api.get(`/kalendme/users/${eventDetails.userId}`)
          if (response.data && response.data.success) {
            setAvailableDateTimes(response.data.availableDateTimes)
          } else {
            console.error('Failed to fetch user availability')
          }
        } catch (error) {
          console.error('Error fetching user availability', error)
        }
      }
      fetchAvailability()
    }
  }, [eventDetails])

  const validateName = (value: string) => {
    setNameError(nameRegex.test(value) ? '' : 'Nome inválido')
  }

  const validateEmail = (value: string) => {
    setEmailError(emailRegex.test(value) ? '' : 'Email inválido')
  }

  const validatePhone = (value: string) => {
    setPhoneError(phoneRegex.test(value) ? '' : 'Telefone inválido')
  }

  const formatPhoneNumber = (phone: any) => {
    const numbers = phone.match(/\d+/g).join('')
    return `+55${numbers}`
  }

  const validateForm = () => {
    const isNameValid = nameRegex.test(name)
    const isEmailValid = emailRegex.test(email)
    const isPhoneValid = phoneRegex.test(phone)

    setIsFormValid(isNameValid && isEmailValid && isPhoneValid)
  }

  useEffect(() => {
    validateForm()
  }, [name, email, phone])

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'name') {
      validateName(value)
    } else if (name === 'email') {
      validateEmail(value)
    } else if (name === 'phone') {
      validatePhone(value)
    }
    validateForm()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'name') {
      setName(value)
      validateName(value)
    } else if (name === 'email') {
      setEmail(value)
      validateEmail(value)
    } else if (name === 'phone') {
      setPhone(value)
      validatePhone(value)
    }
  }

  const isTimeAvailable = (date: string, time: string): boolean => {
    const availabilityForDate = availableDateTimes.find(availability => availability.date === date)
    return availabilityForDate ? availabilityForDate.times.includes(time) : false
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isFormValid && eventDetails) {
      const [day, month, year] = eventDetails.date.split('/')
      const [hours, minutes] = eventDetails.time.split(':')
      const startTimestamp = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10),
        parseInt(hours, 10),
        parseInt(minutes, 10)
      ).getTime()

      if (!isTimeAvailable(eventDetails.date, eventDetails.time)) {
        toast.error('Horário indisponível.')
        return
      }

      const formattedPhone = formatPhoneNumber(phone);

      const requestData = {
        title: 'Boas vindas + Suporte Farma',
        startTimestamp,
        durationMinutes: 30,
        guests: [email],
        mainGuestName: name,
        mainGuestTimeZone: 'America/Sao_Paulo',
        mainGuestLanguage: 'pt',
        location: {
          type: 'phone',
          value: formattedPhone,
          callDirection: 'receive'
        }
      }

      try {
        const response = await api.post(`/kalendme/calendar-event/${eventDetails.userId}`, requestData)
        if (response.status === 200) {
          toast.success('Reunião agendada com sucesso!')
          onSuccess(response.data.createCalendarEvent.calendarEvent)
        } else {
          toast.error('Falha ao agendar reunião.')
        }
      } catch (error) {
        console.error('Erro ao agendar reunião:', error)
        toast.error('Falha ao agendar reunião.')
      } finally {
        setIsSubmitting(false)
      }
    } else {
      toast.error('Por favor, preencha todos os campos corretamente.')
    }
  }

  return (
    <S.FormContainer onSubmit={handleSubmit} style={{ height: '100%' }}>
      <S.Header>Confirme suas informações</S.Header>
      <S.Label>
        Qual é o seu nome?
        <S.Input
          type="text"
          name="name"
          placeholder="Nome *"
          value={name}
          onChange={handleChange}
          onBlur={handleBlur}
          hasError={!!nameError}
        />
        {nameError && <S.ErrorMessage>{nameError}</S.ErrorMessage>}
      </S.Label>
      <S.Label>
        Qual é o seu email?
        <S.Input
          type="email"
          name="email"
          placeholder="Email *"
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          hasError={!!emailError}
        />
        {emailError && <S.ErrorMessage>{emailError}</S.ErrorMessage>}
      </S.Label>
      <S.Label>
        <InputMask mask="(99)99999-9999" value={phone} onChange={handleChange} onBlur={handleBlur}>
          {() => <S.Input type="tel" name="phone" placeholder="Telefone *" hasError={!!phoneError} />}
        </InputMask>
        {phoneError && <S.ErrorMessage>{phoneError}</S.ErrorMessage>}
      </S.Label>
      <S.ConfirmButton type="submit" disabled={!isFormValid || isSubmitting}>
        {isSubmitting ? 'Carregando...' : 'Confirmar'}
      </S.ConfirmButton>
    </S.FormContainer>
  )
}

export default FormConfirmation
