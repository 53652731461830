import styled from 'styled-components'

export const FilterSuggestionDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 48px;
  left: 0;

  background: white;
  width: 230px;
  height: 100px;

  border-radius: 0.25rem;
  box-shadow: var(--shadow-700);
  padding: 0.5rem;

  overflow-y: auto;
`
