import { SVGProps } from 'react'

interface CardIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function CardIcon({ isChecked, ...rest }: CardIconProps) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M23.7995 7.00244H6.10535C5.12813 7.00244 4.33594 7.79464 4.33594 8.77186V21.1578C4.33594 22.135 5.12813 22.9272 6.10535 22.9272H23.7995C24.7767 22.9272 25.5689 22.135 25.5689 21.1578V8.77186C25.5689 7.79464 24.7767 7.00244 23.7995 7.00244Z"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33594 14.0801H10.6174C10.8211 14.079 11.0189 14.1491 11.1764 14.2783C11.3339 14.4076 11.4413 14.5878 11.48 14.7879C11.6489 15.5836 12.086 16.2971 12.7181 16.8091C13.3502 17.3211 14.139 17.6004 14.9524 17.6004C15.7659 17.6004 16.5547 17.3211 17.1868 16.8091C17.8189 16.2971 18.256 15.5836 18.4249 14.7879C18.4636 14.5878 18.571 14.4076 18.7285 14.2783C18.886 14.1491 19.0838 14.079 19.2875 14.0801H25.5689"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33594 10.541H25.5689"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
