import { useCannectAssistant } from "@cannect/hooks/useCannectAssistant";
import React, { useEffect, useState } from "react";

const PhoneInput = ({ phoneFromPrescrition }: { phoneFromPrescrition: string | undefined }) => {
  const { changePrescriptionPatient } = useCannectAssistant()
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (phoneFromPrescrition) {
      const formattedPhone = phoneFromPrescrition.startsWith('+55')
        ? formatPhone(phoneFromPrescrition.slice(4))
        : formatPhone(phoneFromPrescrition);
      setPhone(`+55 ${formattedPhone}`);
    } else {
      setPhone('+55 ');
    }
  }, [phoneFromPrescrition]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputPhone = event.target.value;

    if (inputPhone.startsWith('+55')) {
      inputPhone = inputPhone.slice(4);
    }

    const formattedPhone = formatPhone(inputPhone);
    setPhone(`+55 ${formattedPhone}`)
    changePrescriptionPatient('phone', `${inputPhone}`);
  };

  const formatPhone = (phone: string): string => {
    phone = phone.replace(/\D/g, "");

    if (phone.length > 2) {
      phone = phone.replace(/^(\d{2})(\d)/, "($1) $2");
    }

    if (phone.length > 6) {
      phone = phone.replace(/(\d{5})(\d{4})$/, "$1-$2");
    }

    return phone;
  };

  return (
    <input
      placeholder="Digite o celular do paciente"
      required
      value={phone}
      onChange={handlePhoneChange}
      maxLength={18}
    />
  );
};

export default PhoneInput;
