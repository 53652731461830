import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'
import { useTokenApi } from 'hooks/useTokenApi'
import DataSheet from 'new-components/MarketPlace/ProductDetailsComponent/DataSheet'
import Details from 'new-components/MarketPlace/ProductDetailsComponent/Details'
import RelatedProduct from 'new-components/MarketPlace/ProductDetailsComponent/RelatedProduct'
import { useEffect, useState } from 'react'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from 'services/api'
import When from 'utils/when'
import { DefaultMedicalData } from './defaultValues'
import * as Styles from './styles'
import { MedicalDataType } from './typeProduct'

function ProductDetails() {
  const { tokenApi } = useTokenApi()
  const [product, setProduct] = useState<any>({})
  const [medicalData, setMedicalData] = useState<MedicalDataType>(DefaultMedicalData)
  const [loading, setLoading] = useState(false)

  const { id } = useParams() as any
  const location = useLocation()

  const getProduct = async (idSabor?: string) => {
    setLoading(true)
    const urlProduct = idSabor ? `product/${id}/${idSabor}` : `product/${id}`
    try {
      const {
        data: { success, medicalData, product }
      } = await api.get(urlProduct)
      console.log('medicalData-->', medicalData, 'product:', product)
      if (success) {
        setProduct(product)
        setMedicalData(medicalData)
      }
    } catch (e) {
      toast.error('Erro ao buscar dados do produto')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getProduct()
  }, [id])

  const StatePath = {
    from: {
      pathName: location.pathname
    }
  }

  if (loading)
    return (
      <Styles.LoadingContent>
        <Loading loading={loading} />
      </Styles.LoadingContent>
    )

  return (
    <Styles.Wrapper>
      <Styles.BreadCrumSection>
        <BreadCrumb
          paths={[
            { label: 'Produtos', link: '/produtos/1' },
            { label: `SKU: ${product?.id}`, link: '#' }
          ]}
        />
        <When expr={!tokenApi}>
          <Styles.NotificationLogin>
            <HiOutlineInformationCircle /> Para saber mais detalhes sobre o medicamento, como valores em real (R$) faça
            <Link
              to={{
                pathname: '/login',
                state: StatePath
              }}
            >
              Login no site
            </Link>
          </Styles.NotificationLogin>
        </When>
      </Styles.BreadCrumSection>

      <Details product={product} medicalData={medicalData} getProduct={getProduct} />

      <DataSheet medicalData={medicalData} productData={product} />

      <RelatedProduct filters={`class_id=${product?.class?.id}&shape_id=${product?.shape?.id}`} />
    </Styles.Wrapper>
  )
}

export default ProductDetails
