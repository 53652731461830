import styled, { css } from 'styled-components'
import { aboutUs, cannectScreening } from 'assets/img'
import { media } from 'helpers/mediaQueryStyle'
import { temporaryTheme } from 'styles/theme'

interface HeadingProps {
  color?: 'white' | 'black'
}

interface BoxProps {
  align?: 'center' | 'left' | 'right'
}

export const Wrapper = styled.main`
  width: 100%;
`
export const ContentBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1440px;

  ${media.desktop`
    gap: 22px;
    padding: 0 58px;
  `}
`
export const Banner = styled.div`
  width: 100%;
  height: 416px;
  background: url(${cannectScreening}) no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
`

export const ContentSection = styled.div`
  width: 100%;
  display: flex;
  padding: 25px 0;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;

  ${media.desktop`
    gap: 22px;
    padding: 35px 50px;
  `}

  ${media.mobile`
      gap: 25px;
      flex-direction: column;
  `}
`

export const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Title = styled.h1`
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  font-size: 80px;
  color: white;
  line-height: 55px;
  letter-spacing: 2px;
  margin-left: 42px;

  span {
    font-family: 'Nunito', sans-serif;
    font-size: 40px;
  }

  ${media.mobile`
    font-size: 55px;
    line-height: unset;
    span {
      font-size: 25px;
    }
  `}
`

export const ButtonAction = styled.button`
  all: unset;
  position: absolute;
  right: 40px;
  bottom: 20px;
  background: ${temporaryTheme.colors.sitePrimary};
  color: white;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  ${media.mobile`
      font-size: 25px;
      
    `}
`

export const Heading = styled.h2<HeadingProps>`
  ${({ color }) => css`
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: ${color === 'black' ? '#3d3d3d' : color};
    margin-bottom: 20px;

    span {
      color: ${temporaryTheme.colors.sitePrimary};
    }
  `}
`

export const TextBox = styled.div`
  max-width: 540px;
`

export const SectionImage = styled.section`
  background: url(${aboutUs}) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 522px;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Box = styled.div<BoxProps>`
  ${({ align = 'center' }) => css`
    display: flex;
    align-items: center;
    justify-content: ${align};
    width: 50%;
    flex-direction: column;
    text-align: left;

    ${media.mobile`
      width: 100%;
    `}
  `}
`

export const ImageBox = styled.div`
  ${media.desktop`
    width: 528px;
    height: 528px;
  `}

  ${media.mobile`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`
export const Image = styled.img`
  width: 100%;
  object-fit: cover;

  ${media.mobile`
   width: 85%;
  `}
`

export const ImageHistory = styled.img`
  max-width: 700px;
  min-width: 680px;
  margin-left: 52px;
  width: 100%;

  ${media.mobileWithTablet`
    width: 100%;
    height: unset;
    min-width: unset;
    
  `}
`
