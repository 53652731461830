export const CONSULTATION_FILTER = [
  {
    id: 1523,
    title: 'Todas',
    value: ''
  },
  {
    id: 1123,
    title: 'Esse mês',
    value: 'this_month'
  },
  {
    id: 24124,
    title: 'Mês passado',
    value: 'last_month'
  },
  {
    id: 34125,
    title: 'Esse ano',
    value: 'this_year'
  },
  {
    id: 41642,
    title: '2023',
    value: '2023'
  },
  {
    id: 416622,
    title: '2022',
    value: '2022'
  }
]
