import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const FetchSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  color: #3d3d3d;
  position: relative;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 1.5rem;

    * {
      color: #3d3d3d;
    }
  }
`

interface FakeFetchSelectOptionsContainerProps {
  top: number
  left: number
  maxWidth: number
}
export const FakeFetchSelectOptionsContainer = styled.div<FakeFetchSelectOptionsContainerProps>`
  display: flex;
  flex-direction: column;

  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.2);

  position: fixed;
  top: ${({ top }) => `${top + 51.8}px`};
  left: ${({ left }) => `${left}px`};

  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  max-height: 250px;

  overflow: auto;
  background: #ffffff;
  z-index: 6;

  @media ${MOBILE_WIDTH} {
    top: ${({ top }) => `${top + 44.4}px`};
  }

  * {
    color: #3d3d3d;
  }

  .selected-option {
    background: #dfdfdf;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: rgba(225, 225, 227, 0.6);
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #21976e;
    border-radius: 0.5rem;
  }
`

// export const SearchInputContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   padding: 1rem 0;
//   border-bottom: 1px solid #d9d9d9;
//   margin-bottom: 1rem;
// `

// export const SearchInput = styled.input`
//   width: 95%;
//   padding: 1rem;
//   border: 1px solid #d9d9d9;

//   border-radius: 0.5rem;

//   &::placeholder {
//     color: #d9d9d9;
//     opacity: 1;
//     font-weight: 300;
//   }
// `

interface FakeFetchSelectOptionProps {
  isFetchSelected: boolean
}
export const FakeFetchSelectOption = styled.button<FakeFetchSelectOptionProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isFetchSelected }) => (isFetchSelected ? 'space-between' : 'flex-start')};

  padding: 0.75rem 1.25rem;
  background: #ffffff;
  transition: background 0.3s;

  border: none;

  &:hover {
    background: #d9d9d9;
  }

  span {
    text-align: left;
  }

  svg {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
  }
`

export const FetchSelectBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100%;
  min-height: 100vh;

  z-index: 4;
`

export const InputErrorMessage = styled.p`
  font-size: 1rem;
  color: #e24444;
  margin-top: -0.5rem;
  font-weight: 300;
`
