import styled from 'styled-components'

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;

  background: #5b945b;
  height: 90px;
  width: 100%;

  z-index: 500;

  img {
    width: 235px;
    height: 40px;
    min-width: 235px;
    min-height: 40px;
  }
`
