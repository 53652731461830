/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import * as S from './styles'

type TimeListProps = {
  selectedDate: Date | null
  availableTimes: string[]
  selectedTime: string | null
  handleTimeClick: (time: string) => void
  confirmBooking: (time: string) => void
  loading: boolean
  formatDateTitle: (date: Date) => string
  formRef: React.RefObject<HTMLDivElement>
}

const TimeList: React.FC<TimeListProps> = ({
  selectedDate,
  availableTimes,
  selectedTime,
  handleTimeClick,
  confirmBooking,
  loading,
  formatDateTitle,
  formRef
}) => {
  const sortedTimes = availableTimes.sort((a, b) => {
    const [aHours, aMinutes] = a.split(':').map(Number)
    const [bHours, bMinutes] = b.split(':').map(Number)
    return aHours - bHours || aMinutes - bMinutes
  })

  return (
    <div className="time-list" ref={formRef}>
      <h4>{selectedDate && formatDateTitle(selectedDate)}</h4>
      <div className="legend">
        <span className="dot" />
        horários que temos disponibilidade
      </div>
      <ul>
        {sortedTimes.map((time, index) => (
          <li key={index} onClick={() => handleTimeClick(time)} className={selectedTime === time ? 'selected' : ''}>
            {selectedTime === time ? (
              <div className="button-group">
                <S.TimeButton>{time}</S.TimeButton>
                <S.NextButton onClick={() => confirmBooking(time)} disabled={loading}>
                  {loading ? <ClipLoader size={20} color="#ffffff" /> : 'Confirmar'}
                </S.NextButton>
              </div>
            ) : (
              <>
                <span className="dot" />
                {time}
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TimeList
