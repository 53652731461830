import { theme } from 'design-cannect'
import styled from 'styled-components'

export const OtherDiseaseOccurrenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  border: 2px solid #ecece2;
  border-radius: 0.5rem;
  padding: 1.5rem;

  width: 370px;
  min-width: 370px;
  height: 345px;
  min-height: 345px;

  h4 {
    font-size: 1.35rem;
    color: ${theme.colors.tertiary};
    text-align: center;
  }

  p {
    text-align: center;
  }
`
