import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 200px;
  padding-top: 20px;
`
export const Button = styled.button`
  display: flex;

  background-color: #115850;

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #0b3b35;

  margin-top: 20px;
  padding: 1rem;

  cursor: pointer;

  transition: transform 0.2s;

  a:link {
    text-decoration: none;
  }

  h3 {
    color: #ecece2;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
