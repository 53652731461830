import { noop } from 'lodash'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import Text from 'components/Text'
import Modal from 'new-components/Modal'
import * as Styles from './styles'
import { useEffect, useState } from 'react'

interface StatusModalProps {
  isOpen: boolean
  onClose: any
  notCloseModal?: boolean
  title?: string
  text?: string
  icon: any
  textButton: string
  variant?: 'success' | 'error'
  onClickButton: any
}

function StatusModal({
  isOpen,
  onClose = noop,
  notCloseModal = false,
  text,
  title,
  icon,
  textButton,
  variant = 'success',
  onClickButton = noop
}: StatusModalProps) {
  const color = variant === 'success' ? 'green' : 'red'
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')

    const handleResize = () => setIsMobile(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleResize)

    setIsMobile(mediaQuery.matches)

    return () => mediaQuery.removeEventListener('change', handleResize)
  }, [])

  return (
    <Modal isOpen={isOpen} setOpenModal={notCloseModal ? noop : onClose} title="Status do Documento">
      <Styles.ModalContent color={variant}>
        <div className="icon-box  ">{icon}</div>
        {isMobile ? (
        <div
        style={{
          color:'dark',
          fontSize:'14px',
          fontWeight:'bold',
          marginTop:'10px',
          marginBottom:'10px'
        }}>
          {title}
        </div>) : 
        <Heading elementType="h2" defaultColor="dark" fontSize="18px">
          {title}
        </Heading>}
        <Text>{text}</Text>
        <div className="action-box">
          <Button  background={color} onClick={onClickButton}>
            {textButton}
          </Button>
        </div>
      </Styles.ModalContent>
    </Modal>
  )
}

export default StatusModal