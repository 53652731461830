import { Button } from 'design-cannect'
import Input from 'components/Input'
import Texts from 'components/Text'
import { useState } from 'react'
import { resetPassword } from 'services/user'
import { useHistory } from 'react-router'
import * as Styles from './styles'

function NewPasswordComponents() {
  const [formValue, setFormValue] = useState<any>({
    email: '',
    password: '',
    token: ''
  })

  const history = useHistory()

  const [error, setError] = useState({
    status: false,
    message: ''
  })

  const [loading, setLoading] = useState(false)

  const handleValue = (target: any) => {
    const { id, value } = target
    setFormValue({ ...formValue, [id]: value })
  }

  async function handleSubmit(e: any) {
    e.preventDefault()
    setLoading(true)
    const data = formValue
    resetPassword({
      email: data.email,
      password: data.password,
      token: data.token
    })
      .then(res => {
        setLoading(false)
        history.push('/login')
      })
      .catch(err => {
        setError({ status: true, message: err.response.data.error })
        setLoading(false)
      })
  }

  const { email, password, token } = formValue
  return (
    <Styles.Container>
      <Texts type="h1" color="tertiary">
        Redefinir Senha
      </Texts>
      <Styles.StatusMessage status={error.status}>{error.message}</Styles.StatusMessage>
      <Styles.ContainerForm onSubmit={(e: any) => handleSubmit(e)}>
        <input
          required
          placeholder="Insira o seu token"
          id="token"
          value={token}
          onChange={(e: any) => handleValue(e.target)}
        />

        <input
          required
          placeholder="Insira seu email"
          id="email"
          value={email}
          onChange={(e: any) => handleValue(e.target)}
        />

        <Input
          required
          placeholder="Insira uma nova senha"
          id="password"
          value={password}
          onChange={(e: any) => handleValue(e.target)}
          type="password"
        />
        <Button loading={loading}>Alterar Senha</Button>
      </Styles.ContainerForm>
    </Styles.Container>
  )
}

export default NewPasswordComponents
