import React from 'react'

export default function CannectScience({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.8923 33.6893L25.1092 13.9763V3.06667H26.6373C27.0425 3.06667 27.4312 2.90512 27.7177 2.61756C28.0043 2.33001 28.1653 1.94 28.1653 1.53333C28.1653 1.12667 28.0043 0.736659 27.7177 0.449103C27.4312 0.161547 27.0425 0 26.6373 0H11.3569C10.9517 0 10.563 0.161547 10.2764 0.449103C9.98989 0.736659 9.8289 1.12667 9.8289 1.53333C9.8289 1.94 9.98989 2.33001 10.2764 2.61756C10.563 2.90512 10.9517 3.06667 11.3569 3.06667H12.885V13.9763L1.10192 33.6893C0.824016 34.1542 0.673906 34.6847 0.666884 35.2268C0.659863 35.7689 0.796181 36.3032 1.06195 36.7752C1.32772 37.2472 1.71343 37.6401 2.1798 37.9138C2.64616 38.1875 3.1765 38.3323 3.71677 38.3333H34.2774C34.8182 38.3333 35.3493 38.1893 35.8164 37.916C36.2836 37.6427 36.6701 37.2499 36.9366 36.7777C37.203 36.3055 37.3398 35.7708 37.3331 35.2283C37.3263 34.6857 37.1762 34.1546 36.898 33.6893H36.8923ZM15.7233 15.1896C15.8662 14.9519 15.9415 14.6794 15.941 14.4018V3.06667H22.0532V14.4018C22.0527 14.6794 22.128 14.9519 22.2709 15.1896L29.5711 27.4083C27.279 27.8626 24.0186 27.6709 19.6866 25.4706C16.6477 23.9277 13.7559 23.1092 11.0494 23.0153L15.7233 15.1896ZM3.71677 35.2667L9.16802 26.1472C11.8898 25.8137 14.9574 26.5017 18.3018 28.2018C21.9309 30.0437 24.987 30.6705 27.47 30.6705C28.7329 30.6761 29.9901 30.5019 31.2042 30.153L34.2774 35.2667H3.71677Z"
        fill="#3D3D3D"
      />
    </svg>
  )
}
