import ConfirmationDialogue from 'components/ConfirmationDialogue'
import { Frequency, useCannectCare } from 'hooks/useCannectCare'
import { HorizontalContent } from 'pages/CannectCare/styles'
import { useState } from 'react'
import DeactivatableButton from '../../DeactivatableButton'
import Question from '../Question'

export default function BadFeelingsAssessment() {
  const { setStep, badFeelingsFrequency, setBadFeelingsFrequency, sendLifeQualityQuestions } = useCannectCare()
  const [isErrorDialogueOpened, setIsErrorDialogueOpened] = useState(false)

  function handleOptionChoice(id: string) {
    setBadFeelingsFrequency(id as Frequency)
  }

  async function handleNextClick() {
    const successfulySent = await sendLifeQualityQuestions()

    if (successfulySent) {
      setStep(state => state + 1)
    } else {
      setIsErrorDialogueOpened(true)
    }
  }

  return (
    <>
      <span>
        A questão seguinte refere-se a com que frequência você sentiu ou experimentou certas coisas nas últimas duas
        semanas.
      </span>
      <Question
        title="Com que frequência você tem sentimentos negativos tais como mau humor, desespero, ansiedade, depressão?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleOptionChoice}
        options={[
          {
            id: 'never',
            labelId: 'never',
            label: 'nunca',
            checked: badFeelingsFrequency === 'never'
          },
          {
            id: 'sometimes',
            labelId: 'sometimes',
            label: 'algumas vezes',
            checked: badFeelingsFrequency === 'sometimes'
          },
          {
            id: 'frequently',
            labelId: 'frequently',
            label: 'frequentemente',
            checked: badFeelingsFrequency === 'frequently'
          },
          {
            id: 'veryFrequently',
            labelId: 'veryFrequently',
            label: 'muito frequentemente',
            checked: badFeelingsFrequency === 'veryFrequently'
          },
          {
            id: 'always',
            labelId: 'always',
            label: 'sempre',
            checked: badFeelingsFrequency === 'always'
          }
        ]}
      />
      <HorizontalContent style={{ justifyContent: 'space-between' }}>
        <DeactivatableButton outlined onClick={() => setStep(state => state - 1)}>
          Voltar
        </DeactivatableButton>
        <DeactivatableButton onClick={() => handleNextClick()} disabled={badFeelingsFrequency === ''}>
          Próximo
        </DeactivatableButton>
      </HorizontalContent>

      <ConfirmationDialogue
        isOpened={isErrorDialogueOpened}
        onRequestClose={() => setIsErrorDialogueOpened(false)}
        onConfirmationClick={() => setIsErrorDialogueOpened(false)}
        title="Erro ao enviar respostas!"
        description="Houve um erro ao enviar as suas respostas. Tente novamente mais tarde."
        confirmationButtonText="Entendi!"
        confirmationOnlyDialogue
      />
    </>
  )
}
