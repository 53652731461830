import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  /* display: flex;
  flex-direction: column; */

  width: 100%;

  /* padding-top: 30px; */
  padding-bottom: 50px;

  .headText {
    margin: 70px 0;
    span {
      text-align: center;
    }
  }

  .accordion {
    max-width: 100%;
    background: rgba(0, 0, 0, 0.1);
    height: 500px;
    margin: 20px auto;
    display: flex;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.47);
    flex-direction: row;
    cursor: pointer;
    margin-bottom: 100px;
  }
  .box {
    position: relative;
    height: 100%;
    flex: 1;
    background-position: left;
    background-size: cover;
    transition: 2s;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .box:hover {
    flex: 7;

    height: 500px;
  }
  .a1 {
    /* background-image: url('https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'); */
    background-image: linear-gradient(169.56deg, rgba(91, 148, 91, 0.2) 0.73%, #2fb1a3 100.67%, #2fb1a3 100.67%),
      url('https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80');
    color: #fff;
  }
  .a2 {
    background-image: linear-gradient(169.56deg, rgba(91, 148, 91, 0.2) 0.73%, #30f8e1 100.67%, #2fb1a3 100.67%),
      url('https://images.unsplash.com/photo-1614935151651-0bea6508db6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1525&q=80');
    /* background: linear-gradient(169.56deg, rgba(91, 148, 91, 0.94) 0.73%, #30f8e1 100.67%, #2fb1a3 100.67%); */
    color: #fff;
  }
  .a3 {
    background-image: linear-gradient(169.56deg, rgba(76, 208, 76, 0.2) 0.73%, #2fb1a3 100.67%, #2fb1a3 100.67%),
      url('https://images.unsplash.com/flagged/photo-1564833125683-b9fde4516274?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80');
    color: #fff;
    /* background: linear-gradient(169.56deg, rgba(76, 208, 76, 0.76) 0.73%, #2fb1a3 100.67%, #2fb1a3 100.67%); */
  }
  .a4 {
    background-image: linear-gradient(169.56deg, rgba(148, 244, 148, 0.2) 0.73%, #2fb1a3 100.67%, #2fb1a3 100.67%),
      url('https://images.unsplash.com/photo-1531746790731-6c087fecd65a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1106&q=80');
    color: #fff;
    /* background: linear-gradient(169.56deg, rgba(148, 244, 148, 0.76) 0.73%, #2fb1a3 100.67%, #2fb1a3 100.67%); */
  }
  .a5 {
    background-image: linear-gradient(169.56deg, rgba(91, 148, 91, 0.2) 0.73%, #2fb1a3 100.67%, #2fb1a3 100.67%),
      url('https://images.unsplash.com/photo-1648792940059-3b782a7b8b20?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80');
    /* background: linear-gradient(169.56deg, rgba(91, 148, 91, 0.76) 0.73%, #2fb1a3 100.67%, #2fb1a3 100.67%); */
    color: #fff;
  }
  p {
    font-weight: normal;
    font-family: 'Exo 2', sans-serif;
  }
  .logo {
    rotate: -90deg;
    transition: 1s;
  }
  .text {
    opacity: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
    }
  }
  .box:hover {
    img {
      rotate: 0deg;
      margin-left: 50px;
    }
    .text {
      opacity: 1;
    }
  }

  h1 {
    width: 250px;
    text-align: center;
    margin: 100px auto 20px auto;
  }
  @media screen and (max-width: 800px) {
    body {
      margin: 0;
    }
    .accordion {
      width: 100%;
      flex-direction: column;
      height: auto;
      box-shadow: none;
    }
    .box {
      box-shadow: none;
      padding: 1rem;
      flex: 7;
      height: 900px;
      img {
        rotate: 0deg;
        margin-left: 50px;
      }
      .text {
        opacity: 1;
      }
    }
  }

  @media ${MOBILE_WIDTH} {
    padding: 1rem;
  }
`

export const Video = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(97.69deg, rgba(91, 148, 91, 0.76) 0.24%, #2fb1a3 98.87%);
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    span p {
      width: 360px;
    }
  }
  @media screen and (max-width: 800px) {
    width: 110%;
    margin: -1rem;
    padding: 2rem;
    flex-direction: column;
    /* margin-top: 1rem; */
  }
`

export const LinksWrapper = styled.div`
  padding: 0 2rem;
  display: flex;
  align-items: stretch;
  gap: 1rem;

  &:last-child {
    margin-top: 1rem;
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`
