import styled from 'styled-components'

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;
  max-width: 600px;
  gap: 18px;
`
export const WrapperProduct = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 18px;
  padding: 8px;
  background: rgba(225, 225, 225, 0.3);
  margin: 8px 0;
  .product-image {
    width: 50px;
    height: 50px;

    img {
      width: 50px;
      height: 50px;
    }
  }
`
