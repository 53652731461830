import CommonQuestions from 'pages/CommonQuestions'
import HealthProfessionals from 'pages/HealthProfessionals'
import { NewBlog } from 'pages/NewBlog'
import { HomePage } from '@cannect/pages/Home'
import Partners from 'pages/Partners'
import PatientHome from 'pages/PatientHome'
import RealStories from 'pages/RealStories'
import { Screening } from 'pages/Screening'
import Treatment from 'pages/Treatments/Treatment'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Route from 'routes/Route'
import { IChildTypes } from 'types/ChildrenTypes'
import useScrool from '../../hooks/useScrool'
import { RoutePatientOrDoctor } from '../../routes/PrivateRoutes'
import Footer from '../Footer'
import WrapperWhatsapp from '../WrapperWhatsapp'
import * as Style from './styles'
import CannectCuida from 'pages/CannectCuida'

function Layout({ children }: IChildTypes) {
  const { pathname } = useLocation()
  const { ref, handleScrollTop } = useScrool()

  useEffect(() => {
    if (pathname.indexOf('/produtos/1')) handleScrollTop()
  }, [pathname])

  const isDigitalJourneyFlow = useMemo(() => pathname.includes('jornada-digital'), [pathname])
  return (
    <Style.Container ref={ref} id="main-app-container">
      <WrapperWhatsapp />
      <Style.Wrapper id="page-wrapper" vaul-drawer-wrapper="">
        <Route path="/" exact component={HomePage} />
        <Route path="/blog" exact component={NewBlog} />
        <Route path="/tratamentos/:slug" exact component={Treatment} />
        {/*   <Route path="/teste-genetico" exact component={CannectGene} /> */}
        <Route path="/perguntas-frequentes" exact component={CommonQuestions} />
        <Route path="/area-profissionais" exact component={HealthProfessionals} />
        <Route path="/triagem" component={Screening} />
        <Route path="/area-parceiros" exact component={Partners} />
        <Route path="/comunidade" exact component={RealStories} />
        <RoutePatientOrDoctor path="/area-paciente" exact component={PatientHome} />
        <Route path="/cuida" exact component={CannectCuida} />
        <Style.PagesWrapper applyMaxWidth={pathname !== '/login'} fullHeight={pathname.includes('jornada-digital')}>
          {children}
        </Style.PagesWrapper>
        {!isDigitalJourneyFlow && <Footer />}
      </Style.Wrapper>
    </Style.Container>
  )
}

export default Layout
