import { useHistory } from 'react-router'
import * as Styles from './styles'

export interface BadgeTreatmentTypeProps {
  icon?: string
  title?: string
  slug?: string
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  activeCategory?: boolean
}

export default function BadgeTreatmentType({
  icon,
  title,
  slug,
  size = 'medium',
  activeCategory
}: BadgeTreatmentTypeProps) {
  const history = useHistory()

  const getSlugPage = () => {
    history.push({ pathname: slug, search: '' })
  }

  return (
    <Styles.Wrapper onClick={getSlugPage} size={size} activeCategory={activeCategory}>
      <Styles.IconBox>
        <Styles.IconBadge src={icon} alt={title} />
      </Styles.IconBox>
      <Styles.TitleBadge>{title}</Styles.TitleBadge>
    </Styles.Wrapper>
  )
}
