import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const UserPanelContainer = styled.main`
  display: flex;
  flex-direction: column;

  padding: 3.5rem 0.75rem 0 0.75rem;
  width: 100%;
  margin-bottom: 2rem;

  & * {
    font-family: 'Nunito', sans-serif !important;
  }
`

export const PathsWrapper = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  margin-bottom: 8rem;
  width: 100%;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`
