import styled from 'styled-components'

export const Container = styled.div`
  margin: 10px;
  .client {
    img {
      border-radius: 15px;
      object-fit: cover;
    }
  }
`
