/* eslint-disable no-nested-ternary */
import { useAuth } from 'hooks/AuthContext'
import { getPathFrom, removePahtFrom } from 'hooks/usePathsRedirect'
import { setUser } from 'hooks/useSessionUser'
import { User } from 'hooks/useSignedUser/types'
import { useTokenApi } from 'hooks/useTokenApi'
import { SelectOption } from 'new-components/MultiSelect'
import { RegistrationContextValue } from 'pages/Registration/hooks/useRegistration'
import { requiredFormSchema } from 'pages/Registration/schemas/registrationForms'
import { FormEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import api, { defaultsHeadersAxios } from 'services/api'
import { clearMask } from 'utils/formatters'
import { useScheduling } from 'hooks/useScheduling'
import { getFieldsWithErrorsMessage } from '../helpers'

export interface RegisterSuccess {
  success: boolean
  token: string
  user: User
}
export interface RegisterError {
  error: string
  success: boolean
}

interface FetchSpecialtiesResponse {
  expertises: {
    id: number
    name: string
    rqe: string
  }[]
}

const useRegistrationForm = (formFields: RegistrationContextValue) => {
  const history = useHistory()
  const { state } = useLocation() as any
  const { setTokenApi } = useTokenApi()
  const { getRoles } = useAuth()
  const { isSchedulingFlow, onRegisterSuccess } = useScheduling()

  const {
    preferredName,
    typeOfBoard,
    boardNumber,
    boardFu,
    typeOfBoardOther,
    path,
    specialties,
    specialtiesRQE,
    birthdate,
    rg,
    rne,
    genderIdentity,
    name,
    nationality,
    cpf,
    cellphone,
    email,
    emailConfirmation,
    password,
    passwordConfirmation,
    listAsPrescriber,
    hasPrescribedBefore,
    telephone,

    errors,
    setErrors,
    setLoading,
    removeFieldFromErrors
  } = formFields
  const [agreedToUseTerms, setAgreedToUseTerms] = useState(false)
  const [receivePromotionalEmails, setReceivePromotionalEmails] = useState(false)
  const [specialtiesList, setSpecialtiesList] = useState<SelectOption[]>([])

  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const response = await api.get<FetchSpecialtiesResponse>('/expertises/all')
        setSpecialtiesList(
          response.data.expertises.map(expertise => ({
            value: String(expertise.id),
            label: expertise.name,
            rqe: expertise.rqe
          }))
        )
      } catch (err) {
        console.error('Houve algum erro ao buscar as especialidades. Tente novamente mais tarde.')
      }
    }
    fetchSpecialties()
  }, [])

  const onRegistrationFormSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const requiredFieldsValidation = await requiredFormSchema.safeParseAsync({
      typeOfBoard,
      path,
      birthdate,
      rg,
      rne,
      genderIdentity,
      name,
      nationality,
      cpf,
      cellphone,
      email,
      emailConfirmation,
      password,
      passwordConfirmation,
      listAsPrescriber: listAsPrescriber === undefined ? '' : listAsPrescriber ? 'true' : 'false',
      agreedToUseTerms
    })

    if (!requiredFieldsValidation.success) {
      const newErrors = requiredFieldsValidation.error.errors
      setErrors(newErrors)
      toast.dismiss()
      toast.error(getFieldsWithErrorsMessage(newErrors), {
        autoClose: false
      })
      return
    }
    setErrors(undefined)

    try {
      setLoading(true)
      const {
        data: { token, user }
      } = await api.post<RegisterSuccess>('/register', {
        name,
        preferred_name: preferredName,
        birthday: birthdate,
        email,
        cpf: clearMask(cpf),
        rg,
        rne,
        gender_identity: genderIdentity,
        nationality,
        password,
        phone: clearMask(telephone),
        council: typeOfBoard !== 'other' ? typeOfBoard : typeOfBoardOther,
        nr_council: boardNumber,
        uf_council: boardFu,
        nr_rqe: specialtiesRQE.length > 0 && specialtiesRQE[0] ? specialtiesRQE[0] : null,
        whatsapp: clearMask(cellphone),
        listed: listAsPrescriber,
        expertises: specialties.map(specialty => ({ id: Number(specialty) })),
        already_prescribed: hasPrescribedBefore || 4,
        newsletter: receivePromotionalEmails,
        registration_type: path === 'patient' ? 1 : 2
      })

      setTokenApi(token)
      defaultsHeadersAxios(token)
      await setUser(user)
      getRoles()
      if (isSchedulingFlow && onRegisterSuccess) {
        onRegisterSuccess()
        return
      }
      const pathFrom = await getPathFrom()
      const pageToRedirect = pathFrom || '/painel-do-usuario'
      history.push(pageToRedirect)
      await removePahtFrom()

      toast.success('Cadastro realizado com sucesso!')
    } catch (err: any) {
      const error = err?.response?.data as RegisterError

      toast.error(error?.error ? error?.error : 'Houve um erro ao realizar o cadastro.')
    } finally {
      setLoading(false)
    }
  }

  const changeAgreedToUseTerms = () => {
    setAgreedToUseTerms(state => !state)
    if (errors) {
      removeFieldFromErrors('agreedToUseTerms')
    }
  }

  return {
    onRegistrationFormSubmit,
    changeAgreedToUseTerms,
    agreedToUseTerms,
    setAgreedToUseTerms,
    receivePromotionalEmails,
    setReceivePromotionalEmails,
    specialtiesList
  }
}

export default useRegistrationForm
