import { ReactNode } from 'react'
import * as Style from './styles'

interface SectionsWrapperProps {
  children: ReactNode
  minWidth?: `${number}%` | `${number}rem` | `${number}px`
}

export default function SectionsWrapper({ children, minWidth }: SectionsWrapperProps) {
  return <Style.SectionsWrapperContainer style={{ minWidth }}>{children}</Style.SectionsWrapperContainer>
}
