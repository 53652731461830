import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 3rem;

  h2 {
    text-align: center;
    font-size: 1.3rem;
  }
`

export const ContainerHistory = styled.div<any>`
  display: flex;
  margin-bottom: 6rem;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};

  .image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 250px;
      height: 250px;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  .content {
    padding: 2rem;

    h1 {
      text-align: left;
      font-size: 1.7rem;
    }

    h2 {
      text-align: left;
      margin-bottom: 2rem;
      font-size: 1.2rem;
    }

    p {
      font-weight: 300;
    }
  }
`

export const ContainerOtherHistories = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0rem 0 4rem 0;

  button {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
`
