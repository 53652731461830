import { theme } from 'design-cannect'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const CannectCareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  margin-bottom: 4rem;

  h1 {
    color: ${theme.colors.tertiary};
  }
  .texts-info {
    line-height: 1.7rem;

    span {
      p {
        margin-bottom: 2rem;
        font-size: 1.1rem;
        text-align: justify;
      }
    }
  }

  p {
    max-width: 820px;
    font-weight: 300;
  }

  @media ${MOBILE_WIDTH} {
    padding: 1rem;
  }
`

export const HorizontalContent = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;

  width: 100%;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`
