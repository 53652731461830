import React, { useEffect, useState } from 'react'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Order, PaymentMethod } from 'hooks/useCartCheckout'
import { QRCodeCanvas } from 'qrcode.react'
import { useHistory } from 'react-router-dom'
import useQueryString from 'hooks/useQueryString'
import LoadingContainer from 'components/LoadingContainer'
import { CountDown } from './CountDown'
import * as Style from './styles'

interface Props {
  order?: Order
  payment?: PaymentMethod
  generatePixInfo?: any
  pixInfo?: string
  finishOrderByPaymentMethod: any
  error: any
}

export function Pix({ finishOrderByPaymentMethod, error, loadingContainer }: any) {
  const [lineIsCopied, setLineIsCopied] = useState(false)
  const [, setCopyClipboard] = useState<string>('')
  const [pixInfo, setPixInfo] = useState<string>('')

  const { filters } = useQueryString()
  const cart_id = filters?.cart_id
  const history = useHistory()
  const [paymentStatus, setPaymentStatus] = useState('')

  const copyByClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setCopyClipboard(text)
    setLineIsCopied(true)
  }
  const handleFinishOrder = async () => {
    const data = await finishOrderByPaymentMethod({ type: 'pix' })
    setPixInfo(data?.paymentInfos?.pix_qr_code)
  }
  useEffect(() => {
    handleFinishOrder()
  }, [])

  return (
    <Style.Container>
      {loadingContainer && <LoadingContainer loading />}
      {!loadingContainer && (
        <>
          <Style.CountDownContainer>
            <ClockCircleOutlined style={{ fontSize: '24px', paddingRight: '10px' }} />
            <Style.CountDownContent>
              <CountDown generatePixInfo={finishOrderByPaymentMethod} payment={{ type: 'pix' }} />
              <p>para expirar</p>
            </Style.CountDownContent>
          </Style.CountDownContainer>
          <Style.QrCodeContainer>
            <QRCodeCanvas
              value={pixInfo}
              size={200}
              bgColor="#f6f6f6"
              fgColor="#000000"
              level="L"
              includeMargin={false}
            />
            <Style.QrCodeinfos>
              <p>1. Copia a chave pix.</p>
              <p>2. Entre no aplicativo da sua instituição financeira e acesse o ambiente pix.</p>
              <p>3. Escolha a opção de Copia e Cola e insira o código Pix.</p>
              <p>4. Verifique as informações e confirme o pagamento.</p>
              <p>
                5. Após validado o pagamento você será automaticamente direcionado para a página de resumo de compra.
              </p>
            </Style.QrCodeinfos>
          </Style.QrCodeContainer>
          {!lineIsCopied ? (
            <Style.ButtonCopy onClick={() => copyByClipboard(pixInfo)}> Copiar código </Style.ButtonCopy>
          ) : (
            <Style.ButtonCopied> Código copiado </Style.ButtonCopied>
          )}
        </>
      )}
    </Style.Container>
  )
}
