import { useEffect } from 'react'
import Text from 'components/Text'
import { BsCheckCircleFill } from 'react-icons/bs'

import * as Style from './styles'
import NeedHelp from './NeedHelp'

export default function BlankRecipeCheckout() {
  const orderId = localStorage.getItem('@CANNECT:ORDERID')

  useEffect(() => {
    if (orderId) {
      localStorage.removeItem('@CANNECT:ISDEPENDENT')
      localStorage.removeItem('@CANNECT:PRODUCT')
      localStorage.removeItem('@CANNECT:ORDERID')
    }
  }, [orderId])

  return (
    <Style.Container isColumn>
      <BsCheckCircleFill size={80} color="#115850" />
      <Text type="lg" bold color="tertiary">
        Número do Pedido
      </Text>
      {orderId && (
        <Text type="lg" bold color="secondary">
          {orderId}
        </Text>
      )}

      <Text type="lg" bold color="primary">
        Vamos revisar seu pedido e garantir que toda a documentação está correta.
      </Text>
      <Text type="md" bold>
        Fique atento ao seu e-mail, enviaremos um link de pagamento em até 2 dias.
      </Text>
      <NeedHelp />
    </Style.Container>
  )
}
