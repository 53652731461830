/* eslint-disable @typescript-eslint/ban-ts-comment */
import NotFound from 'pages/404'
import MyAppointments from 'pages/MyQueries'
import UserOrderList from 'pages/UserOrderList'
import ChangePassword from '../../pages/ChangePassword'
// import MyOrders from '../../pages/MyOrders'
import OrderDetails from '../../pages/OrderDetails'
import PatientAddresses from '../../pages/PatientAddresses'
import PersonalData from '../../pages/PersonalData'
import PrescriptionConfig from '../../pages/PrescriptionConfig'
import ProfessionalAddresses from '../../pages/ProfessionalAddresses'
import RegisterAddress from '../../pages/RegisterAddress'
import PatientDetails from '../../pages/PatientDetails'
import MyPatients from '../../pages/MyPatients'

interface PageToRenderProps {
  page?: string
}

const PAGE_TO_RENDER = {
  'perfil-alterar-senha': <ChangePassword />,
  'perfil-dados-pessoais': <PersonalData />,
  'perfil-enderecos': <ProfessionalAddresses />,
  'perfil-enderecos-novo': <RegisterAddress />,
  'perfil-config-prescricoes': <PrescriptionConfig />,
  'enderecos-entrega': <PatientAddresses typeOfAddress="delivery" />,
  'enderecos-cobranca': <PatientAddresses typeOfAddress="billing" />,
  'enderecos-novo': <RegisterAddress />,
  'pedidos-todos': <UserOrderList />,
  consultas: <MyAppointments />,
  pacientes: <MyPatients />
  // 'pedidos-entregues': <MyOrders tab="delivered" />
  // 'perfil-documentos': <Documents />,
}

const PageToRender = ({ page = 'perfil-dados-pessoais' }: PageToRenderProps) => {
  // @ts-ignore
  const chosenPage = PAGE_TO_RENDER[page]
  if (chosenPage) return chosenPage

  const isOrderDetailsPage = page.includes('pedidos-')
  if (isOrderDetailsPage) {
    const orderId = page.split('pedidos-')[1]
    if (orderId) return <OrderDetails orderId={orderId} />
  }

  const isProfessionalAddressesPage = page.includes('perfil-enderecos-')
  if (isProfessionalAddressesPage) {
    const addressId = page.split('perfil-enderecos-')[1]
    if (addressId) return <RegisterAddress addressId={addressId} />
  }

  const isPatientAddressesPage = page.includes('enderecos-')
  if (isPatientAddressesPage) {
    const addressId = page.split('enderecos-')[1]
    if (addressId) return <RegisterAddress addressId={addressId} />
  }

  const isMyPatientsPage = page.includes('pacientes-')
  if (isMyPatientsPage) {
    const patientId = page.split('pacientes-')[1]
    if (patientId) return <PatientDetails patientId={Number(patientId)} />
  }

  return <NotFound />
}

export default PageToRender
