import { useEffect, useState } from 'react'

import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'

import Loading from 'components/Loading'

import api from 'services/api'
import EmptyState from 'components/EmptyState'

import { recipeBox } from 'assets/svg'
import { FaWhatsapp } from 'react-icons/fa'
import Text from 'components/Text'
import { useHistory } from 'react-router'
import { useTokenApi } from 'hooks/useTokenApi'
import FilterItems from './Filters'
import List from './List'

import * as Style from './styles'

interface ProductProps {
  product_id?: number
  quantity?: number
  brand_id?: number
  cannect_choice?: boolean
  class_id?: number
  country_id?: number
  createdAt?: string
  currency?: string
  details?: string
  image?: any
  name?: string
  price?: number
  unitPrice?: number
  route_of_administration_id?: number
  shape_id?: number
  status?: string
  status_assistant?: string
  subclass_id?: number
  supplier_id?: number
  translated_name?: string
  updatedAt?: string
  uuid?: string
  weight?: null
}

interface Brand {
  id: number
  uuid: string
  name: string
  status: string
}

interface Classe {
  id: number
  uuid: string
  name: string
}

interface Shape {
  id: number
  uuid: string
  name: string
}

export default function Products() {
  const { deleteTokenApi } = useTokenApi()
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const orderId = localStorage.getItem('@CANNECT:ORDERID')
  const items = localStorage.getItem('@CANNECT:PRODUCT')
  const managedItems: any = items && JSON.parse(items)

  const [loading, setLoading] = useState(false)

  const [products, setProducts] = useState<any>([])

  const [brands, setBrands] = useState<Brand[]>([])
  const [classes, setClasses] = useState<Classe[]>([])
  const [shapes, setShapes] = useState<Shape[]>([])

  const [savedItems, setSavedItems] = useState<any>([])

  const [selectedItem, setSelectedItem] = useState<any>()
  const [selectedProducts, setSelectedProduct] = useState<number>()

  const [urlBrandFilter, setUrlBrandFilter] = useState<any>('')
  const [urlClassFilter, setUrlClassFilter] = useState<any>('')
  const [urlShapeFilter, setUrlShapeFilter] = useState<any>('')

  const [currentItem, setCurrentItem] = useState<any>()

  const getProducts = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`products/1?limit=1000&${urlClassFilter}${urlShapeFilter}${urlBrandFilter}`)
      const dados = data?.products?.rows.map((product: any) => {
        const { media, product_id, translated_name, price, currency, cannect_choice, ...rest } = product
        return { product_id, image: media[0]?.path, translated_name, price, currency, cannect_choice, ...rest }
      })

      setProducts(dados)
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    } finally {
      setLoading(false)
    }
  }

  const getBrands = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/brands')

      setBrands(data?.brands)
    } catch (error) {
      console.log(error)

      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const getClasses = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/classes')

      setClasses(data?.classes)
    } catch (error) {
      console.log(error)

      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const getShapes = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/shapes')

      setShapes(data?.shapes)
    } catch (error) {
      console.log(error)

      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCurrentItem(undefined)
    getProducts()
    getBrands()
    getClasses()
    getShapes()
  }, [urlBrandFilter, urlClassFilter, urlShapeFilter])

  const handleMultipleFilter = async (value: number, type: string) => {
    if (type === 'brand') {
      if (value === 0) {
        setUrlBrandFilter('')
        return
      }
      setUrlBrandFilter(`&brand_id=${value}`)

      return
    }

    if (type === 'class') {
      if (value === 0) {
        setUrlClassFilter('')
        return
      }
      setUrlClassFilter(`&class_id=${value}`)

      return
    }

    if (type === 'shape') {
      if (value === 0) {
        setUrlShapeFilter('')
        return
      }
      setUrlShapeFilter(`&shape_id=${value}`)
    }

    if (type === 'product') {
      if (value === 0) {
        setSelectedItem({})
        return
      }

      setSelectedProduct(value)
    }
  }

  useEffect(() => {
    handleSelectedItem()
  }, [selectedProducts])

  const handleSelectedItem = async () => {
    setLoading(true)
    const holderProducts = await products.filter((product: any) => product.id === selectedProducts)

    setSelectedItem(holderProducts)

    setCurrentItem({
      quantity: 1,
      brand_id: holderProducts[0].brand_id,
      cannect_choice: holderProducts[0].cannect_choice,
      class_id: holderProducts[0].class_id,
      country_id: holderProducts[0].country_id,
      createdAt: holderProducts[0].createdAt,
      currency: holderProducts[0].currency,
      details: holderProducts[0].details,
      product_id: holderProducts[0].id,
      image: holderProducts[0].image,
      name: holderProducts[0].name,
      price: holderProducts[0].price,
      unitPrice: holderProducts[0].real_price,
      route_of_administration_id: holderProducts[0].route_of_administration_id,
      shape_id: holderProducts[0].shape_id,
      status: holderProducts[0].status,
      status_assistant: holderProducts[0].status_assistant,
      subclass_id: holderProducts[0].subclass_id,
      supplier_id: holderProducts[0].supplier_id,
      translated_name: holderProducts[0].translated_name,
      updatedAt: holderProducts[0].updatedAt,
      uuid: holderProducts[0].uuid,
      weight: holderProducts[0].weight
    })

    setLoading(false)
  }

  useEffect(() => {
    if (currentItem !== undefined) {
      handleSave()
    }
  }, [currentItem])

  const handleSave = async () => {
    const getLocalStorageToSave = localStorage.getItem('@CANNECT:PRODUCT')
    const getItemToSave: ProductProps[] = getLocalStorageToSave && JSON.parse(getLocalStorageToSave)

    if (getItemToSave) {
      let itemExist = false

      for (let i = 0; i < getItemToSave.length; i += 1) {
        if (getItemToSave[i].product_id === currentItem?.product_id) {
          itemExist = true
        }
      }

      if (!itemExist) {
        getItemToSave.push({
          product_id: currentItem?.product_id,
          quantity: 1,
          brand_id: currentItem?.brand_id,
          cannect_choice: currentItem?.cannect_choice,
          class_id: currentItem?.class_id,
          country_id: currentItem?.country_id,
          createdAt: currentItem?.createdAt,
          currency: currentItem?.currency,
          details: currentItem?.details,
          image: currentItem?.image,
          name: currentItem?.name,
          price: currentItem?.price,
          unitPrice: currentItem?.unitPrice,
          route_of_administration_id: currentItem?.route_of_administration_id,
          shape_id: currentItem?.shape_id,
          status: currentItem?.status,
          status_assistant: currentItem?.status_assistant,
          subclass_id: currentItem?.subclass_id,
          supplier_id: currentItem?.supplier_id,
          translated_name: currentItem?.translated_name,
          updatedAt: currentItem?.updatedAt,
          uuid: currentItem?.uuid
        })
      }

      setSavedItems(getItemToSave)
      localStorage.setItem('@CANNECT:PRODUCT', JSON.stringify(getItemToSave))
    } else {
      setSavedItems([currentItem])
      localStorage.setItem('@CANNECT:PRODUCT', JSON.stringify([currentItem]))
    }
  }

  const handleSavedItems = (product: any) => {
    setSavedItems(product)
  }

  useEffect(() => {
    const getLocalStorageToSave = localStorage.getItem('@CANNECT:PRODUCT')
    const getItemToSave: ProductProps[] = getLocalStorageToSave && JSON.parse(getLocalStorageToSave)

    setSavedItems(getItemToSave)
  }, [urlBrandFilter, urlClassFilter, urlShapeFilter])

  const handleSubmit = async () => {
    setLoading(true)
    if (orderId) {
      try {
        const couponName = localStorage.getItem('@CANNECT:COUPON')
        const coupon = {
          coupon: {
            name: couponName
          },
          items: [...products]
        } as any
        await api.put(`order/${+orderId}`, { items: savedItems, coupon: { name: couponName } })
        localStorage.removeItem('@CANNECT:COUPON')
        history.push('/receita-branca/endereco-entrega')
      } catch (e: any) {
        if (e?.response?.status === 401) {
          deleteTokenApi()
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Loading loading={loading} />

      <>
        <Style.FilterWrapper isMobile={isMobile}>
          <FilterItems
            products={products}
            brands={brands}
            classes={classes}
            shapes={shapes}
            handleMultipleFilters={handleMultipleFilter}
          />
        </Style.FilterWrapper>
        {products && products?.length > 0 ? (
          <>
            <List products={savedItems} isMobile={isMobile} handleSavedItems={handleSavedItems} />
            {!savedItems ||
              (savedItems.length === 0 && (
                <Style.NoProduct>
                  <Style.SeeProducts>
                    <img src={recipeBox} alt="" />
                    &nbsp;
                    <Text type="md" bold>
                      Pesquise acima para ver os produtos
                    </Text>
                  </Style.SeeProducts>
                  <Style.SeeProducts>
                    <Text type="md" bold>
                      Não encontrou um produto?
                    </Text>
                    <Style.ButtonWrapper>
                      <Style.Button href="https://atendimento.cannect.life/atendimento-cannect" target="__blank">
                        <FaWhatsapp size={24} /> &nbsp;
                        <p>Converse no Whatsapp com um atendente</p>
                      </Style.Button>
                    </Style.ButtonWrapper>
                  </Style.SeeProducts>
                </Style.NoProduct>
              ))}
          </>
        ) : (
          <EmptyState title="Desculpas!" subTitle="Não localizamos nenhum produto com filtro selecionado" />
        )}
        <Style.SubmitButtonWrapper isMobile={isMobile}>
          <Style.SubmitButton disabled={savedItems?.length === 0} onClick={handleSubmit}>
            Continuar
          </Style.SubmitButton>
        </Style.SubmitButtonWrapper>
      </>
    </>
  )
}
