import styled from 'styled-components'
import { theme } from 'design-cannect'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  margin-bottom: 24px;
  .data {
    margin-bottom: 8px;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray};

  .wrapper-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    background: ${theme.colors.pathway};
    height: 130px;
  }

  .descripiton {
    width: 500px;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .status {
    display: flex;
    align-items: center;
    p {
      margin-left: 16px;
      margin-top: 8px;
    }
  }

  .status-error {
    display: flex;
    align-items: center;
    p {
      margin-left: 16px;
    }
    svg {
      font-size: 52px;
      margin-right: 24px;
      color: ${theme.colors.secondary};
    }
  }

  .status-text {
    margin: 0px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    p {
      margin-bottom: 8px;
    }
  }

  .products {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80px;
        margin-right: 24px;
      }
    }

    .action {
      display: flex;
      flex-direction: column;
      button {
        margin-top: 16px;
      }
    }
  }
`
