import React, { useEffect } from 'react'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useQueryString from 'hooks/useQueryString'
import LoadingContainer from 'components/LoadingContainer'
import { useHistory } from 'react-router'
import { Button } from 'design-cannect'
import { Container } from '../styles'
import Step from '../../NewCart/Steps'
import Documents from '../Documents'

function ValidateDocuments() {
  const { loading: loadignOrdens, getLastOrder } = usePrepareCart()

  const history = useHistory()
  const { filters } = useQueryString()
  const cart_id = filters?.cart_id

  useEffect(() => {
    getLastOrder()
  }, [])

  if (loadignOrdens) return <LoadingContainer loading />

  const nextToCart = () => {
    if (cart_id) {
      history.push(`/analise-cannect-wa?cart_id=${cart_id}`)
    } else {
      history.push('/analise-cannect')
    }
  }

  return (
    <Container>
      <Step step={2} />
      <Documents />
      <div className="status_pedido">
        <Button secondary onClick={nextToCart}>
          Status do pedido
        </Button>
      </div>
    </Container>
  )
}

export default ValidateDocuments
