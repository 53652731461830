/* eslint-disable jsx-a11y/label-has-associated-control */
import Button from 'components/Button'
import Loading from 'components/Loading'
import { Modal } from 'components/Modal'
import { Input } from 'design-cannect'
import { getUserRoles } from 'hooks/useSessionUser'
import { FetchRecipeResponse } from 'pages/BaseRecipe'
import { FormEvent, useEffect, useState } from 'react'
import api from 'services/api'
import { Container, BoxHeader, Box, BoxContent, BoxContentProducts, BoxFooter, PasswordModalContainer } from './styles'

interface Media {
  path: string
  format: string
}

export interface Product {
  id: number
  name: string
  translated_name: string
  price: number
  required_bottles: number
  token: string
  emission: string
  media: Media
}

export interface Recipe {
  token: string
  name: string
  emission: string
  products: Product[]
}

function MyRecipe({ token, name, emission, products }: Recipe) {
  const [password, setPassword] = useState('')
  const [showIsPasswordIncorrect, setShowIsPasswordIncorrect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [registrationType, setRegistrationType] = useState(1)

  async function fetchRecipe(event: FormEvent) {
    event.preventDefault()
    setLoading(true)
    try {
      const response = await api.get<FetchRecipeResponse>(`recipe/${token}`, {
        params: {
          pin: password
        }
      })
      await handleDownloadPdf(response.data.recipe_link)
      setIsOpen(false)
    } catch (err: any) {
      if (err?.response?.data?.message === 'Senha incorreta') {
        setShowIsPasswordIncorrect(true)
        return
      }
    } finally {
      setIsOpen(false)
      setLoading(false)
    }
  }

  async function handleDownloadPdf(linkToDownload: string) {
    const anchorElement = document.createElement('a')
    anchorElement.href = linkToDownload
    anchorElement.target = '_blank'
    anchorElement.click()
  }

  useEffect(() => {
    setRegistrationType(getUserRoles())
  })

  return (
    <Container>
      {loading && <Loading loading={loading} />}
      <time>22 de março de 2022</time>
      <Box>
        <BoxHeader>
          <div className="header">
            <div className="header-title">
              <span>{registrationType === 2 ? 'Paciente' : 'Prescritor'}</span>
              <p>{name}</p>
            </div>
            <div className="header-title">
              <span>Emissão</span>
              <p>{emission}</p>
            </div>
            <div className="header-title">
              <button>Adicionar ao carrinho</button>
            </div>
          </div>
        </BoxHeader>
        <BoxContent>
          <h4>Produtos prescritos</h4>
          {products.map(product => (
            <BoxContentProducts key={product.id}>
              <img src={product?.media?.path} alt="" />
              <span>{product?.required_bottles}</span>
              <p>{product?.translated_name}</p>
            </BoxContentProducts>
          ))}
        </BoxContent>
        <BoxFooter>
          <button onClick={() => setIsOpen(true)}>Visualizar receita</button>
        </BoxFooter>
      </Box>
      <Modal isOpened={isOpen} onRequestClose={() => setIsOpen(false)}>
        <PasswordModalContainer onSubmit={event => fetchRecipe(event)}>
          <h1>Baixar PDF</h1>
          <label htmlFor="password-input">
            <b>Insira os 4 últimos dígitos do seu telefone.</b> <br />
            Este processo é necessário para segurança dos <br />
            seus dados e identificação correta do titular da receita.
          </label>
          {showIsPasswordIncorrect && <span>Senha incorreta</span>}
          <Input
            id="password-input"
            placeholder="Senha"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <Button type="submit">Confirmar</Button>
          <span className="link_whats">
            Não conseguiu acesso? <a href="https://whts.co/Cannect">Fale com um atendente.</a>
          </span>
        </PasswordModalContainer>
      </Modal>
    </Container>
  )
}

export default MyRecipe
