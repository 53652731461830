import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const CheckoutCard = styled.div`
  width: 100%;
  background-color: #f8f7fc;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 16px 64px;
  align-items: center;

  @media ${MOBILE_WIDTH} {
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
`

export const AppointmentInfoWrapper = styled.div`
  @media ${MOBILE_WIDTH} {
    margin-left: 26px;
  }
`
