import { theme } from 'design-cannect'
import styled from 'styled-components'

export const ChooseCertificateContainer = styled.main`
  display: flex;
  flex-direction: column;

  text-align: center;

  h1 {
    color: ${theme.colors.tertiary};
    margin-bottom: 1rem;
  }

  p {
    font-weight: 300;
  }

  > div {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`
