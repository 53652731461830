import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

interface ButtonProps {
  color: 'success' | 'error'
}

interface EvalueteNumberProps {
  isActive: boolean
}

export const Wrapper = styled.main`
  width: 100%;
`
export const Heading = styled.h3`
  width: 100%;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000000;

  span {
    font-weight: 700;
  }
`
export const Description = styled.p`
  text-align: center;
  margin: 25px 0;

  &,
  * {
    font-size: 15px;
    font-family: 'Nunito', sans-serif;
    color: #646464;
  }
`
export const ActionsBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  gap: 20px;

  .content_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    color: #646464;
    margin: 15px 0;
  }

  button {
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
    font-weight: 700;
    height: 35px;
    min-height: 35px;

    &:hover {
      transform: scale(1);
    }
  }
`
export const ButtonAction = styled.button``
export const LinkButton = styled.a`
  color: ${temporaryTheme.colors.link};
  cursor: pointer;

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`

export const EvalueteWrapper = styled.div`
  max-width: 550px;
`

export const EvalueteBox = styled.div`
  margin: 20px 0;

  .evaluete_title {
    text-align: center;
    font-family: 'Spartan', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #5b555c;

    span {
      font-family: 'Spartan', sans-serif;
      font-size: 12px;
      font-weight: 700;
      margin-top: 8px;
    }
  }
`
export const EvalueteNumberList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  margin: 25px 0;

  ${media.mobile`
    flex-wrap: wrap;
  `}
`

export const EvalueteNumber = styled.span<EvalueteNumberProps>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1.9px solid #3d3d3d;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    border-color: ${temporaryTheme.colors.sitePrimary};
    color: ${props => (props.isActive ? temporaryTheme.colors.white : temporaryTheme.colors.sitePrimary)};
  }
  background: ${props => props.isActive && temporaryTheme.colors.sitePrimary};
  border-color: ${props => props.isActive && temporaryTheme.colors.sitePrimary};
  color: ${props => props.isActive && temporaryTheme.colors.white};
`

export const EvalueteTextBox = styled.div`
  width: 100%;
  display: flex;
`

export const EvalueteText = styled.textarea`
  all: unset;
  width: 100%;
  min-height: 90px;
  background: #f6f6f6;
  padding: 16px;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  color: #5b555c;
  border-radius: 12px;
  margin-bottom: 25px;

  ${media.mobile`
    width: 95%;
    display: flex;
    padding: 8px;
  `}
`
export const EvalueteActionBox = styled(ActionsBox)`
  justify-content: center;
  button {
    width: 100%;
  }
`

export const LinkAction = styled.a`
  color: ${temporaryTheme.colors.link};

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`
