/* eslint-disable react-hooks/rules-of-hooks */
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form'
import React, { useCallback, useEffect } from 'react'
import { createMask } from 'imask'
import { FormController } from '@cannect/new-components/atoms'
import { Input, InputProps } from '@cannect/new-components/atoms/Form/Input'
import { formItemVariants } from '@cannect/new-components/atoms/FormController'

import { VariantProps } from 'tailwind-variants'
import { formMasks, TMasks } from '@cannect/constants/formMasks'

type InputFieldProps = Omit<InputProps, 'form'> & {
  description?: string
  isLoading?: boolean
  mask?: TMasks
  unmaskOnChange?: boolean
} & VariantProps<typeof formItemVariants>

export const InputField = <TFieldValues extends FieldValues>({
  control,
  name,
  description,
  size: inputSize,
  variant: inputVariant,
  alignment: formItemAlignment,
  mask,
  unmaskOnChange,
  ...props
}: InputFieldProps & {
  name: Path<TFieldValues>
  control: Control<TFieldValues, any>
}) => {
  const { watch } = useFormContext()
  const watchedValue = watch(name)

  const applyMask = useCallback(
    (value?: string | number) => {
      if (!mask || !value || unmaskOnChange) return value
      const createdMask = createMask(formMasks[mask])
      createdMask.resolve(String(value))
      return createdMask.value
    },
    [mask, unmaskOnChange]
  )

  return (
    <FormController.FormField
      control={control}
      name={name}
      data-testid="input-field"
      render={({ field, fieldState }) => {
        const parsedValue = applyMask(field?.value)

        useEffect(() => {
          if (parsedValue && parsedValue !== watchedValue) {
            field.onChange(parsedValue)
          }
        }, [watchedValue, parsedValue])

        return (
          <FormController.FormItem alignment={formItemAlignment}>
            <FormController.FormControl>
              <Input
                isInvalid={!!fieldState.error || !!fieldState.invalid}
                size={inputSize}
                variant={inputVariant}
                name={name}
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={(e) => {
                  const parsedValue = applyMask(e.target.value)
                  field.onChange(parsedValue)
                }}
                value={field.value}
                {...props}
              />
            </FormController.FormControl>
            {description && <FormController.FormDescription>{description}</FormController.FormDescription>}
            <FormController.FormMessage />
          </FormController.FormItem>
        )
      }}
    />
  )
}

InputField.displayName = 'InputField'
