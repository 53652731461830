import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
`

export const HeaderContainer = styled.div`
  margin-bottom: 80px;
  width: 100%;
`

export const TitleHeader = styled.h1`
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: ${temporaryTheme.colors.darkGrey};

  span {
    color: ${temporaryTheme.colors.sitePrimary};
  }

  margin-bottom: 20px;
`
export const TextHeader = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: ${temporaryTheme.colors.darkGrey};

  @media ${MOBILE_WIDTH} {
    width: 100%;
    flex-wrap: wrap;
  }
`

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input-box {
    width: 616px;

    input {
      font-family: 'Spartan', sans-serif;
      font-weight: 500;

      svg {
        width: 15px;
        height: 15px;
        color: ${temporaryTheme.colors.black};
      }
    }
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column-reverse;
    gap: ${temporaryTheme.spacing.space4};
    .input-box {
      width: 100%;
    }
  }
`

export const ButtonsContainers = styled.div`
  display: flex;
  align-items: center;
  gap: ${temporaryTheme.spacing.space2};

  button {
    font-family: 'Spartan', sans-serif;
    font-weight: 500;

    opacity: 0.8;
    &:not(:disabled):hover {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`
