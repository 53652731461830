import React, { useEffect, useState } from 'react'

import Text from 'components/Text'

import InputFile from 'components/InputFile'

import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'

// import { recipeBox } from 'assets/svg'
// import DoctorInfo from '../DoctorInfo'
import UploadedFileInfo from '../UploadedFileInfo'
import * as Style from './styles'
import NavigationHandler from '../NavigationHandler'
import Footer from '../Footer'

export default function HandleRecipeUpload() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const [submit, setSubmit] = useState(false)
  const [getFiles, setGetFiles] = useState<{ name: string }[]>([])
  const [recipe, setRecipe] = useState<any>('')
  const [recipeData, setRecipeData] = useState()

  const [inputValue, setInputValue] = useState<any>({
    recipeCode: ''
  })

  // const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { id, value } = e.target
  //   setInputValue((inputValue: any) => ({ ...inputValue, [id]: value }))
  //   setSubmit(false)
  // }

  const handleInput = (files: Array<Blob>) => {
    const data: any = files as unknown as { name: string }[]

    setRecipeData(data)
    setGetFiles(data)
    setSubmit(false)
  }

  const handleRemove = (file: string, recipeCode: any) => {
    if (file) {
      const fixFiles = getFiles?.filter((e: { name: string }) => e.name !== file)
      setGetFiles(fixFiles)
    }
    if (recipeCode) {
      setRecipe('')
    }

    setSubmit(false)
  }

  const handleSubmit = () => {
    setSubmit(true)
  }

  useEffect(() => {
    if (inputValue?.recipeCode?.length === 9) {
      setRecipe(inputValue.recipeCode)

      setInputValue({ recipeCode: '' })
    }
  }, [inputValue])

  useEffect(() => {
    setSubmit(false)

    if (recipeData) {
      setGetFiles(recipeData)
    }
  }, [])

  return (
    <>
      <NavigationHandler step={0} />

      <Style.Container isColumn>
        <Style.Header>
          <Text type="lg" bold color="tertiary">
            Insira sua receita abaixo
          </Text>
        </Style.Header>
        <InputFile
          submit={submit}
          handleUpload={handleInput}
          updateComponent={getFiles}
          handleReupload={recipeData}
          type="PRESCRICAO"
        />
        {/* <Style.Header>
        <Text type="lg" bold color="tertiary">
          Código
        </Text>
      </Style.Header>
      <Input
        value={recipeCode}
        id="recipeCode"
        onChange={e => changeValue(e)}
        placeholder="CAN121023"
        type="PRESCRICAO"
      /> */}
        {getFiles.length === 0 && recipe === '' ? (
          ''
        ) : (
          <UploadedFileInfo files={getFiles} recipeCode={recipe} removeFile={handleRemove} />
        )}
        {/* <Style.InformationWrapper>
        <DoctorInfo />
      </Style.InformationWrapper> */}

        <Style.SubmitButtonWrapper isMobile={isMobile}>
          <Style.SubmitButton disabled={getFiles?.length === 0} onClick={handleSubmit}>
            Continuar
          </Style.SubmitButton>
        </Style.SubmitButtonWrapper>
      </Style.Container>

      <Footer />
    </>
  )
}
