import styled from 'styled-components'
import { theme } from 'design-cannect'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 36px;

  .secession {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .barra {
      margin-bottom: 50px;
      width: 100%;
      height: 8px;
      background: ${theme.colors.gray};
    }

    .barra-active {
      margin-bottom: 50px;
      width: 100%;
      height: 8px;
      background: ${theme.colors.secondary};
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      margin-top: 16px;
    }

    .img-active {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      background: ${theme.colors.secondary};
    }
    .img-deactive {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      background: ${theme.colors.gray};
    }
  }
`
