import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Prescriber } from 'pages/MyQueries/types/appointment'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import Button from 'new-components/Button'
import DateNavigation from '../DateNavigation'
import { useQueries } from 'pages/MyQueries/hooks/useQueries'
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR'

import { useFormContext } from 'react-hook-form'
import { BiLoaderCircle } from 'react-icons/bi'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { useConsultation } from 'pages/MyQueries/hooks/useConsultation'
import { toast } from 'react-toastify'
import { ButtonAnimated } from '../Modals/ButtonAnimated'
import * as Styles from './styles'
import { useValidations } from 'pages/MyQueries/hooks/useValidations'

interface RescheduleProps {
  prescriber: Prescriber
  currentDate: string
  currentTime: string
  appointMentId: number
  tabNavigator: () => void
}

export function Reschedule({ prescriber, currentDate, currentTime, appointMentId, tabNavigator }: RescheduleProps) {
  const [expanded, setExpanded] = useState(false)
  const { availableTime, loadingPrescribers, getDates, resetDataContext, prescriberData, loading, loadingConsult } =
    useQueries()
  const { watch, setValue } = useFormContext()
  const queries_time = watch('queries_time')
  const queries_date = watch('queries_date')
  const consultationDateTime = parseISO(`${currentDate}T${currentTime}`)
  const formattedDate = format(consultationDateTime, "EEEE dd/MM 'às' HH:mm", { locale: ptBR })
  const { putRescheduling } = useConsultation()
  const { validateReturnText } = useValidations()
  const validateDisableAction = !!(!!queries_time && queries_date && prescriberData?.appointment_duration)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (prescriber) {
      getDates(prescriber.id)
    }
  }, [prescriber])

  useEffect(() => {
    resetDataContext()
  }, [])

  const handleRescheduling = async () => {
    const resultRate = await putRescheduling(appointMentId, {
      date: queries_date,
      hour: queries_time,
      duration: prescriberData?.appointment_duration || 60
    })

    if (resultRate?.success) {
      toast.success('Consulta reagendada com sucesso.')
      tabNavigator()
    } else {
      toast.error(resultRate.message)
    }
  }

  return (
    <Styles.Wrapper>
      <Styles.Heading className="title">Reagende sua consulta </Styles.Heading>

      <Styles.DescriptionBox>
        <Styles.Descriptiontext>
          Profissional da saúde: <span>{prescriber.professional_name}</span>
        </Styles.Descriptiontext>
        <Styles.Descriptiontext>
          {prescriber.council}:{' '}
          <span>
            {prescriber.nr_council} {prescriber.uf_council}
          </span>
        </Styles.Descriptiontext>
        <Styles.Descriptiontext>
          Data que foi agendada anteriormente: <span> {formattedDate}</span>
        </Styles.Descriptiontext>
        <Styles.Descriptiontext>
          Tipo de consulta: <span>Consulta Médica Online</span>
        </Styles.Descriptiontext>
      </Styles.DescriptionBox>

      <Styles.PrescriberHeader>
        <Styles.Heading>Selecione um novo horário</Styles.Heading>
        <DateNavigation prescriber_id={prescriber.id} />
      </Styles.PrescriberHeader>

      <Styles.PrescriberBox>
        {loading ? (
          <div className="content_loading prescriber_box">
            <div className="load_box">
              <BiLoaderCircle />
            </div>
            Carregando horários...
          </div>
        ) : (
          <>
            <Styles.PrescriberDescription>
              <Styles.AvatarBox>
                {!!prescriberData?.photo && (
                  <Styles.Avatar alt={prescriberData?.professional_name} title="dd" src={prescriberData?.photo} />
                )}
              </Styles.AvatarBox>

              <Styles.PrescriberContent>
                <Styles.PrescriberName>{prescriberData?.professional_name}</Styles.PrescriberName>
                <Styles.PrescriberInfo>
                  <span>
                    {(prescriberData?.prescriber_expertises || [])
                      .slice(0, 3)
                      .map((expertise: any) => expertise.name)
                      .join(' • ')}
                  </span>
                  <br />
                  <span>
                    {(prescriberData?.prescriber_age_groups || []).map((ageGroup: any) => ageGroup.name).join(' • ')}
                  </span>
                </Styles.PrescriberInfo>
              </Styles.PrescriberContent>
            </Styles.PrescriberDescription>

            <Styles.AboutContainer>
              <Styles.Expandable onClick={toggleExpanded}>
                <p>Sobre {expanded ? <AiFillCaretDown /> : <AiFillCaretUp />}</p>
              </Styles.Expandable>
              <motion.div
                initial={{ opacity: 0, height: 0, display: 'none' }}
                animate={
                  expanded
                    ? { opacity: 1, height: 'auto', display: 'block' }
                    : { opacity: 0, height: 0, display: 'none' }
                }
                transition={{
                  opacity: { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] },
                  height: { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98], delay: expanded ? 0 : 0.2 },
                  display: { duration: 0.5, ease: [0.04, 0.66, 0.26, 0.108], delay: expanded ? 0 : 0.5 }
                }}
              >
                <Styles.AboutContent>
                  <p className="weight-600 return-text">{validateReturnText(prescriberData)}</p>
                  <p className="weight-600">
                    {prescriber.council}: {prescriber.nr_council} {prescriber.uf_council}{' '}
                  </p>
                  <TextDanger text={prescriberData?.biography || ''} />
                  <p className="pathology_content">
                    Patologias que atende:
                    <Styles.PathologyContainer>
                      {(prescriberData?.prescriber_pathologies || []).map((pathology: any) => (
                        <Styles.PathologyTag key={pathology.id}>{pathology.name}</Styles.PathologyTag>
                      ))}
                    </Styles.PathologyContainer>
                  </p>
                </Styles.AboutContent>
              </motion.div>
            </Styles.AboutContainer>

            <Styles.PrescriberDates>
              {loadingPrescribers ? (
                <div className="content_loading">
                  <div className="load_box">
                    <BiLoaderCircle />
                  </div>
                  Carregando horários...
                </div>
              ) : (
                <div className="content_button">
                  {availableTime.length > 0 ? (
                    availableTime.map(item => (
                      <Styles.DateButton
                        key={item}
                        isActive={queries_time === item}
                        onClick={() => setValue('queries_time', item)}
                      >
                        {item}
                      </Styles.DateButton>
                    ))
                  ) : (
                    <p>Nenhum horario disponivel</p>
                  )}
                </div>
              )}
            </Styles.PrescriberDates>
          </>
        )}
      </Styles.PrescriberBox>

      <Styles.ActionsBox>
        <ButtonAnimated
          bgColor="#24A148"
          text="Confirmar o reagendamento"
          textLoading="Carregando informações..."
          isLoading={loadingConsult.loadReschedule}
          disabled={!validateDisableAction || !!loadingConsult.loadReschedule}
          onClick={handleRescheduling}
        />
      </Styles.ActionsBox>
    </Styles.Wrapper>
  )
}
