import * as SwitchPrimitives from '@radix-ui/react-switch'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

export type SwitchProps = ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>

export const switchVariants = tv({
  slots: {
    container:
      'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent pr-4 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-700 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-700 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary-700 data-[state=unchecked]:bg-gray-200',
    thumb:
      'pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0'
  },
  variants: {
    size: {
      default: {
        container: 'h-4 w-8',
        thumb: 'h-3 w-3'
      },
      lg: {
        container: 'h-6 w-11',
        thumb: 'h-5 w-5'
      }
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const Switch = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, SwitchProps & VariantProps<typeof switchVariants>>(
  ({ className, size, ...props }, ref) => {
    const { container, thumb } = switchVariants({ size, className })

    return (
      <SwitchPrimitives.Root className={container()} {...props} ref={ref}>
        <SwitchPrimitives.Thumb className={thumb()} />
      </SwitchPrimitives.Root>
    )
  }
)
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
