import { BadToGood, useCannectCare } from 'hooks/useCannectCare'
import { HorizontalContent } from 'pages/CannectCare/styles'
import DeactivatableButton from '../../DeactivatableButton'
import Question from '../Question'

export default function QualityOfLifeAssessment() {
  const { setStep, qualityOfLife, setQualityOfLife } = useCannectCare()

  function handleOptionChoice(id: string) {
    setQualityOfLife(id as BadToGood)
  }

  return (
    <>
      <span>Veja o que você acha e selecione a que lhe parece a melhor resposta</span>
      <Question
        title="Como você avaliaria sua qualidade de vida?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleOptionChoice}
        options={[
          {
            id: 'veryBad',
            labelId: 'veryBad',
            label: 'muito ruim',
            checked: qualityOfLife === 'veryBad'
          },
          {
            id: 'bad',
            labelId: 'bad',
            label: 'ruim',
            checked: qualityOfLife === 'bad'
          },
          {
            id: 'neitherBadNorGood',
            labelId: 'neitherBadNorGood',
            label: 'nem ruim, nem boa',
            checked: qualityOfLife === 'neitherBadNorGood'
          },
          {
            id: 'good',
            labelId: 'good',
            label: 'boa',
            checked: qualityOfLife === 'good'
          },
          {
            id: 'veryGood',
            labelId: 'veryGood',
            label: 'muito boa',
            checked: qualityOfLife === 'veryGood'
          }
        ]}
      />
      <HorizontalContent style={{ justifyContent: 'space-between' }}>
        <DeactivatableButton outlined onClick={() => setStep(state => state - 1)}>
          Voltar
        </DeactivatableButton>
        <DeactivatableButton onClick={() => setStep(state => state + 1)} disabled={qualityOfLife === ''}>
          Próximo
        </DeactivatableButton>
      </HorizontalContent>
    </>
  )
}
