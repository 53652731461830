import { Button } from 'design-cannect'
import { useCannectCare } from 'hooks/useCannectCare'
import { HorizontalContent } from 'pages/CannectCare/styles'

export default function Finished() {
  const { setStep, setPath } = useCannectCare()

  function handleFinishClick() {
    setStep(0)
    setPath('')
    // TODO - Request to send assessment
  }

  return (
    <>
      <h1>Terminou!</h1>
      <p>
        Terminamos por aqui a pesquisa de qualidade de vida. Mas não será só essa vez, vamos te avisar quando você
        precisar responder de novo.{' '}
      </p>
      <HorizontalContent style={{ justifyContent: 'space-between' }}>
        <Button outlined onClick={() => setStep(state => state - 1)}>
          Voltar
        </Button>
        <Button onClick={() => handleFinishClick()}>Finalizar</Button>
      </HorizontalContent>
    </>
  )
}
