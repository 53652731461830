import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 510px;

  form {
    width: 100%;
  }

  @media ${MOBILE_WIDTH} {
    gap: 16px;
  }
`

export const FormHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border-radiux: 8px !important;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    color: #898483;
    @media ${MOBILE_WIDTH} {
      font-size: 16px;
    }
  }
`

export const ActionButton = styled.button<{ outlined?: boolean }>`
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  width: 196px;
  height: 36px;
  background-color: transparent;
  border: 2px solid #748351;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #748351;
  margin: 24px 0;

  @media ${MOBILE_WIDTH} {
    height: 32px;
    padding: 0 8px;
  }
`
