import { theme } from 'design-cannect'
import styled from 'styled-components'

export const PasswordModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .link_whats {
    color: ${theme.colors.tertiary};
    margin-top: 16px;
    a {
      color: ${theme.colors.tertiary};
      text-decoration: underline;
    }
  }

  h1 {
    color: ${theme.colors.tertiary};
    margin-bottom: 2.5rem;
  }

  label {
    font-weight: 300;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  input {
    margin-bottom: 1.5rem;
  }

  span {
    color: red;
    font-size: 0.9rem;
  }
`
