import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  h1 {
    text-align: center;
    margin: 4rem 0;
  }
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.6rem;
`
