/* eslint-disable consistent-return */
import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileField } from '@cannect/new-components/molecules/FileField'
import { useHistory } from 'react-router'
import { TDigitalJourneyFormData } from '../types'
import { FORM_SCHEMAS } from '../constants'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useSendIdentityDocumentMutation } from '@cannect/services/resources/digitalJourney'
import { useCallback } from 'react'
import useSignedUser from '@cannect/hooks/useSignedUser'
import { formatDate } from '@cannect/utils/date'

type TFormFields = TDigitalJourneyFormData['send_identity_document']

export const SendIdentificationStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()
  const { signedUser } = useSignedUser()
  const sendIdentityDocumentMutation = useSendIdentityDocumentMutation()

  const sendIdentityForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.send_identity_document
    },
    resolver: zodResolver(FORM_SCHEMAS.send_identity_document)
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid }
  } = sendIdentityForm

  const onSubmit = useCallback(
    (data: TFormFields) => {
      if (!formData.common_fields?.digital_journey_id) return

      return sendIdentityDocumentMutation.mutateAsync(
        {
          digital_journey_id: formData.common_fields?.digital_journey_id,
          ...data
        },
        {
          onSuccess: (response) => {
            const readFromOcrData = {
              cpf: response?.ocr?.send_identity_document?.cpf || signedUser?.cpf || '',
              birthday:
                response?.ocr?.send_identity_document?.birthday ??
                (signedUser?.birthday ? formatDate(signedUser?.birthday, 'dd/MM/yyyy') : ''),
              rg: response?.ocr?.send_identity_document?.rg || signedUser?.rg || '',
              full_name: response?.ocr?.send_identity_document?.full_name || signedUser?.name || ''
            }

            // if file has some id it means that is a file that backend has already sent to ocr
            const fileChanged = data.user_id_files.some((file) => !file.id)
            // we do this to avoid clearing confirm_identity_document fields always that the user come back.
            const shouldUseOcrData = fileChanged

            updateFormData({
              send_identity_document: {
                user_id_files: [...(response?.form_data?.send_identity_document?.user_id_files || [])]
              },
              confirm_identity_document: shouldUseOcrData ? readFromOcrData : { ...formData.confirm_identity_document }
            })

            if (response?.next_step) {
              history.push(response.next_step)
            }
          }
        }
      )
    },
    [formData, updateFormData, history, sendIdentityDocumentMutation]
  )

  return (
    <div className="flex flex-col justify-center gap-4">
      <div className="mb-4 mt-auto flex flex-col items-center gap-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Você é o titular desta receita?
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Envie um documento de identidade (RG, CNH ou RNE, em caso de estrangeiro) em nome do titular da receita médica
          inserida
        </Typography.Text>
      </div>

      <FormController.Form {...sendIdentityForm}>
        <FileField
          isDisabled={isSubmitting}
          name="user_id_files"
          control={control}
          showDescriptionFilesAccept
          maxFiles={10}
          canDownload
          showListFiles
          acceptFormats={['.pdf', '.png', '.jpeg', '.jpg']}
        />

        <Button
          className="mt-auto font-medium"
          full
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          size="lg"
          disabled={!isValid}>
          Continuar
        </Button>
      </FormController.Form>
    </div>
  )
}
