import { media } from 'helpers/mediaQueryStyle'
import styled, { keyframes } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

interface DateButtonProps {
  isActive: boolean
}

const spin = keyframes`
 
    to{
        transform: rotate(360deg);
    }
`

export const Wrapper = styled.main`
  .content_loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #3d3d3d;
  }

  .load_box {
    animation: ${spin} 2s linear infinite;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  }
`
export const Heading = styled.h3`
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: 700;

  &.title {
    margin-bottom: 27px;
  }

  ${media.mobile`
    font-size: 16px;
  `}
`

export const DescriptionBox = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 27px;
`
export const Descriptiontext = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 12px;

  span {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }
`
export const PrescriberBox = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  min-height: 240px;
`
export const PrescriberHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  ${media.mobile`
    flex-direction: column;
    gap: 12px;
  `}
`
export const PrescriberDescription = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;

  ${media.mobile`
    flex-direction: column;
    align-items: center;
  `}
`

export const AvatarBox = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const Avatar = styled.img`
  width: 100%;
  object-fit: cover;
`

export const PrescriberContent = styled.div``
export const PrescriberName = styled.div`
  font-family: 'Spartan', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: ${temporaryTheme.colors.link};
`
export const PrescriberInfo = styled.div`
  font-size: 12px;
  color: #3d3d3d;

  * {
    font-family: 'Nunito', sans-serif;
  }

  span {
    font-weight: 400;
  }
`
export const PrescriberPrice = styled.div``

export const PrescriberMoreInfo = styled.div``

export const PrescriberDates = styled.div`
  width: 100%;
  margin-top: 16px;
  min-height: 70px;

  .content_button {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;

    p {
      font-family: 'Nunito', sans-serif;
      color: #3d3d3d;
      width: 100%;
      text-align: center;
    }
  }
`

export const DateButton = styled.div<DateButtonProps>`
  width: 72px;
  height: 27px;
  border: 1.9px solid #3d3d3d;
  font-family: 'Spartan', sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-color: ${temporaryTheme.colors.sitePrimary};
  }

  background: ${props => props.isActive && temporaryTheme.colors.sitePrimary};
  border-color: ${props => props.isActive && temporaryTheme.colors.sitePrimary};
  color: ${props => props.isActive && temporaryTheme.colors.white};
`

export const Expandable = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 16px;

  p {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #3d3d3d;
    margin: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;

    height: 1px;
    opacity: 0.4;
    background-image: linear-gradient(to right, transparent, #3d3d3d, transparent);
    background-repeat: repeat-x;
    background-size: 8px 1px;
    background-position: 0 0;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;

    height: 1px;
    opacity: 0.4;
    background-image: linear-gradient(to right, transparent, #3d3d3d, transparent);
    background-repeat: repeat-x;
    background-size: 8px 1px;
    background-position: 0 0;
  }
`
export const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  p {
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
  }

  .pathology_content {
    display: flex;
    width: 100%;
    gap: 4px;
  }
  .return-text {
    color: ${temporaryTheme.colors.link};
  }
`
export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
`

export const ActionsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  button {
    height: 35px;
    min-height: 35px;
    font-family: 'Nunito';
    display: flex;
    align-items: center;
    justify-content: center;

    .load-box {
      display: flex;
      align-items: center;
      width: 32px;
      height: 32px;
      svg {
        animation: ${spin} 2s linear infinite;
      }
    }
  }
`

export const PathologyContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`
export const PathologyTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 19px;
  padding: 0 14px;
  background-color: transparent;
  border: 1px solid ${temporaryTheme.colors.link};
  border-radius: 16px;
  font-family: 'Spartan', sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #3d3d3d;
`
