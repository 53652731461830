import { useCannectAssistant } from 'hooks/useCannectAssistant'
import Button from 'new-components/Button'
import FetchSelect, { FetchSelectOption } from 'new-components/FetchSelect'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import * as Styles from './styles'

interface TemplatesModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

export default function TemplatesModal({ isOpen, onRequestClose }: TemplatesModalProps) {
  const history = useHistory()
  const { clearPrescription, setStep, setPath } = useCannectAssistant()
  const [selectedTemplate, setSelectedTemplate] = useState<FetchSelectOption>()
  const [errorMessage, setErrorMessage] = useState('')

  const onContinueClick = () => {
    if (!selectedTemplate) {
      setErrorMessage('Por favor, selecione um modelo')
      return
    }
    clearPrescription()
    history.push(`/assistente?template=${selectedTemplate?.value}`)
    setPath('alreadyKnow')
    setStep(2)
    onRequestClose()
  }

  const onTemplateSelect = (option: FetchSelectOption) => {
    setSelectedTemplate(option)
    setErrorMessage('')
  }

  useEffect(() => {
    if (isOpen) {
      setErrorMessage('')
      setSelectedTemplate(undefined)
    }
  }, [isOpen])

  return (
    <Styles.TemplatesModalContainer isOpened={isOpen} onRequestClose={onRequestClose}>
      <h1>Seus modelos</h1>
      <FetchSelect
        id="prescriptions-templates-select"
        label="Modelos de prescrição"
        value={selectedTemplate}
        onChange={onTemplateSelect}
        errorMessage={errorMessage}
        endpoint="/templates"
        field="templates.rows"
        countField="templates.count"
        labelsField="name"
        placeholder="Clique para buscar um modelo"
      />
      <Styles.TemplatesModalButtons>
        <Button background="black" isOutlined onClick={onRequestClose}>
          CANCELAR
        </Button>
        <Button background="green" onClick={onContinueClick}>
          CONTINUAR
        </Button>
      </Styles.TemplatesModalButtons>
    </Styles.TemplatesModalContainer>
  )
}
