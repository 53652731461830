import React from 'react'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { PrescriberExpertises } from './formatApiData'
import * as Style from './styles'

export interface PrescriberInfoTypes {
  imgSrc: string
  fullName: string
  occupation: PrescriberExpertises[]
  // eslint-disable-next-line react/no-unused-prop-types
  crm?: string
  crmNumber: string
  price: string | number
  about: string
  serviceType: 'Presencial' | 'Telemedicina' | 'Presencial/Telemedicina'
  healthInsurance: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  whatsapp: string
  id: number
}

const MAX_LENGTH = 182

function PrescriberInfo(props: any) {
  const [aboutInfo, setAboutInfo] = React.useState('')
  const [showMore, setShowMore] = React.useState(false)

  function propsArrayJoin(arr: any, prop: any, glue: any) {
    const results: any[] = []
    // eslint-disable-next-line func-names
    arr.forEach(function (item: any) {
      results.push(item[prop].toString())
    })
    return results.join(glue)
  }

  React.useEffect(() => {
    // In case about info has more than 132 characters, it will be truncated.
    // eslint-disable-next-line react/destructuring-assignment
    if (props?.about?.length > MAX_LENGTH && !showMore) {
      // eslint-disable-next-line react/destructuring-assignment
      setAboutInfo(`${props?.about.slice(0, MAX_LENGTH)}(...)`)
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      setAboutInfo(props?.about)
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [props?.about, showMore])

  const handleClick = () => {
    setShowMore(!showMore)
  }

  const redirectWhatsapp = () => {
    // TODO: decide whatsapp default message.
    window.open(`https://whts.co/Cannect`, '_blank')
  }

  return (
    <Style.Container showMore={showMore}>
      <Style.SelfInfoContainer>
        <Style.Picture src={props?.imgSrc} alt="profile-picture" />

        <Style.PrescriberSelfInfo>
          <p className="name">{props?.fullName}</p>
          <span>
            <strong className="occupation">{propsArrayJoin(props?.occupation, 'name', ' / ')}</strong>
          </span>
          <span>
            <strong>{props?.crm}: </strong>
            {props?.crmNumber}
          </span>
          <span>
            <strong>Valor da consulta: </strong>R${props?.price}
          </span>
        </Style.PrescriberSelfInfo>
      </Style.SelfInfoContainer>

      <Style.AboutContainer>
        <div className="about">
          {/* <strong> */}
          <span>{aboutInfo}</span>
          {/* </strong> */}
        </div>
        {props?.about?.length > MAX_LENGTH && !showMore && (
          <button onClick={handleClick}>
            <span>Mais</span>
            <FaArrowDown />
          </button>
        )}
        {showMore && (
          <button onClick={handleClick}>
            <span>Menos</span>
            <FaArrowUp />
          </button>
        )}
      </Style.AboutContainer>

      <Style.ContactContainer healthInsurance={props?.healthInsurance}>
        <strong className="atendimento-label">Atendimento:</strong>
        <p>{props?.crm === 'CRO' ? 'Teleodontologia' : props?.serviceType}</p>
        <Style.WrappedButton onClick={redirectWhatsapp}>Agendar consulta</Style.WrappedButton>
      </Style.ContactContainer>
    </Style.Container>
  )
}

export default PrescriberInfo
