import { parseISO, isBefore, addHours, isAfter, addMinutes } from 'date-fns'

interface ValidateReturnTextProps {
  include_return_visit: boolean
  return_visit_value: string
  return_period: number
}

export const useValidations = () => {
  const isWithinByHours = (
    consultationDate: string,
    consultationHour: string,
    validateBy: number
  ): { passed: boolean; within: boolean; finished: boolean } => {
    const consultationDateTime = parseISO(`${consultationDate}T${consultationHour}`)
    const currentDate = new Date()

    const twentyFourHoursAfter = addHours(currentDate, validateBy)
    const validateFinished = addMinutes(consultationDateTime, 30)

    return {
      within: isBefore(consultationDateTime, twentyFourHoursAfter),
      passed: isAfter(consultationDateTime, twentyFourHoursAfter),
      finished: isAfter(currentDate, validateFinished)
    }
  }

  const validateReturnText = (prescriberData?: ValidateReturnTextProps) => {
    let returnVisitString = ''

    if (!prescriberData?.include_return_visit) {
      return 'Retorno não incluso no valor da consulta.'
    }

    const formattedReturnValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
      Number(prescriberData?.return_visit_value)
    )

    if (
      prescriberData?.return_period &&
      prescriberData?.return_visit_value &&
      prescriberData?.return_visit_value !== '0'
    ) {
      returnVisitString = `Retorno incluso nos próximos ${prescriberData?.return_period} dias, pelo valor de ${formattedReturnValue}.`
    } else if (
      prescriberData?.return_period &&
      (!prescriberData?.return_visit_value || prescriberData?.return_visit_value === '0')
    ) {
      returnVisitString = `Retorno incluso nos próximos ${prescriberData?.return_period} dias, de forma gratuita.`
    } else if (
      !prescriberData?.return_period &&
      prescriberData?.return_visit_value &&
      prescriberData?.return_visit_value !== '0'
    ) {
      returnVisitString = `Retorno incluso pelo valor de ${formattedReturnValue}.`
    } else {
      returnVisitString = 'Retorno incluso de forma gratuita.'
    }

    return returnVisitString
  }

  return { isWithinByHours, validateReturnText }
}
