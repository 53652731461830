import * as Style from './styles'

interface ButtonProps {
  children: string
  onClick: () => void
  outlined?: boolean
  disabled?: boolean
  className?: string
}

export default function DeactivatableButton({ children, onClick, outlined, disabled, ...rest }: ButtonProps) {
  return (
    <Style.ButtonContainer onClick={onClick} disabled={disabled} outlined={outlined} {...rest}>
      {children}
    </Style.ButtonContainer>
  )
}
