import {
  bannerItemReceitaBranca,
  bannerItemReceitaBrancaMobile,
  bannerItemReceitaEspecial,
  bannerItemReceitaEspecialMobile,
  bannerItemSemReceita,
  bannerItemSemReceitaMobile
} from 'assets/img'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'

function BeginCheckout() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const handleOpenLink = (link: string) => {
    window.open(link, '_blank', 'noopener')
  }
  return (
    <Style.SessionWrapper>
      <Style.SessionTitle>
        <span>Seu caminho</span> para uma <span>vida melhor:</span>
      </Style.SessionTitle>
      <Style.SessionItems>
        <Style.ImageSessionItems
          src={isMobile ? bannerItemSemReceitaMobile : bannerItemSemReceita}
          alt="não tenho receita"
          onClick={() => handleOpenLink('https://atendimento.cannect.life/atendimento-cannect')}
        />
        <Style.ImageSessionItems
          src={isMobile ? bannerItemReceitaBrancaMobile : bannerItemReceitaBranca}
          alt="Receita Branca"
          onClick={() => handleOpenLink('https://www.cannect.life/produtos/1?translated_name=&supplier_id=1')}
        />
        <Style.ImageSessionItems
          src={isMobile ? bannerItemReceitaEspecialMobile : bannerItemReceitaEspecial}
          alt="Receita especial"
          onClick={() => handleOpenLink('https://www.cannect.life/produtos/1?translated_name=&supplier_id=18')}
        />
      </Style.SessionItems>
    </Style.SessionWrapper>
  )
}

export default BeginCheckout
