import { theme } from 'design-cannect'
import styled from 'styled-components'

export const QuestionMarkButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${theme.colors.tertiary};
  border: none;
  border-radius: 999px;

  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;

  font-size: 1.1rem;
  outline: none;
  color: white;

  position: relative;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &:hover {
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;

      content: 'Adicione um sintoma por vez. Caso tenha mais de um, clique no botão ao lado para adicionar um outro sintoma.';
      position: absolute;
      left: 35px;

      width: 200px;
      min-width: 200px;
      height: 100px;
      min-height: 100px;

      background: white;
      box-shadow: var(--shadow-700);
      border-radius: 0.5rem;

      padding: 1.5rem;
      z-index: 200;
      color: black;

      font-weight: 300;

      animation-name: fade-in;
      animation-duration: 0.5s;
    }
  }
`
