import { THistoryListItem } from 'pages/Account/components/pages/MyPatients/types'

interface HistoryNumberCellProps {
  row: THistoryListItem
}
const HistoryNumberCell = ({ row }: HistoryNumberCellProps) => {
  if (!row.order_id && !row.scheduling_order_id && !row.recipe_id) return null

  return <span className="text-xs">{row.order_id || row.scheduling_order_id || row.recipe_id}</span>
}

export default HistoryNumberCell
