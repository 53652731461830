import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.main`
  width: 100%;
  background: #f8fafd;
  padding: 30px;
  border-radius: 8px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-input-search {
    * {
      border-color: #d9d9d9;
    }

    .ant-input-affix-wrapper {
      &:hover {
        border-color: #d9d9d9;
      }
    }

    .ant-input-group-addon {
      button {
        &:hover {
          border-color: ${temporaryTheme.colors.sitePrimary};
        }
      }
    }
  }
`
export const SearchBox = styled.div``
export const TitleBox = styled.div``
export const Title = styled.h4`
  color: #424242;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 20px;
`
export const Count = styled.div`
  font-family: 'Nunito', sans-serif;
  color: #777777;
  font-size: 12px;
  font-weight: 400;
`

export const ListContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin: 22px 0;
`
export const CardItem = styled.div`
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  min-width: calc(100% / 3 - 9.5px);
  padding: 20px 8px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: box-shadow 0.3s ease;
  gap: 6px;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  ${media.mobile`
    width: 100%;
  `}
`
export const CardContent = styled.div``
export const CardName = styled.div`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: #4e4e4e;
  font-size: 14px;
`
export const CardDocument = styled.div`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #afafaf;
`
export const CardIcon = styled.div`
  cursor: pointer;

  svg {
    fill: #424242;
    height: 24px;
    width: 24px;
    transition: fill 0.3s ease;
  }

  &:hover svg {
    fill: #555;
  }
`
export const PaginateBox = styled.div`
  ul li {
    width: 35px;
    height: 35px;
    a {
      color: #bcbcbc;
    }
    &.selected {
      background: #3d3d3d;
      a {
        color: white;
      }
    }

    svg {
      color: #3d3d3d !important;
    }
  }
`

export const MessageError = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  flex-direction: column;

  font-family: 'Nunito', sans-serif;
`
