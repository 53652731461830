import styled, { keyframes } from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  input {
    flex-grow: 1;
    font-size: 1rem;
    border: none;
    outline: none;
  }
`

export const SelectContainer = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '12px'};

  color: #3d3d3d;
  position: relative;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 1.5rem;

    * {
      color: #3d3d3d;
    }
  }
`

interface FakeSelectProps {
  hasError?: boolean
  height?: string
  isLoading?: boolean
}
export const FakeSelect = styled.button<FakeSelectProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isLoading }) => (isLoading ? 'flex-end' : 'space-between')};
  padding: 1rem;
  border-radius: 0.5rem;
  border: ${({ hasError }) => (hasError ? '1px solid #e24444' : '1px solid #d9d9d9')};

  color: #3d3d3d;
  height: ${({ height }) => height || '3.7rem'};
  z-index: 5;

  background: #ffffff;
  cursor: pointer;

  .fake-select-placeholder {
    color: #d9d9d9;
    opacity: 1;
    font-weight: 300;
  }

  &:focus {
    border: 1px solid #3d3d3d;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

interface FakeSelectOptionsContainerProps {
  top: number
  left: number
  maxWidth: number
}
export const FakeSelectOptionsContainer = styled.div<FakeSelectOptionsContainerProps>`
  display: flex;
  flex-direction: column;

  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.2);

  position: fixed;
  top: ${({ top }) => `${top + 51.8}px`};
  left: ${({ left }) => `${left}px`};

  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  max-height: 250px;

  overflow: auto;
  background: #ffffff;
  z-index: 6;

  @media ${MOBILE_WIDTH} {
    top: ${({ top }) => `${top + 44.4}px`};
  }

  * {
    color: #3d3d3d;
  }

  .selected-option {
    background: #dfdfdf;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: rgba(225, 225, 227, 0.6);
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #21976e;
    border-radius: 0.5rem;
  }
`

interface FakeSelectOptionProps {
  isSelected: boolean
}
export const FakeSelectOption = styled.button<FakeSelectOptionProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isSelected }) => (isSelected ? 'space-between' : 'flex-start')};

  padding: 0.75rem 1.25rem;
  background: #ffffff;
  transition: background 0.3s;

  border: none;

  &:hover {
    background: #d9d9d9;
  }

  span {
    text-align: left;
  }

  svg {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
  }
`

export const SelectBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100%;
  min-height: 100vh;

  z-index: 4;
`

export const InputErrorMessage = styled.p`
  font-size: 1rem;
  color: #e24444;
  margin-top: -0.5rem;
  font-weight: 300;
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Spinner = styled.div<{ isMulti?: boolean }>`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation: ${spin} 0.6s linear infinite;
  margin-top: ${({ isMulti }) => (isMulti ? '8px' : '0')};
`
