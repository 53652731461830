import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

interface BadgePropsType {
  clearType?: boolean
}

const modifierBadge = {
  isFilter: () => css`
    border: 1px solid transparent;
    background: ${temporaryTheme.colors.darkGrey};
    cursor: pointer;
    ${BadgeName} {
      color: ${temporaryTheme.colors.white};
    }
  `
}

export const Wrapper = styled.main`
  display: flex;
  flex-wrap: wrap;
`

export const BadgeItemBox = styled.div<BadgePropsType>`
  ${({ clearType }) => css`
    border: 1px solid ${temporaryTheme.colors.darkGrey};
    padding: 3px 6px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${temporaryTheme.spacing.space1};

    ${!!clearType && modifierBadge.isFilter}
  `}
`
export const BadgeName = styled.span`
  font-family: 'Spartan', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: ${temporaryTheme.colors.darkGrey};

  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`
export const BadgeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  cursor: pointer;
`
