import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'

export interface HistoryImageProps {
  image: string
}

export const Wrapper = styled.main`
  width: 100%;
  padding: ${temporaryTheme.spacing.space8} ${temporaryTheme.spacing.space7};
  max-width: ${MAX_PAGE_WIDTH};

  .title-other_stories {
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: #3d3d3d;

    @media ${MOBILE_WIDTH} {
      font-size: 26.29px;
    }
  }
`
export const HeadingPage = styled.h1`
  font-family: 'Spartan', sans-serif;
  font-weight: 500;
  font-size: 46.29px;
  color: #3d3d3d;
  margin-bottom: 60px;
  span {
    color: #21976e;
    font-weight: 600;
  }

  strong {
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    font-size: 26.29px;
  }
`
export const HistoryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space4};

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`
export const HistoryImageBox = styled.img`
  width: 368px;
  height: 387px;
  background: #c3c3c3;
  border-radius: 30px;
  object-fit: cover;
  overflow: hidden;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`
export const HistoryContent = styled.div`
  width: calc(100% - 368px);

  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space4};
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 387px;
  p {
    font-family: 'Spartan', sans-serif;
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }

  &.content-text {
    span,
    h1,
    h2,
    p,
    strong {
      font-family: 'Spartan', sans-serif !important;
      font-weight: 500;
      color: ${temporaryTheme.colors.darkGrey} !important;
      font-size: 15px !important;
    }
  }
`
export const HistoryName = styled.h3`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #21976e;
`
export const HistoryText = styled.p`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  color: ${temporaryTheme.colors.darkGrey};
  font-size: 15px;
  strong {
    font-family: 'Nunito', sans-serif;
  }
`
export const HistoryActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space4};
`

export const OtherStoriesContainer = styled.div`
  margin-top: 103px;
`

export const OtherStoriesSliderCard = styled.div`
  overflow-x: auto;
  padding: ${temporaryTheme.spacing.space4} 2px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
    background: #c3c3c3;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${temporaryTheme.colors.primary['700']};
    border-radius: 10px;
  }
`
export const OtherStoriesSliderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  wrap: nowrap;
  gap: ${temporaryTheme.spacing.space4};
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 50px;
`
export const OtherStoriesCard = styled.img`
  width: 185px;
  height: 185px;
  min-width: 185px;
  min-height: 185px;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
`

export const FormContainer = styled.div`
  margin-top: 86px;
  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space5};
`

export const HiddenText = styled.button`
  background: transparent;
  border: none;
  outline: none;
  color: ${temporaryTheme.colors.primary['700']};
`
