import { TFormats } from '@cannect/new-components/molecules/FileItem/types'
import { ButtonProps } from '../Button'

export const defaultAcceptFormats: TFormats[] = ['.pdf', '.jpg', '.jpeg', '.png']

export type TFileButtonProps = {
  buttonClasses?: string
  onUploadFile: (file: File) => void
  buttonLabel?: string
  isDisabled?: boolean
  maxSize?: number
  customTrigger?: () => void
  acceptFormats?: TFormats[]
  onError?: (error?: TItemError) => void
  showIcon?: boolean
} & Pick<ButtonProps, 'variant' | 'size'>

export type TErrorType = 'DEFAULT' | 'FORMAT' | 'SIZE'

export type TItemError = {
  message: string
}
