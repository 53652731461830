import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`
export const Row = styled.div<any>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  justify-content: space-between;

  width: 100%;
  gap: 15px;
  padding-bottom: 20px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Option = styled.option`
  font-weight: bold;
  letter-spacing: 0.5px;
`
