import { notFound } from 'assets/svg'
import Texts from 'components/Text'
import * as Styles from './styles'

function NotFound() {
  return (
    <Styles.Container>
      <img src={notFound} alt="" />
      <Texts type="h1" color="tertiary">
        404
      </Texts>
      <Texts type="md" color="tertiary" bold>
        Ops, algo deu errado, não temos certeza o que foi, mas não era para você estar aqui
      </Texts>
    </Styles.Container>
  )
}

export default NotFound
