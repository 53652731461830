export const parsePostImageAltText = (image: { alternativeText?: string; name?: string } = {}) => {
  const getAltText = (text: string) => text?.split('.')[0].replace('-', ' ')

  if (image.alternativeText) {
    return getAltText(image.alternativeText)
  }
  if (image.name) {
    return getAltText(image.name)
  }
  return 'Blog post image'
}
