import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 20px;

  .submit-button {
    display: flex;
    justify-content: flex-end;

    width: 100%;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;

  gap: 15px;
  padding-bottom: 20px;
`

export const Option = styled.option``
