import api from 'services/api'
import { TResponseStandard } from 'types/common'
import { THistoryListItem, TPatientListItem } from '../types'
import { FetchPatientHistoryParams } from './types'

export const fetchPatientHistory = async ({ patientId, page, categories, search }: FetchPatientHistoryParams) => {
  return api
    .get<TResponseStandard<THistoryListItem[], 'achievements'>>(`/prescriber/patients/${patientId}/achievements`, {
      params: { page, limit: 8, categories, search }
    })
    .then(({ data }) => data)
}

export const fetchUserPatients = async ({ page, search }: { page: number; search: string }) => {
  return api
    .get<TResponseStandard<TPatientListItem[], 'patients'>>(`/prescriber/patients?page=${page}&search=${search}`)
    .then(({ data }) => data)
}
