import { nextArrow, previusArrow, sound0, sound1 } from 'assets/svg'
import { useEffect, useRef, useState } from 'react'
import { AiOutlinePauseCircle } from 'react-icons/ai'
import { BsPlayCircle } from 'react-icons/bs'
import { FormatTimerAudio } from 'utils/formatters'
import { MockCardPodcast } from './mock-card'
import * as Styles from './styles'

export interface CardPodcastPlayerProps {
  title?: string
  text?: string
  audioLink?: string
  image?: string
}

export default function CardPodcastPlayer({
  title = MockCardPodcast.title,
  text = MockCardPodcast.text,
  audioLink = MockCardPodcast.audio,
  image = MockCardPodcast.image
}: CardPodcastPlayerProps) {
  const [volume, setVolume] = useState(90)
  const [isPlaying, setIsPlaying] = useState(false)
  const [elapsed, setElapsed] = useState(0)
  const [duration, setDuration] = useState(0)

  const getVolume = (volumHeight: number) => {
    setVolume(volumHeight)
  }

  const audioRef = useRef(null) as any

  const controlPlay = () => {
    if (!isPlaying) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
    setIsPlaying(prev => !prev)
  }

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100
    }

    if (isPlaying) {
      setInterval(() => {
        const durationTime = Math.floor(audioRef?.current?.duration)
        const currentTime = Math.floor(audioRef?.current?.currentTime)
        setDuration(durationTime)
        setElapsed(currentTime)
      }, 100)
    }
  }, [volume, isPlaying])

  const moveForWard = () => {
    audioRef.current.currentTime += 15
  }

  const moveToBack = () => {
    audioRef.current.currentTime -= 15
  }

  return (
    <Styles.Wrapper>
      <Styles.Audio ref={audioRef} src={audioLink} preload="auto" />
      <Styles.ThumbVideoBox>
        <Styles.ThumbVideo src={image} alt="" />
      </Styles.ThumbVideoBox>

      <Styles.InfoContent>
        <Styles.TextContent>
          <Styles.TitleBox>
            <Styles.TitleVideo>{title}</Styles.TitleVideo>
          </Styles.TitleBox>
        </Styles.TextContent>

        <Styles.ControllsVideoContent>
          <Styles.PlayerControll onClick={controlPlay}>
            {isPlaying ? <AiOutlinePauseCircle /> : <BsPlayCircle />}
          </Styles.PlayerControll>

          <Styles.PreviusControll onClick={moveToBack}>
            <Styles.ArrowIcon src={previusArrow} alt="voltar 15 segundos" />
          </Styles.PreviusControll>

          <Styles.ProgressBar value={elapsed} max={duration} />

          <Styles.NextControll onClick={moveForWard}>
            <Styles.ArrowIcon src={nextArrow} alt="avançar 15 segundos" />
          </Styles.NextControll>

          <Styles.TimerVideo>{FormatTimerAudio(duration - elapsed)}</Styles.TimerVideo>

          <Styles.SoundControllBox>
            <Styles.SoundIcon src={sound0} />

            <Styles.SoundProgressBar
              type="range"
              min="1"
              max="100"
              value={volume}
              onChange={(e: any) => getVolume(e.target.value)}
            />

            <Styles.SoundIcon src={sound1} />
          </Styles.SoundControllBox>
        </Styles.ControllsVideoContent>
      </Styles.InfoContent>
    </Styles.Wrapper>
  )
}
