import api from '@cannect/services/api'
import { TResponseStandard, TResponseStandardWithPagination } from '@cannect/services/types'
import { TProductDetailItem, TProductFilters, TProductMedicalData } from './types'

export const getProductById = async (productId: number) => {
  return api
    .get<
      TResponseStandard<TProductDetailItem, 'product'> & {
        medicalData: TProductMedicalData
      }
    >(`product/${productId}`)
    .then(({ data }) => data)
}

export const getProductsFilters = async () => {
  return api.get<TResponseStandard<TProductFilters, 'filters'>>('/product-filters').then(({ data }) => data)
}

export const getPublicProductsList = async (page: string, queryParams: URLSearchParams) => {
  const updatedParams = new URLSearchParams(queryParams)
  updatedParams.set('limit', queryParams.get('limit') || '12') // default limit is 12
  return api
    .get<
      TResponseStandardWithPagination<TProductDetailItem[], 'products'>
    >(`/products_public/${page}?${updatedParams.toString()}`)
    .then(({ data }) => data)
}
