import styled from 'styled-components'
import { theme } from 'design-cannect'

interface Props {
  isMobile: boolean
}

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-top: 10px;

  border-radius: 2px;

  background-color: #f6f6f6;

  .white-space {
    background: white;
    height: 20px;
  }
`

export const Container = styled.div<Props>`
  padding-top: 15px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${props => (props.isMobile ? 'center' : 'start')};
    margin-bottom: ${theme.spacing.space3};
  }

  .loading {
    position: absolute;
    top: 30%;
    left: 50%;
  }
`

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;

  padding-bottom: 15px;

  margin: 0px 30px 0px 35px;

  .product {
    width: 50%;
  }

  .product-properties {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-left: 30px;

    width: 50%;
  }
`

export const OrderResume = styled.div<any>`
  padding: 0px 35px 0px 35px;
  margin-bottom: 20px;

  .row-items {
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

    margin-top: 30px;

    justify-content: space-between;
  }

  .total-display {
    display: flex;
    flex-direction: row;

    border-top: 1px solid;
    margin-top: 15px;
    padding-top: 15px;

    justify-content: space-between;
  }

  .coupon-container {
    display: flex;
    flex-direction: column;
  }
`

export const SubmitButton = styled.button`
  display: flex;

  background-color: #ecece2;
  color: ${theme.colors.tertiary};

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #DADAC2;

  margin-top: 10px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
