/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ZodIssue } from 'zod'

const FIELDS_LABELS = {
  zipCode: 'CEP',
  street: 'Logradouro',
  number: 'Número',
  district: 'Bairro',
  city: 'Cidade',
  fu: 'Estado'
}

export const getFieldsWithErrorsMessage = (errors: ZodIssue[]) => {
  let message = 'Os seguintes campos estão inválidos: '
  const uniqueFieldsWithErrors = [...new Set(errors.map(error => error.path[0]))]

  uniqueFieldsWithErrors.forEach((field, index) => {
    if (index === uniqueFieldsWithErrors.length - 1) {
      // @ts-ignore
      message += FIELDS_LABELS[field]
    } else {
      // @ts-ignore
      message += `${FIELDS_LABELS[field]}, `
    }
  })

  return message
}

export const getFieldError = (fieldName: string, errors?: ZodIssue[]) => {
  if (!errors) return undefined

  const fieldError = errors.find(error => error.path.includes(fieldName))
  if (!fieldError) return undefined

  return 'Campo obrigatório'
}

export const getGoBackLink = (registrationType: 1 | 2) => {
  if (registrationType === 1) {
    const goBackLink = localStorage.getItem('@CANNECT_NEW_ADDRESS_FROM')
    return goBackLink || '/conta?pagina=enderecos-entrega'
  }
  return '/conta?pagina=perfil-enderecos'
}
