import { Button, Illustrations, Typography } from '@cannect/new-components/atoms'

type TPaymentStatus = {
  status: 'paid' | 'refused' | 'awaiting_payment'
  handleClick: {
    paid: () => void
    awaiting_payment: () => void
    refused: () => void
  }
}
export const PaymentStatus = ({ status, handleClick }: TPaymentStatus) => {
  const statusTitle = {
    paid: 'Oba! Pagamento aprovado.',
    awaiting_payment: 'Aguardando aprovação',
    refused: 'Ops! Pagamento recusado'
  }
  const statusButtonText = {
    paid: 'Continuar para importação',
    awaiting_payment: 'Acompanhar meu pedido',
    refused: 'Revisar dados do cartão'
  }
  const statusIllustration = {
    paid: Illustrations.SuccessPayment,
    awaiting_payment: Illustrations.WaitingPayment,
    refused: Illustrations.RefusedPayment
  }
  const CurrentIllustration = statusIllustration[status]

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="my-auto flex flex-col items-center gap-8">
        <Typography.Heading type="headingThree" className="text-center text-primary-700">
          {statusTitle[status]}
        </Typography.Heading>
        {CurrentIllustration && <CurrentIllustration />}
      </div>
      <Button size="lg" full onClick={handleClick[status]} className="font-medium">
        {statusButtonText[status]}
      </Button>
    </div>
  )
}
