import Select from 'components/Select'

import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'

import * as Styles from './styles'

interface FilterItemsProps {
  handleMultipleFilters: (value: number, type: string) => void
  products: []
  brands: Brand[]
  classes: Classe[]
  shapes: Shape[]
}

interface Brand {
  id: number
  uuid: string
  name: string
  status: string
}

interface Classe {
  id: number
  uuid: string
  name: string
}

interface Shape {
  id: number
  uuid: string
  name: string
}

export default function FilterItems({ products, brands, handleMultipleFilters, classes, shapes }: FilterItemsProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const handleMultipleFilter = (value: number, type: string) => {
    handleMultipleFilters(value, type)
  }

  return (
    <Styles.Container>
      <Styles.Row isMobile={isMobile}>
        <Select
          onChange={e => handleMultipleFilter(+e.target.value, 'brand')}
          style={{ color: '#115850', fontWeight: '700', letterSpacing: '0.4px' }}
          defaultValue="Marcas"
        >
          <Styles.Option value={0}>Marcas</Styles.Option>
          {brands.map(brand => (
            <Styles.Option value={brand?.id} key={brand?.id}>
              {brand?.name}
            </Styles.Option>
          ))}
        </Select>
        <Select
          onChange={e => handleMultipleFilter(+e.target.value, 'class')}
          style={{ color: '#115850', fontWeight: '700', letterSpacing: '0.4px', width: isMobile ? '327px' : '230px' }}
          defaultValue="Classe"
        >
          <Styles.Option value={0}>Classe</Styles.Option>
          {classes.map(classe => (
            <Styles.Option value={classe?.id} key={classe?.id}>
              {classe?.name}
            </Styles.Option>
          ))}
        </Select>
        <Select
          onChange={e => handleMultipleFilter(+e.target.value, 'shape')}
          style={{ color: '#115850', fontWeight: '700', letterSpacing: '0.4px', width: isMobile ? '327px' : '230px' }}
          defaultValue="Forma"
        >
          <Styles.Option value={0}>Forma</Styles.Option>
          {shapes.map(shape => (
            <Styles.Option value={shape?.id} key={shape?.id}>
              {shape?.name}
            </Styles.Option>
          ))}
        </Select>
      </Styles.Row>
      <Styles.Column>
        <Select
          onChange={e => handleMultipleFilter(+e.target.value, 'product')}
          style={{ color: '#115850', fontWeight: '700', letterSpacing: '0.4px' }}
          defaultValue="Produto"
        >
          <Styles.Option value={0}>Produtos</Styles.Option>
          {products?.length > 0
            ? products?.map((product: any) => {
                return <Styles.Option value={product.id}>{product.translated_name}</Styles.Option>
              })
            : null}
        </Select>
      </Styles.Column>
    </Styles.Container>
  )
}
