import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.main`
  .slick-dots {
    list-style: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: ${temporaryTheme.spacing.space2};
    li {
      background: ${temporaryTheme.colors.gray};
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 ${temporaryTheme.spacing.space1};
      cursor: pointer;
      &.slick-active {
        background: ${temporaryTheme.colors.sitePrimary};
      }
    }
    button {
      opacity: 0;
      width: 0.9rem;
      height: 0.9rem;
      cursor: pointer;
    }
  }
`

export const BannersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 155px;
  background: ${temporaryTheme.colors.gray};
  padding: 0.2rem;
  border-radius: 4px;
  cursor: pointer;
  p {
    font-size: 2rem;
  }
`

export const ImageBanner = styled.img`
  width: 100%;
  object-fit: cover;
`
