/* eslint-disable no-nested-ternary */
import { MdInfoOutline } from 'react-icons/md'
import Select from 'new-components/Select'
import Input from 'new-components/Input'
import { Button } from '@cannect/new-components/atoms'
import { toast } from 'react-toastify'
import { FormEvent, useState } from 'react'
import api from 'services/api'
import useRegistration from 'pages/Registration/hooks/useRegistration'
import { validationFormSchema } from 'pages/Registration/schemas/validationForm'
import ConfirmationDialogue from 'components/ConfirmationDialogue'
import Loading from 'components/Loading'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import { keepOnlyDigits } from 'utils/formatters'
import { temporaryTheme } from 'styles/theme'
import InvisibleButton from 'components/InvisibleButton'
import { useScheduling } from 'hooks/useScheduling'
import * as SharedStyles from '../../../styles'
import * as Styles from './styles'
import { FU_LIST, HAS_PRESCRIBED_BEFORE_LIST, TYPE_OF_BOARD_LIST } from './constants'
import { getFieldError } from '../../helpers'

interface ValidationFormProps {
  onGoBack: () => void
  onValidationSuccess: () => void
}

interface CfmResponse {
  crm: string
  updatedAt: string
  name: string
  status: string
  registrationType: string
  fu: string
  expertises: {
    id: number
    name: string
    rqe: string
  }[]
}

interface PrescriberValidationResponse {
  cfmResponse: CfmResponse | string
  message: string
  success: boolean
}

export default function ValidationForm({ onGoBack, onValidationSuccess }: ValidationFormProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { push } = useHistory()
  const {
    typeOfBoard,
    boardNumber,
    boardFu,
    hasPrescribedBefore,
    typeOfBoardOther,
    changeFieldValue,
    changeSpecialties,
    errors,
    setErrors
  } = useRegistration()
  const [isAlreadyRegisteredModalOpen, setIsAlreadyRegisteredModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const onValidationFormSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const fieldsValidation = await validationFormSchema.safeParseAsync({
      typeOfBoard,
      boardNumber,
      boardFu,
      hasPrescribedBefore,
      typeOfBoardOther
    })
    if (!fieldsValidation.success) {
      setErrors(fieldsValidation.error.errors)
      return
    }
    setErrors(undefined)

    try {
      setLoading(true)
      const response = await api.post<PrescriberValidationResponse | any>(`/consult-cfm?verifyUser=true`, {
        council: typeOfBoard,
        nr_council: boardNumber,
        uf_council: boardFu,
        check_user: true
      })

      if (!response.data.success) {
        if (response.data.message === 'Usuário já cadastrado') {
          setIsAlreadyRegisteredModalOpen(true)
          return
        }
        if (response.data.cfmResponse === 'Irregular') {
          toast.error('Os dados do prescritor estão irregulares. É necessário regularizá-lo para continuar.')
          return
        }
        if (response.data.result) {
          toast.success('Este prescritor já possui cadastro na Cannect.')
          return
        }
      }
      if (!response.data.cfmResponse) {
        onValidationSuccess()
        return
      }
      const cfmResponse = response.data.cfmResponse as CfmResponse
      changeFieldValue('name', cfmResponse.name)
      changeSpecialties(
        cfmResponse.expertises.map((expertise) => ({
          value: String(expertise.id),
          label: expertise.name,
          rqe: expertise.rqe
        }))
      )
      onValidationSuccess()
    } catch (err) {
      toast.error('Houve um erro ao tentar validar o profissional da saúde. Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const handleGoToLogin = () => {
    push('/login')
  }
  const ValidationTypeOfBoard =
    typeOfBoard === 'CRM' ||
    typeOfBoard === 'CRO' ||
    typeOfBoard === 'CRMV' ||
    typeOfBoard === 'CREFITO' ||
    typeOfBoard === 'CRBM' ||
    typeOfBoard === 'RMS'

  const validatioNPrefix = typeOfBoard === 'CRM' && boardFu === 'RJ'

  return (
    <Styles.ValidationFormContainer onSubmit={onValidationFormSubmit}>
      <Loading loading={loading} />
      <Styles.WhyWeNeedTheseData>
        Por que precisamos desses dados?{' '}
        <Tooltip
          title="Esses dados são necessários para validar se os profissioanis cadastrados em nossa base estão aptos a realizar
      prescrições segundo seus conselhos regionais e federais"
          trigger={isMobile ? 'click' : 'hover'}
          placement="top"
          style={{ zIndex: 1100 }}>
          <MdInfoOutline />
        </Tooltip>
      </Styles.WhyWeNeedTheseData>

      <SharedStyles.InputsContainer>
        <Select
          canSearch
          id="type-of-board"
          label="Tipo de conselho*"
          options={TYPE_OF_BOARD_LIST}
          value={typeOfBoard}
          errorMessage={getFieldError('typeOfBoard', errors)}
          onChange={(newValue) => changeFieldValue('typeOfBoard', newValue)}
        />
        <Select
          canSearch
          id="board-fu"
          label="UF conselho*"
          options={FU_LIST.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))}
          value={boardFu}
          errorMessage={getFieldError('boardFu', errors)}
          onChange={(newValue) => changeFieldValue('boardFu', newValue)}
        />
        <Input
          id="board-number"
          label="Número conselho*"
          placeholder="Número do conselho"
          value={boardNumber}
          errorMessage={getFieldError('boardNumber', errors)}
          onChange={(event) => changeFieldValue('boardNumber', keepOnlyDigits(event.target.value))}
          prefix={validatioNPrefix ? '52 -' : undefined}
        />
      </SharedStyles.InputsContainer>
      {typeOfBoard === 'OTHER' && (
        <SharedStyles.InputsContainer style={{ marginTop: '1rem' }}>
          <Input
            id="type-of-board-other"
            label="Informe o tipo de conselho*"
            placeholder="Tipo de conselho"
            value={typeOfBoardOther}
            errorMessage={getFieldError('typeOfBoardOther', errors)}
            onChange={(event) => changeFieldValue('typeOfBoardOther', event.target.value)}
          />
          <Styles.InvisibleContainer />
          <Styles.InvisibleContainer />
        </SharedStyles.InputsContainer>
      )}
      {ValidationTypeOfBoard && (
        <SharedStyles.InputsContainer style={{ marginTop: '1rem' }}>
          <Select
            id="has-prescribed-before"
            label="Já prescreveu cannabis medicinal?*"
            value={hasPrescribedBefore}
            onChange={(newValue) => changeFieldValue('hasPrescribedBefore', newValue)}
            errorMessage={getFieldError('hasPrescribedBefore', errors)}
            options={HAS_PRESCRIBED_BEFORE_LIST}
          />
          <Styles.InvisibleContainer />
          <Styles.InvisibleContainer />
        </SharedStyles.InputsContainer>
      )}
      <SharedStyles.ConfirmRegistrationContainer>
        <Button variant="outline" type="button" onClick={onGoBack}>
          Voltar
        </Button>
        <Button type="submit">Continuar</Button>
      </SharedStyles.ConfirmRegistrationContainer>
      <br />

      <Loading loading={loading} />
      <ConfirmationDialogue
        isOpened={isAlreadyRegisteredModalOpen}
        title="Parece que você já possui uma conta na Cannect!"
        description={
          <p>
            Os dados inseridos já estão cadastros na Cannect.{' '}
            <InvisibleButton
              type="button"
              style={{ display: 'inline-block', textDecoration: 'underline' }}
              color={temporaryTheme.colors.link}
              onClick={handleGoToLogin}>
              Clique aqui
            </InvisibleButton>{' '}
            para ser redirecionado para a página de login.
          </p>
        }
        confirmationButtonText="FECHAR"
        confirmationOnlyDialogue
        onConfirmationClick={() => setIsAlreadyRegisteredModalOpen(false)}
        onRequestClose={() => setIsAlreadyRegisteredModalOpen(false)}
      />
    </Styles.ValidationFormContainer>
  )
}
