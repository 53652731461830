/* eslint-disable react/function-component-definition */
import { useScheduling } from 'hooks/useScheduling'
import { useFormContext } from 'react-hook-form'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { IoCalendarClearOutline } from 'react-icons/io5'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { formatBrazilianDate } from 'utils/formatBrazilianDate'

export const DateSelector = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    color: #3d3d3d;
  }
`

const DateNavigation = () => {
  const { availableDatesState, getPrescribers, setPrescribersList, loading, loadingPrescribers } = useScheduling()
  const { watch, setValue } = useFormContext()
  const scheduling_date = watch('scheduling_date')
  const currentFilter = watch('filter')

  const availableDatesFiltered = availableDatesState
    .map(date => new Date(date).toISOString().split('T')[0])
    .filter((date, index, self) => self.indexOf(date) === index)

  const getPreviousDate = (currentDate: string, availableDates: string[]) => {
    const currentIndex = availableDates.indexOf(currentDate)
    return currentIndex > 0 ? availableDates[currentIndex - 1] : null
  }

  const getNextDate = (currentDate: string, availableDates: string[]) => {
    const currentIndex = availableDates.indexOf(currentDate)
    return currentIndex < availableDates.length - 1 ? availableDates[currentIndex + 1] : null
  }

  const previousDate = getPreviousDate(scheduling_date, availableDatesFiltered)
  const nextDate = getNextDate(scheduling_date, availableDatesFiltered)

  const handleSelectDate = (date: string) => {
    setPrescribersList([])
    setValue('scheduling_time', null)
    setValue('scheduling_date', date)
    setValue('prescriber', null)
    getPrescribers(date, currentFilter)
  }

  const handleSelectPreviousDate = () => {
    if (previousDate) {
      handleSelectDate(previousDate)
    }
  }

  const handleSelectNextDate = () => {
    if (nextDate) {
      handleSelectDate(nextDate)
    }
  }

  return (
    <DateSelector>
      <BsFillArrowLeftCircleFill
        onClick={handleSelectPreviousDate}
        size={22}
        color={!previousDate || loading || loadingPrescribers ? '#C9C5B8' : '#7D755F'}
        style={{
          cursor: !previousDate || loading || loadingPrescribers ? 'not-allowed' : 'pointer'
        }}
      />
      <span>
        <IoCalendarClearOutline color={temporaryTheme.colors.sitePrimary} size={22} />
        {formatBrazilianDate(scheduling_date)}
      </span>
      <BsFillArrowRightCircleFill
        onClick={handleSelectNextDate}
        size={22}
        color={!nextDate || loading || loadingPrescribers ? '#C9C5B8' : '#7D755F'}
        style={{
          cursor: !nextDate || loading || loadingPrescribers ? 'not-allowed' : 'pointer'
        }}
      />
    </DateSelector>
  )
}

export default DateNavigation
