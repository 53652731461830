export default {
  fadeInUp: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.4 }
  },
  slideInRight: {
    initial: { x: '100%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '100%', opacity: 0 },
    transition: {
      type: 'spring',
      stiffness: 90,
      damping: 10,
      duration: 0.4
    }
  },
  counter: {
    spring: {
      type: 'spring',
      damping: 10,
      stiffness: 300,
      y: { duration: 0.15 },
      opacity: { duration: 0.15 },
      scale: { duration: 0.12 },
      filter: { duration: 0.15 }
    },
    slideUp: {
      y: '6px',
      opacity: 0.25,
      scale: 0.95,
      filter: 'blur(1px)'
    },
    slideDown: {
      y: '-6px',
      opacity: 0.25,
      scale: 0.95,
      filter: 'blur(1px)'
    }
  }
}
