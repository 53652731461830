import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'

import { cn } from '@cannect/lib/utils'
import { forwardRef, ComponentPropsWithoutRef, ElementRef, Fragment } from 'react'
import { tv } from 'tailwind-variants'
import { LuChevronDown } from 'react-icons/lu'
import { Link, TLinkProps } from '../Link'
import { Sheet } from '..'

const Menu = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Root>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={cn('relative z-10 flex max-w-max flex-1 items-center justify-center', className)}
    {...props}>
    {children}
  </NavigationMenuPrimitive.Root>
))
Menu.displayName = NavigationMenuPrimitive.Root.displayName

const MenuList = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.List>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={cn('group flex flex-1 list-none items-center justify-center gap-1', className)}
    {...props}
  />
))
MenuList.displayName = NavigationMenuPrimitive.List.displayName

const MenuItem = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Item>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Item>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Item ref={ref} className={cn('relative', className)} {...props} />
))
MenuItem.displayName = NavigationMenuPrimitive.Item.displayName

const navigationMenuTriggerStyle = tv({
  base: 'group inline-flex h-auto w-max items-center justify-center gap-[4px] border-transparent bg-transparent font-sans text-base font-medium text-gray-50 underline-offset-4 transition-all disabled:pointer-events-none disabled:opacity-50 data-[active]:font-semibold data-[active]:underline'
})

const MenuTrigger = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> & { hideDropdownArrow?: boolean }
>(({ className, children, hideDropdownArrow = false, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={cn(navigationMenuTriggerStyle(), 'group', className)}
    {...props}>
    {children}
    {!hideDropdownArrow && (
      <LuChevronDown
        className="relative top-[1px] h-5 w-5 transition duration-200 group-data-[state=open]:rotate-180"
        aria-hidden="true"
      />
    )}
  </NavigationMenuPrimitive.Trigger>
))
MenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

const MenuLink = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Link>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link> & TLinkProps & { withSheetClose?: boolean }
>(({ className, children, isExternal, to, onClick, ...props }, ref) => {
  const [SheetCloseWrapper, shetCloseWrapperProps] = props.withSheetClose
    ? [Sheet.Close, { asChild: true }]
    : [Fragment, {}]
  return (
    <NavigationMenuPrimitive.Link
      ref={ref}
      className={cn(navigationMenuTriggerStyle({ className }), 'hover-underline-animation')}
      {...props}>
      <SheetCloseWrapper {...shetCloseWrapperProps}>
        <Link isExternal={isExternal} to={to ?? '#'} onClick={onClick} className="flex items-center gap-2">
          {children}
        </Link>
      </SheetCloseWrapper>
    </NavigationMenuPrimitive.Link>
  )
})
MenuLink.displayName = NavigationMenuPrimitive.Link.displayName

const MenuContent = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={cn(
      'left-0 top-full z-10 mt-1 flex w-full flex-col items-start gap-2 rounded text-gray-50 data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 xl:absolute xl:w-auto xl:bg-gray-50 xl:p-4 xl:text-primary-700 xl:shadow-lg',
      className
    )}
    {...props}
  />
))
MenuContent.displayName = NavigationMenuPrimitive.Content.displayName

const MenuIndicator = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className={cn(
      'top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in',
      className
    )}
    {...props}>
    <div className="bg-border relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm shadow-md" />
  </NavigationMenuPrimitive.Indicator>
))
MenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName

export { navigationMenuTriggerStyle, Menu, MenuList, MenuItem, MenuContent, MenuTrigger, MenuLink, MenuIndicator }
