import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { DESKTOP_WIDTH, MAX_PAGE_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  max-width: ${MAX_PAGE_WIDTH};
  width: 100%;

  @media ${DESKTOP_WIDTH} {
    padding: 0 ${temporaryTheme.spacing.space4};
  }

  @media ${TABLET_WIDTH} {
    padding: 0 ${temporaryTheme.spacing.space4};
  }

  @media ${MOBILE_WIDTH} {
    padding: 0 ${temporaryTheme.spacing.space4};
  }
`
export const HeaderSection = styled.div`
  margin-bottom: 98px;
  width: 100%;
`
