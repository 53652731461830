import { FactoryArg } from 'imask'

export type TMasks =
  | 'phone'
  | 'cep'
  | 'cnpj'
  | 'creditCard'
  | 'cardValidDate'
  | 'cpf'
  | 'onlyNumbers'
  | 'rg'
  | 'DayMonthYear'
  | 'MonthYear'

export const formMasks: Record<TMasks, FactoryArg[]> = {
  phone: [
    {
      mask: '(00) 00000-0000',
      overwrite: true
    }
  ],
  cep: [
    {
      mask: '00000-000',
      overwrite: true
    }
  ],
  cnpj: [
    {
      mask: '00.000.000/0000-00',
      overwrite: true
    }
  ],
  cpf: [
    {
      mask: '000.000.000-00',
      overwrite: true
    }
  ],
  creditCard: [
    {
      mask: '0000 0000 0000 0000',
      overwrite: true
    }
  ],
  cardValidDate: [
    {
      mask: 'MM/YY',
      overwrite: true
    }
  ],
  onlyNumbers: [
    {
      mask: Number,
      overwrite: true
    }
  ],
  DayMonthYear: [
    {
      mask: '00/00/0000',
      overwrite: true
    }
  ],
  MonthYear: [
    {
      mask: '00/0000',
      overwrite: true
    }
  ],
  rg: [
    {
      mask: '00.000.000-0',
      overwrite: true
    }
  ]
}
