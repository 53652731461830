import { svgEduca } from 'assets/svg'
import Button from 'components/Button'
import FilterBy from 'components/CannectEducate/EducateAside/FilterBy'
import Text from 'components/Text'
import { ReactNode } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import { Link } from 'react-router-dom'
import { drCannabisEducate } from '../../../assets/img'
import * as Style from './styles'

interface EducateAsideProps {
  children?: ReactNode
}

export default function EducateAside({ children }: EducateAsideProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Style.EducateAsideContainer isDesktop={!isMobile}>
      <div className="header">
        <div className="banner">
          <img src={svgEduca} alt="Cannect Educa" />
          <p>
            Oferecemos cursos, podcasts, artigos e workshops para você conhecer sobre a cannabis medicinal e seus
            benefícios a seus pacientes.
          </p>
        </div>
        <div className="drCannabis">
          <div className="textBt">
            <Text type="lg" color="white" bold>
              O que você diz quando seu paciente pergunta sobre Canabidiol?
            </Text>
            <Link to={{ pathname: 'https://cmz.doutorc.com.br/' }} target="_blank">
              <Button
                style={{ backgroundColor: 'transparent', color: 'white', border: '1px solid white', marginTop: '1rem' }}
                outlined
              >
                Conheça o DrC. Academy
              </Button>
            </Link>
          </div>
          <div className="logo">
            <img src={drCannabisEducate} alt="Dr. Cannabis Academy" />
          </div>
        </div>
      </div>
      <div className="filter">{children && <FilterBy>{children}</FilterBy>}</div>
    </Style.EducateAsideContainer>
  )
}
