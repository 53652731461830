import styled from 'styled-components'

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.25rem;
    width: 100%;
    text-align: center;

    border-bottom: 1px solid #5b945b;
    padding-bottom: 0.25rem;
    margin-bottom: 0.25rem;
  }

  p {
    margin-top: 1.25rem;
    font-weight: 300;
    line-height: 1.65rem;

    text-align: center;
  }
`
