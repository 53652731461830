import React from 'react'
import { Button } from 'design-cannect'
import { Container } from './styles'
import AccountHeader from '../AccountComponent/AccountHeader'
import Text from '../Text'
import DemandStatus from './DemandStatus'
import CardDetails from './CardDetails'

function DemandDetailsComponent() {
  return (
    <Container>
      <AccountHeader title="Detalhes do pedido" />
      <div className="header">
        <Text type="md" color="tertiary">
          Pedido realizado em 10 de dezembro de 2021
        </Text>
        <Text type="md" color="tertiary">
          |
        </Text>
        <Text type="md" color="tertiary">
          Número do pedido 929-293-891
        </Text>
        <Button outlined>Recibo</Button>
      </div>
      <DemandStatus />
      <CardDetails />
    </Container>
  )
}

export default DemandDetailsComponent
