import Button from 'new-components/Button'
import { useConsultation } from 'pages/MyQueries/hooks/useConsultation'
import { NPSProps } from 'pages/MyQueries/types/appointment'
import { useState } from 'react'
import { toast } from 'react-toastify'
import * as Styles from './styles'

const evalueteValues = Array.from({ length: 10 }, (_, index) => index + 1)

interface EvalueteQueryProps {
  prescriber_name: string
  appointment_id: number
  onClose: () => void
  nps_values: NPSProps
}

export function EvalueteQuery({ prescriber_name, appointment_id, onClose, nps_values }: EvalueteQueryProps) {
  const [rate, setRate] = useState(nps_values.rate)
  const [comment, setComment] = useState(nps_values.comment)
  const { rateConsultation } = useConsultation()

  const handleRate = async () => {
    if (rate) {
      const resultRate = await rateConsultation(appointment_id, rate, comment)

      if (resultRate?.success) {
        toast.success('Consulta avaliada com sucesso.')
      } else {
        toast.error(resultRate.message)
      }

      onClose()
    }
  }

  return (
    <Styles.EvalueteWrapper>
      <Styles.Heading>
        Avaliação de consulta <br />
        <span> {prescriber_name}</span>
      </Styles.Heading>

      <Styles.EvalueteBox>
        <h4 className="evaluete_title">
          Com base na sua experiência durante a consulta, em uma escala de 0 a 10, o quanto você avaliaria o atendimento
          recebido?
        </h4>
        <Styles.EvalueteNumberList>
          {evalueteValues.map(item => (
            <Styles.EvalueteNumber key={item} onClick={() => setRate(item)} isActive={rate === item}>
              {item}
            </Styles.EvalueteNumber>
          ))}
        </Styles.EvalueteNumberList>
        <Styles.EvalueteTextBox>
          <Styles.EvalueteText
            value={comment}
            placeholder="Algum comentário adicional? Escreva aqui."
            onChange={e => setComment(e.target.value)}
          />
        </Styles.EvalueteTextBox>
      </Styles.EvalueteBox>
      <Styles.EvalueteActionBox>
        <Button background="green" disabled={!rate} onClick={handleRate}>
          Avaliar consulta
        </Button>
      </Styles.EvalueteActionBox>
    </Styles.EvalueteWrapper>
  )
}
