import styled, { css } from 'styled-components'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

interface ImageProps {
  isLoaded: boolean
}

export const Wrapper = styled.main`
  width: 300px;
  height: 283px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const Image = styled.img<ImageProps>`
  ${({ isLoaded }) => css`
    display: ${!isLoaded ? 'none' : 'inline'};
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media ${TABLET_WIDTH} {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    @media ${MOBILE_WIDTH} {
      width: 100%;
      height: 250px;
      object-fit: fill;
    }
  `}
`

export const ImageLoad = styled.img<ImageProps>`
  ${({ isLoaded }) => css`
    display: ${isLoaded ? 'none' : 'inline'};
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(5px);

    @media ${TABLET_WIDTH} {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    @media ${MOBILE_WIDTH} {
      width: 100%;
      height: 250px;
      object-fit: fill;
    }
  `}
`
