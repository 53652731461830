import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useFormContext } from 'react-hook-form'
import { Consultation, FullPrescriber } from 'pages/MyQueries/types/appointment'

interface QueriesContextData {
  getAvailableDates: (dateTime: string, prescriber_id: string | number) => Promise<void>
  availableDatesState: string[]
  getDates: (prescriber_id: string | number) => void
  loadingPrescribers: boolean
  loading: boolean
  prescriberId: number | string
  setPrescriberId: any
  availableTime: string[]
  handleAvailableTimes: (times: string[]) => void
  prescriberData?: FullPrescriber
  resetDataContext: () => void
  consultations: Consultation[]
  updateConsultationList: (consultations: Consultation[]) => void
  loadingConsultation: boolean
  getConsultationList: ({
    limit,
    filterDate,
    search,
    page
  }: {
    limit?: number
    filterDate?: string
    search?: string
    page: number
  }) => void
  handleReloadList: () => void
  reloadList: boolean
  count: number
  loadingConsult: { loadList: boolean; loadReschedule: boolean; loadCancel: boolean }
  handleLoading: (type: 'loadReschedule' | 'loadList' | 'loadCancel', value: boolean) => void
}

const QueriesContext = createContext<QueriesContextData>({} as QueriesContextData)

interface QueriesProviderProps {
  children: ReactNode
}

export function QueriesProvider({ children }: QueriesProviderProps) {
  const [loading, setLoading] = useState(false)
  const [loadingConsult, setLoadingConsult] = useState({ loadList: false, loadReschedule: false, loadCancel: false })
  const [availableDatesState, setAvailableDatesState] = useState<string[]>([])
  const [availableTime, setAvailableTime] = useState<string[]>([])
  const [loadingPrescribers, setLoadingPrescribers] = useState(false)
  const [loadingConsultation, setLoadingConsultation] = useState(false)
  const [reloadList, setReloadList] = useState(false)
  const [prescriberId, setPrescriberId] = useState<number | string>('')
  const [prescriberData, setPrescriberData] = useState()
  const [consultations, setConsultations] = useState([] as Consultation[])
  const { setValue } = useFormContext()
  const [count, setCount] = useState(0)

  const updateConsultationList = (consultations: Consultation[]) => {
    setConsultations(consultations)
  }

  const handleReloadList = () => {
    setReloadList(!reloadList)
  }

  const resetDataContext = () => {
    setValue('queries_date', null)
    setValue('queries_time', null)
    handleAvailableTimes([])
  }

  const getAvailableDates = async (dateTime: string, prescriber_id: string | number) => {
    try {
      setLoadingPrescribers(true)
      const response = await api.get(`/scheduling/availability`, {
        params: {
          dateTime,
          prescriber: prescriber_id
        }
      })

      if (response.data.success) {
        setAvailableTime(response.data?.prescribers[0]?.availableHours)
        setPrescriberData(response.data?.prescribers[0])
        const time = response.data?.prescribers[0]?.availableHours[0]
        setValue('queries_time', time)
      }
    } catch (e) {
      toast.error('Houve um erro ao listar as datas disponíveis. Tente novamente.')
      setAvailableDatesState([])
    } finally {
      setLoadingPrescribers(false)
    }
  }

  const getDates = async (prescriber_id: string | number) => {
    try {
      setLoading(true)
      const { data } = await api.get(`/scheduling/availability/prescriber/${prescriber_id}?origin_scheduling=1`)
      if (data.success) {
        const sortedAvailableDates = data.availableDates.sort()
        setAvailableDatesState(sortedAvailableDates)
        getAvailableDates(sortedAvailableDates[0], prescriber_id)
        setValue('queries_date', sortedAvailableDates[0])
      }
    } catch (e) {
      toast.error('Houve um erro ao listar as datas disponíveis. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handleAvailableTimes = (times: string[]) => {
    setAvailableTime(times)
  }

  const getConsultationList = async ({
    limit = 6,
    filterDate,
    search,
    page
  }: {
    limit?: number
    filterDate?: string
    search?: string
    page?: number
  }) => {
    setLoadingConsultation(true)
    try {
      const params = {
        limit,
        filterDate,
        search,
        page,
        sortBy: '-date'
      }
      const { data } = await api.get('/scheduling/user/appointment', {
        params
      })

      if (data.success) {
        setConsultations(data?.appointments?.rows)
        setCount(Math.ceil(data.appointments.count / limit))
      }
    } catch (e) {
      toast.error('Erro ao buscar a lista de consultas.')
    } finally {
      setLoadingConsultation(false)
    }
  }

  const handleLoading = (type: 'loadReschedule' | 'loadList' | 'loadCancel', value: boolean) => {
    setLoadingConsult((state) => ({ ...state, [type]: value }))
  }

  const contextValue = useMemo(
    () => ({
      availableDatesState,
      getAvailableDates,
      getDates,
      loadingPrescribers,
      loading,
      prescriberId,
      setPrescriberId,
      availableTime,
      handleAvailableTimes,
      prescriberData,
      resetDataContext,
      consultations,
      updateConsultationList,
      loadingConsultation,
      getConsultationList,
      handleReloadList,
      reloadList,
      loadingConsult,
      count,
      handleLoading
    }),
    [
      availableTime,
      availableDatesState,
      getAvailableDates,
      getDates,
      loadingPrescribers,
      loading,
      prescriberId,
      setPrescriberId,
      handleAvailableTimes,
      prescriberData,
      resetDataContext,
      consultations,
      updateConsultationList,
      getConsultationList,
      loadingConsultation,
      handleReloadList,
      reloadList,
      count,
      loadingConsult,
      handleLoading
    ]
  )

  return <QueriesContext.Provider value={contextValue}>{children}</QueriesContext.Provider>
}

export function useQueries(): QueriesContextData {
  const context = useContext(QueriesContext)

  if (!context) {
    throw new Error('useQueries must be used within a QueriesProvider')
  }

  return context
}
