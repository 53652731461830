import { MOBILE_WIDTH } from 'utils/constants'
import styled from 'styled-components'

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  @media ${MOBILE_WIDTH} {
    max-width: 99%;
    overflow-x: auto;
  }
`
