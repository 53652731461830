import BreadCrumb from 'components/BreadCrumb'
import AccordionPartnes from 'new-components/partnesComponents/AccordionPartnes'
import { PatientDataFaq, ProfessionalFaq } from './dataFaq'
import * as Styles from './styles'
import { TabsPrimitive, Typography } from '@cannect/new-components/atoms'

function CommonQuestions() {
  return (
    <Styles.Wrapper>
      <BreadCrumb paths={[{ label: 'FAQ', link: `/perguntas-frequentes` }]} />

      <Typography.Heading type="headingThree" className="mt-12 text-primary-700 lg:mt-0">
        <span>Perguntas Frequentes</span> Cannect
      </Typography.Heading>

      <Typography.Text className="my-5 pb-12">
        Tire todas as suas dúvidas sobre canabinóides e entenda como pode mudar a sua vida.
      </Typography.Text>

      <div className="mb-10 flex w-full items-center justify-center">
        <TabsPrimitive.Root defaultValue="patients" className="flex w-full flex-col items-center">
          <TabsPrimitive.List className="mb-[32px] lg:mb-[24px]">
            <TabsPrimitive.Trigger
              className="m-[1px] rounded-md px-4 py-2 text-sm font-bold text-secondary-800 lg:my-[2px] lg:text-base"
              value="patients">
              Para pacientes
            </TabsPrimitive.Trigger>
            <TabsPrimitive.Trigger
              className="m-[1px] rounded-md px-4 py-2 text-sm font-bold text-secondary-800 lg:my-[2px] lg:text-base"
              value="prescribers">
              Para profissionais da saúde
            </TabsPrimitive.Trigger>
          </TabsPrimitive.List>
          <TabsPrimitive.Content value="patients" className="w-full">
            <AccordionPartnes data={PatientDataFaq.accordionItems} withPrefix={false} size="full" />
          </TabsPrimitive.Content>
          <TabsPrimitive.Content value="prescribers" className="w-full">
            <AccordionPartnes data={ProfessionalFaq.accordionItems} withPrefix={false} size="full" />
          </TabsPrimitive.Content>
        </TabsPrimitive.Root>
      </div>
    </Styles.Wrapper>
  )
}

export default CommonQuestions
