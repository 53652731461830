import { theme } from 'design-cannect'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 1rem;
  }

  .voltar-home {
    background-color: ${theme.colors.tertiary};
    border: none;
    border-radius: 10px;
    padding: 1rem 3rem;
    margin-top: 2rem;

    a {
      text-decoration: none;
      color: #ecece2;
    }
  }
`
