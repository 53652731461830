import React, { useState, useEffect } from 'react'
import BreadCrumb from 'components/BreadCrumb'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import BadgeTreatmentType from '@cannect/new-components/TreatmentsComponents/BadgeTreatmentType'
import { BadgesTreatmentsWellBeing } from './mockTreatment'
import * as Styles from './styles'
import strapi from '@cannect/services/strapi'
import { AxiosResponse } from 'axios'

function Nutraceuticos() {
  const [data, setData] = useState(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result: AxiosResponse = await strapi.get(`/api/nutraceuticos/1?populate=*`)
        setData(result.data.data)
      } catch (error) {
        console.error('Error fetching data: ', error)
      }
    }

    fetchData()
  }, [])

  if (!data) {
    return <div>Loading...</div>
  }

  const { Title, Paragrafo2, Paragrafo3, Paragrafo4, Paragrafo5, Paragrafo6, Paragrafo7 } = data.attributes || {}

  return (
    <Styles.Wrapper>
      <BreadCrumb paths={[{ label: 'Nutracêuticos', link: `/nutraceuticos` }]} />
      <Styles.ListIcons>
        {BadgesTreatmentsWellBeing.map(item => (
          <BadgeTreatmentType icon={item.icon} title={item.title} slug={item.slug} key={item.id} />
        ))}
      </Styles.ListIcons>

      <Styles.SectionMedicalTreatments>
        {Title && (
          <Heading elementType="h2">
            <span>{Title}</span>
          </Heading>
        )}

        {[Paragrafo2, Paragrafo3, Paragrafo4, Paragrafo5, Paragrafo6, Paragrafo7].map(
          paragraph =>
            paragraph?.Titulo && (
              <div key={paragraph.Titulo}>
                <Heading elementType="h2" fontSize="1.5625rem">{paragraph.Titulo}</Heading>
                <TextDanger text={paragraph.Sobre || paragraph.Conteudo} />
              </div>
            )
        )}
      </Styles.SectionMedicalTreatments>

      <ModalHome isOpen={modal} setOpenModal={setModal}>
        <BeginCheckout />
      </ModalHome>
    </Styles.Wrapper>
  )
}

export default Nutraceuticos
