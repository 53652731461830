import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const PersonalDataContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background: #f8f8f8;
  padding: 2rem;
  border-radius: 0.5rem;

  width: 100%;
  height: fit-content;

  h2 {
    font-weight: 600;
  }
`

export const FieldsWrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const PreviewRecipeContainer = styled.div<any>`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const ContainerLeft = styled.div``
export const ContainerRight = styled.div`
  overflow-x: scroll;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 36px;
  color: #3d3d3d;
`

export const Content = styled.div``

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;

  background: #f5f7fb;
  border-radius: 6.3466px;
  padding: 1.5rem 0rem 1.5rem 1.4rem;

  width: 350px;
  height: 156.13px;
  cursor: pointer;

  margin-top: 1rem;
  margin-bottom: 4rem;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const ButtonUpload = styled.button`
  border: none;
  background-color: transparent;
  height: 100%;
`

export const ContentUpload = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;

  h3 {
    font-weight: 400;
    color: #3d3d3d;
    font-size: 15px;
  }

  h4 {
    margin-top: 1rem;
    font-weight: 300;
    color: #3d3d3d;
    font-size: 15px;
  }

  span {
    margin-top: 1rem;
    font-weight: 200;
    font-size: 13px;
    color: #3d3d3d;
  }

  @media ${MOBILE_WIDTH} {
    h3 {
      font-size: 14px;
    }
    h4 {
      font-size: 14px;
    }
  }
`

export const SelectLogoPosition = styled.select`
  width: 100%;
  max-width: 250px;
  height: 45px;
  margin-top: 1rem;

  background: #ffffff;
  border: 2.25331px solid #d9d9d9;
  border-radius: 5.57017px;

  padding-left: 1rem;
  color: #3d3d3d;
  font-weight: 400;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const LogoContainer = styled.div`
  height: 180px;
  width: 200px;
  display: flex;
  margin-bottom: 4rem;

  justify-content: center;
  align-items: center;

  border: 2px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;

  img {
    object-fit: cover;
    max-height: 180px;
    max-width: 200px;
  }
`

export const ContainerButtons = styled.div`
  margin: 42px 0 24px 0;
  display: flex;
  justify-content: end;
  gap: 15px;
  width: 100%;
`
export const CancelButton = styled.button`
  background: #8f8f8f;
  width: 138.79px;
  height: 40px;
  border-radius: 8px;
  border: none;
  color: white;
`
export const SaveButton = styled.button`
  background: #21976e;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  border: none;
  color: white;
`
