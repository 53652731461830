import Button from 'new-components/Button'
import { FormEvent, ReactNode, useMemo, useRef, useState } from 'react'
import { FiChevronDown, FiCheck } from 'react-icons/fi'
import { keepOnlyDigits } from 'utils/formatters'
import * as Styles from './styles'

interface SelectProps {
  label: string | ReactNode
  id: string
  options: {
    label: string
    value: string
  }[]
  value?: string
  onChange: (newValue: string) => void
  selectedOptionSufix?: string
  width?: string
  maxWidth?: string
  placeholder?: string
  errorMessage?: string
  disabled?: boolean
}

export default function SelectWithInput({
  id,
  label,
  options,
  value,
  onChange,
  placeholder = 'selecione',
  width,
  selectedOptionSufix = '',
  maxWidth,
  errorMessage,
  disabled = false
}: SelectProps) {
  const fakeSelectRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const selectedOption = useMemo(() => {
    return options.find(option => option.value === value)
  }, [options, value])

  const onOptionClick = (newValue: string) => {
    onChange(newValue)
    setIsOpen(false)
  }

  const handleInputChangeSubmit = (event: FormEvent) => {
    event.preventDefault()
    onChange(inputValue)
    setIsOpen(false)
  }

  return (
    <Styles.SelectContainer style={{ width: width ?? '100%', maxWidth: maxWidth ?? '100%' }}>
      {isOpen && <Styles.SelectBackground onClick={() => setIsOpen(false)} />}
      {typeof label === 'string' ? <label htmlFor={id}>{label}</label> : label}

      <Styles.FakeSelect
        type="button"
        hasError={errorMessage !== undefined && errorMessage.length > 0}
        onClick={() => setIsOpen(state => !state)}
        disabled={disabled}
        ref={fakeSelectRef}
      >
        <span className={value === undefined ? 'fake-select-placeholder' : ''}>
          {value !== undefined ? `${value} ${selectedOptionSufix}` : placeholder}
        </span>
        <FiChevronDown
          style={{
            transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
            transition: 'transform 150ms linear'
          }}
        />
      </Styles.FakeSelect>
      {errorMessage !== undefined && errorMessage.length > 0 && (
        <Styles.InputErrorMessage>{errorMessage}</Styles.InputErrorMessage>
      )}

      {isOpen && (
        <Styles.FakeSelectOptionsContainer
          top={fakeSelectRef.current?.getBoundingClientRect().top ?? 0}
          left={fakeSelectRef.current?.getBoundingClientRect().left ?? 0}
          maxWidth={fakeSelectRef.current?.getBoundingClientRect().width ?? 0}
        >
          {options.map(option => {
            const isOptionSelected = selectedOption?.value === option.value
            return (
              <Styles.FakeSelectOption
                isSelected={isOptionSelected}
                key={option.value}
                onClick={() => onOptionClick(option.value)}
                type="button"
              >
                <span>{option.label}</span>
                {isOptionSelected && <FiCheck />}
              </Styles.FakeSelectOption>
            )
          })}
          <Styles.SelectInputContainer onSubmit={handleInputChangeSubmit}>
            <input
              type="text"
              placeholder="informe a quantidade"
              value={inputValue}
              onChange={event => setInputValue(keepOnlyDigits(event.target.value))}
            />
            <Button background="green" type="submit" style={{ color: '#ffffff' }}>
              APLICAR
            </Button>
          </Styles.SelectInputContainer>
        </Styles.FakeSelectOptionsContainer>
      )}
    </Styles.SelectContainer>
  )
}
