import { Tooltip } from 'antd'
import { IconePacote, Truck } from 'assets/svg'
import useMediaQuery from 'hooks/useMediaQuery'
import { FiHelpCircle } from 'react-icons/fi'
import { MOBILE_WIDTH } from 'utils/constants'
import { OrderPackage as OrderPackageType } from '../../../../../../types'
import StatusIndicator from './components/StatusIndicator'
import * as Styles from './styles'

interface OrderPackageHeaderProps {
  package?: OrderPackageType
  packageNumber: number
}
export default function OrderPackageHeader({ package: orderPackage, packageNumber }: OrderPackageHeaderProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Styles.OrderPackageHeaderContainer>
      {orderPackage ? (
        <>
          <h3>
            <IconePacote />
            <span>Pacote {String(packageNumber).padStart(2, '0')}</span>
            <Tooltip
              title="Produtos partem da mesma origem e possuem uma transportadora designada"
              trigger={isMobile ? 'click' : 'hover'}
              placement="top"
              style={{ zIndex: 1100 }}
            >
              <FiHelpCircle className="help-icon" />
            </Tooltip>
          </h3>

          <Styles.CompanyAndStatusWrapper>
            <Styles.ShippingCompanyContainer>
              <Truck />
              <span>TRANSPORTADORA:</span>
              <strong>{orderPackage.shippingCompany}</strong>
            </Styles.ShippingCompanyContainer>

            <StatusIndicator
              label={orderPackage.packageStatus.translated_name}
              color={orderPackage.packageStatus.color}
            />
          </Styles.CompanyAndStatusWrapper>
        </>
      ) : (
        <h3>
          <span>Produtos aguardando separação</span>
          <Tooltip
            title="Estes são produtos que ainda não foram designados a nenhuma transportadora parceira"
            trigger={isMobile ? 'click' : 'hover'}
            placement="top"
            style={{ zIndex: 1100 }}
          >
            <FiHelpCircle className="help-icon" />
          </Tooltip>
        </h3>
      )}
    </Styles.OrderPackageHeaderContainer>
  )
}
