import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

const arrow = css`
  content: '';
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #c0bbbb;
  transform: rotate(45deg);
`
const top = css`
  left: ${({ position }: any) => position.left + position.width / 2}px;
  top: ${({ position }: any) => position.top - 10}px;
  transform: translate(-50%, -100%);
  margin-top: ${ifProp('fade', '0', '5px')};
  transition-property: opacity, margin-top;

  &::after {
    ${arrow};
    left: 50%;
    margin-left: -4px;
    bottom: -4px;
  }
`
const topLeft = css`
  left: ${({ position }: any) => position.left}px;
  top: ${({ position }: any) => position.top - 10}px;
  transform: translate(0, -100%);
  margin-top: ${ifProp('fade', '0', '5px')};
  transition-property: opacity, margin-top;

  &::after {
    ${arrow};
    left: 0;
    margin-left: 10px;
    bottom: -4px;
  }
`
const topRight = css`
  left: ${({ position }: any) => position.left + position.width}px;
  top: ${({ position }: any) => position.top - 10}px;
  transform: translate(-100%, -100%);
  margin-top: ${ifProp('fade', '0', '5px')};
  transition-property: opacity, margin-top;

  &::after {
    ${arrow};
    right: 0;
    margin-right: 10px;
    bottom: -4px;
  }
`

const bottom = css`
  left: ${({ position }: any) => position.left + position.width / 2}px;
  top: ${({ position }: any) => position.top + position.height + 10}px;
  transform: translate(-50%, 0);
  margin-top: ${ifProp('fade', '0', '-5px')};
  transition-property: opacity, margin-top;

  &::after {
    ${arrow};
    left: 50%;
    margin-left: -4px;
    top: -4px;
  }
`

const bottomLeft = css`
  left: ${({ position }: any) => position.left}px;
  top: ${({ position }: any) => position.top + position.height + 10}px;
  transform: translate(0, 0);
  margin-top: ${ifProp('fade', '0', '-5px')};
  transition-property: opacity, margin-top;

  &::after {
    ${arrow};
    left: 0;
    margin-left: 10px;
    top: -4px;
  }
`
const bottomRight = css`
  left: ${({ position }: any) => position.left + position.width}px;
  top: ${({ position }: any) => position.top + position.height + 10}px;
  transform: translate(-100%, 0);
  margin-top: ${ifProp('fade', '0', '-5px')};
  transition-property: opacity, margin-top;

  &::after {
    ${arrow};
    right: 0;
    margin-right: 10px;
    top: -4px;
  }
`
const left = css`
  left: ${({ position }: any) => position.left - 10}px;
  top: ${({ position }: any) => position.top + position.height / 2}px;
  transform: translate(-100%, -50%);
  margin-left: ${ifProp('fade', '0', '5px')};
  transition-property: opacity, margin-left;

  &::after {
    ${arrow};
    top: 50%;
    margin-top: -4px;
    right: -4px;
  }
`
const leftTop = css`
  left: ${({ position }: any) => position.left - 10}px;
  top: ${({ position }: any) => position.top}px;
  transform: translate(-100%, 0);
  margin-left: ${ifProp('fade', '0', '5px')};
  transition-property: opacity, margin-left;

  &::after {
    ${arrow};
    top: 0;
    margin-top: 10px;
    right: -4px;
  }
`

const leftBottom = css`
  left: ${({ position }: any) => position.left - 10}px;
  top: ${({ position }: any) => position.top + position.height}px;
  transform: translate(-100%, -100%);
  margin-left: ${ifProp('fade', '0', '5px')};
  transition-property: opacity, margin-left;

  &::after {
    ${arrow};
    bottom: 0;
    margin-bottom: 10px;
    right: -4px;
  }
`

const right = css`
  left: ${({ position }: any) => position.left + position.width + 10}px;
  top: ${({ position }: any) => position.top + position.height / 2}px;
  transform: translate(0, -50%);
  margin-left: ${ifProp('fade', '0', '-5px')};
  transition-property: opacity, margin-left;

  &::after {
    ${arrow};
    top: 50%;
    margin-top: -4px;
    left: -4px;
  }
`

const rightTop = css`
  left: ${({ position }: any) => position.left + position.width + 10}px;
  top: ${({ position }: any) => position.top}px;
  transform: translate(0, 0);
  margin-left: ${ifProp('fade', '0', '-5px')};
  transition-property: opacity, margin-left;

  &::after {
    ${arrow};
    top: 0;
    margin-top: 10px;
    left: -4px;
  }
`

const rightBottom = css`
  left: ${({ position }: any) => position.left + position.width + 10}px;
  top: ${({ position }: any) => position.top + position.height}px;
  transform: translate(0, -100%);
  margin-left: ${ifProp('fade', '0', '-5px')};
  transition-property: opacity, margin-left;

  &::after {
    ${arrow};
    bottom: 0;
    margin-bottom: 10px;
    left: -4px;
  }
`

export const Balloon = styled.div`
  position: absolute;
  z-index: 999999;
  ${(props: any) => props.placement === 'top' && top};
  ${(props: any) => props.placement === 'bottom' && bottom};
  ${(props: any) => props.placement === 'left' && left};
  ${(props: any) => props.placement === 'right' && right};
  ${(props: any) => props.placement === 'topLeft' && topLeft};
  ${(props: any) => props.placement === 'topRight' && topRight};
  ${(props: any) => props.placement === 'leftTop' && leftTop};
  ${(props: any) => props.placement === 'leftBottom' && leftBottom};
  ${(props: any) => props.placement === 'bottomLeft' && bottomLeft};
  ${(props: any) => props.placement === 'bottomRight' && bottomRight};
  ${(props: any) => props.placement === 'rightTop' && rightTop};
  ${(props: any) => props.placement === 'rightBottom' && rightBottom};
  max-width: 250px;
  width: max-content;
  padding: 6px 10px;
  background: #c0bbbb;
  color: white;
  font-size: 12px;
  border-radius: 6px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  opacity: ${ifProp('fade', '1', '0')};
  transition-duration: 0.2s;
  transition-timing-function: ease;
`
