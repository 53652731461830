import { SVGProps } from 'react'

interface CircleWavyCheckIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function CircleWavyCheckIcon({ isChecked, ...rest }: CircleWavyCheckIconProps) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M6.89541 23.0928C5.878 22.0754 6.55259 19.9411 6.03282 18.6914C5.51305 17.4418 3.52246 16.3469 3.52246 14.9646C3.52246 13.5822 5.49094 12.5316 6.03282 11.2378C6.5747 9.94387 5.878 7.85374 6.89541 6.83633C7.91283 5.81891 10.0472 6.49351 11.2968 5.97374C12.5465 5.45397 13.6413 3.46338 15.0237 3.46338C16.406 3.46338 17.4566 5.43186 18.7505 5.97374C20.0444 6.51562 22.1345 5.81891 23.1519 6.83633C24.1693 7.85374 23.4948 9.9881 24.0145 11.2378C24.5343 12.4874 26.5249 13.5822 26.5249 14.9646C26.5249 16.3469 24.5564 17.3975 24.0145 18.6914C23.4726 19.9853 24.1693 22.0754 23.1519 23.0928C22.1345 24.1103 20.0002 23.4357 18.7505 23.9554C17.5009 24.4752 16.406 26.4658 15.0237 26.4658C13.6413 26.4658 12.5907 24.4973 11.2968 23.9554C10.0029 23.4136 7.91283 24.1103 6.89541 23.0928Z"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.89 12.3115L13.3984 18.5045L10.1582 15.408"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
