import { ecossistemaCannect, graficoNumeroEcossistema } from 'assets/img'
import BreadCrumb from 'components/BreadCrumb'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { EcosystemMockTextChart, EcosystemText, ListEcosystem, SolutionsCannect } from './ecosystem-mock'
import * as Styles from './styles'

function EcosystemCannect() {
  const history = useHistory()
  const [modal, setModal] = useState(false)

  return (
    <Styles.Wrapper>
      <BreadCrumb paths={[{ label: 'Ecossistema Cannect', link: '/ecossistema-cannect' }]} />
      <Heading fontSize="30px">
        Cannect,
        <br />
        <span>o maior ecossistema de cannabis medicinal da América Latina.</span>
      </Heading>
      <TextDanger text={EcosystemMockTextChart} fontWeight={400} />

      <Styles.ChartBox>
        <Styles.ChatEcosystemImage src={graficoNumeroEcossistema} alt="Gráfico de pacientes por ano" loading="lazy" />
      </Styles.ChartBox>

      <Heading fontSize="30px">
        O ecossistema <span>Cannect</span>
      </Heading>
      <TextDanger text={EcosystemText} fontWeight={400} />
      <Styles.ListBox>
        {ListEcosystem.map((item) => (
          <Styles.ListItem key={item.id}>
            <TextDanger text={item.title} />
          </Styles.ListItem>
        ))}
      </Styles.ListBox>

      <Styles.ActionContent>
        <Button background="black" onClick={() => history.push('/agendamento')}>
          Agende sua consulta
        </Button>
        <Button background="green" isOutlined onClick={() => setModal(true)}>
          Inicie seu tratamento
        </Button>
      </Styles.ActionContent>

      <Heading fontSize="30px">
        Soluções <span>Cannect</span>
      </Heading>

      <Styles.ChartBox>
        <Styles.ChatEcosystemImage src={ecossistemaCannect} alt="ecossistema cannect" />
      </Styles.ChartBox>

      <TextDanger text={SolutionsCannect} fontWeight={400} />

      <ModalHome isOpen={modal} setOpenModal={setModal}>
        <BeginCheckout />
      </ModalHome>
    </Styles.Wrapper>
  )
}

export default EcosystemCannect
