import React, { useEffect, useState } from 'react'
import ModalFlowersInactive from '../ModalFlowersInactive'
import { usePrepareCart } from '../../hooks/usePrepareCart'
import { useHistory } from 'react-router'
import { ModalInfo } from '../ModalInfo'

function ContainerModalAnvisaAndFlowers() {
  const { lastOrderCreated, updateCart } = usePrepareCart()
  const [itensFlowerInactive, setItensFlowerInactive] = useState([])
  const [isModalFlowers, setIsModalFlowers] = useState(false)
  const [isValidateAnvisa, setIsValidateAnvisa] = useState(false)
  const history = useHistory()

  const filterFlowerInactive = () => {
    const itensFlower = lastOrderCreated?.items?.filter((item: any) => item?.product?.shape_id === 29) || []
    const itensFlowerInactive = itensFlower.filter((item: any) => item?.product?.status === 'I') || []
    const validateModalAnvisa = !isValidateAnvisa && itensFlowerInactive.length === 0 && itensFlower.length > 0
    if (validateModalAnvisa) {
      setIsValidateAnvisa(true)
      setIsModalFlowers(validateModalAnvisa)
    }
    setItensFlowerInactive(itensFlowerInactive)
  }
  useEffect(() => {
    const isPaid = lastOrderCreated?.payments?.some((item: any) => item.status === 'paid')
    if (!isPaid) filterFlowerInactive()
  }, [lastOrderCreated])

  const isModalFlowersInactive = itensFlowerInactive.length > 0

  const closeModal = () => {
    const itensUpdate = lastOrderCreated?.items?.filter((item: any) => item?.product?.status !== 'I') || []
    if (itensUpdate.length === 0) {
      history.push(`/produtos/1?translated_name=`)
      setItensFlowerInactive([])
    } else {
      updateCart(itensUpdate)
    }
    setItensFlowerInactive([])
  }

  return (
    <div>
      <ModalFlowersInactive
        closeModal={closeModal}
        isModalFlowersInactive={isModalFlowersInactive}
        itensFlowerInactive={itensFlowerInactive}
      />
      <ModalInfo isOpen={isModalFlowers} setOpenModal={setIsModalFlowers} />
    </div>
  )
}

export default ContainerModalAnvisaAndFlowers
