import { useCannectCare } from 'hooks/useCannectCare'
import { useEffect } from 'react'
import Loading from 'components/Loading'
import { logoCannectCuida } from 'assets/img'
import * as Style from './styles'
import SideEffectsSelection from './SideEffectsSelection'
import ThankYou from '../ThankYou'

export default function SideEffects() {
  const { step, loading, fetchSideEffects } = useCannectCare()

  useEffect(() => {
    fetchSideEffects()
  }, [])

  return (
    <Style.SideEffectsContainer>
      <Style.HeaderContainer>
        <img src={logoCannectCuida} alt="Cannect Cuida" />
        <h1>Efeitos Colaterais</h1>
      </Style.HeaderContainer>
      {step === 0 && <SideEffectsSelection />}
      {step === 1 && <ThankYou />}
      {loading && <Loading loading={loading} />}
    </Style.SideEffectsContainer>
  )
}
