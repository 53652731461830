/* eslint-disable no-plusplus */
import React from 'react'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'
import ProductCard from 'new-components/MarketPlace/ProductCard'
import { toast } from 'react-toastify'
import { useCannectAssistant } from 'hooks/useCannectAssistant'

type ListProductsProps = {
  products: any
  tableFormat?: any
}

function ListProducts({ products, tableFormat = 'table' }: ListProductsProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { prescription, addProductToPrescription, removeProductFromPrescription } = useCannectAssistant()

  function changeProductInclusion(product: any) {
    const productsArray = Array.isArray(product) ? product : [product]
    let addedCount = 0
    let removedCount = 0

    productsArray.forEach((item: any) => {
      const addedToPrescription = prescription.products.some(
        (productInPrescription: any) => productInPrescription.id === item.id
      )

      if (addedToPrescription) {
        removeProductFromPrescription(item.id)
        removedCount++
      } else {
        addProductToPrescription(item)
        addedCount++
      }
    })

    if (removedCount > 0) {
      toast.error(`${removedCount} produto(s) removido(s) da receita`, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      })
    }

    if (addedCount > 0) {
      toast.success(`${addedCount} produto(s) adicionado(s) à receita`, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      })
    }
  }

  return (
    <Style.Container isMobile={isMobile} isList={tableFormat}>
      <Style.ProductListContainer className="wrapper" tableFormat={tableFormat}>
        {products?.map((product: any) => {
          return (
            <ProductCard
              key={product.id}
              isAssistant
              importProduct={product => changeProductInclusion(product)}
              product={product}
              promotional={product?.promotion}
              prescribedProduct={
                !!prescription.products.some(productInPrescription => productInPrescription.id === product.id)
              }
              {...product}
            />
          )
        })}
      </Style.ProductListContainer>
    </Style.Container>
  )
}

export default ListProducts
