import styled from 'styled-components'
import { theme } from 'design-cannect'

export const PostContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 16px 32px 16px;
  gap: 1rem;
  border-radius: 20px;
  background-color: #fafafa;
  min-width: 329px;
  max-height: 275px;
  height: 275px;

  button {
    width: 100px;
    height: 24px;
    margin: 0;
    font-family: 'Nunito', sans-serif;
    color: #f0780a !important;
    font-weight: 600;
    font-size: 15px;
  }

  svg {
    font-size: 17px;
    color: #f0780a;
  }
`

export const ContentWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: ${props => (props.inLine ? '10px 0' : '')};

  div {
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    font-weight: 600;
  }

  h1 {
    font-size: 20px;
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    letter-spacing: -0.09em;
    color: ${theme.colors.darkGrey};
    :hover {
      color: ${props => (props.inLine ? theme.colors.primary : '')};
    }
  }
  max-width: ${props => (props.inLine ? '100%' : '297px')};

  img {
    width: 100%;
    max-height: 307px;
    border-radius: 1rem;
  }
`
