import { pngCannectCircleLogo } from 'assets/img'
import { Post } from 'components/CannectEducate/TabContent'
import { useTokenApi } from 'hooks/useTokenApi'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import api from 'services/api'

const IntrodutoryCourseContext = createContext({} as IntrodutoryCourseContextValues)

interface IntrodutoryCourseProviderProps {
  children: ReactNode
}

export interface Course {
  id: number
  name: string
  description: string
  status: true
  createdAt: Date
  updatedAt: Date
}

export interface Lesson {
  image: string | undefined
  id: number
  uuid: string
  lesson_number: number
  lesson_module: number
  title: string
  description: string
  url: string
  status: true
  createdAt: Date
  updatedAt: Date
  course_id: number
  teacher_id: number
  cn_teacher: {
    name: string
    biography: string
  }
}

export interface FetchCoursesResponse {
  course: Course
  courseLessons: Lesson[]
  success: boolean
}

export interface FetchAllCoursesResponse {
  success: boolean
  courses: {
    id: number
  }[]
}

export interface Teacher {
  id: number
  name: string
  biography: string
  photo_url?: string
}

interface IntrodutoryCourseContextValues {
  course?: Course
  setCourse: Dispatch<SetStateAction<Course | undefined>>
  lessons: Lesson[]
  setLessons: Dispatch<SetStateAction<Lesson[]>>
  postsAOA: Post[][]
  setPostsAOA: Dispatch<SetStateAction<Post[][]>>
  uniqueTeachers: Teacher[]
  setUniqueTeachers: Dispatch<SetStateAction<Teacher[]>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  fetchCourse: () => void
}

export function formatIntrodutoryCourses(lessons: Lesson[]): Post[] {
  const newPosts: Post[] = []
  lessons.forEach((lesson) => {
    newPosts.push({
      id: lesson.id,
      description: lesson.description,
      img: lesson.image,
      title: lesson.title,
      url: `/educa/cursos-introdutorios/${lesson.id}`,
      externalUrl: lesson.url
    })
  })

  return newPosts
}

export function extractLessonsInformations(lessons: Lesson[]): [Post[][], Teacher[]] {
  const newPostsAOA: Post[][] = []
  let newPosts: Post[] = []

  if (lessons.length === 0) return [newPostsAOA, []]

  const newUniqueTeachers: Teacher[] = [{ id: lessons[0].teacher_id, ...lessons[0].cn_teacher }]
  let previousLesson = lessons[0]

  lessons.forEach((lesson) => {
    // Posts filter
    if (lesson.lesson_module === previousLesson.lesson_module) {
      newPosts.push(fromLessonToPost(lesson))
    } else {
      newPostsAOA.push(newPosts)
      newPosts = []
      newPosts.push(fromLessonToPost(lesson))
    }

    // Teachers filter
    const teacherAlreadyAdded = newUniqueTeachers.find((teacher) => teacher.id === lesson.teacher_id)
    if (!teacherAlreadyAdded) {
      newUniqueTeachers.push({ id: lesson.teacher_id, ...lesson.cn_teacher })
    }

    previousLesson = lesson
  })
  newPostsAOA.push(newPosts)
  newUniqueTeachers.push({
    id: 5,
    biography: `A Cannect é um ecossistema digital que conecta pacientes, médicos, instituições de saúde, pesquisadores e fornecedores, sempre na busca de oferecer a melhor experiência do paciente no uso da Cannabis medicinal. Sua proposta é ser um agente disruptivo e facilitador, unindo tecnologia e conhecimento científico para democratizar o acesso aos tratamentos à base de Cannabis medicinal.
    `,
    name: 'Cannect',
    photo_url: pngCannectCircleLogo
  })

  return [newPostsAOA, newUniqueTeachers]
}

function fromLessonToPost(lesson: Lesson): Post {
  return {
    id: lesson.id,
    description: lesson.description,
    img: lesson.image,
    title: lesson.title,
    url: `/educa/cursos-introdutorios/${lesson.id}`,
    externalUrl: lesson.url
  }
}

export function IntrodutoryCourseProvider({ children }: IntrodutoryCourseProviderProps) {
  const { deleteTokenApi } = useTokenApi()

  const [course, setCourse] = useState<Course>()
  const [lessons, setLessons] = useState<Lesson[]>([])
  const [postsAOA, setPostsAOA] = useState<Post[][]>([])
  const [uniqueTeachers, setUniqueTeachers] = useState<Teacher[]>([])
  const [loading, setLoading] = useState(true)

  async function fetchCourse() {
    try {
      const allCoursesResponse = await api.get<FetchAllCoursesResponse>('/courses')

      const lastCourseId = allCoursesResponse.data.courses[allCoursesResponse.data.courses.length - 1].id

      const response = await api.get<FetchCoursesResponse>(`/course/${lastCourseId}`)
      setCourse(response.data.course)
      setLessons(response.data.courseLessons)
      const [newPostsAOA, newUniqueTeachers] = extractLessonsInformations(response.data.courseLessons)
      setPostsAOA(newPostsAOA)
      setUniqueTeachers(newUniqueTeachers)
    } catch (err: any) {
      if (err?.response?.status === 401) {
        localStorage.removeItem('@MyApp:token')
        deleteTokenApi()
      }
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <IntrodutoryCourseContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        course,
        setCourse,
        lessons,
        setLessons,
        postsAOA,
        setPostsAOA,
        uniqueTeachers,
        setUniqueTeachers,
        loading,
        setLoading,
        fetchCourse
      }}>
      {children}
    </IntrodutoryCourseContext.Provider>
  )
}

export function useIntrodutoryCourse() {
  return useContext(IntrodutoryCourseContext)
}
