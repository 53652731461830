import CardAuthor from 'components/BlogComponents/CardAuthor'
import PostContent from 'components/BlogComponents/PostsContent'
import SimiliarPost from 'components/BlogComponents/SimiliarPost'
import BreadCrumb from 'components/BreadCrumb'
import LoadingContainer from 'components/LoadingContainer'
import { IGetPostContent, IGetPostSimiliar } from 'dtos/Posts'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getPostBySlug, getSimiliarPost } from 'usecases/posts'
import * as Styles from './styles'

interface PostContentParams {
  slug?: string
  category?: string
}

function SlugPost() {
  const [postContent, setPostContent] = useState<IGetPostContent>({} as IGetPostContent)
  const [similarPost, setSimilarPost] = useState<IGetPostSimiliar>({} as IGetPostSimiliar)
  const [loading, setLoading] = useState(false)

  const { slug, category }: PostContentParams = useParams()

  const loadPostContent = async () => {
    setLoading(true)
    try {
      const responseCategories = await getPostBySlug({ slug })
      setPostContent(responseCategories)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const loadSimiliarPost = async () => {
    setLoading(true)
    try {
      const responseCategories = await getSimiliarPost({ category })
      setLoading(false)
      setSimilarPost(responseCategories)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadPostContent()
    loadSimiliarPost()
  }, [slug])

  return (
    <Styles.Container>
      {loading ? (
        <div className="loading">
          <LoadingContainer loading />
        </div>
      ) : (
        <>
          <BreadCrumb
            paths={[
              { label: 'Blog', link: `/blog` },
              { label: category || '', link: `/blog/${category}` }
            ]}
          />
          <PostContent postContent={postContent.data} />
          <CardAuthor posts={postContent.data} />
          <SimiliarPost posts={similarPost.data} />
        </>
      )}
    </Styles.Container>
  )
}

export default SlugPost
