/* eslint-disable no-plusplus */
import { TErrorType, TItemError } from './types'

/* eslint-disable no-restricted-syntax */

export const typeErrors: Record<TErrorType, TItemError> = {
  DEFAULT: {
    message: 'Houve um erro ao fazer o upload.'
  },
  FORMAT: {
    message: 'Formato de arquivo não aceito.'
  },
  SIZE: {
    message: 'Não é possível enviar arquivos com mais de 20MB'
  }
}
