/* eslint-disable react/function-component-definition */

import CustomInput from 'components/CustomInput'
import React, { useEffect, useState } from 'react'

import { Typography } from 'antd'
import axios from 'axios'
import { ErrorMessage } from 'components/CustomInput/styles'
import { useScheduling } from 'hooks/useScheduling'
import { BRAZIL_STATES } from 'pages/Registration/components/Forms/components/ValidationForm/constants'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { clearMask } from 'utils/formatters'
import * as Styled from '../styles'

const BillingAddress: React.FC = () => {
  const { loading, appointmentFromB2C } = useScheduling()
  const {
    setValue,
    clearErrors,
    formState: { errors },
    watch,
    control
  } = useFormContext()
  const [initialCEP, setInitialCEP] = useState('')

  const [loadingCep, setLoadingCep] = useState(false)

  const zipCode = watch('address.cep')

  const getCep = async () => {
    try {
      setLoadingCep(true)
      const { data } = await axios.get(`https://viacep.com.br/ws/${clearMask(zipCode)}/json/`)
      setValue('address.city', data?.localidade || '')
      setValue('address.state', data?.uf || '')
      setValue('address.address', data?.logradouro || '')
      clearErrors()
    } catch (e) {
      toast.error('CEP não encontrado')
    } finally {
      setLoadingCep(false)
    }
  }

  const handlePatientAddress = () => {
    setInitialCEP(appointmentFromB2C?.patient?.person?.address?.cep || '')
    setValue('address.cep', appointmentFromB2C?.patient?.person?.address?.cep || '')
    setValue('address.address', appointmentFromB2C?.patient?.person?.address?.address || '')
    setValue('address.number', appointmentFromB2C?.patient?.person?.address?.number || '')
    setValue('address.city', appointmentFromB2C?.patient?.person?.address?.city || '')
    setValue('address.state', appointmentFromB2C?.patient?.person?.address?.state || '')
  }

  useEffect(() => {
    if (clearMask(zipCode)?.length >= 8 && !loading && zipCode !== initialCEP) {
      getCep()
    }
  }, [zipCode, loading])

  useEffect(() => {
    if (appointmentFromB2C) {
      handlePatientAddress()
    }
  }, [appointmentFromB2C])

  return (
    <Styled.Box style={{ height: 'max-content', gridArea: 'billing' }}>
      <Styled.BoxTitle>Endereço de cobrança</Styled.BoxTitle>
      <Styled.Row>
        <CustomInput type="cep" outlined name="address.cep" label="CEP" isLoading={loadingCep} />
        <CustomInput outlined name="address.address" label="Endereço" isLoading={loadingCep} />
      </Styled.Row>
      <Styled.Row>
        <CustomInput outlined name="address.number" label="N°" placeholder="Número ou S/N" />
        <CustomInput outlined name="address.complement" label="Complemento" />
      </Styled.Row>
      <Styled.Row>
        <CustomInput outlined name="address.city" label="Cidade" isLoading={loadingCep} />
        <CustomInput
          outlined
          isLoading={loadingCep}
          name="address.state"
          label="Estado"
          select
          options={BRAZIL_STATES}
        />
      </Styled.Row>
      <Styled.AgreeFieldWrapper>
        <Controller
          name="agreeWithTerms"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Styled.StyledCheckbox onChange={onChange} checked={value}>
                <Typography.Text>
                  Eu concordo com{' '}
                  <a href="/politica-de-agendamento" target="_blank" style={{ color: '#9fae7c' }}>
                    as políticas de uso da plataforma de agendamento.
                  </a>
                </Typography.Text>
              </Styled.StyledCheckbox>
              {errors.agreeWithTerms?.message && (
                <ErrorMessage className="error-agreement">{String(errors.agreeWithTerms.message)}</ErrorMessage>
              )}
            </>
          )}
        />
      </Styled.AgreeFieldWrapper>
    </Styled.Box>
  )
}

export default BillingAddress
