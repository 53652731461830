import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { DESKTOP_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

interface ContentTypes {
  loading?: boolean
}

export const Wrapper = styled.main`
  padding-bottom: ${temporaryTheme.spacing.space4};

  button {
    font-family: 'Spartan', sans-serif;
    font-weight: 500;
  }
  @media (${TABLET_WIDTH}) {
    padding: 0 ${temporaryTheme.spacing.space4};
  }
  @media ${MOBILE_WIDTH} {
    padding: 0 ${temporaryTheme.spacing.space3};
  }
`

export const HeadingBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  button {
    min-width: 190px;
  }
`

export const Heading = styled.h1`
  font-weight: 600;
  font-size: ${temporaryTheme.fontSize.h2};
  font-family: 'Spartan', sans-serif;
  color: ${temporaryTheme.colors.dark};
  margin: ${temporaryTheme.spacing.space8} 0 ${temporaryTheme.spacing.space9} 0;

  span {
    color: ${temporaryTheme.colors.sitePrimary};
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
    font-size: 28px;
  }
`

export const BrandsDetailsContent = styled.div<ContentTypes>`
  ${({ loading }) => css`
    display: flex;
    align-items: flex-start;
    opacity: ${loading ? 0.7 : 1};
    gap: ${temporaryTheme.spacing.space7};
    @media (${MOBILE_WIDTH}) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `}
`

export const BrandsCardDetails = styled.div`
  /*   background: linear-gradient(316deg, rgba(249, 249, 249, 1) 31%, rgba(161, 167, 179, 1) 68%); */
  background: linear-gradient(to bottom right, #a1a7b3, #ffffff);
  width: 400px;
  height: 400px;
  border-radius: 5px;
  padding: 1px;
  overflow: hidden;
  @media (${TABLET_WIDTH}) {
    width: 50%;
    max-width: 400px;
  }
  @media ${MOBILE_WIDTH} {
    width: 100%;
    height: auto;
    max-width: 400px;
  }
`

export const CardContent = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${temporaryTheme.spacing.space4};
`
export const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
`

export const CardDetails = styled.div`
  padding: ${temporaryTheme.spacing.space2};
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space4};

  @media (${DESKTOP_WIDTH}) {
    width: 800px;
  }
  @media (${TABLET_WIDTH}) {
    width: 50%;
  }
`
export const CardTitle = styled.h2`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 40px;
  color: ${temporaryTheme.colors.darkGrey};
  @media ${MOBILE_WIDTH} {
    font-size: 28px;
  }
`
export const CardText = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: ${temporaryTheme.colors.dark};

  p,
  h2,
  h1,
  span,
  strong {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: ${temporaryTheme.colors.dark};
  }
`
export const CardActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${temporaryTheme.spacing.space4};

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const GridProductList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  gap: ${temporaryTheme.spacing.space3};

  @media ${MOBILE_WIDTH} {
    justify-content: center;
  }
`
