import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: auto;
  flex: 1;

  overflow-x: hidden;
  color: #3d3d3d;

  background: #dbd8d0;

  & * {
    font-family: 'Poppins', sans-serif;
  }
`

interface ContentWrapperProps {
  usage: 'login' | 'createAccount'
}
export const ContentWrapper = styled.aside<ContentWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
  height: 100%;

  padding: ${({ usage }) => (usage === 'login' ? '10% 150px 150px 150px' : '10% 150px 200px 150px')};
  background: ${({ usage }) => (usage === 'login' ? '#dbd8d0' : '#edece7')};

  p {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  @media ${MOBILE_WIDTH} {
    padding: 6rem 0.75rem 4rem 0.75rem;
  }
`

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;

  > button {
    margin-top: 1.5rem;
  }

  @media ${MOBILE_WIDTH} {
    > button {
      width: 100%;
      margin-bottom: 5rem;
    }
  }
`

export const ForgotPasswordContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-weight: 300;
    color: #4b5dff;
    font-size: 1rem;
  }
`

export const FirstAccessButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;

  color: #4b5dff;
  margin-left: 0.25rem;
`
