import dorCronicaImage from '../../assets/img/home/tratamento-dor-cronica.png'
import saudeMentalImage from '../../assets/img/home/tratamento-saude-mental.png'
import oncologiaImage from '../../assets/img/home/tratamento-oncologia.png'
import odontologiaImage from '../../assets/img/home/tratamento-odontologia.png'
import neurologiaImage from '../../assets/img/home/tratamento-neurologia.png'
import bemEstarIntimoImage from '../../assets/img/home/tratamento-bem-estar-intimo.png'
import cuidadosDaPeleImage from '../../assets/img/home/tratamento-cuidados-da-pele.png'
import pediatriaImage from '../../assets/img/home/tratamento-pediatria.png'
import medicinaEsportivaImage from '../../assets/img/home/tratamento-medicina-esportiva.png'

export const treatmentCards = [
  {
    title: 'Dor crônica',
    path: '/tratamentos/dor-cronica',
    imagePath: dorCronicaImage,
    description: 'Autonomia e liberdade em qualquer situação'
  },
  {
    title: 'Saúde mental',
    path: '/tratamentos/saude-mental',
    imagePath: saudeMentalImage,
    description: 'O bem-estar começa dentro da gente'
  },
  {
    title: 'Oncologia',
    path: '/tratamentos/oncologia-cuidados-paliativos',
    imagePath: oncologiaImage,
    description: 'A vida merece mais qualidade'
  },
  {
    title: 'Odontologia',
    path: '/tratamentos/odontologia',
    imagePath: odontologiaImage,
    description: 'A saúde bucal merece um cuidado especial'
  },
  {
    title: 'Neurologia',
    path: '/tratamentos/neurologia',
    imagePath: neurologiaImage,
    description: 'Quando nossa central precisa de mais atenção'
  },
  {
    title: 'Bem-estar íntimo',
    path: '/tratamentos/bem-estar-intimo',
    imagePath: bemEstarIntimoImage,
    description: 'Merecemos conforto em todos os momentos'
  },
  {
    title: 'Cuidados da pele',
    path: '/tratamentos/cuidados-da-pele',
    imagePath: cuidadosDaPeleImage,
    description: 'Cuidados com o cartão de visitas do nosso corpo'
  },
  {
    title: 'Pediatria',
    path: '/tratamentos/pediatria',
    imagePath: pediatriaImage,
    description: 'O primeiro contato com o mundo precisa ser especial'
  },
  {
    title: 'Medicina esportiva',
    path: '/tratamentos/medicina-esportiva',
    imagePath: medicinaEsportivaImage,
    description: 'Disposição e rendimento é um estilo de vida'
  }
]

export const typeWriteTexts = ['ciência', 'cuidado', 'saúde', 'você']
