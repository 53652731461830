import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const SessionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 48px;

  @media (max-width: 900px) {
    padding: 20px 0;
  }
`

export const SessionTitle = styled.h2`
  width: 100%;
  font-family: 'Spartan', sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 155%;
  margin-bottom: 45px;
  color: ${theme.colors.darkGrey};
  span {
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
  }
  @media (max-width: 900px) {
    display: none;
  }
`
export const SessionItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 14px;
  overflow: auto;
  padding: ${temporaryTheme.spacing.space3} 0;
  @media (max-width: 900px) {
    overflow-x: auto;
    gap: 14px;
    justify-content: flex-start;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  img {
    width: 360px;
    cursor: pointer;
    @media (max-width: 900px) {
      width: 126px;
    }
    @media (min-width: 900px) and (max-width: 1249px) {
      width: 270px;
    }
  }
`
export const ImageSessionItems = styled.img``
