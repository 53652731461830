import styled from 'styled-components'

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid #3d3d3d;
  border-radius: 1.5rem;
  padding: 0.25rem 1rem;

  height: 2.5rem;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
  }
`
export const InputItself = styled.input`
  background: transparent;
  border: none;

  width: 100%;
`
