import { InputHTMLAttributes } from 'react'
import { FiSearch } from 'react-icons/fi'
import * as Styles from './styles'

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  width?: `${number}px` | `${number}rem` | `${number}%`
  maxWidth?: `${number}px` | `${number}rem` | `${number}%`
}

export default function SearchInput({ width, maxWidth, style, ...rest }: SearchInputProps) {
  return (
    <Styles.SearchInputContainer style={{ ...style, width, maxWidth }}>
      <Styles.InputItself {...rest} />
      <FiSearch color="#21976e" />
    </Styles.SearchInputContainer>
  )
}
