import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 52px;
  margin-top: 0px;
  flex-basis: auto;
  align-items: center;

  width: 100%;

  background-color: #6b7b3c;

  .second-content {
    margin-top: 64px;

    p:first-child {
      max-width: 489px;
    }

    p {
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 4px;
    }

    @media (max-width: 900px) {
      gap: 30px;
    }

    @media (min-width: 900px) and (max-width: 1300px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 44px;
    }
  }

  .container-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 300px;
    :first-child {
      align-self: center;
    }

    width: ${({ width }) => (width ? '100%' : 'auto')};

    @media (max-width: 900px) {
      order: ${({ order }) => order || null};
      align-self: flex-start !important;
    }
  }
`

export const Content = styled.div`
  display: flex;
  gap: 56px;

  .title {
    margin-bottom: ${theme.spacing.space5};
  }
  .badge-success {
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.05px;
    color: #f8f8f4;
    margin-top: 12px;
  }
  .newsletter-input {
    width: 270px;
    background: #ffffff;
    border: 1.6px solid #dddddd;
    border-radius: 4px;
    ::placeholder {
      color: #c4c4c4;
      font-size: 15px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
    }
    @media (min-width: 900px) and (max-width: 1300px) {
      width: 400px;
    }
    @media (max-width: 620px) {
      width: 282px;
    }
  }
  .newsletter-input:first-child {
    margin-bottom: 20px;
  }

  @media (min-width: 900px) and (max-width: 1300px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 148px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const SubscribeButton = styled.button`
  width: 166px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${temporaryTheme.colors.darkBlue};
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  border-radius: 8px;
  margin-top: 32px;
  border: none;
`

export const QuickAccessContainer = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 26px;
`
export const QuickAccessContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 16px;
  @media (min-width: 900px) and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px 82px;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const Title = styled.h3`
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #ffff;
`
export const TextLink = styled.a`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  font-weight: 400;
  color: #ffff;
  cursor: pointer;
`

export const ImgWrapper = styled.div`
  align-items: center;
  justify-content: center;
`

export const Img = styled.img`
  display: flex;
  justify-content: center;
  width: 203px;
  height: 34px;
`

export const Link = styled.a`
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #f3f3f3;

  text-decoration: none;
`

export const LinkTrabalhe = styled.a`
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #f3f3f3;
  text-decoration: none;
  margin-top: 20px;
`

export const FormContent = styled.div`
  width: 100%;

  * {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }

  form {
    margin-top: 10px !important;
  }

  ._form_element {
    margin-bottom: 16px !important;
  }

  #_form_60_submit {
    margin-top: 0px !important;
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  input {
    padding: 6px 16px !important;
  }
`
