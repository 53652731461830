import styled from 'styled-components'
import { MAX_PAGE_WIDTH } from 'utils/constants'

export const BaseRecipeHelpContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  position: relative;

  min-height: 100vh;
`

export const QuestionsWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  padding: 0.75rem;

  margin-top: 110px;
  margin-bottom: 230px;
`
