import { AxiosResponse } from 'axios'
import {
  IGetTratamentoContent,
  IGetTratamentoContentRequestParams,
  IGetCategoriaContentRequestParams
} from 'dtos/Tratamento'
import strapi from 'services/strapi'
import api from 'services/api'

export const getTratamento = async (params: IGetTratamentoContentRequestParams): Promise<IGetTratamentoContent> => {
  const result: AxiosResponse<IGetTratamentoContent> = await strapi.get(
    `/api/tratamentos?filters[$and][0][slug][$eq]=${params.slug}&populate=image&populate=icon`
  )

  return result.data
}
export const getCategoria = async (params: IGetTratamentoContentRequestParams): Promise<IGetTratamentoContent> => {
  const result: AxiosResponse = await api.get(`category-by-name/${params.slug}`)

  return result.data
}
