import * as Styles from './styles'

interface OrderAmountsProps {
  amount: number
  discount: number
  shipping: number
}
export default function OrderAmounts({ amount, discount, shipping }: OrderAmountsProps) {
  return (
    <Styles.OrderAmountsContainer>
      <Styles.PackageAmount isTotal={false}>
        <span>Subtotal</span>
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(amount - shipping)}
        </span>
      </Styles.PackageAmount>
      <Styles.PackageAmount isTotal={false}>
        <span>Custo de importação</span>
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(shipping)}
        </span>
      </Styles.PackageAmount>
      <Styles.PackageAmount isTotal={false}>
        <span>Descontos</span>
        <span>
          -{' '}
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(discount)}
        </span>
      </Styles.PackageAmount>
      <Styles.PackageAmount isTotal>
        <span>Total</span>
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(amount)}
        </span>
      </Styles.PackageAmount>
    </Styles.OrderAmountsContainer>
  )
}
