import styled from 'styled-components'

import { theme } from 'design-cannect'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  .page {
    margin: 24px 0;
  }
  @media (max-width: 1380px) {
    padding: 0 24px;
  }
  .modal-cannect-ciencia {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 64px;
  margin-bottom: 56px;
  @media ${MOBILE_WIDTH} {
    margin-bottom: 40px;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  svg {
    width: 30px;
    height: 30px;
  }
`

export const HeaderTitle = styled.h1`
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #3d3d3d;
  span {
    color: #21976e;
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
    font-size: 30px;
  }
`
export const FilterLabel = styled.h3`
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  font-size: 19px;
  margin-right: 214px;
  color: ${theme.colors.darkGrey};
`
export const HeaderSubtitle = styled.h2`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: ${theme.colors.darkGrey};
  margin-top: 16px;
  @media ${MOBILE_WIDTH} {
    font-size: 18px;
    margin-top: 18px;
  }
`

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 54px;
  margin-bottom: 26px;
  .select-order-filter {
    min-width: 182px;
    button {
      padding: 10px;
    }
    .fake-select-placeholder {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #3d3d3d;
    }
    & * {
      font-family: 'Nunito', sans-serif;
    }
  }
`

export const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > Button {
    margin: 0 8px;
  }
`
export const AsideBar = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`
export const HeaderWrapp = styled.div<any>`
  margin: 25px auto;
  width: ${({ isDesktop }) => (isDesktop ? '50%' : '100%')};
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  align-content: center;
  > div {
    display: flex;

    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
`

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 44px;

  Button {
    margin-top: 25px;
    float: right;
  }
`
export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem;
  border: 1px solid ${theme.colors.gray};
  border-radius: 8px;
  margin: 15px;
`
export const CardWrapp = styled.div`
  /* margin: 0 auto; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  flex-wrap: nowrap;
  gap: 32px;
`

export const MobileFilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 16px;
  width: 118px;
  height: 50px;
  background: #444444;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  color: #fff;
  margin-top: 34px;
  font-size: 15px;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  svg {
    width: 22px;
    height: 22px;
  }
`

export const MobileFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ededed;
  border-radius: 10px;
  padding: 24px;
  max-width: 333px;
`

export const MobileFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  svg {
    width: 32px;
    height: 32px;
    color: #21976e;
    cursor: pointer;
  }
`

export const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-bottom: 36px;
`
