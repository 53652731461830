import styled from 'styled-components'
import { theme } from 'design-cannect'
import { temporaryTheme } from 'styles/theme'

export const InitialQuestionsContainer = styled.div`
  display: flex;

  input {
    border-color: #3d3d3d;

    &:focus {
      border-color: #3d3d3d;
      box-shadow: none;
    }
  }

  .link {
    margin-bottom: 24px;
    color: ${temporaryTheme.colors.link};
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 10px;

    &:hover {
      color: ${temporaryTheme.colors.linkHover};
    }
  }
`

export const ButtonBack = styled.button`
  background-color: transparent;
  border: 1px solid ${theme.colors.tertiary};
  color: ${theme.colors.tertiary} !important;
  padding: 10px 16px;
  border-radius: 5px;
`
