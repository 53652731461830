import Heading from 'new-components/Heading'
import When from 'utils/when'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../utils/constants'
import * as Styles from './styles'
import { CardItem } from '../CardItem'
import useSignedUser from 'hooks/useSignedUser'

function MyCart({ products, IncrementProduct, DecrementProduct, DeleteProducts, deleteProduct }: any) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { signedUser } = useSignedUser()

  const isPatientUserType = signedUser?.registration_type === 1

  return (
    <Styles.Container>
      <Heading>
        Meus <span>Favoritos</span>
      </Heading>

      <When expr={!isMobile}>
        <Heading elementType="h4" fontSize="25px">
          Produtos
        </Heading>
      </When>

      <Styles.CardList>
        {products.map((product: any) => (
          <CardItem
            key={product.id}
            product_id={product?.id}
            image={isPatientUserType && !(product?.class_id == 6) ? null : product?.media}
            name={product?.translated_name || product?.name}
            shippingType={product?.supplier_id}
            isFavorite
            product={product}
            removeFavorite={deleteProduct}
            price={product?.real_price || product?.price}
          />
        ))}
      </Styles.CardList>
    </Styles.Container>
  )
}

export default MyCart
