import styled from 'styled-components'

export const ItemBox = styled.div`
  min-width: 130px;
  text-align: center;
  font-family: 'Spartan', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #3d3d3d;

  &.total_value {
    font-weight: 400;
  }

  &.unit_value {
    font-weight: 700;
  }
`

export const PromotionalBox = styled.div`
  font-family: 'Spartan', sans-serif;
  text-align: center;
  .discount {
    color: #48b791f0;
    font-size: 11px;
    font-weight: 500;
  }

  .old_price {
    font-size: 10px;
    font-weight: 400;
    color: #858585;
    text-decoration: line-through;
  }
`
