import { theme } from 'design-cannect'
import { FiChevronRight } from 'react-icons/fi'
import * as Style from './styles'

type ChooseCertificateButtonProps = {
  label: string
  onClick?: () => void
}

export default function ChooseCertificateButton({ label, onClick }: ChooseCertificateButtonProps) {
  return (
    <Style.ChooseCertificateButtonContainer onClick={onClick}>
      <span>{label}</span>
      <span>
        <FiChevronRight size={30} color={theme.colors.tertiary} />
      </span>
    </Style.ChooseCertificateButtonContainer>
  )
}
