export function TextIcon() {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      data-testid="file_icon_text"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9744 0.208984H4.12484C3.07502 0.208984 2.0682 0.626022 1.32587 1.36835C0.583541 2.11068 0.166504 3.1175 0.166504 4.16732V35.834C0.166504 36.8838 0.583541 37.8906 1.32587 38.6329C2.0682 39.3753 3.07502 39.7923 4.12484 39.7923H27.8748C28.9247 39.7923 29.9315 39.3753 30.6738 38.6329C31.4161 37.8906 31.8332 36.8838 31.8332 35.834V13.0677C31.8332 12.4379 31.5829 11.8338 31.1375 11.3884L20.6538 0.904605C20.2084 0.459206 19.6043 0.208984 18.9744 0.208984ZM17.979 14.0632V4.16737L27.8748 14.0632H17.979Z"
        fill="#16A6C7"
      />
      <path
        d="M4.94557 31.8676C4.94557 31.6477 5.02117 31.4638 5.17235 31.3161C5.32697 31.1683 5.53142 31.0945 5.78569 31.0945C6.03995 31.0945 6.24268 31.1683 6.39387 31.3161C6.54849 31.4638 6.6258 31.6477 6.6258 31.8676C6.6258 32.0875 6.54849 32.2713 6.39387 32.4191C6.24268 32.5668 6.03995 32.6407 5.78569 32.6407C5.53142 32.6407 5.32697 32.5668 5.17235 32.4191C5.02117 32.2713 4.94557 32.0875 4.94557 31.8676ZM11.6997 25.0796V32.584H10.1586V25.0796H11.6997ZM14.0087 25.0796V26.2909H7.88567V25.0796H14.0087ZM16.4798 25.0796L17.892 27.6979L19.3042 25.0796H21.0721L18.8919 28.8009L21.1288 32.584H19.3455L17.892 29.9142L16.4386 32.584H14.6449L16.887 28.8009L14.7016 25.0796H16.4798ZM25.579 25.0796V32.584H24.0379V25.0796H25.579ZM27.888 25.0796V26.2909H21.765V25.0796H27.888Z"
        fill="white"
      />
    </svg>
  )
}
