import { useQuery } from '@tanstack/react-query'
import * as requests from './requests'

export const KEY_GET_PRESCRIBERS_HIGHLIGHTED_LIST = 'getPrescribersHighlightedList'

export const useGetPrescribersHighlightedList = () =>
  useQuery({
    queryKey: [KEY_GET_PRESCRIBERS_HIGHLIGHTED_LIST],
    queryFn: () => requests.getHighlitedPrescribers(),
    staleTime: 600000,
    meta: {
      errorMessage: 'Erro ao buscar os prescritores destacados.'
    }
  })
