import Text from 'components/Text'
import { SiWhatsapp } from 'react-icons/si'
import * as Style from './styles'

export default function NeedHelp() {
  return (
    <Style.Container>
      <Text>Dúvidas? Fale com um atendente</Text>
      <Style.Button>
        <a href="https://atendimento.cannect.life/atendimento-cannect" target="__blank">
          <h3>
            <SiWhatsapp size={20} /> Conversar no whatsapp com um atendente
          </h3>
        </a>
      </Style.Button>
    </Style.Container>
  )
}
