import React from 'react'
import { BiPurchaseTagAlt } from 'react-icons/bi'
import { Container } from './styles'

interface TagCupomProps {
  value: string
  variant?: 'positive' | 'negative' | 'warning' | 'info'
}
function TagCupom({ value, variant }: TagCupomProps) {
  return (
    <Container variant={variant}>
      <div className="tag">
        {value}
        <BiPurchaseTagAlt />
      </div>
    </Container>
  )
}

export default TagCupom
