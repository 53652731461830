import styled from 'styled-components'

export const OrderAmountsContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 1px solid #d1cece;
  padding: 1.5rem 1.5rem 0 1.5rem;
`

interface PackageAmountProps {
  isTotal: boolean
}
export const PackageAmount = styled.div<PackageAmountProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ isTotal }) => (isTotal ? '#000' : '#3d3d3d')};
  font-size: ${({ isTotal }) => (isTotal ? '1.25rem' : '1rem')};
  margin-top: ${({ isTotal }) => (isTotal ? '0.5rem' : '0')};
`
