import styled from 'styled-components'

interface Props {
  small?: boolean
}

export const ActionsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`
export const QuantityBox = styled.div<Props>`
  height: ${(props) => (props.small ? '32px' : '40px')};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  select {
    min-width: 30px;
    padding: 10px;
  }
  p {
    background: #ffffff;
    border: 1.4px solid #c4c4c4;
    width: ${(props) => (props.small ? '32px' : '40px')};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #4e4e4e;
  }

  select {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
  }

  svg {
    color: #424242;
    margin: 0;
    transition: 0.2s;
    :hover {
      color: #115850;
      scale: 1.2;
    }
  }
`
export const ButtonRemove = styled.button<{ disabled?: boolean }>`
  background: #ffffff;
  border: 1.4px solid #c4c4c4;
  border-radius: 5px 0px 0px 5px;
  width: 26px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: none;

  ${({ disabled }) => {
    if (disabled) {
      return `
        opacity: .5;
        cursor: wait;
      `
    }
    return ``
  }}

  :hover {
    background: #f1dbbb;
  }
`

export const ButtonAdd = styled.button<{ disabled?: boolean }>`
  background: #ffffff;
  border: 1.4px solid #c4c4c4;
  border-radius: 0px 5px 5px 0px;
  width: 26px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-left: none;

  ${({ disabled }) => {
    if (disabled) {
      return `
        opacity: .5;
        cursor: wait;
      `
    }
    return ``
  }}

  :hover {
    background: #c5f1bb;
  }
`
