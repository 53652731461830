export const EcosystemMockTextChart = `
Desde que os produtos à base de cannabis para uso medicinal foram aprovados para a importação,
a adesão de brasileiros para o tratamento com Cannabis Medicinal só cresce.
<br/>
<br/>
A cannabis pode ser utilizada em uma série de condições. Os médicos brasileiros já emitiram mais
de 20 mil prescrições de produtos à base de cannabis em um intervalo de três anos para cerca de
44 especialidades diferentes, entre 2015 e 2019.
`

export const EcosystemText = `
A Cannect nasceu com a missão de tornar a Cannabis Medicinal mais acessível aos pacientes.Trata-se
de um ecossistema que liga pacientes, médicos e fornecedores, cuidando de todos os processos necessários
e garantindo eficiência em cada etapa.
<br/>
<br/>
<br/>
<strong>O maior marketplace da América Latina</strong>
<br/>
<br/>
<br/>
Atualmente, o portfólio Cannect é o maior da américa latina, com mais de 500 produtos para os mais variados
tratamentos em diferentes faixas de preço.
<br/>
<br/>
As linhas de cuidado foram formatadas para garantir a maior aderência
possível a todas as terapêuticas propostas pelos médicos.
<br/>
<br/>
Temos uma curadoria que seleciona produtos seguros e
eficazes, que acima de tudo, respeitam as normas estabelecidas pela Anvisa.
<br/>
<br/>
Só vendemos produtos testados em laboratório
e cuidadosamente monitorados, desde a seleção da planta até a produção final.
<br/>
<br/>

Também nos atentamos se todas as instalações
são certificadas por boas práticas, tanto o espaço de cultivo, de extração, de armazenamento e até de distribuição.
<br/>
<br/>
Relacionamento com Profissionais da SaúdePara profissionais da saúde, por exemplo, oferecemos um atendimento exclusivo e
material de apoio com cursos e protocolos para facilitar a prescrição médica
`

export const ListEcosystem = [
  {
    id: 1,
    title:
      '<a href="https://www.drcannabis.com.br/?utm_source=pagina-ecossistema&utm_medium=cta&utm_id=cannect" target="_blank"  rel="noopener noreferrer">Curso Introdutório para prescritores iniciantes</a>',
    link: ''
  },
  {
    id: 2,
    title:
      'Um portfólio com mais de <a href="https://www.cannect.life/produtos/1?translated_name=" target="_blank"> 800 produtos </a>',
    link: ''
  },
  {
    id: 3,
    title:
      '<a href="/educa"> Webinars & podcasts semanais </a> (cannect foco, cannect na prática, cannect com ciência)',
    link: ''
  },
  {
    id: 4,
    title:
      '<a href="/healthProfessionals">Comunidade médica</a>, para trocar informações e conhecer outros prescritores',
    link: ''
  },
  {
    id: 5,
    title:
      '<a href="/teste-genetico">Cannect Gene</a> (teste genético para o metabolismo dos canabinoides e potenciais respostas do organismo);',
    link: ''
  },
  {
    id: 6,
    title:
      '<a href="/cuida"> Cannect Cuida </a> (programa de cuidado coordenado durante o tratamento com Cannabis Medicinal).',
    link: ''
  }
]

export const SolutionsCannect = `
<strong>Atendimento Inicial</strong>
<br/>
O paciente é acolhido, a equipe de atendimento entende quais são as suas necessidades e como pode ajudá-lo.
<br/>
<br/>

<strong>Marcação de Consulta</strong>
<br/>
A Cannect procura o melhor horário para a consulta, levando em consideração a agenda do paciente e do médico. <a href="https://atendimento.cannect.life/atendimento-cannect" target="_blank">Agende sua consulta!</a>
<br/>
<br/>

<strong>Prescrição Médica</strong>
<br/>
Trata-se da primeira consulta, quando o médico escuta e entende o que o paciente precisa para prescrever Cannabis Medicinal.É importante ressaltar que os médicos são orientados a indicar o melhor tratamento, seja através de produtos à base de cannabis ou não.
<br/>
<br/>

<strong>Autorização da Anvisa</strong>
<br/>
Para importar, é necessário uma autorização excepcional da Anvisa para cada paciente. Burocracia que também é resolvida pela Cannect.
<br/>
<br/>

<strong>Compra dos Produtos</strong>
<br/>
A Cannect também auxilia em toda a logística que vai da compra do produto até a casa do paciente, além de garantir o custo de importação mais em conta do mercado.
<br/>
<br/>

<strong>Cuidado contínuo</strong>
<br/>
Através do programa Cannect Cuida, os pacientes são acompanhados para avaliar o progresso do tratamento. Dados que são encaminhados ao médico prescritor.
<br/>

`
