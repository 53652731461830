/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { svgTwitter, svgWhatsapp } from 'assets/svg'
import * as Style from './styles'

interface SocialMediaProps {
  message: string
}

export default function SocialMedia({ message }: SocialMediaProps) {
  return (
    <Style.SocialMediaContainer>
      <span>Compartilhar</span>

      <Style.Medias>
        <a
          href={`https://twitter.com/intent/tweet?text=${message}&url=${window.location.href}`}
          target="_blank"
          rel="noreferrer">
          <img src={svgTwitter} alt="Twitter" />
        </a>
        <a
          href={`whatsapp://send?text=${message} ${window.location.href}`}
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noreferrer">
          <img src={svgWhatsapp} alt="Whatsapp" />
        </a>
      </Style.Medias>
    </Style.SocialMediaContainer>
  )
}
