import styled from 'styled-components'

export const ChooseCertificateButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #ecece2;
  border-radius: 0.5rem;
  padding: 0.25rem 2rem 0.25rem 0.75rem;

  margin-bottom: 0.75rem;

  transition: filter 0.3s;
  font-weight: 300;
  font-size: 1rem;

  border: none;
  outline: none;

  &:hover {
    filter: brightness(0.95);
  }

  span {
    margin-top: 0.2rem;
  }
`
