import { SVGProps } from 'react'

export const HeartPulseIcon = ({ width = '48', height = '42', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.0225 14.3106C40.4973 10.7853 34.7816 10.7853 31.2564 14.3106L29.6498 15.9172C29.2122 16.3548 28.6187 16.6006 27.9998 16.6006C27.381 16.6006 26.7875 16.3548 26.3499 15.9172L24.7433 14.3106C21.218 10.7853 15.5024 10.7853 11.9771 14.3106C8.45185 17.8359 8.45185 23.5515 11.9771 27.0768L27.9998 43.0995L30.7681 40.3313C31.6793 39.4201 33.1567 39.4201 34.0679 40.3313C34.9791 41.2425 34.9791 42.7199 34.0679 43.6311L29.6498 48.0493C29.2122 48.4869 28.6187 48.7327 27.9998 48.7327C27.381 48.7327 26.7875 48.4869 26.3499 48.0493L8.6773 30.3766C3.32957 25.0289 3.32957 16.3585 8.6773 11.0108C14.0106 5.67748 22.6487 5.66311 27.9998 10.9677C33.351 5.66311 41.9891 5.67748 47.3224 11.0108C50.09 13.7784 51.4253 17.4403 51.3282 21.0625C51.2937 22.3507 50.2214 23.367 48.9332 23.3325C47.645 23.298 46.6287 22.2257 46.6632 20.9375C46.7274 18.5425 45.8473 16.1353 44.0225 14.3106ZM34.0735 22.5141C33.7742 21.716 33.0635 21.1446 32.2198 21.0237C31.376 20.9027 30.5335 21.2515 30.0221 21.9334L26.0554 27.2223H20.9998C19.7112 27.2223 18.6665 28.2669 18.6665 29.5556C18.6665 30.8443 19.7112 31.8889 20.9998 31.8889H27.2221C27.9565 31.8889 28.6481 31.5432 29.0887 30.9556L31.1996 28.1411L34.3706 36.5971C34.6699 37.3952 35.3806 37.9666 36.2243 38.0876C37.0681 38.2085 37.9106 37.8597 38.4221 37.1778L42.3887 31.8889H48.9998C50.2885 31.8889 51.3332 30.8443 51.3332 29.5556C51.3332 28.2669 50.2885 27.2223 48.9998 27.2223H41.2221C40.4876 27.2223 39.7961 27.5681 39.3554 28.1556L37.2445 30.9701L34.0735 22.5141Z"
      fill="#748351"
    />
  </svg>
)
