import { noProfilePicture } from 'assets/img'
import Loading from 'components/Loading'
import { Modal } from 'components/Modal'
import PageTitle from 'components/PageTitle'
import useSignedUser from 'hooks/useSignedUser'
import Button from 'new-components/Button'
import { ChangeEvent, useState } from 'react'
import { FiArrowRight, FiX } from 'react-icons/fi'
import { toast } from 'react-toastify'
import api from 'services/api'
import * as Styles from './styles'

interface ChangeProfileImageModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function ChangeProfileImageModal({ isOpen, onClose }: ChangeProfileImageModalProps) {
  const { signedUser, refetchSignedUser } = useSignedUser()
  const [selectedImage, setSelectedImage] = useState<File>()
  const [selectedImageAsStr, setSelectedImageAsStr] = useState<string>()
  const [loading, setLoading] = useState(false)

  const onImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (!selectedFile) return
    setSelectedImage(selectedFile)

    const reader = new FileReader()

    reader.onload = e => {
      setSelectedImageAsStr(e.target?.result as string)
    }

    reader.readAsDataURL(selectedFile)
  }

  const updateProfilePicture = async () => {
    if (!selectedImage) return
    setLoading(true)
    try {
      const formData = new FormData()
      const blob = new Blob([selectedImage], {
        type: selectedImage.type
      })

      formData.append('file', blob, selectedImage.name)

      await api.put('my-account/information/photo', formData)
      refetchSignedUser()
      setSelectedImage(undefined)
      setSelectedImageAsStr(undefined)
      onClose()
      toast.success('Foto de perfil atualizada com sucesso!')
    } catch (err) {
      toast.error('Houve um erro ao tentar atualizar a foto de perfil. Tente novamente.')
    }
    setLoading(false)
  }

  const onRemoveSelectedImage = () => {
    setSelectedImage(undefined)
    setSelectedImageAsStr(undefined)
  }

  return (
    <Modal isOpened={isOpen} onRequestClose={onClose}>
      <Loading loading={loading} />
      <PageTitle>Alterar foto de perfil</PageTitle>
      <Styles.PicturesContainer>
        <Styles.ImageWrapper>
          <img src={signedUser.photo ?? noProfilePicture} alt="Foto de perfil" />
        </Styles.ImageWrapper>

        <FiArrowRight />

        {!selectedImage ? (
          <>
            <Styles.SelectImageInput
              id="select-new-image-input"
              type="file"
              accept="image/png, image/jpeg, image/svg+xml"
              onChange={onImageSelect}
            />
            <Styles.SelectNewImageLabel htmlFor="select-new-image-input">Selecione</Styles.SelectNewImageLabel>
          </>
        ) : (
          <Styles.ImageWrapper>
            <button onClick={onRemoveSelectedImage} aria-label="Remover imagem selecionada">
              <FiX />
            </button>
            <img src={selectedImageAsStr} alt="Nova foto de perfil" />
          </Styles.ImageWrapper>
        )}
      </Styles.PicturesContainer>

      <Styles.ButtonsContainer>
        <Button background="green" isOutlined onClick={onClose}>
          Cancelar
        </Button>
        <Button background="green" disabled={!selectedImage} onClick={updateProfilePicture}>
          Confirmar
        </Button>
      </Styles.ButtonsContainer>
    </Modal>
  )
}
