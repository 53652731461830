import Text from 'components/Text'
import { Button } from 'design-cannect'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import api from 'services/api'
import BreadCrumb from 'components/BreadCrumb'
import TabsNavigator from 'components/TabsNavigator'
import { Article } from 'types/ArticlesTypes'
import CienciaNavigator from '../CienciaNavigator'
import * as Styles from './styles'
// import { CannectCienciaArticleLogo } from '../../../assets/img'
// import { text } from 'stream/consumers'
// import api from 'services/api'

interface RouteParams {
  id: string
}

interface FetchArticleResponse {
  article: Article
  success: boolean
}

export function BaseCientifica() {
  const [article, setArticle] = useState<Article>()
  const [loading, setLoading] = useState(false)
  const [errorToFetch, setErrorToFetch] = useState<string>()
  const { id } = useParams<RouteParams>()

  useEffect(() => {
    async function fetchScientificBase() {
      setLoading(true)
      try {
        const response = await api.get<FetchArticleResponse>(`/article/${id}`)
        if (response.data.article !== null) {
          setArticle(response.data.article)
        } else {
          setErrorToFetch('Artigo não encontrado')
        }
      } catch (err: any) {
        setErrorToFetch(err?.response?.data)
      }
      setLoading(false)
    }
    fetchScientificBase()
  }, [id])

  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Ciência', link: '/cannect-ciencia' },
          { label: 'Bases Cientificas', link: '/bases-cientificas' }
        ]}
      />
      <TabsNavigator />
      <CienciaNavigator />
      <Styles.Article style={{ paddingBottom: '70px' }} isDesktop={!isMobile}>
        <header>
          {/* <img src={CannectCienciaArticleLogo} alt="" /> */}
          <Text type="h1" color="tertiary">
            Bases cientificas
          </Text>
        </header>
        <Styles.Container>
          <div>
            <Text type="md" color="tertiary" bold>
              {article?.name}
              {/* Cognitive function and adaptive skills after a one-year trial of cannabidiol (CBD) in a pediatric sample
            with treatment-resistant epilepsy. */}
            </Text>
          </div>
          {/* article.infos[0].classe.name */}
        </Styles.Container>
        <Styles.Body>
          <Text type="md" bold color="tertiary">
            Sinopse
          </Text>
          <Text type="md" color="black">
            {article?.synopsis}
          </Text>
        </Styles.Body>
        <Styles.Body>
          <Text type="md" bold color="tertiary">
            Bibliografia
          </Text>
          <Text type="md" color="black">
            {article?.bibliography}
          </Text>
        </Styles.Body>
        <Styles.CardContainer>
          <Styles.CardWrapp isDesktop={!isMobile}>
            <Styles.Card isDesktop={!isMobile}>
              <Text type="md" bold color="secondary">
                Ano de Publicação
              </Text>
              <Text type="md" color="black">
                {article?.year}
              </Text>
            </Styles.Card>
            <Styles.Card>
              <Text type="md" bold color="secondary">
                Nível de evidência
              </Text>
              <Text type="md" color="black">
                {article?.level}
              </Text>
            </Styles.Card>
            <Styles.Card>
              <Text type="md" bold color="secondary">
                Tipo de estudo
              </Text>
              <Text type="md" color="black">
                {article?.TypeStudy.name}
              </Text>
            </Styles.Card>
          </Styles.CardWrapp>
          <Styles.CardWrapp>
            <Styles.Card>
              <Text type="md" bold color="secondary">
                Faixa etária
              </Text>
              <Text type="md" color="black">
                {article?.age_group}
              </Text>
            </Styles.Card>
            <Styles.Card>
              <Text type="md" bold color="secondary">
                Sexo
              </Text>
              <Text type="md" color="black">
                {article?.sex}
              </Text>
            </Styles.Card>
          </Styles.CardWrapp>
        </Styles.CardContainer>
        <Styles.Table isDesktop={!isMobile}>
          <Styles.Container isDesktop={!isMobile}>
            <table className="tg">
              <thead>
                <tr>
                  {/* <th className="tg-0lax">Classe farmacológica</th> */}
                  <th className="tg-0lax">Classse farmacológica</th>
                  <th className="tg-0lax">Subclasse farmacológica</th>
                  <th className="tg-0lax">Concentração</th>
                  <th className="tg-0lax">Forma farmacêutica</th>
                  <th className="tg-0lax">Via de administração</th>
                  <th className="tg-0lax">Posologia</th>
                </tr>
              </thead>
              <tbody>
                {article?.infos?.map((info: any) => (
                  <tr>
                    <td className="tg-baqh">{info?.classe?.name}</td>
                    <td className="tg-baqh">{info?.subclass?.name}</td>
                    <td className="tg-baqh">{info?.concentration}</td>
                    <td className="tg-baqh">{info?.shape?.name}</td>
                    <td className="tg-baqh">{info?.routesOfAdministration.name}</td>
                    <td className="tg-baqh">{info?.posology}</td>
                    {/* <td className="tg-baqh">{text?.infos[0].posology}</td> */}
                  </tr>
                ))}
                {/* <tr>
                <td className="tg-baqh">Espectro completo</td>
                <td className="tg-baqh">CBD</td>
                <td className="tg-baqh">30 mg/mL</td>
                <td className="tg-baqh">Solução líquida</td>
                <td className="tg-baqh">Oral</td>
                <td className="tg-baqh">Doses crescentes de 5 a 50 mg/kg/dia</td>
              </tr> */}
              </tbody>
            </table>
          </Styles.Container>
        </Styles.Table>
        <Styles.BtnArticle>
          <Button>
            <a href={article?.link} target="_blank" rel="noreferrer">
              Acessar artigo
            </a>
          </Button>
        </Styles.BtnArticle>
        <Styles.CardPathologySynopsis>
          <Text type="md" bold color="tertiary">
            Sobre a patologia
          </Text>
          <Text type="lg" bold color="secondary">
            {article?.Pathology?.name}
          </Text>
          <Text type="md" color="black">
            {article?.pathology_synopsis}
          </Text>
        </Styles.CardPathologySynopsis>
      </Styles.Article>
    </>
  )
}
