/* eslint-disable react/jsx-no-useless-fragment */
import { useGetProductsFilters, useGetPublicProductsListInfiniteQuery } from '@cannect/services/resources/products'
import { TSheetProductsListProps, TStep, TStepsConfig } from './types'
import { useQueryFilters } from '@cannect/hooks/utils/useQueryFilters'
import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export const useSheetProductsList = ({ onAddToCart, isLoadingAction, children }: TSheetProductsListProps) => {
  const { queryParams, updateQueryParams } = useQueryFilters<{
    brand_id: string[]
    class_id: string[]
  }>()
  const [currentStep, setCurrentStep] = useState<TStep>('filterByBrand')
  const productsFiltersQuery = useGetProductsFilters()

  const productsListQuery = useGetPublicProductsListInfiniteQuery(queryParams, currentStep === 'filteredProducts') // will search only if currentStep is filteredProducts

  const isFetchingProducts = useMemo(
    () => productsListQuery.isFetchingNextPage || productsListQuery.fetchStatus === 'fetching',
    [productsListQuery.isLoading, productsListQuery.isFetchingNextPage, productsListQuery.fetchStatus]
  )

  const productsList = productsListQuery.data?.pages.flatMap((page) => page.products.rows) || []

  const handleFilterChange = (filterKey: string, value: string) => {
    const currentFilters = queryParams.getAll(filterKey)
    updateQueryParams({
      [filterKey]: !currentFilters.includes(value)
        ? [...currentFilters, value]
        : currentFilters.filter((filter) => filter !== value)
    })
  }

  const goToStep = (step: TStep | null) => {
    if (step) {
      setCurrentStep(step)
    }
  }

  const STEPS_CONFIG: TStepsConfig = useMemo(
    () => ({
      filterByBrand: {
        title: 'Marcas',
        description: 'Escolha a marca do produto:',
        filterKey: 'brand_id',
        filters: productsFiltersQuery?.data?.brand_id || [],
        previousStep: null,
        nextStep: 'filterByClass'
      },
      filterByClass: {
        title: 'Classes',
        description: 'Escolha a classificação do produto:',
        filterKey: 'class_id',
        filters: productsFiltersQuery?.data?.class_id || [],
        previousStep: 'filterByBrand',
        nextStep: 'filteredProducts'
      },
      filteredProducts: {
        title: 'Produtos',
        description: 'Escolha os produtos abaixo:',
        previousStep: 'filterByClass',
        nextStep: null
      }
    }),
    [productsFiltersQuery.data]
  )

  const currentStepConfig = useMemo(() => STEPS_CONFIG[currentStep], [STEPS_CONFIG, currentStep])

  const { ref, inView } = useInView({
    threshold: 0.9
  })

  useEffect(() => {
    const shouldFetchNextPage = inView && !isFetchingProducts && productsListQuery.hasNextPage
    if (shouldFetchNextPage) {
      productsListQuery.fetchNextPage()
    }
  }, [inView, isFetchingProducts, productsListQuery.hasNextPage, productsListQuery.isFetchingNextPage])

  return {
    onAddToCart,
    productsList,
    isFetchingNextPage: productsListQuery.isFetchingNextPage,
    handleFilterChange,
    lastItemRef: ref,
    isLoadingProducts: isFetchingProducts,
    isInitialProductsLoading: productsListQuery.isLoading,
    isLoadingAction,
    children,
    goToStep,
    currentStepConfig,
    queryParams
  }
}
