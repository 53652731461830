import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

interface PathSelectorButtonContainerProps {
  enabled: boolean
}
export const PathSelectorButtonContainer = styled(Link)<PathSelectorButtonContainerProps>`
  display: flex;
  align-items: center;
  gap: 1rem;

  border: 1px solid #e0e7ec;
  border-radius: 0.25rem;
  padding: 1rem;

  max-width: 430px;
  width: 100%;
  min-height: 11rem;

  color: #3d3d3d;
  text-decoration: none;
  transition: 0.3s;

  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
  cursor: not-allowed;

  svg {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    min-height: 3rem;

    path {
      stroke: #3d3d3d;
    }
  }

  &.ciência {
    svg {
      width: 2.5rem;
      height: 2.5rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
    }
  }

  ${({ enabled }) =>
    enabled &&
    `&:hover {
      border-color: #21976e;

      h2 {
        color: #21976e;
      }

      svg {
        path {
          stroke: #21976e !important;
        }
      }

      cursor: pointer;
    }`}

  @media ${MOBILE_WIDTH} {
    max-width: 100%;
  }
`

export const CommingSoon = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-left: 0.25rem;
`

export const PathSelectorContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h2 {
    font-size: 2rem;
    transition: 0.3s;
  }

  p {
    font-size: 1rem;
  }
`
