import axios from 'axios'
import { baseURL } from './api'

export const registrationRoute = (args: any) => {
  return axios.post(`${baseURL}/register`, args)
}

export const sendTokenForgotPassword = (args: any) => {
  return axios.post(`${baseURL}/forgot_password`, args)
}

export const resetPassword = (args: any) => {
  return axios.post(`${baseURL}/reset_password`, args)
}
