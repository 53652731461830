import { noop } from 'lodash'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import Text from 'components/Text'
import Modal from 'new-components/Modal'
import * as Styles from './styles'

interface StatusModalProps {
  isOpen: boolean
  onClose: any
  notCloseModal?: boolean
  title?: string
  text?: string
  icon: any
  textButton: string
  variant?: 'success' | 'error'
  onClickButton: any
}

function StatusModal({
  isOpen,
  onClose = noop,
  notCloseModal = false,
  text,
  title,
  icon,
  textButton,
  variant = 'success',
  onClickButton = noop
}: StatusModalProps) {
  const color = variant === 'success' ? 'green' : 'red'

  return (
    <Modal isOpen={isOpen} setOpenModal={notCloseModal ? noop : onClose} title="Status do pagamento">
      <Styles.ModalContent color={variant}>
        <div className="icon-box">{icon}</div>
        <Heading elementType="h2" defaultColor="dark" fontSize="18px">
          {title}
        </Heading>
        <Text>{text}</Text>
        <div className="action-box">
          <Button isOutlined background={color} onClick={onClickButton}>
            {textButton}
          </Button>
        </div>
      </Styles.ModalContent>
    </Modal>
  )
}

export default StatusModal
