import * as Style from './styles'

interface DiseaseProps {
  img: string
  label: string
  selected: boolean
  onClick: () => void
}

export default function Disease({ img, label, selected, onClick }: DiseaseProps) {
  return (
    <Style.DiseaseContainer selected={selected} onClick={onClick}>
      <img src={img} alt={label} />
      <span>{label}</span>
    </Style.DiseaseContainer>
  )
}
