import React, { FunctionComponent } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { DigitalJourney } from '@cannect/new-components/organisms'
import animation from '@cannect/constants/animation'
import { Button, Dialog, Typography } from '@cannect/new-components/atoms'

import { DIGITAL_JOURNEY_ROUTES, STEPS_ENUM } from './constants'
import * as DigitalJourneySteps from './Steps'
import { TStepName } from './types'
import { Route } from 'react-router'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { LuChevronLeft } from 'react-icons/lu'

const STEP_COMPONENTS: Record<TStepName, FunctionComponent> = {
  [STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT]: DigitalJourneySteps.SendPrescription,
  [STEPS_ENUM.AUTH_INFORMATIONS]: DigitalJourneySteps.Auth,
  [STEPS_ENUM.CART]: DigitalJourneySteps.Cart,
  [STEPS_ENUM.SEND_IDENTITY_DOCUMENT]: DigitalJourneySteps.SendIdentification,
  [STEPS_ENUM.CONFIRM_IDENTITY_INFORMATIONS]: DigitalJourneySteps.ConfirmIdentification,
  [STEPS_ENUM.SEND_PROOF_OF_ADDRESS_DOCUMENT]: DigitalJourneySteps.SendProofOfAddress,
  [STEPS_ENUM.CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS]: DigitalJourneySteps.ConfirmProofOfAddress,
  [STEPS_ENUM.ORDER_PAYMENT]: DigitalJourneySteps.OrderPayment,
  [STEPS_ENUM.ORDER_SUMMARY]: DigitalJourneySteps.OrderSummary,
  [STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION]: DigitalJourneySteps.GenerateAndSignProcuration,
  [STEPS_ENUM.SEND_ANVISA_AUTHORIZATION]: DigitalJourneySteps.SendAnvisaAuthorization,
  [STEPS_ENUM.JOURNEY_FINISHED]: DigitalJourneySteps.JourneyFinished
}

export const DigitalJourneyPage = () => {
  const { currentStep, goBack, canGoBack, showPaymentWarningModal, setShowPaymentWarningModal } = useDigitalJourney()

  if (!currentStep) return null
  // adicionar botao para criar nova jornada quando for paga
  return (
    <>
      <div className="absolute inset-0 left-0 top-0 -z-[1] h-[100dvh] w-screen overflow-hidden bg-primary-50" />
      <div className="mx-auto flex min-h-full w-full max-w-screen-xs flex-col gap-4 px-8 pb-6 pt-4">
        {canGoBack && (
          <Button
            onClick={goBack}
            unstyled
            className="gap-1 self-start font-medium text-primary-700"
            icon={<LuChevronLeft />}
            iconPlacement="left">
            Voltar
          </Button>
        )}
        <DigitalJourney.Progress currentStep={currentStep} />
        <AnimatePresence initial={false} mode="popLayout">
          <motion.div key={currentStep} {...animation.fadeInUp} className="flex flex-grow">
            {Object.entries(DIGITAL_JOURNEY_ROUTES).map(([step, path]) => {
              const StepComponent = STEP_COMPONENTS[step]
              return StepComponent ? <Route key={path} path={path} component={StepComponent} exact /> : null
            })}
          </motion.div>
        </AnimatePresence>
      </div>
      <Dialog.Root open={showPaymentWarningModal} onOpenChange={setShowPaymentWarningModal}>
        <Dialog.Content>
          <Dialog.Header className="mt-4">
            <Dialog.Title>
              <Typography.Heading type="headingSix" className="text-center leading-relaxed text-primary-700">
                Você já realizou o pagamento deste pedido.
              </Typography.Heading>
            </Dialog.Title>
            <Dialog.Description>
              <Typography.Text type="paragraphTwo" className="text-center">
                Não é possível voltar para os passos anteriores.
              </Typography.Text>
            </Dialog.Description>
          </Dialog.Header>
        </Dialog.Content>
      </Dialog.Root>
    </>
  )
}
