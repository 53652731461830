import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const CheckboxesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 1.5rem;

  gap: 2rem;

  label {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    color: #424242;
    font-size: 16px;
  }
`
export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 2.5rem 1.5rem;
  border-bottom: 1px solid #ecece2;

  width: 100%;

  textarea {
    border: 1.34639px solid #696969;
    font-weight: 300;
    border-radius: 6.73195px;
    height: 200px;
    padding-left: 1rem;
    padding-top: 1rem;
    width: 100%;
  }

  > h2 {
    color: #424242;
    text-align: left;
    margin-bottom: 2rem;
  }

  h3 {
    color: ${temporaryTheme.colors.tertiary};
    width: 100%;
    font-size: 2rem;
  }

  p.product-explanation {
    color: ${temporaryTheme.colors.tertiary};
    font-weight: 300;
    max-width: 600px;
    text-align: center;
    margin-top: 2rem;
  }

  >button {
    color: #748351;
    background-color: #fff;
    border: 2px solid #748351;
    margin-top: 2rem;
  }

  > object {
    border: 1px solid #ecece2;
    border-radius: 20px;
    margin-top: 1rem;
  }

  textarea {
    resize: none;
  }

  @media ${MOBILE_WIDTH} {
    width: 98%;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  width: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  button {
    min-width: 200px;
    height: 38px;
    min-height: 38px;
  }

  ${media.mobileWithTablet`
    flex-direction: column-reverse;
    button {
      width: 100%;
    }

    div {
      width: 100%;
      button{
        width: 50%;
      }
    }
  `}

  ${media.mobile`
    div {
      button{
        width: 100%;
      }
      flex-direction: column-reverse;
    }
  `}
`

export const RemoveProduct = styled.button`
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  border: none;
  width: 0px;
  color: #DC3545 !important;
  text-decoration: underline;
  font-weight: 300;
  font-size: 11px;
  margin-left: auto;
  margin-right: 4rem;
`

export const AddProduct = styled.button`
  background-color: transparent;
  border: none;
  width: 0px;
  color: #21976e !important;
  text-decoration: underline;
  font-weight: 300;
  font-size: 11px;
  margin-left: auto;
  margin-right: 4rem;
  width: 100%;
  margin-top: 12px;
`

export const NoAvailableBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .icon-text {
    display: flex;
    align-items: center;
    gap: ${temporaryTheme.spacing.space2};
    font-family: 'Spartan', sans-serif;
    color: ${temporaryTheme.colors.red};
    svg {
      width: 22px;
      height: 22px;
    }
  }
`

export const ButtonTransp = styled.button`
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  width: 89px;
  height: 36px;
  background-color: transparent;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #748351;
`

