import { Typography } from 'new-components/atoms/Typography'

interface TableNoResultsFoundProps {
  colSpan: number
}

const TableNoResultsFound = ({ colSpan }: TableNoResultsFoundProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="flex h-40 w-full items-center justify-center">
          <Typography.Text type="captionOne">Nenhum resultado encontrado</Typography.Text>
        </div>
      </td>
    </tr>
  )
}

export default TableNoResultsFound
