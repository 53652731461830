import { ReactNode } from 'react'
import * as Styles from './styles'

interface PageTitleProps {
  children: ReactNode
}

function PageTitle({ children }: PageTitleProps) {
  return <Styles.PageTitleContainer>{children}</Styles.PageTitleContainer>
}

export default PageTitle
