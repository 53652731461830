/* eslint-disable no-nested-ternary */
import InvisibleButton from 'components/InvisibleButton'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import useSignedUser from 'hooks/useSignedUser'
import Button from 'new-components/Button'
import Input from 'new-components/Input'
import LinkAsButton from 'new-components/LinkAsButton'
import Select from 'new-components/Select'
import { FU_LIST } from 'pages/Registration/components/Forms/components/ValidationForm/constants'
import { useMemo } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { formatZipCode } from 'utils/formatters'
import { VariableWidthContainer } from '../PersonalData/styles'
import { getFieldError, getGoBackLink } from './helpers'
import useRegisterAddress from './hooks/useRegisterAddress'
import * as Styles from './styles'

export interface RegisterAddressProps {
  addressId?: string
}

export default function RegisterAddress({ addressId }: RegisterAddressProps) {
  const {
    signedUser: { registration_type }
  } = useSignedUser()
  const {
    fetchedAddress,
    zipCode,
    street,
    number,
    complement,
    setComplement,
    district,
    city,
    fu,
    additionalInfo,
    setAdditionalInfo,
    loading,
    isZipCodeInvalid,
    errors,
    changeFieldValue,
    handleDeleteAddress,
    onRegisterAddressSubmit
  } = useRegisterAddress({ addressId })

  const goBackLink = useMemo(() => getGoBackLink(registration_type), [registration_type])

  return (
    <Styles.RegisterAddressContainer>
      <Loading loading={loading} />
      <PageTitle>
        <LinkAsButton to={goBackLink} buttonStyle="ghost" background="green">
          <BiChevronLeft />
        </LinkAsButton>{' '}
        {loading ? 'Carregando endereço...' : fetchedAddress ? 'Alterar endereço' : 'Cadastrar endereço'}
      </PageTitle>

      <Styles.FieldsContainer onSubmit={event => onRegisterAddressSubmit(event, goBackLink)}>
        <Styles.FieldsWrapper>
          <Input
            label="CEP*"
            placeholder="Digite seu CEP aqui"
            value={zipCode}
            errorMessage={isZipCodeInvalid ? 'CEP inválido' : getFieldError('zipCode', errors)}
            onChange={event => changeFieldValue('zipCode', formatZipCode(event.target.value))}
            subtitle={
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noreferrer"
                style={{ marginTop: '-0.5rem', color: '#4b5dff' }}
              >
                Não sei meu CEP
              </a>
            }
          />
          <Input
            label="Logradouro*"
            placeholder="Rua, avenida, praça..."
            value={street}
            errorMessage={getFieldError('street', errors)}
            onChange={event => changeFieldValue('street', event.target.value)}
          />
          <Input
            label="Número*"
            placeholder="Digite seu número aqui"
            value={number}
            errorMessage={getFieldError('number', errors)}
            onChange={event => changeFieldValue('number', event.target.value)}
          />
        </Styles.FieldsWrapper>
        <Styles.FieldsWrapper>
          <Input
            label="Complemento"
            placeholder="Bloco, apartamento, casa..."
            value={complement}
            onChange={event => setComplement(event.target.value)}
          />
          <Input
            label="Bairro*"
            placeholder="Digite seu bairro aqui"
            value={district}
            errorMessage={getFieldError('district', errors)}
            onChange={event => changeFieldValue('district', event.target.value)}
          />
          <Input
            label="Cidade*"
            placeholder="Informe sua cidade"
            value={city}
            errorMessage={getFieldError('city', errors)}
            onChange={event => changeFieldValue('city', event.target.value)}
          />
        </Styles.FieldsWrapper>
        <Styles.FieldsWrapper>
          <VariableWidthContainer width="33%">
            <Select
              id="fu-select"
              label="Estado*"
              placeholder="Selecione seu estado"
              options={FU_LIST}
              value={fu}
              errorMessage={getFieldError('fu', errors)}
              onChange={newValue => changeFieldValue('fu', newValue)}
            />
          </VariableWidthContainer>
          <VariableWidthContainer width="67%">
            <Input
              label="Informações adicionais"
              placeholder="Referências ou informações extras relevantes para entrega"
              value={additionalInfo}
              onChange={event => setAdditionalInfo(event.target.value)}
            />
          </VariableWidthContainer>
        </Styles.FieldsWrapper>

        <Styles.ButtonsWrapper isEditingAddress={fetchedAddress !== undefined}>
          {fetchedAddress && (
            <InvisibleButton
              type="button"
              color="#E24444"
              style={{ textDecoration: 'underline' }}
              onClick={() => handleDeleteAddress(goBackLink)}
            >
              EXCLUIR
            </InvisibleButton>
          )}
          <Button type="submit">SALVAR ENDEREÇO</Button>
        </Styles.ButtonsWrapper>
      </Styles.FieldsContainer>
    </Styles.RegisterAddressContainer>
  )
}
