import styled from 'styled-components'

export const CantUncheckWarningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  background: #f9e4cd;

  margin-bottom: 1.5rem;

  p {
    text-align: center;
    line-height: 1.5rem;
    font-weight: 700;
  }
`
