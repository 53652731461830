import React from 'react'

export default function Truck({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14.9646 6.80226H18.5867C18.7222 6.80138 18.8548 6.84151 18.9671 6.91738C19.0794 6.99326 19.1661 7.10132 19.2158 7.22738L20.4062 10.2033"
        stroke="black"
        strokeWidth="0.906931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3606 12.2432H14.9646"
        stroke="black"
        strokeWidth="0.906931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9849 18.3654C17.1119 18.3654 18.0255 17.4518 18.0255 16.3248C18.0255 15.1978 17.1119 14.2842 15.9849 14.2842C14.8579 14.2842 13.9443 15.1978 13.9443 16.3248C13.9443 17.4518 14.8579 18.3654 15.9849 18.3654Z"
        stroke="black"
        strokeWidth="0.906931"
        strokeMiterlimit="10"
      />
      <path
        d="M5.78156 18.3654C6.90855 18.3654 7.82216 17.4518 7.82216 16.3248C7.82216 15.1978 6.90855 14.2842 5.78156 14.2842C4.65457 14.2842 3.74097 15.1978 3.74097 16.3248C3.74097 17.4518 4.65457 18.3654 5.78156 18.3654Z"
        stroke="black"
        strokeWidth="0.906931"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9438 16.3247H7.82202"
        stroke="black"
        strokeWidth="0.906931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.74129 16.3241H2.04079C1.86039 16.3241 1.68738 16.2524 1.55982 16.1249C1.43226 15.9973 1.3606 15.8243 1.3606 15.6439V6.12112C1.3606 5.94072 1.43226 5.76771 1.55982 5.64014C1.68738 5.51258 1.86039 5.44092 2.04079 5.44092H14.9646V14.5556"
        stroke="black"
        strokeWidth="0.906931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9646 10.2026H20.4062V15.6442C20.4062 15.8246 20.3345 15.9976 20.207 16.1252C20.0794 16.2528 19.9064 16.3244 19.726 16.3244H18.0255"
        stroke="black"
        strokeWidth="0.906931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
