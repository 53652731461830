import Heading from 'new-components/Heading'
import * as S from './styles'

interface ProductHeadingProps {
  translated_name: string
  brand_name: string
  sku: number | string
}

export function ProductHeading({ brand_name, sku, translated_name }: ProductHeadingProps) {
  return (
    <S.ProductHeadingWrapper>
      <Heading defaultColor="darkGrey" fontSize="34px" fontWeight={500} verticalSpace="xsmall">
        {translated_name}
      </Heading>

      <S.InfoBox>
        <S.SkuDetailsBox>
          {brand_name} <S.SeparatorCircle /> SKU: {sku}
        </S.SkuDetailsBox>
      </S.InfoBox>
    </S.ProductHeadingWrapper>
  )
}
