import React from 'react'
import AccountHeader from '../AccountComponent/AccountHeader'
import { Container } from './styles'
import CardBuyStatus from './CardBuyStatus'
import { gene } from '../../assets/img'

const pedido = {
  status: true,
  data: '29 de fevereiro de 2022',
  id: 982321221,
  dataEntrega: '02/03/2022',
  total: '491,80',
  img: gene,
  nomeProduto: 'Cannect Gene Básico',
  descricaoProduto: 'Cannect Gene Básico'
}
const pedidos = {
  status: false,
  data: '29 de fevereiro de 2022',
  id: 982321221,
  dataEntrega: '02/03/2022',
  total: '491,80',
  img: gene,
  nomeProduto: 'Cannect Gene Básico',
  descricaoProduto: 'Cannect Gene Básico'
}

function DemandComponent() {
  return (
    <Container>
      <AccountHeader title="Pedidos" />
      <CardBuyStatus pedido={pedidos} />
      <CardBuyStatus pedido={pedido} />
    </Container>
  )
}

export default DemandComponent
