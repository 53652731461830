/* eslint-disable consistent-return */
import { useState } from 'react'

import {
  useAuthInformationMutation,
  useSendPrescriptionDocumentMutation
} from '@cannect/services/resources/digitalJourney'
import { FORM_SCHEMAS } from '../../constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useHistory } from 'react-router'
import { TDigitalJourneyFormData } from '../../types'

type TFormFields = TDigitalJourneyFormData['auth_informations']

export const useAuthStep = () => {
  const history = useHistory()
  const [showLoginForm, setShowLoginForm] = useState(false)
  const { formData, updateFormData } = useDigitalJourney()

  const initialInformationsForm = useForm<TFormFields>({
    mode: 'onBlur',
    defaultValues: {
      ...formData?.auth_informations
    },
    resolver: zodResolver(FORM_SCHEMAS.auth_informations)
  })

  const { control } = initialInformationsForm

  const { mutateAsync: authMutateAsync } = useAuthInformationMutation()
  const { mutate: sendPrescriptionMutate, isPending: isSendPrescriptionPending } = useSendPrescriptionDocumentMutation()

  const onSubmit = async (data: TFormFields) => {
    if (!formData?.common_fields?.digital_journey_id) return

    return authMutateAsync(
      {
        digital_journey_id: formData.common_fields.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            auth_informations: {
              ...data
            },
            common_fields: {
              ...formData.common_fields,
              form_steps_order: response?.form_steps_order,
              order_id: response?.order_id,
              user_id: response?.user_id
            }
          })
          if (response?.next_step) {
            history.push(response.next_step)
          }
        }
      }
    )
  }

  const handleSuccessLogin = async (user_id: number) => {
    await sendPrescriptionMutate(
      {
        user_id,
        digital_journey_id: formData.common_fields.digital_journey_id,
        document_id: formData?.send_prescription_document?.prescription_file[0]?.id ?? undefined
      },
      {
        onSuccess: (response) => {
          updateFormData({
            common_fields: {
              ...formData?.common_fields,
              form_steps_order: response?.form_steps_order,
              digital_journey_id: response?.digital_journey_id,
              order_id: response?.order_id,
              user_id: response?.user_id
            }
          })
          if (response?.next_step) {
            history.push(response.next_step)
          }
        }
      }
    )
  }

  return {
    isSendPrescriptionPending,
    initialInformationsForm,
    onSubmit,
    control,
    showLoginForm,
    setShowLoginForm,
    handleSuccessLogin
  }
}
