import { svgCursoIntrodutorio } from 'assets/svg'
import EducateAside from 'components/CannectEducate/EducateAside'
import EducateNavigator from 'components/CannectEducate/EducateNavigator'
import SocialMedia from 'components/SocialMedia'
import TabContentTitle from 'components/CannectEducate/TabContentTitle'
import TabsNavigator from 'components/TabsNavigator'
import YouTubeFrame from 'components/YouTubeFrame'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as EducateStyle from 'styles/cannectEducate'
import LessonTitle from 'components/LessonTitle'
import { Lesson, useIntrodutoryCourse } from 'hooks/useIntrodutoryCourse'
import { Button } from 'design-cannect'
import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'
import * as Style from './styles'
import TeacherBiography from './TeacherBiography'

interface PageParams {
  id: string
}

export default function CourseLesson() {
  const history = useHistory()
  const { id } = useParams<PageParams>()
  const { lessons, loading, fetchCourse } = useIntrodutoryCourse()
  const [selectedLesson, setSelectedLesson] = useState<Lesson>()
  const [nextLesson, setNextLesson] = useState<Lesson>()

  useEffect(() => {
    fetchCourse()
  }, [])

  useEffect(() => {
    const newId = parseInt(id, 10)

    const newSelectedLesson = lessons[newId - 1]
    if (newSelectedLesson) {
      if (newId + 1 <= lessons.length) {
        setNextLesson(lessons[newId])
      } else {
        setNextLesson(undefined)
      }
      setSelectedLesson(newSelectedLesson)
    }
  }, [id, lessons])

  function handleRedirectToCoursesHome() {
    history.push('/educa/cursos-introdutorios')
  }

  function handleRedirectToNextLesson() {
    history.push(`/educa/cursos-introdutorios/${nextLesson?.id}`)
  }

  return (
    <EducateStyle.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Educa', link: '/educa' },
          { label: 'Curso Introdutório', link: `/educa/cursos-introdutorios/${id}` }
        ]}
      />
      <TabsNavigator />
      <EducateNavigator />

      <EducateStyle.Content>
        <EducateAside />

        <EducateStyle.Wrapper>
          <SocialMedia message="Acesse já esta aula do curso introdutório Cannect!" />

          <TabContentTitle title="Curso Introdutório" titleImg={svgCursoIntrodutorio} />

          {!loading && selectedLesson ? (
            <>
              <LessonTitle>{selectedLesson.title}</LessonTitle>
              <p className="course-description">{selectedLesson.description}</p>
              <YouTubeFrame url={selectedLesson.url} title={selectedLesson.title} />
              <Style.ActionsContainer>
                <Button outlined onClick={() => handleRedirectToCoursesHome()}>
                  Sobre o curso
                </Button>
                {nextLesson && (
                  <Button outlined onClick={() => handleRedirectToNextLesson()}>
                    {nextLesson.title}
                  </Button>
                )}
              </Style.ActionsContainer>
              <TeacherBiography teacher={{ id: 0, ...selectedLesson.cn_teacher }} />
            </>
          ) : (
            <Loading loading={loading} />
          )}
        </EducateStyle.Wrapper>
      </EducateStyle.Content>
    </EducateStyle.Container>
  )
}
