import styled from 'styled-components'
import { theme } from 'design-cannect'
import { MOBILE_WIDTH } from 'utils/constants'

export const TabContentTitleContainer = styled.div<{ newStyle?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  width: 100%;
  margin-bottom: ${({ newStyle }) => (newStyle ? '0' : '1rem;')};

  @media ${MOBILE_WIDTH} {
    justify-content: ${({ newStyle }) => (newStyle ? 'flex-start' : 'center')};
  }
`

export const TitleContent = styled.div<{ newStyle?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ newStyle }) => (newStyle ? '15px' : '0.5rem')};

  h1 {
    font-size: 1.5rem;
    font-family: ${({ newStyle }) => (newStyle ? 'Spartan, sans-serif' : null)};
    color: ${({ newStyle }) => (newStyle ? theme.colors.darkGrey : theme.colors.tertiary)};
    margin-bottom: -5px;
  }

  img {
    max-width: ${({ newStyle }) => (newStyle ? '30px' : '25px;')};
  }

  span {
    font-family: ${({ newStyle }) => (newStyle ? 'Nunito, sans-serif' : null)};
    font-weight: ${({ newStyle }) => (newStyle ? '400' : null)};
    color: ${({ newStyle }) => (newStyle ? theme.colors.darkGrey : theme.colors.secondary)};
    font-size: ${({ newStyle }) => (newStyle ? '1.25rem' : '1.05rem')};
  }
`
