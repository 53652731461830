import { Tooltip } from 'antd'
import useMediaQuery from 'hooks/useMediaQuery'
import { OrderItem } from 'pages/Account/components/pages/MyOrders/types'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Styles from './styles'

interface ItemsTableProps {
  orderItems: OrderItem[]
}
export default function ItemsTable({ orderItems }: ItemsTableProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const nameMaxLength = isMobile ? 29 : 170

  return (
    <Styles.ItemsTableWrapper>
      <Styles.ItemsTableContainer>
        <thead>
          <tr>
            <th>
              <Styles.FirstCell>PRODUTO</Styles.FirstCell>
            </th>
            <th>
              <Styles.SecondCell>QUANT.</Styles.SecondCell>
            </th>
            <th>
              <Styles.ThirdCell>VALOR UN.</Styles.ThirdCell>
            </th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map(item => {
            let isLongerThanMaxLength = false
            const nameLength = item.name.length
            let formattedName = item.name
            if (nameLength >= nameMaxLength) {
              formattedName = `${item.name.substring(0, nameMaxLength)}...`
              isLongerThanMaxLength = true
            }
            return (
              <tr key={item.id}>
                <td>
                  <Styles.FirstCell>
                    <Styles.ProductImageWrapper>
                      <img src={item.picture} alt="Foto" />
                    </Styles.ProductImageWrapper>
                    {isLongerThanMaxLength ? (
                      <Tooltip
                        title={item.name}
                        trigger={isMobile ? 'click' : 'hover'}
                        placement="top"
                        style={{ zIndex: 1100 }}
                      >
                        <span>{formattedName}</span>
                      </Tooltip>
                    ) : (
                      formattedName
                    )}
                  </Styles.FirstCell>
                </td>
                <td>
                  <Styles.SecondCell>{item.quantity}</Styles.SecondCell>
                </td>
                <td>
                  <Styles.ThirdCell>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(item.unitPrice)}
                  </Styles.ThirdCell>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Styles.ItemsTableContainer>
    </Styles.ItemsTableWrapper>
  )
}
