import LoadingContainer from 'components/LoadingContainer'
import { useBlogFilter } from 'hooks/useBlogFilter'
import { debounce } from 'lodash'
import { Card } from 'new-components/blogComponents/Card'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import Base, { regexInContent } from '../Base'
import * as Styles from '../styles'
import { CardListCategory } from './styles'
import { useGetStrapiPosts } from '@cannect/services/resources/strapi'

export default function Categories() {
  const [search, setSearch] = useState('')
  const { categorySelected, slugCategory } = useBlogFilter()
  const history = useHistory()
  const { category } = useParams() as any

  const debouncedSearch = useCallback(
    debounce((value) => setSearch(value), 500),
    []
  )

  const { data: postsFiltered, isLoading } = useGetStrapiPosts({
    filterQuery: `filters[$and][0][categories][slug][$eq]=${slugCategory}&filters[$or][0][title][$contains]=${search}&filters[$or][1][content][$contains]=${search}&populate=categories&populate=image&populate=author`
  })

  const handleSearchText = (value: string) => {
    debouncedSearch(value)
  }

  const handleSelectPost = ({ slug, post }: any) => {
    history.push(`/blog/${slug}/${post}`)
  }

  return (
    <Base
      pathBreadCrumb={[{ label: categorySelected, link: `${category}` }]}
      handleSearch={(value) => handleSearchText(value)}>
      <Styles.SectionCategory>
        <Styles.HeadingCategory>{categorySelected}</Styles.HeadingCategory>
        <CardListCategory isFiltered={postsFiltered && postsFiltered?.data?.length < 4}>
          {isLoading ? (
            <div className="loading-box">
              <LoadingContainer loading />
            </div>
          ) : (
            postsFiltered?.data?.map((item) => (
              <Card
                key={item?.id}
                title={item?.attributes?.title}
                text={regexInContent(item?.attributes?.content)}
                image={`${import.meta.env.VITE_APP_STRAPI_URL}${item?.attributes?.image?.data?.attributes.url}`}
                handleSelectPost={() => handleSelectPost({ slug: category, post: item?.attributes.slug })}
              />
            ))
          )}
        </CardListCategory>
      </Styles.SectionCategory>
    </Base>
  )
}
