import { useEffect, useState } from 'react'
import * as Styles from './styles'

interface ImageLazyTypes {
  image: string
  alt: string
  imageLoading?: string
}

export function ImageLazy({ image, alt, imageLoading }: ImageLazyTypes) {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setImageLoaded(true)
    }

    img.src = image
  }, [image])

  return (
    <Styles.Wrapper>
      <Styles.ImageLoad src={imageLoading} alt={alt} isLoaded={imageLoaded} loading="lazy" />
      <Styles.Image src={image} alt={alt} isLoaded={imageLoaded} loading="lazy" />
    </Styles.Wrapper>
  )
}
