import { useCannectAssistant } from 'hooks/useCannectAssistant'
import ShareRecipe from '../ShareRecipe'
import PrescriptionReview from '../PrescriptionReview'
import ProductsSelection from './ProductsSelection'
import PrescriptionInfo from './PrescriptionInfo'
import * as Style from './styles'

export default function AlreadyKnow() {
  const { step } = useCannectAssistant()

  return (
    <Style.NeedHelpContainer>
      {step === 1 && <ProductsSelection />}
      {step === 2 && <PrescriptionInfo />}
      {step === 3 && <PrescriptionReview />}
      {(step === 4 || step === 5) && <ShareRecipe />}
    </Style.NeedHelpContainer>
  )
}
