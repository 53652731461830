import React from 'react'
import { Container } from './styles'
import Text from '../../Text'
import CardDetailsProcuct from '../CardDetailsProduct'

function CardDetails() {
  return (
    <Container>
      <div className="header">
        <div className="descripiton">
          <Text type="md" bold color="tertiary">
            Endereço de envio
          </Text>
          <div>
            <Text type="md" color="tertiary">
              Minha Casa Rua Avanhandava, 250 apt 99, Bela Vista, São Paulo, SP, 01303-000
            </Text>
          </div>
        </div>
        <div className="descripiton">
          <Text type="md" bold color="tertiary">
            Paciente
          </Text>
          <div>
            <Text type="md" color="tertiary">
              Rodrigo Moser de Sena
            </Text>
          </div>
        </div>
        <div className="descripiton">
          <Text type="md" bold color="tertiary">
            Endereço de envio
          </Text>
          <div className="acount">
            <Text type="md" color="tertiary">
              Subtotal do(s) item(ns):
            </Text>
            <Text type="md" color="tertiary">
              R$122,90
            </Text>
          </div>
          <div className="acount">
            <Text type="md" color="tertiary">
              Custo de importação:
            </Text>
            <Text type="md" color="tertiary">
              R$12,90
            </Text>
          </div>
          <div className="acount">
            <Text type="md" color="tertiary">
              Descontos:
            </Text>
            <Text type="md" color="tertiary">
              R$32,90
            </Text>
          </div>
          <div className="acount">
            <Text type="md" bold color="tertiary">
              Total:
            </Text>
            <Text type="md" bold color="tertiary">
              R$102,90
            </Text>
          </div>
        </div>
      </div>
      <div className="product">
        <CardDetailsProcuct />
        <CardDetailsProcuct />
      </div>
    </Container>
  )
}

export default CardDetails
