import { Separator, Typography } from '@cannect/new-components/atoms'
import { TDigitalJourneyFormData } from '@cannect/pages/DigitalJourney/types'
import { formatToReal } from '@cannect/utils/number'

type TCartSummary = {
  summaryFields: TDigitalJourneyFormData['cart']['summary']
  productsQuantity: number
}

export const CartSummary = ({ summaryFields, productsQuantity }: TCartSummary) => (
  <div className="grid grid-cols-5 gap-2">
    <Typography.Text type="captionOne" className="col-span-3">
      Subtotal (sem desconto)
    </Typography.Text>
    <Typography.Text type="captionOne" weight="semibold" className="col-span-2 text-right">
      {formatToReal(summaryFields?.amount_total)}
    </Typography.Text>
    <Typography.Text type="captionOne" className="col-span-3">
      Taxa de Importação
    </Typography.Text>
    <Typography.Text type="captionOne" className="col-span-2 text-right">
      {formatToReal(summaryFields?.shipping_amount)}
    </Typography.Text>
    <Typography.Text
      type="captionOne"
      className={`col-span-3 ${summaryFields?.discount_product > 0 ? 'text-primary-700' : ''}`}>
      Desconto nos Produtos
    </Typography.Text>
    <Typography.Text
      type="captionOne"
      className={`col-span-2 text-right ${summaryFields?.discount_product > 0 ? 'text-primary-700' : ''}`}>
      -{formatToReal(summaryFields?.discount_product)}
    </Typography.Text>
    <Typography.Text type="captionOne" className="col-span-3">
      Desconto na Importação
    </Typography.Text>
    <Typography.Text type="captionOne" className="col-span-2 text-right">
      -{formatToReal(summaryFields?.discount_freight)}
    </Typography.Text>
    <Separator className="col-span-full mt-4" />
    <Typography.Text className="col-span-3" weight="semibold">
      Total{' '}
      <span className="text-xs text-neutral-300">
        ({productsQuantity} {productsQuantity > 1 ? 'itens' : 'item'})
      </span>
    </Typography.Text>
    <Typography.Text weight="semibold" className="col-span-2 text-right">
      {formatToReal(summaryFields?.amount_liquid)}
    </Typography.Text>
  </div>
)
