import { theme } from 'design-cannect'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const SymptomsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: ${theme.colors.tertiary};
    font-weight: 300;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 2rem;

  img {
    width: 200px;
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const DiseasesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;

  .add-other-symptom-button {
    height: 105px;
    width: 125px;
    font-size: 1.1rem;

    margin-top: 51px;
    margin-left: 47.5px;
  }

  @media ${MOBILE_WIDTH} {
    justify-content: center;
  }
`

export const ActionsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  width: 100%;
  margin-top: 2.5rem;
`
