import { useCannectAssistant } from 'hooks/useCannectAssistant'
import SectionsWrapper from '../SectionsWrapper'
import Section from '../SectionsWrapper/Section'
import { ShareWaysWrapper, Button } from './styles'
import ShareButton from './ShareButton'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../../utils/constants'

export default function ShareRecipe() {
  const { createdRecipe, setStep, setPath, clearPrescription } = useCannectAssistant()
  const link = `${window.location.origin}/receitas/${createdRecipe?.token}`
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const restartPrescription = () => {
    clearPrescription()
    setStep(0)
    setPath('')
  }

  return (
    <SectionsWrapper>
      <Section>
        <h2 style={{ color: '#424242' }}>Compartilhar receita:</h2>
        <p style={{ marginBottom: '4rem', fontWeight: 300 }}>
          Garanta que seu paciente receba a receita. Compartilhe também por canais pessoais:
        </p>
        <ShareWaysWrapper isMobile={isMobile}>
          <ShareButton type="whatsapp" link={link} />
          <ShareButton type="copy" link={link} />
          <ShareButton type="showRecipe" link={link} linkPdf={createdRecipe?.link_pdf} />
          <ShareButton type="print" link={link} linkPdf={createdRecipe?.link_pdf} />
        </ShareWaysWrapper>
        <Button onClick={() => restartPrescription()}>Nova prescrição</Button>
      </Section>
    </SectionsWrapper>
  )
}
