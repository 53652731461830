import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  padding: 0 ${temporaryTheme.spacing.space4};
`

export const SectionBase = styled.div`
  margin: 50px 0;
  padding: 30px 0;
`
