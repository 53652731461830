import { ReactNode } from 'react'
import * as Styles from './styles'

interface PathSelectorButtonProps {
  to: string
  icon: ReactNode
  title: string
  description: string
  enabled: boolean
}

function PathSelectorButton({ to, icon, title, description, enabled }: PathSelectorButtonProps) {
  const { pathname, search } = document.location

  return (
    <Styles.PathSelectorButtonContainer
      to={enabled ? to : `${pathname}${search}`}
      enabled={enabled}
      className={title.toLowerCase()}
    >
      {icon}
      <Styles.PathSelectorContentWrapper>
        <h2>
          {title}
          {!enabled && <Styles.CommingSoon>(em breve)</Styles.CommingSoon>}
        </h2>
        <p>{description}</p>
      </Styles.PathSelectorContentWrapper>
    </Styles.PathSelectorButtonContainer>
  )
}

export default PathSelectorButton
