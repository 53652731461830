import { FaUserCircle } from 'react-icons/fa'
import PatientStatusCell from '../../../MyPatients/components/MyPatientsList/components/PatientStatusCell'
import { Button, Typography } from 'new-components/atoms'
import { PatientDetailsProps } from '../..'
import { useQuery } from '@tanstack/react-query'
import { fetchPatientPersonalData, fetchLatestRecipeWithPatient } from '../../services'
import Loading from 'components/Loading'
import { formatLatestRecipe, formatPatientPersonalData } from './helpers'
import { useCannectAssistant } from '@cannect/hooks/useCannectAssistant'
import { useHistory } from 'react-router'

const PatientPersonalData = ({ patientId }: PatientDetailsProps) => {
  const history = useHistory()
  const { data: patientData, isFetching: isFetchingPatient } = useQuery({
    queryKey: ['patient-personal-data', patientId],
    queryFn: () => fetchPatientPersonalData(patientId).then((res) => formatPatientPersonalData(res.patient))
  })
  const { data: latestRecipe, isFetching: isFetchingLatestRecipe } = useQuery({
    queryKey: ['latest-recipe-with-patient', patientId],
    queryFn: () => fetchLatestRecipeWithPatient(patientId).then((res) => formatLatestRecipe(res))
  })

  const {
    clearPrescription,
    changeWholePrescriptionPatient,
    changePrescriptionRecomendation,
    addProductsToPrescription,
    setStep,
    setPath
  } = useCannectAssistant()

  const onNewPrescriptionClick = () => {
    clearPrescription()
    setPath('alreadyKnow')

    if (!latestRecipe) {
      const { cpf, name, whatsapp, phone, email } = patientData!
      changeWholePrescriptionPatient({ cpf, name, email, phone: whatsapp || phone })
      setStep(1)
    } else {
      const {
        recommendation,
        products,
        patient: { cpf, email, phone, name }
      } = latestRecipe
      changeWholePrescriptionPatient({ cpf, name, email, phone })
      changePrescriptionRecomendation(recommendation)
      addProductsToPrescription(products)
      setStep(2)
    }

    history.push('/assistente')
  }

  const dataGroup = 'flex flex-col gap-1 xl:items-start items-center'
  const dataItem = 'flex items-center gap-1'

  if (isFetchingPatient) return <Loading loading />

  if (!patientData) return null

  return (
    <div className="flex flex-col items-end gap-2">
      {!isFetchingLatestRecipe && (
        <Button onClick={onNewPrescriptionClick} className="w-full xl:max-w-48">
          Nova prescrição
        </Button>
      )}

      <div className="flex w-full flex-col justify-center gap-6 rounded bg-gray-50 p-6 pr-12">
        <div className="flex justify-center gap-3 pr-4 xl:pr-0">
          <FaUserCircle className="mt-1 h-10 min-h-10 w-10 min-w-10 text-gray-300" />

          <div className="flex flex-col items-center justify-between xl:w-full xl:flex-row">
            <Typography.Text>{patientData.name}</Typography.Text>
            <PatientStatusCell row={patientData} showNullPlaceholder={false} />
          </div>
        </div>

        <div className="flex flex-col items-center gap-1 xl:flex-row xl:items-start xl:justify-between xl:gap-0 xl:pl-12 xl:pr-56">
          <div className={dataGroup}>
            <div className={dataItem}>
              <Typography.Text type="captionOne">Data de Nascimento: </Typography.Text>
              <Typography.Text type="captionOne" weight="semibold">
                {patientData.birthday || '-'}
              </Typography.Text>
            </div>
            <div className={dataItem}>
              <Typography.Text type="captionOne">Idade: </Typography.Text>
              <Typography.Text type="captionOne" weight="semibold">
                {patientData.age || '-'}
              </Typography.Text>
            </div>
          </div>

          <div className={dataGroup}>
            <div className={dataItem}>
              <Typography.Text type="captionOne">Telefone: </Typography.Text>
              <Typography.Text type="captionOne" weight="semibold">
                {patientData.phone || '-'}
              </Typography.Text>
            </div>
            <div className={dataItem}>
              <Typography.Text type="captionOne">E-mail: </Typography.Text>
              <Typography.Text type="captionOne" weight="semibold">
                {patientData.email || '-'}
              </Typography.Text>
            </div>
          </div>

          <div className={dataItem}>
            <Typography.Text type="captionOne">CPF: </Typography.Text>
            <Typography.Text type="captionOne" weight="semibold">
              {patientData.cpf || '-'}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientPersonalData
