import styled from 'styled-components'

export const InvisibleButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  background: transparent;
  border: none;
  outline: none;

  transition: transform 0.3s;

  a {
    text-decoration: none;
    color: inherit;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
