import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const SocialMediaContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  width: 100%;

  span {
    font-weight: 700;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const Medias = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  @media ${MOBILE_WIDTH} {
    justify-content: center;
    width: 100%;
  }
`
