import { NothingToCompletely, useCannectCare } from 'hooks/useCannectCare'
import { HorizontalContent } from 'pages/CannectCare/styles'
import DeactivatableButton from '../../DeactivatableButton'
import Question from '../Question'

export default function GeneralInfoAssessment() {
  const {
    setStep,
    energy,
    setEnergy,
    physicalAppearanceAcceptance,
    setPhysicalAppearanceAcceptance,
    moneyNeed,
    setMoneyNeed,
    informationAvailability,
    setInformationAvailability,
    leisureActivities,
    setLeisureActivities
  } = useCannectCare()

  function handleEnergyChoice(id: string) {
    setEnergy(id as NothingToCompletely)
  }

  function handlePhysicalAppearanceChoice(id: string) {
    setPhysicalAppearanceAcceptance(id as NothingToCompletely)
  }

  function handleMoneyNeedChoice(id: string) {
    setMoneyNeed(id as NothingToCompletely)
  }

  function handleInformationAvailabilityChoice(id: string) {
    setInformationAvailability(id as NothingToCompletely)
  }

  function handleLeisureActivitiesChoice(id: string) {
    setLeisureActivities(id as NothingToCompletely)
  }

  return (
    <>
      <span>
        As questões seguintes perguntam sobre quão completamente você tem sentido ou é capaz de fazer certas coisas
        nestas últimas duas semanas.
      </span>
      <Question
        title="Você tem energia suficiente para seu dia-a-dia?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleEnergyChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'energy-nothing',
            checked: energy === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'energy-veryLittle',
            checked: energy === 'veryLittle'
          },
          {
            id: 'medium',
            label: 'médio',
            labelId: 'energy-medium',
            checked: energy === 'medium'
          },
          {
            id: 'quite',
            label: 'muito',
            labelId: 'energy-quite',
            checked: energy === 'quite'
          },
          {
            id: 'completely',
            label: 'completamente',
            labelId: 'energy-completely',
            checked: energy === 'completely'
          }
        ]}
      />
      <Question
        title="Você é capaz de aceitar sua aparência física?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handlePhysicalAppearanceChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'physicalAppearanceAcceptance-nothing',
            checked: physicalAppearanceAcceptance === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'physicalAppearanceAcceptance-veryLittle',
            checked: physicalAppearanceAcceptance === 'veryLittle'
          },
          {
            id: 'medium',
            label: 'médio',
            labelId: 'physicalAppearanceAcceptance-medium',
            checked: physicalAppearanceAcceptance === 'medium'
          },
          {
            id: 'quite',
            label: 'muito',
            labelId: 'physicalAppearanceAcceptance-quite',
            checked: physicalAppearanceAcceptance === 'quite'
          },
          {
            id: 'completely',
            label: 'completamente',
            labelId: 'physicalAppearanceAcceptance-completely',
            checked: physicalAppearanceAcceptance === 'completely'
          }
        ]}
      />
      <Question
        title="Você tem dinheiro suficiente para satisfazer suas necessidades?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleMoneyNeedChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'moneyNeed-nothing',
            checked: moneyNeed === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'moneyNeed-veryLittle',
            checked: moneyNeed === 'veryLittle'
          },
          {
            id: 'medium',
            label: 'médio',
            labelId: 'moneyNeed-medium',
            checked: moneyNeed === 'medium'
          },
          {
            id: 'quite',
            label: 'muito',
            labelId: 'moneyNeed-quite',
            checked: moneyNeed === 'quite'
          },
          {
            id: 'completely',
            label: 'completamente',
            labelId: 'moneyNeed-completely',
            checked: moneyNeed === 'completely'
          }
        ]}
      />
      <Question
        title="Quão disponíveis para você estão as informações que precisa no seu dia-a-dia?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleInformationAvailabilityChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'informationAvailability-nothing',
            checked: informationAvailability === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'informationAvailability-veryLittle',
            checked: informationAvailability === 'veryLittle'
          },
          {
            id: 'medium',
            label: 'médio',
            labelId: 'informationAvailability-medium',
            checked: informationAvailability === 'medium'
          },
          {
            id: 'quite',
            label: 'muito',
            labelId: 'informationAvailability-quite',
            checked: informationAvailability === 'quite'
          },
          {
            id: 'completely',
            label: 'completamente',
            labelId: 'informationAvailability-completely',
            checked: informationAvailability === 'completely'
          }
        ]}
      />
      <Question
        title="Em que medida você tem oportunidades de atividade de lazer?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleLeisureActivitiesChoice}
        options={[
          {
            id: 'nothing',
            label: 'nada',
            labelId: 'leisureActivities-nothing',
            checked: leisureActivities === 'nothing'
          },
          {
            id: 'veryLittle',
            label: 'muito pouco',
            labelId: 'leisureActivities-veryLittle',
            checked: leisureActivities === 'veryLittle'
          },
          {
            id: 'medium',
            label: 'médio',
            labelId: 'leisureActivities-medium',
            checked: leisureActivities === 'medium'
          },
          {
            id: 'quite',
            label: 'muito',
            labelId: 'leisureActivities-quite',
            checked: leisureActivities === 'quite'
          },
          {
            id: 'completely',
            label: 'completamente',
            labelId: 'leisureActivities-completely',
            checked: leisureActivities === 'completely'
          }
        ]}
      />

      <HorizontalContent style={{ justifyContent: 'space-between' }}>
        <DeactivatableButton outlined onClick={() => setStep(state => state - 1)}>
          Voltar
        </DeactivatableButton>
        <DeactivatableButton
          onClick={() => setStep(state => state + 1)}
          disabled={
            energy === '' ||
            physicalAppearanceAcceptance === '' ||
            moneyNeed === '' ||
            informationAvailability === '' ||
            leisureActivities === ''
          }
        >
          Próximo
        </DeactivatableButton>
      </HorizontalContent>
    </>
  )
}
