import styled from 'styled-components'

interface StatusIndicatorContainerProps {
  background: string
}
export const StatusIndicatorContainer = styled.span<StatusIndicatorContainerProps>`
  display: flex;
  flex-direction: column;

  width: fit-content;
  border-radius: 999px;
  background: ${props => props.background};

  padding: 0.5rem 1rem;

  label {
    color: #fff;
    text-transform: lowercase;
    font-size: 0.85rem;

    text-align: center;
  }
`
