/* eslint-disable react/function-component-definition */
import { Controller, useFormContext } from 'react-hook-form'
import { useScheduling } from 'hooks/useScheduling'
import React from 'react'
import { IPrescriberInfo } from 'hooks/useScheduling/types'
import { Time, TimeContainer } from './styles'

const AvailableTimes: React.FC<{
  prescriberInfo: IPrescriberInfo | null
}> = ({ prescriberInfo }) => {
  const {
    formState: { errors },
    watch,
    control,
    setValue
  } = useFormContext()

  const { next } = useScheduling()
  const scheduling_time = watch('scheduling_time')
  const selectedPrescriber = watch('prescriber')

  if (!prescriberInfo) return null

  const handleSelectPrescriber = (prescriber: IPrescriberInfo) => {
    setValue('prescriber', prescriber)
    setValue('amountWithDiscount', prescriber.online_appointment_value)
    setValue('creditAmountWithDiscount', prescriber.online_appointment_credit_value)
    next()
  }

  return (
    <TimeContainer>
      <div>
        <Controller
          name="scheduling_time"
          control={control}
          defaultValue={scheduling_time || ''}
          render={({ field }) => (
            <>
              {prescriberInfo.availableHours.map((time: string, index: number) => (
                <Time
                  type="button"
                  key={index}
                  style={
                    time === field.value && prescriberInfo.id === selectedPrescriber?.id
                      ? { backgroundColor: '#769536', color: '#fff', borderColor: '#769536' }
                      : {}
                  }
                  onClick={() => {
                    field.onChange(time)
                    handleSelectPrescriber(prescriberInfo)
                  }}
                >
                  {time}
                </Time>
              ))}
            </>
          )}
        />
      </div>
      <div>{errors.scheduling_time && <span>{errors.scheduling_time.message as string}</span>}</div>
    </TimeContainer>
  )
}

export default AvailableTimes
