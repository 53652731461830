import { Collapse } from 'antd'
import { differenceInMinutes } from 'date-fns' // Importing differenceInMinutes
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { useFormContext } from 'react-hook-form'
import { Container, StyledCollapse } from './styles'
import { lessThan24HoursText, moreThan24HoursText } from './mock'
import { AiFillCaretDown } from 'react-icons/ai'

function AppointmentConditions() {
  const { watch } = useFormContext()
  const scheduling_date = watch('scheduling_date')
  const scheduling_time = watch('scheduling_time')
  const { Panel } = Collapse

  const appointmentDate = new Date(`${scheduling_date}T${scheduling_time}:00`)
  const now = new Date()

  const minutesDifference = differenceInMinutes(appointmentDate, now)

  const hoursDifference = minutesDifference / 60

  const appointmentInLessThan24Hours = hoursDifference <= 24

  const collapseText = appointmentInLessThan24Hours ? lessThan24HoursText : moreThan24HoursText

  return (
    <Container>
      <StyledCollapse expandIconPosition="end" expandIcon={AiFillCaretDown}>
        <Panel header="Condições de cancelamento" key="1">
          <TextDanger fontSize="15px" text={collapseText} />
        </Panel>
      </StyledCollapse>
    </Container>
  )
}

export default AppointmentConditions
