import { theme } from 'design-cannect'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  time {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 160%; */
    color: #000000;
  }
`

export const BoxHeader = styled.div`
  background-color: ${theme.colors.pathway};
  display: flex;
  align-items: center;
  padding: 32px;
  gap: 298px;
  width: 100%;
  height: 112px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;

    width: 100%;
    height: 48px;
    flex-grow: 1;

    .header-title {
      /* width: 270px; */
      height: 41px;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.6;
      span {
        color: ${theme.colors.tertiary};
      }

      p {
        color: ${theme.colors.black};
        font-weight: 300;
      }

      button {
        border: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        gap: 10px;

        /* width: 191px; */
        height: 48px;

        /* Cannect Color Styles/Tertiary */

        background: #115850;
        border-radius: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 14px;
        /* identical to box height, or 100% */

        /* Cannect Color Styles/White */

        color: #ecece2;
      }
    }
  }
`
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 16px 0;
  border: 1px solid ${theme.colors.pathway};
`

export const BoxContent = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h4 {
    margin-bottom: 16px;
  }
`

export const BoxContentProducts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  img {
    width: 87px;
    height: 97px;
  }
  span {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
  }
`
export const BoxFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
  button {
    border: 0;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;

    width: 206px;
    height: 48px;
    border: 2px solid #115850;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #115850;
  }
`

export const PasswordModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .link_whats {
    color: ${theme.colors.tertiary};
    margin-top: 16px;
    a {
      color: ${theme.colors.tertiary};
      text-decoration: underline;
    }
  }

  h1 {
    color: ${theme.colors.tertiary};
    margin-bottom: 2.5rem;
  }

  label {
    font-weight: 300;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  input {
    margin-bottom: 1.5rem;
  }

  span {
    color: red;
    font-size: 0.9rem;
  }
`
