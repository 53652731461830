/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { VariantProps, tv } from 'tailwind-variants'
import { NavLinkProps, Link as RouterDomLink } from 'react-router-dom'
import { CSSProperties } from 'react'
import { cn } from '@cannect/lib/utils'

const link = tv({
  base: 'font-sans text-inherit no-underline disabled:pointer-events-none disabled:opacity-60',
  variants: {
    variant: {
      default: 'font-medium',
      primary: 'rounded bg-primary-800 px-6 py-2 font-bold text-white hover:bg-primary-800/90'
    }
  },

  defaultVariants: {
    variant: 'default'
  }
})

export type TLinkProps = {
  isExternal?: boolean
  to: string
  style?: CSSProperties
} & VariantProps<typeof link> &
  Omit<NavLinkProps, 'to'>

export const Link = ({ variant, className, to, isExternal, target, ...rest }: TLinkProps) => {
  if (isExternal) return <a href={to} target={target} className={cn(link({ variant }), className)} {...rest} />

  return <RouterDomLink to={to} className={`${link({ variant })} ${className}`} {...rest} target={target} />
}
