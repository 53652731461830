import Button from 'new-components/Button'
import { useHistory } from 'react-router'
import * as Styles from './styles'
import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'

export interface BannerHealthProps {
  backgroundImage?: string
}

export default function BannerHealth({ backgroundImage }: BannerHealthProps) {
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const redirectLink = (link = 'https://atendimento.cannect.life/atendimento-cannect') => {
    window.open(link, '_blank', 'noopener')
  }
  return (
    <Styles.Wrapper backgroundImage={backgroundImage}>
      <Styles.Content>
        <Styles.TitleBanner>
          <span>Faça parte</span> da nossa <span>comunidade científica</span>
        </Styles.TitleBanner>
        <Styles.TextBanner>
          Acesso a centenas de artigos científicos e a uma rede de profissionais da saúde conectados para explorar e
          discutir o uso de cannabis medicinal, rede exclusiva para profissionais que realizaram onboarding.
        </Styles.TextBanner>
        <Styles.ActionsContent>
          <Button height={isMobile ? 70 : 45} background="green" onClick={() => history.push('/cadastro')}>
            Cadastre-se
          </Button>
          <Button
            height={isMobile ? 70 : 45}
            background="green"
            isOutlined
            onClick={() => redirectLink('https://api.whatsapp.com/message/TPR5DCAVBUKIO1?autoload=1&app_absent=0')}
          >
            Agende seu bate papo de Boas Vindas
          </Button>
        </Styles.ActionsContent>
      </Styles.Content>
    </Styles.Wrapper>
  )
}
