import * as Tabs from '@radix-ui/react-tabs'
import { media } from 'helpers/mediaQueryStyle'
import styled, { keyframes } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOutToLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`

export const Wrapper = styled(Tabs.Root)``
export const AnimatedContent = styled.div`
  width: 100%;
`
export const Content = styled(Tabs.Content)`
  width: 530px;
  overflow: hidden;
  background: #f2f2f2;

  /*  &[data-state='active'] {
    ${AnimatedContent} {
      animation: ${slideInFromRight} 0.3s ease-in-out forwards;
    }
  }
  &[data-state='inactive'] {
    ${AnimatedContent} {
      animation: ${slideOutToLeft} 0.3s ease-in-out forwards;
    }
  } */

  &:not(#info) {
    &[data-state='active'] {
      ${AnimatedContent} {
        animation: ${slideInFromRight} 0.3s ease-in-out forwards;
      }
    }
    &[data-state='inactive'] {
      ${AnimatedContent} {
        animation: ${slideOutToLeft} 0.3s ease-in-out forwards;
      }
    }
  }

  ${media.mobile`
    width: 100%;
  `}
`

export const SuccessReschedule = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;

  p {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
`

export const Heading = styled.h4`
  font-size: 22px;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  width: 100%;
  text-align: center;
`

export const IconBox = styled.div`
  margin: 20px 0;
  svg {
    color: ${temporaryTheme.colors.sitePrimary};
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;

  button {
    font-family: 'Nunito', sans-serif;
  }
`
