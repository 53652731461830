import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import Text from 'components/Text'

import * as Style from './styles'

function Footer() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Style.Footer isMobile={isMobile}>
      <Text type="lg">Cannect é o maior marketplace de produtos cannábicos na América Latina!</Text>
      <Style.RefButton href="https://www.cannect.life/ecossistema-cannect" target="_blank">
        Nos conheça melhor
      </Style.RefButton>
    </Style.Footer>
  )
}

export default Footer
