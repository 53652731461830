import styled from 'styled-components'

export const OtherDiseaseContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  width: 228px;
  min-width: 228px;
  height: 200px;
  min-height: 200px;

  border-radius: 0.5rem;
  border: 2px solid;
  border-color: #ecece2;
  background: transparent;

  padding: 2rem;

  input {
    min-width: 0 !important;
  }
`
