import styled from 'styled-components'
import { theme } from 'design-cannect'

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;

  align-items: center;
`
export const HelpWrapper = styled.div<any>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  text-align: center;
`

export const Header = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;

  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'null')};

  padding-top: 36px;
  padding-bottom: 36px;
  gap: 5px;

  .href {
    text-decoration: underline;
  }
`

export const RefButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  color: ${theme.colors.secondary};
  font-weight: bold;
  font-size: 15px;
`
export const SubmitButton = styled.button`
  display: flex;

  background-color: ${theme.colors.tertiary};
  color: ${theme.colors.white};

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: ${theme.colors.tertiary};

  margin-top: 50px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
export const SubmitAfterButton = styled.button`
  display: flex;

  background-color: #DADAC2;
  color: ${theme.colors.tertiary};

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #DADAC2;

  margin-top: 50px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  gap: 15px;

  width: 100%;
`

export const TitleWrapper = styled.div`
  padding-top: 20px;

  text-align: center;
`

export const CardWrapper = styled.button<any>`
  display: flex;
  flex-direction: column;
  align-items: start;

  text-decoration: none;

  width: 269px;

  gap: 24px;

  margin: 64px 64px 32px 64px;
  padding: 22px;

  background-color: #ecece2;

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #dadac2;

  transition: transform 0.4s;

  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`

export const LinkCardWrapper = styled.a<any>`
  display: flex;
  flex-direction: column;
  align-items: start;

  text-decoration: none;

  width: 269px;

  gap: 24px;

  margin: 64px 64px 32px 64px;
  padding: 22px;

  background-color: #ecece2;

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #dadac2;

  transition: transform 0.4s;

  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`
