import Select from 'new-components/Select'
import { InputHTMLAttributes, ReactNode, useState } from 'react'
import * as Styles from './styles'

export interface AnimatedInputProps extends InputHTMLAttributes<HTMLInputElement> {
  bgColor?: string
  label: string
  type?: 'text' | 'email' | 'password' | 'number'
  required?: boolean
  color?: 'lightGrey' | 'dark'
  select?: boolean
  setSelectValue?: any
  options?: {
    label: string
    value: string
  }[]
  name?: string
  icon?: ReactNode
}

export default function AnimatedInput({
  bgColor,
  label,
  color,
  type,
  required,
  select,
  setSelectValue,
  options,
  name,
  icon,
  ...res
}: AnimatedInputProps) {
  const [sortValue, setSortValue] = useState('')
  /* eslint-disable  @typescript-eslint/no-non-null-assertion */

  const handleChange = (e: any) => {
    setSortValue(e)
    setSelectValue(e)
  }
  return (
    <Styles.InputBox>
      {select ? (
        <div className="select-animated-filter">
          <Select
            id="interests-filter"
            label=""
            width="100%"
            height="41px"
            gap="0px"
            placeholder="selecione seu interesse"
            value={sortValue}
            onChange={(e: any) => handleChange(e)}
            options={options!}
          />
        </div>
      ) : (
        <>
          <Styles.Input
            name={name}
            type={type || 'text'}
            required={required || false}
            className="input-text"
            bgColor={bgColor}
            color={color}
            {...res}
          />
          {!!icon && <Styles.IconBox>{icon}</Styles.IconBox>}
          <Styles.SpanLayer className="span-text"> {label} </Styles.SpanLayer>
        </>
      )}
    </Styles.InputBox>
  )
}
