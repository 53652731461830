import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const CheckoutContainer = styled.div<{ marginTop: string }>`
  width: 100%;
  max-width: 1340px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  margin-top: ${({ marginTop }) => marginTop};

  @media ${MOBILE_WIDTH} {
    gap: 18px;
  }
`

export const NameRow = styled.div`
  display: flex;
  gap: 21px;
  align-items: center;

  & svg {
    width: 26px;
    height: 26px;
    @media ${MOBILE_WIDTH} {
      width: 18px;
      height: 18px;
    }
  }

  @media ${MOBILE_WIDTH} {
    gap: 12px;
  }
`

export const Text = styled.p<{ marginBottom?: string; color?: string; fontSize?: string; fontWeight?: number }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: ${({ color }) => color || '#5A5555'};
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};

  @media ${MOBILE_WIDTH} {
    font-size: 14px;
  }
`
export const TruncatedText = styled(Text)<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '18px'};
  width: 350px;
  line-height: 1.5em;
  max-height: 3em;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${MOBILE_WIDTH} {
    font-size: 14px;
    width: 100%;
  }
`

export const StepName = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: #5a5555;

  @media ${MOBILE_WIDTH} {
    font-size: 12px;
  }
`

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 62px;
  margin-top: 32px;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  max-width: 940px;
  margin-top: -8px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    border-radius: 10px;
    position: absolute;
    z-index: -1;
    top: 52px;
    transition: all 0.2s ease-in-out;
  }

  @media ${MOBILE_WIDTH} {
    gap: 10px;
    margin-top: 0px;
  }
`

export const Step = styled.div<{ isCurrentStep?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  min-width: 200px;

  &::before {
    content: '';
    display: ${({ isCurrentStep }) => (isCurrentStep ? 'block' : 'none')};
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background-color: ${({ isCurrentStep }) => (isCurrentStep ? '#748351' : '#e0e0e0')};
    position: absolute;
    z-index: 2;
    top: 50px;
    transition: all 0.2s ease-in-out;
  }

  @media ${MOBILE_WIDTH} {
    min-width: unset;
    gap: 5px;
  }
`

export const StepCircle = styled.div<{ isActive: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? '#748351' : 'transparent')};
  border: 1px solid ${({ isActive }) => (isActive ? '#748351' : '#e0e0e0')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${({ isActive }) => (isActive ? '#fff' : '#262626')};
  transition: all 0.2s ease-in-out;

  @media ${MOBILE_WIDTH} {
    width: 19px;
    height: 19px;
    font-size: 11px;
    font-weight: 400;
  }
`

export const StepContent = styled.div<{ activeStep?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`

export const NavigateButtonsWrapper = styled.div<{ usage?: 'identification' | 'review' }>`
  display: flex;
  align-items: center;
  justify-content: ${({ usage }) => (usage === 'identification' ? 'flex-end' : 'space-between')};
  gap: 28px;
  width: 100%;
  margin-top: ${({ usage }) => (usage === 'identification' ? '20px' : '0')};

  button {
    font-weight: 700;
    font-size: 16px;
    min-height: 35px;
    max-height: 35px;
    border-radius: 4px;
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 18px;
    margin-top: ${({ usage }) => (usage === 'identification' ? '14px' : '0')};

    button {
      width: 100%;
    }

    button:last-child {
      order: -1;
    }
  }
`

export const StepTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #748351;
  margin: 0;
  text-align: center;

  @media ${MOBILE_WIDTH} {
    font-size: 14px;
  }
`
