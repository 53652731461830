import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  border-radius: 20px;
  overflow: hidden;
  width: 300px;
  height: 570px;

  &:hover {
    box-shadow: ${temporaryTheme.shadow.shad1};
    cursor: pointer;
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const Title = styled.h2`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: ${temporaryTheme.colors.black};
  padding: ${temporaryTheme.spacing.space3};

  @media ${TABLET_WIDTH} {
    font-size: 15px;
  }
`
export const Text = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: ${temporaryTheme.colors.black};
  padding: ${temporaryTheme.spacing.space3};
  text-align: left;

  p,
  span,
  i,
  h3,
  h2,
  strong {
    all: unset;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: ${temporaryTheme.colors.black};
    text-align: left;

    @media ${TABLET_WIDTH} {
      font-size: 12px;
    }
  }
`
