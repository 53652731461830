import { isValid, parse } from 'date-fns'
import { validateCpf } from 'utils/validators'
import { z } from 'zod'

const requiredMessage = 'Campo obrigatório'
const nonEmptyStringSchema = z.string({ required_error: requiredMessage }).min(1, requiredMessage)

const agreeWithTermsCheck = z.boolean().refine(value => value === true, {
  message: 'Você precisa concordar com as políticas de uso da plataforma de agendamento.'
})

export const AddressSchema = z.object({
  cep: z
    .string()
    .min(1, requiredMessage)
    .refine(
      value => {
        const cep = value.replace(/[\s-]/g, '')
        return cep === '' || /^\d{8}$/.test(cep)
      },
      {
        message: 'CEP inválido'
      }
    ),
  city: nonEmptyStringSchema,
  address: nonEmptyStringSchema,
  number: nonEmptyStringSchema,
  state: nonEmptyStringSchema
})

export const CreditCardFormSchema = z.object({
  address: AddressSchema,
  payment: z.object({
    card_holder_name: nonEmptyStringSchema,
    card_validate: nonEmptyStringSchema.refine(
      value => {
        const [month, year] = value.split('/')
        if (!month || !year || month.length !== 2 || year.length !== 4) return false

        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth() + 1
        const intMonth = parseInt(month, 10)
        const intYear = parseInt(year, 10)

        if (Number.isNaN(intMonth) || Number.isNaN(intYear)) return false
        if (intMonth < 1 || intMonth > 12) return false
        if (intYear < currentYear || (intYear === currentYear && intMonth < currentMonth)) return false

        return true
      },
      {
        message: 'Digite uma data válida'
      }
    ),
    card_number: nonEmptyStringSchema,
    card_cvv: nonEmptyStringSchema
  }),
  agreeWithTerms: agreeWithTermsCheck
})

export const PixFormSchema = z.object({
  address: AddressSchema,
  agreeWithTerms: agreeWithTermsCheck
})

export const validatePhone = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/

export const SimplifiedIdentificationFormSchema = z.object({
  birthday: nonEmptyStringSchema.refine(
    value => {
      const date = parse(value, 'dd/MM/yyyy', new Date())
      return isValid(date)
    },
    {
      message: 'Digite uma data válida'
    }
  ),
  cpf: z.string().refine(value => validateCpf(value), {
    message: 'CPF Inválido'
  }),

  name: nonEmptyStringSchema,
  email: z.string({ required_error: 'Campo obrigatório' }).email('Digite um e-mail válido'),
  whatsapp: nonEmptyStringSchema.regex(validatePhone, 'Digite um telefone válido')
})
