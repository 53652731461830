import { SVGProps } from 'react'

export const MedicineIcon = ({ width = '48', height = '48', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6665 9.85185C4.6665 6.98815 6.98799 4.66666 9.85169 4.66666H35.7776C38.6413 4.66666 40.9628 6.98815 40.9628 9.85185V12.4444C40.9628 14.8304 39.3513 16.8399 37.1574 17.444L39.7019 20.3902C40.5153 21.332 40.9628 22.535 40.9628 23.7794V30.5926C46.6902 30.5926 51.3332 35.2356 51.3332 40.963C51.3332 46.6904 46.6902 51.3333 40.9628 51.3333C40.3711 51.3333 39.791 51.2838 39.2264 51.1886C38.9584 51.2824 38.6702 51.3333 38.3702 51.3333H12.4443C8.14873 51.3333 4.6665 47.8511 4.6665 43.5556V23.7794C4.6665 22.535 5.11405 21.332 5.92742 20.3902L8.47188 17.444C6.27797 16.8399 4.6665 14.8304 4.6665 12.4444V9.85185ZM35.7776 23.7794V31.98C32.6779 33.773 30.5924 37.1245 30.5924 40.963C30.5924 42.8519 31.0974 44.6228 31.9798 46.1481H12.4443C11.0124 46.1481 9.85169 44.9874 9.85169 43.5556V23.7794L15.1628 17.6296H30.4665L35.7776 23.7794ZM35.7776 12.4444H31.653H13.9763H9.85169V9.85185H35.7776V12.4444ZM25.4072 25.4074C25.4072 23.9756 24.2465 22.8148 22.8147 22.8148C21.3828 22.8148 20.2221 23.9756 20.2221 25.4074V28H17.6295C16.1976 28 15.0369 29.1607 15.0369 30.5926C15.0369 32.0244 16.1976 33.1852 17.6295 33.1852H20.2221V35.7778C20.2221 37.2096 21.3828 38.3704 22.8147 38.3704C24.2465 38.3704 25.4072 37.2096 25.4072 35.7778V33.1852H27.9998C29.4317 33.1852 30.5924 32.0244 30.5924 30.5926C30.5924 29.1607 29.4317 28 27.9998 28H25.4072V25.4074ZM40.9628 35.7778C38.0991 35.7778 35.7776 38.0993 35.7776 40.963C35.7776 41.9078 36.0303 42.7936 36.4719 43.5565L40.9629 35.7778C40.9629 35.7778 40.9628 35.7778 40.9628 35.7778ZM45.4539 38.3696L40.9629 46.1481C43.8266 46.1481 46.148 43.8266 46.148 40.963C46.148 40.0182 45.8953 39.1325 45.4539 38.3696Z"
      fill="#748351"
    />
  </svg>
)
