import { ReactNode } from 'react'
import * as Style from './styles'

interface FilterSuggestionDropDownProps {
  children: ReactNode
}

export default function FilterSuggestionDropDown({ children }: FilterSuggestionDropDownProps) {
  return <Style.FilterSuggestionDropDownContainer>{children}</Style.FilterSuggestionDropDownContainer>
}
