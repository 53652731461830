import { HTMLAttributes } from 'react'
import { VariantProps, tv } from 'tailwind-variants'

const badgeVariants = tv({
  base: 'inline-flex items-center justify-center gap-2 border border-solid border-transparent font-sans leading-5 text-white transition-colors focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2',
  variants: {
    variant: {
      default: 'bg-primary-800 hover:bg-primary-800/90',
      primary_light: 'bg-primary-300 text-primary-700 hover:bg-primary-300/90',
      secondary: 'bg-secondary hover:bg-secondary/90',
      secondary_light: 'bg-secondary-300 hover:bg-secondary-300/90',
      neutral: 'bg-neutral-500 hover:bg-neutral-500/90',
      neutral_light: 'bg-neutral-200 hover:bg-neutral-200/90',
      outline: 'border-secondary-800 text-secondary-800 hover:opacity-90',
      warning: 'bg-warning-200 text-neutral-800 hover:bg-warning-200/90'
    },
    size: {
      default: 'px-4 py-0.5 text-sm',
      lg: 'px-4 py-2 text-base'
    },
    isLoading: {
      true: 'animate-pulse bg-gray-200 text-transparent'
    },
    rounded: {
      full: 'rounded-full',
      lg: 'rounded',
      md: 'rounded-md',
      none: 'rounded-none'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
    rounded: 'full'
  }
})

export interface BadgeProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  isLoading?: boolean
}

function Badge({ className, variant, size, rounded, isLoading = false, children, ...props }: BadgeProps) {
  return (
    <div className={badgeVariants({ variant, isLoading, size, rounded, className })} {...props}>
      <span className="font-[inherit] [&>*]:font-[inherit]">{children}</span>
    </div>
  )
}

export { Badge, badgeVariants }
