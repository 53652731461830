import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

interface CardListCategoryProps {
  isFiltered?: boolean
}

export const WrapperCard = styled.main`
  display: flex;
  width: 100%;
  gap: ${temporaryTheme.spacing.space4};
`

export const CardListCategory = styled.div<CardListCategoryProps>`
  ${({ isFiltered }) => css`

display: flex;
align-items: center;
gap ${temporaryTheme.spacing.space3};
flex-wrap: wrap;
width: 100%;
justify-content: ${isFiltered ? 'flex-start' : 'space-between'};

.loading-box {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
`}
`
