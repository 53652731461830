import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  padding: 0 ${temporaryTheme.spacing.space4};
`

export const SelectFaqBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${temporaryTheme.spacing.space4};
  margin-top: 60px;
  button {
    width: 212px;
  }
`

export const ContentFaq = styled.div`
  text-align: center;
`

export const AccordionContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 51px;

  svg {
    width: 32px;
    height: 32px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    color: ${temporaryTheme.colors.darkGrey};
  }
`

export const StartYouJourneySection = styled.div`
  margin-top: 151px;
  width: 100%;
  text-align: left;
  margin-bottom: 50px;
`

export const ImageIcon = styled.img``

export const TitleWithIcon = styled.div`
  margin-top: 38px;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Spartan', sans-serif;
  color: ${temporaryTheme.colors.darkGrey};
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  text-align: left;
  gap: ${temporaryTheme.spacing.space3};
  svg {
    width: 42px;
  }
`

export const CardBox = styled.div`
  margin-top: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space3};
  flex-wrap: wrap;
  cursor: pointer;
`
