import { useEffect, useState } from 'react'
import * as Style from './styles'

export type Progress = `${number}%`

type Color = '#EB9344' | '#F5E457' | '#E1F85B' | '#A3F657' | '#78F560'

interface ProgressBarProps {
  progress: `${number}%`
}

export function ProgressBar({ progress }: ProgressBarProps) {
  const [progressBackground, setProgressBackground] = useState<Color>('#EB9344')

  useEffect(() => {
    const numberProgress = parseFloat(progress.split('%')[0])

    if (numberProgress <= 50) {
      setProgressBackground('#EB9344')
    } else if (numberProgress <= 65) {
      setProgressBackground('#F5E457')
    } else if (numberProgress <= 70) {
      setProgressBackground('#E1F85B')
    } else if (numberProgress <= 83) {
      setProgressBackground('#A3F657')
    } else {
      setProgressBackground('#78F560')
    }
  }, [progress])

  return (
    <Style.ProgressBarContainer>
      <Style.CurrentProgress style={{ width: progress, background: progressBackground }} />
    </Style.ProgressBarContainer>
  )
}
