import { theme } from 'design-cannect'
import { SymptomType } from 'hooks/useCannectCare'
import styled from 'styled-components'

export const ToggleTypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    font-weight: 300;
  }
`

interface ToggleButtonProps {
  type: SymptomType
}
export const ToggleButton = styled.div<ToggleButtonProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.type === 'episodes' ? 'flex-start' : 'flex-end')};

  width: 70px;
  min-width: 70px;
  height: 30px;
  min-height: 30px;

  border: 1px solid #ecece2;
  background: #ecece2;
  border-radius: 2rem;
  padding: 3px;

  transition: justify-content 0.2s;
  cursor: pointer;
`

export const Circle = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;

  background: ${theme.colors.tertiary};
  border-radius: 999px;
`
