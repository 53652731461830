import { theme } from 'design-cannect'
import styled from 'styled-components'

interface ButtonContainerProps {
  outlined?: boolean
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  background: ${props => (props.outlined ? 'transparent' : theme.colors.tertiary)};
  border: 1px solid ${theme.colors.tertiary};
  outline: none;

  color: ${props => (props.outlined ? theme.colors.tertiary : 'white')};
  border-radius: 0.5rem;
  padding: 1rem;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
