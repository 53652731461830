import { generateRandomNumber } from 'utils/randomNumberGenerator'
import * as Style from './styles'

type QuestionProps = {
  title: string
  answers: string[]
}

export default function Question({ title, answers }: QuestionProps) {
  return (
    <Style.QuestionContainer>
      <h2>{title}</h2>
      {answers.map(answer => (
        <p key={generateRandomNumber()}>{answer}</p>
      ))}
    </Style.QuestionContainer>
  )
}
