import { FormProvider, useForm } from 'react-hook-form'
import { QueriesProvider, useQueries } from './hooks/useQueries'
import ListAppointments from './components/ListAppointments'

export interface AppointmentFormProps {
  queries_date: string | null
  queries_time: string | null
}

function MyAppointments() {
  const createAppointmentForm = useForm<AppointmentFormProps>({
    mode: 'onChange',
    shouldUnregister: false,

    defaultValues: {
      queries_date: '',
      queries_time: ''
    }
  })

  return (
    <FormProvider {...createAppointmentForm}>
      <QueriesProvider>
        <ListAppointments />
      </QueriesProvider>
    </FormProvider>
  )
}

export default MyAppointments
