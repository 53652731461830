import React from 'react'

interface IconePerfilPacienteProps extends React.SVGProps<SVGSVGElement> {
  color?: 'default' | 'hover'
}

export default function IconePerfilPaciente({ color, ...rest }: IconePerfilPacienteProps) {
  return (
    <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M49.4271 86.5708C69.9007 86.5708 86.4978 69.9737 86.4978 49.5001C86.4978 29.0266 69.9007 12.4294 49.4271 12.4294C28.9536 12.4294 12.3564 29.0266 12.3564 49.5001C12.3564 69.9737 28.9536 86.5708 49.4271 86.5708Z"
        stroke="#3D3D3D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.4276 61.8568C57.9582 61.8568 64.8737 54.9414 64.8737 46.4107C64.8737 37.8801 57.9582 30.9646 49.4276 30.9646C40.8969 30.9646 33.9814 37.8801 33.9814 46.4107C33.9814 54.9414 40.8969 61.8568 49.4276 61.8568Z"
        stroke="#3D3D3D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6357 77.0712C26.96 72.493 30.5067 68.6479 34.8825 65.962C39.2583 63.2761 44.2924 61.8544 49.4268 61.8544C54.5611 61.8544 59.5952 63.2761 63.9711 65.962C68.3469 68.6479 71.8935 72.493 74.2178 77.0712"
        stroke="#3D3D3D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
