import React, { useRef } from 'react'
import { AiOutlineInfoCircle, AiOutlineUpload } from 'react-icons/ai'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import { noop } from 'lodash'
import Tooltip from 'components/Tooltip'
import * as Style from './styles'
import { ListDocuments } from './ListDocuments'

export interface UploaderDocumentTypes {
  label: string
  documentId: any
  handleChange: (value: any) => void
  inputRef?: any
  tolltip?: any
  children?: any
  status?: 'pending' | 'approved' | 'denied'
  documentData?: any
}

function UploaderDocument({
  label = '',
  documentId,
  handleChange = noop,
  tolltip = null,
  children,
  status = 'pending',
  documentData
}: UploaderDocumentTypes) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [document, setDocument] = React.useState<any>({})

  const handleClick = (input: any) => {
    input?.current?.click()
  }
  const ref = useRef<null | any>(null)

  const changeDocumente = (e: any) => {
    handleChange(e)
    setDocument(e.target.files)
  }

  return (
    <Style.Wrapper>
      <Style.UploadWrapper isMobile={isMobile} status={status}>
        <div className="upload-content">
          <Style.TitleUpload>
            {label}
            {!!tolltip && (
              <Tooltip placement="right" content={tolltip}>
                <AiOutlineInfoCircle />
              </Tooltip>
            )}
          </Style.TitleUpload>
          <Style.NameFile>{document[0]?.name || documentId}</Style.NameFile>
          {children}
        </div>
        <Style.ButtonUpload onClick={() => handleClick(ref)} isValue={document.length > 0 || documentId}>
          <AiOutlineUpload />
          upload
        </Style.ButtonUpload>
        <input type="file" ref={ref} style={{ display: 'none' }} onChange={changeDocumente} />
      </Style.UploadWrapper>
      {document.length > 0 && <ListDocuments documents={[]} onRemove={() => console.log('remove')} />}
    </Style.Wrapper>
  )
}

export default UploaderDocument
