import { createContext, useContext, useMemo, useState } from 'react'
import useFilters from './useFilters'

const FilterProductContext = createContext<any>(null)
FilterProductContext.displayName = 'FilterProductContext'

function FilterProductProvider({ children }: any) {
  const { filters, filter, isFetching, keyFilter, pathologiesRequest, getFilters } = useFilters()
  const [inputValue, setInputValue] = useState<any>(filter)
  const [filterSelected, setFilterSelected] = useState<any[]>([])

  const fireBaseProviderValue = useMemo(
    () => ({
      inputValue,
      setInputValue,
      filter,
      filters,
      isFetching,
      keyFilter,
      pathologiesRequest,
      getFilters,
      filterSelected,
      setFilterSelected
    }),
    [getFilters, inputValue, setInputValue]
  )

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FilterProductContext.Provider value={fireBaseProviderValue}>{children}</FilterProductContext.Provider>
  )
}

const useFilterProduct = () => useContext(FilterProductContext)

export { FilterProductProvider, useFilterProduct }
