import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const StyledButton = styled.button<{ copied: boolean; fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 10px;
  border: 1px solid ${({ copied }) => (copied ? `${temporaryTheme.colors.sitePrimary}` : '#ccc')};
  background-color: ${({ copied }) =>
    copied ? `${temporaryTheme.colors.sitePrimary}` : `${temporaryTheme.colors.link}`};
  color: #fff;
  font-size: 14px;
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
`
