import { Satisfaction, useCannectCare } from 'hooks/useCannectCare'
import { HorizontalContent } from 'pages/CannectCare/styles'
import DeactivatableButton from '../../DeactivatableButton'
import Question from '../Question'

export default function HealthSatisfactionAssessment() {
  const { setStep, healthSatisfaction, setHealthSatisfaction } = useCannectCare()

  function handleOptionChoice(id: string) {
    setHealthSatisfaction(id as Satisfaction)
  }

  return (
    <>
      <span>Veja o que você acha e selecione a que lhe parece a melhor resposta</span>
      <Question
        title="Quão satisfeito(a) você está com a sua saúde?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleOptionChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            labelId: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            checked: healthSatisfaction === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            labelId: 'unsatisfied',
            label: 'insatisfeito',
            checked: healthSatisfaction === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            labelId: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            checked: healthSatisfaction === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            labelId: 'satisfied',
            label: 'satisfeito',
            checked: healthSatisfaction === 'satisfied'
          },
          {
            id: 'verySatisfied',
            labelId: 'verySatisfied',
            label: 'muito satisfeito',
            checked: healthSatisfaction === 'verySatisfied'
          }
        ]}
      />
      <HorizontalContent style={{ justifyContent: 'space-between' }}>
        <DeactivatableButton outlined onClick={() => setStep(state => state - 1)}>
          Voltar
        </DeactivatableButton>
        <DeactivatableButton onClick={() => setStep(state => state + 1)} disabled={healthSatisfaction === ''}>
          Próximo
        </DeactivatableButton>
      </HorizontalContent>
    </>
  )
}
