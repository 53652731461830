export function generateRandomNumber(): string {
  let generatedValue = ''
  const min = Math.ceil(0)
  const max = Math.floor(9)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    generatedValue += Math.floor(Math.random() * (max - min) + min)
  }

  return generatedValue
}
