import {
  IconeAjuda,
  IconeCartao,
  IconeCasa,
  IconeNovaConsulta,
  IconePacote,
  IconePerfilPaciente,
  IconePessoa,
  IconeReceita,
  CannectScience
} from 'assets/svg'

export const PATH_SELECTOR_ICONS = {
  profile: <IconePerfilPaciente />,
  patients: <IconePessoa />,
  orders: <IconePacote />,
  prescription: <IconeReceita />,
  consult: <IconeNovaConsulta />,
  card: <IconeCartao />,
  address: <IconeCasa />,
  help: <IconeAjuda />,
  science: <CannectScience />
}
