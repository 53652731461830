/* eslint-disable react/jsx-no-constructed-context-values */
import { SelectOption } from 'new-components/MultiSelect'
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { z, ZodIssue } from 'zod'

interface RegistrationContextProviderProps {
  children: ReactNode
}

const RegistrationContext = createContext({} as RegistrationContextValue)

export interface RegistrationContextValue {
  path?: RegistrationPath
  setPath: Dispatch<SetStateAction<RegistrationPath | undefined>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>

  typeOfBoard: string
  boardFu: string
  boardNumber: string
  hasPrescribedBefore: string
  typeOfBoardOther: string

  name: string
  nationality: string
  preferredName: string
  setPreferredName: Dispatch<SetStateAction<string>>
  genderIdentity: string
  cpf: string
  rg: string
  rne: string
  birthdate: string
  telephone: string
  setTelephone: Dispatch<SetStateAction<string>>
  cellphone: string
  specialties: string[]
  specialtiesRQE: string[]
  changeSpecialties: (newSpecialties: SelectOption[]) => void
  email: string
  emailConfirmation: string
  password: string
  passwordConfirmation: string
  listAsPrescriber?: boolean

  removeFieldFromErrors: (fieldName: string) => void
  changeFieldValue: (fieldName: string, fieldValue: string | boolean) => void

  errors?: ZodIssue[]
  setErrors: Dispatch<SetStateAction<ZodIssue[] | undefined>>
}

export type RegistrationPath = 'patient' | 'professional'

export function RegistrationContextProvider({ children }: RegistrationContextProviderProps) {
  const [path, setPath] = useState<RegistrationPath>()
  const [loading, setLoading] = useState(false)

  // Validation form
  const [typeOfBoard, setTypeOfBoard] = useState('')
  const [boardFu, setBoardFu] = useState('')
  const [boardNumber, setBoardNumber] = useState('')
  const [hasPrescribedBefore, setHasPrescribedBefore] = useState('')
  const [typeOfBoardOther, setTypeOfBoardOther] = useState('')

  // Registration form
  const [name, setName] = useState('')
  const [nationality, setNationality] = useState('')
  const [preferredName, setPreferredName] = useState('')
  const [genderIdentity, setGenderIdentity] = useState('')
  const [cpf, setCpf] = useState('')
  const [rg, setRg] = useState('')
  const [rne, setRne] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [telephone, setTelephone] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [specialties, setSpecialties] = useState<string[]>([])
  const [specialtiesRQE, setSpecialtiesRQE] = useState<string[]>([])
  const [email, setEmail] = useState('')
  const [emailConfirmation, setEmailConfirmation] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [listAsPrescriber, setListAsPrescriber] = useState<boolean>()

  // Validation
  const [errors, setErrors] = useState<ZodIssue[]>()

  const setFunctions = {
    typeOfBoard: setTypeOfBoard,
    boardFu: setBoardFu,
    boardNumber: setBoardNumber,
    hasPrescribedBefore: setHasPrescribedBefore,
    typeOfBoardOther: setTypeOfBoardOther,

    name: setName,
    nationality: setNationality,
    genderIdentity: setGenderIdentity,
    cpf: setCpf,
    rg: setRg,
    rne: setRne,
    birthdate: setBirthdate,
    cellphone: setCellphone,
    specialties: setSpecialties,
    specialtiesRQE: setSpecialtiesRQE,
    email: setEmail,
    emailConfirmation: setEmailConfirmation,
    password: setPassword,
    passwordConfirmation: setPasswordConfirmation,
    listAsPrescriber: setListAsPrescriber
  }

  const removeFieldFromErrors = (fieldName: string) => {
    setErrors(prevErrors => prevErrors?.filter(error => !error.path.includes(fieldName)))
  }

  const changeFieldValue = useCallback(
    (fieldName: string, newValue: string | string[] | boolean) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const setFunction = setFunctions[fieldName]
      setFunction(newValue)
      if (errors) {
        removeFieldFromErrors(fieldName)
      }
    },
    [errors]
  )

  const changeSpecialties = useCallback(
    (newSpecialties: SelectOption[]) => {
      setSpecialties(newSpecialties.map(specialties => specialties.value))
      setSpecialtiesRQE(newSpecialties.map(specialties => specialties.rqe))
      if (errors) {
        removeFieldFromErrors('specialties')
      }
    },
    [errors]
  )

  useEffect(() => {
    setErrors(undefined)
  }, [path])

  return (
    <RegistrationContext.Provider
      value={{
        path,
        setPath,
        loading,
        setLoading,

        typeOfBoard,
        boardFu,
        boardNumber,
        hasPrescribedBefore,
        typeOfBoardOther,

        name,
        nationality,
        preferredName,
        setPreferredName,
        genderIdentity,
        cpf,
        rg,
        rne,
        birthdate,
        telephone,
        setTelephone,
        cellphone,
        specialties,
        specialtiesRQE,
        changeSpecialties,
        email,
        emailConfirmation,
        password,
        passwordConfirmation,
        listAsPrescriber,

        changeFieldValue,
        removeFieldFromErrors,

        errors,
        setErrors
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}

export default function useRegistration() {
  return useContext(RegistrationContext)
}
