import { useState, useEffect } from 'react'
import { Col, Row, Statistic } from 'antd'
import { PaymentMethod } from 'hooks/useCartCheckout'

interface Props {
  generatePixInfo: any
  payment: PaymentMethod
}

export function CountDown({ generatePixInfo, payment }: Props) {
  const [pixExpired, setPixExpired] = useState<boolean>(false)

  const { Countdown } = Statistic
  const time = Date.now() + (1000 * 60 * 60 * 24 * 2) / 580
  const deadline = !pixExpired ? time : 0

  const onFinish = () => {
    generatePixInfo(payment)
    setPixExpired(true)
  }

  useEffect(() => {
    setPixExpired(false)
  }, [pixExpired])

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Countdown value={deadline} onFinish={onFinish} />
      </Col>
    </Row>
  )
}
