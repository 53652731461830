import { useAuth } from 'hooks/AuthContext'
import useMediaQuery from 'hooks/useMediaQuery'
import { useTokenApi } from 'hooks/useTokenApi'
import { Fragment, useEffect, useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { MOBILE_WIDTH } from 'utils/constants'
import CustomButtom from './CustomButton'
import CustomLink from './CustomLink'
import * as Style from './styles'

interface BreadCrumbProps {
  paths?: {
    label: string
    link: string
    onClick?: () => void
  }[]
}

export default function BreadCrumb({ paths }: BreadCrumbProps) {
  const { deleteTokenApi, tokenApi } = useTokenApi()
  const { getRoles } = useAuth()
  const [userName, setUserName] = useState('')
  const [productsCart, setProductCart] = useState(0)

  const productCart: any = localStorage.getItem('shoppingCart')

  const getProductsCart = () => {
    const signedUser = localStorage.getItem('@CANNECT_USER')
    if (signedUser) {
      setProductCart(JSON.parse(productCart)?.length)
    }
  }

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  useEffect(() => {
    const signedUser = localStorage.getItem('@CANNECT_USER')

    if (signedUser) {
      setUserName(JSON.parse(signedUser)?.name.split(' ')[0])
    }
  }, [])

  useEffect(() => {
    getProductsCart()
  }, [productCart])

  return (
    <Style.BreadCrumbContainer isMobile={isMobile}>
      <Style.PathContainer>
        <CustomLink to="/" active={window.location.pathname === '/'}>
          Início
        </CustomLink>
        <FiChevronRight />
        {paths?.map((path, index) => (
          <Fragment key={path.link}>
            {path.onClick ? (
              <CustomButtom
                active={
                  index === paths.length - 1
                    ? window.location.pathname.includes(path.link)
                    : window.location.pathname === path.link
                }
                onClick={path.onClick}>
                {path.label}
              </CustomButtom>
            ) : (
              <CustomLink
                to={path.link}
                active={
                  index === paths.length - 1
                    ? window.location.pathname.includes(path.link)
                    : window.location.pathname === path.link
                }>
                {path.label}
              </CustomLink>
            )}
            {index !== paths.length - 1 && <FiChevronRight />}
          </Fragment>
        ))}
      </Style.PathContainer>
    </Style.BreadCrumbContainer>
  )
}
