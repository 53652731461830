import { theme } from 'design-cannect'
import { useEffect, useState } from 'react'
import * as Style from './styles'

interface StepButtonProps {
  number: string
  active: boolean
  disabled?: boolean
  onClick: () => void
}

export default function StepButton({ number, active, disabled, onClick }: StepButtonProps) {
  const [background, setBackground] = useState('#ecece2')

  useEffect(() => {
    if (active) {
      setBackground(`${theme.colors.secondary}`)
    } else if (!active) {
      setBackground(`${theme.colors.primary}`)
    }

    if (disabled) {
      setBackground(`#ecece2`)
    }
  }, [active, disabled])

  return (
    <Style.StepButtonContainer
      style={disabled ? { background, color: 'black' } : { background, color: 'white' }}
      disabled={disabled}
      onClick={onClick}
    >
      {number}
    </Style.StepButtonContainer>
  )
}
