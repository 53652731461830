import { AddToCalendarButton, Typography } from '@cannect/new-components/atoms'
import { TWebinarItem } from '@cannect/services/resources/webinars'
import { formatDate } from '@cannect/utils/date'
import { CalendarEvent } from 'calendar-link'
import { parse } from 'date-fns'
import { motion } from 'framer-motion'

type StepFourProps = {
  webinarDetails: TWebinarItem
  isAlreadySubscribed: boolean
}
export const StepFour = ({ webinarDetails, isAlreadySubscribed }: StepFourProps) => {
  const dateTimeString = `${webinarDetails.date}T${webinarDetails.time}`

  const parsedDate = parse(dateTimeString, "yyyy-MM-dd'T'HH:mm:ss", new Date())

  const event: CalendarEvent = {
    title: `Webinário: ${webinarDetails?.title}`,
    start: parsedDate,
    location: webinarDetails?.host_link || undefined,
    description: ` ${
      webinarDetails?.host_link ? `Link da transmissão: ${webinarDetails?.host_link} - ` : ''
    } Data: ${formatDate(parsedDate, "dd 'de' MMMM 'às' HH:mm")}`,
    duration: [1, 'hour']
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center gap-4">
      <div>
        <Typography.Text className="text-center">
          {isAlreadySubscribed ? 'Você já está inscrito!' : 'Inscrição realizada com sucesso!'}
        </Typography.Text>
        <Typography.Text type="paragraphTwo" className="text-center">
          {!isAlreadySubscribed && <span className="font-medium">Importante:</span>} Salve o evento na sua agenda para
          não esquecer.
        </Typography.Text>
      </div>
      <AddToCalendarButton size="md" event={event} />
    </motion.div>
  )
}
