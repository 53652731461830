import { Navigation, Separator } from '@cannect/new-components/atoms'
import { TMenu } from './types'
import { cloneElement, Fragment } from 'react'

export const SubMenuItem = ({
  submenu,
  currentRoute,
  withSheetClose
}: {
  submenu: TMenu[]
  currentRoute: string
  withSheetClose?: boolean
}) => (
  <Navigation.MenuContent className="ml-2 xl:ml-0">
    {submenu
      .filter((item) => !item.hidden)
      .map(({ title, path, icon: SubIcon, onClick, external, divider, iconPlacement: subIconPlacement = 'left' }) => (
        <Fragment key={path}>
          {divider ? (
            <Separator className="my-2" />
          ) : (
            <Navigation.MenuLink
              className="!text-sm xl:!text-primary-700"
              withSheetClose={withSheetClose}
              isExternal={external}
              to={path ?? '#'}
              onClick={() => {
                onClick?.()
              }}
              active={currentRoute === path}>
              {SubIcon && subIconPlacement === 'left' && cloneElement(SubIcon)}
              {title}
              {SubIcon && subIconPlacement === 'right' && cloneElement(SubIcon)}
            </Navigation.MenuLink>
          )}
        </Fragment>
      ))}
  </Navigation.MenuContent>
)
