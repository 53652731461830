import { PATH_SELECTOR_ICONS } from './icons'

const SHARED_OPTIONS_LIST = {
  orders: [
    {
      title: 'Todos os pedidos',
      to: '/conta?pagina=pedidos-todos',
      enabled: true
    }
    /* {
      title: 'Entregues',
      to: '/conta?pagina=pedidos-entregues',
      enabled: true
    } */
  ],
  helps: [
    {
      title: 'Políticas de envio',
      to: '/conta?pagina=ajuda-envio',
      enabled: false
    },
    {
      title: 'Políticas de devolução',
      to: '/conta?pagina=ajuda-devolucao',
      enabled: false
    },
    {
      title: 'Políticas de dados (LGPD)',
      to: '/conta?pagina=ajuda-dados',
      enabled: false
    },
    {
      title: 'Preferências de cookies',
      to: '/conta?pagina=ajuda-cookies',
      enabled: false
    }
  ]
}

export const SIDE_PANEL_MY_ORDERS = {
  title: {
    label: 'Meus pedidos',
    subItemsId: 'pedidos',
    icon: PATH_SELECTOR_ICONS.orders,
    enabled: true,
    to: '/conta?pagina=pedidos-todos'
  }
}

export const SIDE_PANEL_HELP = {
  title: {
    label: 'Ajuda',
    subItemsId: 'ajuda',
    icon: PATH_SELECTOR_ICONS.help,
    enabled: false
  },
  subItems: SHARED_OPTIONS_LIST.helps
}

export const SHARED_OPTIONS_DESCRIPTIONS = {
  'Meus pedidos': 'Consulte seus pedidos, acompanhe o status de entrega e visualize seu histórico de compras',
  Ajuda: 'Consulte nossas políticas de envio, devolução, dados (LGPD) e preferências de cookies'
}
