import React, { ComponentProps, ElementType } from 'react'
import * as Styles from './styles'

export interface HeadingProps extends ComponentProps<typeof Styles.Title> {
  children: React.ReactNode
  elementType?: ElementType
  fontSize?: `${number}%` | `${number}px`
  fontWeight?: number
  verticalSpace?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
  imageIcon?: string
  defaultColor?: 'darkGrey' | 'dark' | 'white' | 'lightGrey'
  className?: string
  marginTop?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
}

export default function Heading({
  children,
  fontSize = '30px',
  verticalSpace = 'medium',
  elementType = 'h1',
  fontWeight = 700,
  imageIcon,
  defaultColor = 'lightGrey',
  className = '',
  marginTop,
  marginBottom,
  marginLeft,
  marginRight
}: HeadingProps) {
  return (
    <Styles.Wrapper
      fontSize={fontSize}
      verticalSpace={verticalSpace}
      fontWeight={fontWeight}
      defaultColor={defaultColor}
      className={`heading-component ${className}`}
      imageIcon={imageIcon}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <Styles.Title as={elementType}>
        {!!imageIcon && <Styles.IconBox src={imageIcon} alt="icone do titulo" />}
        {children}
      </Styles.Title>
    </Styles.Wrapper>
  )
}
