import { useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'

import { DocumentVisualization } from 'components/UploadDocument/DocumentVisualization'
import * as S from './styles'
import { temporaryTheme } from 'styles/theme'
import { TListFiles } from './types'

import { IoTrashBinOutline } from 'react-icons/io5'
import { Button } from '@new-components/atoms'

export function DocumentListFiles({ list = [], onRemove, canRemove = false }: TListFiles) {
  const [visualizationModal, setVisualizationModal] = useState(false)
  const [documentVisualization, setDocumentVisualization] = useState({ url: '', extension: '' })

  const handleTrashClick = (itemId: number) => {
    if (onRemove) onRemove(itemId)
  }

  const openVisualizationModal = ({ url, extension }: { url?: string; extension?: string }) => {
    if (url && extension) {
      setDocumentVisualization({ url, extension })
      setVisualizationModal(true)
    }
  }

  const menu = (
    <Menu>
      {list.map(item => (
        <Menu.Item key={item.id}>
          <S.MenuItem>
            <S.NameFile>{item?.name || item.id}</S.NameFile>
            <div className="flex w-fit items-center justify-end gap-2">
              <Button
                className="h-auto"
                size="md"
                unstyled
                onClick={() => openVisualizationModal({ url: item.url, extension: item.extension })}
              >
                visualizar
              </Button>
              {canRemove && (
                <Button
                  className="h-auto"
                  size="md"
                  unstyled
                  onClick={() => openVisualizationModal({ url: item.url, extension: item.extension })}
                >
                  <IoTrashBinOutline
                    size={18}
                    color={temporaryTheme.colors.red}
                    onClick={() => handleTrashClick(item.id)}
                  />
                </Button>
              )}
            </div>
          </S.MenuItem>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <S.Wrapper>
      <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']} disabled={list.length === 0}>
        <S.SpanDropdown>
          {list.length > 0 ? (
            <>
              <p>
                Lista de documentos
                <HiOutlineClipboardDocumentList />
              </p>
              <p>
                {list.length} {list.length > 1 ? 'arquivos anexados' : 'arquivo anexado'}
              </p>
            </>
          ) : (
            <p>Nenhum arquivo indexado</p>
          )}
        </S.SpanDropdown>
      </Dropdown>

      <DocumentVisualization
        openVisualization={visualizationModal}
        file={documentVisualization}
        closeVisualization={() => setVisualizationModal(false)}
      />
    </S.Wrapper>
  )
}
