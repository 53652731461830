import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import { ChangeEvent, useRef } from 'react'
import { uploadSvg } from 'assets/svg'
import Button from 'new-components/Button'
import { toast } from 'react-toastify'
import usePrescriptionConfig from './hooks/usePrescriptionConfig'
import * as Styles from './styles'
import SimulatePdf from './components/SimulatePDF'

export default function PrescriptionConfig() {
  const { selectedImgStr, setSelectedImgStr, logoPosition, setLogoPosition, setClinicLogo, loading, onFormSubmit } =
    usePrescriptionConfig()
  const hiddenClinicLogoInput = useRef<HTMLInputElement>(null)

  const handleClick = (input: any) => {
    input?.current?.click()
  }

  const handleClinicLogoUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const data = files?.[0]
    if (!data) return

    setClinicLogo(data)
    const reader = new FileReader()
    reader.readAsDataURL(data)
    reader.onload = () => {
      setSelectedImgStr(reader.result as string)
    }
    reader.onerror = () => {
      toast.error('Houve um erro ao carregar a pré-visualização da imagem')
    }
  }

  return (
    <Styles.PersonalDataContainer onSubmit={onFormSubmit}>
      <Loading loading={loading} />
      <PageTitle>Config. de prescrições</PageTitle>

      <h2 style={{ marginTop: '1rem' }}>Pré-visualização da receita</h2>
      <Styles.PreviewRecipeContainer>
        <Styles.ContainerLeft>
          <Styles.UploadContainer onClick={() => handleClick(hiddenClinicLogoInput)}>
            <Styles.ButtonUpload type="button">
              <img src={uploadSvg} alt="upload" />
            </Styles.ButtonUpload>
            <input
              type="file"
              ref={hiddenClinicLogoInput}
              style={{ display: 'none' }}
              onChange={handleClinicLogoUpload}
            />
            <Styles.ContentUpload>
              <h3>Clique para subir o arquivo da logo!</h3>
              <h4>Formatos aceitos: JPEG, BMP e PNG.</h4>
              <span>Máximo de 5 MB.</span>
            </Styles.ContentUpload>
          </Styles.UploadContainer>

          <Styles.LogoContainer>{selectedImgStr ? <img src={selectedImgStr} alt="" /> : 'LOGO'}</Styles.LogoContainer>

          <Styles.Title style={{ fontSize: '20px' }}>Posicionamento da Logo *</Styles.Title>

          <Styles.SelectLogoPosition value={logoPosition} onChange={event => setLogoPosition(event.target.value)}>
            <option value="left">Alinhado a esquerda</option>
            <option value="center">Alinhado ao centro</option>
            <option value="right">Alinhado a direita</option>
          </Styles.SelectLogoPosition>
        </Styles.ContainerLeft>

        <Styles.ContainerRight>
          <SimulatePdf positionLogo={logoPosition} img={selectedImgStr} />
        </Styles.ContainerRight>
      </Styles.PreviewRecipeContainer>
      <Button style={{ alignSelf: 'flex-end' }}>SALVAR INFORMAÇÕES</Button>
    </Styles.PersonalDataContainer>
  )
}
