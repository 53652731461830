import axios from 'axios'
import Loading from 'components/Loading'
import { Modal } from 'components/Modal'
import useMediaQuery from 'hooks/useMediaQuery'
import { getUserID, getUserStatus } from 'hooks/useSessionUser'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { MOBILE_WIDTH } from 'utils/constants'
import { clearMask, formatPhone, formatZipCode } from 'utils/formatters'
import { FinishedUpdate } from './FinishedUpdate'
import * as Style from './styles'

export function UserStatus() {
  const [userStatus, setUserStatus] = useState(true)
  const [personalFields, setPersonalFields] = useState([])
  const [addressFields, setAddressFields] = useState([])
  const [profileFields, setProfileFields] = useState([])
  const [isFinished, setIsFinished] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [inputValue, setInputValue] = useState({ ...personalFields, ...addressFields, ...profileFields })
  const [complement, setComplement] = useState('')

  const history = useHistory()

  const fetchUserStatus = async () => {
    const data = await getUserStatus()

    setUserStatus(data?.status?.completedRegistration)
    setPersonalFields(data?.status?.personalFields)
    setAddressFields(data?.status?.addressFields)
    setProfileFields(data?.status?.profileFields)
  }

  const closeModal = () => {
    setUserStatus(true)
    history.push('/')
  }

  // const allFieldsCompleted =

  const closeModalWithoutRedirect = () => {
    setUserStatus(true)
  }

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    if (id === 'cep') {
      setInputValue((inputValue: any) => ({ ...inputValue, [id]: value }))
      if (value.length === 9) {
        fetchZipCode(clearMask(value))
      }
    } else {
      setInputValue((inputValue: any) => ({ ...inputValue, [id]: value }))
    }
  }

  async function fetchZipCode(cep: string) {
    setIsLoading(true)
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

      setInputValue((state: any) => ({
        ...state,
        cep: formatZipCode(response?.data?.cep ? response?.data?.cep : '0'),
        address: response.data.logradouro,
        district: response.data.bairro,
        city: response.data.localidade,
        state: response.data.uf
      }))
    } catch (err: any) {
      setIsLoading(false)
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const updateUserStatus = async (e: any) => {
    try {
      e.preventDefault()
      setIsLoading(true)
      await api
        .put('/user_status_update', { ...inputValue, complement })
        .then(res => {
          setIsFinished(true)
          setIsLoading(false)
        })
        .catch(err => {
          setIsLoading(false)
          setIsFinished(false)
        })
      return true
    } catch (error) {
      setIsLoading(false)
      return error
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUserStatus()
  }, [])

  return (
    <Style.Container>
      <Loading loading={isLoading} />
      <Modal isOpened={!userStatus} onRequestClose={() => closeModal()}>
        {isFinished ? (
          <FinishedUpdate closeModal={closeModalWithoutRedirect} />
        ) : (
          <Style.ModalContent isMobile={isMobile} onSubmit={(e: any) => updateUserStatus(e)}>
            <h3>Por favor preencha todos os seus dados cadastrais</h3>
            <p>
              Verificamos que os seguintes dados ainda não foram preenchidos, esses dados são importante para que você
              possa continuar usando nossa plataforma
            </p>
            <div className="fields">
              {personalFields.length > 0 || profileFields.length > 0 ? <h2>Dados Pessoais</h2> : null}
              {personalFields.length > 0 && (
                <div className="fields-container">
                  {personalFields.map((field: any, i) => (
                    <div key={field.name} className="field">
                      <span>{field.translatedName}</span>
                      <input value={inputValue[field.name]} id={field.name} onChange={e => changeValue(e)} />
                    </div>
                  ))}
                </div>
              )}
              {profileFields.length > 0 && (
                <div className="fields-container">
                  {profileFields.map((field: any, i) => (
                    <div key={field.name} className="field">
                      <span>{field.translatedName}</span>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {field.name === 'phone' ? (
                          <>
                            <span
                              style={{
                                marginRight: '10px',
                                marginTop: '5px',
                                color: 'd8d8d8'
                              }}
                            >
                              +55
                            </span>
                            <input
                              value={formatPhone(inputValue[field.name])}
                              id={field.name}
                              onChange={e => changeValue(e)}
                            />
                          </>
                        ) : (
                          <input value={inputValue[field.name]} id={field.name} onChange={e => changeValue(e)} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button className="no-account-btn" onClick={() => closeModal()}>
                preencher mais tarde
              </button>
              <button className="account-btn" type="submit">
                preencher dados
              </button>
            </div>
          </Style.ModalContent>
        )}
      </Modal>
    </Style.Container>
  )
}
