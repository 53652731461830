export const FILTER_TYPES = {
  pathology: 'pathology',
  class_id: 'classe',
  shapes: 'typestudy',
  cannabidiol_id: 'subclass',
  level: 'level',
  routeofadministrations: 'routeofadministrations',
  years: 'year'
} as any

export const FILTER_NAME = {
  pathology: 'Patologia',
  years: 'Ano',
  shapes: 'Tipo de estudo',
  level: 'Nível de evidência',
  routeofadministrations: 'Via de Administração',
  class_id: 'Classe farmacológica',
  cannabidiol_id: 'Subclasse farmacológica'
} as any

export const SORT_OPTIONS = [
  {
    label: 'Mais antigos primeiro',
    value: 'ASC'
  },
  {
    label: 'Mais novos primeiro',
    value: 'DESC'
  }
]

export const YEARS = [
  {
    id: '2012',
    name: '2012'
  },
  {
    id: '2013',
    name: '2013'
  },
  {
    id: '2014',
    name: '2014'
  },
  {
    id: '2015',
    name: '2015'
  },
  {
    id: '2016',
    name: '2016'
  },
  {
    id: '2017',
    name: '2017'
  },
  {
    id: '2018',
    name: '2018'
  },
  {
    id: '2019',
    name: '2019'
  },
  {
    id: '2020',
    name: '2020'
  },
  {
    id: '2021',
    name: '2021'
  },
  {
    id: '2022',
    name: '2022'
  },
  {
    id: '2023',
    name: '2023'
  }
]

export const LEVEL_OF_EVIDENCE = [
  {
    id: '1A',
    name: '1A'
  },
  {
    id: '1B',
    name: '1B'
  },
  {
    id: '1C',
    name: '1C'
  },
  {
    id: '2A',
    name: '2A'
  },
  {
    id: '2B',
    name: '2B'
  },
  {
    id: '2C',
    name: '2C'
  },
  {
    id: '3A',
    name: '3A'
  },
  {
    id: '3B',
    name: '3B'
  },
  {
    id: '4',
    name: '4'
  },
  {
    id: '5',
    name: '5'
  }
]
