import React, { cloneElement, ReactElement, ReactNode } from 'react'
import { FormController, Form, Typography, Separator } from '@cannect/new-components/atoms'
import { tv, type VariantProps } from 'tailwind-variants'

type RadioOption = {
  value: string | number
  label: string
  description?: ReactNode | string
  icon?: ReactElement
}

const radioGroupCard = tv({
  slots: {
    radioGroup: 'gap-0',
    radioItem: 'flex items-center gap-5',
    labelContainer: 'flex items-center gap-2',
    label: 'text-sm font-semibold',
    description: 'block text-xs text-muted [&>strong]:font-bold',
    separator: 'my-8'
  },
  variants: {
    isDisabled: {
      true: {
        radioGroup: 'opacity-50'
      }
    }
  }
})

type RadioGroupCardProps = VariantProps<typeof radioGroupCard> & {
  options: RadioOption[]
  classNames?: Partial<Record<keyof ReturnType<typeof radioGroupCard>, string>>
  onValueChange?: (value: string) => void
  value?: string
  isDisabled?: boolean
}

export const RadioGroupCard = ({
  options,
  classNames = {},
  onValueChange,
  value,
  isDisabled = false
}: RadioGroupCardProps) => {
  const { radioGroup, radioItem, labelContainer, label, description, separator } = radioGroupCard({
    isDisabled
  })

  return (
    <Form.RadioGroup
      onValueChange={onValueChange}
      defaultValue={value}
      className={radioGroup({ className: classNames.radioGroup })}
      disabled={isDisabled}>
      {options.map((option, index) => (
        <div className="flex flex-col" key={option.value}>
          <div className={radioItem({ className: classNames.radioItem })}>
            <Form.RadioGroupItem
              value={String(option.value)}
              id={`${option.label}-${option.value}`}
              disabled={isDisabled}
            />
            <div className={labelContainer({ className: classNames.labelContainer })}>
              {option.icon && cloneElement(option.icon)}
              <FormController.FormLabel htmlFor={`${option.label}-${option.value}`}>
                <Typography.Text className={label({ className: classNames.label })}>{option?.label}</Typography.Text>
                {option.description && (
                  <Typography.Text className={description({ className: classNames.description })}>
                    {option?.description}
                  </Typography.Text>
                )}
              </FormController.FormLabel>
            </div>
          </div>
          {index !== options.length - 1 && <Separator className={separator({ className: classNames.separator })} />}
        </div>
      ))}
    </Form.RadioGroup>
  )
}

RadioGroupCard.displayName = 'RadioGroupCard'
