import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${temporaryTheme.spacing.space2};

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    margin-bottom: 42px;
  }
`

export const Container = styled.div`
  .page {
    margin: 24px 0;
  }

  .scheduling-select {
    width: 100%;
    position: relative;

    .fake-select-placeholder {
      display: flex;
      align-items: center;
      color: #ddd;
    }
    & * {
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      font-weight: 500;
    }
  }
`

export const SelectDateContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  > .select-date-content {
    flex-direction: column;
    display: flex;
    max-width: 400px;
    gap: 16px;
    > span {
      font-weight: 700;
      font-size: 18px;
      font-family: 'Spartan', sans-serif;
      color: #3d3d3d;
    }
  }

  @media ${MOBILE_WIDTH} {
    gap: 16px;
  }
`

export const FieldsContainer = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 16px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
  width: 100%;
  @media ${MOBILE_WIDTH} {
    margin-bottom: 30px;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  svg {
    width: 30px;
    height: 30px;
  }
`

export const HeaderTitle = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: #748351;
  margin-bottom: 48px;

  span {
    color: #21976e;
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
    font-size: 30px;
  }

  @media screen and (max-width: 420px) {
    max-width: 247px;
    margin-bottom: 24px;
  }
`

export const HeaderSubtitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${theme.colors.darkGrey};

  @media ${MOBILE_WIDTH} {
    font-size: 16px;
  }
`

export const RadioGroup = styled.div`
  margin-bottom: 24px;

  span:last-child {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border: 2px solid #868686;
    background-color: #fff;
  }

  .ant-radio-inner,
  .ant-radio-inner:hover {
    border: 2px solid #B5B3B3 !important;
    :hover {
      border: 2px solid #B5B3B3 !important;
    }
  }

  .ant-radio-inner::after {
    background-color: #748351;
  }

  @media ${MOBILE_WIDTH} {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

export const Button = styled.button<{ disabled?: boolean; width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: ${({ width }) => width || '100%'};
  background-color: ${({ disabled }) => (disabled ? '#B6B6B6' : '#F0780A')};
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 19px;
  font-family: 'Spartan', sans-serif;
  color: #fff;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const Image = styled.img`
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const ExternalLink = styled.a`
  color: ${temporaryTheme.colors.link};
  font-family: 'Spartan', sans-serif;
  font-weight: Bold;
  font-size: 13px;
  text-decoration: none;
`

export const SelectDateWithPrescriber = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media (${MOBILE_WIDTH}) {
    flex-direction: column;
  }
`

export const SelectDatePrescriberInfo = styled.div`
  width: 100%;
  max-width: 50%;
  padding-top: 10px;
  padding-right: 15px;

  @media (${MOBILE_WIDTH}) {
    max-width: 100%;
    margin-top: 0;
  }
`

export const SelectDateContent = styled.div`
  .select-date-title-content {
    font-size: 21.277px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
`
