import { theme } from 'design-cannect'
import styled from 'styled-components'

export const CookiesConsent = styled.div<any>`
  /* align-items: baseline; */
  height: ${(props) => (props.isMobile ? '250px' : '190px')};
  background: #ffffff;
  border: 2px solid #ecece2;
  box-shadow: 0px 4px 0px #ecece2;
  border-radius: 8px;
  color: white;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  transition: 0.8s;
  animation: slideIn 0.8s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;

  .header-cookies {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
  }
  .cookie-bar {
    display: flex;
    width: 1040px;
    justify-content: center;
    align-items: center;
    max-width: 852px;
    img {
      width: 32px;
      height: 32px;
    }

    .cookie-bar-content {
      h2 {
        font-family: 'Spartan', sans-serif;
        font-weight: 700;
        font-size: 26px;

        color: #748351;
      }

      p {
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: ${theme.colors.darkGrey};
        margin-left: 46px;
      }
      margin-bottom: 16px;
    }

    .footer-cookies {
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
        font-family: 'Spartan', sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: ${theme.colors.darkGrey};
        margin-top: 22px;
        margin-left: 46px;
      }
    }
  }
`
