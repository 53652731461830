import { TDigitalJourneyRoutes, TDigitalJourneyFormData } from './types'
import formsFeedback from '@cannect/constants/formsFeedback'
import { CEP_VALIDATION_REGEX, ONLY_LETTERS_REGEX, PHONE_VALIDATION_REGEX } from '@cannect/constants/regex'
import { TCartResponseProductItem } from '@cannect/services/resources/digitalJourney'
import { validateCardExpirationDate, validateCpf } from '@cannect/utils/validators'
import { isValid, parse } from 'date-fns'
import { z } from 'zod'

export enum STEPS_ENUM {
  SEND_PRESCRIPTION_DOCUMENT = 'send_prescription_document',
  AUTH_INFORMATIONS = 'auth_informations',
  CART = 'cart',
  SEND_IDENTITY_DOCUMENT = 'send_identity_document',
  CONFIRM_IDENTITY_INFORMATIONS = 'confirm_identity_document',
  SEND_PROOF_OF_ADDRESS_DOCUMENT = 'send_proof_of_address_document',
  CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS = 'confirm_proof_of_address_document',
  ORDER_PAYMENT = 'order_payment',
  ORDER_SUMMARY = 'order_summary',
  GENERATE_AND_SIGN_ANVISA_AUTHORIZATION = 'generate_and_sign_anvisa_authorization',
  SEND_ANVISA_AUTHORIZATION = 'send_anvisa_authorization',
  JOURNEY_FINISHED = 'journey_finished'
}

export const DIGITAL_JOURNEY_ROUTES: TDigitalJourneyRoutes = {
  [STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT]: '/jornada-digital',
  [STEPS_ENUM.AUTH_INFORMATIONS]: '/jornada-digital/autenticacao',
  [STEPS_ENUM.CART]: '/jornada-digital/carrinho',
  [STEPS_ENUM.SEND_IDENTITY_DOCUMENT]: '/jornada-digital/enviar-identificacao',
  [STEPS_ENUM.CONFIRM_IDENTITY_INFORMATIONS]: '/jornada-digital/confirmar-identificacao',
  [STEPS_ENUM.SEND_PROOF_OF_ADDRESS_DOCUMENT]: '/jornada-digital/enviar-comprovante-de-endereco',
  [STEPS_ENUM.CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS]: '/jornada-digital/confirmar-comprovante-de-endereco',
  [STEPS_ENUM.ORDER_SUMMARY]: '/jornada-digital/resumo',
  [STEPS_ENUM.ORDER_PAYMENT]: '/jornada-digital/pagamento',
  [STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION]: '/jornada-digital/autorizacao-anvisa',
  [STEPS_ENUM.SEND_ANVISA_AUTHORIZATION]: '/jornada-digital/enviar-autorizacao-anvisa',
  [STEPS_ENUM.JOURNEY_FINISHED]: '/jornada-digital/pedido-concluido'
}

export const FileSchema = z.object({
  id: z.number().nullable().optional(),
  file: z.custom<File>(),
  name: z.string().optional(),
  size: z.coerce.number(),
  type: formsFeedback.nonEmptyStringSchema,
  file_url: formsFeedback.nonEmptyStringSchema
})

export const FORM_SCHEMAS = {
  [STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT]: z.object({
    prescription_file: z
      .array(FileSchema, { required_error: formsFeedback.requiredField })
      .min(1, formsFeedback.requiredField)
  }),

  [STEPS_ENUM.AUTH_INFORMATIONS]: z.object({
    full_name: formsFeedback.nonEmptyStringSchema.regex(ONLY_LETTERS_REGEX, formsFeedback.invalidField),
    cpf: formsFeedback.nonEmptyStringSchema.refine((value) => validateCpf(value), formsFeedback.invalidCpf),
    email: formsFeedback.nonEmptyStringSchema.email(formsFeedback.invalidEmail),
    phone: formsFeedback.nonEmptyStringSchema.regex(PHONE_VALIDATION_REGEX, formsFeedback.invalidPhone),
    terms_communication_accepted: z.boolean().optional()
  }),

  [STEPS_ENUM.CART]: z.object({
    generatedByOcr: z.boolean().optional(),
    coupon_name: z.string().optional().nullable(),
    products: z.array(z.custom<TCartResponseProductItem>()).min(1, 'É necessário pelo menos um produto.'),
    summary: z.object({
      shipping_amount: z.coerce.number(),
      discount_freight: z.coerce.number(),
      discount_product: z.coerce.number(),
      amount_total: z.coerce.number(),
      amount_liquid: z.coerce.number()
    })
  }),

  [STEPS_ENUM.SEND_IDENTITY_DOCUMENT]: z.object({
    user_id_files: z.array(FileSchema).min(1, formsFeedback.requiredField)
  }),

  [STEPS_ENUM.CONFIRM_IDENTITY_INFORMATIONS]: z.object({
    full_name: formsFeedback.nonEmptyStringSchema.regex(ONLY_LETTERS_REGEX, formsFeedback.invalidField),
    birthday: formsFeedback.nonEmptyStringSchema.refine(
      (value) => {
        const date = parse(value, 'dd/MM/yyyy', new Date())
        return isValid(date)
      },
      {
        message: formsFeedback.invalidDate
      }
    ),
    cpf: formsFeedback.nonEmptyStringSchema.refine((value) => validateCpf(value), formsFeedback.invalidCpf),
    rg: formsFeedback.nonEmptyStringSchema
  }),

  [STEPS_ENUM.SEND_PROOF_OF_ADDRESS_DOCUMENT]: z.object({
    proof_of_address_files: z.array(FileSchema).min(1, formsFeedback.requiredField)
  }),

  [STEPS_ENUM.CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS]: z.object({
    address_holder_full_name: formsFeedback.nonEmptyStringSchema.regex(ONLY_LETTERS_REGEX, formsFeedback.invalidField),
    address_holder_cpf: formsFeedback.nonEmptyStringSchema.refine(
      (value) => validateCpf(value),
      formsFeedback.invalidCpf
    ),
    address_cep: formsFeedback.nonEmptyStringSchema.regex(CEP_VALIDATION_REGEX, formsFeedback.invalidCep),
    address_state: formsFeedback.nonEmptyStringSchema.regex(ONLY_LETTERS_REGEX, formsFeedback.invalidField),
    address_city: formsFeedback.nonEmptyStringSchema.regex(ONLY_LETTERS_REGEX, formsFeedback.invalidField),
    address_neighborhood: formsFeedback.nonEmptyStringSchema,
    address_street: formsFeedback.nonEmptyStringSchema,
    address_number: formsFeedback.nonEmptyStringSchema,
    address_complement: z.string().optional()
  }),

  [STEPS_ENUM.ORDER_PAYMENT]: z.discriminatedUnion('payment_method', [
    z.object({
      payment_method: z.literal('credit_card'),
      credit_card: z.object({
        card_holder_name: formsFeedback.nonEmptyStringSchema,
        card_validate: formsFeedback.nonEmptyStringSchema.refine((value) => validateCardExpirationDate(value), {
          message: formsFeedback.invalidField
        }),
        card_number: formsFeedback.nonEmptyStringSchema,
        card_cvv: formsFeedback.nonEmptyStringSchema,
        installments: z.number().min(1, formsFeedback.invalidField)
      })
    }),
    z.object({
      payment_method: z.enum(['pix', 'boleto']),
      credit_card: z.any()
    }),
    z.object({
      payment_method: z.undefined(),
      credit_card: z.any()
    })
  ]),

  [STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION]: z.object({
    sign_terms_accepted: z
      .boolean({ required_error: formsFeedback.requiredField })
      .refine((val) => val === true, 'É necessário autorizar a assinatura.'),
    signed_anvisa_authorization_file: z.array(FileSchema).min(1, formsFeedback.requiredField)
  }),

  [STEPS_ENUM.SEND_ANVISA_AUTHORIZATION]: z.object({
    anvisa_authorization_file: z.array(FileSchema).min(1, formsFeedback.requiredField)
  })
}

export const FORM_DEFAULT_VALUES: TDigitalJourneyFormData = {
  common_fields: {
    form_steps_order: [],
    status: 'in_progress',
    order_id: null,
    order_uuid: null,
    user_id: null
  },
  [STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT]: {
    prescription_file: []
  },
  [STEPS_ENUM.AUTH_INFORMATIONS]: {
    full_name: '',
    cpf: '',
    email: '',
    phone: '',
    terms_communication_accepted: true
  },
  [STEPS_ENUM.CART]: {
    generatedByOcr: false,
    coupon_name: null,
    products: [],
    summary: {
      shipping_amount: 0,
      discount_freight: 0,
      discount_product: 0,
      amount_total: 0,
      amount_liquid: 0
    }
  },
  [STEPS_ENUM.SEND_IDENTITY_DOCUMENT]: {
    user_id_files: []
  },
  [STEPS_ENUM.CONFIRM_IDENTITY_INFORMATIONS]: {
    full_name: '',
    birthday: '',
    cpf: '',
    rg: ''
  },
  [STEPS_ENUM.SEND_PROOF_OF_ADDRESS_DOCUMENT]: {
    proof_of_address_files: []
  },
  [STEPS_ENUM.CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS]: {
    address_holder_full_name: '',
    address_holder_cpf: '',
    address_cep: '',
    address_number: '',
    address_complement: '',
    address_neighborhood: '',
    address_city: '',
    address_state: '',
    address_street: ''
  },
  [STEPS_ENUM.ORDER_PAYMENT]: {
    payment_method: undefined,
    credit_card: undefined
  },

  [STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION]: {
    sign_terms_accepted: false,
    signed_anvisa_authorization_file: []
  },
  [STEPS_ENUM.SEND_ANVISA_AUTHORIZATION]: {
    anvisa_authorization_file: []
  }
}
