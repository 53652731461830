import AccountHeader from 'components/AccountComponent/AccountHeader'
import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'
import React, { useEffect } from 'react'
import api from 'services/api'
import { generateUniqueId } from 'utils/validators'
import MyRecipe from './MyRecipe'
import { Container, NotFoundMessage } from './styles'

function AccountRecipeComponent() {
  const [recipes, setRecipes] = React.useState<any>([])
  const [loading, setLoading] = React.useState(false)

  const getMyRecipes = async () => {
    try {
      setLoading(true)
      const { data: recipes } = await api.get(`recipes`)
      setRecipes(recipes.recipes)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getMyRecipes()
  }, [])
  return (
    <Container>
      <BreadCrumb paths={[{ label: 'Minhas Receitas', link: '/minhas-receitas' }]} />
      {loading && <Loading loading={loading} />}
      <AccountHeader title="Receitas" />
      {recipes.length > 0 ? (
        recipes.map((recipe: any) => (
          <MyRecipe
            key={generateUniqueId(recipe.id)}
            token={recipe.token}
            name={recipe.name}
            emission={recipe.emission}
            products={recipe.products}
          />
        ))
      ) : (
        <NotFoundMessage>
          <h2>Você não possui receitas</h2>
        </NotFoundMessage>
      )}
    </Container>
  )
}

export default AccountRecipeComponent
