import { Satisfaction, useCannectCare } from 'hooks/useCannectCare'
import { HorizontalContent } from 'pages/CannectCare/styles'
import DeactivatableButton from '../../DeactivatableButton'
import Question from '../Question'

export default function LifeSatisfactionAssessment() {
  const {
    setStep,
    sleepSatisfaction,
    setSleepSatisfaction,
    routineActivitiesCapability,
    setRoutineActivitiesCapability,
    workCapability,
    setWorkCapability,
    selfSatisfaction,
    setSelfSatisfaction,
    personalRelationship,
    setPersonalRelationship,
    sexualLife,
    setSexualLife,
    friendsSupport,
    setFriendsSupport,
    livingPlace,
    setLivingPlace,
    healthAccess,
    setHealthAccess,
    transportAccess,
    setTransportAccess
  } = useCannectCare()

  function handleSleepSatisfactionChoice(id: string) {
    setSleepSatisfaction(id as Satisfaction)
  }

  function handleRoutineActivitiesCapabilityChoice(id: string) {
    setRoutineActivitiesCapability(id as Satisfaction)
  }

  function handleWorkCapabilityChoice(id: string) {
    setWorkCapability(id as Satisfaction)
  }

  function handleSelfSatisfactionChoice(id: string) {
    setSelfSatisfaction(id as Satisfaction)
  }

  function handlePersonalRelationshipChoice(id: string) {
    setPersonalRelationship(id as Satisfaction)
  }

  function handleSexualLifeChoice(id: string) {
    setSexualLife(id as Satisfaction)
  }

  function handleFriendsSupportChoice(id: string) {
    setFriendsSupport(id as Satisfaction)
  }

  function handleLivingPlaceChoice(id: string) {
    setLivingPlace(id as Satisfaction)
  }

  function handleHealthAccessChoice(id: string) {
    setHealthAccess(id as Satisfaction)
  }

  function handleTransportAccessChoice(id: string) {
    setTransportAccess(id as Satisfaction)
  }

  return (
    <>
      <span>
        As questões seguintes perguntam sobre quão bem ou satisfeito você se sentiu a respeito de vários aspectos de sua
        vida nas últimas duas semanas.
      </span>
      <Question
        title="Quão satisfeito(a) você está com o seu sono?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleSleepSatisfactionChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'sleepSatisfaction-veryUnsatisfied',
            checked: sleepSatisfaction === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'sleepSatisfaction-unsatisfied',
            checked: sleepSatisfaction === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'sleepSatisfaction-neitherSatisfiedNorUnsatisfied',
            checked: sleepSatisfaction === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'sleepSatisfaction-satisfied',
            checked: sleepSatisfaction === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'sleepSatisfaction-verySatisfied',
            checked: sleepSatisfaction === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com sua capacidade de desempenhar as atividades do seu dia-a-dia?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleRoutineActivitiesCapabilityChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'routineActivitiesCapability-veryUnsatisfied',
            checked: routineActivitiesCapability === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'routineActivitiesCapability-unsatisfied',
            checked: routineActivitiesCapability === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'routineActivitiesCapability-neitherSatisfiedNorUnsatisfied',
            checked: routineActivitiesCapability === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'routineActivitiesCapability-satisfied',
            checked: routineActivitiesCapability === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'routineActivitiesCapability-verySatisfied',
            checked: routineActivitiesCapability === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com sua capacidade para o trabalho?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleWorkCapabilityChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'workCapability-veryUnsatisfied',
            checked: workCapability === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'workCapability-unsatisfied',
            checked: workCapability === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'workCapability-neitherSatisfiedNorUnsatisfied',
            checked: workCapability === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'workCapability-satisfied',
            checked: workCapability === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'workCapability-verySatisfied',
            checked: workCapability === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está consigo mesmo?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleSelfSatisfactionChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'selfSatisfaction-veryUnsatisfied',
            checked: selfSatisfaction === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'selfSatisfaction-unsatisfied',
            checked: selfSatisfaction === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'selfSatisfaction-neitherSatisfiedNorUnsatisfied',
            checked: selfSatisfaction === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'selfSatisfaction-satisfied',
            checked: selfSatisfaction === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'selfSatisfaction-verySatisfied',
            checked: selfSatisfaction === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com suas relações pessoais (amigos, parentes, conhecidos, colegas)?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handlePersonalRelationshipChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'personalRelationship-veryUnsatisfied',
            checked: personalRelationship === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'personalRelationship-unsatisfied',
            checked: personalRelationship === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'personalRelationship-neitherSatisfiedNorUnsatisfied',
            checked: personalRelationship === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'personalRelationship-satisfied',
            checked: personalRelationship === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'personalRelationship-verySatisfied',
            checked: personalRelationship === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com sua vida sexual?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleSexualLifeChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'sexualLife-veryUnsatisfied',
            checked: sexualLife === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'sexualLife-unsatisfied',
            checked: sexualLife === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'sexualLife-neitherSatisfiedNorUnsatisfied',
            checked: sexualLife === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'sexualLife-satisfied',
            checked: sexualLife === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'sexualLife-verySatisfied',
            checked: sexualLife === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com o apoio que você recebe de seus amigos?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleFriendsSupportChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'friendsSupport-veryUnsatisfied',
            checked: friendsSupport === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'friendsSupport-unsatisfied',
            checked: friendsSupport === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'friendsSupport-neitherSatisfiedNorUnsatisfied',
            checked: friendsSupport === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'friendsSupport-satisfied',
            checked: friendsSupport === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'friendsSupport-verySatisfied',
            checked: friendsSupport === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com as condições do local onde mora?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleLivingPlaceChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'livingPlace-veryUnsatisfied',
            checked: livingPlace === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'livingPlace-unsatisfied',
            checked: livingPlace === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'livingPlace-neitherSatisfiedNorUnsatisfied',
            checked: livingPlace === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'livingPlace-satisfied',
            checked: livingPlace === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'livingPlace-verySatisfied',
            checked: livingPlace === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com o seu acesso aos serviços de saúde?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleHealthAccessChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'healthAccess-veryUnsatisfied',
            checked: healthAccess === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'healthAccess-unsatisfied',
            checked: healthAccess === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'healthAccess-neitherSatisfiedNorUnsatisfied',
            checked: healthAccess === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'healthAccess-satisfied',
            checked: healthAccess === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'healthAccess-verySatisfied',
            checked: healthAccess === 'verySatisfied'
          }
        ]}
      />
      <Question
        title="Quão satisfeito(a) você está com o seu meio de transporte?"
        // eslint-disable-next-line react/jsx-no-bind
        onOptionChoice={handleTransportAccessChoice}
        options={[
          {
            id: 'veryUnsatisfied',
            label: 'muito insatisfeito',
            labelId: 'transportAccess-veryUnsatisfied',
            checked: transportAccess === 'veryUnsatisfied'
          },
          {
            id: 'unsatisfied',
            label: 'insatisfeito',
            labelId: 'transportAccess-unsatisfied',
            checked: transportAccess === 'unsatisfied'
          },
          {
            id: 'neitherSatisfiedNorUnsatisfied',
            label: 'nem satisfeito, nem insatisfeito',
            labelId: 'transportAccess-neitherSatisfiedNorUnsatisfied',
            checked: transportAccess === 'neitherSatisfiedNorUnsatisfied'
          },
          {
            id: 'satisfied',
            label: 'satisfeito',
            labelId: 'transportAccess-satisfied',
            checked: transportAccess === 'satisfied'
          },
          {
            id: 'verySatisfied',
            label: 'muito satisfeito',
            labelId: 'transportAccess-verySatisfied',
            checked: transportAccess === 'verySatisfied'
          }
        ]}
      />

      <HorizontalContent style={{ justifyContent: 'space-between' }}>
        <DeactivatableButton outlined onClick={() => setStep(state => state - 1)}>
          Voltar
        </DeactivatableButton>
        <DeactivatableButton
          onClick={() => setStep(state => state + 1)}
          disabled={
            sleepSatisfaction === '' ||
            routineActivitiesCapability === '' ||
            workCapability === '' ||
            selfSatisfaction === '' ||
            personalRelationship === '' ||
            sexualLife === '' ||
            friendsSupport === '' ||
            livingPlace === '' ||
            healthAccess === '' ||
            transportAccess === ''
          }
        >
          Próximo
        </DeactivatableButton>
      </HorizontalContent>
    </>
  )
}
