import { useEffect, useState } from 'react'
import api from 'services/api'
import { toast } from 'react-toastify'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useQueryString from 'hooks/useQueryString'
import { EMISSAO, PRESCRICAO } from 'utils/constants'

export const useDocument = () => {
  const { filters } = useQueryString()
  const withFilters = filters?.cart_id
  const DOCUMENT_ROUTES = withFilters ? 'order_public/document' : 'order/document'
  const [documents, setDocuments] = useState<any>()
  const [showDependentDocumentInput, setShowDependentDocumentInput] = useState(false)
  const { lastOrderCreated, getLastOrder, updateCartRecipes } = usePrepareCart()

  useEffect(() => {
    if (lastOrderCreated) {
      const documents = lastOrderCreated?.documents?.documents_order || lastOrderCreated?.documents
      const newDocuments = documents?.filter((document: any) => document.type !== PRESCRICAO) || []
      const recipe = lastOrderCreated?.recipes[0] || {}
      if (recipe?.id) {
        newDocuments.push({ type: PRESCRICAO, id: recipe?.id, url: recipe?.filename, file: null })
      }
      setDocuments(newDocuments)
    }
  }, [lastOrderCreated])

  const handelDocuments = (type: any) => documents?.find((document: any) => document.type === type)

  const handleChangeDocuments = async (e: any, type: any) => {
    const isValidate = handelDocuments(type)
    if (!isValidate) {
      const newDocument = documents.map((document: any) => {
        if (document.type === type) {
          return { ...document, file: e.target.files[0] }
        }
        return document
      })
      setDocuments(newDocument)
    } else {
      setDocuments([...documents, { type, file: e.target.files[0] }])
    }
    await handleIdUpload({ event: e, typeInput: type })
  }

  const handleIdUpload = async ({ event, typeInput }: any) => {
    const { files } = event.target
    const dataName = files as unknown as { name: string }[]

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      formData.append('file', file)
    }

    try {
      const fetchUrl = `/${DOCUMENT_ROUTES}/${lastOrderCreated.id}?type=${typeInput}&emission=${EMISSAO}`
      if (typeInput === PRESCRICAO) {
        const { data } = await api.post('/order/send_recipe', formData)
        updateCartRecipes(data?.recipeSalved.id)
      } else {
        await api.post(fetchUrl, formData)
      }
      getLastOrder(false)
    } catch (e) {
      toast.error('Erro ao enviar documento...')
    }
  }

  return {
    handelDocuments,
    handleChangeDocuments,
    showDependentDocumentInput,
    setShowDependentDocumentInput
  }
}
