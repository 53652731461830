import ConfirmationDialogue from 'components/ConfirmationDialogue'
import { Button } from 'design-cannect'
import { useCannectCare } from 'hooks/useCannectCare'
import { useState } from 'react'
import { ActionsButtons } from '../styles'
import DiseaseOccurrence from './DiseaseOccurrence'
import OtherDiseaseOccurrence from './OtherDiseaseOccurrence'
import * as Style from './styles'

export default function OccurrencesSelection() {
  const { symptoms, otherSymptoms, setStep, sendSymptoms } = useCannectCare()
  const [isErrorDialogueOpened, setIsErrorDialogueOpened] = useState(false)

  async function handleSaveClick() {
    const successfulySent = await sendSymptoms()

    if (successfulySent) {
      setStep(2)
    } else {
      setIsErrorDialogueOpened(true)
    }
  }

  return (
    <Style.OccurrencesSelectionContainer>
      <h3>
        Descreva a intensidade ou quantidade de episódios <strong>nos últimos três dias?</strong>
      </h3>

      <Style.SymptomsContainer>
        {symptoms.map(symptom => symptom.selected && <DiseaseOccurrence key={symptom.id} symptom={symptom} />)}
        {otherSymptoms.map(symptom => (
          <OtherDiseaseOccurrence key={symptom.id} symptom={symptom} />
        ))}
      </Style.SymptomsContainer>

      <ActionsButtons>
        <Button outlined onClick={() => setStep(0)}>
          Voltar
        </Button>
        <Button onClick={() => handleSaveClick()}>Salvar</Button>
      </ActionsButtons>

      <ConfirmationDialogue
        isOpened={isErrorDialogueOpened}
        onRequestClose={() => setIsErrorDialogueOpened(false)}
        onConfirmationClick={() => setIsErrorDialogueOpened(false)}
        title="Erro ao enviar sintomas!"
        description="Houve um erro ao enviar os sintomas. Tente novamente mais tarde."
        confirmationButtonText="Entendi!"
        confirmationOnlyDialogue
      />
    </Style.OccurrencesSelectionContainer>
  )
}
