import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.div`
  width: 100%;
`

export const SpanDropdown = styled.span`
  width: 100%;
  background: rgb(215 215 215);
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  color: #3d3d3d;
  justify-content: space-between;

  p {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
`

export const NameFile = styled.div`
  flex: 1;
`

export const ActionsFile = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`

export const VisualizationAction = styled.span`
  font-family: 'Nunito', sans-serif;
  &:hover {
    color: ${temporaryTheme.colors.primary['700']};
  }
`
