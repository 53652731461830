import { FiltersList } from 'hooks/useQueryString/types'
import { CollapsableMenuTitle } from './index'

export const getIsOpenInitialValue = (title: CollapsableMenuTitle, filters: FiltersList) => {
  const selectedPage = filters.pagina ?? 'perfil-dados-de-acesso'

  const selectedTitle = selectedPage.split('-')[0]
  if (!title.subItemsId) {
    const itemPath = title.to?.split('?pagina=')[1]
    return itemPath === selectedTitle
  }
  return selectedTitle === title.subItemsId
}
