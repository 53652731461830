import { Link } from 'react-router-dom'
import { theme } from 'design-cannect'
import { ReactNode } from 'react'

interface BreadCrumbProps {
  children: ReactNode | ReactNode[] | any

  active: boolean
  to: string
}

const customLinkStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  textDecoration: 'none',
  fontSize: '1.1rem'
}

export default function CustomLink({ children, to, active }: BreadCrumbProps) {
  return (
    <Link
      to={to}
      style={
        active
          ? { ...customLinkStyles, color: theme.colors.tertiary, fontWeight: 700 }
          : { ...customLinkStyles, color: 'black', fontWeight: 300 }
      }
    >
      {children}
    </Link>
  )
}
