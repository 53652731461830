import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const RegistrationFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
  margin-bottom: 2rem;
`

export const InvisibleContainer = styled.div`
  height: 1px;
  width: 100%;

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

interface VariableWidthContainerProps {
  width: string
}
export const VariableWidthContainer = styled.div<VariableWidthContainerProps>`
  width: ${({ width }) => width};

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const PasswordGuidelines = styled.p`
  padding: 2px 0;
  line-height: 1.5;
  font-size: 0.885rem;
  color: #9c9c9c;
`
