import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { media } from 'helpers/mediaQueryStyle'

export const ModalContent = styled.div<any>`
  width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 0;
  .icon-box {
    svg {
      width: 90px;
      height: 90px;
      color: ${({ color }) => (color === 'success' ? temporaryTheme.colors.sitePrimary : 'red')};
      ${media.mobile`
        margin-top: -30px;
        width: 60px;
        height: 60px;
      `}
    }
      
  }
  ${media.mobile`
    width: 250px;
    height: 150px;
  `}
  span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: ${temporaryTheme.colors.darkGrey};
  }
  .action-box {
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
  }
  button {
    margin-top: 16px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`