import { theme } from 'design-cannect'
import styled from 'styled-components'

interface DiseaseContainerProps {
  selected: boolean
}

export const DiseaseContainer = styled.button<DiseaseContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  width: 228px;
  min-width: 228px;
  height: 200px;
  min-height: 200px;

  border-radius: 0.5rem;
  border: 2px solid;
  border-color: ${props => (props.selected ? `${theme.colors.tertiary}` : '#ecece2')};
  background: ${props => (props.selected ? `${theme.colors.tertiary}` : 'transparent')};

  padding: 1rem;
  transition: transform 0.2s;

  > span {
    color: ${props => (props.selected ? 'white' : `${theme.colors.tertiary}`)};
    font-weight: 300;
    font-size: 1.25rem;
  }

  > img {
    max-width: 110px;
    min-width: 90px;
  }

  &:hover {
    transform: scale(1.03);
  }
`
