import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import Text from '../Text'
import { TextProps } from '../../utils/TextTypes'
import * as Style from './styles'

interface TextLinkProps {
  title: string
  url: any
  type?: TextProps['type']
  color?: TextProps['color']
  bold?: TextProps['bold']
  iconAfter?: boolean
  iconColor?: any
}

function TextLink({ title, url, type, iconColor, color, bold = false, iconAfter = false }: TextLinkProps) {
  return (
    <Style.TextLink>
      <Link to={url}>
        <Text type={type || 'md'} color={color || 'tertiary'} bold={bold}>
          {title}
        </Text>
        {iconAfter && <AiOutlineArrowRight color={iconColor || 'secondary'} />}
      </Link>
    </Style.TextLink>
  )
}

export default TextLink
