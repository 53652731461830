export type QueryObject = Record<string, string | string[]>

export function serializeQueryToObject(data: URLSearchParams): QueryObject {
  const object: QueryObject = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of data) {
    if (object[key] === undefined) {
      object[key] = value
    } else if (Array.isArray(object[key])) {
      ;(object[key] as string[]).push(value)
    } else {
      object[key] = [object[key] as string, value]
    }
  }

  return object
}

export const removeEmptyFilterParams = (data: Record<string, any>): Record<string, any> => {
  return Object.entries(data).reduce(
    (acc, [key, value]) => {
      const isValidValue = value !== undefined && value !== '' && !Number.isNaN(value)
      const hasElements = Array.isArray(value) ? value.length > 0 : true
      if (isValidValue && hasElements) {
        acc[key] = value
      }
      return acc
    },
    {} as Record<string, any>
  )
}

export function parseRangeQueryFields(
  queryParams: URLSearchParams,
  fieldsToParse: Record<string, { targetFields: string[] }>
) {
  const parsedQuery = new URLSearchParams(queryParams.toString())

  Object.entries(fieldsToParse).forEach(([sourceField, { targetFields }]) => {
    const value = parsedQuery.get(sourceField)
    if (value) {
      const [startValue, endValue] = value.split(',')
      targetFields.forEach((targetField, index) => {
        parsedQuery.set(targetField, index === 0 ? startValue : endValue)
      })
      parsedQuery.delete(sourceField)
    }
  })
  return parsedQuery
}
