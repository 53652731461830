import styled from 'styled-components'

export const Wrapper = styled.main`
  min-height: 700px;
  width: 100%;
  padding: 0 8px;
`

export const Heading = styled.h1`
  padding: 16px 0;
  margin-bottom: 10px;
`

export const ContentUpload = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`

export const Subtitle = styled.h3`
  margin-bottom: 22px;
  max-width: 600px;
  padding: 10px;
`

export const DocumentTextBox = styled.div`
  background: rgb(246, 246, 246);
  border-radius: 4px;
  padding: 26px 35.17px 16px 26px;
  margin-bottom: 16px;
`

export const TextDocument = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
`
