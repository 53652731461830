import { useState } from 'react'
import { BiBarcode } from 'react-icons/bi'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { BoletoBarcode } from './BoletoBarcode'
import * as Style from './styles'

interface Props {
  error: boolean
  isValidateSubmit: boolean
  finishOrderByPaymentMethod: any
}

export function Boleto({ error, finishOrderByPaymentMethod, isValidateSubmit }: Props) {
  const { lastOrderCreated } = usePrepareCart()
  const [generateBoleto, setGenerateBoleto] = useState(false)

  const handleBarcodeInfo = () => {
    generateBoletoInfo()
    setGenerateBoleto(true)
  }

  const [barcodeInfo, setBarcodeInfo] = useState<any>({
    amount: 0,
    boleto_barcode: '',
    boleto_expiration_date: new Date(),
    boleto_url: ''
  })

  async function generateBoletoInfo(): Promise<any> {
    try {
      const response = await finishOrderByPaymentMethod({ type: 'boleto' })
      const payload = {
        amount: response?.paymentInfos?.amount,
        boleto_barcode: response?.paymentInfos?.boleto_barcode.toString(),
        boleto_expiration_date: response?.paymentInfos?.boleto_expiration_date,
        boleto_url: response?.paymentInfos?.boleto_url
      }
      setBarcodeInfo(payload)
      setGenerateBoleto(true)
      return payload
    } catch (error) {
      return error
    }
  }

  return (
    <Style.Container>
      {!generateBoleto && (
        <Style.BoletoRequest>
          <span>
            <BiBarcode size="5em" />
          </span>
          <h1>
            Valor a pagar:<b> </b>
            <b>
              {new Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'symbol'
              }).format(Number(lastOrderCreated?.amount))}
            </b>
          </h1>
          <h2>Seu boleto ficará válido para pagamento por 5 dias úteis</h2>
          <Style.ButtonCopy onClick={handleBarcodeInfo} disabled={isValidateSubmit}>
            Finalizar pedido e gerar boleto
          </Style.ButtonCopy>
          <h3>O prazo para compensação do pagamento é de até 3 dias úteis.</h3>
        </Style.BoletoRequest>
      )}
      {generateBoleto && <BoletoBarcode data={barcodeInfo} />}
    </Style.Container>
  )
}
