import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'

import Text from 'components/Text'

import { BiLeftArrowCircle } from 'react-icons/bi'
import Navigator from './Navigator'

import * as Style from './styles'

interface NavigatorProps {
  step: number
}

export default function NavigationHandler({ step }: NavigatorProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const history = useHistory()

  const handleNavigation = useCallback(() => {
    if (step === 0) {
      history.push('/')
    }

    if (step === 1) {
      history.push('/receita-branca/inserir-receita')
    }

    if (step === 2) {
      history.push('/receita-branca/selecao-produtos')
    }

    if (step === 3) {
      history.push('/receita-branca/endereco-entrega')
    }

    if (step === 4) {
      history.push('/receita-branca/documentos-pessoais')
    }

    // eslint-disable-next-line no-param-reassign
  }, [step])

  return (
    <Style.Container isMobile={isMobile}>
      <Style.ButtonWrapper onClick={handleNavigation}>
        <BiLeftArrowCircle size={40} color="#115850" />
        <Text type="lg" bold color="tertiary">
          Voltar
        </Text>
      </Style.ButtonWrapper>

      <Style.NavigatorWrapper>
        <Navigator step={step} />
        {/* eslint-disable no-nested-ternary */}
        {step === 0 ? (
          <>
            <Text type="lg" bold color="tertiary">
              Anexe sua receita
            </Text>
            <Style.SubmitButton>
              <Style.Reference href="https://whts.co/Cannect" target="__blank">
                <h3>Não tenho uma receita</h3>
              </Style.Reference>
            </Style.SubmitButton>
          </>
        ) : step === 1 ? (
          <Text type="lg" bold color="tertiary">
            Escolha o produto
          </Text>
        ) : step === 2 ? (
          <Text type="lg" bold color="tertiary">
            Dados pessoais
          </Text>
        ) : step === 3 ? (
          <Text type="lg" bold color="tertiary">
            Documentos
          </Text>
        ) : step === 4 ? (
          <Text type="lg" bold color="tertiary">
            Autorização da Anvisa
          </Text>
        ) : null}
      </Style.NavigatorWrapper>
    </Style.Container>
  )
}
