import { useQuery } from '@tanstack/react-query'
import * as requests from './requests'

const KEY_ADDRESS_BY_CEP = 'getAddressByCep'

export const useAddressByCep = (cep: string) => {
  const onlyLettersZipCode = cep?.replace(/\D/g, '')

  return useQuery({
    queryKey: [KEY_ADDRESS_BY_CEP, cep],
    queryFn: () => requests.getAddressByZipCode(onlyLettersZipCode),
    enabled: !!onlyLettersZipCode && onlyLettersZipCode.length === 8,
    staleTime: 0,
    meta: {
      errorMessage: 'Houve um erro ao buscar o endereço pelo CEP.'
    }
  })
}
