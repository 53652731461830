import { ElementType, HtmlHTMLAttributes } from 'react'
import { VariantProps, tv } from 'tailwind-variants'

const heading = tv({
  base: 'font-sans text-neutral-800',
  variants: {
    type: {
      headingOne: 'text-5xl',
      headingTwo: 'text-4xl',
      headingThree: 'text-3xl',
      headingFour: 'text-2xl',
      headingFive: 'text-xl',
      headingSix: 'text-lg'
    },
    weight: {
      light: 'font-light',
      regular: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold'
    }
  },
  defaultVariants: {
    type: 'headingOne',
    fontFamily: 'default',
    weight: 'semibold'
  }
})

export interface THeadingProps extends HtmlHTMLAttributes<HTMLHeadingElement>, VariantProps<typeof heading> {
  level?: 1 | 2 | 3 | 4 | 5 | 6
}

export const Heading = ({ type, weight, className, level = 1, children, ...props }: THeadingProps) => {
  const Component = `h${level}` as ElementType

  return (
    <Component className={heading({ className, type, weight })} {...props}>
      {children}
    </Component>
  )
}
