import { theme } from 'design-cannect'
import styled, { css } from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export enum PictureSize {
  S = '144px',
  M = '9rem',
  L = '10rem'
}

export type PictureTypes = {
  size?: keyof typeof PictureSize
}

export const Container = styled('div')<{ showMore: boolean }>`
  ${(props) => css`
    display: flex;
    align-items: center;
    width: 100%;
    border: 2px solid #ecece2;
    display: flex;
    padding: 28px;
    border-radius: 8px;
    margin-bottom: 16px;
    transition: max-height 0.3s ease-in-out;
    height: auto;
    max-height: ${props.showMore ? '600px' : '209px'};
    font-size: 16px;

    @media (max-width: 960px) {
      flex-direction: column;
      max-height: ${props.showMore ? '800px' : '545px'};
      width: auto;

      div {
        align-self: center;
        margin-bottom: 30px;
        :last-child {
          margin-bottom: 0;
        }
      }
    }

    @media ${MOBILE_WIDTH} {
      font-size: 12px;
    }
  `}
`

export const SelfInfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  margin-right: 18px;
`

export const AboutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: space-between;
  align-self: flex-start;
  height: 100%;
  margin-right: auto;

  div.about {
    overflow: hidden;
    font-weight: 300;
    display: flex;
    /* flex-wrap: wrap; */
    padding-right: 35px;

    span {
      font-size: 15px;
      color: #3d3d3d;
      line-height: 23px;
    }
    span::before {
      font-weight: bold;
      content: 'Sobre: ';
      font-size: 15px;
    }

    strong {
      margin-right: 0.4rem;
    }

    @media ${MOBILE_WIDTH} {
      padding-right: 0;
    }
  }

  button {
    display: flex;
    align-items: center;
    color: #115850;
    border: none;
    background: transparent;
    font-weight: 300;
    letter-spacing: 0.05rem;
    margin: 1.5rem 0;
    font-size: 16px;

    span {
      margin-right: 0.5rem;
    }

    svg {
      margin-bottom: 4px;
    }
  }

  strong {
    display: flex;
    align-items: center;
  }

  @media ${MOBILE_WIDTH} {
    button {
      margin: 0;
      font-size: 18px;
      align-self: center;
    }

    align-self: flex-start !important;
  }
`

export const ContactContainer = styled('div')<{ healthInsurance: boolean }>`
  ${({ healthInsurance }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 135px;
    align-self: flex-start;
    min-width: 249px;
    margin-left: 18px;

    p {
      font-weight: 300;
      margin-bottom: 1rem;
    }

    strong.atendimento-label {
      margin-bottom: 0.3rem;
    }

    span.health-insurance-status {
      font-size: 14px;
      margin: 0.7rem 0;
      color: ${healthInsurance ? theme.colors.primary : '#C5581B'};
    }

    @media ${MOBILE_WIDTH} {
      margin-left: 0;
    }
  `}
`

export const Picture = styled('img')<PictureTypes>`
  ${({ size = 'S' }) => css`
    width: ${PictureSize[size]};
    height: ${PictureSize[size]};
    min-width: ${PictureSize[size]};
    min-height: ${PictureSize[size]};
    border-radius: 50%;
    object-fit: cover;

    @media ${MOBILE_WIDTH} {
      width: 98.51px;
      height: 98.51px;
      min-width: 98.51px;
      min-height: 98.51px;
    }
  `}
`

export const PrescriberSelfInfo = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  color: ${theme.colors.darkGrey};
  /* height: 117px; */
  height: auto;
  width: 239px;
  justify-content: space-between;

  .occupation {
    font-size: 14px;
  }

  // Name will be a paragraph with slightly bigger font size
  p.name {
    font-size: 20px;
    font-weight: 300;
    color: #3d3d3d;
    margin-bottom: 7px;

    @media ${MOBILE_WIDTH} {
      font-size: 18px;
    }
  }

  span {
    font-weight: 300;
    margin: 0.2rem 0;
    font-size: 15px;
  }

  // Every other info will be a strong element
  strong {
    font-weight: bold;
    font-size: 15px;
  }

  @media ${MOBILE_WIDTH} {
    margin-left: 8px;
    margin-right: auto;
  }
`

export const WrappedButton = styled('button')`
  height: 38px;
  font-weight: 700;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding: 0px 16px;
  background: #115850;
  color: #ecece2;
  transition: transform 0.2s;
  width: 201px;
  height: 48px;
  font-size: 14px;
`
