import React from 'react'
import { Container } from './styles'
import Text from '../../Text'
import { productBluebird } from '../../../assets/img'

function CardDetailsProcuct() {
  return (
    <Container>
      <div className="detalhes">
        <img src={productBluebird} alt="" />
        <div>
          <Text type="md" bold color="tertiary">
            1 unidade
          </Text>
          <Text type="md" color="tertiary">
            Espectro Amplo - CBD Oil Capsules 3000mg - 60 cápsulas
          </Text>
        </div>
      </div>
      <div>
        <Text type="md" bold color="tertiary">
          R$ 40,90
        </Text>
      </div>
    </Container>
  )
}

export default CardDetailsProcuct
