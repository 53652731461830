import Footer from '../Footer'
import NavigationHandler from '../NavigationHandler'
import Products from '../Products'

import * as Style from './styles'

export default function SelectProduct() {
  return (
    <>
      <NavigationHandler step={1} />

      <Style.Container>
        <Products />
      </Style.Container>

      <Footer />
    </>
  )
}
