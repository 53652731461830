import { useCannectCare } from 'hooks/useCannectCare'
import { useEffect } from 'react'
import Loading from 'components/Loading'
import { logoCannectCuida } from 'assets/img'
import InvisibleButton from 'components/InvisibleButton'
import * as Style from './styles'
import SymptomsSelection from './SymptomsSelection'
import OccurrencesSelection from './OccurrencesSelection'
import ThankYou from '../ThankYou'

export default function Symptoms() {
  const { step, loading, fetchSymptoms, setPath, setStep } = useCannectCare()

  useEffect(() => {
    fetchSymptoms()
  }, [])

  function handleGoBackClick() {
    setPath('')
    setStep(0)
  }

  return (
    <Style.SymptomsContainer>
      <Style.HeaderContainer>
        <InvisibleButton onClick={() => handleGoBackClick()}>
          <img src={logoCannectCuida} alt="Cannect Cuida" />
        </InvisibleButton>
        <h1>Sintomas</h1>
      </Style.HeaderContainer>
      {step === 0 && <SymptomsSelection />}
      {step === 1 && <OccurrencesSelection />}
      {step === 2 && <ThankYou />}
      {loading && <Loading loading={loading} />}
    </Style.SymptomsContainer>
  )
}
