import { pngArtigo4 } from 'assets/img'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { BsArrowRight } from 'react-icons/bs'
import * as Styles from './styles'

export interface EducateCardProps {
  title?: string
  text?: string
  image?: string
  module?: string
  path?: string
  moreInfoText: string
  category?: string
}

export default function EducateCard({ image, text, title, module, path, moreInfoText, category }: EducateCardProps) {
  return (
    <Styles.Wrapper className={category}>
      <Styles.ImageBox className={category}>
        <Styles.Image src={image || pngArtigo4} alt="aa" loading="lazy" />
      </Styles.ImageBox>

      <Styles.Content>
        {!!module && <Styles.ModuleName>Módulo {module}</Styles.ModuleName>}
        <Styles.Title>{title}</Styles.Title>
        {!!text && (
          <Styles.Description>
            <TextDanger text={text} />
          </Styles.Description>
        )}
      </Styles.Content>
      <Styles.LinkCustom href={path || ''} target="_blank" rel="noreferrer">
        {moreInfoText} <BsArrowRight />
      </Styles.LinkCustom>
    </Styles.Wrapper>
  )
}
