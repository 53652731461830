import React, { useState, useCallback } from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'

import { StyledButton } from './styles'

interface CopyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dataToCopy: string
  buttonText?: string
  copiedText?: string
  fullWidth?: boolean
}

function CopyButton({
  dataToCopy,
  buttonText = 'Copiar',
  copiedText = 'Copiado!',
  fullWidth,
  ...props
}: CopyButtonProps) {
  const [copied, setCopied] = useState<boolean>(false)

  const copyByClipboard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      setCopied(true)
      toast.success('Copiado para área de transferência')
    } catch (err) {
      console.error('Falha ao copiar: ', err)
      toast.error('Falha ao copiar')
    }
  }, [])

  const handleCopy = useCallback(async () => {
    await copyByClipboard(dataToCopy)
    setTimeout(() => setCopied(false), 3000)
  }, [dataToCopy, copyByClipboard])

  return (
    <StyledButton fullWidth={fullWidth} copied={copied} onClick={handleCopy} {...props}>
      {copied ? (
        <>
          <CheckCircleOutlined /> {copiedText}
        </>
      ) : (
        buttonText
      )}
    </StyledButton>
  )
}

export default CopyButton
