import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const OrderStatusContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  color: #dc602e;
  margin-bottom: 2rem;

  p {
    margin-bottom: 2rem;
  }
`

export const StatusDetails = styled.div`
  display: flex;

  margin-top: 1rem;
  margin-bottom: 2rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 0.25rem;
  }
`
