import { keyframes } from 'styled-components'

const smallDown = keyframes`
    from {
        height: 0;
        padding-top: 0;
    }
    to {
        height: 200px;
        padding-top: 21px;
    }
`

const smallUp = keyframes`
    from {
        height: 200px;
        padding-top: 21px;
    }
    to {
        height: 0;
        padding-top: 0;

    }
`

const mediumDown = keyframes`
    from {
        height: 0;
        padding-top: 0;
    }
    to {
        height: 230px;
        padding-top: 21px;
    }
`

const mediumUp = keyframes`
    from {
        height: 230px;
        padding-top: 21px;
    }
    to {
        height: 0;
        padding-top: 0;

    }
`

const largeDown = keyframes`
    from {
        height: 0;
        padding-top: 0;
    }
    to {
        height: 280px;
        padding-top: 21px;
    }
`

const largeUp = keyframes`
    from {
        height: 280px;
        padding-top: 21px;
    }
    to {
        height: 0;
        padding-top: 0;

    }
`

const fullDown = keyframes`
    from {
        height: 0;
        padding-top: 0;
    }
    to {
        width: 100%;
        height: fit-content;
        padding-top: 21px;
    }
`

const fullUp = keyframes`
    from { 
        width: 100%;
        height: fit-content;
        padding-top: 21px;
    }
    to {
        height: 0;
        padding-top: 0;

    }
`

export const AnimationResponsive = { largeDown, largeUp, mediumDown, mediumUp, smallDown, smallUp, fullDown, fullUp }
