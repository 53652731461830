import axios from 'axios'
import BreadCrumb from 'components/BreadCrumb'
import Pagination from 'components/Pagination'
import PrescriberFilter from 'components/PrescriberFilter'
import formatApiData, { PrescriberApiTypes } from 'components/PrescriberInfo/formatApiData'
import { PrescriberInfoTypes } from 'components/PrescriberInfo/PrescriberInfo'
import PrescriberList from 'components/PrescriberList'
import { getUserRoles, rolesPatientOrDoctor } from 'hooks/useSessionUser'
import React, { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { debounce } from 'utils/debounce.js'
import * as Style from './styles'
import useMediaQuery from '../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../utils/constants'

type QueryParamsType = {
  name?: string
  expertise?: string
  patology?: string
  health_insurance?: number
  online_appointment?: number
  presencial_appointment?: number
}

let timer: ReturnType<typeof setTimeout>

function Prescribers() {
  const [prescribers, setPrescribers] = useState<PrescriberInfoTypes[]>([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [loading, setLoading] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [filter, setFilter] = useState({
    name: '',
    expertise: '',
    patology: ''
  })
  const roles = getUserRoles()
  const [checkboxFilter, setCheckboxFilter] = useState({
    health_insurance: false,
    online_appointment: false,
    presencial_appointment: false
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFilter(filter => ({ ...filter, [name]: value }))
  }

  const handleCheckbox = (type: keyof typeof checkboxFilter) => {
    setCheckboxFilter(filter => ({ ...filter, [type]: !filter[type] }))
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const handlePageChange = async ({ selected }: { selected: number }) => {
    if (loading) return
    await getPrescribers(selected + 1)
  }

  const getPrescribers = async (pageNumber?: number) => {
    const { expertise, name } = filter
    const { health_insurance, online_appointment, presencial_appointment } = checkboxFilter

    const params = {} as QueryParamsType
    if (expertise) params.expertise = expertise
    if (name) params.name = name
    if (health_insurance) params.health_insurance = 1
    if (online_appointment) params.online_appointment = 1
    if (presencial_appointment) params.presencial_appointment = 1

    setLoading(true)
    document?.getElementById('page-wrapper')?.scrollTo(0, 0)
    const { data } = await api.get(`list_all_prescribers/${pageNumber || page}`, {
      params
    })
    setLoading(false)
    setPage(Number(data?.page) || pageNumber || page)

    const prescribers = data.prescribers.rows.map((p: PrescriberApiTypes) => formatApiData(p))

    setPrescribers(prescribers)
    setPageCount(Math.ceil(data.prescribers.count / 15))

    const prescribersListEl = document.getElementById('prescribers-list')
    if (prescribersListEl) prescribersListEl.scrollTop = 0
  }

  const redirectToWhats = () => {
    window.open(
      'https://api.whatsapp.com/send/?phone=5511950811547&text=Ol%C3%A1%21+Gostaria+de+saber+mais+e+fazer+parte+da+listagem+de+profissionais+da+sa%C3%BAde+da+Cannect&type=phone_number&app_absent=0'
    )
  }

  useEffect(() => {
    // fetch data
    clearTimeout(timer)
    timer = setTimeout(() => {
      getPrescribers(1)
    }, 500)
  }, [checkboxFilter, filter])

  return (
    <Style.Container>
      {roles === 2 ? (
        <Style.HeaderPrescribers isMobile={isMobile}>
          <BreadCrumb
            paths={[{ label: 'Cannect prescritores', link: '/profissionais-parceiros-cannabis-medicinal' }]}
          />
          <Style.ContainerButtons isMobile={isMobile}>
            <Style.Title>Seja um profissional parceiro da Cannect:</Style.Title>
            <Style.ButtonBeList onClick={redirectToWhats}>Quero ser listado</Style.ButtonBeList>
          </Style.ContainerButtons>
        </Style.HeaderPrescribers>
      ) : (
        <BreadCrumb paths={[{ label: 'Cannect prescritores', link: '/profissionais-parceiros-cannabis-medicinal' }]} />
      )}

      <PrescriberFilter
        filter={filter}
        checkboxFilter={checkboxFilter}
        handleChange={handleChange}
        onSubmit={onSubmit}
        handleCheckbox={handleCheckbox}
      />
      <PrescriberList prescribers={prescribers} />
      <div className="pagination-prescribers">
        <Pagination pageCount={pageCount} onPageChange={handlePageChange} forcePage={page - 1} />
      </div>
    </Style.Container>
  )
}

export default Prescribers
