import { useGetPrescribersHighlightedList } from '@cannect/services/resources/home'
import { useGetCannectPosts, useGetStrapiBrands } from '@cannect/services/resources/strapi'
import { useMemo } from 'react'
import { useHistory } from 'react-router'

export const useHome = () => {
  const history = useHistory()

  const { data: highlightedPrescribers } = useGetPrescribersHighlightedList()
  const { data: postsData } = useGetCannectPosts({})

  const { data: brandsData } = useGetStrapiBrands({
    filterQuery: 'populate=logo&pagination[pageSize]=100'
  })

  const postsList = postsData?.blog.data

  const brandsList = useMemo(() => {
    return (
      brandsData?.data?.map((item: any) => ({
        path: `${import.meta.env.VITE_APP_STRAPI_URL}${item?.attributes?.logo?.data?.attributes?.url}`,
        onClick: () => history.push(`/marcas/${item?.attributes?.slug}`)
      })) || []
    )
  }, [brandsData])

  const handleGoTo = (path: string) => {
    history.push(path)
  }

  return {
    highlightedPrescribers,
    postsList,
    handleGoTo,
    brandsList
  }
}
