import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const BackToPanelLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;
  margin-bottom: 1rem;
`

export const BackToPanelLinkItself = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  text-decoration: none;
  cursor: pointer;
  color: #3d3d3d;
`
