import styled from 'styled-components'

export const LifeQualityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  width: 100%;

  > span {
    text-align: center;
    font-size: 1.25rem;
  }

  > p {
    font-size: 1.1rem;
    line-height: 2rem;
  }
`
