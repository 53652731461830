import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import Modal from 'new-components/Modal'
import * as Style from './styles'
import { WrapperProduct } from './styles'

interface modalInfoProps {
  isModalFlowersInactive: boolean
  closeModal: any
  itensFlowerInactive: any[]
}

function ModalFlowersInactive({ isModalFlowersInactive, itensFlowerInactive, closeModal }: modalInfoProps) {
  return (
    <Modal isOpen={isModalFlowersInactive} setOpenModal={closeModal} hiddenClose>
      <Style.ContainerModal>
        <div>
          <Heading elementType="h5" defaultColor="darkGrey" fontSize="16px" verticalSpace="small">
            Este produto não está mais disponível.
          </Heading>
        </div>
        {itensFlowerInactive.map((item: any) => (
          <WrapperProduct>
            <div className="product-image">
              <img src={item.product?.media[0]?.path} alt="imagem do produto" />
            </div>
            <div>{item?.product?.translated_name}</div>
          </WrapperProduct>
        ))}
        <Button onClick={closeModal}>Estou ciente, continuar</Button>
      </Style.ContainerModal>
    </Modal>
  )
}

export default ModalFlowersInactive
