import { Typography } from '@cannect/new-components/atoms'
import { ElementType } from 'react'

type THomeFeatureCard = {
  icon: ElementType
  title: string
  subtitle: string
}

export const HomeFeatureCard = ({ icon: IconElement, title, subtitle }: THomeFeatureCard) => {
  return (
    <div className="flex h-[340px] min-w-[273px] flex-col items-start gap-[16px] rounded bg-gray-50 py-6 pl-6 pr-5 text-left text-neutral-800">
      <IconElement className="h-12 w-12 text-primary-700" />
      <Typography.Text weight="semibold" type="paragraphOne" className="text-lg leading-[120%] text-secondary-1000">
        {title}
      </Typography.Text>

      <Typography.Text type="paragraphTwo" className="leading-[150%] md:text-lg">
        {subtitle}
      </Typography.Text>
    </div>
  )
}
