import { Button } from 'design-cannect'
import { useHistory } from 'react-router-dom'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'
import TabContentPost from '../TabContentPost'

interface TabContentProps {
  actionButtonText: string
  seeMorePath?: string
  posts: Post[]
  externalRedirect?: boolean
  inLine?: boolean
}

export interface Post {
  id: number
  url: string
  img?: string | undefined
  title: string
  description: string
  externalUrl: string
}

export default function CardContent({
  seeMorePath,
  actionButtonText,
  externalRedirect,
  posts,
  inLine
}: TabContentProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const history = useHistory()

  function handleRedirect() {
    history.push(seeMorePath as string)
  }

  return (
    <Style.AllWrapp>
      <Style.TabContentContainer isDesktop={!isMobile}>
        <Style.ContentItself isDesktop={!isMobile}>
          {posts.map(post => (
            <Style.CardContainer isDesktop={!isMobile}>
              <TabContentPost
                key={post.id}
                title={post.title}
                description={post.description}
                img={post.img}
                url={post.url}
                actionButtonText={actionButtonText}
                externalRedirect={externalRedirect}
                inLine={inLine}
              />
            </Style.CardContainer>
          ))}
        </Style.ContentItself>
      </Style.TabContentContainer>
      {seeMorePath && (
        <Button outlined onClick={() => handleRedirect()}>
          Mais
        </Button>
      )}
    </Style.AllWrapp>
  )
}
