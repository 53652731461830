import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Container = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 170px 0 100px 48px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  :before {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 0 100% 100% 0;
    background-color: #78d8b7;
    width: 350px;
    height: 700px;
    transform: translate(0px, 20px) scale(1.2);
    z-index: -1;
    opacity: 0.1;
    filter: blur(20px);
    @media (max-width: 900px) {
      width: 250px;
    }
  }

  @media (min-width: 900px) and (max-width: 1249px) {
    padding: 140px 0 100px 28px;
  }
`

export const Title = styled.h3`
  font-size: 40px;
  font-family: 'Spartan', sans-serif;
  font-weight: 400;
  color: ${temporaryTheme.colors.darkGrey};
  margin-bottom: 42px;

  span {
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
  }

  @media (max-width: 900px) {
    font-size: 26px;
    text-align: start;
    margin-bottom: 48px;
  }
`
export const StoriesContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 20px;

  width: 100%;
  padding-bottom: 12px;
  overflow-x: auto;
  color: #00000000;
  transition: color 0.3s;
  ::-webkit-scrollbar {
    width: 14px;
    height: 8px;
  }
  :-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 7px;
    box-shadow: inset 0 0 0 6px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  :hover {
    color: #d9d9d9;
  }
`
export const MoreInfo = styled.button`
  width: '420px';
  border: none;
  background: none;
  margin-top: 28px;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  text-decoration: underline;
  color: ${temporaryTheme.colors.link};
  transition: all 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: 900px) {
    margin-top: 16px;
  }
`
