import { useState } from 'react'
import api from 'services/api'
import { Consultation, GroupedConsultations } from '../types/appointment'
import { useQueries } from './useQueries'

export function useConsultation() {
  const [loadingRate, setLoadingRate] = useState(false)
  const { handleReloadList, handleLoading } = useQueries()

  const groupConsultationsByYear = (consultations: Consultation[]): GroupedConsultations => {
    const groupedConsultations: GroupedConsultations = {}

    consultations.forEach(consultation => {
      const year = new Date(consultation.date).getFullYear().toString()

      if (!groupedConsultations[year]) {
        groupedConsultations[year] = []
      }

      groupedConsultations[year].push(consultation)
    })

    return groupedConsultations
  }

  const cancelConsultation = async (appointment_id: number | string) => {
    handleLoading('loadCancel', true)
    try {
      const { data } = await api.post(`/scheduling/user/appointment/cancel/${appointment_id}`)

      if (data.success) {
        handleReloadList()
        return data
      }
      return { message: 'Erro ao cancelar consulta' }
    } catch (e) {
      return { message: 'Erro ao cancelar consulta' }
    } finally {
      handleLoading('loadCancel', false)
    }
  }

  const rateConsultation = async (appointment_id: number | string, rate: number, comment: string) => {
    setLoadingRate(true)
    try {
      const { data } = await api.post(`/scheduling/user/appointment/rate/${appointment_id}`, {
        rate,
        comment
      })

      if (data.success) {
        return data
      }

      return { success: false, message: data?.message }
    } catch (e) {
      return { success: false, message: 'Erro ao avaliar consulta' }
    } finally {
      setLoadingRate(false)
    }
  }

  const putRescheduling = async (
    appointment_id: number | string,
    { date, hour, duration }: { date: string; hour: string; duration: number }
  ) => {
    handleLoading('loadReschedule', true)

    try {
      const params = {
        date,
        hour,
        duration
      }
      const { data } = await api.put(`/scheduling/appointment/${appointment_id}`, params)

      if (data.success) {
        handleReloadList()
        return data
      }
      return { message: 'Erro ao reagendar consulta' }
    } catch (e) {
      return { message: 'Erro ao reagendar consulta' }
    } finally {
      handleLoading('loadReschedule', false)
    }
  }

  return {
    groupConsultationsByYear,
    cancelConsultation,
    rateConsultation,
    putRescheduling
  }
}
