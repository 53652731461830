export const FilterBadgeMock = [
  { type: 'cronica', link: 'category_id=103', id: '1' },
  { type: 'mental', link: 'category_id=104', id: '2' },
  { type: 'oncologia', link: 'category_id=105', id: '3' },
  { type: 'neurologia', link: 'category_id=106', id: '4' },
  { type: 'pediatria', link: 'category_id=107', id: '5' },
  { type: 'intimo', link: 'category_id=108', id: '6' },
  { type: 'pele', link: 'category_id=109', id: '7' },
  { type: 'esportiva', link: 'category_id=110', id: '8' },
  { type: 'odontologia', link: 'category_id=113', id: '11' }
]

export const ORDER_MENU_FILTER = [
  {
    key: '1',
    by: 'price',
    order: 'ASC',
    label: 'Menor preço',
    name: 'Menor preço'
  },
  {
    key: '2',
    by: 'price',
    order: 'DESC',
    label: 'Maior preço',
    name: 'Maior preço'
  },
  {
    key: '3',
    by: 'name',
    order: 'ASC',
    label: 'Ordenar de A a Z',
    name: 'Ordenar de A a Z'
  },
  {
    key: '4',
    by: 'name',
    order: 'DESC',
    label: 'Ordenar de Z a A',
    name: 'Ordenar de Z a A'
  }
]
