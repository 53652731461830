import TextDanger from 'new-components/partnesComponents/TextDanger'
import * as Styles from './styles'
import { ImageLazy } from '../Imageload'

export interface CardBlogProps {
  title: string
  text: string
  image: string
  handleSelectPost?: () => void
  imageLoading?: string
}

export function Card({ text, title, image, imageLoading, handleSelectPost = () => null }: CardBlogProps) {
  return (
    <Styles.Wrapper onClick={handleSelectPost}>
      <ImageLazy image={image} alt={title} imageLoading={imageLoading} />
      <Styles.Title>{title}</Styles.Title>
      <Styles.Text id="text-content">
        <TextDanger text={text} withMaxLengthText maxLineText={4} />
      </Styles.Text>
    </Styles.Wrapper>
  )
}
