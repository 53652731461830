import { ReactNode } from 'react'
import TabButton from './components/TabButton'

export interface TabsProps {
  tabs: {
    label: string
    value: string | undefined
    content?: ReactNode
  }[]
  value: string | undefined
  onValueChange: (value: string | undefined) => void
  className?: string
}

const Tabs = ({ tabs, value, onValueChange, className }: TabsProps) => {
  const selectedTab = tabs.find(tab => tab.value === value)

  return (
    <div className={`${className} flex flex-col gap-2`}>
      <div className="flex gap-5 border-x-0 border-b-2 border-t-0 border-solid border-gray-100">
        {tabs.map(tab => (
          <TabButton key={tab.value} isActive={tab.value === value} onClick={() => onValueChange(tab.value)}>
            {tab.label}
          </TabButton>
        ))}
      </div>

      {selectedTab?.content}
    </div>
  )
}

export default Tabs
