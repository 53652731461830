import Input from 'components/Input'
import QuestionMarkButton from './QuestionMaskButton'
import * as Style from './styles'

interface DiseaseProps {
  value: string
  onChange: (value: string) => void
}

export default function OtherDisease({ value, onChange }: DiseaseProps) {
  return (
    <Style.OtherDiseaseContainer>
      <QuestionMarkButton />
      <Input value={value} placeholder="Outro" onChange={event => onChange(event.target.value)} />
    </Style.OtherDiseaseContainer>
  )
}
