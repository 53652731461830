import { courseIcon } from 'assets/svg'
import LoadingContainer from 'components/LoadingContainer'
import Button from 'new-components/Button'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from 'services/api'
import { DrCannabisCourses } from 'pages/CannectEducate/textMock'
import EducateCard from '../EducateCard'
import * as Styles from './styles'

export interface SectionCategoryProps {
  page?: number
  limit?: number
  category: string
  title: string
  subTitle?: string
  image?: string
  icon?: string
  description?: string
  redirectLink?: string
  externalLink?: boolean
}

export default function SectionCategory({
  category,
  title,
  image,
  limit = 4,
  page,
  subTitle,
  icon,
  description,
  redirectLink,
  externalLink
}: SectionCategoryProps) {
  const [posts, setPosts] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const formatResponse = (data: any) => {
    const newValues = data?.workshops || data?.podcasts || data?.whitepapers || data?.courses || data?.webinars
    return newValues?.rows || newValues
  }

  const formatUrl = page ? `/${category}/${page}?limit=${limit}` : `/${category}/1`

  const getPost = async () => {
    setLoading(true)
    try {
      const { data } = await api.get(formatUrl)
      const FormattData = formatResponse(data)

      if (category === 'course') {
        const modulesCourses = data?.courseLessons?.length > 0 ? data?.courseLessons : []
        const limitedModules = modulesCourses.slice(0, 4)
        const formatedModules = limitedModules?.map((item: any) => ({
          id: item?.id,
          title: item?.title,
          description: item?.description,
          image: item?.image,
          module: item?.lesson_module
        }))
        setPosts(formatedModules)
      } else {
        setPosts(FormattData)
      }
    } catch (e) {
      toast.error(`Erro ao carregar as postagens dos ${title}`)
    } finally {
      setLoading(false)
    }
  }

  const SelectText = () => {
    if (category === 'course') return 'Acessar curso completo'
    if (category === 'dr_cannabis') return 'Explorar todos os cursos'
    return 'Veja mais'
  }

  const SelectMoreInfo = () => {
    if (category === 'podcasts') return 'Escutar'
    if (category === 'dr_cannabis') return 'Acessar curso'
    if (category === 'webinars') return 'Assistir'
    if (category === 'whitepapers') return 'Ler whitepaper'
    return 'Assistir aula'
  }

  const handleRouterLink = (link: string) => {
    history.push(link)
  }

  const cousePath = (id: number) => {
    return category === 'course' ? `/educa/cursos-introdutorios/${id}` : ''
  }

  useEffect(() => {
    if (category === 'dr_cannabis') {
      setPosts(DrCannabisCourses)
    } else {
      getPost()
    }
  }, [])

  return (
    <Styles.Wrapper>
      <Styles.SectionCategory>
        <Styles.TitleCategory>
          <Styles.IconCategory src={icon || courseIcon} alt="icone de cursos" />
          {title} {!!subTitle && <span> - {subTitle}</span>}
        </Styles.TitleCategory>

        {!!description && (
          <Styles.DescriptionCategory>
            <TextDanger text={description} fontSize="20px" fontWeight={400} />
          </Styles.DescriptionCategory>
        )}

        {loading ? (
          <Styles.LoadingBox>
            <LoadingContainer loading />
          </Styles.LoadingBox>
        ) : (
          <Styles.CardGrid>
            {posts?.length > 0 ? (
              <>
                {posts.map((item: any) => (
                  <EducateCard
                    category={category}
                    key={item?.id}
                    title={item?.title || item?.name}
                    text={category === 'podcasts' ? null : item?.description}
                    image={item?.image || item?.img}
                    path={item?.path || item?.link || cousePath(item?.id)}
                    module={item?.module}
                    moreInfoText={SelectMoreInfo()}
                  />
                ))}
                <Styles.ActionsBox>
                  {externalLink ? (
                    <Button background="black" isOutlined>
                      <Styles.LinkExternal to={{ pathname: redirectLink || '' }} target="_blank">
                        {SelectText()}
                      </Styles.LinkExternal>
                    </Button>
                  ) : (
                    <Button background="black" isOutlined onClick={() => handleRouterLink(redirectLink || '#')}>
                      {SelectText()}
                    </Button>
                  )}
                </Styles.ActionsBox>
              </>
            ) : (
              <Styles.EmptyState>Não encontramos postagens</Styles.EmptyState>
            )}
          </Styles.CardGrid>
        )}
      </Styles.SectionCategory>
    </Styles.Wrapper>
  )
}
