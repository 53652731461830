import React, { useEffect, useState } from 'react'
import { svgPixIcon, svgPixIconWhite } from 'assets/svg'
import * as Style from './styles'
import { Boleto } from './Boleto'
import { CreditCart } from './CreditCart'
import { Pix } from './Pix'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../utils/constants'

interface PaymentMethod {
  type: 'credit_card' | 'pix' | 'boleto'
}

// eslint-disable-next-line no-redeclare
function PaymentMethods({
  disabledPix,
  disabledCreditCard,
  disabledBoleto,
  finishOrderByPaymentMethod,
  error,
  isValidateSubmit,
  loadingContainer,
  clearIntervalOutside
}: any) {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({ type: 'credit_card' })
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  function AlterMethod(method: 'credit_card' | 'pix' | 'boleto') {
    setPaymentMethod({ type: method })
  }
  useEffect(() => {
    return () => {
      clearIntervalOutside()
    }
  }, [clearIntervalOutside])

  return (
    <Style.Container isMobile={isMobile}>
      <Style.Title>Meio de Pagamento</Style.Title>
      <Style.Methods>
        {!disabledCreditCard && (
          <Style.ButtonMethod
            disabled={isValidateSubmit}
            isActive={paymentMethod?.type === 'credit_card'}
            onClick={() => AlterMethod('credit_card')}
          >
            Cartão de crédito
          </Style.ButtonMethod>
        )}
        {!disabledPix && (
          <Style.ButtonMethod
            disabled={isValidateSubmit}
            isActive={paymentMethod?.type === 'pix'}
            onClick={() => AlterMethod('pix')}
          >
            Pix
            {paymentMethod?.type !== 'pix' && <img src={svgPixIcon} alt="Pix" />}
            {paymentMethod?.type === 'pix' && <img src={svgPixIconWhite} alt="Pix" />}
          </Style.ButtonMethod>
        )}
        {!disabledBoleto && (
          <Style.ButtonMethod
            disabled={isValidateSubmit}
            isActive={paymentMethod?.type === 'boleto'}
            onClick={() => AlterMethod('boleto')}
          >
            Boleto Bancário
          </Style.ButtonMethod>
        )}
      </Style.Methods>
      {!disabledPix && paymentMethod?.type === 'pix' && (
        <Pix
          finishOrderByPaymentMethod={finishOrderByPaymentMethod}
          error={error}
          loadingContainer={loadingContainer}
        />
      )}
      {!disabledCreditCard && paymentMethod?.type === 'credit_card' && (
        <CreditCart
          isValidateSubmit={isValidateSubmit}
          finishOrderByPaymentMethod={finishOrderByPaymentMethod}
          loadingContainer={loadingContainer}
        />
      )}
      {!disabledBoleto && paymentMethod?.type === 'boleto' && (
        <Boleto
          finishOrderByPaymentMethod={finishOrderByPaymentMethod}
          isValidateSubmit={isValidateSubmit}
          error={error}
        />
      )}
    </Style.Container>
  )
}

export default PaymentMethods
