import { lazyLoad } from 'assets/img'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import useMediaQuery from 'hooks/useMediaQuery'
import Button from 'new-components/Button'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import RealStoriesForm from 'new-components/realStoriesComponents/Form'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { getAllPostsByCategories } from 'usecases/categories'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Styles from './styles'

export interface HistoryProps {
  name: string
  content: string
  image: string
  id: number
}

export default function RealStories() {
  const history = useHistory()
  const { state } = useLocation() as any
  const [overflowText, setOverflowText] = useState(true)
  const [historySelected, setHistorySelected] = useState<any>({})
  const [histories, setHistories] = useState<HistoryProps[]>([])
  const [modal, setModal] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const modifierStoriesResult = ({ items = [] }: { items: any }) => {
    const newItems = items.map((stories: any) => ({
      name: stories?.attributes?.title,
      content: stories?.attributes?.content,
      image: stories?.attributes?.image?.data?.attributes?.url,
      id: stories?.id
    }))
    setHistories(newItems)

    if (state?.id) {
      const newHistorySelectedFromHome = newItems.filter((item: any) => item?.id === state?.id)
      setHistorySelected(newHistorySelectedFromHome[0])
    } else {
      setHistorySelected(newItems[0])
    }
  }

  const loadHistories = async () => {
    try {
      const responsePosts = await getAllPostsByCategories({
        category: 'historias-reais'
      })
      modifierStoriesResult({ items: responsePosts?.data })
    } catch (e) {
      toast.error('Erro ao buscar historia')
    }
  }

  const selectedHistory = (id: any) => {
    const newSelectedHistory = histories.filter((item) => item.id === id)
    setHistorySelected(newSelectedHistory[0])
  }

  useEffect(() => {
    loadHistories()
  }, [])

  return (
    <Styles.Wrapper>
      <Styles.HeadingPage>
        Conheça as <span>histórias de pessoas</span> que
        <br />
        <strong> mudaram suas vidas </strong> com a <strong> cannabis medicinal </strong>
      </Styles.HeadingPage>

      <Styles.HistoryContainer>
        <Styles.HistoryImageBox
          src={`${import.meta.env.VITE_APP_STRAPI_URL}${historySelected?.image}` || lazyLoad}
          loading="lazy"
          alt={`Imagem  de ${historySelected.name}`}
        />

        <Styles.HistoryContent>
          <Styles.HistoryName>{historySelected.name}</Styles.HistoryName>
          <div className="content-text">
            <TextDanger text={historySelected.content} withMaxLengthText={overflowText} />
          </div>
          <Styles.HiddenText onClick={() => setOverflowText(!overflowText)}>
            {overflowText ? 'Continuar lendo' : 'Mostrar menos'}
          </Styles.HiddenText>
          <Styles.HistoryActions>
            <Button background="grey" onClick={() => history.push('/agendamento')}>
              Agende sua consulta
            </Button>
            <Button background="green" isOutlined onClick={() => setModal(true)}>
              Inicie seu tratamento
            </Button>
          </Styles.HistoryActions>
        </Styles.HistoryContent>
      </Styles.HistoryContainer>

      <Styles.OtherStoriesContainer>
        <h3 className="title-other_stories">Leia outras histórias sobre os benefícios da Cannabis Medicinal</h3>

        <Styles.OtherStoriesSliderCard>
          <Styles.OtherStoriesSliderContent>
            {histories.map((item) => (
              <Styles.OtherStoriesCard
                key={item.name}
                src={`${import.meta.env.VITE_APP_STRAPI_URL}${item.image}`}
                loading="lazy"
                onClick={() => selectedHistory(item.id)}
              />
            ))}
          </Styles.OtherStoriesSliderContent>
        </Styles.OtherStoriesSliderCard>
      </Styles.OtherStoriesContainer>

      {/* <Styles.FormContainer>
        <h3 className="title-other_stories">Quer contar a sua história? Nos escreva!</h3>
        <RealStoriesForm />
      </Styles.FormContainer> */}
      <ModalHome isOpen={modal} setOpenModal={setModal}>
        <BeginCheckout />
      </ModalHome>
    </Styles.Wrapper>
  )
}
