import React from 'react'

export default function IconePacote({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M42.0001 33.4243V14.9368C41.9987 14.6697 41.9269 14.4078 41.7919 14.1774C41.6569 13.9469 41.4636 13.7562 41.2314 13.6243L24.7314 4.34303C24.509 4.21467 24.2568 4.14709 24.0001 4.14709C23.7434 4.14709 23.4912 4.21467 23.2689 4.34303L6.76887 13.6243C6.53665 13.7562 6.34331 13.9469 6.20835 14.1774C6.07338 14.4078 6.00156 14.6697 6.00012 14.9368V33.4243C6.00156 33.6913 6.07338 33.9533 6.20835 34.1837C6.34331 34.4141 6.53665 34.6049 6.76887 34.7368L23.2689 44.018C23.4912 44.1464 23.7434 44.214 24.0001 44.214C24.2568 44.214 24.509 44.1464 24.7314 44.018L41.2314 34.7368C41.4636 34.6049 41.6569 34.4141 41.7919 34.1837C41.9269 33.9533 41.9987 33.6913 42.0001 33.4243V33.4243Z"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1873 28.7742V19.0242L14.9998 8.99298"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.7939 14.168L24.1689 24.1805L6.20642 14.168"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1687 24.1806L23.9999 44.2056"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
