import { FaArrowRight } from 'react-icons/fa'
import * as Styles from './styles'

export interface CardFaqProps {
  title?: string
  image?: string
  link?: string
}

export default function CardFaq({ image, link, title }: CardFaqProps) {
  const redirectLink = () => {
    window.open(link, '_blank', 'noopener')
  }
  return (
    <Styles.Wrapper onClick={redirectLink}>
      <Styles.ImageBox>
        <Styles.Image src={image} alt={title} />
      </Styles.ImageBox>

      <Styles.Content>
        <Styles.Title>{title}</Styles.Title>
        <Styles.IconBox>
          <FaArrowRight />
        </Styles.IconBox>
      </Styles.Content>
    </Styles.Wrapper>
  )
}
