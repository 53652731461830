import animation from '@cannect/constants/animation'
import { cn } from '@cannect/lib/utils'
import { Typography } from '@cannect/new-components/atoms'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'

type TCounterBadgeProps = {
  quantity: number
  className?: string
  isLoading?: boolean
}
export const CounterBadge = ({ quantity, className, isLoading = false }: TCounterBadgeProps) => {
  const prevQuantityRef = useRef(quantity || 0)
  const animationDirection = quantity > prevQuantityRef.current ? 'increment' : 'decrement'

  useEffect(() => {
    prevQuantityRef.current = quantity
  }, [quantity])

  if (quantity === 0) return null

  if (isLoading) return <div className="absolute -top-[2px] right-0 h-5 w-5 animate-pulse rounded-full bg-gray-200" />
  return (
    <div
      className={cn(
        'absolute -top-[2px] right-0 flex h-4 min-w-4 flex-col items-center rounded-full bg-error-700 outline outline-2 outline-primary-750',
        className
      )}>
      <AnimatePresence mode="popLayout">
        <div className="flex flex-col items-center">
          <motion.div
            key={quantity}
            initial={animationDirection === 'increment' ? animation.counter.slideUp : animation.counter.slideDown}
            animate={{ y: '0', opacity: 1, scale: 1, filter: 'blur(0px)' }}
            exit={animationDirection === 'increment' ? animation.counter.slideDown : animation.counter.slideUp}
            transition={animation.counter.spring}>
            <Typography.Text weight="medium" type="paragraphTwo" className="text-center leading-[17px] text-white">
              {quantity}
            </Typography.Text>
          </motion.div>
        </div>
      </AnimatePresence>
    </div>
  )
}
