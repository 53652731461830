import { IconePessoa } from 'assets/svg'
import { Typography } from 'new-components/atoms'

const PatientDetailsHeader = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex h-fit items-center gap-2">
        <IconePessoa className="h-16 w-16 text-primary-700 [&>path]:stroke-primary-700" />
        <Typography.Heading type="headingFive" className="font-serif text-primary-700">
          Meus Pacientes {'>'} Detalhes do Paciente
        </Typography.Heading>
      </div>
      <Typography.Text type="captionOne">
        Importante: os pacientes são provenientes do assistente de prescrição, agendamento de consultas e pedidos
        através da Cannect.
      </Typography.Text>
    </div>
  )
}

export default PatientDetailsHeader
