import TextDanger from 'new-components/partnesComponents/TextDanger'
import * as Styles from './styles'

export interface SymptomsProps {
  text: string
}

export default function Symptoms({ text }: SymptomsProps) {
  return (
    <Styles.Wrapper>
      <Styles.Title>Sintomas </Styles.Title>
      <TextDanger text={text} />
    </Styles.Wrapper>
  )
}
