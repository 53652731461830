import { formatInTimeZone } from 'date-fns-tz'
import { TFlavor, TProductDetailItem } from './types'

export const findRecommendedFlavor = (flavors: TFlavor[], currentFlavor?: string | null): string | undefined => {
  if (flavors && flavors.length > 0) {
    const maxBalance = Math.max(...flavors.map((flavor) => flavor?.balance || 0))
    const highBalanceFlavors = flavors.filter((flavor) => flavor.balance === maxBalance)

    if (highBalanceFlavors && highBalanceFlavors?.length === 1) {
      return highBalanceFlavors[0].flavor
    }
    const productFlavorMatch = highBalanceFlavors.find((flavor) => flavor?.flavor === currentFlavor)
    return productFlavorMatch ? productFlavorMatch.flavor : highBalanceFlavors[0].flavor
  }

  return undefined
}

export const findValidPromotion = (product: TProductDetailItem) => {
  if (!product.promotion) return undefined

  const now = formatInTimeZone(new Date(), 'America/Sao_Paulo', "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")

  const productPrice = Number(product.real_price || product.price)
  const parsedRegularPrice = Number(product.promotion.regular_price || productPrice) || undefined
  const parsedPromotionalPrice = Number(product.promotion.promotional_price || productPrice) || undefined

  if (product?.promotion?.promotion_info) {
    const startDate = product.promotion.promotion_info.start_date || ''
    const endDate = product.promotion.promotion_info.end_date || ''

    if (now > startDate && now < endDate) {
      return {
        valid_promotion: {
          regular_price: parsedRegularPrice,
          promotional_price: parsedPromotionalPrice
        }
      }
    }
  } else if (product?.promotion?.status === 'active') {
    return {
      valid_promotion: {
        regular_price: parsedRegularPrice,
        promotional_price: parsedPromotionalPrice
      }
    }
  }

  return undefined
}
