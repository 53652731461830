import { SVGProps } from 'react'

interface PackageIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function PackageIcon({ isChecked, ...rest }: PackageIconProps) {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M24.8873 20.4168V9.51274C24.8865 9.35524 24.8441 9.20074 24.7645 9.06483C24.6849 8.92892 24.5708 8.8164 24.4339 8.73862L14.7021 3.26449C14.571 3.18878 14.4222 3.14893 14.2708 3.14893C14.1194 3.14893 13.9706 3.18878 13.8395 3.26449L4.10771 8.73862C3.97075 8.8164 3.85671 8.92892 3.77711 9.06483C3.6975 9.20074 3.65514 9.35524 3.6543 9.51274V20.4168C3.65514 20.5743 3.6975 20.7288 3.77711 20.8647C3.85671 21.0006 3.97075 21.1131 4.10771 21.1909L13.8395 26.665C13.9706 26.7407 14.1194 26.7806 14.2708 26.7806C14.4222 26.7806 14.571 26.7407 14.7021 26.665L24.4339 21.1909C24.5708 21.1131 24.6849 21.0006 24.7645 20.8647C24.8441 20.7288 24.8865 20.5743 24.8873 20.4168V20.4168Z"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.69 17.6739V11.9233L8.96289 6.00684"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.7651 9.06006L14.3698 14.9655L3.77539 9.06006"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3705 14.9648L14.271 26.7757"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
