/* eslint-disable import/no-extraneous-dependencies */
import { useTokenApi } from 'hooks/useTokenApi'
import ForgotPassword from 'pages/ForgotPassword'
import RegistrationWrapper from 'pages/Registration'
import { useScheduling } from 'hooks/useScheduling'
import Loading from 'components/Loading'

import { StepsContainer, Step, StepCircle, CheckoutContainer, StepContent, StepName, StepTitle } from './styles'
import PaymentStep from './PaymentStep'
import ConclusionStep from './ConclusionStep'
import ReviewStep from './ReviewStep'
import IdentificationStep from './IdentificationStep'
import When from 'utils/when'
import AppointmentResumeCard from './IdentificationStep/AppointmentResumeCard'

export default function CheckoutStep() {
  const { tokenApi } = useTokenApi()
  const {
    checkoutActiveStep,
    checkoutFormState,
    appointmentFromB2C,
    paidOrCanceledScheduling,
    checkoutSteps,
    loading
  } = useScheduling()

  const isStepActive = (stepNumber: number) => {
    if (tokenApi && stepNumber === 1) {
      return true
    }
    return checkoutActiveStep === stepNumber || checkoutActiveStep > stepNumber
  }

  const currentStep = checkoutSteps[checkoutActiveStep - 1]

  const isIdentificationStep = checkoutActiveStep === 1

  return (
    <CheckoutContainer marginTop={appointmentFromB2C || paidOrCanceledScheduling ? '42px' : '12px'}>
      <When expr={isIdentificationStep}>
        <AppointmentResumeCard />
      </When>

      <StepsContainer>
        <Loading loading={loading} />
        {checkoutSteps?.map(step => (
          <Step key={step.index} isCurrentStep={currentStep?.index === step?.index}>
            <StepCircle isActive={isStepActive(step?.index)}>{step?.index}</StepCircle>
            <StepName>{step?.title}</StepName>
          </Step>
        ))}
      </StepsContainer>
      <When expr={isIdentificationStep}>
        <StepTitle>
          Para completar seu agendamento,<br/> identifique-se abaixo:
        </StepTitle>
      </When>

      <StepContent activeStep={checkoutActiveStep}>
        {isIdentificationStep && checkoutFormState === 'simplified' && <IdentificationStep />}
        {isIdentificationStep && checkoutFormState === 'login' && <IdentificationStep />}
        {isIdentificationStep && checkoutFormState === 'register' && <RegistrationWrapper />}
        {isIdentificationStep && checkoutFormState === 'forgotPassword' && <ForgotPassword />}
        {checkoutActiveStep === 2 && <ReviewStep />}
        {checkoutActiveStep === 3 && <PaymentStep />}
        {checkoutActiveStep === 4 && <ConclusionStep />}
      </StepContent>
    </CheckoutContainer>
  )
}
