import { ReactNode } from 'react'
import * as Style from './styles'

interface SuggestionProps {
  children: ReactNode
  onClick: () => void
}

export default function Suggestion({ children, onClick }: SuggestionProps) {
  return <Style.SuggestionContainer onClick={onClick}>{children}</Style.SuggestionContainer>
}
