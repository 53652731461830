import styled from 'styled-components'

export const TemplateNameInputContainer = styled.form`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;

  width: 100%;
  max-width: 500px;

  * {
    font-weight: 300;
  }

  input,
  span {
    border: none;
    width: 100%;
    font-size: 1rem;
  }

  svg {
    width: 1.1rem;
    min-width: 1.1rem;
    height: 1.1rem;
    min-height: 1.1rem;
  }
`
