import { Modal } from 'antd'
import { noop } from 'lodash'
import { DocumentTypes } from '../types/documentTypes'
import { TipIdentify } from './TipIdentify'
import * as S from './styles'
import { TipResponsible } from './TipResponsible'
import { FileButton } from '@cannect/new-components/atoms'

interface TipModalTypes {
  type: DocumentTypes
  openTipModal: boolean
  onCloseTip: () => void
  onConfirmToUpload?: (file: File) => void
}

export function TipModal({ type, openTipModal, onCloseTip = noop, onConfirmToUpload = noop }: TipModalTypes) {
  return (
    <Modal open={openTipModal} footer={null} onCancel={onCloseTip} width="100%" style={{ maxWidth: '1200px' }}>
      <S.Wrapper>
        {type === 'VINCULO' && <TipResponsible />}
        {type === 'IDENTIDADE' && <TipIdentify />}
      </S.Wrapper>

      <S.ActionsBox>
        <FileButton
          variant="gray"
          showIcon={false}
          onUploadFile={file => onConfirmToUpload(file as File)}
          buttonLabel="Continuar"
        />
      </S.ActionsBox>
    </Modal>
  )
}
