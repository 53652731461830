import formsFeedback from '@cannect/constants/formsFeedback'
import { PHONE_VALIDATION_REGEX } from '@cannect/constants/regex'
import { z } from 'zod'

export const stepOneSchema = z.object({
  email: z.string({ required_error: formsFeedback.requiredField }).email(formsFeedback.invalidEmail)
})

export const stepTwoSchema = z.object({
  council: formsFeedback.nonEmptyStringSchema,
  uf_council: z.object(
    {
      value: formsFeedback.nonEmptyStringSchema,
      label: formsFeedback.nonEmptyStringSchema
    },
    { required_error: formsFeedback.requiredField }
  ),
  nr_council: formsFeedback.nonEmptyStringSchema
})
export const stepThreeSchema = z.object({
  first_name: formsFeedback.nonEmptyStringSchema,
  last_name: formsFeedback.nonEmptyStringSchema,
  expertise: z.string().optional(),
  whatsapp: formsFeedback.nonEmptyStringSchema.regex(PHONE_VALIDATION_REGEX, formsFeedback.invalidPhone),
  terms_privacy: z
    .boolean({ required_error: formsFeedback.requiredField })
    .refine((val) => val === true, 'É necessário aceitar os termos de privacidade.'),
  terms_comunication_accepted: z.boolean().optional()
})

export type TFormValues = z.infer<typeof stepOneSchema> & z.infer<typeof stepTwoSchema & typeof stepThreeSchema>

export enum FormSteps {
  EMAIL_STEP = 0,
  COUNCIL_VALIDATION_STEP = 1,
  USER_INFO_STEP = 2,
  CONCLUSION_STEP = 3
}
