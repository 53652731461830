/* eslint-disable react/jsx-no-useless-fragment */
import { AiOutlinePlusCircle } from 'react-icons/ai'

import { useHistory } from 'react-router'
import { temporaryTheme } from 'styles/theme'
import * as Style from './styles'

function StoryCard(stories: any) {
  const history = useHistory()

  const regex = /(<([^>]+)>)/gi
  const result = stories?.story?.attributes?.content.replace(regex, '')
  const handleSubstring = result.substr(0, 300)

  return (
    <>
      <Style.ItemContainer>
        <Style.Image
          src={`https://strapi.cannect.life/${stories?.story?.attributes?.image?.data?.attributes?.url}`}
          alt="Profile picture"
        />
        <Style.CardTitle>
          {stories?.story?.attributes?.title}{' '}
          <AiOutlinePlusCircle
            onClick={() => history.push('/comunidade', { id: stories?.story?.id })}
            color={temporaryTheme.colors.sitePrimary}
          />
        </Style.CardTitle>

        <Style.CardDescription dangerouslySetInnerHTML={{ __html: stories && `${handleSubstring} ...` }} />
      </Style.ItemContainer>
    </>
  )
}

export default StoryCard
