export const ABOUT_SCREENING = `
A triagem é uma opção oferecida a todas as pessoas que tenham <strong>interesse em iniciar um tratamento com cannabis medicinal.</strong>
<br/>
<br/>
Este espaço é destinado a <strong> responder às dúvidas dos pacientes, </strong> e principalmente, <strong>auxiliar na jornada para uma vida melhor!</strong>
<br/>
<br/>
<strong>
A Cannect é o maior ecossistema de cannabis medicinal
da
América Latina e estamos sempre encontrando soluções para melhor atende-lo.
</strong>
`

export const ABOUT_FUNCTION = `
É tudo muito simples: basta informar seu <strong>nome, telefone e e-mail,</strong> e iremos atende-lo via <strong>Whatsapp.</strong>
<br/>
<br/>
<strong>O processo é sem custos e não pede outras informações</strong>
`
