import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const Background = styled.div<{ overlayColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  overflow: inherit;
  overflow-y: inherit;

  z-index: 9996;
  background-color: ${({ overlayColor }) => overlayColor || '#5b945b50'};
  padding: 1rem;

  @media ${MOBILE_WIDTH} {
    padding: 0.25rem 16px;
  }
`

export const Container = styled.div`
  background: white;
  border-radius: 0.25rem;
  padding: 2rem;

  z-index: 9997;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);

  max-height: 80%;
  overflow: auto;
`
