import { TResponseStandard } from 'types/common'
import { THistoryListItem } from '../../../MyPatients/types'

const formatDate = (createdAt: string) => {
  const date = new Date(createdAt).toLocaleDateString('pt-BR')
  const hours = createdAt.split('T')[1].split(':')

  return `${date} ${hours[0]}:${hours[1]}`
}

export const formatPatientHistory = (res: TResponseStandard<THistoryListItem[], 'achievements'>) => {
  return {
    ...res,
    achievements: {
      ...res.achievements,
      rows: res.achievements.rows.map(row => {
        return {
          ...row,
          createdAt: formatDate(row.createdAt)
        }
      })
    }
  }
}
