import { assinaturaDigital, powerByCannect } from 'assets/img'
import * as Style from './styles'
import useMediaQuery from '../../../../../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../../../../../utils/constants'

interface Props {
  positionLogo?: string
  img: string
}

export default function SimulatePdf({ positionLogo, img }: Props) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Style.Container isMobile={isMobile}>
      {img.length > 0 && (
        <Style.Header positionLogo={positionLogo}>
          <img src={img} alt="" />
        </Style.Header>
      )}
      <Style.PatientData isMobile={isMobile}>
        <h2>RECEITUÁRIO SIMPLES</h2>
        <span>
          <b>Nome: </b>Nome do paciente
        </span>
        <span>
          <b>CPF: </b>0000000000
        </span>
      </Style.PatientData>
      <Style.RecipeContent isMobile={isMobile}>
        <h2>USO CONTÍNUO</h2>
        <Style.InfoMedical>
          <div className="product-info">
            <span className="product-name">1. Nome do produto - xxxxx xxxxxx CBD</span>
            <span className="posology">Tomar após o café da manhã, 3 vezes ao dia entre refeições</span>
          </div>
          <div className="required-bottles">
            <span className="bottles">Quantidade de frascos:</span>
            <span className="bottles-per-year">120</span>
          </div>
        </Style.InfoMedical>
        <Style.InfoMedical>
          <div className="product-info">
            <span className="product-name">1. Nome do produto - xxxxx xxxxxx CBD</span>
            <span className="posology">Tomar após o café da manhã, 3 vezes ao dia entre refeições</span>
          </div>
          <div className="required-bottles">
            <span className="bottles">Quantidade de frascos:</span>
            <span className="bottles-per-year">120</span>
          </div>
        </Style.InfoMedical>
      </Style.RecipeContent>
      <Style.Footer>
        <Style.Date>xx de novembro de 20xx</Style.Date>
        <Style.PrescriberData>
          <Style.PrescriberInfo>
            <span>
              <b>Nome do médico: </b>John Doe
            </span>
            <span>
              <b>CRM: </b>000000
            </span>
          </Style.PrescriberInfo>
          <img src={assinaturaDigital} alt="signature" />
        </Style.PrescriberData>
        <Style.Rodape>
          Não há norma que exija a aposição de carimbo na receita Médica, de acordo com o Parecer CFM n. 01/14. A ANS
          entende que a prescrição feita remotamente é equivalente àquelas apresentadas em receituário de papel, para
          fins de realização do procedimento junto à rede prestadora do plano. Atenta-se à NOTA TÉCNICA N° 1/2020
          (COMEC/GEAS/GGRAS/DIRAD-DIPRO/DIPRO), da ANS. Em caso de problemas com operadoras de saúde, o
          prestador/usuário poderá entrar em contato com a ANS, pelo telefone 0800 701 9656
        </Style.Rodape>
      </Style.Footer>
    </Style.Container>
  )
}
