import React from 'react'
import * as Styles from './styles'

export type CardProps = {
  icon?: React.ReactNode
  title?: string
  text?: string
}

export default function Card({ icon, title, text }: CardProps) {
  return (
    <Styles.Wrapper>
      <div className="icon-box"> {icon}</div>

      <Styles.TextContent>
        <h2 className="card-title"> {title} </h2>

        <p className="card-text"> {text} </p>
      </Styles.TextContent>
    </Styles.Wrapper>
  )
}
