import Loading from '@cannect/components/Loading'
import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { useAuthStep } from './useAuthStep'
import { LoginForm } from '@cannect/new-components/organisms'
import { InputField, SwitchField } from '@cannect/new-components/molecules'

export const AuthStepUI = ({
  isSendPrescriptionPending,
  initialInformationsForm,
  onSubmit,
  control,
  showLoginForm,
  setShowLoginForm,
  handleSuccessLogin
}: ReturnType<typeof useAuthStep>) => {
  return (
    <div className="flex w-full flex-col">
      <Loading loading={isSendPrescriptionPending} />
      <div className="mb-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          {showLoginForm ? 'Já tem cadastro? Faça seu login!' : 'Obrigado! Agora precisamos fazer seu cadastro.'}
        </Typography.Heading>
      </div>

      {showLoginForm ? (
        <LoginForm onSuccessLogin={handleSuccessLogin} />
      ) : (
        <FormController.Form {...initialInformationsForm}>
          <form onSubmit={initialInformationsForm.handleSubmit(onSubmit)} className="flex flex-col gap-3">
            <InputField
              variant="filledLight"
              name="full_name"
              label="Nome completo"
              placeholder="Insira o nome completo"
              control={control}
            />
            <InputField
              variant="filledLight"
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              mask="cpf"
              control={control}
            />
            <InputField
              variant="filledLight"
              name="email"
              label="E-mail"
              placeholder="Insira o e-mail"
              control={control}
            />
            <InputField
              variant="filledLight"
              name="phone"
              mask="phone"
              label="Celular"
              placeholder="(00) 00000-0000"
              control={control}
            />

            <SwitchField
              name="terms_communication_accepted"
              control={control}
              label="Eu concordo em receber comunicações."
            />

            <Button
              className="mb-1 mt-3"
              full
              type="submit"
              isLoading={initialInformationsForm.formState.isSubmitting}
              size="lg">
              Continuar
            </Button>
            <Typography.Text type="captionOne" className="text-center">
              Já possui cadastro? Entre e aproveite seus documentos e dados já validados:
            </Typography.Text>
            <Button
              full
              unstyled
              className="font-medium text-primary-700"
              size="lg"
              onClick={() => setShowLoginForm(true)}>
              Já tenho cadastro
            </Button>
          </form>
        </FormController.Form>
      )}
    </div>
  )
}
