import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'
import Pagination from 'components/Pagination'
import Select from 'new-components/Select'
import { noop } from 'lodash'
import Input from 'new-components/Input'
import { BiSliderAlt } from 'react-icons/bi'
import { Modal } from 'components/Modal'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FetchArticlesResponse } from 'types/ArticlesTypes'
import useFilters from 'hooks/useFilters'
import useMediaQuery from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import api from 'services/api'
import { MOBILE_WIDTH } from 'utils/constants'
import useQueryString from 'hooks/useQueryString'
import { Post } from 'components/CannectEducate/TabContent'
import { SORT_OPTIONS } from './cienciaConstants'
import Filters from '../NewFilters'
import { svgBasesCientificasGreen } from '../../../assets/svg'
import * as Styles from './styles'
import CardContent from '../../../components/CannectCienciaComponents/TabContent'
import TabContentTitle from '../../../components/CannectEducate/TabContentTitle'
import EmptyState from '../../../components/EmptyState'
import { Item, formatArticles, formatInitialFilters, unselectAllFilters } from './helpers'

function BasesCientificas() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { getFilters, filter: filtersList, dataPathologies, isFetching } = useFilters()
  const { queryString, filters: selectedFilters, changeFilters, clearFilters: clearQueryFilters } = useQueryString()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [postsQuantity, setPostsQuantity] = useState(0)
  const [articles, setArticle] = useState<Post[]>([])
  const [newFiltersKeys, setNewKeyFilters] = useState<string[]>([])
  const [showMobileFilters, setShowMobileFilters] = useState(false)

  const [sortedFilters, setSortedFilters] = useState<{ [key: string]: Item[] }>(
    formatInitialFilters(filtersList, dataPathologies, selectedFilters)
  )

  const handleChangeFilters = (key: string, itemId: string | number) => {
    const filtersSection = sortedFilters[key]

    const newSectionsValues = filtersSection?.map(input => {
      if (String(input.id) === String(itemId)) {
        return { ...input, checked: !input.checked }
      }
      return input
    })
    setSortedFilters(state => ({ ...state, [key]: newSectionsValues }))

    changeFilters({ [key]: newSectionsValues.filter(item => item.checked).map(item => item.id) })
  }

  const clearFilters = () => {
    setPage(1)
    setSortedFilters(unselectAllFilters(sortedFilters))
    clearQueryFilters()
    setShowMobileFilters(false)
  }

  const getArticle = async () => {
    try {
      setLoading(true)
      const formattedQueryString = !queryString.includes('order=') ? `${queryString}&order=DESC` : queryString
      const {
        data: { result }
      } = await api.get<FetchArticlesResponse>(`/articles/${page}?${formattedQueryString}&limit=12`)
      setArticle(formatArticles(result.items))
      setPostsQuantity(result.totalItems)
      setTotalPages(result.totalPages)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(() => {
    setSortedFilters(formatInitialFilters(filtersList, dataPathologies, selectedFilters))
  }, [filtersList])

  useEffect(() => {
    getArticle()
  }, [queryString, page])

  useEffect(() => {
    if (sortedFilters) {
      setNewKeyFilters(Object.keys(sortedFilters))
    }
  }, [sortedFilters])

  return (
    <Styles.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Ciência', link: '/cannect-ciencia' }
        ]}
      />
      <Styles.HeaderContainer>
        <Styles.HeaderContent>
          <Styles.HeaderTitle>
            <span>Cannect</span> Ciência
          </Styles.HeaderTitle>
          <Styles.HeaderSubtitle>
            Centenas de artigos científicos sobre o uso medicinal da cannabis.
          </Styles.HeaderSubtitle>
          {isMobile && (
            <Styles.MobileFilterButton onClick={() => setShowMobileFilters(true)}>
              <BiSliderAlt />
              Filtros
            </Styles.MobileFilterButton>
          )}
        </Styles.HeaderContent>
        {!isMobile && (
          <Styles.HeaderContent>
            <Input
              type="search"
              height="55px"
              placeholder="Pesquise por palavras-chave ou pelos filtros"
              value={selectedFilters.search || ''}
              onChange={event => changeFilters({ search: event.target.value })}
            />
          </Styles.HeaderContent>
        )}
      </Styles.HeaderContainer>
      <Styles.Container />

      <>
        <Styles.TabContainer>
          {!isMobile && <Styles.FilterLabel>Filtros</Styles.FilterLabel>}
          <TabContentTitle
            newStyle
            title="Bases Científicas"
            titleImg={svgBasesCientificasGreen}
            postsCountText={!isMobile ? `${articles?.length} de ${postsQuantity} artigos` : ''}
          />
          {!isMobile && (
            <div className="select-order-filter">
              <Select
                id="sort-filter"
                label=""
                width="172px"
                maxWidth="172px"
                height="33px"
                gap="0px"
                placeholder="Ordenar"
                value={selectedFilters.order || ''}
                onChange={newOrder => changeFilters({ order: newOrder })}
                options={SORT_OPTIONS}
                withFilterIcon
              />
            </div>
          )}
        </Styles.TabContainer>

        <Styles.CardContainer>
          {!isMobile && (
            <Filters
              handleChange={handleChangeFilters}
              filtersSections={sortedFilters}
              filtersSectionsKeys={newFiltersKeys}
              isFetching={isFetching}
              clearFilters={clearFilters}
              selectedFilters={selectedFilters}
            />
          )}

          {!loading && articles.length > 0 ? (
            <Styles.ArticlesContainer>
              <CardContent posts={articles} actionButtonText="Ler mais" />
              <Pagination
                forcePage={page - 1}
                onPageChange={({ selected }: any) => setPage(selected + 1)}
                pageCount={totalPages}
              />
            </Styles.ArticlesContainer>
          ) : (
            <Loading loading={loading} />
          )}
          {!loading && articles.length === 0 && <EmptyState title="Não encontramos artigos com o filtro selecionado" />}
        </Styles.CardContainer>
      </>

      <Modal
        isOpened={showMobileFilters}
        onRequestClose={() => noop}
        className="modal-cannect-ciencia"
        overlayColor="rgba(0, 0, 0, 0.5)"
      >
        <Styles.MobileFilterContainer>
          <Styles.MobileFilterHeader>
            <Styles.FilterLabel style={{ marginRight: '0px' }}>Filtros</Styles.FilterLabel>
            <AiFillCloseCircle onClick={() => setShowMobileFilters(false)} />
          </Styles.MobileFilterHeader>

          <Filters
            handleChange={handleChangeFilters}
            filtersSections={sortedFilters}
            filtersSectionsKeys={newFiltersKeys}
            isFetching={isFetching}
            clearFilters={clearFilters}
            selectedFilters={selectedFilters}
          />
        </Styles.MobileFilterContainer>
      </Modal>
    </Styles.Container>
  )
}

export default BasesCientificas
