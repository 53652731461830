import {
  createSlideDownAnimation,
  createSlideUpAnimation
} from 'pages/Account/components/template/AccountSidePanel/components/CollapsableMenu/styles'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface CollapsableMenuContainerProps {
  animationHeight: number
}
export const CollapsableMenuContainer = styled.div<CollapsableMenuContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 100%;
  margin-bottom: 2rem;
  color: #3d3d3d;

  svg {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
  }

  .slide-down {
    ${({ animationHeight }) => createSlideDownAnimation(animationHeight)}
  }

  .slide-up {
    ${({ animationHeight }) => createSlideUpAnimation(animationHeight)}
  }
`

export const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: transparent;
  padding-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #e0e7ec;

  color: #3d3d3d;

  &:disabled {
    opacity: 0.5;
  }
`

export const CollapsableButtonTitle = styled.h3`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  color: #3d3d3d;

  span {
    font-weight: 400;
    font-size: 2rem;
  }
`

export const CommingSoon = styled.span`
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #3d3d3d;
`

export const SubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 0;
  overflow: hidden;
  visibility: hidden;
`

interface SubItemProps {
  enabled: boolean
}
export const SubItem = styled(Link)<SubItemProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  color: #3d3d3d;
  text-decoration: none;
  font-size: 1.5rem;

  margin: 0.5rem 0;
  opacity: ${({ enabled }) => (enabled ? '1' : '0.4')};
`
