/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from '@cannect/new-components/atoms'
import { FiMonitor, FiShield } from 'react-icons/fi'
import { MdOutlineWhatsapp } from 'react-icons/md'
import { useWebinarSubscription } from './useWebinarSubscription'
import { formatDate } from '@cannect/utils/date'
import { ImArrowLeft2, ImSpinner2 } from 'react-icons/im'
import { Webinar } from '@cannect/new-components/organisms'
import { FormProvider } from 'react-hook-form'
import { AnimatePresence } from 'framer-motion'
import { FormSteps } from './types'

export const WebinarSubscriptionUI = ({
  webinarSubscriptionForm,
  currentStep,
  handlePreviousStep,
  webinarItem,
  isPracticeWebinar,
  onSubmit,
  isWerbinarDetailsLoading,
  isLastStep,
  isFirstStep,
  isAlreadySubscribedUser
}: ReturnType<typeof useWebinarSubscription>) => {
  if (isWerbinarDetailsLoading)
    return (
      <div className="flex h-[400px] flex-col items-center justify-center gap-2">
        <Typography.Heading className="font-serif tracking-tight" type="headingThree" level={3}>
          Carregando dados da Webinar...
        </Typography.Heading>
        <ImSpinner2 className="animate-spin text-base text-primary-700" size={60} />
      </div>
    )
  if (!webinarItem)
    return (
      <div className="flex h-[400px] flex-col items-center justify-center">
        <Typography.Heading className="font-serif tracking-tight" type="headingThree" level={3}>
          Webinar não encontrado.
        </Typography.Heading>
      </div>
    )

  return (
    <div className="relative flex flex-col">
      <div className="flex flex-col gap-14 px-12 py-20 lg:flex-row lg:justify-between">
        <div className="flex flex-col gap-12">
          <Typography.Heading className="font-serif tracking-tight" type="headingThree" level={3}>
            {webinarItem.title}
          </Typography.Heading>

          <div className="flex items-center gap-2">
            <FiMonitor size={20} color="#21976e" />
            <Typography.Text weight="bold">Online e gratuito</Typography.Text>
          </div>

          <div className="left-gradient-divider relative flex flex-col gap-1 rounded-xl pl-7">
            <Typography.Text weight="bold">{webinarItem.day}</Typography.Text>
            <Typography.Text>{formatDate(webinarItem.date, "dd 'de' MMMM")}</Typography.Text>
            <Typography.Text>{webinarItem.time}</Typography.Text>
          </div>
        </div>
        <FormProvider {...webinarSubscriptionForm}>
          <form
            onSubmit={webinarSubscriptionForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4 rounded-3xl bg-gray-50 p-8 shadow-lg lg:w-[500px]">
            {!isLastStep && (
              <>
                <div className="flex items-center gap-4">
                  {!isFirstStep && <ImArrowLeft2 onClick={handlePreviousStep} size={20} />}
                  <Typography.Text weight="bold" className="font-serif text-lg">
                    {isFirstStep && 'Participar do evento'}
                    {!isFirstStep && 'Inscrição'}
                  </Typography.Text>
                </div>

                <Typography.Text className="text-sm text-gray-500">
                  {isFirstStep && 'Digite seu e-mail para prosseguir'}
                  {currentStep === FormSteps.COUNCIL_VALIDATION_STEP && (
                    <>
                      {isPracticeWebinar
                        ? 'Este evento é exclusivo para médicos(as). Preencha seu CRM abaixo:'
                        : 'Preencha os dados do conselho abaixo:'}
                    </>
                  )}
                  {currentStep === FormSteps.USER_INFO_STEP && 'Complete seus dados e aceite os termos.'}
                </Typography.Text>
              </>
            )}
            <AnimatePresence>
              {isFirstStep && <Webinar.StepOne />}
              {currentStep === FormSteps.COUNCIL_VALIDATION_STEP && (
                <Webinar.StepTwo allowOnlyDoctors={isPracticeWebinar} />
              )}
              {currentStep === FormSteps.USER_INFO_STEP && <Webinar.StepThree />}
              {isLastStep && (
                <Webinar.StepFour webinarDetails={webinarItem} isAlreadySubscribed={isAlreadySubscribedUser} />
              )}
            </AnimatePresence>
            <hr className="my-2 border-gray-50 opacity-50" />
            <div>
              <div className="mb-1 flex items-center gap-2">
                <FiShield size={18} color="#21976e" />
                <Typography.Text>Suas informações estão seguras e livres de spam.</Typography.Text>
              </div>
              <div className="flex items-center gap-2">
                <MdOutlineWhatsapp size={18} color="#21976e" />
                <Typography.Text>
                  Precisando de ajuda?{' '}
                  <a href="https://wa.link/lj7uc3" target="_blank" rel="noreferrer">
                    Clique aqui.
                  </a>
                </Typography.Text>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <Webinar.TimelineSection webinarDetails={webinarItem} />
    </div>
  )
}
