import { Button, Form, Typography } from '@cannect/new-components/atoms'
import { TFormValues } from '@cannect/pages/WebinarSubscription/types'

import { Controller, useFormContext } from 'react-hook-form'
import { motion } from 'framer-motion'
import { InputField } from '@cannect/new-components/molecules'

export const StepThree = () => {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext<TFormValues>()

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center gap-4">
      <InputField name="first_name" placeholder="Nome" control={control} />
      <InputField name="last_name" placeholder="Sobrenome" control={control} />

      <InputField name="expertise" control={control} placeholder="Área de atuação/Especialidade (Opcional)" />

      <div className="flex w-full flex-col items-start">
        <InputField name="whatsapp" placeholder="Whatsapp" control={control} mask="phone" />

        <Typography.Text type="captionOne">
          Utilizamos o whatsapp apenas para enviar o link dos eventos. Você pode deixar de receber nossas comunicações
          quando desejar.
        </Typography.Text>
      </div>
      <hr className="my-2 border-gray-50 opacity-50" />
      <Controller
        control={control}
        name="terms_comunication_accepted"
        render={({ field, fieldState }) => (
          <Form.Checkbox
            size="sm"
            ref={field.ref}
            checked={field.value}
            onCheckedChange={field.onChange}
            label="Eu concordo em receber comunicações da comunidade e do evento."
            isInvalid={fieldState.invalid}
            message={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="terms_privacy"
        render={({ field, fieldState }) => (
          <Form.Checkbox
            size="sm"
            ref={field.ref}
            checked={field.value}
            onCheckedChange={field.onChange}
            label="Eu aceito e concordo com a política de privacidade da plataforma."
            isInvalid={fieldState.invalid}
            message={fieldState.error?.message}
          />
        )}
      />
      <Button type="submit" full isLoading={isSubmitting}>
        Finalizar inscrição
      </Button>
    </motion.div>
  )
}
