import React from 'react'
import Text from 'components/Text'

import * as Style from './styles'

interface FileProps {
  icon?: React.ReactNode
  fileName: string
  recipeCode?: any
  removeFile: (file: string, recipeCode: any) => void
}

export default function File({ icon, fileName, removeFile, recipeCode }: FileProps) {
  const handleRemove = () => {
    removeFile(fileName, recipeCode)
  }

  return (
    <Style.Container>
      <span className="wrapper-icon">{icon}</span>
      <Text type="lg" bold>
        {fileName}
      </Text>
      &nbsp;&nbsp;
      <Style.Button onClick={handleRemove}>excluir</Style.Button>
    </Style.Container>
  )
}
