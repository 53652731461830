import styled from 'styled-components'
import { theme } from 'design-cannect'

interface ContainerProps {
  isColumn?: boolean
  requirePadding?: boolean
  isMobile?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  align-items: center;

  gap: 25px;

  width: 100%;

  .submit-button {
    display: flex;
    justify-content: flex-end;

    width: 100%;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column !important;
`

export const DeliveryAddress = styled.div<ContainerProps>`
  background-color: #ecece2;
  border-radius: 16px;

  margin: 32px 0 32px 0;
  padding: 32px;

  width: 80%;

  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  justify-content: center;
  align-items: center;

  gap: 20px;

  .full {
    margin-bottom: 16px;
    min-width: 200px;

    flex-direction: row !important;

    p {
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      min-width: 200px;
    }
  }
  .input-flex {
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    min-width: 200px;

    flex-direction: row !important;

    input {
      width: 100%;
      min-width: 200px;
    }
  }

  .row {
    flex-direction: row !important;
  }
`

export const InputWrapper = styled.div<ContainerProps>`
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')} !important;

  gap: 30px;

  flex-grow: 1;

  .teste {
    margin-top: 90px;
  }
`

export const Button = styled.button`
  display: flex;

  background-color: #115850;

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #0b3b35;

  margin-top: 20px;
  padding: 1rem;

  cursor: pointer;

  transition: transform 0.2s;

  a:link {
    text-decoration: none;
  }

  h3 {
    color: #ecece2;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
export const SubmitButton = styled.button`
  display: flex;

  background-color: ${theme.colors.tertiary};
  color: ${theme.colors.white};

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: ${theme.colors.tertiary};

  margin-top: 50px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
export const SubmitButtonWrapper = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;

  width: 100%;
`
export const CheckboxContainer = styled.div<any>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  align-items: center;

  gap: 20px;
  margin-bottom: 75px;
`

interface CheckboxProps {
  checked?: boolean
}

export const Checkbox = styled.div<CheckboxProps>`
  height: 30px;
  width: 30px;

  background: ${({ checked }) => (checked ? '#C5581B' : 'white')};
  box-shadow: 0 0 0 2.5px ${({ checked }) => (checked ? '#C5581B' : '#115850')};

  border: 6px solid white;
  border-radius: 50%;

  cursor: pointer;
`
export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 15px;
`
