import Heading from 'new-components/Heading'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'

import LoadingContainer from 'components/LoadingContainer'
import { useCartCheckout } from 'hooks/useCartCheckout'
import { useTokenApi } from 'hooks/useTokenApi'
import ProductCard from 'new-components/MarketPlace/ProductCard'
import { useParams } from 'react-router'
import * as Styles from './styles'

export interface RelatedProductProps {
  filters?: string
  limit?: number
  titleSection?: string
}
/*  filter exemple  class_id=${classId}&shape_id=${shapeId} */

export default function RelatedProduct({ filters, titleSection, limit }: RelatedProductProps) {
  const [loading, setLoading] = useState(false)
  const [productItems, setProductItems] = useState<any[]>([])
  const { tokenApi } = useTokenApi()
  const { addToCart } = useCartCheckout()
  const { id } = useParams() as any

  const urlAuth = tokenApi
    ? `products/1?${filters}&limit=${limit || 6}`
    : `products_public/1?${filters}&limit=${limit || 6}`

  const getRelatedProduct = async () => {
    setLoading(true)
    try {
      const { data } = await api.get(urlAuth)
      if (data?.success) {
        const validateProduct = data?.products?.rows?.filter((item: any) => `${id}` !== `${item.id}`)

        setProductItems(validateProduct)
      }
    } catch (e) {
      toast.error('Erro ao buscar produtos relacionados')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (filters) {
      getRelatedProduct()
    }
  }, [filters])

  const handleFavorite = async (id: number) => {
    setProductItems(
      productItems.map((product: any) => {
        if (product.id === id) {
          return { ...product, isFavorite: !product.isFavorite }
        }
        return product
      })
    )
  }

  return (
    <Styles.Wrapper>
      <Heading elementType="h2" defaultColor="darkGrey">
        {titleSection || 'Produtos semelhantes:'}
      </Heading>

      <Styles.GridProductList justifySpace={productItems.length <= 5}>
        {loading ? (
          <LoadingContainer loading />
        ) : (
          productItems.length > 0 &&
          productItems.map(product => (
            <ProductCard
              name={product?.translated_name}
              key={product?.id}
              real_price={product?.real_price}
              image={product?.media[0]?.path}
              isFavorite={product?.isFavorite}
              handleFavorite={() => handleFavorite(product?.id)}
              id={product?.id}
              supplier_id={product?.supplier_id}
              importProduct={() => addToCart(product?.id, product)}
              promotional={product?.promotion}
              stock={product?.stock}
              isRelatedProduct
            />
          ))
        )}
      </Styles.GridProductList>
    </Styles.Wrapper>
  )
}
