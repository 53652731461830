import styled from 'styled-components'

export const SuggestionContainer = styled.button`
  display: flex;

  background: transparent;
  outline: none;
  border: none;

  padding: 0.5rem;
  border-radius: 0.25rem;

  transition: background 0.5s;

  &:hover {
    background: #ecece2;
  }
`
