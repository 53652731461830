import React from 'react'
import { Container } from './styles'
import AccountHeader from '../AccountComponent/AccountHeader'
import Text from '../Text'
import CardDocument from './CardDocument'

function MissingDocumentsComponent() {
  return (
    <Container>
      <AccountHeader title="Documentos faltantes" />
      <div className="header">
        <Text type="md" color="tertiary">
          Pedido realizado em 10 de dezembro de 2021
        </Text>
        <Text type="md" color="tertiary">
          |
        </Text>
        <Text type="md" color="tertiary">
          Número do pedido 929-293-891
        </Text>
      </div>
      <CardDocument title="Documento de Identidade (RG ou CNH)" />
      <CardDocument title="Comprovante de residência" />
    </Container>
  )
}

export default MissingDocumentsComponent
