export const GENDER_IDENTITY_LIST = [
  {
    label: 'Mulher cisgênera',
    value: 'Mulher cisgênera'
  },
  {
    label: 'Mulher trans',
    value: 'Mulher trans'
  },
  {
    label: 'Homem cisgênero',
    value: 'Homem cisgênero'
  },
  {
    label: 'Homem trans',
    value: 'Homem trans'
  },
  {
    label: 'Pessoa não-binária',
    value: 'Pessoa não-binária'
  },
  {
    label: 'Prefiro não responder',
    value: 'Prefiro não responder'
  },
  {
    label: 'Nenhuma das opções listadas',
    value: 'Nenhuma das opções listadas'
  }
]

export const NATIONALITY_LIST = [
  {
    label: 'Brasileiro(a)',
    value: 'brasileira'
  }
  // Leave this commented until we have a way to deal with foreigners
  // {
  //   label: 'Estrangeiro(a)',
  //   value: 'estrangeira'
  // }
]

export const LIST_AS_PRESCRIBER_POSSIBILITIES = [
  {
    label: 'Sim, tenho interesse',
    value: 'true'
  },
  {
    label: 'Não',
    value: 'false'
  }
]
