import styled from 'styled-components'
import { theme } from 'design-cannect'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'
import { temporaryTheme } from 'styles/theme'

export const Container = styled.div`
  width: 100%;

  @media (${MOBILE_WIDTH}) {
    padding: 0 12px;
  }
`

export const CardList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 19px;

  margin: 22px 0;
  @media (${TABLET_WIDTH}) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
  }
`

export const ButtonClearFavorite = styled.button`
  padding: 7px;
  border-radius: 5px;
  background: transparent;
  min-width: 125px;
  outline: none;
  border: 1px solid ${temporaryTheme.colors.darkGrey};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${temporaryTheme.spacing.space2};
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${temporaryTheme.colors.darkGrey};
  min-height: 45px;
  svg {
    width: 14px;
    height: 14px;
  }
`
