import styled from 'styled-components'
import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'
import { temporaryTheme } from 'styles/theme'

export const Container = styled.div<any>`
  padding-top: 4rem;
  .header {
    display: flex;
    width: 99%;
    justify-content: center;
    align-items: flex-start;
    margin-top: -16px;
    margin-bottom: 8px;
    button {
      height: auto;
      padding: 8px 16px;
    }
    a {
      text-decoration: underline;
      color: #4579ff;
    }
    p {
      width: 70%;
    }
    .product-header__search-box {
      margin-right: 0px;
      padding-top: 0px;
      width: 79%;
      .wrapper-icon {
        top: 10px;
      }
    }
    .product-header__button-box {
      button {
        background: #424242;
        border: none;
      }
      .filter-button {
        display: none;
      }
    }
    input {
      min-height: 35px;
    }
  }

  .FilterLabel {
    font-size: 24px;
    font-weight: 500;
    color: #424242;
    margin-bottom: 16px;
  }

  .FilterBadge {
    margin-bottom: 70px;
    margin-top: 40px;
    width: 100%;
    overflow-x: auto;
    padding: 0 10px;
    .filter {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .badge {
      a {
        > div {
          background: none;
        }
      }
      p {
        font-size: 14px;
      }
    }
  }

  .wrapper {
    display: flex;
    width: 100%;
    .products {
      margin-left: ${props => (props.isMobile ? '0px' : '48px')};
      max-width: 900px;
      width: calc(100% - 270px);
    }
    .filter {
      width: 250px;
      margin-right: 24px;
      .clear {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        button {
          height: 30px;
        }
      }
    }
    .page {
      margin-top: ${theme.spacing.space4};
      height: 100px;
    }
    .contentBody {
      .emptyState {
        display: flex;
      }
    }
  }

  .modal-produtos {
    padding: 0;
  }

  .filters-result {
    font-family: 'Spartan', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #21976e;
    display: flex;
    align-items: center;
    gap: 2px;

    svg {
      margin-top: -4px;
    }

    span {
      font-family: 'Nunito', sans-serif;
      font-weight: 500;
      color: #3d3d3d;
    }
  }

  .actions-responsive {
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 12px;
    display: none;
    button {
      min-width: 300px;
      font-family: 'Spartan', sans-serif;
    }
  }

  @media only screen and (max-width: 767px) {
    .header {
      flex-direction: column;
      .product-header__search-box {
        width: 100%;
        .count-result {
          font-family: 'Nunito', sans-serif;
          font-weight: 400;
          width: 100%;
          text-align: right;
        }
      }
      .product-header__button-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .filter-button {
          margin-top: 16px;
          display: block;
        }
        button {
          margin: 0 5px;
        }
      }
    }

    .wrapper {
      width: 100%;
      padding: 0 5px;
      .filter {
        display: none;
      }
      .products {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .title {
        padding: 0;
      }
    }

    .FilterBadge {
      .filter {
        width: 1024px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .actions-responsive {
      display: flex;
      flex-direction: column-reverse;
      padding: 0 22px;
      button {
        width: 100%;
      }
    }
  }

  ${media.tablet`
    .filter {
      display: none;
    }
    .header {
      width: 100%;

      .product-header__search-box {
        width: 100%;
        padding: 0 12px;
        min-width: 633.7px;
        max-width: 900px;



      }
    }
    .wrapper {
      justify-content: center;
      .products {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .modal-produtos {
      max-width: 600px;
      width: 100%;
    }

    .actions-responsive {
      display: flex;
    }
  `}

  @media screen and (min-width: 770px) and (max-width: 900px) {
    .product-header__search-box {
      width: 75% !important;
      max-width: 610px !important;
    }
  }

  ${media.mobileWithTablet`
    padding-top: 24px;
  `}
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 15px;
  width: 100%;

  button {
    height: 40px !important;
    font-size: 14px;
  }
`
export const CancelButton = styled.button`
  color: #4380e6;
  background-color: transparent;
  border-radius: 8px;
  border: none;
  margin-left: auto;
  width: 240px;
  text-decoration: underline;
  font-family: 'Spartan', sans-serif;
  font-weight: 400;
  text-align: left;
  margin-bottom: 25px;
`
export const SaveButton = styled.button`
  background: #464F31 !important;
  width: 240px;
  height: 40px;
  border-radius: 8px;
  border: none;
  color: white;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`

export const ButtonVizualization = styled.button`
  background: transparent !important;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  border: 2.96007px solid #3d3d3d !important;
  color: #3d3d3d;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
`

export const ContentProduct = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }
`

export const ProductList = styled.div`
  ${media.tablet`
     width: 100%;
  `}
`

export const BadgeFilterBoxMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const FiltersBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.outHeader {
    width: 97%;
    margin-bottom: 8px;

    &.column {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .count-result {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    width: 100%;
    text-align: right;
  }
`

export const FilterResult = styled.p``
export const FilterSort = styled.div`
  min-width: 200px;
  display: flex;
  align-items: center;

  .ant-space-item {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    color: #21976e;
    .anticon {
      display: none;
    }

    svg {
      color: ${temporaryTheme.colors.sitePrimary};
    }
  }

  span {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    color: #3d3d3d;
  }
`

export const BadgeFilterBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const DropdownRender = styled.div``
