import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;

  max-width: 75%;

  margin-bottom: 1.5rem;

  @media ${MOBILE_WIDTH} {
    max-width: 60%;
    overflow-x: auto;
  }
`
