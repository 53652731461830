/* eslint-disable prefer-regex-literals */
import { z } from 'zod'

export const personalDataSchema = z
  .object({
    registrationType: z.number(),
    typeOfBoard: z.string(),
    specialties: z.string().array(),
    birthdate: z.string(),
    name: z.string(),
    nationality: z.string().min(1),
    cpf: z.string(),
    rg: z.string(),
    rne: z.string(),
    genderIdentity: z.string().min(1),
    cellphone: z.string().min(15),
    hasPrescribedBefore: z.string()
  })
  .required()
  .refine(
    (data) =>
      ((data.nationality === 'brasileira' || data.nationality === '') && data.rg.trim().length > 5) ||
      (data.nationality === 'estrangeira' && data.rne.trim().length > 5),
    {
      message: 'Field is required',
      path: ['rg', 'rne']
    }
  )
  .refine(
    (data) => {
      if (data.registrationType === 1) return true
      if (data.typeOfBoard !== 'CRO') return true
      return data.specialties.length > 0
    },
    {
      message: 'Field is required',
      path: ['specialties']
    }
  )
  .refine(
    (data) => {
      if (
        data.typeOfBoard === 'CRM' ||
        data.typeOfBoard === 'CRO' ||
        data.typeOfBoard === 'CRMV' ||
        data.typeOfBoard === 'RMS'
      ) {
        return data.hasPrescribedBefore.length > 0
      }
      return true
    },
    {
      message: 'Field is required',
      path: ['hasPrescribedBefore']
    }
  )
