import { theme } from 'design-cannect'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const VidaasContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;
  width: 100%;

  img {
    width: 250;
    min-width: 215px;
    height: 120px;
    min-height: 120px;

    margin-bottom: 1rem;
  }

  h1 {
    color: ${theme.colors.tertiary};
  }

  p {
    font-weight: 600;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`
