import PageTitle from 'components/PageTitle'
import useSignedUser from 'hooks/useSignedUser'
import Button from 'new-components/Button'
import Input from 'new-components/Input'
import { getFieldError } from 'pages/Registration/components/Forms/helpers'
import { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'
import { ZodIssue } from 'zod'
import { accessDataForm } from './schemas/accessDataForm'
import * as Styles from './styles'

function ChangePassword() {
  const { signedUser } = useSignedUser()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [errors, setErrors] = useState<ZodIssue[]>()

  const onFormSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const requiredFieldsValidation = await accessDataForm.safeParseAsync({
      newPassword
    })

    if (!requiredFieldsValidation.success) {
      setErrors(requiredFieldsValidation.error.errors)
      return
    }
    setErrors(undefined)

    try {
      await api.post('/update_password', {
        email: signedUser?.email,
        password: currentPassword,
        newPassword
      })
      toast.success('Senha alterada com sucesso!')
      setCurrentPassword('')
      setNewPassword('')
    } catch (err: any) {
      if (err.response?.data?.error === 'Senha Inválida') {
        toast.error('A senha atual está incorreta.')
        return
      }
      toast.error('Houve um erro ao tentar atualizar a senha. Tente novamente.')
    }
  }

  const changeNewPassword = (newValue: string) => {
    setNewPassword(newValue)
    setErrors(undefined)
  }

  return (
    <Styles.ChangePasswordContainer onSubmit={onFormSubmit}>
      <PageTitle>Dados de acesso</PageTitle>

      <Styles.ChangePasswordInputs>
        <Styles.ChangePasswordInputWrapper>
          <Input
            value={currentPassword}
            onChange={event => setCurrentPassword(event.target.value)}
            type="password"
            label="Redefinição de senha"
            placeholder="Digite sua senha atual"
          />
          <Input
            value={newPassword}
            onChange={event => changeNewPassword(event.target.value)}
            type="password"
            placeholder="Digite sua nova senha"
            errorMessage={getFieldError('newPassword', errors)}
          />
          <a href="/esqueci-minha-senha" target="_blank">
            Esqueci minha senha
          </a>
          <Button background="transparent" style={{ width: '100%', marginTop: '1rem', color: '#464F31', border: '2px solid #464F31' }} type="submit">
            Alterar a senha
          </Button>
        </Styles.ChangePasswordInputWrapper>
      </Styles.ChangePasswordInputs>
    </Styles.ChangePasswordContainer>
  )
}

export default ChangePassword
