import { Checkbox } from 'antd'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & * {
    font-family: 'Poppins', sans-serif;
    weight: 400;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;

  h1 {
    font-size: 30px;
    font-weight: 700;
    strong {
      color: ${temporaryTheme.colors.sitePrimary};
    }
  }

  p {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  @media ${MOBILE_WIDTH} {
    padding: 4rem 0.75rem 0.75rem 0.75rem;
  }
`

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .login-button {
    margin-bottom: 42px;
  }

  .register-button,
  .login-button {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    > button {
      width: 100%;
      margin-bottom: 5rem;
    }
  }
`

export const ForgotPasswordContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${temporaryTheme.colors.sitePrimary};
    border-color: ${temporaryTheme.colors.sitePrimary};

    &:hover {
      border-color: ${temporaryTheme.colors.sitePrimary};
    }
  }
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-color: #748351;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5D6941;
    border-color: #5D6941;
  }

  .ant-checkbox-checked::after {
    border-color: #fff;
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5D6941 !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked:focus .ant-checkbox-inner {
    background-color: #464F31 !important;
    border-color: #464F31 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #464F31 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #464F31 !important;
    border-color: #464F31 !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5D6941 !important;
  }
`;
