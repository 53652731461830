import * as Style from './styles'

interface OptionProps {
  label: string
  checked: boolean
  id: string
  onChange: (id: string) => void
}

export default function Option({ label, id, checked, onChange }: OptionProps) {
  return (
    <Style.OptionContainer>
      <label htmlFor={id}>{label}</label>
      <Style.VisualInput htmlFor={id} checked={checked} />
      <input id={id} type="radio" checked={checked} onChange={() => onChange(id)} />
    </Style.OptionContainer>
  )
}
