import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'
import styled, { css } from 'styled-components'

type FilterButtonContainerProps = {
  active: boolean
}
export const FilterButtonContainer = styled.button<FilterButtonContainerProps>`
  ${({ active }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 12px;
    background: ${active ? `${theme.colors.secondary}` : 'transparent'};
    color: ${active ? 'white' : '#3D3D3D'};

    border-radius: 6px;
    outline: none;
    border: none;

    font-weight: 600;
    font-size: 12px;
    font-family: 'Spartan', sans-serif;

    ${media.mobileWithTablet`
    font-size: 10px;
    padding: 3px 6px;
    `}
  `}
`
