import React from 'react'

export default function IconeCasa({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M27 27V14.4375C26.9978 14.2991 26.9679 14.1626 26.9121 14.0359C26.8562 13.9092 26.7756 13.795 26.675 13.7L16.675 4.6125C16.4907 4.44385 16.2499 4.35032 16 4.35032C15.7502 4.35032 15.5094 4.44385 15.325 4.6125L5.32501 13.7C5.22203 13.7932 5.13985 13.907 5.08385 14.0341C5.02785 14.1612 4.99928 14.2986 5.00001 14.4375V27"
        stroke="#3D3D3D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 27H30" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 27V20C19 19.7348 18.8946 19.4804 18.7071 19.2929C18.5196 19.1054 18.2652 19 18 19H14C13.7348 19 13.4804 19.1054 13.2929 19.2929C13.1054 19.4804 13 19.7348 13 20V27"
        stroke="#3D3D3D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
