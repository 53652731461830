interface Item {
  name: string
  // Add more properties as needed
}

/**
 * Sorts an array of items by name using the localeCompare method.
 * @param itemsToSort The array of items to sort.
 * @returns A new array containing the sorted items, or the original array if it's not an array.
 */

export const sortItemsByName = (itemsToSort: Item[] | unknown): Item[] => {
  if (!Array.isArray(itemsToSort)) {
    return itemsToSort as Item[]
  }

  const sortedItems = [...itemsToSort].sort((a, b) => a.name.localeCompare(b.name))
  return sortedItems
}
