import { Collapse } from 'antd'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  width: 100%;
  grid-area: conditions;
`

export const StyledCollapse = styled(Collapse)`
  border: none;
  border-radius: 8px;

  .ant-collapse-item {
    border: none;
  }

  .danger-text {
    font-size: 15px;
  }

  .ant-collapse-header {
    background-color: #f8f7fc;
    border-radius: 8px !important;
    padding: 24px 36px !important;

    font-size: 15px !important;
    font-weight: 700;
  }

  .ant-collapse-expand-icon {
    svg {
      font-size: 16px !important;
    }
  }

  .ant-collapse-content-box {
    background-color: #f8f7fc;
    border-radius: 8px;
    padding: 32px 42px;
  }

  @media ${MOBILE_WIDTH} {
    .ant-collapse-header,
    .ant-collapse-content-box {
      padding: 25px;
    }
  }
`
