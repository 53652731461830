/* eslint-disable prefer-regex-literals */
import { z } from 'zod'

export const accessDataForm = z
  .object({
    newPassword: z
      .string()
      .regex(new RegExp('.*[A-Z].*'), 'One uppercase character')
      .regex(new RegExp('.*[a-z].*'), 'One lowercase character')
      .regex(new RegExp('.*\\d.*'), 'One number')
      .regex(new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'), 'One special character')
      .min(8, 'Too small')
      .max(16, 'Too big')
  })
  .required()
