import { useState } from 'react'
import Modal from 'new-components/Modal'
import { Checkbox, Typography } from 'antd'
import * as Styles from './styles'
import Button from 'new-components/Button'
import api from 'services/api'
import useSignedUser from 'hooks/useSignedUser'
import { Document, Page, pdfjs } from 'react-pdf'
import prescriberTermsPdf from 'assets/files/termos-agendamento-prescritor-paciente-joined.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

function ModalPrescriberTerms() {
  const { shouldOpenPrescriberTerms, setShouldOpenPrescriberTerms } = useSignedUser()
  const [agreeSchedulingTerms, setAgreeSchedulingTerms] = useState<boolean>(false)
  const [prescriberNumPages, setPrescriberNumPages] = useState<number>(0)

  const onPrescriberDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPrescriberNumPages(numPages)
  }

  const handleSubmit = () => {
    setShouldOpenPrescriberTerms(false)
    api.post('/my-account/information/accept-terms-of-use')
  }

  return (
    <Modal maxHeight={undefined} isOpen={shouldOpenPrescriberTerms} setOpenModal={setShouldOpenPrescriberTerms}>
      <Styles.PrescriberTermsContainer>
        <Typography.Title level={2}>Atualização nos Termos de Uso da Plataforma 📢</Typography.Title>

        <Typography.Title level={5}>Leia abaixo nossas políticas atualizadas:</Typography.Title>
        <Styles.TermsWrapper>
          <Document file={prescriberTermsPdf} onLoadSuccess={onPrescriberDocumentLoadSuccess}>
            {Array.from(new Array(prescriberNumPages), (el, index) => (
              <Styles.PageWrapper>
                <Page
                  noData="Erro ao carregar o arquivo."
                  key={`prescriberPdf_page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={1.5}
                  renderMode="canvas"
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Styles.PageWrapper>
            ))}
          </Document>
        </Styles.TermsWrapper>
        <Checkbox
          style={{ gap: '8px' }}
          onChange={() => setAgreeSchedulingTerms(!agreeSchedulingTerms)}
          checked={agreeSchedulingTerms}
        >
          <Typography.Text>
            Li e concordo com{' '}
            <a href="/politica-de-agendamento" target="_blank" rel="noopener noreferrer">
              as políticas de uso da plataforma de agendamento de consulta.
            </a>
          </Typography.Text>
        </Checkbox>
        <Button background="blue" disabled={!agreeSchedulingTerms} onClick={handleSubmit}>
          Continuar para o site
        </Button>
      </Styles.PrescriberTermsContainer>
    </Modal>
  )
}

export default ModalPrescriberTerms
