/* eslint-disable react/jsx-no-useless-fragment */
import { useSheetProductsList } from './useSheetProductsList'
import { Badge, Button, Form, ScrollArea, Separator, Sheet, Skeleton, Typography } from '@cannect/new-components/atoms'
import { Product } from '@cannect/new-components/molecules'
import { motion, AnimatePresence } from 'framer-motion'
import animation from '../../../../constants/animation'
import { ProductDetailsDialog, Skeletons } from '@cannect/new-components/organisms'

import React from 'react'
import { LuLoader2 } from 'react-icons/lu'

export const SheetProductsListUI = ({
  productsList,
  isLoadingAction,
  isFetchingNextPage,
  onAddToCart,
  currentStepConfig,
  children,
  lastItemRef,
  goToStep,
  queryParams,
  handleFilterChange,
  isInitialProductsLoading
}: ReturnType<typeof useSheetProductsList>) => {
  return (
    <Sheet.Root modal={false}>
      <Sheet.Trigger asChild>
        <Button full variant="outline_light" className="gap-1 font-normal">
          {children || (
            <>
              <span className="text-lg font-bold">+</span> Adicionar mais produtos
            </>
          )}
        </Button>
      </Sheet.Trigger>
      <Sheet.Content onInteractOutside={(e) => e.preventDefault()} className="pb-[120px]">
        <Sheet.Header className="mt-8">
          <Sheet.Title>{currentStepConfig.title}</Sheet.Title>
          <Sheet.Description>{currentStepConfig.description}</Sheet.Description>
        </Sheet.Header>
        <Separator className="my-4" />
        <ScrollArea className="h-[calc(100%-120px)] pr-2">
          <AnimatePresence mode="wait">
            <motion.div key={currentStepConfig.filterKey || 'filteredProduducts'} {...animation.fadeInUp}>
              {currentStepConfig?.filters && currentStepConfig.filterKey ? (
                <div className="flex flex-col">
                  {currentStepConfig.filters?.length > 0 ? (
                    currentStepConfig.filters.map((filter) => (
                      <React.Fragment key={filter?.value}>
                        <Form.Checkbox
                          inverted
                          justify="between"
                          label={filter?.label}
                          checked={queryParams
                            ?.getAll(currentStepConfig.filterKey!)
                            ?.includes(filter?.value?.toString())}
                          onCheckedChange={() =>
                            handleFilterChange(currentStepConfig.filterKey!, filter?.value?.toString())
                          }
                        />
                        <Separator className="my-4" />
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      {Array.from({ length: 8 }).map((_, index) => (
                        <>
                          <Skeleton key={index} className="mt-2 h-12 w-full" />
                        </>
                      ))}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {isInitialProductsLoading ? (
                    <div className="space-y-4">
                      <Skeletons.SkeletonProductItem />
                      <Skeletons.SkeletonProductItem />
                    </div>
                  ) : (
                    <>
                      {productsList?.length > 0 ? (
                        productsList.map((product, index: number) => (
                          <Product.Root
                            key={product.id}
                            ref={index === productsList.length - 1 ? lastItemRef : null}
                            className="gap-4">
                            <ProductDetailsDialog productId={product.id}>
                              <Product.Header className="gap-x-1">
                                <Product.HeaderInfo
                                  name={product?.name}
                                  volume={product?.medicalData?.volume}
                                  unitOfMeasurement={product?.medicalData?.unit_of_measurement}
                                  brandName={product?.brand?.name}
                                  className="[&>#product-name]:text-xs"
                                />
                                <Product.HeaderImage
                                  className="size-16 justify-self-end border-solid border-neutral-200 shadow-none"
                                  src={product?.media?.filter((value) => value.format === 'IMG')?.[0]?.path}
                                  alt={product?.name}
                                  isNationalProduct={product?.supplier?.id === 18}
                                />
                                <Product.HeaderBadges>
                                  {product?.class?.name && (
                                    <Badge variant="neutral" className="text-xxs">
                                      {product?.class?.name}
                                    </Badge>
                                  )}
                                  {product?.flavor && (
                                    <Badge className="bg-flavors-default-100 text-xxs text-flavors-default-900">
                                      {product?.flavor}
                                    </Badge>
                                  )}
                                </Product.HeaderBadges>
                              </Product.Header>
                            </ProductDetailsDialog>
                            <div>
                              <Product.Footer>
                                <Product.Price
                                  currentPrice={product?.real_price ?? undefined}
                                  promotionalPrice={product?.valid_promotion?.promotional_price}
                                  className="text-left [&>#product-price]:text-base"
                                />

                                {product?.flavors && product?.flavors.length > 1 ? (
                                  <Product.FlavorSelect
                                    className="px-2 text-sm"
                                    isDisabled={isLoadingAction(product.id)}
                                    triggerText="+ Escolher sabor"
                                    variant="outline_light"
                                    size="md"
                                    unstyled={false}
                                    flavorsList={product?.flavors || []}
                                    onChange={onAddToCart}
                                    value={product?.flavor || 'Natural'}
                                  />
                                ) : (
                                  <Button
                                    className="gap-1 px-2 text-sm font-normal"
                                    variant="outline_light"
                                    size="md"
                                    onClick={(event) => {
                                      event.preventDefault()
                                      onAddToCart(product.id)
                                    }}
                                    isLoading={isLoadingAction(product.id) || false}>
                                    <span className="text-lg font-bold">+</span> Adicionar
                                  </Button>
                                )}
                              </Product.Footer>
                            </div>
                            <Separator className="mb-6" />
                          </Product.Root>
                        ))
                      ) : (
                        <Typography.Heading type="headingSix" className="text-center">
                          Nenhum produto encontrado para pesquisa selecionada.
                        </Typography.Heading>
                      )}
                    </>
                  )}
                </>
              )}
            </motion.div>
          </AnimatePresence>
          {isFetchingNextPage && (
            <div className="flex w-full items-center justify-center">
              <LuLoader2 size={42} className="animate-spin text-primary" />
            </div>
          )}
        </ScrollArea>

        <Sheet.Footer className="h-[120px] items-center justify-end gap-3 px-11 py-2 pb-6">
          {currentStepConfig.previousStep && (
            <Button
              type="button"
              size="lg"
              unstyled
              className="font-medium text-primary-700"
              onClick={() => goToStep(currentStepConfig.previousStep)}>
              Voltar
            </Button>
          )}
          {currentStepConfig.nextStep ? (
            <Button
              type="button"
              size="lg"
              full
              onClick={() => goToStep(currentStepConfig.nextStep)}
              className="font-medium">
              Avançar
            </Button>
          ) : (
            <Sheet.Close asChild>
              <Button type="submit" size="lg" full>
                Concluir
              </Button>
            </Sheet.Close>
          )}
        </Sheet.Footer>
      </Sheet.Content>
    </Sheet.Root>
  )
}
