import React from 'react'

export default function IconeReceita({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M14.25 21.1694H33.75" stroke="#3D3D3D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.25 27.0678H33.75" stroke="#3D3D3D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6 40.339V12.322C6 11.931 6.15804 11.5559 6.43934 11.2794C6.72064 11.0028 7.10218 10.8475 7.5 10.8475H40.5C40.8978 10.8475 41.2794 11.0028 41.5607 11.2794C41.842 11.5559 42 11.931 42 12.322V40.339L36 37.3898L30 40.339L24 37.3898L18 40.339L12 37.3898L6 40.339Z"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
