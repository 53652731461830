import { ReactNode } from 'react'

interface TabButtonProps {
  children: ReactNode
  isActive: boolean
  onClick: () => void
}

const TabButton = ({ children, isActive, onClick }: TabButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`bg-transparent pb-2 text-sm ${
        isActive
          ? 'border-b-3 border-x-0 border-t-0 border-solid border-green-600 font-semibold text-green-600'
          : 'border-b-3 border-x-0 border-t-0 border-solid border-transparent'
      }`}
    >
      {children}
    </button>
  )
}

export default TabButton
