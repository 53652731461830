import {
  svgBemEstar,
  svgCronica,
  svgEsportiva,
  svgMental,
  svgNeurologia,
  svgOdontologia,
  svgOncologia,
  svgPediatria,
  svgPele
} from 'assets/svg'
import * as queryString from 'querystring'
import { useHistory, useLocation } from 'react-router-dom'

import { BadgeTreatmentLineProps } from 'types/BadgeLineTtpes'
import { transformFilterProductsUrl } from '../../utils/formatedParamsUrl'
import Text from '../Text'
import * as Style from './styles'

const typeBadge = {
  cronica: { imgSvg: svgCronica, text: 'Dor crônica' },
  mental: { imgSvg: svgMental, text: 'Saúde mental' },
  oncologia: { imgSvg: svgOncologia, text: 'Oncologia' },
  neurologia: { imgSvg: svgNeurologia, text: 'Neurologia' },
  pediatria: { imgSvg: svgPediatria, text: 'Pediatria' },
  intimo: { imgSvg: svgBemEstar, text: 'Bem-estar íntimo' },
  pele: { imgSvg: svgPele, text: 'Cuidados da pele' },
  esportiva: { imgSvg: svgEsportiva, text: 'Medicina esportiva' },
  odontologia: { imgSvg: svgOdontologia, text: 'Odontologia' }
}
export function BadgeTreatmentLine({
  title,
  type,
  svg,
  color,
  id,
  selectCategory,
  setSelectCategory
}: BadgeTreatmentLineProps) {
  const { text, imgSvg } = typeBadge[type]
  const history = useHistory()
  const location = useLocation()

  const handleFilter = () => {
    const status = selectCategory?.some((item: any) => item === id)
    const keyParamsSearchUrls = transformFilterProductsUrl(location.search) as any
    if (!status) {
      const newSelectCategory = [id]
      setSelectCategory(newSelectCategory)
      const search = queryString.stringify({ ...keyParamsSearchUrls, category_id: newSelectCategory })
      history.push({ pathname: '/produtos/1', search })
    }
    if (selectCategory && status && selectCategory?.length > 0) {
      const filterSelectCategory = selectCategory?.filter((item: any) => item !== id)
      setSelectCategory(filterSelectCategory)
      if (filterSelectCategory.length > 0) {
        keyParamsSearchUrls.category_id = filterSelectCategory
        const keyParamsSearchUrl = queryString.stringify({ ...keyParamsSearchUrls })
        history.push({ pathname: '/produtos/1', search: keyParamsSearchUrl })
      } else {
        const { category_id, ...rest } = keyParamsSearchUrls
        const search = queryString.stringify({ ...rest })
        history.push({ pathname: '/produtos/1', search })
      }
    }
  }
  const active = selectCategory?.some((item: any) => item === id)
  return (
    <Style.Container className="badge" active={active}>
      <button type="button" onClick={handleFilter}>
        <Style.Wrapper type={type} color={color} active={active}>
          <img src={svg || imgSvg} alt={type} />
        </Style.Wrapper>
        <Text type="md" color={active ? 'tertiary' : 'black'} bold={active}>
          {title || text}
        </Text>
      </button>
    </Style.Container>
  )
}
