/* eslint-disable jsx-a11y/label-has-associated-control */
import { Select } from 'antd'
import EmptyState from 'components/EmptyState'
import Loading from 'components/Loading'
import { format } from 'date-fns'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { noop } from 'lodash'
import { ChangeEvent, useState } from 'react'
import { clearMask, formatCpf } from 'utils/formatters'
import * as Style from '../styles'
import PhoneInput from './phoneInput'

export default function PatientInputs() {
  const { prescription, changePrescriptionPatient, fetchPatientByCpf, loading, changeNewProductList } =
    useCannectAssistant()
  const [loadingUpdate, setLoadingUpdate] = useState(false)

  function handleCpfChange(event: ChangeEvent<HTMLInputElement>) {
    const inputValue = formatCpf(event.target.value)

    changePrescriptionPatient('cpf', inputValue)
    if (inputValue.length === 14) {
      fetchPatientByCpf(clearMask(inputValue))
    }
  }

  const onChange = async (value: string) => {
    setLoadingUpdate(true)
    await changeNewProductList(value)
    setLoadingUpdate(false)
  }

  const formatPatientRecipes = () => {
    const recipes = prescription.patient.recipes || []
    const newValues = recipes.map((item) => ({
      value: item?.id,
      label: format(new Date(item?.createdAt), "dd/MM/yyyy 'as' HH:mm")
    }))
    return newValues
  }

  return (
    <>
      <Style.InputsWrapper>
        <Style.HorizontalContent>
          <div className="input-label">
            <label>CPF*</label>
            <input
              placeholder="digite CPF do paciente"
              type="text"
              required
              value={prescription?.patient?.cpf}
              onChange={(event) => handleCpfChange(event)}
            />
          </div>
          <div className="input-label">
            <label>Nome completo*</label>
            <input
              placeholder="Nome completo do paciente"
              type="text"
              required
              value={prescription?.patient?.name}
              onChange={(event) => changePrescriptionPatient('name', event.target.value)}
            />
          </div>
        </Style.HorizontalContent>
      </Style.InputsWrapper>

      <Style.InputsWrapper>
        <Style.HorizontalContent>
          <div className="input-label">
            <label>E-mail*</label>
            <input
              placeholder="E-mail do paciente"
              type="email"
              required
              value={prescription?.patient?.email}
              onChange={(event) => changePrescriptionPatient('email', event.target.value)}
            />
          </div>
          <div className="input-label">
            <label>Celular* (usado para envio de sms)</label>
            <PhoneInput phoneFromPrescrition={prescription.patient.phone} />
          </div>
        </Style.HorizontalContent>
      </Style.InputsWrapper>
      {prescription?.patient?.recipes && prescription.patient.recipes.length > 0 && (
        <Style.InputsWrapper>
          <Style.HorizontalContent>
            <div className="input-label">
              <label>Acessar prescrições anteriores</label>
              <Select
                loading={loadingUpdate}
                disabled={loadingUpdate}
                showSearch
                placeholder="Selecione para carregar posologias anteriores "
                optionFilterProp="children"
                onChange={onChange}
                onSearch={noop}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={formatPatientRecipes()}
                style={{ fontSize: '14px', fontWeight: 400, color: 'rgb(66, 66, 66)' }}
                dropdownStyle={{ fontSize: '14px', fontWeight: 400, color: 'rgb(66, 66, 66)' }}
                allowClear
                clearIcon={<span>limpar sessão</span>}
                notFoundContent={<EmptyState title="nenhuma prescrição disponivel" />}
              />
            </div>
          </Style.HorizontalContent>
        </Style.InputsWrapper>
      )}
      {loading && <Loading loading={loading} />}
    </>
  )
}
