import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { BadgeTreatmentTypeProps } from '.'

type WrapperStyleProps = Pick<BadgeTreatmentTypeProps, 'size' | 'activeCategory'>

const WrapperModifier = {
  xsmall: () => css`
    width: 95px;
    ${IconBox} {
      width: 60px;
    }
    ${TitleBadge} {
      font-size: 12px;
      width: 95px;
    }
  `,
  small: () => css`
    width: 130px;
    ${IconBox} {
      width: 65px;
    }
    ${TitleBadge} {
      font-size: 16px;
      width: 130px;
    }
  `,
  medium: () => css`
    width: 157px;
    ${IconBox} {
      width: 85px;
    }
    ${TitleBadge} {
      font-size: 18px;
      width: 157px;
    }
  `,
  large: () => css`
    width: 170px;
    ${IconBox} {
      width: 95px;
    }
    ${TitleBadge} {
      font-size: 20px;
      width: 170px;
    }
  `
}

export const IconBox = styled.div`
  width: 85px;
  border-radius: 50%;
`
export const IconBadge = styled.img`
  width: 100%;
  filter: invert(55%) sepia(0%) saturate(1%) hue-rotate(340deg) brightness(95%) contrast(92%);
`

export const TitleBadge = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #858585;
  width: 157px;
  text-align: center;
`

export const Wrapper = styled.main<WrapperStyleProps>`
  ${({ size, activeCategory }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${temporaryTheme.spacing.space2};
    gap: ${temporaryTheme.spacing.space3};
    width: 157px;

    ${IconBadge} {
      filter: ${activeCategory &&
      'invert(62%) sepia(64%) saturate(1056%) hue-rotate(338deg) brightness(98%) contrast(99%)'};
    }
    ${TitleBadge} {
      color: ${activeCategory && temporaryTheme.colors.darkGrey};
    }

    &:hover {
      cursor: pointer;

      ${TitleBadge} {
        color: ${temporaryTheme.colors.darkGrey};
      }

      ${IconBadge} {
        filter: invert(43%) sepia(34%) saturate(876%) hue-rotate(108deg) brightness(104%) contrast(86%);
      }
    }

    ${!!size && WrapperModifier[size]}
  `}
`
