import { ColDef } from 'new-components/atoms/Table'
import { THistoryListItem } from '../../../MyPatients/types'
import HistoryNumberCell from './components/HistoryNumberCell'
import LinkCell from './components/LinkCell'
import CategoryCell from './components/CategoryCell'

export const CATEGORIES = [
  {
    value: undefined,
    label: 'Todos'
  },
  {
    value: 'prescricao',
    label: 'Prescrições'
  },
  {
    value: 'consulta',
    label: 'Consultas'
  },
  {
    value: 'pedido',
    label: 'Pedidos'
  }
]

export const COL_DEFS: ColDef<THistoryListItem>[] = [
  {
    id: 1,
    label: 'data de atualização',
    field: 'createdAt',
    className: 'text-xs text-nowrap'
  },
  {
    id: 2,
    label: 'categoria',
    cellRenderer: CategoryCell
  },
  {
    id: 3,
    label: 'número',
    cellRenderer: HistoryNumberCell
  },
  {
    id: 4,
    label: 'status',
    field: 'status',
    className: 'text-xs text-nowrap'
  },
  {
    id: 5,
    label: 'link',
    cellRenderer: LinkCell
  }
]
