import { useSendPrescriptionStep } from './useSendPrescriptionStep'
import { Button, Typography, Illustrations, Form } from '@cannect/new-components/atoms'
import { DrawerDialog } from '@cannect/new-components/molecules'

import { LuUpload } from 'react-icons/lu'
import Loading from '@cannect/components/Loading'

export const SendPrescriptionStepUI = ({
  hasOcrError,
  isPending,
  handleErrorRedirect,
  handleSubmitPrescription,
  handleContinueJourney,
  setIsOpenExistingJourneyWarning,
  isOpenExistingJourneyWarning,
  isLoadingUserLastJourney,
  handleTryAgain
}: ReturnType<typeof useSendPrescriptionStep>) => {
  return (
    <div className="flex flex-col items-center">
      <Loading loading={isLoadingUserLastJourney} />
      <div className="mb-8 flex flex-col items-center gap-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          {hasOcrError ? 'A leitura de sua receita falhou :(' : 'Tudo começa pela receita médica'}
        </Typography.Heading>
        <Illustrations.Prescription />
        {!hasOcrError && (
          <Typography.Text type="paragraphTwo" className="mt-8 text-center">
            Ela precisa ter sido emitida nos últimos 5 meses e assinada por um profissional com CRM/CRO válido.
          </Typography.Text>
        )}
      </div>

      {hasOcrError ? (
        <div className="space-y-4">
          <Button
            className="font-medium text-primary-700"
            full
            unstyled
            isLoading={isPending}
            size="lg"
            onClick={handleTryAgain}>
            Tentar novamente
          </Button>

          <Typography.Text type="paragraphTwo" className="text-center">
            Se preferir, adicione os produtos manualmente ou fale com nosso atendimento.
          </Typography.Text>
          <Button className="!mt-6 font-medium" full size="lg" onClick={handleErrorRedirect}>
            + Adicionar produtos
          </Button>
          <Button className="font-medium" full size="lg" variant="outline">
            Falar com o atendimento
          </Button>
        </div>
      ) : (
        <Form.File
          isDisabled={isPending}
          showListFiles={false}
          acceptFormats={['.pdf', '.png', '.jpeg', '.jpg']}
          onChange={(fileItem) => {
            handleSubmitPrescription(fileItem[0])
          }}
          CustomInputComponent={
            <Button
              className="mt-auto font-medium"
              full
              isLoading={isPending}
              size="lg"
              icon={<LuUpload />}
              iconPlacement="left">
              Enviar receita
            </Button>
          }
        />
      )}
      <DrawerDialog.Root open={isOpenExistingJourneyWarning} onOpenChange={setIsOpenExistingJourneyWarning}>
        <DrawerDialog.Content className="p-6 pt-2 md:pt-6">
          <DrawerDialog.Header>
            <DrawerDialog.Title className="text-left md:text-center">
              Deseja continuar com seu pedido em andamento?
            </DrawerDialog.Title>
          </DrawerDialog.Header>

          <div className="flex flex-col items-center gap-4">
            <Button className="font-medium" full size="lg" onClick={() => handleContinueJourney()}>
              Sim, continuar pedido
            </Button>
            <Button
              className="font-medium text-primary-700"
              full
              unstyled
              size="lg"
              onClick={() => {
                setIsOpenExistingJourneyWarning(false)
              }}>
              Não, criar novo pedido
            </Button>
          </div>
        </DrawerDialog.Content>
      </DrawerDialog.Root>
    </div>
  )
}
