/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-constructed-context-values */
import { IChildTypes } from '@cannect/types/ChildrenTypes'
import { useLocalStorage } from 'hooks/useLocalStorage'
import useQueryString from 'hooks/useQueryString'
import { noop } from 'lodash'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'

interface CartCheckoutContextValues {
  addToCart: (id: number, quantity?: number) => void
  removeCart: (id: number) => void
  incrementOrDecrementCart: (id: number, quantity: number) => void
  clearCart: () => void
  lastOrderCreated: any
  newLastOrder: (val: any) => void
  getLastOrder: (loading?: boolean) => void
  getPublicCart: ({ cart_id }: { cart_id: string }) => void
  loading: boolean
  setLoading: (val: boolean) => void
  recipesDocuments: any
  setRecipeDocuments: any
  updateCartCoupon: (coupon: any, item: any) => void
  loadingCupon: boolean
  isUpdate: boolean
  updateCart: (items?: any[]) => void
  handlePatientIsUser: () => void
  patientIsUser: boolean
  updateCartRecipes: (recipes: any) => void
  statusPaid: any
  addToCartFlavor: (idRemove: number, id: number, quantity?: number) => void
  setOrderId: (val: any) => void
  clearOrderData: () => void
  AddToCartByFlavor: (items: any[]) => void
}

const defaultPrepareCartContextValues = {
  cartItems: [],
  addToCart: noop,
  removeCart: noop,
  clearCart: noop,
  incrementOrDecrementCart: noop,
  setCartItems: noop,
  lastOrderCreated: {},
  newLastOrder: noop,
  getLastOrder: noop,
  getPublicCart: noop,
  loading: false,
  setLoading: noop,
  recipesDocuments: {},
  setRecipeDocuments: noop,
  getLastOrderOrUpdatedCart: noop,
  updateCartCoupon: noop,
  loadingCupon: false,
  isUpdate: false,
  updateCart: noop,
  patientIsUser: false,
  handlePatientIsUser: noop,
  updateCartRecipes: noop,
  statusPaid: {},
  addToCartFlavor: noop,
  setOrderId: noop,
  clearOrderData: noop,
  AddToCartByFlavor: noop
}

const PrepareCartContext = createContext<CartCheckoutContextValues>(defaultPrepareCartContextValues)

function PrepareCartProvider({ children }: IChildTypes) {
  const [orderData, setOrderData] = useState<any>({ items: [], recipes: [] })
  const [recipesDocuments, setRecipeDocuments] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [loadingCupon, setLoadingCupon] = useState(false)
  const [stateOrderId, setStateOrderId] = useState(null)
  const [patientIsUser, setPatientIsUser] = useState(true)
  const [statusPaid, setStatusPaid] = useState<any>({})
  const [validatedStatus, setValidatedStatus] = useState(false)
  const { filters } = useQueryString()
  const withFilter = filters?.cart_id
  const [orderId, setOrderId] = useLocalStorage('@CANNECT:ORDER_CHECKOUT_CART_ID', null)

  const cartItemsRef = useRef(orderData?.items || [])

  const isPublicUrl = withFilter ? 'order_cart_public' : 'order_cart'

  useEffect(() => {
    const storedItems = JSON.parse(localStorage.getItem('selectedProducts') || '[]')
    setOrderData((prevData: any) => {
      cartItemsRef.current = storedItems
      return { ...prevData, items: storedItems }
    })
  }, [])

  async function AddToCartByFlavor(items: any[]) {
    const oldCartItems = orderData?.items || []
    const formattedItems = items.map((item) => ({
      id: item?.product_id || item?.id,
      product_id: item?.product_id || item?.id,
      flavor: item?.flavor?.name,
      flavors: item?.flavors,
      name: item?.translated_name,
      quantity: item?.quantity,
      stock: item?.stock,
      product: {
        id: item?.id,
        main_product_id: item?.id
      },
      main_product_id: item?.main_product_id
    }))

    const updatedCartItems = oldCartItems.map((existingItem) => {
      const product_id = existingItem?.product_id || existingItem?.id
      const matchingItem = formattedItems.find((item) => product_id === item.id)

      if (matchingItem) {
        return {
          ...existingItem,
          quantity: matchingItem.quantity // Replace the quantity instead of adding
        }
      }

      return existingItem
    })

    // Add new items to the cart
    const newItems = formattedItems.filter(
      (item) => !updatedCartItems.some((existingItem) => existingItem.product_id === item.product_id)
    )

    const finalCartItems = [...updatedCartItems, ...newItems]

    await updateCart(finalCartItems)
  }

  const handlePatientIsUser = () => {
    setPatientIsUser(true)
  }

  const clearOrderData = () => {
    setOrderId(null)
    setOrderData({ items: [], recipes: [] })
    setStatusPaid({})
    setIsUpdate(false)
    localStorage.removeItem('selectedProducts')
    localStorage.removeItem('selectedProductsMedice')
  }

  const getLastOrder = async (loading = true) => {
    if (withFilter) {
      await getPublicCart({ cart_id: withFilter }, loading)
    } else {
      await getLastOrders(loading)
    }
  }

  const hasDuplicatedItems = (items: any[]): boolean => {
    const productIdList = items.map((i: any) => i.product_id)
    const uniqueProductIdList = [...new Set([...productIdList])]
    return productIdList.length > uniqueProductIdList.length
  }

  const removeDuplicatedCartItems = (items: any[]) => {
    const uniqueItems: any[] = []
    const itemMap = new Map()

    items.forEach((item: any) => {
      const existingItem = itemMap.get(item.product_id)
      if (existingItem) {
        existingItem.quantity += item.quantity
      } else {
        itemMap.set(item.product_id, { ...item })
      }
    })

    itemMap.forEach((item) => uniqueItems.push(item))
    return uniqueItems
  }

  const getLastOrders = async (loading = true) => {
    const id = orderId || stateOrderId
    if (!id) return
    setLoading(loading)
    try {
      const { data } = await api.post(`/order_status/${id}`)
      if (data?.order) {
        hasDuplicatedItems(data?.order?.items)
          ? removeDuplicatedCartItems(data?.order?.items)
          : setOrderData(data?.order)
      }
      setStatusPaid(data?.payment)
      setPatientIsUser(data?.patientIsUser)
    } catch (e) {
      console.log(e)
    } finally {
      setValidatedStatus(true)
      setLoading(false)
    }
  }

  const getPublicCart = async ({ cart_id }: { cart_id?: string; loading?: boolean }, loading = true) => {
    setLoading(loading)
    try {
      const { data } = await api.get(`/order_b2c_public/${cart_id}`)
      if (data?.order) {
        setOrderData(data?.order)
        setPatientIsUser(data?.patientIsUser)
      }
      const isPaid = data?.order?.payments?.some((payment: any) => payment.status === 'paid')
      const status = isPaid ? { status: 'paid' } : data?.payment
      setStatusPaid({ ...status })
    } catch (e: any) {
      console.log(e)
    } finally {
      setValidatedStatus(true)
      setLoading(false)
    }
  }

  const createOrder = async ({ items }: any) => {
    try {
      const { data } = await api.post('/order_cart', { items })
      setOrderData(data?.order)
      setOrderId(data?.order?.id)
      setStateOrderId(data?.order?.id)
    } catch (error) {
      console.log(error)
    }
  }

  const clearCart = () => {
    updateCart([])
  }

  const updateCartCoupon = async (coupon: any, items: any) => {
    setIsUpdate(true)
    try {
      const { data } = await api.put(`/${isPublicUrl}/${orderData?.id}`, { coupon, items })
      setOrderData(data?.order)
      setPatientIsUser(data?.patientIsUser)
    } catch (e) {
      console.log(e)
    } finally {
      setIsUpdate(false)
      setLoadingCupon(false)
    }
  }

  const updateCartRecipes = async (recipeId: any) => {
    try {
      const { data } = await api.put(`/${isPublicUrl}/${orderData?.id}`, { recipes: [{ id: recipeId }] })
      setOrderData(data?.order)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingCupon(false)
    }
  }

  const updateCart = async (itemsToAdd: any[] = [], idsToRemove: number[] = [], validateDuplicated = true) => {
    setLoadingCupon(true)
    setIsUpdate(true)

    try {
      let orderId = orderData?.id

      // Fetch current state of orderData from the reference
      const currentItems = cartItemsRef.current || []

      // Remove specified items from the cart
      const remainingItems = currentItems.filter(
        (item: any) => !idsToRemove.includes(item.id) && !idsToRemove.includes(item.product_id)
      )

      // Add or update specified items in the remaining list
      itemsToAdd.forEach((newItem) => {
        const existingItemIndex = remainingItems.findIndex((item: any) => item.product_id === newItem.product_id)
        if (existingItemIndex !== -1) {
          remainingItems[existingItemIndex].quantity = newItem.quantity // Replace quantity instead of adding
        } else {
          remainingItems.push(newItem)
        }
      })

      if (!orderId) {
        const { data: newOrderData } = await api.post('/order_cart', { items: remainingItems })
        orderId = newOrderData?.order?.id
        setOrderData(newOrderData?.order)
        cartItemsRef.current = newOrderData?.order?.items || []
      }

      const { data } = await api.put(`/${isPublicUrl}/${orderId}`, { items: remainingItems })

      // Remove any invalid items that were added back by the API
      const validItems = data?.order?.items.filter(
        (item: any) => !idsToRemove.includes(item.id) && !idsToRemove.includes(item.product_id)
      )

      // Update the orderData and localStorage with the valid items
      setOrderData((prevOrderData: any) => {
        cartItemsRef.current = validItems
        return { ...data.order, items: validItems }
      })

      localStorage.setItem('selectedProducts', JSON.stringify(validItems))

      // Validate the state after updating the cart
      validateCartState(idsToRemove)
    } catch (e) {
      console.error('Erro ao atualizar carrinho:', e)
      toast.error('Erro ao atualizar carrinho')
    } finally {
      setIsUpdate(false)
      setLoadingCupon(false)
    }
  }

  const incrementOrDecrementCart = async (id: number, quantity: number) => {
    const newValues = orderData?.items?.map((item: any) => {
      return item.id === id ? { ...item, quantity } : item
    })
    setOrderData({ ...orderData, items: newValues })
    updateCart(newValues)
  }

  async function addToCart(id: number, quantity?: number) {
    setLoadingCupon(true)
    const existingItem = orderData?.items?.find((item: any) => item.product_id === id)
    const formatValues = existingItem
      ? orderData?.items.map((itemCart: any) => {
          if (itemCart.product_id === id) {
            return {
              product_id: itemCart?.product_id || itemCart?.product?.id,
              quantity: itemCart.quantity + (quantity || 1)
            }
          }
          return itemCart
        })
      : [...orderData.items, { product_id: id, quantity: quantity || 1 }]

    if (!orderId) {
      await createOrder({ items: formatValues })
      toast.success('Produto adicionado ao carrinho')
    } else {
      try {
        const { data } = await api.put(`/order_cart/${orderId}`, { items: formatValues })
        setOrderData(data?.order)
        toast.success('Produto adicionado ao carrinho')
      } catch (error) {
        setOrderId(null)
        toast.error('Erro ao adicionar produto no pedido')
      }
    }
    setLoadingCupon(false)
  }

  async function addToCartFlavor(idRemove: number, id: number, quantity?: number) {
    setIsUpdate(true)
    const newOrder = orderData?.items?.filter((item: any) => item.product_id !== idRemove)
    const formatValues = [...newOrder, { product_id: id, quantity: quantity || 1 }]

    try {
      const { data } = await api.put(`/order_cart/${orderId}`, { items: formatValues })
      setOrderData(data?.order)
      toast.success('Produto adicionado ao carrinho')
    } catch (error) {
      toast.error('Erro ao adicionar produto no carrinho')
    } finally {
      setIsUpdate(false)
    }
  }

  const removeCart = async (idsToRemove: number | number[]) => {
    if (!Array.isArray(idsToRemove)) {
      idsToRemove = [idsToRemove]
    }

    const remainingItems = cartItemsRef.current?.filter(
      (item: any) => !idsToRemove.includes(item.id) && !idsToRemove.includes(item.product_id) && item.quantity > 0
    )

    setOrderData((prevOrderData: any) => ({ ...prevOrderData, items: remainingItems }))
    cartItemsRef.current = remainingItems

    const storedProducts = JSON.parse(localStorage.getItem('selectedProducts') || '[]')
    const updatedStoredProducts = storedProducts.filter(
      (storedProduct: any) =>
        !idsToRemove.includes(storedProduct.id) &&
        !idsToRemove.includes(storedProduct.product_id) &&
        storedProduct.quantity > 0
    )
    localStorage.setItem('selectedProducts', JSON.stringify(updatedStoredProducts))

    await updateCart([], idsToRemove)
  }

  const validateCartState = (idsToRemove: number[]) => {
    let remainingItems = cartItemsRef.current || []
    const invalidItemsInOrderData = remainingItems.filter(
      (item) => idsToRemove.includes(item.id) || idsToRemove.includes(item.product_id)
    )
    if (invalidItemsInOrderData.length > 0) {
      remainingItems = remainingItems.filter(
        (item) => !idsToRemove.includes(item.id) && !idsToRemove.includes(item.product_id)
      )
      setOrderData((prevOrderData: any) => ({
        ...prevOrderData,
        items: remainingItems
      }))
      cartItemsRef.current = remainingItems
    }

    const storedProducts = JSON.parse(localStorage.getItem('selectedProducts') || '[]')
    const invalidItemsInLocalStorage = storedProducts.filter((storedProduct: any) =>
      idsToRemove.includes(storedProduct.id)
    )
    if (invalidItemsInLocalStorage.length > 0) {
      const updatedStoredProducts = storedProducts.filter(
        (storedProduct: any) =>
          !idsToRemove.includes(storedProduct.id) && !idsToRemove.includes(storedProduct.product_id)
      )
      localStorage.setItem('selectedProducts', JSON.stringify(updatedStoredProducts))
    }
  }

  return (
    <PrepareCartContext.Provider
      value={{
        AddToCartByFlavor,
        clearOrderData,
        getLastOrder,
        lastOrderCreated: orderData,
        addToCart,
        incrementOrDecrementCart,
        clearCart,
        removeCart,
        newLastOrder: setOrderData,
        getPublicCart,
        loading,
        setLoading,
        recipesDocuments,
        setRecipeDocuments,
        updateCartCoupon,
        loadingCupon,
        isUpdate,
        updateCart,
        patientIsUser,
        handlePatientIsUser,
        updateCartRecipes,
        statusPaid,
        addToCartFlavor,
        setOrderId
      }}>
      {children}
    </PrepareCartContext.Provider>
  )
}

function usePrepareCart() {
  return useContext(PrepareCartContext)
}

export { PrepareCartProvider, usePrepareCart }
