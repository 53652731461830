import { ReactNode, SelectHTMLAttributes } from 'react'
import * as Style from './styles'

type DefaultSelectProps = SelectHTMLAttributes<HTMLSelectElement>

interface SelectProps {
  children: ReactNode
  width?: `${number}px` | `${number}%` | `${number}rem`
  minWidth?: `${number}px` | `${number}%` | `${number}rem`
}

export default function Select({ children, width, minWidth, ...rest }: SelectProps & DefaultSelectProps) {
  return (
    <Style.SelectContainer style={{ width, minWidth }} {...rest}>
      {children}
    </Style.SelectContainer>
  )
}
