import ConfirmationDialogue from 'components/ConfirmationDialogue'
import Button from 'new-components/Button'
import { useState } from 'react'
import TemplateNameInput from './components/TemplateNameInput'
import * as Styles from './styles'

interface TemplateHeaderProps {
  changeTemplateName: (newName: string) => void
  deleteTemplate: () => void
  templateName: string
}

export default function TemplateHeader({ templateName, changeTemplateName, deleteTemplate }: TemplateHeaderProps) {
  const [isDeleteTemplateModalOpened, setIsDeleteTemplateModalOpened] = useState(false)

  return (
    <Styles.TemplateHeaderContainer>
      <Styles.LabelAndInputWrapper>
        <h2>Modelo: </h2>
        <TemplateNameInput templateName={templateName} changeTemplateName={changeTemplateName} />
      </Styles.LabelAndInputWrapper>
      <Button background="red" isOutlined onClick={() => setIsDeleteTemplateModalOpened(true)}>
        excluir modelo
      </Button>

      <ConfirmationDialogue
        isOpened={isDeleteTemplateModalOpened}
        onRequestClose={() => setIsDeleteTemplateModalOpened(false)}
        title="Excluir modelo"
        description="Tem certeza que deseja excluir este modelo?"
        confirmationButtonText="Excluir"
        cancelButtonText="Cancelar"
        onConfirmationClick={deleteTemplate}
        titleColor="#D82626"
        buttonsBackground="red"
        onCancelClick={() => setIsDeleteTemplateModalOpened(false)}
      />
    </Styles.TemplateHeaderContainer>
  )
}
