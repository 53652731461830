import styled from 'styled-components'

export const RegistrationPathButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  width: 100%;
  padding: 2rem;
  border: 1px solid #e0e7ec;

  min-height: 12rem;
  height: 100%;
  cursor: pointer;

  border-radius: 0.5rem;
  transition: 0.3s;

  > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p {
      line-height: 1.75rem;
      font-family: 'Nunito', sans-serif;
      font-weight: 400;
    }
  }

  h2 {
    transition: 0.3s;
  }

  svg {
    width: 4.5rem;
    height: 4.5rem;
    min-width: 4.5rem;
    min-height: 4.5rem;
    transition: 0.6s;
  }

  &:hover {
    border-color: #748351;

    h2 {
      color: #748351;
    }

    svg {
      path {
        stroke: #748351 !important;
      }
    }
  }
`
