import styled from 'styled-components'

export const StepButtonContainer = styled.button<any>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${props => (props.isMobile ? '20rem' : '5rem')};
  height: 5rem;
  border-radius: 999px;
  cursor: default;

  font-size: 1.5rem;
  outline: none;
  border: none;

  /* transition: transform 0.2s; */

  &:hover {
    /* transform: scale(1.05); */
  }

  &:disabled {
    cursor: not-allowed;
  }
`
