import { Badge, Separator } from '@cannect/new-components/atoms'
import { Product } from '@cannect/new-components/molecules'
import { TDigitalJourneyFormData } from '@cannect/pages/DigitalJourney/types'
import { ProductDetailsDialog } from '@cannect/new-components/organisms'
import { CustomSelect, TOption } from '@cannect/new-components/atoms/Form/CustomSelect'

type TCartProductItem = {
  product: TDigitalJourneyFormData['cart']['products'][0]
  handleRemoveProduct: (productId: number) => void
  handleChangeProductQuantity: (productId: number, quantity: number) => void
  handleChangeProductFlavor: (oldProductId: number, newProductId: number) => void
  loadingProducts: Record<number, boolean>
  isLastItem: boolean
}

const quantityOptions: TOption[] = Array.from({ length: 20 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1} ${i === 0 ? 'item' : 'itens'}`
}))

export const CartProductItem = ({
  product,
  handleRemoveProduct,
  handleChangeProductQuantity,
  handleChangeProductFlavor,
  loadingProducts,
  isLastItem
}: TCartProductItem) => (
  <div className="flex flex-col items-center">
    <Product.Root>
      <Product.RemoveButton onRemove={() => handleRemoveProduct(product.product_id)} />
      <ProductDetailsDialog productId={product.product_id}>
        <Product.Header>
          <Product.HeaderImage
            src={product?.media?.filter((value) => value.format === 'IMG')?.[0]?.path}
            alt={product?.name}
            isNationalProduct={product?.supplier?.id === 18}
          />
          <Product.HeaderInfo
            name={product?.name}
            volume={product?.medical_data?.volume}
            unitOfMeasurement={product?.medical_data?.unit_of_measurement}
            brandName={product?.brand?.name}
          />
          <Product.HeaderBadges>
            {product?.class?.name && (
              <Badge variant="neutral" className="text-xxs">
                {product?.class?.name}
              </Badge>
            )}
            {product?.flavor && (
              <Badge className="bg-flavors-default-100 text-xxs text-flavors-default-900">
                {product?.flavor?.name || 'Natural'}
              </Badge>
            )}
          </Product.HeaderBadges>
        </Product.Header>
      </ProductDetailsDialog>
      <div>
        <Product.Footer>
          <div className="flex items-center gap-4">
            <CustomSelect
              isSearchable={false}
              isClearable={false}
              customClassNames={{
                control: 'px-3 gap-5',
                dropdownIndicator: 'text-gray-400',
                option: 'text-gray-400'
              }}
              variant="outlined"
              options={quantityOptions}
              value={quantityOptions.find((option) => option.value === product.product_quantity)}
              onChange={(option) => {
                if (option) {
                  handleChangeProductQuantity(product.product_id, option.value as number)
                }
              }}
              isLoading={loadingProducts[product.product_id]}
              size="small"
            />
            {product?.flavors && product?.flavors.length > 1 && (
              <Product.FlavorSelect
                flavorsList={product?.flavors || []}
                onChange={(newProductId) => handleChangeProductFlavor(product.product_id, newProductId)}
                value={product?.flavor?.name || 'Natural'}
              />
            )}
          </div>
          <Product.Price
            currentPrice={product?.real_price}
            promotionalPrice={product?.valid_promotion?.promotional_price}
          />
        </Product.Footer>
      </div>
    </Product.Root>
    {!isLastItem && <Separator className="mt-6" />}
  </div>
)
