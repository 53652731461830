import { SymptomType } from 'hooks/useCannectCare'
import * as Style from './styles'

interface ToggleTypeProps {
  type: SymptomType
  onTypeChange: () => void
}

export default function ToggleType({ type, onTypeChange }: ToggleTypeProps) {
  return (
    <Style.ToggleTypeContainer>
      <span>Episódios</span>
      <Style.ToggleButton type={type} onClick={() => onTypeChange()}>
        <Style.Circle />
      </Style.ToggleButton>
      <span>Intensidade</span>
    </Style.ToggleTypeContainer>
  )
}
