import { LogicalOperatorsBetweenProducts } from 'hooks/useCannectAssistant/types'
import { Fragment, useEffect, useState } from 'react'
import ConfirmationDialogue from 'components/ConfirmationDialogue'
import CheckboxInput from 'components/CheckboxInput'
import Button from 'new-components/Button'
import Loading from 'components/Loading'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import SectionsWrapper from '../SectionsWrapper'
import ProductUseMode from './components/ProductUseMode'
import SaveTemplateModal from './components/SaveTemplateModal'
import useTemplate from './hooks/useTemplate'
import TemplateHeader from './components/TemplateHeader'
import { fromPrescriptionToApiProducts, fromTemplateToPrescription } from './helpers'
import PatientData from '../PatientData'
import DigitalSignatureModal from '../EmitRecipe/DigitalSignatureModal'
import { toast } from 'react-toastify'
import * as Styles from './styles'
import { useHistory } from 'react-router'
import { BackButton } from '@cannect/pages/AppointmentScheduling/styles'

export default function PrescriptionReview() {
  const history = useHistory()
  const {
    prescription,
    setStep,
    setPath,
    clearPrescription,
    changePrescriptionRecomendation,
    changeIsTemplateApplied,
    logicalOperatorsBetweenProducts,
    addProductsToPrescription,
    setLogicalOperatorsBetweenProducts,
    changeTemplateName,
    removeProductFromPrescription,
    createRecipe
  } = useCannectAssistant()
  const { isTemplateLoading, template, updateTemplate, saveTemplate, deleteTemplate } = useTemplate({
    setPath,
    setStep
  })
  const [modalSignature, setModalSignature] = useState(false)
  const [isSignatureErrorDialogOpen, setIsSignatureErrorDialogOpen] = useState(false)
  const [isErrorDialogueOpen, setIsErrorDialogueOpen] = useState(false)
  const [isPatientErrorDialogueOpen, setIsPatientErrorDialogueOpen] = useState(false)
  const [isSaveTemplateModalOpened, setIsSaveTemplateModalOpened] = useState(false)

  useEffect(() => {
    if (!template || prescription.isTemplateApplied) return
    const { products, logicalOperatorsBetweenProducts } = fromTemplateToPrescription(template, prescription.products)
    setLogicalOperatorsBetweenProducts(logicalOperatorsBetweenProducts)
    addProductsToPrescription(products)
    changePrescriptionRecomendation(template.recommendation)
    changeTemplateName(template.name)
    changeIsTemplateApplied(true)
  }, [template])

  useEffect(() => {
    if (!prescription.products || !logicalOperatorsBetweenProducts || template) return
    if (logicalOperatorsBetweenProducts.length === 0) {
      const newLogicalOperators = Array.from(Array(prescription.products.length)).fill('and')
      setLogicalOperatorsBetweenProducts(newLogicalOperators)
    } else {
      const diff = (prescription.products.length ?? 0) - logicalOperatorsBetweenProducts.length
      if (diff < 0) return
      const updatedLogical = Array.from(Array(diff)).fill('and')
      const updatedLogicalOperatorsBetweenProducts = [...logicalOperatorsBetweenProducts, ...updatedLogical]
      setLogicalOperatorsBetweenProducts(updatedLogicalOperatorsBetweenProducts)
    }
  }, [prescription])

  function handleFinishPrescriptionRequest() {
    const isPatientDataFilled =
      prescription.patient?.name &&
      prescription.patient?.cpf &&
      prescription.patient?.phone &&
      prescription.patient?.email

    if (!isPatientDataFilled) {
      setIsPatientErrorDialogueOpen(true)
      return
    }

    const isAnyProductIncluded = prescription.products?.some(product => product.includedInPrescription)
    const hasBottles = prescription.products?.every(product => product.quantity)
    const hasPosology = prescription.products?.every(product => product.howToUse)
    if (!isAnyProductIncluded || !hasBottles || !hasPosology) {
      setIsErrorDialogueOpen(true)
      return
    }

    setModalSignature(true)
  }

  function handleLogicalOperatorsBetweenProductsChange(
    operatorIndex: number,
    newOperator: LogicalOperatorsBetweenProducts
  ) {
    const newLogicalOperators = logicalOperatorsBetweenProducts.map((operator, index) =>
      index === operatorIndex ? newOperator : operator
    )
    setLogicalOperatorsBetweenProducts(newLogicalOperators)
  }

  const handleClosePatientErrorDialog = () => {
    setIsPatientErrorDialogueOpen(false)
    document?.getElementById('main-app-container')?.scrollTo(0, 0)
  }

  const validationStatus = (status?: string) => {
    if (status === 'A') return false
    if (status === 'O') return false
    return true
  }

  const withDigitalSignature = prescription.recipeOptions.useDigitalSignature
  const handleRecipeSignature = async (useDigitalSignature?: boolean) => {
    try {
      const recipeSuccessfulyCreated = await createRecipe(!!useDigitalSignature)
      if (recipeSuccessfulyCreated) {
        toast.success('A receita foi enviada para o e-mail do paciente com sucesso!')
        setStep(state => state + 1)
      }
    } catch (e) {
      setIsSignatureErrorDialogOpen(true)
    }
  }

  const handleCancelPrescription = () => {
    clearPrescription()
    setStep(0)
    setPath('')
  }

  const handleAnotherSignature = () => {
    handleRecipeSignature(false)
    history.push('/assistente')
    setStep(state => state + 1)
  }

  return (
    <SectionsWrapper>
      <Loading loading={isTemplateLoading} />
      <Styles.SectionContainer>
        {template && (
          <TemplateHeader
            templateName={prescription.templateName}
            changeTemplateName={changeTemplateName}
            deleteTemplate={deleteTemplate}
          />
        )}
        <PatientData />
        <h2>{prescription.products.length > 0 ? 'Descreva o modo de usar' : 'Adicione Produtos para continuar.'}</h2>
        {prescription.products?.map((product, index) => (
          <Fragment key={product.id}>
            <ProductUseMode
              product={product}
              productIndex={index}
              isArrowUpDisabled={index === 0}
              isArrowDownDisabled={index === prescription.products.length - 1}
              unAvailableProduct={validationStatus(product?.status_assistant)}
            />
            <Styles.RemoveProduct onClick={() => removeProductFromPrescription(product.id)}>
              REMOVER
            </Styles.RemoveProduct>
            {index !== prescription.products.filter(p => p.includedInPrescription).length - 1 && (
              <Styles.CheckboxesContainer>
                <CheckboxInput
                  id={`and-selector-${index}`}
                  label="E"
                  checked={logicalOperatorsBetweenProducts[index] === 'and'}
                  onChange={() => handleLogicalOperatorsBetweenProductsChange(index, 'and')}
                />
                <CheckboxInput
                  id={`or-selector-${index}`}
                  label="OU"
                  checked={logicalOperatorsBetweenProducts[index] === 'or'}
                  onChange={() => handleLogicalOperatorsBetweenProductsChange(index, 'or')}
                />
              </Styles.CheckboxesContainer>
            )}
          </Fragment>
        ))}
      </Styles.SectionContainer>
      <Styles.AddProduct onClick={() => setStep(1)}>ADICIONAR NOVO PRODUTO</Styles.AddProduct>
      <Styles.SectionContainer>
        <h2>Recomendações</h2>
        <textarea
          value={prescription.recomendations}
          style={{ width: '100%' }}
          onChange={event => changePrescriptionRecomendation(event.target.value)}
          placeholder="Quais são as suas recomendações para o seu paciente?"
        />
      </Styles.SectionContainer>
      <Styles.SectionContainer>
        <Styles.ButtonsContainer>
          <div>
            <BackButton  onClick={handleCancelPrescription}>
              CANCELAR
            </BackButton>
          </div>
          <div>
            {template ? (
              <Button
                background="primary"
                isOutlined
                onClick={() =>
                  updateTemplate(
                    prescription.templateName,
                    prescription.recomendations,
                    fromPrescriptionToApiProducts(prescription.products, logicalOperatorsBetweenProducts)
                  )
                }
              >
                ALTERAR MODELO
              </Button>
            ) : (
              <BackButton  onClick={() => setIsSaveTemplateModalOpened(true)}>
                SALVAR COMO MODELO
              </BackButton>
            )}
            <BackButton onClick={() => setStep(1)}>
              Ver seleção de produtos
            </BackButton>

            <Button background="primary" onClick={() => handleFinishPrescriptionRequest()}>
              Assinar
            </Button>
          </div>
        </Styles.ButtonsContainer>
      </Styles.SectionContainer>

      <SaveTemplateModal
        isOpen={isSaveTemplateModalOpened}
        onRequestClose={() => setIsSaveTemplateModalOpened(false)}
        prescriptionProducts={prescription.products}
        logicalOperators={logicalOperatorsBetweenProducts}
        recommendation={prescription.recomendations}
        saveTemplate={saveTemplate}
      />

      <DigitalSignatureModal
        onRequestClose={() => setModalSignature(false)}
        isOpen={modalSignature}
        handleSignature={() => handleRecipeSignature(withDigitalSignature)}
        handleAnotherSignature={handleAnotherSignature}
      />

      <ConfirmationDialogue
        isOpened={isErrorDialogueOpen}
        onRequestClose={() => setIsErrorDialogueOpen(false)}
        onConfirmationClick={() => setIsErrorDialogueOpen(false)}
        title="É necessário informar a quantidade e posologia"
        description="Por favor, verifique sua prescrição."
        confirmationButtonText="Entendi!"
        confirmationOnlyDialogue
      />

      <ConfirmationDialogue
        isOpened={isPatientErrorDialogueOpen}
        onRequestClose={handleClosePatientErrorDialog}
        onConfirmationClick={handleClosePatientErrorDialog}
        title="É necessário informar os dados do paciente"
        description="Por favor, informe os dados do paciente antes de continuar."
        confirmationButtonText="Entendi!"
        confirmationOnlyDialogue
      />

      <ConfirmationDialogue
        isOpened={isSignatureErrorDialogOpen}
        onRequestClose={() => setIsSignatureErrorDialogOpen(false)}
        onConfirmationClick={() => setIsSignatureErrorDialogOpen(false)}
        title="Houve um erro ao fazer a emissão da receita!"
        description="A receita não pôde ser emitida devido a um erro interno. Você pode tentar novamente agora ou mais tarde."
        confirmationButtonText="Entendi!"
        confirmationOnlyDialogue
      />
    </SectionsWrapper>
  )
}
