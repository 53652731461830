import { useScheduling } from 'hooks/useScheduling'
import When from 'utils/when'
import LoginForm from './LoginForm'
import SimplifiedRegisterForm from './SimplifiedRegisterForm'
import { ActionButton, Container, FormHeader } from './styles'
import { BackButton } from '@cannect/pages/AppointmentScheduling/styles'
import { FaArrowLeft } from 'react-icons/fa'

export default function IdentificationStep() {
  const { checkoutFormState, setCheckoutFormState } = useScheduling()

  return (
    <Container>
      <FormHeader>
        <h3>Dados do paciente</h3>
        <When expr={checkoutFormState === 'simplified'}>
            <ActionButton className="login-button" type="button" onClick={() => setCheckoutFormState('login')}>
              Entrar
            </ActionButton>
        </When>
        <When expr={checkoutFormState === 'login'}>
           <ActionButton className="login-button" type="button" onClick={() => setCheckoutFormState('simplified')}>
                Cadastre-se
            </ActionButton>
        </When>
      </FormHeader>
      {checkoutFormState === 'simplified' && <SimplifiedRegisterForm />}
      {checkoutFormState === 'login' && <LoginForm />}
    </Container>
  )
}
