import RadioInput from 'components/RadioInput'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import Section from 'pages/CannectAssistant/components/SectionsWrapper/Section'
import * as NeedHelpStyle from '../../NeedHelp/styles'

export default function CbgForm() {
  const { canContainOtherCannabinoid, setCanContainOtherCannabinoid, setIsChosenQuestionsFinished } =
    useCannectAssistant()

  function handleLastQuestionAnswer(newValue: boolean) {
    setCanContainOtherCannabinoid(newValue)
    setIsChosenQuestionsFinished(true)
  }

  return (
    <Section>
      <h2>Pode conter outros canabinoides?</h2>
      <NeedHelpStyle.InputsWrapper>
        <RadioInput
          name="canContainOtherCannabinoid-yes"
          id="canContainOtherCannabinoid-yes"
          label="Sim"
          checked={canContainOtherCannabinoid === true}
          onChange={() => handleLastQuestionAnswer(true)}
        />
        <RadioInput
          name="canContainOtherCannabinoid-no"
          id="canContainOtherCannabinoid-no"
          label="Não"
          checked={canContainOtherCannabinoid === false}
          onChange={() => handleLastQuestionAnswer(false)}
        />
      </NeedHelpStyle.InputsWrapper>
    </Section>
  )
}
