import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space4};
`

const ContainerBase = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FilterContainer = styled(ContainerBase)`
  @media (max-width: 900px) {
    gap: ${temporaryTheme.spacing.space3};
    flex-direction: column-reverse;
  }
`

export const SearchBox = styled.div`
  width: 408px;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const SelectActionBox = styled.div`
  display: flex;
  width: calc(100% - 408px);
  align-items: center;
  justify-content: flex-end;
  gap: ${temporaryTheme.spacing.space3};

  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
`

export const DetailsContainer = styled(ContainerBase)`
  @media (max-width: 900px) {
    flex-direction: column;
    gap: ${temporaryTheme.spacing.space3};
  }
`

export const NotSearchText = styled.p`
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 15px;
  color: #858585;
`

export const LinkCustom = styled.a`
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 15px;

  color: ${temporaryTheme.colors.link};

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`

export const ResultsText = styled.div`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  color: #777777;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const FilterModalBox = styled.div`
  display: none;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    width: 170px;
    height: 33px;
    min-height: unset;
    border: 1.61812px solid #d9d9d9;
    border-radius: 5px;
    font-family: 'Spartan', sans-serif;
    font-weight: 400;
    font-size: 12px;
    gap: 8px;
  }
  @media (max-width: 900px) {
    display: block;
  }
`

export const FilterModalIcon = styled.img``
