import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const QuestionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  margin-top: 5rem;
  width: 100%;

  h2 {
    font-size: 1.25rem;
    font-weight: 300;

    @media ${MOBILE_WIDTH} {
      text-align: center;
    }
  }
`
export const QuestionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;

  @media ${MOBILE_WIDTH} {
    flex-wrap: wrap;
    justify-content: center;
  }
`
