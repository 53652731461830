import { theme } from 'design-cannect'
import styled from 'styled-components'

export const BaseRecipeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  overflow: auto;
  padding: 0.8rem;
  position: relative;

  //min-height: 100vh;
  width: 100%;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  margin-top: 130px;
  margin-bottom: 220px;

  max-width: 920px;
`

export const SecondHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    height: 48px;
    padding: 0 1rem;
    border-radius: 0.5rem;

    border: 2px solid ${theme.colors.tertiary};
    color: ${theme.colors.tertiary};
    font-weight: 700;

    line-height: 48px;
  }
`

export const RecipesWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  width: 100%;
  margin-top: 1.5rem;

  hr {
    width: 100%;
    height: 0px;
    border: none;
    border-bottom: 1px solid ${theme.colors.primary};
  }
`
