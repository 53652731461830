import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const EducateAsideContainer = styled.aside<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  /* width: 100%; */
  /* justify-content: center; */
  /* max-width: 100%; */

  .header {
    display: flex;
    flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
    .banner {
      display: flex;
      flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
      align-items: ${({ isDesktop }) => (isDesktop ? 'center' : 'flex-start')};
      justify-content: center;
      gap: 1rem;
      width: ${({ isDesktop }) => (isDesktop ? '50%' : '100%')};
      margin: 1rem 0;
      p {
        width: 80%;
      }
    }
    .drCannabis {
      margin-left: ${({ isDesktop }) => (isDesktop ? '1rem' : '0')};
      display: flex;
      flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
      align-items: center;
      background: #3b42d1;
      padding: 32px;
      color: #fff;
      .textBt {
        width: ${({ isDesktop }) => (isDesktop ? '50%' : '100%')};
        a {
          text-decoration: none;
        }
        button {
          font-weight: 700;
          font-size: 14px;
        }
      }
      .logo {
        width: ${({ isDesktop }) => (isDesktop ? '50%' : '100%')};
      }
    }
  }

  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* width: 100%; */
  }

  p {
    font-weight: 300;
  }

  @media ${MOBILE_WIDTH} {
    max-width: 100%;
  }
`
