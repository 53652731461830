import { theme } from 'design-cannect'
import styled from 'styled-components'

export const EpisodesSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Counter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 85px;
  min-width: 85px;
  height: 100px;
  min-height: 100px;

  border: 2px solid ${theme.colors.tertiary};
  border-radius: 0.5rem;

  font-size: 1.1rem;
  font-weight: 300;
  color: ${theme.colors.tertiary};
`
