import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const ProfessionalAddressesContainer = styled.main`
  display: flex;
  flex-direction: column;

  width: 100%;
  background: #f8f8f8;
  padding: 2rem;

  border-radius: 0.5rem;
  height: fit-content;
`

export const AllAddressesWrapper = styled.div`
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const NewAddressButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 15rem;
  border: 1px solid #e0e7ec;

  border-radius: 0.5rem;
`

export const AddressesSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-size: 1.75rem;
    color: #3d3d3d;
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`
