import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: ${temporaryTheme.colors.darkGrey};
  margin-bottom: 52px;
`

export const Box = styled.div`
  width: 100%;
  background-color: #f8f7fc;
  border-radius: 8px;
  padding: 32px 42px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media ${MOBILE_WIDTH} {
    padding: 12px;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .cupom-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 30px;
    position: relative;
    * {
      font-family: 'Nunito', sans-serif;
    }

    label {
      text-align: end;
    }

    span {
      position: absolute;
      left: -8px;
      bottom: -20px;
    }
  }
`

export const Header = styled.h1`
  font-size: 28px;
  font-weight: 500;
  font-family: 'Spartan', sans-serif;
  color: ${temporaryTheme.colors.sitePrimary};

  @media ${MOBILE_WIDTH} {
    margin-top: 12px;
  }
`

export const BoxTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: #3d3d3d;
  font-family: 'Spartan', sans-serif;
  margin-bottom: 12px;

  @media ${MOBILE_WIDTH} {
    font-size: 15px;
  }
`
export const TextDetail = styled.div<{ fontSize?: string; color?: string }>`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    font-weight: 600;
    color: ${({ color }) => color || '#3d3d3d'};
  }
  span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    color: ${({ color }) => color || '#3d3d3d'};
  }
  * {
    font-family: 'Poppins', sans-serif;
    font-size: ${({ fontSize }) => fontSize || '22px'};
  }

  &.professional_name {
    font-size: 18px;
  }

  @media ${MOBILE_WIDTH} {
    * {
      font-size: 14px;
    }
  }
`

export const WhiteBox = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 32px 42px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 16px 0;
  min-height: 230px;

  @media ${MOBILE_WIDTH} {
    justify-content: unset;
    padding: 12px;
    flex-direction: column;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const FullContent = styled(Content)``
export const BasicContent = styled(Content)`
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    justify-content: unset;
  }
`
