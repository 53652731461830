import useMediaQuery from 'hooks/useMediaQuery'
import useQueryString from 'hooks/useQueryString'
import { MOBILE_WIDTH } from 'utils/constants'
import AccountSidePanel from './components/template/AccountSidePanel'
import BackToPanelLink from './components/template/BackToPanelLink'
import * as Styles from './styles'
import PageToRender from './components/template/PageToRender'

function Account() {
  const { filters } = useQueryString()
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Styles.AccountContainer>
      <BackToPanelLink page={filters.pagina} />
      <Styles.AccountContent>
        {!isMobile && <AccountSidePanel />}
        <PageToRender page={filters.pagina} />
      </Styles.AccountContent>
    </Styles.AccountContainer>
  )
}

export default Account
