import { theme } from 'design-cannect'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  overflow: hidden;

  padding: 2rem;
  padding-bottom: 100px;
`

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const CenterHero = styled.div<any>`
  max-width: ${({ isDesktop }) => (isDesktop ? '50%' : '100%')};
  margin: 0 auto;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  gap: 32px;
`

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;

  a.access-article {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    text-decoration: none;
    font-size: 1rem;

    height: 48px;
    width: 210px;
    background: ${theme.colors.tertiary};

    color: white;
    border-radius: 0.25rem;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 5%);
    }
  }

  p.error-message {
    font-weight: 300;
    font-size: 1.1rem;
    margin-top: -1rem;
  }

  p.course-description {
    font-weight: 300;
  }

  h2.module-title {
    color: ${theme.colors.primary};
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`

export const FiltersWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  margin-top: 1.5rem;
`

export const AsideInputsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

  width: 100%;

  input {
    margin-top: 1rem;
    margin-right: 1rem;
  }
`

export const TeachersContainer = styled.section`
  display: flex;
  gap: 0.7rem;
  flex-wrap: wrap;

  width: 100%;
  margin-top: 5rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
`

export const FiltersCardsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  margin-top: 0.5rem;
`
