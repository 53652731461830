import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  padding: 0 ${temporaryTheme.spacing.space4};
`

export const ChartBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
`

export const ChatEcosystemImage = styled.img`
  width: 60%;
  object-fit: cover;

  @media (${MOBILE_WIDTH}) {
    width: 100%;
  }
`

export const ListBox = styled.ul`
  width: 100%;
  padding-left: ${temporaryTheme.spacing.space3};
`
export const ListItem = styled.li``

export const ActionContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${temporaryTheme.spacing.space4};
  margin-bottom: 60px;
  margin-top: 68px;
`
