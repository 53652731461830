import styled from 'styled-components'

export const RadioInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: #3d3d3d;
  font-weight: 300;

  input {
    display: none;
  }

  label {
    cursor: pointer;
  }
`

export const VisualInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #3d3d3d;
  border-radius: 4px;

  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;

  cursor: pointer;
  margin-top: -3px;
`

export const Circle = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;

  border-radius: 999px;
  background: #3d3d3d;
`
