import { useEffect } from 'react'
import { useForm, useWatch, useFormState } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useConfirmProofAddressDocumentMutation } from '@cannect/services/resources/digitalJourney'
import { zodResolver } from '@hookform/resolvers/zod'
import { DIGITAL_JOURNEY_ROUTES, FORM_SCHEMAS } from '../../constants'
import { TDigitalJourneyFormData } from '../../types'
import { useAddressByCep } from '@cannect/services/resources/address/hooks'

type TFormFields = TDigitalJourneyFormData['confirm_proof_of_address_document']

export const useConfirmProofOfAddressStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()

  const confirmProofOfAddressForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.confirm_proof_of_address_document
    },
    resolver: zodResolver(FORM_SCHEMAS.confirm_proof_of_address_document)
  })

  const confirmProofOfAddressDocumentMutation = useConfirmProofAddressDocumentMutation()
  const { handleSubmit, control, setValue } = confirmProofOfAddressForm

  const { touchedFields, isSubmitting } = useFormState({ control })
  const watchedFields = useWatch({ control })
  const cepWatch = watchedFields.address_cep?.replace(/\D/g, '') || ''

  const cepHasChanged = !!touchedFields.address_cep

  const {
    data: addressData,
    isLoading: isLoadingAddress,
    isError: isErrorAddress
  } = useAddressByCep(cepHasChanged ? cepWatch : '')

  useEffect(() => {
    if (isErrorAddress) {
      setValue('address_city', '')
      setValue('address_neighborhood', '')
      setValue('address_street', '')
      setValue('address_state', '')
      return
    }
    if (addressData) {
      setValue('address_city', addressData.localidade)
      setValue('address_neighborhood', addressData.bairro)
      setValue('address_street', addressData.logradouro)
      setValue('address_state', addressData.uf)
    }
  }, [addressData, isErrorAddress])

  useEffect(() => {
    updateFormData({
      confirm_proof_of_address_document: {
        address_cep: watchedFields.address_cep || '',
        address_number: watchedFields.address_number || '',
        address_city: watchedFields.address_city || '',
        address_neighborhood: watchedFields.address_neighborhood || '',
        address_street: watchedFields.address_street || '',
        address_state: watchedFields.address_state || '',
        address_holder_cpf: watchedFields.address_holder_cpf || '',
        address_holder_full_name: watchedFields.address_holder_full_name || '',
        address_complement: watchedFields.address_complement || ''
      }
    })
  }, [watchedFields])

  const onSubmit = (data: TFormFields) => {
    if (!formData.common_fields?.digital_journey_id) return

    // eslint-disable-next-line consistent-return
    return confirmProofOfAddressDocumentMutation.mutateAsync(
      {
        digital_journey_id: formData.common_fields?.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            confirm_proof_of_address_document: {
              ...data
            }
          })
          if (response?.next_step) {
            history.push(DIGITAL_JOURNEY_ROUTES.order_summary)
          }
        }
      }
    )
  }

  return {
    confirmProofOfAddressForm,
    onSubmit,
    control,
    isSubmitting,
    handleSubmit,
    isLoadingAddress
  }
}
