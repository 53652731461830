import * as Style from './styles'

export default function CantUncheckWarning() {
  return (
    <Style.CantUncheckWarningContainer>
      <p>
        Os sintomas reportados na primeira consulta com a enfermeira são obrigatórios. Caso não sinta mais, coloque a
        intensidade ou quantidade como 0 na próxima página.
      </p>
    </Style.CantUncheckWarningContainer>
  )
}
