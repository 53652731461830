import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { Dialog, Drawer } from '@cannect/new-components/atoms'
import { ComponentProps, ReactNode } from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'

interface BaseProps {
  children: ReactNode
}

interface RootDrawerDialogProps extends BaseProps {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  drawerProps?: ComponentProps<typeof DrawerPrimitive.Root>
  dialogProps?: ComponentProps<typeof Dialog.Root>
}

interface DrawerDialogProps extends BaseProps {
  className?: string
  asChild?: true
}

const desktop = '(min-width: 768px)'

const Root = ({ children, dialogProps, drawerProps, ...props }: RootDrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Root = isDesktop ? Dialog.Root : Drawer.Root

  return (
    <Root {...props} {...(isDesktop ? dialogProps : drawerProps)}>
      {children}
    </Root>
  )
}

const Trigger = ({ className, children, ...props }: DrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Trigger = isDesktop ? Dialog.Trigger : Drawer.Trigger

  return (
    <Trigger className={className} {...props}>
      {children}
    </Trigger>
  )
}

const Close = ({ className, children, ...props }: DrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Close = isDesktop ? Dialog.Close : Drawer.Close

  return (
    <Close className={className} {...props}>
      {children}
    </Close>
  )
}

const Content = ({ className, children, ...props }: DrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Content = isDesktop ? Dialog.Content : Drawer.Content

  return (
    <Content className={className} {...props}>
      {children}
    </Content>
  )
}

const Description = ({ className, children, ...props }: DrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Description = isDesktop ? Dialog.Description : Drawer.Description

  return (
    <Description className={className} {...props}>
      {children}
    </Description>
  )
}

const Header = ({ className, children, ...props }: DrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Header = isDesktop ? Dialog.Header : Drawer.Header

  return (
    <Header className={className} {...props}>
      {children}
    </Header>
  )
}

const Title = ({ className, children, ...props }: DrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Title = isDesktop ? Dialog.Title : Drawer.Title

  return (
    <Title className={className} {...props}>
      {children}
    </Title>
  )
}

const Footer = ({ className, children, ...props }: DrawerDialogProps) => {
  const isDesktop = useMediaQuery(desktop)
  const Footer = isDesktop ? Dialog.Footer : Drawer.Footer

  return (
    <Footer className={className} {...props}>
      {children}
    </Footer>
  )
}

export { Root, Trigger, Close, Content, Description, Header, Title, Footer }
