import FirstSession from 'components/QuemSomosComponents/FirstSession'
import SecondSession from 'components/QuemSomosComponents/SecondSession'
import ThirdSession from 'components/QuemSomosComponents/ThirdSession'
import * as Style from './style'

function QuemSomos() {
  return (
    <Style.Container>
      <FirstSession />
      <SecondSession />
      <ThirdSession />
    </Style.Container>
  )
}

export default QuemSomos
