import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const ImageBox = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  background: ${temporaryTheme.colors.lightGrey};
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
`
export const Content = styled.div`
  width: 100%;
  height: calc(100% - 200px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`
export const Title = styled.p`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 25px;

  width: 50%;
`
export const IconBox = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${temporaryTheme.colors.darkGrey};
  transition: 0.3s;
  cursor: pointer;
  svg {
    color: ${temporaryTheme.colors.white};
    width: 36px;
  }

  &:hover {
    background: ${temporaryTheme.colors.white};
    svg {
      color: ${temporaryTheme.colors.sitePrimary};
    }
  }
`

export const Wrapper = styled.main`
  width: 318px;
  height: 310px;
  background: linear-gradient(0deg, #fafafa, #fafafa), #d9d9d9;
  border-radius: 20px;
  overflow: hidden;

  &:hover {
    ${Image} {
      transform: scale(1.2);
    }
  }

  @media ${MOBILE_WIDTH} {
    min-width: 318px;
    width: 318px;
    padding: 12px 0;
  }
`
