import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  padding: 0 ${temporaryTheme.spacing.space4};
`

export const SectionBase = styled.div`
  padding: ${temporaryTheme.spacing.space5} 0;

  &.firstSection {
    padding-top: 0;
  }
`

export const SectionRelatedProducts = styled.div`
  padding-top: ${temporaryTheme.spacing.space7};
  padding-bottom: ${temporaryTheme.spacing.space8};
`

export const ListProductSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: ${temporaryTheme.spacing.space3};
  @media ${MOBILE_WIDTH} {
    justify-content: center;
  }
`

export const CategoryNavigatorSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  overflow-x: auto;
`
