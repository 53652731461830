import styled from 'styled-components'

export const Wrapper = styled.main`
  height: 600px;
  margin-top: 20px;
  overflow-y: auto;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 350px;
`
export const ActionsBox = styled.div``
