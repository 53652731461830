import MyPatientsFilters from './components/MyPatientsFilters'
import MyPatientsHeader from './components/MyPatientsHeader'
import { useQuery } from '@tanstack/react-query'
import { fetchUserPatients } from './services'
import { toast } from 'react-toastify'
import MyPatientsList from './components/MyPatientsList'
import useQueryString from 'hooks/useQueryString'

const MyPatients = () => {
  const { filters } = useQueryString()

  const { data, isFetching, isError } = useQuery({
    queryKey: ['patients', filters.page, filters.search],
    queryFn: () =>
      fetchUserPatients({ page: Number(filters.page ?? 1), search: filters.search || '' }).then((res) => ({
        ...res,
        patients: {
          ...res.patients,
          rows: res.patients.rows.map((patient) => ({
            ...patient,
            binding_date: patient.binding_date ? new Date(patient.binding_date).toLocaleDateString() : '-'
          }))
        }
      }))
  })

  if (isError) {
    toast.error('Erro ao buscar pacientes. Tente novamente mais tarde.')
  }

  return (
    <div className="flex w-full flex-col gap-4 overflow-hidden rounded bg-white p-6">
      <MyPatientsHeader />
      <MyPatientsFilters totalResults={data?.patients?.count ?? 0} />
      <MyPatientsList data={data} isLoading={isFetching} />
    </div>
  )
}

export default MyPatients
