import { svgLogo } from 'assets/svg'
import * as Style from './styles'

export default function Header() {
  return (
    <Style.HeaderContainer>
      <img src={svgLogo} alt="Cannect" />
    </Style.HeaderContainer>
  )
}
