import styled from 'styled-components'

export const TimeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;

  div:first-child {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 900px) {
    gap: 12px;
    margin-top: 32px;
  }
`

export const Time = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  padding: 8px 0;
  font-family: 'Poppins', sans-serif;
  color: #3d3d3d;
  background-color: #f8f7fc;
  border: 1px solid #898483;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;

  @media screen and (max-width: 900px) {
    width: 66px;
    border: 1px solid #898483;
    font-size: 12px;
  }
`
