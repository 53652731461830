import React, { useEffect, useState, useCallback } from 'react'
import CustomInput from 'components/CustomInput'
import * as Styles from '../../styles'
import { Select } from 'antd'

const { Option } = Select

export interface CreditCard {
  card_name?: string
  card_number?: string
  card_month_validate?: string
  card_year_validate?: string
  card_cvv: string
  installments?: number
  birth_date?: string
  cpf?: string
}

export type CreditCardFields =
  | 'card_name'
  | 'card_number'
  | 'card_month_validate'
  | 'card_year_validate'
  | 'card_cvv'
  | 'installments'
  | 'birth_date'
  | 'cpf'

export function CreditCardMethod({ creditAmount, creditCard, setCreditCard }: any) {
  const [installments, setInstallments] = useState([])
  const [cardValidate, setCardValidate] = useState('')

  useEffect(() => {
    const calculateInstallments = () => {
      const results = []
      for (let i = 1; i <= 2; i++) {
        results.push({
          id: i,
          installment: `${i}x`,
          amount: (creditAmount / i).toFixed(2),
          fees: '(sem juros)',
          total: creditAmount.toFixed(2)
        })
      }
      setInstallments(results)
    }

    if (creditAmount) {
      calculateInstallments()
    }
  }, [creditAmount])

  function changeCreditCardInfo(fieldToChange: CreditCardFields, newValue: string) {
    setCreditCard({
      ...creditCard,
      [fieldToChange]: newValue
    })
  }

  const handleValidDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '')
      if (value.length > 6) {
        value = value.slice(0, 6)
      }

      let formattedValue = value
      if (value.length >= 2) {
        formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`
      }

      setCardValidate(formattedValue)

      if (value.length === 6) {
        const month = value.substring(0, 2)
        const year = value.substring(2, 6)
        changeCreditCardInfo('card_month_validate', month)
        changeCreditCardInfo('card_year_validate', year)
      }
    },
    [changeCreditCardInfo]
  )

  const handleCreditCardChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '') // Remove non-digit characters

      // Ensure the length does not exceed 16 digits
      if (value.length > 16) {
        value = value.slice(0, 16)
      }

      // Group digits into blocks of 4 separated by spaces
      let formattedValue = value.match(/.{1,4}/g)?.join(' ') || value

      changeCreditCardInfo('card_number', formattedValue)
    },
    [changeCreditCardInfo]
  )

  const handleCardHolderNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = e.target

      // Allow only alphabetic characters, spaces, hyphens, and apostrophes
      value = value.replace(/[^a-zA-Z\s'-]/g, '')

      // Capitalize the first letter of each word
      value = value.replace(/\b\w/g, char => char.toUpperCase())

      changeCreditCardInfo('card_name', value)
    },
    [changeCreditCardInfo]
  )

  return (
    <>
      <Styles.Row>
        <CustomInput
          maxLength={19}
          type="credit-card"
          outlined
          name="payment.card_number"
          label="N° do cartão"
          placeholder="Digite o número do cartão"
          value={creditCard.card_number}
          onChange={handleCreditCardChange}
        />
      </Styles.Row>
      <Styles.Row>
        <CustomInput
          outlined
          name="payment.card_holder_name"
          label="Nome no cartão"
          placeholder="Nome que consta no cartão"
          value={creditCard.card_name}
          onChange={handleCardHolderNameChange}
        />
      </Styles.Row>
      <Styles.Row>
        <CustomInput
          type="text"
          outlined
          name="payment.card_validate"
          label="Validade"
          placeholder="mm/aaaa"
          maxLength={7}
          value={cardValidate}
          onChange={handleValidDateChange}
        />
        <CustomInput
          placeholder="***"
          type="number"
          outlined
          name="payment.card_cvv"
          label="CVV"
          maxLength={4}
          value={creditCard.card_cvv}
          onChange={e => changeCreditCardInfo('card_cvv', e.target.value)}
        />
      </Styles.Row>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
        <span>Parcelamento</span>

        <Select
          placeholder="Selecione a quantidade de parcelas."
          size="large"
          virtual={false}
          defaultValue={installments.length ? installments[0].id : undefined}
          onChange={installment => changeCreditCardInfo('installments', installment)}
        >
          {installments.map((installment: any) => (
            <Option key={installment.id} value={installment.id}>
              <Styles.OptionInstallment>
                <p className="values_installment">{`${installment.installment} de ${installment.amount}`}</p>
                {installment.fees === '(sem juros)' ? (
                  <p className="total_installment not_installment">Sem juros</p>
                ) : (
                  <p className="total_installment">{installment.total}</p>
                )}
              </Styles.OptionInstallment>
            </Option>
          ))}
        </Select>
      </div>
    </>
  )
}

export default CreditCardMethod
