import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  width: 100%;
  .page {
    margin: 24px 0;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0;
  @media ${MOBILE_WIDTH} {
    margin-bottom: 30px;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  * {
    font-family: 'Poppins', sans-serif;
  }
  @media ${MOBILE_WIDTH} {
    align-items: flex-start;
  }
`

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;
`

export const Text = styled.p`
  font-size: 16px;
  color: ${temporaryTheme.colors.darkGrey};
  font-weight: 400;
`

export const EmptyPrescriberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;

  font-family: 'Nunito', sans-serif;
`
