import Footer from 'components/BaseRecipeComponents/Footer'
import Header from 'components/BaseRecipeComponents/Header'
import InvisibleButton from 'components/InvisibleButton'
import { BaseRecipePathParams } from 'pages/BaseRecipe'
import { FiChevronLeft } from 'react-icons/fi'
import { useParams, useHistory } from 'react-router-dom'
import { generateRandomNumber } from 'utils/randomNumberGenerator'
import Question from './Question'
import * as Style from './styles'

const QUESTIONS = [
  {
    title: 'Como eu faço para comprar produtos à base de Cannabis?',
    answers: [
      `Primeiro, você deve se consultar com algum médico que saiba prescrever Cannabis medicinal.
    Este médico será responsável por confirmar se existe a indicação de uso para seu caso.
    Em caso positivo, ele irá elaborar uma prescrição detalhada, determinando o tipo de produto,
    a marca, a concentração, a dosagem e quantidade de frascos específicas para você.`,
      `Em posse desta prescrição, basta entrar em contato com o nosso atendimento que coletamos
    os documentos necessários para dar entrada na solicitação junto à ANVISA, via Plataforma
    de Serviços do Governo Federal. Atualmente, o prazo de emissão da autorização de importação
    está entre 10 e 15 dias.`,
      `O envio do produto pelo fornecedor é feito após a liberação da autorização. Após uma
    checagem pela ANVISA na entrada do país, o produto está apto a seguir até sua casa.
    Quando receber seus medicamentos, entre em contato com o médico prescritor para
    continuar o tratamento, ajustar dosagem e verificar os efeitos do tratamento.`
    ]
  },
  {
    title: 'Como eu consigo a autorização da ANVISA?',
    answers: [
      `Para liberação da importação de produtos à base de Cannabis é necessário pedir autorização à
      ANVISA. Você pode fazer esse processo de autorização a ANVISA via plataforma de serviços do
      governo federal, ou diretamente com a Cannect. É necessário alguns documentos: o RG, a receita
      branca do medicamento, um comprovante de residência no nome do paciente (no caso de dependentes
        , é necessário certificado de filiação, como certidão de casamento ou certidão de nascimento).
        Atualmente, o prazo de emissão da autorização de importação está entre 10 e 15 dias.`
    ]
  },
  {
    title: 'O que é uma receita digital?',
    answers: [
      `A receita digital tem a mesma validade que uma receita em papel. Caso você receba uma receita
      digital, não é necessário imprimi-la para comprar seus produtos.Você pode comprar online pela
      Cannect, ou em uma farmácia física, basta informar o token de sua receita (que inicia com CAN)
      ao farmacêutico.`
    ]
  },
  {
    title: 'Onde recebo minha receita digital?',
    answers: [
      `Você receberá sua receita digital via SMS, a mensagem chegará com o remetente Cannect. Seu
      médico pode também compartilhar com você sua receita via whatsapp, email ou por um link igual
      ao que você recebe no SMS.`
    ]
  },
  {
    title: 'Como eu baixo o PDF?',
    answers: [
      `Para baixar o PDF é simples! Basta clicar no botão "Baixar PDF" e você verá um campo para inserir
      a senha de download. A senha é composta pelos 4 últimos números do número de celular do paciente.
      Ao inserir a senha corretamente, você será direcionado ao arquivo PDF.`
    ]
  },
  {
    title: 'E se eu tiver uma receita controlada (Azul ou Amarela)?',
    answers: [
      `Caso você possua uma receita de medicamento controlado, pode adquirir seu produto sem necessidade
      de autorização da ANVISA.`
    ]
  }
]

export default function BaseRecipeHelp() {
  const { token } = useParams<BaseRecipePathParams>()
  const history = useHistory()

  return (
    <Style.BaseRecipeHelpContainer>
      <Header />
      <Style.QuestionsWrapper>
        <InvisibleButton onClick={() => history.push(`/receitas/${token}`)}>
          <FiChevronLeft size={25} />
        </InvisibleButton>

        {QUESTIONS.map(question => (
          <Question key={generateRandomNumber()} title={question.title} answers={question.answers} />
        ))}
      </Style.QuestionsWrapper>
      <Footer />
    </Style.BaseRecipeHelpContainer>
  )
}
