import PatientDetailsHeader from './components/PatientDetailsHeader'
import PatientPersonalData from './components/PatientPersonalData'
import PatientHistory from './components/PatientHistory'

export interface PatientDetailsProps {
  patientId: number
}

const PatientDetails = ({ patientId }: PatientDetailsProps) => {
  return (
    <div className="flex w-full flex-col gap-4 overflow-hidden rounded bg-white p-6">
      <PatientDetailsHeader />
      <PatientPersonalData patientId={patientId} />
      <PatientHistory patientId={patientId} />
    </div>
  )
}

export default PatientDetails
