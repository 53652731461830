import styled from 'styled-components'
import { theme } from 'design-cannect'

interface ItemProps {
  isSelected?: boolean
  isMobile?: boolean
}

export const Container = styled.div<ItemProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: center;

  margin-bottom: 8px;
  margin-top: 8px;

  .product {
    display: flex;
    flex-direction: row;

    padding-left: 20px;

    width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  }

  .product-properties {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};

    padding-top: 15px;
    padding-left: 10px;
    padding-right: 35px;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .align-item {
    padding-top: 15px;
    min-width: 78.5px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;

  padding-top: 10px;
  width: 85px;
  height: 85px;
`

export const DescriptionWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;

  text-align: start;
  margin-top: ${theme.spacing.space3};
  padding-left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`

export const RefButton = styled.a`
  color: black;

  border: none;
  background: none;
  text-decoration: underline;
  font-weight: bold;
  font-size: 12px;

  padding-top: 5px;
`
export const AddOrRemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 40px;
  min-height: 50px;
  min-width: 40px;

  border-radius: 2px;
  border: 1px solid #115850;

  margin-top: 5px;
  padding-bottom: 6px;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`
export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 50px;

  border: 1px solid #115850;
  border-radius: 2px;

  margin: 5px 5px 0px 5px;
  width: 40px;
`
