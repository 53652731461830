import qs from 'query-string'

export const removableFiltersKeys = { status: 0, search: '' }

export const removeKeyValuePairsByOmitObject = (params: Record<string, any>, omitKeyValueList: Record<string, any>) =>
  Object.keys(params).reduce((acc, key: string) => {
    const omitParam = omitKeyValueList[key] === params[key] || params[key] === null
    return omitParam ? acc : { ...acc, [key]: params[key] }
  }, {})

export const normalizeFilters = (queryString?: string) => {
  if (!queryString || queryString.length === 0) return {}
  const filters = qs.parse(queryString)

  return filters
}
