import { svgWhitepapers } from 'assets/svg'
import { logoCannectCiencia } from 'assets/img'
import EducateAside from 'components/CannectEducate/EducateAside'
import CienciaNavigator from 'components/CannectCienciaComponents/CienciaNavigator'
import SocialMedia from 'components/SocialMedia'
import TabContent, { Post } from 'components/CannectEducate/TabContent'
import TabContentTitle from 'components/CannectEducate/TabContentTitle'
import TabsNavigator from 'components/TabsNavigator'
import { useEffect, useState } from 'react'
import api from 'services/api'
import * as Style from 'styles/cannectEducate'
import Loading from 'components/Loading'
import InfiniteScroll from 'react-infinite-scroll-component'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import BreadCrumb from 'components/BreadCrumb'
import Text from '../../components/Text'

export interface Whitepaper {
  image: string | undefined
  createdAt: Date
  description: string
  id: number
  path: string
  status: string
  title: string
  updatedAt: Date
}

export interface FetchWhitepapersResponse {
  whitepapers: {
    count: number
    rows: Whitepaper[]
  }
  success: boolean
}

export function formatWhitepapers(whitepapers: Whitepaper[]): Post[] {
  const newWhitepapers: Post[] = []
  whitepapers.forEach(whitepaper => {
    newWhitepapers.push({
      id: whitepaper.id,
      description: whitepaper.description,
      img: whitepaper.image,
      title: whitepaper.title,
      url: `/whitepapers/${whitepaper.id}`,
      externalUrl: whitepaper.path
    })
  })

  return newWhitepapers
}

// eslint-disable-next-line react/function-component-definition
export default function WhitepapersHome() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<Post[]>([])
  const [postsQuantity, setPostsQuantity] = useState(0)

  useEffect(() => {
    fetchPosts()
  }, [page])

  async function fetchPosts() {
    setLoading(true)
    try {
      const response = await api.get<FetchWhitepapersResponse>(`/whitepapers/${page}?limit=12`)
      setPosts([...posts, ...formatWhitepapers(response.data.whitepapers.rows)])

      if (page === 1) {
        setPostsQuantity(response.data.whitepapers.count)
        setTotalPages(Math.ceil(response.data.whitepapers.count / 12))
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <Style.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Ciência', link: '/cannect-ciencia' },
          { label: 'Whitepapers', link: `/whitepapers` }
        ]}
      />

      <Style.Content>
        <Style.Wrapper>
          <SocialMedia message="Acesse já diversor whitepapers sobre o uso da Cannabis medicinal!" />
          <TabContentTitle
            title="Whitepapers"
            titleImg={svgWhitepapers}
            postsCountText={`${postsQuantity} whitepapers encontrados`}
          />

          <InfiniteScroll
            dataLength={posts.length}
            next={() => setPage(state => state + 1)}
            hasMore={page !== totalPages}
            loader={<div />}
            endMessage={<div />}
          >
            <TabContent posts={posts} actionButtonText="LER" externalRedirect />
          </InfiniteScroll>

          {loading && <Loading loading={loading} />}
        </Style.Wrapper>
      </Style.Content>
    </Style.Container>
  )
}
