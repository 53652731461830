import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import CalendarOnboardingContainer from './CalendarOnboardingContainer'
import FilterByStep from '../AppointmentScheduling/FormSteps/FilterByStep'

const FormWrapper: React.FC = () => {
  const methods = useForm({
    defaultValues: {
      filter: '',
      scheduling_date: null
    }
  })

  return (
    <FormProvider {...methods}>
      <CalendarOnboardingContainer />
      <FilterByStep showFilterForm ={false} />
    </FormProvider>

  )
}

export default FormWrapper
