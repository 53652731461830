import { pngAnonymous } from 'assets/img'
import { Teacher } from 'hooks/useIntrodutoryCourse'
import * as Style from './styles'

interface TeacherBiographyProps {
  teacher: Teacher
}
export default function TeacherBiography({ teacher }: TeacherBiographyProps) {
  return (
    <Style.TeacherBiographyContainer>
      {teacher.photo_url ? (
        <img src={teacher.photo_url} alt="Perfil do professor" />
      ) : (
        <img src={pngAnonymous} alt="Perfil do professor" />
      )}
      <Style.TeacherInformation>
        <h3>{teacher.name}</h3>
        <p>{teacher.biography}</p>
      </Style.TeacherInformation>
    </Style.TeacherBiographyContainer>
  )
}
