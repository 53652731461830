/* eslint-disable react/no-unstable-nested-components */
import React, { cloneElement, ReactElement } from 'react'
import Select, { ClassNamesConfig, GroupBase, Props as ReactSelectProps, Theme } from 'react-select'
import { cn } from '@cannect/lib/utils'
import { IconType } from 'react-icons'
import { tv, VariantProps } from 'tailwind-variants'
import { ImSpinner2 } from 'react-icons/im'

export const selectVariants = tv({
  slots: {
    control: 'w-full rounded border border-solid border-transparent px-4 transition-all duration-200 ease-in-out',
    helperText: 'mt-1 text-left'
  },
  variants: {
    variant: {
      default: {
        control: 'bg-gray-100',
        helperText: 'text-gray-600'
      },
      outlined: {
        control: 'border-gray-300',
        helperText: 'text-gray-600'
      }
    },
    size: {
      default: {
        control: 'h-[45px] text-sm'
      },
      small: {
        control: 'h-8 text-sm'
      },
      medium: {
        control: 'h-10 text-sm'
      }
    },
    isInvalid: {
      true: {
        control: 'border-critical-500',
        helperText: 'text-critical-500'
      }
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
})

export type TOption = {
  value: string | number | boolean
  label: string
  icon?: IconType
  iconColor?: string
  endAdornment?: ReactElement
  options?: TOption[] // child options
  [key: string]: any
}
type CustomClassNames = {
  [K in keyof ClassNamesConfig]?: string
}
export type CustomSelectProps = {
  customClassNames?: CustomClassNames
  isInvalid?: boolean
  message?: string
} & VariantProps<typeof selectVariants> &
  ReactSelectProps<TOption, false, GroupBase<TOption>>

export const CustomSelect = ({
  customClassNames = {},
  variant,
  size,
  className,
  isInvalid,
  placeholder,
  menuPortalTarget,
  isMulti = false,
  formatOptionLabel,
  isDisabled,
  isLoading,
  ...rest
}: CustomSelectProps) => {
  const { control } = selectVariants({ variant, size, isInvalid, className })

  const defaultOptionLabel = (option: TOption) => (
    <div className="flex h-full w-full items-center justify-center">
      {option.icon && <option.icon size={18} color={option.iconColor} className="mr-2" />}
      <span className="flex w-full items-center gap-2">{option.label}</span>
      {option?.endAdornment &&
        cloneElement(option.endAdornment, {
          className: cn('ml-auto text-inherit', option.endAdornment.props.className)
        })}
    </div>
  )

  return (
    <div>
      <Select
        {...rest}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        formatOptionLabel={formatOptionLabel || defaultOptionLabel}
        isMulti={isMulti}
        unstyled
        placeholder={placeholder || 'Selecione'}
        menuShouldScrollIntoView
        menuPortalTarget={menuPortalTarget}
        components={{
          LoadingIndicator: () => {
            return <ImSpinner2 className="animate-spin text-base text-gray-900" />
          }
        }}
        theme={(theme: Theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: 'neutral20',
            primary25: 'neutral20',
            primary: 'neutral20'
          }
        })}
        classNames={{
          dropdownIndicator: () => cn(customClassNames?.dropdownIndicator),
          container: () => cn(customClassNames?.container),
          groupHeading: () => cn('text-link text-lg font-bold', customClassNames?.groupHeading),
          control: (state) => cn(control(), state.isDisabled ? 'opacity-60' : '', customClassNames?.control),
          menu: () => cn('z-[100] bg-white border border-neutral-200 rounded border-solid', customClassNames?.menu),
          menuList: () =>
            cn(
              'p-2 scrollbar-thumb-gray-500 scrollbar-track-transparent scrollbar-rounded',
              customClassNames?.menuList
            ),
          option: (state) =>
            cn(
              'cursor-pointer text-gray-700 rounded transition-all duration-100 ease-in-out p-2 font-medium !text-sm',
              state.isSelected ? 'bg-gray-200' : '',
              'active:bg-gray-100 active:text-gray-900 active:font-semibold',
              'hover:bg-gray-50',
              customClassNames?.option
            ),
          input: () => cn('bg-transparent w-full order-1 text-gray-900 font-medium', customClassNames?.input),
          multiValue: () =>
            cn(
              'h-4 bg-primary-100 rounded-2xl flex items-center gap-3 justify-around px-3.5 py-2.5',
              customClassNames?.multiValue
            ),
          multiValueLabel: () => cn('text-primary-700 text-xs font-semibold', customClassNames?.multiValueLabel),
          multiValueRemove: () => cn('visible text-gray-500', customClassNames?.multiValueRemove),
          placeholder: () => cn('text-gray-400', customClassNames?.placeholder),
          singleValue: (state) =>
            cn('bg-transparent', state.isDisabled ? 'text-gray-400' : 'text-gray-500 ', customClassNames?.singleValue),
          valueContainer: () =>
            cn(
              ' transition-all duration-300 ease-in-out bg-transparent hover:max-h-none hover:rounded space-x-1 space-y-1',
              customClassNames?.valueContainer
            ),
          indicatorsContainer: () => cn('items-center', customClassNames?.indicatorsContainer),
          loadingIndicator: () =>
            cn(
              'border-t-2 border-solid border-gray-400 rounded-full w-6 h-6 animate-spin',
              customClassNames?.loadingIndicator
            )
        }}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
      />
    </div>
  )
}
