import { MedicalDataType } from 'pages/ProductDetails/typeProduct'
import TabComponent from './Tab'
import * as Styles from './styles'

export interface DataSheetProps {
  medicalData: MedicalDataType
  productData?: any
}

export default function DataSheet({ medicalData, productData }: DataSheetProps) {
  return (
    <Styles.Wrapper>
      <TabComponent data={medicalData} product={productData} />
    </Styles.Wrapper>
  )
}
