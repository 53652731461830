import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  color: #5b945b;
  margin-bottom: 2rem;
`

export const NotificationText = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0 6px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: 32px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`

export const LinkNotification = styled.a`
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${temporaryTheme.colors.link};
  margin-left: 3px;
  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`
