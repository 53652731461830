import { useCallback, useMemo } from 'react'

type BaseProduct = {
  product_id: number
  product_quantity: number
}

type TUseCartActions<T extends BaseProduct> = {
  addProduct: (product: T) => T[]
  changeProductQuantity: (product_id: number, quantity: number) => T[]
  removeProduct: (product_id: number) => T[]
  changeProductFlavor: (oldProductId: number, newProductId: number) => T[]
}

export const useCartActions = <T extends BaseProduct>(currentItems: T[]): TUseCartActions<T> => {
  const memoizedItems = useMemo(() => currentItems, [currentItems])

  // Add a new product to the cart or increase quantity if it already exists
  const addProduct = useCallback(
    (product: T): T[] => {
      const existingProduct = memoizedItems.find((item) => item.product_id === product.product_id)
      if (existingProduct) {
        // If the product already exists, increase its quantity
        return memoizedItems.map((item) =>
          item.product_id === product.product_id
            ? { ...item, product_quantity: (item.product_quantity || 0) + 1 }
            : item
        )
      }
      // If it's a new product, add it to the cart
      return [...memoizedItems, product]
    },
    [memoizedItems]
  )

  // Change the quantity of an existing product
  const changeProductQuantity = useCallback(
    (product_id: number, quantity: number): T[] => {
      return memoizedItems.map((product) =>
        product.product_id === product_id ? { ...product, product_quantity: quantity } : product
      )
    },
    [memoizedItems]
  )

  // Remove a product from the cart
  const removeProduct = useCallback(
    (product_id: number): T[] => {
      return memoizedItems.filter((product) => product.product_id !== product_id)
    },
    [memoizedItems]
  )

  // Change the flavor of a product (effectively replacing it with a new product ID)
  const changeProductFlavor = useCallback(
    (oldProductId: number, newProductId: number): T[] => {
      return memoizedItems.map((product) =>
        product.product_id === oldProductId ? { ...product, product_id: newProductId } : product
      )
    },
    [memoizedItems]
  )

  return {
    addProduct,
    changeProductQuantity,
    removeProduct,
    changeProductFlavor
  }
}
