import TagCupom from 'components/TagCupom'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { ResumeOrder } from 'new-components/ResumeOrder'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../utils/constants'
import * as Style from './styles'
import useResume from './useResume'

interface IResume {
  isNotEditing?: boolean
  resumeDetails?: any
}

export function Resume({ isNotEditing = false, resumeDetails }: IResume) {
  const { getDiscount, statusDiscount, couponIsValid, couponValue, changeValueCoupon } = useResume()
  const { lastOrderCreated } = usePrepareCart()
  const orderResume = resumeDetails || lastOrderCreated
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const defaultCouponIsValid = !!orderResume?.coupon?.name

  return (
    <Style.Container isNotEditing={isNotEditing} isMobile={isMobile}>
      <Style.Prices isMobile={isMobile}>
        <Style.Title>Resumo do Pedido</Style.Title>
        {lastOrderCreated?.origin !== 'REENVIO' && (
          <Style.RightContainer isMobile={isMobile}>
            {!isNotEditing && (
              <>
                <div className="cupom">cupom de desconto</div>
                <div className="cupom-container">
                  <div className="input-group">
                    <Style.CupomInput
                      isValid={couponIsValid || defaultCouponIsValid}
                      type="text"
                      value={couponValue}
                      id="couponValue"
                      onChange={(e) => changeValueCoupon(e)}
                    />

                    {statusDiscount && (
                      <div className="status-cupom">
                        <TagCupom variant={couponIsValid ? 'positive' : 'negative'} value={statusDiscount} />
                      </div>
                    )}
                  </div>

                  <Style.ButtonDiscount onClick={() => getDiscount(couponValue)}>Validar</Style.ButtonDiscount>
                </div>
              </>
            )}
          </Style.RightContainer>
        )}
      </Style.Prices>
      <Style.Prices isMobile={false}>
        <ResumeOrder
          items={orderResume?.items}
          discountFreight={orderResume?.shippingDiscount}
          discountProduct={orderResume?.discount}
          freight={orderResume?.shipping}
          reshipping_value={orderResume?.reshipping_payer_value}
          subtotal={orderResume?.subtotal}
          amount={orderResume?.amount}
          originType={orderResume?.origin}
        />
      </Style.Prices>
    </Style.Container>
  )
}
