import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

type ShareButtonContainerProps = {
  disabled: boolean
}
export const ShareButtonContainer = styled.div<ShareButtonContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  opacity: ${props => (props.disabled ? 0.4 : 1)};

  span {
    font-weight: 300;
    font-size: 1.1rem;
  }
`

export const ButtonItself = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;

  border-radius: 10px;
  background: #ecece3;
  border: none;

  transition: transform 0.2s;

  &:not(:disabled):hover {
    transform: scale(1.03);
  }

  &:disabled {
    cursor: not-allowed;
  }

  img,
  svg {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  svg {
    color: ${temporaryTheme.colors.sitePrimary};
  }

  .content-frame {
    display: none;
  }

  ${media.mobileWithTablet`
    width: 100px;
    height: 100px;
    min-width: unset;
    min-height: unset;

    img, svg {
      width: 70px;
      height: 70px;
      min-width: unset;
      min-height: unset;
    }
  `}
`

export const ContentFrame = styled.div`
  width: 800px;
  height: 800px;
`
