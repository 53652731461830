import { theme } from 'design-cannect'
import styled from 'styled-components'

interface ButtonContainerProps {
  outlined?: boolean
  isThinButton?: boolean
}
export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;

  background: ${props => (props.outlined ? 'white' : `${theme.colors.tertiary}`)};
  color: ${props => (props.outlined ? `${theme.colors.tertiary}` : 'white')};
  border: 1px solid ${theme.colors.tertiary};

  height: ${props => (props.isThinButton ? '38px' : '48px')};
  transition: transform 0.2s;
  font-family: 'Spartan', sans-serif;
  font-weight: 500;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`
