import { Symptom, useCannectCare } from 'hooks/useCannectCare'
import { svgCurrentIntensity } from 'assets/svg'
import * as Style from './styles'

interface IntensitySelectorProps {
  symptom: Symptom
  isExistingSymptom: boolean
}

export const selectorsBackgrounds = [
  '#84D7CC',
  '#84D7A0',
  '#85D773',
  '#BDD974',
  '#D8CE74',
  '#D3AC70',
  '#CF926C',
  '#CB6B68',
  '#B95F5C',
  '#A45452',
  '#8F4846'
]

export default function IntensitySelector({ symptom, isExistingSymptom }: IntensitySelectorProps) {
  const { changeSymptomOccurrences } = useCannectCare()

  return (
    <Style.IntensitySelectorContainer>
      <Style.ContentWrapper>
        {Array.from({ length: 11 }, (value, index) => (
          <Style.Label key={index}>{index}</Style.Label>
        ))}
      </Style.ContentWrapper>
      <Style.ContentWrapper>
        {Array.from({ length: 11 }, (value, index) => (
          <Style.Selector
            key={index}
            onClick={() => changeSymptomOccurrences(symptom.id, index, isExistingSymptom)}
            onMouseDown={() => changeSymptomOccurrences(symptom.id, index, isExistingSymptom)}
            background={selectorsBackgrounds[index]}
          >
            {symptom.occurrences === index && <img src={svgCurrentIntensity} alt="•" />}
          </Style.Selector>
        ))}
      </Style.ContentWrapper>
    </Style.IntensitySelectorContainer>
  )
}
