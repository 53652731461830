import { SVGProps } from 'react'

interface ListChecksIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function ListChecksIcon({ isChecked, ...rest }: ListChecksIconProps) {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14.5835 14.9648H24.3153"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5835 7.88672H24.3153"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5835 22.042H24.3153"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6031 6.11719L6.76572 9.65602L4.85254 7.8866"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6031 13.1948L6.76572 16.7337L4.85254 14.9642"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6031 20.2725L6.76572 23.8113L4.85254 22.0419"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
