import styled from 'styled-components'

export const FilterByContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > button {
    outline: none;
    border: none;

    width: 100%;
    height: 70px;
    font-size: 1.1rem;

    background: #ebebe1;
    color: #f98e2c;
  }
`
