import { tratamentoComCannabisMedicinalCannect, tratamentoComCannabisMedicinalCannect1 } from 'assets/img'
import Button from 'components/Button'
import useMediaQuery from 'hooks/useMediaQuery'
import { BsWhatsapp } from 'react-icons/bs'
import { DESKTOP_WIDTH, TABLET_WIDTH } from 'utils/constants'
import When from 'utils/when'

import * as Styles from './style'

function ThirdSession() {
  const redirectLink = (link = 'https://atendimento.cannect.life/atendimento-cannect') => {
    window.open(link, '_blank', 'noopener')
  }

  const isDesktop = useMediaQuery(DESKTOP_WIDTH)
  const isTablet = useMediaQuery(TABLET_WIDTH)
  function renderText() {
    return (
      <Styles.TextDiv>
        <Styles.Title>
          Pelo conhecimento e pela qualidade, somos uma referência em cuidados relacionados à Cannabis medicinal.
        </Styles.Title>
        <Styles.Subtitle>
          Nossa equipe de cuidado especializado - os Cannecter - acompanham e orientam nossos clientes durante toda a
          sua jornada de saúde, da teleconsulta e acesso aos medicamentos até a evolução de seu tratamento, em busca da
          sua melhor qualidade de vida. <br />
          <br />
          <span>Ciência, saúde e bem estar é o que nos define.</span>
          <br />
          <Button
            width="252px"
            style={{
              borderRadius: '5px',
              backgroundColor: `#21976E`,
              marginTop: isDesktop ? '15px' : '20px'
            }}
            onClick={() => redirectLink()}
          >
            <Styles.ButtonText color="#fff">
              <BsWhatsapp />
              Fale com um Cannecter
            </Styles.ButtonText>
          </Button>
        </Styles.Subtitle>
      </Styles.TextDiv>
    )
  }
  return (
    <Styles.Container>
      <Styles.LeftWrapper>
        <img
          src={isDesktop && !isTablet ? tratamentoComCannabisMedicinalCannect : tratamentoComCannabisMedicinalCannect1}
          alt="banner"
        />
        <When expr={!isDesktop}>{renderText()}</When>
      </Styles.LeftWrapper>
      <When expr={isDesktop}>{renderText()}</When>
    </Styles.Container>
  )
}

export default ThirdSession
