import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const ItemContainer = styled.div`
  width: 252px;
  min-width: 252px;
  height: 518px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const CardTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 19px;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  color: ${temporaryTheme.colors.darkGrey};
  svg {
    font-size: 26px;
    cursor: pointer;
  }
`

export const CardDescription = styled.p`
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  color: ${temporaryTheme.colors.darkGrey};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  -webkit-box-orient: vertical;

  @media (max-width: 900px) {
    font-size: 16px;
  }

  a,
  button {
    text-decoration: underline;
    color: ${temporaryTheme.colors.link};
    font-weight: 400;
    font-family: 'Nunito';
    font-size: 18px;
    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
`

export const Image = styled.img<any>`
  height: 275px;
  width: 252px;
  min-height: 275px;
  min-width: 252px;
  max-height: 275px;
  max-width: 252px;
  border-radius: 20px;
  object-fit: cover;
`
