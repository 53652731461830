import { useFilterProduct } from 'hooks/FilterProductContext'
import { AiOutlineClose } from 'react-icons/ai'
import { useHistory, useLocation } from 'react-router'
import * as Styles from './styles'

export interface FilterBadgeProps {
  item?: any
  clearFilter?: any
  pathFilter?: string
}

export default function FilterBadge({ item, clearFilter = () => null, pathFilter = '/produtos/1' }: FilterBadgeProps) {
  const { inputValue, setInputValue, keyFilter } = useFilterProduct()
  const FormatBadges = inputValue[item]?.filter((item: any) => item?.checked) || []
  const location = useLocation()
  const history = useHistory()

  const handleRemoveFilter = (id: any) => {
    const newFilter = inputValue[item]
    const urlSearch = location.search.toLowerCase()
    const categoryBadge = `${item}=${id}`
    const newInputValue = newFilter?.map((input: any) => {
      if (input?.id === id) {
        return { ...input, checked: !input.checked }
      }
      return input
    })
    const newInput = { ...inputValue, [item]: newInputValue }
    setInputValue(newInput)
    if (urlSearch.includes(categoryBadge.toLowerCase())) {
      const newValue = location.search
      const search = newValue.replace(`${item}=${id}`, '')
      history.push({ pathname: pathFilter, search })
    }
  }

  return FormatBadges.length > 0 ? (
    <>
      {FormatBadges.length > 4 && (
        <Styles.BadgeItemBox clearType onClick={clearFilter}>
          <Styles.BadgeName>Limpar Filtro</Styles.BadgeName>
        </Styles.BadgeItemBox>
      )}
      {FormatBadges?.map((item: any) => (
        <Styles.BadgeItemBox key={item?.id} onClick={() => handleRemoveFilter(item?.id)}>
          <Styles.BadgeName>{item?.name}</Styles.BadgeName>
          <Styles.BadgeIcon>
            <AiOutlineClose />
          </Styles.BadgeIcon>
        </Styles.BadgeItemBox>
      ))}
    </>
  ) : null
}
