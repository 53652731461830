import { media } from 'helpers/mediaQueryStyle'
import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

interface ButtonActionProps {
  color: string
}

interface WrapperProps {
  status: string
}

const WrapperModifier = {
  canceled: () => css`
    ${DateBox} {
      color: ${temporaryTheme.colors.red};
      svg {
        color: ${temporaryTheme.colors.red};
      }
    }
    ${InfoBox} {
      svg {
        color: ${temporaryTheme.colors.red};
      }

      &.info {
        svg {
          color: #3d3d3d;
        }
      }
    }
    ${AppointMentBox} {
      p {
        color: ${temporaryTheme.colors.red};
      }
    }
    ${ButtonLink} {
      color: ${temporaryTheme.colors.red};
      text-decoration: none;
      cursor: auto;
      &:hover {
        color: ${temporaryTheme.colors.red};
      }
    }
  `
}

export const Wrapper = styled.main<WrapperProps>`
  ${({ status }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    background: #ededed;
    border-radius: 15px;
    padding: 14px;
    min-height: 91px;

    ${status === 'canceled' && WrapperModifier.canceled()};

    ${media.mobile`
      flex-direction: column;
      gap: 16px;
    `}
  `}
`
export const InfoBox = styled.main`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    color: #3d3d3d;
    width: 22px;
    height: 22px;
  }
`

export const IconBox = styled.div`
  width: 32px;
`
export const InfoIconBox = styled.div``

export const DataDescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    color: #3d3d3d;
  }

  ${media.desktop`
    min-width: 400px;
  `}
`
export const Name = styled.p`
  font-weight: 700;
  font-size: 14px;
  font-family: 'Spartan', sans-serif;
`
export const Category = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 12px;
`
export const RescheduleValidate = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 10px;
  max-width: 350px;
`

export const DateBox = styled.div`
  color: #3d3d3d;
  min-width: 79px;
  p {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 10px;
  }
`

export const AppointMentBox = styled.div`
  p {
    color: #3d3d3d;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 10px;
  }
`

export const ButtonActions = styled.div`
  ${media.mobile`
    display: flex;
    justify-content: center;
    width: 100%;
  `}
`
export const Button = styled.button<ButtonActionProps>`
  ${({ color }) => css`
    all: unset;
    height: 30px;
    width: 153px;
    background: ${color};
    border-radius: 4px;
    text-align: center;
    color: white;
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }

    ${media.mobile`
      width: 100%;
      max-width: 250px;
    `}
  `}
`
export const LinkActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  .box_link {
    display: flex;
    flex-direction: column;
  }

  ${media.desktop`
    width: 150px;
  `}

  ${media.mobile`
    .box_link {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
  `}
`
export const ButtonLink = styled.button`
  all: unset;
  font-family: 'Nunito', sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  color: ${temporaryTheme.colors.link};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`
