import { Button } from 'design-cannect'
import { RiAlertLine } from 'react-icons/ri'
import { status } from '../../../assets/img'
import Text from '../../Text'
import TextLink from '../../TextLink'
import { Container, Wrapper } from './styles'

function CardBuyStatus({ pedido }: any) {
  const { data, id, dataEntrega, total, img, nomeProduto, descricaoProduto } = pedido
  return (
    <Container>
      <div className="data">
        <Text type="md" bold>
          {data}
        </Text>
      </div>
      <Wrapper>
        <div className="wrapper-status">
          {pedido.status ? (
            <div className="status">
              <img src={status} alt="status" />
              <Text type="md" bold>
                Pedido entregue
              </Text>
            </div>
          ) : (
            <div className="status">
              <div className="status-error">
                <RiAlertLine />
              </div>
              <div>
                <Text type="md" bold>
                  Falta enviar alguns documentos
                </Text>
                <TextLink type="md" bold iconAfter title="ENVIAR DOCUMENTOS" url="" />
              </div>
            </div>
          )}

          <div className="descripiton">
            <div className="status-text">
              <Text type="md" bold color="tertiary">
                Pedido
              </Text>
              <Text type="md">{id}</Text>
            </div>
            <div className="status-text">
              <Text type="md" bold color="tertiary">
                Entrega prevista
              </Text>
              <Text type="md">{dataEntrega}</Text>
            </div>
            <div className="status-text">
              <Text type="md" bold color="tertiary">
                Total
              </Text>
              <Text type="md">R$ {total}</Text>
            </div>
          </div>
        </div>
        <div />

        <div className="products">
          <div className="item">
            <img src={img} alt="status" />
            <div>
              <Text type="md" bold color="tertiary">
                {nomeProduto}
              </Text>
              <Text type="md">{descricaoProduto}</Text>
            </div>
          </div>
          <div className="action">
            <Button outlined>Detalhes da compra</Button>
            <Button>Comprar novamente</Button>
          </div>
        </div>
      </Wrapper>
    </Container>
  )
}

export default CardBuyStatus
