import React from 'react'

interface IconeProfissionalSaudeProps extends React.SVGProps<SVGSVGElement> {
  color?: 'default' | 'hover'
}

export default function IconeProfissionalSaude({ color, ...rest }: IconeProfissionalSaudeProps) {
  return (
    <svg width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.2756 27.6877H14.8658C13.2237 27.6877 11.8926 29.0189 11.8926 30.6609V78.2317C11.8926 79.8738 13.2237 81.2049 14.8658 81.2049H80.2756C81.9176 81.2049 83.2488 79.8738 83.2488 78.2317V30.6609C83.2488 29.0189 81.9176 27.6877 80.2756 27.6877Z"
        stroke="#3D3D3D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.4368 27.688V21.7416C62.4368 20.1646 61.8103 18.6521 60.6952 17.5369C59.58 16.4218 58.0675 15.7953 56.4905 15.7953H38.6514C37.0744 15.7953 35.5619 16.4218 34.4467 17.5369C33.3316 18.6521 32.7051 20.1646 32.7051 21.7416V27.688"
        stroke="#3D3D3D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.5703 44.0403V64.8525"
        stroke="#3D3D3D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.9763 54.4463H37.1641"
        stroke="#3D3D3D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
