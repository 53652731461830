import { theme } from 'design-cannect'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2.5rem 1.5rem;
  border-bottom: 1px solid #ecece2;

  width: 90%;

  h2 {
    color: #3d3d3d;
    font-family: 'Spartan', sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2rem;
  }

  h3 {
    color: ${theme.colors.tertiary};
    width: 100%;
    font-size: 2rem;
  }

  p.product-explanation {
    color: ${theme.colors.tertiary};
    font-weight: 300;
    max-width: 600px;

    text-align: center;
    margin-top: 2rem;
  }

  > button {
    color: ${theme.colors.tertiary};
    margin-top: 2rem;
  }

  > object {
    border: 1px solid #ecece2;
    border-radius: 20px;
    margin-top: 1rem;
  }

  textarea {
    resize: none;
  }

  @media ${MOBILE_WIDTH} {
    width: 98%;
  }
`
