import { Typography } from 'new-components/atoms'
import { TPatientListItem } from 'pages/Account/components/pages/MyPatients/types'
import { FaCircle } from 'react-icons/fa'

interface PatientStatusCellProps {
  row: Pick<TPatientListItem, 'current_status'>
  showNullPlaceholder?: boolean
}

const PatientStatusCell = ({ row, showNullPlaceholder = true }: PatientStatusCellProps) => {
  return (
    <div className="flex items-center gap-2 lowercase">
      {row.current_status ? (
        <>
          <FaCircle className="mb-px" size={11} fill={row.current_status.color} />
          <Typography.Text type="captionOne" weight="regular" className="text-nowrap">
            {row.current_status.name}
          </Typography.Text>
        </>
      ) : (
        <Typography.Text type="captionOne" weight="regular">
          {showNullPlaceholder && '-'}
        </Typography.Text>
      )}
    </div>
  )
}

export default PatientStatusCell
