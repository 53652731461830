import { ChangeEvent } from 'react'
import * as Style from './styles'

interface RadioInputProps {
  label: string
  id?: string
  name: string
  checked: boolean
  onChange: (event?: ChangeEvent<HTMLInputElement>) => void
  bold?: boolean
}

export default function RadioInput({ label, id, checked, bold = false, onChange }: RadioInputProps) {
  return (
    <Style.RadioInputContainer>
      <input id={id} type="radio" checked={checked} onChange={onChange} />
      <Style.VisualInput htmlFor={id}>{checked && <Style.Circle />}</Style.VisualInput>
      <label htmlFor={id} style={bold ? { fontWeight: 'bold' } : { fontWeight: '' }}>
        {label}
      </label>
    </Style.RadioInputContainer>
  )
}
