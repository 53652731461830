/* eslint-disable prettier/prettier */
import { cuidaPatientAmandaNobrega, cuidaPatientDarllanSouza, cuidaPatientIdenirPereira, cuidaPatientLuanaAlves, cuidaPatientSoniaLanza, cuidaProfessionalDaniGonzalez, cuidaProfessionalEduardoTesta, cuidaProfessionalJessicaDurand, cuidaProfessionalJulianaPaiva, cuidaProfessionalViniciusMesquita } from 'assets/img'
import { cuidaApoioIndividualIcon, cuidaAcompanhamentoIcon, cuidaGarantiaSegurancaIcon, cuidaMelhorAdaptacaoIcon, cuidaMonitoramentoPersonalizadoIcon, cuidaAdesaoTerapeuticaIcon, cuidaReforcoPersonalizadoIcon, cuidaMantemInformadoIcon } from 'assets/svg'


interface Reports {
    name: string
    responsibility: string
    image: string
    report: string
}

export const cuidaPatientBenefits = [
    {
        name: 'Apoio individualizado',
        image: cuidaApoioIndividualIcon
    },
    {
        name: 'Acompanhamento',
        image: cuidaAcompanhamentoIcon
    },
    {
        name: 'Garantia de segurança',
        image: cuidaGarantiaSegurancaIcon
    },
    {
        name: 'Melhor adaptação ao tratamento',
        image: cuidaMelhorAdaptacaoIcon
    },
]

export const cuidaProfessionalBenefits = [
    {
        name: 'Monitoramento personalizado',
        image: cuidaMonitoramentoPersonalizadoIcon
    },
    {
        name: 'Adesão terapêutica',
        image: cuidaAdesaoTerapeuticaIcon
    },
    {
        name: 'Reforço da posologia e orientações',
        image: cuidaReforcoPersonalizadoIcon
    },
    {
        name: 'Você se mantém informado sobre toda a adaptação do seu paciente ao tratamento',
        image: cuidaMantemInformadoIcon
    },
]

export const patientReports: Reports[] = [
    {
        name: 'Amanda Nóbrega',
        responsibility: 'Analista de RH',
        image: cuidaPatientAmandaNobrega,
        report: `Iniciei meu tratamento à base de cannabis medicinal para tratar ansiedade e insônia. Faço uso de óleo diariamente e gummies em casos emergenciais (dias mais estressantes). A consulta com um dos enfermeiros do Cannect Cuida foi fundamental para eu iniciar meu tratamento com mais qualidade e confortável. Eu tinha muitas dúvidas iniciais sobre quanto tempo deixar o óleo sublingual, se podia fazer o uso em jejum ou não e o que fazer se percebesse alguns efeitos colaterais nas primeiras semanas. O acompanhamento inicial foi muito importante, me senti cuidada e assistida. Hoje, com um pouco mais de 4 meses de tratamento, percebo nitidamente os resultados no meu dia a dia. Me sinto muito mais calma e consigo ter mais foco, além de conquistar um sono de qualidade.`
    },
    {
        name: 'Luana Alves',
        responsibility: 'Filmmaker',
        image: cuidaPatientLuanaAlves,
        report: `Comecei o tratamento com cannabis medicinal para tratar a minha ansiedade, depressão e TDAH em outubro de 2021, desde o começo os enfermeiros do Cannect Cuida acompanham o meu processo, desde dando dicas de como utilizar e me ajudando a encontrar a dose ideal do óleo.`
    },
    {
        name: 'Darllan Souza',
        responsibility: 'Gestor de Produto',
        image: cuidaPatientDarllanSouza,
        report: `Não consigo expressar o quanto sou grato à equipe de enfermeiros do cuida que me acompanha no tratamento com cannabis medicinal. Desde o início, eles não só mostraram muito conhecimento, mas também um apoio incrível. Tiraram minhas dúvidas, me deram suporte e sempre me fizeram sentir seguro. Não tenho palavras para agradecer por tornarem meu percurso de tratamento mais eficaz e humano.`
    },
    {
        name: 'Sonia Lanza',
        responsibility: 'Professora aposentada',
        image: cuidaPatientSoniaLanza,
        report: `Comecei meu tratamento com cannabis medicinal e fui muito bem atendida pelo Vitor (enfermeiro), que é uma pessoa que sempre me recebeu muito bem, sempre que tenho alguma dúvida entro em contato com ele, me atende com muita atenção. Parabéns! Vitor, você é demais! Obrigada por tudo!`
    },
    {
        name: 'Idenir Pereira de Oliveira',
        responsibility: 'Bancário Aposentado',
        image: cuidaPatientIdenirPereira,
        report: `Gostaria de parabenizar a equipe de enfermagem pela atenção e cuidados ao iniciar o tratamento CBD. Na pessoa do senhor Vitor que sempre me atendeu com atenção a medida de minha solicitação sempre que foi preciso. Obrigado!`
    },
]

export const professionalReports: Reports[] = [
    {
        name: 'Dani Gonzalez',
        responsibility: 'Generalista',
        image: cuidaProfessionalDaniGonzalez,
        report: `Com a assistência do Cannect Cuida eu posso ficar tranquila que meus pacientes estão sendo bem assistidos. Sei que esse acompanhamento auxilia o paciente a ter maior adesão ao tratamento, já que tiram dúvidas e orientam junto comigo cada pessoa. Com os relatórios que recebo da equipe podemos fazer uma medicina individualizada e sempre ter o melhor pros pacientes.`
    },
    {
        name: 'Eduardo Testa',
        responsibility: 'Generalista',
        image: cuidaProfessionalEduardoTesta,
        report: `Quando falamos sobre prescrição de fitocanabinoides, o acompanhamento próximo ao paciente é determinante para o sucesso – ou fracasso – do tratamento. Sabemos que os médicos tem uma agenda repleta de compromissos e muitas vezes é difícil realizar esse acompanhamento de forma tão assertiva. O Cannect Cuida é uma ferramenta que auxilia o médico nesse processo, com profissionais muito capacitados trazendo confiança e segurança ao paciente.`
    },
    {
        name: 'Jéssica Durand',
        responsibility: 'Generalista',
        image: cuidaProfessionalJessicaDurand,
        report: `O Cannect Cuida, na minha prática clínica, é uma das ferramentas mais úteis e importantes que a Cannect oferece. O time de enfermagem traz um olhar horizontal do paciente, oferecendo suporte em relação à orientações, titulação de dosagem e efeitos colaterais, tornando a assistência ao cuidado muito mais eficiente e integral. Com o Cannect Cuida, me sinto mais segura em relação ao acompanhamento dos meus pacientes e consigo, também, discutir os casos com o time de especialistas, trazendo uma riqueza de informações que podem auxiliar ainda mais no plano terapêutico dos meus pacientes! Só elogios!`
    },
    {
        name: 'Juliana Paiva',
        responsibility: 'Generalista',
        image: cuidaProfessionalJulianaPaiva,
        report: `Contar com o cuidado continuado do Cannect Cuida ajuda muito no meu dia a dia com os pacientes. Informações importantes que eventualmente o paciente não assimilou na consulta são reforçadas pelos enfermeiros, garantindo um tratamento mais efetivo. E se algum problema fica evidente, o Cannect Cuida toma as atitudes necessárias para me comunicar e podermos assim encontrar a melhor solução para a saúde do paciente. Sou fã!`
    },
    {
        name: 'Vinícius Mesquita',
        responsibility: 'Generalista',
        image: cuidaProfessionalViniciusMesquita,
        report: `Com o Cannect Cuida eu tenho a tranquilidade de saber que meus pacientes estão sendo acompanhados por profissionais que entendem profundamente sobre os produtos da cannabis, seus efeitos adversos e colaterais a fim de potencializar as propriedades terapêuticas.`
    },
]