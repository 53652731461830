import { pngCannabis } from 'assets/img'
import { svgWebinar } from 'assets/svg'
import EducateAside from 'components/CannectEducate/EducateAside'
import EducateNavigator from 'components/CannectEducate/EducateNavigator'
import SocialMedia from 'components/SocialMedia'
import TabContent, { Post } from 'components/CannectEducate/TabContent'
import TabContentTitle from 'components/CannectEducate/TabContentTitle'
import TabsNavigator from 'components/TabsNavigator'
import YouTubeFrame from 'components/YouTubeFrame'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import api from 'services/api'
import * as Style from 'styles/cannectEducate'
import LessonTitle from 'components/LessonTitle'
import RadioInput from 'components/RadioInput'
import FilterCard from 'components/CannectEducate/FilterCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'

export interface Webinar {
  image: string | undefined
  id: number
  title: string
  description: string
  category: string
  status: string
  path: string
  createdAt: Date
  updatedAt: Date
}

export interface FetchWebinarsResponse {
  webinars: {
    count: number
    rows: Webinar[]
  }
  success: boolean
}

export function formatWebinars(webinars: Webinar[]): Post[] {
  const newPosts: Post[] = []
  webinars.forEach(webinar => {
    newPosts.push({
      id: webinar.id,
      description: webinar.description,
      img: webinar.image,
      title: webinar.title,
      url: `/educa/webnars/${webinar.id}`,
      externalUrl: webinar.path
    })
  })

  return newPosts
}

interface Filter {
  id: string
  label: string
  checked: boolean
}

export default function WebinarsHome() {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<Post[]>([])
  const [postsQuantity, setPostsQuantity] = useState(0)
  const location = useLocation()
  const selectedWebinar = handleWebinarSelection()
  const [filters, setFilters] = useState([
    {
      id: 'cannect-na-pratica',
      label: 'Cannect na prática',
      checked: false
    },
    {
      id: 'cannect-em-foco',
      label: 'Cannect em foco',
      checked: false
    },
    {
      id: 'cannect-com-ciencia',
      label: 'Cannect com ciência',
      checked: false
    }
  ])

  useEffect(() => {
    fetchPosts()
  }, [page])

  useEffect(() => {
    setPosts([])
    setPage(1)
    if (page === 1) {
      fetchPosts()
    }
  }, [filters])

  async function fetchPosts() {
    setLoading(true)
    try {
      const formattedFilter = formatFilters(filters)
      const response = await api.get<FetchWebinarsResponse>(`/webinars/${page}?limit=12${formattedFilter}`)
      const newPosts = formatWebinars(response.data.webinars.rows)

      if (page === 1) {
        setPosts(newPosts)
        setPostsQuantity(response.data.webinars.count)
        setTotalPages(Math.ceil(response.data.webinars.count / 12))
      } else {
        setPosts([...posts, ...newPosts])
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  function formatFilters(filters: Filter[]): string {
    let formattedFilter = ''
    filters.forEach(filter => {
      if (filter.checked) {
        formattedFilter = `&filter=${filter.label}`
      }
    })
    return formattedFilter
  }

  function handleWebinarSelection(): Post | undefined {
    const newPathname = location.pathname.split('/educa/webnars/')[1]

    if (newPathname) {
      const selectedPost = posts.find(post => post.id === parseInt(newPathname, 10))
      return selectedPost
    }

    return undefined
  }

  function handleRadioChange(id: string) {
    setFilters(
      filters.map(filter =>
        filter.id === id
          ? {
              ...filter,
              checked: true
            }
          : {
              ...filter,
              checked: false
            }
      )
    )
  }

  function handleCancelFilter(id: string) {
    setFilters(
      filters.map(filter =>
        filter.id === id
          ? {
              ...filter,
              checked: false
            }
          : filter
      )
    )
  }

  return (
    <Style.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Educa', link: '/educa' },
          { label: 'Webinars', link: `/educa/webnars` }
        ]}
      />
      <TabsNavigator />
      <EducateNavigator />

      <Style.Content>
        <EducateAside>
          <Style.AsideInputsWrapper>
            {filters.map(filter => (
              <RadioInput
                key={filter.id}
                label={filter.label}
                name={filter.id}
                id={filter.id}
                checked={filter.checked}
                onChange={() => handleRadioChange(filter.id)}
              />
            ))}
          </Style.AsideInputsWrapper>
        </EducateAside>

        <Style.Wrapper>
          <SocialMedia message="Acesse já diversos webinars sobre o uso da Cannabis medicinal!" />

          <TabContentTitle
            title="Webinars"
            titleImg={svgWebinar}
            postsCountText={`${postsQuantity} webinars encontrados`}
          />

          <Style.FiltersCardsContainer>
            {filters.map(
              filter =>
                filter.checked && (
                  <FilterCard key={filter.id} background="green" onRemoveClick={() => handleCancelFilter(filter.id)}>
                    {filter.label}
                  </FilterCard>
                )
            )}
          </Style.FiltersCardsContainer>

          {selectedWebinar && (
            <>
              <LessonTitle>{selectedWebinar.title}</LessonTitle>
              <YouTubeFrame url={selectedWebinar.externalUrl} title={selectedWebinar.title} />
            </>
          )}

          <InfiniteScroll
            dataLength={posts.length}
            next={() => setPage(state => state + 1)}
            hasMore={page !== totalPages}
            loader={<div />}
            endMessage={<div />}
          >
            <TabContent posts={posts.filter(post => post.id !== selectedWebinar?.id)} actionButtonText="ASSISTIR" />
          </InfiniteScroll>

          {loading && <Loading loading={loading} />}
        </Style.Wrapper>
      </Style.Content>
    </Style.Container>
  )
}
