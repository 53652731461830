import { Prescription } from './types'

export const PRESCRIPTION_INITIAL_STATE: Prescription = {
  products: [],
  recomendations: '',
  report: {
    deseaseName: '',
    cid: '',
    caseDescription: '',
    justificationForUsingCannabinoid: '',
    previousTreatments: ''
  },
  flavor: '',
  patient: {
    name: '',
    cpf: '',
    email: '',
    phone: ''
  },
  therapeuticPlan: {
    useObservationalFollowUp: false,
    protocol: ''
  },
  prescriber: {
    logoPosition: 'left',
    profilePicture: ''
  },
  recipeOptions: {
    useDigitalSignature: false,
    sendSmsToPatient: true,
    printMyPrescriptions: false
  },
  isTemplateApplied: false,
  templateName: '',
  createdAt: null
}
