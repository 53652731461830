export const DefaultMedicalData = {
  cbc: '',
  cbc_concentration: '',
  cbd: 0,
  cbd_concentration: 0,
  cbda: '',
  cbda_concentration: '',
  cbdv: '',
  cbdv_concentration: '',
  cbg: '',
  cbg_concentration: '',
  cbga: '',
  cbga_concentration: '',
  cbn: '',
  cbn_concentration: '',
  createdAt: '',
  delta8: '',
  delta8_concentration: '',
  delta8_thcv: '',
  delta8_thcv_concentration: '',
  delta9_thcv: '',
  delta9_thcv_concentration: '',
  delta10: '',
  delta10_concentration: '',
  id: 0,
  product_id: '',
  thc: '',
  thc_concentration: '',
  unit_of_measurement: '',
  updatedAt: '',
  uuid: '',
  volume: 0
}
