import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const NewAddressButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 15rem;
  border: 1px solid #e0e7ec;

  border-radius: 0.5rem;
  max-width: 30rem;

  @media ${MOBILE_WIDTH} {
    max-width: 100%;
  }
`
