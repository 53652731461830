/* eslint-disable no-param-reassign */
import { ComponentProps, forwardRef, useLayoutEffect } from 'react'
import { VariantProps, tv } from 'tailwind-variants'
import { Typography } from '../Typography'

export const textArea = tv({
  slots: {
    container: 'relative flex items-center',
    containerInput:
      'peer block h-auto w-full appearance-none overflow-hidden bg-transparent p-4 text-sm text-gray-700 focus:border-blue-600 focus:outline-none focus:ring-0',
    label:
      'absolute left-1 top-1 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:px-2',
    containerIcon: 'text-gray-500',
    helperText: 'text-left'
  },
  variants: {
    variant: {
      filled: {
        container: 'bg-white',
        containerInput: 'rounded text-gray-700',
        helperText: 'text-gray-400',
        label: 'text-gray-400'
      },
      ghost: {
        container: 'rounded',
        containerInput: 'rounded text-gray-700',
        helperText: 'text-gray-400',
        label: 'text-gray-400'
      },
      filledDark: {
        container: 'rounded border-2 border-gray-600 bg-white',
        containerInput: 'rounded text-gray-700',
        helperText: 'text-gray-400',
        label: 'text-gray-700'
      },
      filledGray: {
        container: 'rounded border-2 border-gray-100 bg-white',
        containerInput: 'rounded text-gray-700',
        helperText: 'text-gray-400',
        label: 'text-gray-400'
      }
    },
    hasIconRight: {
      true: {
        container: 'pr-4',
        containerInput: 'pr-4'
      }
    },
    hasIconLeft: {
      true: {
        container: 'pl-4',
        label: 'left-12'
      }
    },
    small: {
      true: {
        containerInput: 'h-10'
      }
    },
    medium: {
      true: {
        containerInput: 'h-12'
      }
    },
    big: {
      true: {
        containerInput: 'h-[3.25rem]'
      }
    },
    isInvalid: {
      true: {
        container: 'border-critical-500',
        containerIcon: 'text-critical-500',
        containerInput: 'text-critical-500',
        helperText: 'text-critical-900',
        label: 'text-critical-500'
      }
    },
    isDisabled: {
      true: {
        container: 'bg-gray-100',
        containerInput: 'text-gray-400',
        label: 'bg-transparent'
      }
    },

    rounded: {
      small: {
        container: 'rounded'
      },
      medium: {
        container: 'rounded'
      },
      big: {
        container: 'rounded-xl'
      },
      full: {
        container: 'rounded-full'
      }
    }
  },
  defaultVariants: {
    variant: 'filled',
    rounded: 'medium'
  }
})

export type TextAreaProps = ComponentProps<'textarea'> &
  VariantProps<typeof textArea> & {
    message?: string
    limit?: number
    isLoading?: boolean
  }

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      big,
      rounded,
      isDisabled,
      isInvalid,
      medium,
      message,
      name,
      placeholder,
      isLoading = false,
      small,
      className,
      variant,
      limit,
      ...props
    },
    ref
  ) => {
    const { container, containerInput, helperText, label } = textArea({
      className,
      big,
      rounded,
      isDisabled,
      isInvalid,
      medium,
      small,
      variant
    })

    const autoResize = (element: any) => {
      if (!element) return

      element.style.height = 'auto'

      element.style.height = `${element.scrollHeight}px`
    }

    useLayoutEffect(() => {
      const time = setTimeout(() => {
        const element = document.getElementById(`floating_${name}`)

        autoResize(element)
      }, 300)

      return () => {
        clearTimeout(time)
      }
    }, [name])

    if (isLoading) {
      return (
        <div className="animate-pulse">
          <div className="h-24 w-full rounded bg-gray-200" />
        </div>
      )
    }

    return (
      <div className="flex flex-col">
        <div className={container()}>
          <textarea
            ref={ref}
            id={`floating_${name}`}
            className={containerInput()}
            placeholder=""
            style={{ resize: 'none' }}
            disabled={!!isDisabled}
            onInput={(e) => autoResize(e.target)}
            {...props}
          />

          <label htmlFor={`floating_${name}`} className={label()}>
            <Typography.Text type="paragraphOne">{placeholder}</Typography.Text>
          </label>
        </div>
        <div className="mt-1 flex items-center">
          <div className="flex-1">
            {message && (
              <Typography.Text type="captionOne" weight="medium" className={helperText()}>
                {message}
              </Typography.Text>
            )}
          </div>
          <div>
            {limit && limit > 0 && (
              <Typography.Text type="captionOne" className="tabular-nums">{`${
                (props.value as string)?.length
              }/${limit}`}</Typography.Text>
            )}
          </div>
        </div>
      </div>
    )
  }
)
