import styled from 'styled-components'

const RetryButton = styled.button`
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border: none;
  outline: none;
  margin-top: 6px;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
`

interface ToastRetryButtonProps {
  onRetry: () => void
}
export default function ToastRetryButton({ onRetry }: ToastRetryButtonProps) {
  return (
    <RetryButton type="button" onClick={onRetry}>
      Tentar novamente
    </RetryButton>
  )
}
