import { theme } from 'design-cannect'
import styled from 'styled-components'

export const TeacherBiographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  width: 307px;

  img {
    align-self: center;
    width: 200px;
    height: 200px;
    border-radius: 999px;
    margin-bottom: 1rem;
  }

  h3 {
    color: ${theme.colors.tertiary};
    font-size: 1rem;
  }

  p {
    font-weight: 300;
    font-size: 1rem;
  }
`
