import styled from 'styled-components'

export const OrdersContainer = styled.section`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;

  .order_wrapper {
    background: #fff;
    height: 100%;
    border-radius: 0.5rem;
    padding: 1rem;

    .order_title {
      display: flex;
      align-items: center;
      color: #21976e;
      font-size: 2rem;
      font-family: 'Poppins', sans-serif !important;
    }

    .order_subtitle {
      display: flex;
      align-items: center;
      color: #21976e;
      font-size: 0.8rem;
      font-family: 'Poppins', sans-serif !important;
      svg {
        font-size: 4rem;
        margin-right: 16px;
      }
    }

    .order_filter {
      display: flex;
      padding: 1rem;
      align-items: center;
      background: #f8f8f8;
      width: 100%;
      height: auto;
      p {
        margin-left: 24px;
      }
    }

    .order_list {
      margin-top: 16px;
      width: 100%;

      .card_order_header {
        width: 100%;
        display: flex;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        height: 50px;
        align-items: center;
        color: #64748b;
        padding: 0 16px;
        .card_order_id {
          width: 45%;
        }
        .card_order_date {
          width: 30%;
        }
      }

      .card_order {
        .card_order_id {
          width: 40%;
        }
        .card_order_date {
          width: 30%;
        }
        width: 100%;
        display: flex;
        padding: 0 16px;
        height: 60px;
        border-bottom: 1px solid #ccc;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`
