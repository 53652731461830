import useSignedUser from 'hooks/useSignedUser'
import { PATIENT_SIDE_PANEL } from 'pages/Account/components/template/AccountSidePanel/constants/patient'
import {
  CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL,
  PROFESSIONAL_SIDE_PANEL
} from 'pages/Account/components/template/AccountSidePanel/constants/professional'
import { useMemo } from 'react'
import CollapsableMenu from './components/CollapsableMenu'
import * as Styles from './styles'

function CollapsableMenus() {
  const { signedUser } = useSignedUser()
  const isCRMOrCRO = useMemo(() => {
    if (
      signedUser?.prescriber?.council === 'CRO' ||
      signedUser?.prescriber?.council === 'CRM' ||
      signedUser?.prescriber?.council === 'RMS'
    ) {
      return true
    }
    return false
  }, [signedUser])

  const profissionalSidePanel = isCRMOrCRO ? CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL : PROFESSIONAL_SIDE_PANEL

  return (
    <Styles.CollapsableMenusContainer>
      {signedUser?.registration_type === 1
        ? PATIENT_SIDE_PANEL.map(item => (
            <CollapsableMenu
              title={item.title.label}
              icon={item.title.icon}
              to={item.title.to}
              subItems={item?.subItems}
              enabled={item.title.enabled}
              key={item.title.label}
            />
          ))
        : profissionalSidePanel.map(item => (
            <CollapsableMenu
              title={item.title.label}
              icon={item.title.icon}
              to={item.title.to}
              subItems={item?.subItems}
              enabled={item.title.enabled}
              key={item.title.label}
            />
          ))}
    </Styles.CollapsableMenusContainer>
  )
}

export default CollapsableMenus
