import { Control, FieldValues, Path } from 'react-hook-form'
import React from 'react'
import { Form, FormController } from '@cannect/new-components/atoms'
import { formItemVariants } from '@cannect/new-components/atoms/FormController'
import { SwitchProps, switchVariants } from '@cannect/new-components/atoms/Form/Switch'
import { VariantProps } from 'tailwind-variants'

type SwitchFieldProps = SwitchProps & {
  label?: string
  isLoading?: boolean
} & VariantProps<typeof formItemVariants> &
  VariantProps<typeof switchVariants>

export const SwitchField = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  alignment: formItemAlignment,
  ...props
}: SwitchFieldProps & {
  name: Path<TFieldValues>
  control: Control<TFieldValues, any>
}) => {
  return (
    <FormController.FormField
      control={control}
      name={name}
      data-testid="switch-field"
      render={({ field }) => (
        <FormController.FormItem alignment={formItemAlignment}>
          <div className="flex items-center gap-2">
            <FormController.FormControl>
              <Form.Switch
                onBlur={field.onBlur}
                name={name}
                ref={field.ref}
                onCheckedChange={field.onChange}
                checked={field.value}
                {...props}
              />
            </FormController.FormControl>
            {label && <FormController.FormLabel className="text-xs font-normal">{label}</FormController.FormLabel>}
          </div>
          <FormController.FormMessage />
        </FormController.FormItem>
      )}
    />
  )
}

SwitchField.displayName = 'SwitchField'
