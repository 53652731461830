import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  border-radius: 5px;
  padding: 18px;
  padding-top: 40px;
  max-height: 1059px;
  ${media.desktop`
    min-width: 950px;
    background: #F8F8F8;
  `}

  ${media.mobileWithTablet`
    max-height: unset;
    padding-top: 8px;
  `}
`
export const Heading = styled.h2`
  color: #21976e;
  display: flex;
  gap: 8px;
  font-family: 'Spartan', sans-serif;
  font-size: 26px;
  font-weight: 600;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }
`
export const FilterBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin: 40px 0 12px 0;
  ${media.mobile`
    flex-direction: column;
    margin: 20px 0 12px 0;

  `}
`

export const SearchBox = styled.div`
  * {
    font-family: 'Nunito', sans-serif;
  }
  .input_wrapper {
    height: 33px;

    border-radius: 14px;
  }

  .ant-picker {
    background: none;
    border: none;
  }
  .ant-picker-input {
    display: none;
  }

  ${media.mobile`
    width: 100%;
    .input_wrapper {
      height: 40px;
    }
  `}
`
export const Filters = styled.div`
  display: flex;
  align-items: center;

  * {
    font-family: 'Spartan', sans-serif;
    font-weight: 400;
    color: #424242;
  }
  .button_dropdown {
    width: fit-content;
    border: none;
    background: transparent;
    img {
      filter: invert(21%) sepia(15%) saturate(0%) hue-rotate(268deg) brightness(90%) contrast(86%);
    }

    svg {
      color: #3d3d3d;
    }
  }

  ${media.mobile`
    width: 100%;
    justify-content: center;
  `}
`

export const Separator = styled.div`
  width: 0.5px;
  height: 27px;
  background: #3d3d3d;
  margin: 0 12px;
  /*   transform: rotate(90deg); */
`

export const ListAppointments = styled.div`
  margin-top: 12px;
  width: 100%;
`

export const GroupedConsultationsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    margin-top: 22px;
  }
`

export const LoadingContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PaginateBox = styled.div`
  padding: 38px 0;
  width: 100%;

  li.selected {
    background: #646464;
  }
`
