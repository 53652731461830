import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";



export function usePreserveUTM() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const utmParams: Record<string, string> = {}

    urlParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmParams[key] = value
      }
    })

    if (Object.keys(utmParams).length > 0 ){
      sessionStorage.setItem('utmParams', JSON.stringify(utmParams))
    }


  }, [location.search])

  useEffect(() => {
    const storedParams = sessionStorage.getItem('utmParams')
    let  utmParams: Record<string, string> = {}

    if (storedParams) {
      utmParams = JSON.parse(storedParams) as Record<string, string>
    }

    const currentSearchParams = new URLSearchParams(location.search)

    Object.entries(utmParams).forEach(([key, value]) => {
      if (!currentSearchParams.has(key)){
        currentSearchParams.append(key, value)
      }
    })

    if (Object.keys(utmParams).length > 0 ) {
      history.replace(`${location.pathname}?${currentSearchParams.toString()}`)
    }

  }, [location.pathname, location.search, history])


}
