import styled from 'styled-components'

interface IResume {
  isNotEditing?: boolean
  isMobile: boolean
}

export const Container = styled.div<IResume>`
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  border-radius: 4px;
  margin-top: 1rem;
  /* width: ${({ isNotEditing, isMobile }) => (isNotEditing || isMobile ? '100%' : '50%')}; */
  padding: 25.13px 27.45px 30.82px 33.45px;
  flex: 1;

  hr {
    border: 0.7px solid #777777;
    margin-top: 11px;
  }

  .component_resume-wrapper {
    padding: 0;
  }
`

export const Title = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;

  text-transform: uppercase;
`

export const Prices = styled.div<IResume>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;

  .container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    margin-bottom: 16px;
    color: #777777;
  }
`

export const RightContainer = styled.div<IResume>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  margin: 8px 0;

  .cupom-container {
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 16px;

    span {
      display: flex;
      margin-top: 10px;
      margin-right: 10px;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
  }

  span {
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
`

interface Coupon {
  isValid: boolean
}

export const CupomInput = styled.input<Coupon>`
  width: auto;
  height: 35px;
  padding: 1rem;
  border: ${props => (props.isValid ? '1px solid rgba(197, 88, 27, 0.5)' : '')};
`

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  .total-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 17px;

    color: #000000;
  }

  .total {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: right;
  }
`

export const ButtonDiscount = styled.button`
  background: #748351;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 35px;
  width: 70px;
`

export const SmallLetter = styled.b`
  font-size: 10px;
  padding-left: 4px;
  font-style: italic;
`
