import styled from 'styled-components'

export const IntensitySelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.45rem;
`
export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  min-width: 30px;
  height: 15px;
  min-height: 15px;

  font-weight: 300;
`

interface SelectorProps {
  background: string
}
export const Selector = styled.button<SelectorProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  min-width: 30px;
  height: 35px;
  min-height: 35px;

  border: none;
  outline: none;
  transition: filter 0.4s;

  background: ${(props) => props.background};

  &:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  &:hover {
    filter: brightness(0.8);
  }
`
