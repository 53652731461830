import React, { useState } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Text from '../Text'
import * as Styles from './styles'

interface AccordionProps {
  title?: any
  children?: React.ReactNode
  defaultOpen?: boolean
}
function AccordionFilter({ title, children, defaultOpen = false }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <Styles.Container isOpen={isOpen}>
      <Styles.HeaderAccordionFilter isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div>
          <Styles.LabelText isOpen={isOpen}>{title}</Styles.LabelText>
        </div>
        <Styles.ToggleButton type="button">{isOpen ? <FiMinusCircle /> : <FiPlusCircle />}</Styles.ToggleButton>
      </Styles.HeaderAccordionFilter>
      <Styles.Wrapper isOpen={isOpen}>
        <div>{children}</div>
      </Styles.Wrapper>
    </Styles.Container>
  )
}

export default AccordionFilter
