import { cannectDefaultProduct } from 'assets/img'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { Product } from 'hooks/useCannectAssistant/types'
import useMediaQuery from 'hooks/useMediaQuery'
import SelectWithInput from 'new-components/SelectWithInput'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'
import { breakpointsToHook } from 'utils/breakpoints'
import When from 'utils/when'
import * as Style from './styles'

interface ProductUseModeProps {
  product: Product
  productIndex: number
  isArrowUpDisabled: boolean
  isArrowDownDisabled: boolean
  unAvailableProduct?: boolean
}

export default function ProductUseMode({
  product,
  productIndex,
  isArrowUpDisabled,
  isArrowDownDisabled,
  unAvailableProduct
}: ProductUseModeProps) {
  const { changePrescriptionOrder, changeProductHowToUse, changeProductBottlesPerYear } = useCannectAssistant()
  const isMobile = useMediaQuery(breakpointsToHook.mobile)

  let productImageURL
  const productImage = product?.media?.[0]?.path ?? product.image
  productImage && productImage.length > 0 ? (productImageURL = productImage) : (productImageURL = cannectDefaultProduct)

  function handleChangeProductIndex(action: 'up' | 'down') {
    if (action === 'up') {
      changePrescriptionOrder(product, productIndex - 1, productIndex)
    } else {
      changePrescriptionOrder(product, productIndex + 1, productIndex)
    }
  }

  return (
    <Style.ProductUseModeContainer unAvailableProduct={unAvailableProduct}>
      <span className="number_index">{productIndex + 1}.</span>

      <Style.ImageContent>
        <img src={productImageURL} alt="Produto" />

        <When expr={isMobile}>
          <div className="content-sort_mobile">
            <Style.SortContainer>
              <Style.ButtonArrow disabled={isArrowUpDisabled} onClick={() => handleChangeProductIndex('up')}>
                <FiArrowUp size={25} color="#fff" />
              </Style.ButtonArrow>
              <Style.ButtonArrow disabled={isArrowDownDisabled} onClick={() => handleChangeProductIndex('down')}>
                <FiArrowDown size={25} color="#fff" />
              </Style.ButtonArrow>
            </Style.SortContainer>
          </div>
        </When>
      </Style.ImageContent>

      <Style.MainInfos>
        <h2>{product.translated_name || product.name}</h2>
        <Style.VerticalContent>
          <SelectWithInput
            value={String(product?.quantity || '')}
            onChange={(newValue) => changeProductBottlesPerYear(product.id, newValue)}
            label={<span className="posology-title">Quantidade</span>}
            selectedOptionSufix="unidades"
            id="bottles-per-year-select"
            options={[
              {
                value: '1',
                label: '1 unidade'
              },
              {
                value: '2',
                label: '2 unidades'
              },
              {
                value: '3',
                label: '3 unidades'
              },
              {
                value: '4',
                label: '4 unidades'
              },
              {
                value: '5',
                label: '5 unidades'
              }
            ]}
          />

          <span className="posology-title">Posologia</span>
          <textarea
            value={product.howToUse}
            placeholder="Ex: Tomar x ml de x/x horas"
            onChange={(event) => changeProductHowToUse(product.id, event.target.value)}
          />
        </Style.VerticalContent>
      </Style.MainInfos>

      <When expr={!isMobile}>
        <Style.SortContainer>
          <Style.ButtonArrow disabled={isArrowUpDisabled} onClick={() => handleChangeProductIndex('up')}>
            <FiArrowUp size={25} color="#fff" />
          </Style.ButtonArrow>
          <Style.ButtonArrow disabled={isArrowDownDisabled} onClick={() => handleChangeProductIndex('down')}>
            <FiArrowDown size={25} color="#fff" />
          </Style.ButtonArrow>
        </Style.SortContainer>
      </When>
    </Style.ProductUseModeContainer>
  )
}
