import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const OrderPackageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;

    font-size: 1.5rem;
    color: #21976e;

    svg {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      min-height: 2rem;

      path {
        stroke: #21976e;
      }
    }
  }

  .help-icon {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    color: #3d3d3d;

    path {
      stroke: #3d3d3d;
    }
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`

export const CompanyAndStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;

  @media ${MOBILE_WIDTH} {
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;

    width: 100%;
  }
`

export const ShippingCompanyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  text-transform: uppercase;

  svg {
    margin-bottom: 2px;
  }

  span {
    font-size: 0.9rem;
    font-weight: 500;
  }

  strong {
    color: #3d3d3d;
    font-size: 1.15rem;
    font-weight: 400;
  }
`
