import CardsClasses from 'components/ClassesEComposicoesComponents/CardsClasses'
import SessionPrimary from 'components/ClassesEComposicoesComponents/SessionPrimary'
import { classesTabela } from 'assets/img'
import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import BreadCrumb from 'components/BreadCrumb'
import * as Styles from './styles'

function ClassesEComposicoes() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Styles.Container isMobile={isMobile}>
      <BreadCrumb
        paths={[
          { label: 'Produtos', link: '/produtos/1' },
          { label: 'Classes e composições', link: '/classes-e-composicoes' }
        ]}
      />
      <SessionPrimary />
      <CardsClasses />
      <img src={classesTabela} alt="" />
    </Styles.Container>
  )
}

export default ClassesEComposicoes
