import { svgLogo } from 'assets/svg'
import * as Style from './styles'

export default function Footer() {
  return (
    <Style.FooterContainer>
      <img src={svgLogo} alt="Cannect" />
      <p>
        R. Dr. Renato Paes de Barros, 33 Itaim Bibi, São Paulo/SP <br />
        faleconosco@cannect.com.br
      </p>
      <p>
        <strong>Cannect 2022 ® Todos direitos reservados.</strong>
      </p>
    </Style.FooterContainer>
  )
}
