import Texts from 'components/Text'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Styles from './styles'

function CardsClasses() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Styles.Container isMobile={isMobile}>
      <Styles.Card isMobile={isMobile}>
        <Texts type="h1" color="primary" bold>
          Espectro Completo
        </Texts>
        <Texts type="h2" color="tertiary" bold>
          Full spectrum
        </Texts>
        <Texts type="md" color="black">
          São extraídos preservando todos os fitoquímicos encontrados na cannabis. Isto significa que todos os
          fitocanabinóides estão presentes juntamente com os terpenos, óleos essenciais e demais compostos, Este tipo de
          produto promove o chamado “efeito entourage”, um efeito que tem demonstrado na prática uma maior eficiência em
          decorrência do efeito comitiva e sinergia de todos os canabinóides em conjunto.
        </Texts>
      </Styles.Card>
      <Styles.Card>
        <Texts type="h1" color="primary" bold>
          Espectro amplo
        </Texts>
        <Texts type="h2" color="tertiary" bold>
          Broad Spectrum
        </Texts>
        <Texts type="md" color="black">
          Apresentam uma forma diferente de extração em relação ao espectro amplo, normalmente mantendo todos os
          fitoquímicos porém removendo o componente THC. Eles também possuem o efeito entourage e têm sido utilizados,
          por exemplo, por atletas de alto rendimento que não podem fazer o uso da substância THC em virtude de questões
          relacionadas ao exame de doping.
        </Texts>
      </Styles.Card>
      <Styles.Card>
        <Texts type="h1" color="primary" bold>
          Isolado
        </Texts>
        <Texts type="md" color="black">
          É composto exclusivamente pelo CBD, o produto é extraído de uma forma que retiram-se todos os demais compostos
          químicos da planta. Os benefícios terapêuticos do CBD têm se mostrado positivos para tratamentos de doenças
          como epilepsia, esclerose múltipla.
        </Texts>
      </Styles.Card>
    </Styles.Container>
  )
}

export default CardsClasses
