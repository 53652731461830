import { Control, FieldValues, Path } from 'react-hook-form'
import React from 'react'
import { VariantProps } from 'tailwind-variants'
import { FormController } from '@cannect/new-components/atoms'
import { formItemVariants } from '@cannect/new-components/atoms/FormController'
import { InputFileProps } from '@cannect/new-components/atoms/Form/File/types'
import { File } from '@cannect/new-components/atoms/Form'

type FileFieldProps = Omit<InputFileProps, 'onChange'> & VariantProps<typeof formItemVariants>

export const FileField = <TFieldValues extends FieldValues>({
  control,
  name,
  alignment: formItemAlignment,
  ...props
}: FileFieldProps & {
  name: Path<TFieldValues>
  control: Control<TFieldValues, any>
}) => {
  return (
    <FormController.FormField
      control={control}
      name={name}
      data-testid="file-field"
      render={({ field, fieldState }) => (
        <FormController.FormItem alignment={formItemAlignment}>
          <FormController.FormControl>
            <File isInvalid={fieldState.invalid} message={fieldState.error?.message} {...field} {...props} />
          </FormController.FormControl>
        </FormController.FormItem>
      )}
    />
  )
}

FileField.displayName = 'FileField'
