import styled from 'styled-components'

interface VisualInputProps {
  disabled?: boolean
  hasError?: boolean
}
export const CheckboxInputContainer = styled.div<VisualInputProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${({ hasError }) => (hasError ? '#e24444' : '#646464')};
  font-weight: 300;

  input {
    display: none;
  }

  label {
    margin-top: 6px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
    font-size: 1;
  }
`

export const VisualInput = styled.label<VisualInputProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: ${({ hasError }) => (hasError ? '1px solid #e24444' : '1px solid #646464')};
  border-radius: 0.25rem;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
