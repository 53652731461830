import { ButtonLink } from '../styles'

interface ValidateLinkActionsProps {
  cancelAction?: () => void
  rescheduleAction?: () => void
  evalueteAction?: () => void
  onMyPrescription?: () => void
  withPrescription?: boolean
}

export const useValidateActions = (status: string, isFinished: boolean, withNps: boolean) => {
  const statusValidate = !!(status === 'paid' || status === 'waiting_appointment')
  const validatePayment = !!(status === 'waiting_payment' || status === 'created')
  const validateButtonActions = () => {
    if (isFinished) {
      return {
        color: '#4E4E4E',
        text: 'novo agendamento'
      }
    }
    if (statusValidate) {
      return {
        color: '#4380E6',
        text: 'acessar consulta'
      }
    }
    if (validatePayment) {
      return {
        color: '#F0780A',
        text: 'efetuar o pagamento'
      }
    }
    return {
      color: '#4E4E4E',
      text: 'novo agendamento'
    }
  }

  function ValidateLinkActions({
    rescheduleAction,
    cancelAction,
    evalueteAction,
    onMyPrescription,
    withPrescription
  }: ValidateLinkActionsProps) {
    if (isFinished) {
      return (
        <div className="box_link">
          <ButtonLink onClick={evalueteAction}> {withNps ? 'reavaliar' : 'Avaliar'} consulta</ButtonLink>
          {!!withPrescription && <ButtonLink onClick={onMyPrescription}>Ver minha prescrição</ButtonLink>}
        </div>
      )
    }
    if (statusValidate || validatePayment) {
      return (
        <div className="box_link">
          <ButtonLink onClick={rescheduleAction}> Reagendar</ButtonLink>
          <ButtonLink onClick={cancelAction}>Cancelar</ButtonLink>
        </div>
      )
    }

    if (status === 'canceled') {
      return (
        <div className="box_link">
          <ButtonLink> Consulta Cancelada</ButtonLink>
        </div>
      )
    }

    return (
      <div className="box_link">
        <ButtonLink onClick={evalueteAction}> Avaliar consulta</ButtonLink>
        {!!withPrescription && <ButtonLink onClick={onMyPrescription}>Ver minha prescrição</ButtonLink>}
      </div>
    )
  }

  const ButtonProps = {
    color: validateButtonActions().color,
    text: validateButtonActions().text
  }

  return { ButtonProps, ValidateLinkActions }
}
