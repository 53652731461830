/* eslint-disable consistent-return */
import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileField } from '@cannect/new-components/molecules/FileField'
import { useHistory } from 'react-router'
import { TDigitalJourneyFormData } from '../types'
import { FORM_SCHEMAS } from '../constants'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useSendProofAddressDocumentMutation } from '@cannect/services/resources/digitalJourney'
import { useCallback } from 'react'

type TFormFields = TDigitalJourneyFormData['send_proof_of_address_document']

export const SendProofOfAddressStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()
  const sendProofOfAddressDocumentMutation = useSendProofAddressDocumentMutation()

  const sendProofOfAddressForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.send_proof_of_address_document
    },
    resolver: zodResolver(FORM_SCHEMAS.send_proof_of_address_document)
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid }
  } = sendProofOfAddressForm

  const onSubmit = useCallback(
    (data: TFormFields) => {
      if (!formData.common_fields?.digital_journey_id) return

      return sendProofOfAddressDocumentMutation.mutateAsync(
        {
          digital_journey_id: formData.common_fields?.digital_journey_id,
          proof_of_address_files: data.proof_of_address_files
        },
        {
          onSuccess: (response) => {
            const readFromOcrData = {
              address_cep: response?.ocr?.send_proof_of_address_document?.address_cep || '',
              address_number: response?.ocr?.send_proof_of_address_document?.address_number || '',
              address_city: response?.ocr?.send_proof_of_address_document?.address_city || '',
              address_neighborhood: response?.ocr?.send_proof_of_address_document?.address_neighborhood || '',
              address_complement: response?.ocr?.send_proof_of_address_document?.address_complement || '',
              address_state: response?.ocr?.send_proof_of_address_document?.address_state || '',
              address_street: response?.ocr?.send_proof_of_address_document?.address_street || '',
              address_holder_cpf: response?.ocr?.send_proof_of_address_document?.address_holder_cpf || '',
              address_holder_full_name: response?.ocr?.send_proof_of_address_document?.address_holder_full_name || ''
            }

            // if file has some id it means that is a file that backend has already sent to ocr
            const fileChanged = data.proof_of_address_files.some((file) => !file.id)
            // we do this to avoid clearing confirm_proof_of_address_document fields always that the user come back.
            const shouldUseOcrData = fileChanged

            updateFormData({
              send_proof_of_address_document: {
                proof_of_address_files: [
                  ...(response?.form_data?.send_proof_of_address_document?.proof_of_address_files || [])
                ]
              },
              confirm_proof_of_address_document: shouldUseOcrData
                ? readFromOcrData
                : { ...formData.confirm_proof_of_address_document }
            })

            if (response?.next_step) {
              history.push(response.next_step)
            }
          }
        }
      )
    },
    [formData, updateFormData, history, sendProofOfAddressDocumentMutation]
  )

  return (
    <div className="flex flex-col justify-center gap-4">
      <div className="mb-4 mt-auto flex flex-col items-center gap-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Onde você mora?
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Também precisamos de um comprovante de endereço em nome do titular da receita, emitido nos{' '}
          <span className="font-semibold">últimos 5 meses</span> e com o{' '}
          <span className="font-semibold">mesmo CEP da entrega do medicamento.</span> Ordens da Anvisa! 🫡
        </Typography.Text>
      </div>

      <FormController.Form {...sendProofOfAddressForm}>
        <FileField
          name="proof_of_address_files"
          control={control}
          showDescriptionFilesAccept
          canDownload
          showListFiles
          acceptFormats={['.pdf', '.png', '.jpeg', '.jpg']}
        />

        <Button
          className="mt-auto font-medium"
          full
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          size="lg"
          disabled={!isValid}>
          Continuar
        </Button>
      </FormController.Form>
    </div>
  )
}
