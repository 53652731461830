import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const OtherSummaryInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  border-radius: 0 0.5rem 0.5rem 0;
  background: #f1f2fa;
  padding: 1.5rem;

  height: 100%;
  width: 100%;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 2rem;
    border-radius: 0 0 0.5rem 0.5rem;
  }
`

export const OtherSummaryInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  height: 100%;

  label {
    font-weight: 600;
  }

  span {
    color: #3d3d3d;
    font-size: 0.85rem;
  }
`

export const TalkToCannecterContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: #3d3d3d;
`
