import InvisibleButton from 'components/InvisibleButton'
import Loading from 'components/Loading'
import LinkAsButton from 'new-components/LinkAsButton'
import { useMemo, useState } from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { toast } from 'react-toastify'
import api from 'services/api'
import { formatZipCode } from 'utils/formatters'
import { Address } from '../../types'
import * as Styles from './styles'

interface AddressCardProps {
  address: Address
  onSelectedAsMain: () => void
  usage?: 'professional' | 'patient'
}

export default function AddressCard({ address, onSelectedAsMain, usage = 'professional' }: AddressCardProps) {
  const [loading, setLoading] = useState(false)

  const handleSelectAsMainAddress = async () => {
    setLoading(true)
    try {
      const response = await api.put(`/my-account/address/${address.id}/main`)
      if (!response.data.success) {
        toast.error('Houve um erro ao tentar selecionar endereço como principal. Tente novamente mais tarde.')
        return
      }
      onSelectedAsMain()
    } catch (err) {
      console.error(err)
      toast.error('Houve um erro ao tentar selecionar endereço como principal. Tente novamente mais tarde.')
    }
    setLoading(false)
  }

  const editAddressLink = useMemo(() => {
    if (usage === 'professional') {
      return `/conta?pagina=perfil-enderecos-${address.id}`
    }
    return `/conta?pagina=enderecos-${address.id}`
  }, [address])

  const handleEditAddressClick = () => {
    localStorage.setItem(
      '@CANNECT_NEW_ADDRESS_FROM',
      `/conta?pagina=enderecos-${address.isDelivery ? 'entrega' : 'cobranca'}`
    )
  }

  return (
    <Styles.AddressCardContainer usage={usage}>
      <Loading loading={loading} />
      <Styles.AddressCardHeader>
        <h3>{address.address}</h3>
        <LinkAsButton buttonStyle="ghost" background="grey" to={editAddressLink} onClick={handleEditAddressClick}>
          <MdOutlineEdit />
        </LinkAsButton>
      </Styles.AddressCardHeader>

      <Styles.AddressCardBody useDivider={!address.main}>
        {address.additional_information && <p>{address.additional_information}</p>}
        <p>
          {address.address}, {address.number}
        </p>
        <p>
          {address.district} - {address.city}/{address.state}
        </p>
        <p>CEP: {formatZipCode(address.cep)}</p>
      </Styles.AddressCardBody>

      {!address.main && (
        <Styles.AddressCardFooter>
          <InvisibleButton color="#21976e" onClick={handleSelectAsMainAddress}>
            Selecionar como endereço principal
          </InvisibleButton>
        </Styles.AddressCardFooter>
      )}
    </Styles.AddressCardContainer>
  )
}
