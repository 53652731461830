import * as React from 'react'
import { SVGProps } from 'react'

const OrderCreatedIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width={157} height={169} fill="none">
    <circle cx={78.5} cy={78.5} r={78.5} fill="#F5ECD3" />
    <path
      fill="#A4C55E"
      d="M86.272 8.413c1.234-.036 4.165-.031 5.085-.031 1.213 0 1.88 0 2.742 1.161.709.948 2.789 3.339 3.818 4.814.893 1.282 1.834 2.543 2.732 3.83.945 1.36 1.759 1.36 2.847.042 4.386-5.305 8.893-10.512 13.258-15.838 1.329-1.622 2.768-2.464 4.911-2.386 3.404.126 6.813.058 10.222.073.698 0 1.518-.083 1.891.612.399.743-.258 1.288-.699 1.816-9.922 11.81-19.881 23.592-29.746 35.449-1.555 1.868-2.427 1.873-3.86-.12-6.451-8.99-13.028-17.885-19.546-26.821-.436-.602-1.24-1.235-.799-2.046.431-.785 1.403-.539 2.17-.544 1.66-.021 3.32-.005 4.98-.005l-.006-.006Z"
    />
    <path
      fill="#6C7B3C"
      d="M113.909 121.415c-.016.165-.028.327-.044.492-1.555 1.751-3.147 3.458-4.993 4.924-3.291 2.611-7.03 4.295-11.009 5.516-2.016.337-4.02.736-6.072.839-4.684.24-9.124-.971-13.561-2.222-.82-.232-1.648-.423-2.472-.634-.435-.162-.863-.347-1.306-.487-5.637-1.769-11.156-3.837-16.476-6.414 3.392-3.487 5.297-7.661 5.992-12.469.853-5.903.109-11.748-.399-17.61-.242-2.799-.098-5.53 1.182-8.103 1.236.025 2.472.072 3.709.067.53 0 .775.141.749.718-.165 3.51-1.1 6.665-3.778 8.748.886.129 1.406-.778 1.978-1.55 1.671-2.256 2.114-4.89 2.235-7.613.031-.68-.378-.744-.906-.739-1.28.013-2.56 0-3.842 0 .013-.484.242-.89.448-1.31 1.31-2.676 3.255-4.824 5.544-6.688l1.514.08-1.02 2.459.302.136c.386-.87.772-1.743 1.161-2.613 2.12 0 4.236-.005 6.356-.008-.06.834-.397 1.656-.155 2.838.379-1.128.461-1.98.513-2.84h8.63c.233.885.419 1.794 1.173 2.471-.267-.824-.535-1.648-.8-2.472l2.382-.059c1.431 1.486 2.593 3.18 3.728 4.89 5.872 8.849 10.371 18.413 14.517 28.15 1.625 3.813 3.154 7.668 4.728 11.505l-.008-.002Zm-17.135 7.955c-.384-5.269-20.2-31.07-24.974-32.63 9.641 9.837 19.123 19.749 24.974 32.63Zm-13.37-44.487c-2.094 0-4.19.008-6.284-.005-.574-.002-1.022.039-.943.814.279 2.707.783 5.33 2.473 7.574 2.467 3.278 6.865 3.368 9.463.185 1.726-2.112 2.305-4.61 2.516-7.264.08-1.007-.123-1.373-1.197-1.33-2.006.083-4.017.024-6.026.024l-.003.002Z"
    />
    <path
      fill="#F2ACA4"
      d="M19.264 90.858c6.23-.744 11.614-3.242 16.134-7.633 1.591-1.545 3.352-2.912 5.026-4.37.412-.358.93-.62 1.157-1.164.213-.02.376-.108.556-.252 4.602-3.68 9.381-7.116 14.372-10.252 3.863-2.426 7.751-4.813 11.897-6.745-1.112 2.923-1.184 5.88-.358 8.887.544 1.976 1.314 3.86 2.053 5.761-8.344 4.836-16.183 10.38-23.434 16.741-4.177 3.665-8.318 7.373-11.849 11.697-1.233 1.511-1.388 2.789-.445 4.473.126.224.317.412.193.695-2.305.598-4.45.551-6.06-1.55-.149-.196-.231-.443-.345-.667-.489-2.395.08-4.489 1.88-6.235-2.137 1.538-2.742 3.618-2.292 6.08-2.217-.373-3.288-1.45-3.685-3.708.709-1.29.335-2.948 1.489-4.09-1.36.98-1.417 2.46-1.636 3.881-1.915-1.063-2.6-3.175-1.668-5.505.317-.788.273-1.095-.608-1.257-.657-.118-1.3-.394-1.91-.685-2.13-1.012-2.303-2.621-.47-4.105l.003.003Zm6.423 7.457c.486-.254.746-.53 1.05-1.254-.643.456-.973.68-1.05 1.254ZM97.863 132.347c3.979-1.221 7.718-2.905 11.009-5.516 1.846-1.465 3.438-3.173 4.993-4.924 1.803 4.893 3.618 9.778 5.405 14.676 1.113 3.052 2.179 6.119 3.268 9.181l-20.552.028c-1.37-4.483-2.544-9.026-4.123-13.445Z"
    />
    <path
      fill="#7D7660"
      d="M32.77 152.511c.557-1.968 1.556-3.807 1.693-5.892.306-.286.324-.675.41-1.051.733-3.327.9-6.693.7-10.085l9.85-4.071c.772 1.79 1.594 3.564 2.302 5.38.425 1.092 1.2 2.057 1.241 3.291-.466-.219-.816.095-1.195.268-2.41 1.099-4.818 2.201-7.228 3.304l-2.13.97.16.335 10.393-4.782c.53.301.615.881.845 1.365.994 2.091 1.705 4.311 2.892 6.314-.325.703-1.061.858-1.656 1.105-7.491 3.077-10.934 9.067-12.155 16.703-.085.53-.008 1.127-.422 1.581-.279-.662-.577-1.319-.835-1.988-1.627-4.247-3.244-8.496-4.864-12.745v-.002ZM122.642 166.942c-2.078-1.589-4.117-3.237-6.571-4.247-3.392-1.831-6.989-2.84-10.868-2.75l-2.449-10.821c3.402-.088 6.807-.124 10.208.013 1.051 3.644 2.099 7.29 3.15 10.934l.396-.116c-1.055-3.603-2.111-7.205-3.164-10.811.633-.028 1.267-.069 1.903-.082 2.796-.064 5.598.131 8.395-.095.837 1.952 1.604 3.94 2.526 5.851 1.931 4.012 4.499 7.527 8.204 10.12 1.164.816 2.483 1.298 3.737 1.919-.209.023-.415.069-.621.069-4.947.008-9.896.01-14.843.013l-.003.003Z"
    />
    <path
      fill="#F2ACA4"
      d="M136.177 59.987c-.103-2.38-1.272-3.482-3.662-3.415-4.743.137-9.443.711-14.127 1.427-.42.064-.847.087-1.27.129.129-.407.281-.809.387-1.22 1.11-4.325 1.079-8.468-1.952-12.127 1.177-.204 2.346-.454 3.528-.603 5.106-.636 9.342-3.113 13.246-6.265.943-.763 1.867-1.293 2.995-.57 1.185.76 1.128 1.898.721 3.075-.249.721-.512 1.435-.785 2.194 1.015.17 1.926.508 2.73 1.102 1.372 1.218 2.101 2.66 1.565 4.533-.141.489.044.906.17 1.362.369 1.329.335 2.616-.62 3.737-.49-.335-.631-1.051-1.298-1.193-1.203-1.277-3.497-1.797-4.865-.96 1.811-.157 3.389.049 4.777 1.027.423.497.842.994 1.265 1.491.96 2.557-.129 4.996-2.807 6.279l.002-.003Zm2.431-12.036c.116.09.232.18.348.273.028-.07.098-.173.077-.199-.069-.08-.175-.131-.265-.195-.814-.863-1.787-1.365-3.01-1.306 1.091.203 1.939.878 2.85 1.427ZM57.78 123.445c.068.01.132.007.197-.013 5.32 2.577 10.838 4.645 16.476 6.414.442.139.87.325 1.305.487-5.078 7.303-12.157 12.139-19.911 16.141-.108.054-.25.042-.376.06-1.44-3.369-2.843-6.753-4.334-10.098-.948-2.127-1.654-4.367-2.92-6.348.208-.473.682-.607 1.076-.839 2.967-1.733 5.866-3.567 8.488-5.807v.003Z"
    />
    <path
      fill="#100B0B"
      d="M35.57 135.481c.202 3.392.034 6.758-.7 10.085-.082.376-.1.767-.41 1.051-.705-1.365-1.292-1.592-2.652-1.018-.077.034-.146.09-.226.119-1.069.373-2.223 1.54-3.09 1.112-.742-.365-.917-1.908-1.314-2.941-.66-1.712-1.277-3.44-1.947-5.147-.195-.503-.231-.786.417-.871 3.392-.445 6.634-1.506 9.923-2.39ZM105.206 159.945c3.876-.09 7.476.919 10.867 2.75-1.601.266-2.05.92-1.679 2.46.011.041.018.082.026.126.232 1.156 1.231 2.621.631 3.35-.59.713-2.251.167-3.44.196-1.327.033-2.655-.021-3.979.023-.636.021-.889-.173-.997-.824-.443-2.699-.947-5.387-1.429-8.078v-.003Z"
    />
    <path
      fill="#F9F4E8"
      d="M123.639 148.97c-2.797.226-5.596.031-8.395.095-.634.016-1.267.054-1.901.082a16.146 16.146 0 0 1-.381-.01c-3.402-.136-6.806-.1-10.208-.013-.363-1.086-.68-2.183-.77-3.332l20.553-.028c.528 1.012.916 2.073 1.102 3.203v.003ZM48.217 130.088c1.267 1.98 1.972 4.221 2.92 6.348 1.491 3.345 2.894 6.729 4.334 10.097-.83.639-1.712 1.167-2.773 1.324-1.188-2.004-1.898-4.224-2.892-6.315-.232-.486-.315-1.063-.845-1.365v-.097c-.041-1.237-.816-2.202-1.241-3.292-.709-1.815-1.53-3.587-2.303-5.379.848-.623 1.731-1.164 2.797-1.321h.003Z"
    />
    <path
      fill="#100B0B"
      d="M32.772 152.51c1.62 4.249 3.236 8.498 4.864 12.745.258.672.554 1.326.834 1.988-.072 1.182.42 1.548 1.63 1.44 1.605-.145 3.235-.037 4.852.164H25.891a4.88 4.88 0 0 0-.005-.195h4.957c1.54 0 3.082-.042 4.62.007.896.029 1.207-.049.788-1.081-1.368-3.366-2.509-6.824-3.956-10.152-.803-1.843-.767-3.381.477-4.918v.002ZM122.642 166.942c4.947-.003 9.897-.005 14.844-.013.206 0 .412-.044.62-.07.482.304 1.473-.481 1.535.76.039.791-.09 1.203-1.043 1.198-4.511-.031-9.026.023-13.537.005-1.237-.005-1.633-1.224-2.421-1.878l.002-.002ZM27.752 106.327c-.45-2.462.155-4.54 2.292-6.08-1.8 1.746-2.369 3.837-1.88 6.235l-.412-.155ZM23.92 102.408c.218-1.419.272-2.902 1.634-3.88-1.153 1.143-.78 2.799-1.488 4.089a1.77 1.77 0 0 1-.147-.211v.002ZM137.807 52.15c.667.145.806.86 1.296 1.195l-.121.363c-.423-.497-.842-.994-1.265-1.49l.088-.068h.002Z"
    />
    <path
      fill="#4F4A3E"
      d="M68.41 60.44c-4.147 1.932-8.035 4.32-11.898 6.745-4.99 3.134-9.77 6.57-14.372 10.252-.18.144-.34.232-.556.252-1.314.042-2.606.392-3.938.152-5.881-1.058-8.459-7.285-5.05-12.237 1.18-1.715 2.782-2.89 4.739-3.559 3.06-1.048 5.003-3.139 6-6.193 1.066-3.268 3.317-5.094 6.778-5.344 2.438-.177 4.836-.577 7.151-1.38 2.192-.76 4.056-2.016 5.496-3.845 1.962-2.49 4.509-3.564 7.65-3.275-.368.103-.733.218-1.104.309-1.13.27-1.952.911-2.215 2.062-.27 1.18.345 2.06 1.203 2.787.36.306.77.553 1.213.87-1.896.482-2.692 1.092-2.64 1.965.057.909 1.156 1.715 2.68 1.702 1.051-.008 1.038.464.984 1.247-.17 2.451-.654 4.818-1.668 7.063-.15.145-.302.291-.45.436l-.004-.008Z"
    />
    <path
      fill="#F2ACA4"
      d="M68.86 60.005c1.014-2.245 1.498-4.612 1.668-7.064.054-.785.067-1.254-.983-1.246-1.525.01-2.624-.793-2.681-1.702-.054-.873.744-1.483 2.64-1.965-.444-.314-.853-.564-1.214-.87-.854-.727-1.47-1.605-1.202-2.787.263-1.148 1.084-1.792 2.215-2.062.37-.088.736-.204 1.104-.31.716-.074 1.347.237 1.96.505 1.213.53 2.261 1.316 3.796.716.95-.37 1.55.66.888 1.638-.548.806-.324 1.347.029 2.065.965 1.968 1.15 3.912-.34 5.769-.77.958-.518 2.147-.464 3.221.039.814.703.284 1.043.134a170.217 170.217 0 0 1 10.115-4.04 161.339 161.339 0 0 1 22.304-6.263c1.694-.348 3.381-.667 5.101-.824.67.203 1.064.739 1.352 1.295 2.081 4.002 2.176 8.055.23 12.142-5.001.793-9.928 1.937-14.784 3.363-3.799 1.115-7.605 2.228-11.23 3.85-.58-.005-1.062.304-1.58.5-5.825 2.217-12.51-1.787-12.757-8.341-.036-.96-.345-.956-1.063-.61-2.04.983-4.098 1.928-6.147 2.886Zm4.92-12.87c.822.465 1.603.538 2.378 0H73.78Zm-.293-.22c-.043-.066-.077-.16-.139-.187-.054-.024-.141.025-.214.043.062.088.121.178.194.255.005.005.1-.07.157-.11h.002Z"
    />
    <path
      fill="#100B0B"
      d="M116.421 58.36c1.946-4.085 1.854-8.14-.23-12.143-.288-.553-.682-1.091-1.352-1.295-.085-.165-.455-.324-.249-.46.358-.238.613.27.963.316 3.031 3.66 3.062 7.803 1.952 12.126-.106.412-.255.814-.387 1.221-.422.59-.79 1.226-1.274 1.756-1.028 1.123-1.945 1.278-3.464.598 1.937.358 3.304-.31 4.041-2.122v.002Z"
    />
    <path
      fill="#CBD7A2"
      d="M68.86 60.005c2.05-.96 4.107-1.903 6.146-2.887.719-.345 1.028-.35 1.064.61.247 6.554 6.932 10.559 12.757 8.341.515-.195.997-.504 1.579-.5.572 2.437 1.21 4.85 1.074 7.404-.072 1.324-.217 2.62-.541 3.897l-2.382.059h-9.361c-2.12 0-4.236.005-6.355.008l-.443.02c-.505-.025-1.01-.054-1.515-.08l-.782-1.787c-.74-1.9-1.512-3.788-2.053-5.76-.826-3.008-.754-5.965.358-8.887l.453-.436v-.002Z"
    />
    <path
      fill="#100B0B"
      d="M96.774 129.37c-5.85-12.881-15.332-22.793-24.974-32.63 4.775 1.56 24.593 27.361 24.974 32.63ZM83.404 84.884c2.008 0 4.02.057 6.026-.023 1.073-.044 1.277.322 1.197 1.329-.208 2.655-.79 5.153-2.516 7.264-2.6 3.183-7 3.093-9.464-.185-1.686-2.243-2.194-4.867-2.472-7.574-.08-.775.368-.816.943-.813 2.093.012 4.19.005 6.283.005l.003-.003Zm-6.835.44c.23 2.828.719 5.45 2.377 7.708 2.181 2.974 6.178 3.214 8.63.471 1.887-2.112 2.47-4.715 2.67-7.45.052-.692-.32-.739-.855-.736-2.434.015-4.87.007-7.306.007h-5.518.002ZM64.751 85.25c.047-.124.096-.25.142-.374 1.28.003 2.56.015 3.84.003.527-.006.937.059.906.739-.121 2.722-.564 5.356-2.235 7.612-.572.772-1.092 1.681-1.978 1.55 2.678-2.083 3.613-5.238 3.778-8.748.028-.577-.22-.718-.75-.718-1.236.005-2.472-.041-3.708-.067l.005.002ZM72.4 76.96c.147-.008.294-.013.443-.02-.386.87-.773 1.743-1.161 2.613l-.302-.136 1.02-2.46v.003ZM88.18 76.932h.374c.268.824.536 1.648.801 2.472-.754-.677-.94-1.587-1.174-2.472ZM79.197 76.932h.357c-.051.857-.133 1.71-.512 2.84-.242-1.184.095-2.003.154-2.838v-.002ZM25.686 98.316c.077-.575.407-.796 1.05-1.255-.303.724-.566 1-1.05 1.255ZM48.961 140.08v.098c-3.464 1.591-6.93 3.188-10.393 4.782l-.16-.335 2.13-.971c2.41-1.102 4.818-2.204 7.228-3.304.379-.172.726-.486 1.195-.267v-.003ZM112.961 149.137c.129.002.255.007.382.01 1.053 3.603 2.109 7.205 3.164 10.808l-.396.116c-1.051-3.644-2.099-7.29-3.15-10.934ZM137.72 52.218c-1.388-.98-2.967-1.185-4.777-1.028 1.367-.837 3.659-.317 4.864.96l-.085.068h-.002ZM138.608 47.953c-.911-.55-1.759-1.225-2.85-1.429 1.223-.059 2.196.443 3.01 1.306l-.162.123h.002Z"
    />
    <path
      fill="#100B0B"
      d="M138.768 47.83c.09.062.196.113.265.196.021.025-.049.129-.077.198l-.348-.27.16-.124ZM73.78 47.134h2.378c-.776.538-1.553.466-2.377 0ZM73.485 46.918c-.055.039-.15.116-.155.108-.072-.077-.131-.167-.193-.254.072-.018.16-.07.214-.044.061.028.092.123.136.19h-.002Z"
    />
    <path
      fill="#6C7B3C"
      d="M76.57 85.324h5.519c2.436 0 4.87.008 7.306-.007.535-.003.906.043.855.736-.201 2.735-.783 5.338-2.67 7.45-2.452 2.743-6.449 2.503-8.63-.471-1.659-2.261-2.148-4.88-2.377-7.708h-.003Z"
    />
  </svg>
)
export const OrderCreated = React.memo(OrderCreatedIllustration)
