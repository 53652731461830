import useMediaQuery from 'hooks/useMediaQuery'
import React from 'react'
import { ANVISA, IDENTIDADE, MOBILE_WIDTH, PRESCRICAO, RESIDENCIAL, VINCULO } from 'utils/constants'
import UploadDocument from 'components/UploadDocument'
import CheckboxInput from 'new-components/CheckboxInput'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { useDocument } from './useDocument'
import * as Style from './styles'

function Documents() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { handelDocuments, handleChangeDocuments, showDependentDocumentInput, setShowDependentDocumentInput } =
    useDocument()

  const { patientIsUser } = usePrepareCart()

  return (
    <Style.Container isMobile={isMobile}>
      <Style.Title>
        {patientIsUser ? (
          <h1>
            Documentos do <strong>Paciente:</strong>
          </h1>
        ) : (
          <h1>
            Inserir documentos do <strong>Paciente:</strong>
          </h1>
        )}
      </Style.Title>
      <div className="horizontal-uploads">
        <UploadDocument
          label="Pedido/receita médica"
          documentId={handelDocuments(PRESCRICAO)?.originalName || handelDocuments(PRESCRICAO)?.url}
          status={handelDocuments(PRESCRICAO)?.status}
          handleChange={e => handleChangeDocuments(e, PRESCRICAO)}
          documentData={handelDocuments(PRESCRICAO)}
          /* eslint-disable-next-line react/no-children-prop */
          children={
            <CheckboxInput
              id="proof-of-residence-in-other-name"
              label="A receita e documentos enviados estão em nome de outra pessoa"
              checked={showDependentDocumentInput}
              onChange={e => setShowDependentDocumentInput(e.target.checked)}
              style={{ fontSize: '0.85rem' }}
            />
          }
        />
        {showDependentDocumentInput && (
          <UploadDocument
            label="Comprovante de vínculo (dependente)"
            status={handelDocuments(VINCULO)?.status}
            documentId={handelDocuments(VINCULO)?.originalName || handelDocuments(VINCULO)?.url}
            handleChange={e => handleChangeDocuments(e, VINCULO)}
          />
        )}
      </div>
      <div className="horizontal-uploads">
        <UploadDocument
          label="Documento de identidade (RG ou CNH)"
          documentId={handelDocuments(IDENTIDADE)?.originalName || handelDocuments(IDENTIDADE)?.url}
          handleChange={e => handleChangeDocuments(e, IDENTIDADE)}
          status={handelDocuments(IDENTIDADE)?.status}
        />
        <UploadDocument
          label="Comprovante de residência"
          documentId={handelDocuments(RESIDENCIAL)?.originalName || handelDocuments(RESIDENCIAL)?.url}
          status={handelDocuments(RESIDENCIAL)?.status}
          handleChange={e => handleChangeDocuments(e, RESIDENCIAL)}
          /* eslint-disable-next-line react/no-children-prop */
          children={
            <CheckboxInput
              id="proof-of-residence-in-other-name"
              label="O comprovante enviado está em nome de outra pessoa"
              checked={showDependentDocumentInput}
              onChange={e => setShowDependentDocumentInput(e.target.checked)}
              style={{ fontSize: '0.85rem' }}
            />
          }
        />
      </div>
      <div className="horizontal-uploads">
        <UploadDocument
          label="Autorização ANVISA"
          documentId={handelDocuments(ANVISA)?.originalName || handelDocuments(ANVISA)?.url}
          status={handelDocuments(ANVISA)?.status}
          handleChange={e => handleChangeDocuments(e, ANVISA)}
          tolltip={
            <div>
              <p>Caso opte por emitir a autorização, veja nosso vídeo com o passo a passo abaixo:</p>
              <a href="https://www.youtube.com/watch?v=cA9OQbNffME" target="_blank" rel="noreferrer">
                Liberação Anvisa
              </a>
            </div>
          }
        />
      </div>
    </Style.Container>
  )
}

export default Documents
