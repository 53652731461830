import styled from 'styled-components'
import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'

export const InitialQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .buttons_actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 16px;
    button {
      width: 300px;
    }

    ${media.tablet`
      button {
        width: 50%;
      }
    `}

    ${media.mobile`
      flex-direction: column-reverse;
      button {
        width: 100%;
      }
    `}
  }
`

export const ButtonBack = styled.button`
  background-color: transparent;
  border: 1px solid ${theme.colors.tertiary};
  color: ${theme.colors.tertiary} !important;
  padding: 10px 16px;
  border-radius: 5px;
`
