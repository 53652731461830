import { OrderStatus } from 'pages/Account/components/pages/MyOrders/types'

interface StatusesList {
  status: OrderStatus
  label: string
}

export const NATIONAL_STATUSES_LIST: StatusesList[] = [
  {
    status: 'approved_documents',
    label: 'documentos aprovados'
  },
  {
    status: 'finished_items',
    label: 'itens finalizados'
  },
  {
    status: 'dispatched',
    label: 'pacote enviado'
  },
  {
    status: 'delivered',
    label: 'pedido entregue'
  }
]

export const INTERNATIONAL_STATUSES_LIST: StatusesList[] = [
  {
    status: 'approved_documents',
    label: 'documentos aprovados'
  },
  {
    status: 'finished_items',
    label: 'itens separados'
  },
  {
    status: 'dispatched',
    label: 'pacote enviado'
  },
  {
    status: 'anvisa_br',
    label: 'chegou no Brasil'
  },
  {
    status: 'delay_release',
    label: 'inspeção ANVISA'
  },
  {
    status: 'release_anvisa',
    label: 'liberado pela ANVISA'
  },
  {
    status: 'delivered',
    label: 'pedido entregue'
  }
]

export const ALL_STATUSES_LIST = [
  {
    status: 'created',
    label: 'criado'
  },
  {
    status: 'waiting_documentation',
    label: 'aguardando documentos'
  },
  {
    status: 'waiting_anvisa',
    label: 'aguardando ANVISA'
  },
  {
    status: 'waiting_approval',
    label: 'aguardando aprovação'
  },
  {
    status: 'awaiting_payment',
    label: 'aguardando pagamento'
  },
  {
    status: 'paid',
    label: 'pagamento aprovado'
  },
  {
    status: 'approved_documents',
    label: 'documentos aprovados'
  },
  {
    status: 'finished_items',
    label: 'itens finalizados'
  },
  {
    status: 'dispatched',
    label: 'pacote enviado'
  },
  {
    status: 'anvisa_br',
    label: 'chegou no Brasil'
  },
  {
    status: 'delay_release',
    label: 'inspeção ANVISA'
  },
  {
    status: 'release_anvisa',
    label: 'liberado pela ANVISA'
  },
  {
    status: 'delivered',
    label: 'pedido entregue'
  },
  {
    status: 'shipping_supplier',
    label: 'enviado para fornecedor'
  },
  {
    status: 'waiting_payment',
    label: 'aguardando pagamento'
  },
  {
    status: 'monitor_order',
    label: 'monitorar pedido'
  },
  {
    status: 'order_matriz',
    label: 'pedido matriz'
  },
  {
    status: 'pharmacy_order',
    label: 'pedido farmácia'
  },
  {
    status: 'awaiting_invoice',
    label: 'aguardando nota fiscal'
  },
  {
    status: 'anvisa_exception',
    label: 'exceção ANVISA'
  }
]
