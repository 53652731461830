import api from 'services/api'

const KEY_CART = 'shoppingCart'
const FAVORITED = 'favorited'

const getProduct = () => localStorage.getItem(KEY_CART)

const getAllProduct = () => {
  const produtos = getProduct()
  return produtos ? JSON.parse(produtos) : []
}

const setProduct = (product: any) => {
  const produtos = getProduct()
  if (produtos) {
    const produtosArray = JSON.parse(produtos)
    const newProdutos = produtosArray.filter((item: any) => item.id === product.id)
    if (newProdutos.length === 0) {
      produtosArray.push({ ...product, amount: 1 })
      localStorage.setItem(KEY_CART, JSON.stringify(produtosArray))
    }
  } else {
    localStorage.setItem(KEY_CART, JSON.stringify([{ ...product, amount: 1 }]))
  }
}

const setFavorited = (product: any) => {
  const produtos = getProduct()
  if (produtos) {
    const produtosArray = JSON.parse(produtos)
    const newProdutos = produtosArray.map((item: any) => {
      return item.id === product.id ? { ...item, favorited: item.favorited } : item
    })

    localStorage.setItem(FAVORITED, JSON.stringify(newProdutos))
  }
}

const getFavorite = (product: any) => {
  const produtos = api.get('/product/favorites/?page=1&limit=15')
  return produtos
}

const IncrementProductByID = (id: any) => {
  const produtos = getProduct()
  if (produtos) {
    const produtosArray = JSON.parse(produtos)
    const newProdutos = produtosArray.map((item: any) => {
      return item.id !== id ? item : { ...item, amount: item.amount + 1 }
    })
    localStorage.setItem(KEY_CART, JSON.stringify(newProdutos))
  }
}
const DecrementProductByID = (id: any) => {
  const produtos = api.get('/product/favorites/?page=1&limit=15')
  return produtos
}

const deleteProductByID = (id: any) => {
  const produtos = api.post(`/product/favorite/${id}`)
  return produtos
}

const deleteAllProduct = () => {
  localStorage.removeItem(KEY_CART)
}

export {
  getProduct,
  setProduct,
  setFavorited,
  deleteProductByID,
  getAllProduct,
  IncrementProductByID,
  DecrementProductByID,
  deleteAllProduct,
  getFavorite
}
