import { format, isToday, isYesterday, parseISO } from 'date-fns'

/* eslint-disable no-param-reassign */

export function formatIsoDateToDisplay(date: string) {
  if (!date) return '-'
  const data = new Date(date)
  const dia = data.getDate().toString().padStart(2, '0')
  const mes = (data.getMonth() + 1).toString().padStart(2, '0')
  const ano = data.getFullYear()
  return `${dia}/${mes}/${ano}`
}

export function formatPhone(phone?: string): string {
    if (!phone) return ''
    if (phone.length >= 16) return phone.slice(0, 15)
  
    let formattedPhone = clearMask(phone)
  
    if (formattedPhone.length <= 10) {
      formattedPhone = formattedPhone.replace(/(\d{0})(\d)/, '$1($2')
      formattedPhone = formattedPhone.replace(/(\d{2})(\d)/, '$1) $2')
      formattedPhone = formattedPhone.replace(/(\d{4})(\d)/, '$1-$2')
    } else {
      formattedPhone = formattedPhone.replace(/(\d{0})(\d)/, '$1($2')
      formattedPhone = formattedPhone.replace(/(\d{2})(\d)/, '$1) $2')
      formattedPhone = formattedPhone.replace(/(\d{5})(\d)/, '$1-$2')
    }
  
    formattedPhone = formattedPhone.slice(0, 15)
    return formattedPhone
  }

export function formatCpf(cpf?: string): string {
  if (!cpf) return ''

  if (cpf.length >= 14) return cpf.slice(0, 14)

  let formattedCpf = cpf
  formattedCpf = clearMask(cpf)

  formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2')
  formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2')
  formattedCpf = formattedCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

  return formattedCpf
}

export function keepOnlyDigits(value?: string) {
  if (!value) return ''
  return value.replace(/\D/g, '')
}

export function formatZipCode(zipCode = ''): string {
  if (zipCode.length >= 9) return zipCode.slice(0, 9)

  let formattedZipCode = zipCode
  formattedZipCode = clearMask(formattedZipCode)
  formattedZipCode = formattedZipCode.replace(/(\d{5})(\d)/, '$1-$2')

  return formattedZipCode
}

export function clearMask(value?: string): string {
  if (!value) return ''
  return value.replace(/\D/g, '')
}

export function dateMask(date: string, format: 'fullDate' | 'dayAndMonth' | 'monthAndYear' = 'fullDate'): string {
  let formattedBirthday = date

  if (!formattedBirthday) {
    formattedBirthday = ''
  }

  if (format === 'fullDate') {
    if (formattedBirthday.length <= 10) {
      formattedBirthday = clearMask(formattedBirthday)

      formattedBirthday = formattedBirthday.replace(/(\d{2})(\d)/, '$1/$2')
      formattedBirthday = formattedBirthday.replace(/(\d{2})(\d)/, '$1/$2')
    } else {
      formattedBirthday = formattedBirthday.slice(0, 10)
    }
  } else if (format === 'dayAndMonth') {
    if (formattedBirthday.length <= 5) {
      formattedBirthday = clearMask(formattedBirthday)
      formattedBirthday = formattedBirthday.replace(/(\d{2})(\d)/, '$1/$2')
    } else {
      formattedBirthday = formattedBirthday.slice(0, 5)
    }
  } else if (formattedBirthday.length <= 7) {
    formattedBirthday = clearMask(formattedBirthday)
    formattedBirthday = formattedBirthday.replace(/(\d{2})(\d)/, '$1/$2')
  } else {
    formattedBirthday = formattedBirthday.slice(0, 7)
  }

  return formattedBirthday
}

export function formatExpertise(expertises: string[]): string {
  expertises[0]?.split(',')
  const especialidades: string[] = []

  // eslint-disable-next-line array-callback-return
  expertises.map(esp => {
    const formated = esp.split(' - ')

    especialidades.push(formated[0]?.trim())
  })

  const especialideFormatada = especialidades?.join(' - ')

  return especialideFormatada
}

export function expertiseArray(expertise: string): string[] {
  const especialidadeFormatada = expertise.split(' - ')

  return especialidadeFormatada
}

export const currencyUnmasked = (value = '') => value.replace(/[^0-9,]*/g, '').replace(',', '.')

export const currencyMask = (param: string | number) => {
  if (!param) {
    return 'R$ 0,00'
  }

  let value = ''

  if (typeof param === 'number') {
    // eslint-disable-next-line no-param-reassign
    param = param.toFixed(2)
  }

  if (String(param).includes('.') || String(param).includes(',')) {
    value = String(param)
  } else {
    value = `${String(param)}.00`
  }

  value = String(value).replace(/\D/g, '')

  const v = `${(Number(value) / 100).toFixed(2)}`.split('.')

  const m =
    v[0]
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g) || []

  for (let i = 0; i < m.length; i += 1) {
    m[i] = `${m[i].split('').reverse().join('')}.`
  }

  const r = m.reverse().join('')

  const masked = `R$ ${r.substring(0, r.lastIndexOf('.'))},${v[1]}`
  return masked
}

export function formatCard(v: string | any = '') {
  if (v.length <= 0) {
    return ''
  }

  v = v.replace(/\D/g, '')
  return v.match(/\d{1,4}/g)?.join(' ')
}

export function formatCardValidate(v: string | any = '') {
  if (v.length <= 0) {
    return ''
  }

  if (v.length >= 5) return v.slice(0, 5)
  v = v.replace(/\D/g, '')
  return v.match(/\d{1,2}/g).join('/')
}

export function getBrazilianFormatDate(unformattedDate?: string) {
  if (!unformattedDate) return ''
  const date = new Date(unformattedDate)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}

export function formatRg(v0: string, errChar = '') {
  const v = v0.toUpperCase().replace(/[^\dX]/g, '')
  return v.length === 8 || v.length === 9 ? v.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/, '$1.$2.$3-$4') : errChar + v0
}

export const formatPrice = ({
  value,
  currencyStyle,
  currencyDisplay,
  type
}: {
  value?: number
  currencyStyle: string
  currencyDisplay: string
  type: 'en-EN' | 'pr-br'
}) => {
  if (value) {
    const formatter = new Intl.NumberFormat(type, {
      style: 'currency',
      currency: currencyStyle,
      currencyDisplay
    })
    return formatter.format(value)
  }
  return ''
}

export const FormatTimerAudio = (time: number) => {
  if (time && !Number.isNaN(time)) {
    const minutes = Math.floor(time / 60) < 10 ? `0${Math.floor(time / 60)}` : Math.floor(time / 60)
    const seconds = Math.floor(time % 60) < 10 ? `0${Math.floor(time % 60)}` : Math.floor(time % 60)
    return `${minutes}:${seconds}`
  }
  return `00:00`
}
export const formatPriceToNumber = (value: any) => {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    currencyDisplay: 'symbol'
  }).format(value)
}
export const NewFormatPrice = (value?: number) => {
  return !!value && value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
}

export const formatToBRL = (value?: number | string) => {
  if (value === null || value === undefined) return ''

  const valueToFormat = typeof value === 'string' ? Number(value) : value
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    currencyDisplay: 'symbol'
  }).format(valueToFormat)
}

export const formatReal = (value: number | string) => {
  return (
    !!value &&
    parseFloat(value.toString()).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL'
    })
  )
}

export const HiddenCpfDigits = (cpf?: string): string => {
  if (!cpf) return ''

  const bulletPoints = '*'.repeat(3)
  const formattedCPF = `${cpf.substring(0, 3)}.${bulletPoints}.${bulletPoints}-${cpf.substring(9)}`
  return formattedCPF
}

export const removeSpecialCharacters = (text: string): string => {
  if (!text) return ''
  return text.replace(/[^a-zA-Z0-9]/g, '')
}

export const formatHistoryDate = (dateString: string): string => {
  if (!dateString) return '-'
  const date = parseISO(dateString)

  if (isToday(date)) {
    return `Hoje às ${format(date, 'HH:mm')}`
  }
  if (isYesterday(date)) {
    return `Ontem às ${format(date, 'HH:mm')}`
  }
  return format(date, 'dd/MM/yy HH:mm')
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
