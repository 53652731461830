import { Order } from '../../../MyOrders/types'
import OtherSummaryInfo from './components/OtherSummaryInfo'
import PaymentStatusCard from './components/PaymentStatusCard'
import * as Styles from './styles'

interface OrderDetailsSummaryProps {
  order: Order
}
export default function OrderDetailsSummary({ order }: OrderDetailsSummaryProps) {
  return (
    <Styles.OrderDetailsSummaryContainer>
      <h3>Resumo da compra</h3>
      <Styles.OrderDetailsSummaryContent>
        <PaymentStatusCard
          status={order.status.payment_status.translated_name}
          color={order.status.payment_status.color}
          createdAt={order.createdAt}
        />
        <OtherSummaryInfo payment={order.payment} address={order.address} />
      </Styles.OrderDetailsSummaryContent>
    </Styles.OrderDetailsSummaryContainer>
  )
}
