import { useState } from 'react'
import { noProfilePicture } from 'assets/img'
import useSignedUser from 'hooks/useSignedUser'
import * as Styles from './styles'
import ChangeProfileImageModal from './components/ChangeProfileImageModal'

function HelloMessage() {
  const { signedUser, isUserLoading } = useSignedUser()
  const [isChangeProfileImageModalOpen, setIsChangeProfileImageModalOpen] = useState(false)

  return (
    <Styles.HelloMessageContainer>
      <Styles.ImageWithChangeButtonWrapper>
        <Styles.ProfileImageWrapper>
          {!isUserLoading && <img src={signedUser?.photo ?? noProfilePicture} alt="Foto" />}
        </Styles.ProfileImageWrapper>
        <Styles.ChangeImageButton onClick={() => setIsChangeProfileImageModalOpen(true)}>
          Alterar
        </Styles.ChangeImageButton>
      </Styles.ImageWithChangeButtonWrapper>
      <Styles.MessageWrapper>
        <p>
          <strong>Olá,</strong>
        </p>
        <p>{signedUser?.preferred_name?.length > 0 ? signedUser?.preferred_name : signedUser?.name}</p>
      </Styles.MessageWrapper>

      <ChangeProfileImageModal
        isOpen={isChangeProfileImageModalOpen}
        onClose={() => setIsChangeProfileImageModalOpen(false)}
      />
    </Styles.HelloMessageContainer>
  )
}

export default HelloMessage
