import { useScheduling } from 'hooks/useScheduling'
import Button from 'new-components/Button'
import * as Styled from './styles'
import ContinueYourJourney from 'pages/AppointmentScheduling/ContinueYourJourney'
import AppointmentResume from './AppointmentResume'
import { useHistory } from 'react-router'

function ConclusionStep() {
  const { appointmentFromB2C } = useScheduling()
  const history = useHistory()

  const currentStatus = appointmentFromB2C?.current_status.status_description.name || ''

  const paymentOk = ['paid', 'waiting_appointment'].includes(currentStatus)

  const handleCreateNewAppointment = () => {
    history.push('/agendamento')
    window.location.reload()
  }
  return (
    <Styled.Container id="agendamento-conclusao">
      <Styled.Header>{paymentOk ? 'Consulta confirmada!' : 'Consulta pré-agendada!'}</Styled.Header>

      <AppointmentResume />
      <Button
        style={{ alignSelf: 'center', fontFamily: 'Poppins', fontWeight: '600', backgroundColor: '#464F31' }}
        onClick={handleCreateNewAppointment}
      >
        Agendar outra consulta
      </Button>

      <ContinueYourJourney />
    </Styled.Container>
  )
}

export default ConclusionStep
