import PageTitle from 'components/PageTitle'
import { BiChevronLeft } from 'react-icons/bi'
import LinkAsButton from 'new-components/LinkAsButton'
import { useEffect, useMemo, useState } from 'react'
import NotFound from 'pages/404'
import api from 'services/api'
import { toast } from 'react-toastify'
import Loading from 'components/Loading'
import * as Styles from './styles'
import { Order } from '../MyOrders/types'
import OrderStatus from './components/OrderStatus'
import OrderDetailsSummary from './components/OrderDetailsSummary'
import OrderPackage from '../MyOrders/components/OrderSummary/components/OrderPackage'
import OrderAmounts from '../MyOrders/components/OrderSummary/components/OrderAmounts'

interface FetchOrderResponse {
  order: Order
  success: boolean
}
interface OrderDetailsProps {
  orderId: string
}

export default function OrderDetails({ orderId }: OrderDetailsProps) {
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState<Order>()

  const backButtonUrl = useMemo(() => {
    const previousPageTab = localStorage.getItem('@CANNECT_ORDERS_FROM')
    if (previousPageTab && previousPageTab === 'delivered') return '/conta?pagina=pedidos-entregues'
    return '/conta?pagina=pedidos-todos'
  }, [])

  const fetchOrder = async () => {
    setLoading(true)
    try {
      const response = await api.get<FetchOrderResponse>(`/my-account/orders/${orderId}`)
      setOrder(response.data.order)
    } catch (err) {
      console.error(err)
      toast.error('Houve um erro ao recuperar o pedido. Tente novamente.')
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchOrder()
  }, [])

  if (loading) return <Loading loading />
  if (!order) return <NotFound />
  return (
    <Styles.OrderDetailsContainer>
      <PageTitle>
        <LinkAsButton to={backButtonUrl} buttonStyle="ghost" background="green">
          <BiChevronLeft />
        </LinkAsButton>{' '}
        Pedido #{order.id}
      </PageTitle>

      <OrderStatus
        statusHistory={order.statusLogs}
        currentStatus={order.status.order_status}
        isNational={order.isNational}
      />
      <OrderDetailsSummary order={order} />

      <Styles.OrdersContainer>
        {order.packages.map((orderPackage, index) => (
          <OrderPackage key={orderPackage.id} package={orderPackage} packageNumber={index + 1} />
        ))}
        <OrderPackage items={order.items} packageNumber={1} />
        <OrderAmounts amount={order.amount} discount={order.discount} shipping={order.shipping} />
      </Styles.OrdersContainer>
    </Styles.OrderDetailsContainer>
  )
}
