import { SVGProps } from 'react'

export const PixIcon = ({ width = '22', height = '22', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 1.11523C10.2711 1.11523 9.54218 1.39251 8.98751 1.94718L5.44585 5.48885H5.55279C6.4559 5.48885 7.30168 5.83773 7.94168 6.47774L10.4236 8.95968C10.7436 9.27257 11.2564 9.27257 11.5764 8.95968L14.0583 6.47774C14.6983 5.83773 15.5441 5.48885 16.4472 5.48885H16.5542L13.0125 1.94718C12.4578 1.39251 11.7289 1.11523 11 1.11523ZM4.37918 6.55551L1.94723 8.98746C0.837901 10.0968 0.837901 11.9031 1.94723 13.0125L4.37918 15.4444H5.55279C6.17146 15.4444 6.75512 15.2032 7.1889 14.7694L9.67085 12.2875C10.4033 11.555 11.5967 11.555 12.3292 12.2875L14.8111 14.7694C15.2449 15.2032 15.8286 15.4444 16.4472 15.4444H17.6208L20.0528 13.0125C21.1621 11.9031 21.1621 10.0968 20.0528 8.98746L17.6208 6.55551H16.4472C15.8286 6.55551 15.2449 6.79673 14.8111 7.23051L12.3292 9.71246C11.9665 10.0751 11.4836 10.2611 11 10.2611C10.5165 10.2611 10.0335 10.0751 9.67085 9.71246L7.1889 7.23051C6.75512 6.79673 6.17146 6.55551 5.55279 6.55551H4.37918ZM11 12.8055C10.792 12.8055 10.5836 12.8838 10.4236 13.0402L7.94168 15.5222C7.30168 16.1622 6.4559 16.5111 5.55279 16.5111H5.44585L8.98751 20.0527C10.0968 21.1621 11.9032 21.1621 13.0125 20.0527L16.5542 16.5111H16.4472C15.5441 16.5111 14.6983 16.1622 14.0583 15.5222L11.5764 13.0402C11.4164 12.8838 11.208 12.8055 11 12.8055Z"
      fill="#748351"
    />
  </svg>
)
