import useMediaQuery from 'hooks/useMediaQuery'
import React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { MOBILE_WIDTH } from 'utils/constants'

import { Container } from './styles'
import { Button } from 'new-components/atoms'

function Pagination(props: ReactPaginateProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Container>
      <ReactPaginate
        {...props}
        marginPagesDisplayed={isMobile ? 1 : 3}
        previousLabel={
          <Button unstyled disabled={props.forcePage === 0}>
            <MdKeyboardArrowLeft />
          </Button>
        }
        nextLabel={
          <Button unstyled disabled={props.forcePage === props.pageCount - 1}>
            <MdKeyboardArrowRight />
          </Button>
        }
      />
    </Container>
  )
}

export default Pagination
