import useMediaQuery from 'hooks/useMediaQuery'
import { StatusLogs } from 'pages/Account/components/pages/MyOrders/types'
import { useMemo } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import StatusRuleItem from './components/StatusRuleItem'
import { INTERNATIONAL_STATUSES_LIST, NATIONAL_STATUSES_LIST } from './constants'
import { getStatusIcon } from './helpers'
import * as Styles from './styles'

interface StatusRuleProps {
  statusHistory: StatusLogs[]
  isOrderNational: boolean
}
export default function StatusRule({ statusHistory, isOrderNational }: StatusRuleProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const statusesListArray = useMemo(() => {
    if (isOrderNational) return [NATIONAL_STATUSES_LIST]

    if (isMobile) {
      const splittedStatusesList = []
      const [e1, e2, e3, e4] = INTERNATIONAL_STATUSES_LIST
      const [, , , e5, e6, e7] = INTERNATIONAL_STATUSES_LIST
      splittedStatusesList.push([e1, e2, e3, e4])
      splittedStatusesList.push([e5, e6, e7])
      return splittedStatusesList
    }
    return [INTERNATIONAL_STATUSES_LIST]
  }, [isOrderNational, isMobile])

  return (
    <Styles.StatusRuleContainer>
      {statusesListArray.map((statusesList, index) => (
        <Styles.StatusItemsWrapper key={index}>
          {statusesList.map((status, index) => {
            const isItemChecked = !!statusHistory.find(item => item.status.name === status.status)
            return (
              <StatusRuleItem
                key={status.status}
                isFirstItem={index === 0}
                isLastItem={index === statusesList.length - 1}
                icon={getStatusIcon(status.status, isItemChecked)}
                isChecked={isItemChecked}
                label={status.label}
              />
            )
          })}
        </Styles.StatusItemsWrapper>
      ))}
    </Styles.StatusRuleContainer>
  )
}
