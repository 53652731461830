import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  padding: 0 ${temporaryTheme.spacing.space4};
`

export const BreadCrumSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (${MOBILE_WIDTH}) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`
export const NotificationLogin = styled.div`
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 15px;
  min-width: 80%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #424242;
  gap: ${temporaryTheme.spacing.space2};
  flex-wrap: wrap;

  svg {
    width: 18px;
    height: 18px;
    color: #424242;
  }

  a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: ${temporaryTheme.colors.link};

    &:hover {
      color: ${temporaryTheme.colors.linkHover};
    }
  }

  @media (${MOBILE_WIDTH}) {
    text-align: center;
    width: 100%;
    justify-content: center;
  }
`

export const ProductDetailsContainer = styled.div``

export const ImageContent = styled.div``
export const SelectedImageBox = styled.div``
export const GridImageBox = styled.div``

export const DetailsContent = styled.div``

export const SkuDetailsBox = styled.div``

export const InfoProductBox = styled.div``

export const ActionsBox = styled.div``

export const LoadingContent = styled.div`
  height: 100vh;
`
