import { Button } from '@cannect/new-components/atoms'
import { InputField } from '@cannect/new-components/molecules'
import { TFormValues } from '@cannect/pages/WebinarSubscription/types'
import { useFormContext } from 'react-hook-form'
import { motion } from 'framer-motion'

export const StepOne = () => {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext<TFormValues>()

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col gap-4"
    >
      <InputField name="email" placeholder="E-mail" control={control} />

      <Button type="submit" isLoading={isSubmitting}>
        Garantir meu lugar
      </Button>
    </motion.div>
  )
}
