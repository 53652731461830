import styled, { css, keyframes } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

const InfiniteSlideText = keyframes`
  0% {
    transform: translate(0,0);
  }
  100% {
    transform: translate(-100%,0);
  }

`

export const Audio = styled.audio`
  display: none;
`

export const Wrapper = styled.main`
  width: 650px;
  min-width: 650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 22px;
  background: ${temporaryTheme.colors.darkGrey};
  padding: ${temporaryTheme.spacing.space3};
  gap: ${temporaryTheme.spacing.space3};
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    min-width: unset;
    width: 100%;
    height: 340px;
    padding: 23px;
  }
`

export const ThumbVideoBox = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    min-height: 201px;
  }
`

export const ThumbVideo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const InfoContent = styled.div`
  color: ${temporaryTheme.colors.white};
  width: calc(100% - 216px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: ${temporaryTheme.spacing.space4};
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const TextContent = styled.div`
  flex: 0.5 auto;
  @media (max-width: 900px) {
    flex: unset;
    width: 85%;
    position: relative;
    height: 25px;
    overflow: hidden;
  }
`

export const TitleBox = styled.div`
  @media (max-width: 900px) {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    min-width: 100%;
    margin: auto auto;
    white-space: nowrap;
  }
`

export const TitleVideo = styled.h4`
  width: 290px;
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 900px) {
    overflow: unset;
    text-overflow: unset;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    font-size: 17px;
    display: inline-block;
    padding-left: 100%;
    text-align: center;
    white-space: nowrap;
    width: auto;
    height: 100%;
    line-height: 25px;
    animation: ${InfiniteSlideText} 10s linear infinite;
  }
`
export const DescriptionVideo = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: ${temporaryTheme.spacing.space3};
`

export const ControllsVideoContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${temporaryTheme.spacing.space3};
  position: absolute;
  bottom: 16px;
  right: 16px;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
`

export const ArrowIcon = styled.img`
  width: 100%;
`

export const BaseControllIcon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  filter: brightness(100);
  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    svg {
      color: ${temporaryTheme.colors.sitePrimary};
    }
    filter: invert(43%) sepia(34%) saturate(876%) hue-rotate(108deg) brightness(104%) contrast(86%);
  }
`

export const PlayerControll = styled(BaseControllIcon)``

export const ProgressBar = styled.progress`
  width: 199px;
  height: 6px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  overflow: hidden;
  &::-webkit-progress-bar {
    border-radius: 7px;
  }
  &::-webkit-progress-value {
    border-radius: 7px;
    height: 100%;
    margin-left: -1px;
    background: ${temporaryTheme.colors.sitePrimary};
  }

  @media (max-width: 900px) {
    margin-bottom: 6px;
    width: calc(100% - 250px);
    min-width: 180px;
  }
`

export const PreviusControll = styled(BaseControllIcon)`
  @media (max-width: 900px) {
    display: none;
  }
`
export const NextControll = styled(BaseControllIcon)`
  @media (max-width: 900px) {
    display: none;
  }
`

export const TimerVideo = styled.div`
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: ${temporaryTheme.colors.white};
`

export const SoundControllBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  gap: ${temporaryTheme.spacing.space2};
  width: 18px;

  @media (max-width: 900px) {
    width: 15px;
  }
`

export const SoundProgressBar = styled.input<any>`
  ${({ height }) => css`
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.3);
    appearance: none;
    width: 68px;
    height: 4px;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 2px;
    transform: rotate(270deg);
    margin: calc(68px / 2) 0;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${temporaryTheme.colors.sitePrimary};
      cursor: pointer;
    }
  `}
`

export const SoundIcon = styled.img`
  @media (max-width: 900px) {
    width: 15px;
  }
`
