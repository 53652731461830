import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'
import styled, { css } from 'styled-components'

interface ProductUseModeContainerTypes {
  unAvailableProduct?: boolean
}

export const ProductUseModeContainer = styled.div<ProductUseModeContainerTypes>`
  ${({ unAvailableProduct }) => css`
    display: flex;
    position: relative;
    align-items: center;
    gap: 1.5rem;

    width: 100%;
    border-radius: 14px;
    padding: 1.5rem 2rem 1.5rem 2rem;

    background-color: #f8fafd;
    margin-top: 1.5rem;

    border: 1px solid transparent;
    border-color: 'transparent';

    .number_index {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;

      top: 20px;
      background-color: #d9dfcb;
      color: #464f31;

      border-radius: 999px;
      height: 35px;
      width: 35px;
    }

    img {
      max-width: 135px;
      min-width: 135px;
      max-height: 135px;
      min-height: 135px;
      object-fit: contain;
    }

    ${media.mobile`
    flex-direction: column;

      img {
        margin-top: 1.75rem;
      }

      .number_index {
      display: flex;
      align-items: center;
      justify-content: center;

    }
    `}
  `}
`

export const MainInfos = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;

  max-width: 100%;
  width: 100%;

  h2 {
    text-align: left;
    font-size: 1.1rem;
    margin-bottom: 0;
    color: #424242;
    font-weight: 600;
  }

  p {
    text-align: left;
    color: #424242;
  }

  ${media.mobile`
    min-width: 100%;
  `}
`

export const HorizontalContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;

  p {
    color: black;
    font-weight: 300;
  }

  ${media.mobile`
    flex-direction: column;
  `}
`

export const VerticalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flwex-start;
  gap: 1rem;
  width: 100%;

  margin-top: 1rem;

  p {
    color: #424242;
    font-weight: 300;
  }

  span {
    color: #424242;
    font-weight: 400;
  }

  textarea {
    background: #ffffff;
    border: 1.38068px solid #dddddd;
    border-radius: 6.90339px;
    height: 80px;
    max-height: 80px;
    width: 100%;
  }

  select {
    background: #fdfdfd;
    border-radius: 4.59399px;
    height: 30px;
    border: none;
    color: #424242;
    font-weight: 400;
    font-size: 12px;
  }

  ${media.mobile`
    flex-direction: column;
  `}
`

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${media.tablet`
    height: 100%;
    justify-content: flex-end;
  `}
`

interface CustomizedButtonProps {
  outlined?: boolean
  isThinButton?: boolean
}
export const CustomizedButton = styled.button<CustomizedButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  border-radius: 0.5rem;
  padding: 0.5rem;

  background: ${(props) => (props.outlined ? 'white' : `${theme.colors.tertiary}`)};
  color: ${(props) => (props.outlined ? `${theme.colors.tertiary}` : 'white')};
  border: 1px solid ${theme.colors.tertiary};

  height: ${(props) => props.isThinButton && '38px'};

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`
export const ButtonArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #424242;
  border: none;
  border-radius: 0.25rem;

  height: 50px;
  width: 50px;

  &:disabled {
    background-color: #8f8f8f;
    cursor: not-allowed;
  }
`

export const ButtonInput = styled.button`
  background: #d6d6d6;
  border-radius: 3.88489px;
  border: none;
  padding: 5px 12px;
  color: #424242;
  font-size: 11px;
  margin-left: auto;
`

export const ImageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  .content-sort_mobile {
    position: absolute;
    top: 20px;
    right: 0;
  }

  ${media.desktop`
    width: 40%;
  `}
`
