import axios from 'axios'
import { CouponProps, ProductProps } from 'pages/BlankRecipe/Products/List'
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import { clearMask, formatZipCode } from 'utils/formatters'
import { useLocalStorage } from './useLocalStorage'

interface CartCheckoutContextValues {
  itens: Item[]
  addToCart: (id: number, item: Item, quantity?: number) => void
  removeCart: (id: number) => void
  setQuantity: (id: number, quantity: number) => void
  createOrder: (items: Item[], uuid?: string | any) => Promise<Order>
  createOrderPublic: (items: Item[], uuid?: string | any, person_id?: number | any) => Promise<Order>
  step: number
  maxStep: number
  setStep: any
  setMaxStep: any
  loading: boolean
  setLoading: any
  couponResponse: GetCouponResponse
  getDiscount: (items: Item[], coupon: string) => any
  getDiscountPublic: (items: Item[], coupon: string) => any
  changeStepUrl: (step: number, path: string) => void
  order: Order
  setOrder: any
  shippingAddress: ShippingAddress
  setShippingAddress: any
  changeShippingAddress: (fieldToChange: ShippingAddressFields, newValue: string) => void
  paymentMethod: PaymentMethod
  setPaymentMethod: any
  creditCard: CreditCard
  changeCreditCardInfo: (fieldToChange: CreditCardFields, newValue: string) => void
  finishOrder: () => Promise<Order>
  finishOrderByPyamentMethod: ({ type }: PaymentMethod) => Promise<any>
  finishOrderByPaymentMethodPublic: ({ type }: PaymentMethod) => Promise<any>
  recipeSend: boolean
  setRecipeSend: any
  documents: Document[]
  setDocuments: any
  recipesDocuments: Document[]
  setRecipeDocuments: any
  statusDiscount: string
  couponIsValid: boolean
  fetchZipCode: (cep: string, type: 'shipping' | 'billing') => void
  activeBilling: boolean
  setActiveBilling: any
  changeBillingAddress: (fieldToChange: BillingAddressFields, newValue: string) => void
  billingAddress: BillingAddress
  setBillingAddress: any
  discount: number
  couponValue: string
  changeValueCoupon: (e: React.ChangeEvent<HTMLInputElement>) => void
  error: boolean
  setError: any
  getOrder: (uuid: string) => any
  getOrderPublic: (uuid: string) => any
  checkPixPaiment: (uuid: string) => any
  patientIsUser: boolean
  setPatientIsUser: any
  clearFinishOrderData: () => void
  getInstallments: () => void
  getInstallmentsPublic: () => void
  installments: any
  sendDataFormPartnerEmail: (dataForm: DataForm) => Promise<any>
}

type ShippingAddressFields = 'cep' | 'state' | 'city' | 'address_number' | 'street' | 'district' | 'complement'

type BillingAddressFields = 'cep' | 'state' | 'city' | 'address_number' | 'street' | 'district' | 'complement'

type CreditCardFields =
  | 'card_name'
  | 'card_number'
  | 'card_month_validate'
  | 'card_year_validate'
  | 'card_cvv'
  | 'installments'

interface Item {
  id: number
  name: string
  real_price: number | any
  quantity: number
  image: string
  translated_name: string
  price?: number
  media?: any
  shape_id?: number
  promotion?: any
}

interface Response {
  order: OrderResponse
}

interface OrderResponse {
  id: number
  amount: string
  anvisa_id: number
  anvisaDoc: DocOrderResponse
  carriers: number
  cep: string
  city: string
  complement: string
  cannect_user_id: number
  coupon?: {
    name: string
  }
  coupon_id: number
  delivery_forecast: string
  identidadeDoc: DocOrderResponse
  identidadeId: number
  items: ItemsOrderResponse[]
  link: string
  origin: string
  originCheckout: string
  patient_id?: number
  person_id: string
  recipes: RecipeOrderResponse[]
  residencialDoc: DocOrderResponse
  residencial_id: number
  shipping: string
  shippingDiscount: string
  discount: string
  state: string
  status: StatusProp
  street: string
  user_id: string
  user: any
  uuid: string
}

interface StatusProp {
  payment_status: PaymentStatusProp
  order_status: OrderStatusProp
}

interface PaymentStatusProp {
  status: string
  date: string
  translated_name: string
  color: string
}

interface OrderStatusProp {
  status: string
  date: string
  translated_name: string
  color: string
}

interface DocOrderResponse {
  id: number
  person_id: number
  extension: string
  status: string
  type: string
  url: string
}

interface RecipeOrderResponse {
  id: number
  link_pdf: string
  order_id: number
}
interface ItemsOrderResponse {
  amount: string
  id: number
  product_id: number
  quantity: number
  unitPrice: string
  order_id: number
  product: ItemOrderResponse
}

interface ItemOrderResponse {
  brand_id: number
  currency: string
  free_shipping: boolean
  name: string
  price: number
  translated_name: string
  media: MediaItemOrderResponse[]
}
interface MediaItemOrderResponse {
  format: string
  id: number
  path: string
  product_id: number
}
export interface Order {
  id?: number | any
  status?: string
  originCheckout?: string
  identidade_id?: number
  residencial_id?: number
  documents?: object[]
  vinculo_id?: number
  anvisa_id?: number
  identidade_dependent_id?: number
  uuid?: string
  amount?: any
  person?: any
  patient_id?: number
  recipes: {
    id?: number
    link_pdf?: string
    order_id?: number
    filename?: string
  }[]
}

interface ShippingAddress {
  cep?: string
  state?: string
  city?: string
  address_number?: string
  street?: string
  district?: string
  complement?: string
}

interface BillingAddress {
  cep?: string
  state?: string
  city?: string
  address_number?: string
  street?: string
  district?: string
  complement?: string
}

export interface PaymentMethod {
  type: 'credit_card' | 'pix' | 'boleto'
}

interface CreditCard {
  card_name?: string
  card_number?: string
  card_month_validate?: string
  card_year_validate?: string
  card_cvv?: string
  installments?: number
}

interface Document {
  id: number
  type: string
}

interface GetCouponResponse {
  coupon: CouponResponse
  items: ItemsCouponResponse[]
}

interface CouponResponse {
  type: string
  type_shipping: string
  value: any
  value_shipping: any
  name: string
}

interface ItemsCouponResponse {
  amount: number
  discount: 0
  product: any
  product_id: number
  quantity: number
  unitPrice: number
}

interface Coupon {
  name?: string
}
interface CartCheckoutProviderProps {
  children: ReactNode
}

interface InstallmentsProps {
  id: number
  installment: string
  amount: number
  feels: string
}

interface DataForm {
  name: string
  email: string
  phoneNumber: string
  city: string
  company: string
  interestOn: string
  message?: string
}

const CartCheckoutContext = createContext({} as CartCheckoutContextValues)

export function CartCheckoutProvider({ children }: CartCheckoutProviderProps) {
  const [isStart, setIsStart] = useState(false)
  const [step, setStep] = useState(0)
  const [maxStep, setMaxStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [couponResponse, setCouponResponse] = useState<GetCouponResponse>({
    items: [],
    coupon: {
      type: '',
      type_shipping: '',
      value: '',
      value_shipping: '',
      name: ''
    }
  })
  const [couponIsValid, setCouponIsValid] = useState(false)
  const [statusDiscount, setStatusDiscount] = useState('')
  const [coupon, setCoupon] = useState<Coupon>({})

  const [inputCoupon, setInputCoupon] = useState<any>({
    couponValue: ''
  })

  const { couponValue } = inputCoupon

  const changeValueCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    setInputCoupon((inputValue: any) => ({ ...inputValue, [id]: value }))
  }

  const [discount, setDiscount] = useState(0)
  const [documents, setDocuments] = useState<Document[]>([])
  const [recipesDocuments, setRecipeDocuments] = useState<Document[]>([])

  const [shippingAddress, setShippingAddress] = useState<ShippingAddress>({})
  const [billingAddress, setBillingAddress] = useState<BillingAddress>({})
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({ type: 'credit_card' })
  const [recipeSend, setRecipeSend] = useState(false)
  const [activeBilling, setActiveBilling] = useState(false)
  const [error, setError] = useState(false)
  const [patientIsUser, setPatientIsUser] = useState(true)
  const [installments, setInstallments] = useState<InstallmentsProps[]>()

  const [creditCard, setCreditCard] = useState<CreditCard>({
    installments: 1,
    card_month_validate: '01',
    card_year_validate: '22'
  })

  const history = useHistory()

  let totalProductValue = 0
  let totalDiscountValue = 0

  const [cartItems, setCartItems] = useLocalStorage<Item[]>('@CANNECT:CHECKOUT_CART', [])
  const [order, setOrder] = useLocalStorage<Order>('@CANNECT:ORDER_CHECKOUT_CART', {
    recipes: [],
    id: null
  })

  function addToCart(id: number, item: Item, quantity?: number) {
    toast.success('Produto adicionado ao carrinho')
    setCartItems(currItems => {
      if (currItems.find(item => item.id === id) == null) {
        return [
          ...currItems,
          {
            id,
            quantity: quantity || 1,
            name: item.name,
            image: item?.media?.path ? item.media?.path : item.image,
            real_price: item?.real_price || item?.price,
            translated_name: item.translated_name,
            price: item?.price,
            shape_id: item?.shape_id,
            promotion: item?.promotion
          }
        ]
      }
      return currItems.map(item => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + 1 }
        }
        return item
      })
    })
  }

  function removeCart(id: number) {
    setCartItems(currItems => {
      return currItems.filter(item => item.id !== id)
    })
  }

  async function setQuantity(id: number, quantity: number) {
    setCartItems(currItems => {
      return currItems.map(item => {
        if (item.id === id) {
          return { ...item, quantity }
        }
        return item
      })
    })
  }

  async function createOrder(items: Item[], uuid?: string) {
    try {
      setError(false)
      setLoading(true)
      // if (!order?.id) {
      const newOrderRequestItems: any[] = []

      items?.forEach(product => {
        const newOrderRequestItem: any = {}

        newOrderRequestItem.product_id = product.id
        newOrderRequestItem.quantity = product.quantity

        newOrderRequestItems.push(newOrderRequestItem)
      })

      const payload = {
        items: newOrderRequestItems,
        coupon,
        order_uuid: uuid
      }

      const response = await api
        .post('/order_cart', payload)
        .then(res => {
          setLoading(false)
          setError(false)
          setDocuments([])

          setOrder({
            id: res?.data?.order?.id,
            status: res?.data?.order?.status,
            originCheckout: res?.data?.order?.originCheckout,
            vinculo_id: res?.data?.order?.vinculo_id,
            identidade_dependent_id: res?.data?.order?.identidade_dependent_id,
            uuid: res?.data?.order?.uuid,
            amount: res?.data?.order?.amount,
            recipes: res?.data?.order?.recipes
          })

          if (order && order.recipes && order.recipes.length > 0) {
            setRecipeSend(true)
          }

          if (res?.data?.order?.documents.length > 0) {
            setOrder({
              ...order,
              documents: res?.data?.order?.documents,
              identidade_id: res?.data?.order?.documents
                ? res?.data?.order?.documents.filter((doc: any) => doc.type === 'IDENTIDADE')[0]?.id
                : null,
              residencial_id: res?.data?.order?.documents
                ? res?.data?.order?.documents.filter((doc: any) => doc.type === 'RESIDENCIAL')[0]?.id
                : null,
              anvisa_id: res?.data?.order?.documents
                ? res?.data?.order?.documents.filter((doc: any) => doc.type === 'ANVISA')[0]?.id
                : null
            })
          }
          setLoading(false)
          setStep(2)
          setMaxStep(3)

          return res
        })
        .catch(err => {
          setError(true)
          setLoading(false)
          return err
        })

      return response?.data?.order
      // }
      // return order?.id
    } catch (error) {
      console.log(error)
      setError(true)
      setLoading(false)
      return error
    } finally {
      setLoading(false)
    }
  }

  async function createOrderPublic(items: Item[], uuid?: string, person_id?: number) {
    try {
      setError(false)
      setLoading(true)
      const newOrderRequestItems: any[] = []

      items?.forEach(product => {
        const newOrderRequestItem: any = {}

        newOrderRequestItem.product_id = product.id
        newOrderRequestItem.quantity = product.quantity

        newOrderRequestItems.push(newOrderRequestItem)
      })

      const payload = {
        items: newOrderRequestItems,
        coupon,
        order_uuid: uuid,
        person_id
      }

      const response = await api
        .post('/order_cart_public', payload)
        .then(res => {
          setLoading(false)
          setError(false)
          setDocuments([])

          setOrder({
            id: res?.data?.order?.id,
            status: res?.data?.order?.status,
            originCheckout: res?.data?.order?.originCheckout,
            vinculo_id: res?.data?.order?.vinculo_id,
            identidade_dependent_id: res?.data?.order?.identidade_dependent_id,
            uuid: res?.data?.order?.uuid,
            amount: res?.data?.order?.amount,
            recipes: res?.data?.order?.recipes
          })

          if (order && order.recipes && order.recipes.length > 0) {
            setRecipeSend(true)
          }

          if (res?.data?.order?.documents.length > 0) {
            setOrder({
              ...order,
              documents: res?.data?.order?.documents,
              identidade_id: res?.data?.order?.documents
                ? res?.data?.order?.documents.filter((doc: any) => doc.type === 'IDENTIDADE')[0]?.id
                : null,
              residencial_id: res?.data?.order?.documents
                ? res?.data?.order?.documents.filter((doc: any) => doc.type === 'RESIDENCIAL')[0]?.id
                : null,
              anvisa_id: res?.data?.order?.documents
                ? res?.data?.order?.documents.filter((doc: any) => doc.type === 'ANVISA')[0]?.id
                : null
            })
          }
          setLoading(false)
          return res
        })
        .catch(err => {
          setError(true)
          setLoading(false)
          return err
        })

      return response?.data?.order
      // }
      // return order?.id
    } catch (error) {
      console.log(error)
      setError(true)
      setLoading(false)
      return error
    } finally {
      setLoading(false)
    }
  }

  async function updateOrder(orderId: number, payload: any) {
    const atualURL = window.location.href
    const partUrl = atualURL.split('/')
    const cartWithautAuth = partUrl.pop()

    try {
      if (cartWithautAuth !== `carrinho-wa?cart_id=${order.uuid}`) {
        const { data } = await api.put(`/order_cart/${orderId}`, { items: payload })

        return data
      }

      const { data } = await api.put(`/order_cart_public/${orderId}`, { items: payload, person_id: order?.patient_id })

      return data
    } catch (error) {
      return error
    }
  }

  async function getDiscount(items: Item[], coupon: string) {
    try {
      setLoading(true)
      setStatusDiscount('')
      const payload = {
        coupon: {
          name: coupon
        },
        items: items.map(item => {
          return {
            product_id: item.id,
            ...item
          }
        })
      } as any

      const { data } = await api.post(`/coupon/valid-items`, payload)

      const { totalDiscount } = calculateDiscounts(data.coupon, data.items)

      totalDiscountValue = totalDiscount

      setDiscount(totalDiscountValue)
      setCouponResponse(data)

      setCoupon({ name: coupon })
      setCouponIsValid(true)
    } catch (e) {
      setLoading(false)
      setDiscount(0)
      setStatusDiscount('Cupom inválido')
      setCoupon({ name: '' })
      setCouponIsValid(false)
    } finally {
      setLoading(false)
    }
  }

  async function getDiscountPublic(items: Item[], coupon: string) {
    try {
      setLoading(true)
      setStatusDiscount('')
      const payload = {
        coupon: {
          name: coupon
        },
        items: items.map(item => {
          return {
            product_id: item.id,
            ...item
          }
        })
      } as any

      const { data } = await api.post(`/coupon_public/valid-items`, payload)

      const { totalDiscount } = calculateDiscounts(data.coupon, data.items)

      totalDiscountValue = totalDiscount

      setDiscount(totalDiscountValue)
      setCouponResponse(data)

      setCoupon({ name: coupon })
      setCouponIsValid(true)
    } catch (e) {
      setLoading(false)
      setDiscount(0)
      setStatusDiscount('Cupom inválido')
      setCoupon({ name: '' })
      setCouponIsValid(false)
      return
    } finally {
      setLoading(false)
    }
  }

  const calculateDiscounts = (coupon: CouponProps, products: ProductProps[]) => {
    totalProductValue = products.reduce((sum, product) => sum + product.unitPrice * product.quantity, 0)

    if (coupon.infos && coupon.infos.length > 0) {
      totalDiscountValue = calculateDiscountByInfos(coupon, products)
    } else {
      totalDiscountValue = coupon.type === 'percent' ? (coupon.value / 100) * totalProductValue : coupon.value
    }

    const shippingDiscountValue =
      // eslint-disable-next-line no-nested-ternary
      coupon.value_shipping > 0
        ? coupon.type_shipping === 'percent'
          ? (coupon.value_shipping / 100) * 140
          : coupon.value_shipping
        : 0

    return { totalDiscount: Number(totalDiscountValue) + shippingDiscountValue, totalProduct: totalProductValue }
  }

  const calculateDiscountByInfos = (coupon: CouponProps, products: any[]): number => {
    let brandsFinded: number[] = []
    let productsFinded: number[] = []
    let discount = 0

    brandsFinded = coupon.infos.filter(info => info.brand_id).map(info => info.brand_id)
    productsFinded = coupon.infos.filter(info => info.product_id).map(info => info.product_id)

    products.forEach(product => {
      if (
        productsFinded.length <= 0 &&
        brandsFinded.length > 0 &&
        brandsFinded.some(brandId => product.product.brand.id === brandId)
      ) {
        discount += calculateDiscountPerProduct(coupon, product)
      } else if (productsFinded.length > 0 && productsFinded.some(productId => product.product_id === productId)) {
        discount += calculateDiscountPerProduct(coupon, product)
      }
    })
    return discount
  }

  const calculateDiscountPerProduct = (coupon: CouponProps, product: any): number => {
    if (!product.promotional) {
      return coupon.type === 'percent'
        ? (coupon.value / 100) * product.unitPrice * product.quantity
        : coupon.value * product.quantity
    }
    return 0
  }

  function changeStepUrl(step: number, path: string) {
    setStep(step)
    setMaxStep(step + 1)
    history.push(path)
  }

  function changeShippingAddress(fieldToChange: ShippingAddressFields, newValue: string) {
    setShippingAddress({
      ...shippingAddress,
      [fieldToChange]: newValue
    })
    if (fieldToChange === 'cep') {
      if (newValue.length === 9) {
        fetchZipCode(clearMask(newValue), 'shipping')
      }
    }
  }

  function changeBillingAddress(fieldToChange: BillingAddressFields, newValue: string) {
    setBillingAddress({
      ...billingAddress,
      [fieldToChange]: newValue
    })
    if (fieldToChange === 'cep') {
      if (newValue.length === 9) {
        fetchZipCode(clearMask(newValue), 'billing')
      }
    }
  }

  function changeCreditCardInfo(fieldToChange: CreditCardFields, newValue: string) {
    const value = newValue.replace(/\D/g, '')
    setCreditCard({
      ...creditCard,
      [fieldToChange]: newValue
    })
  }

  async function fetchZipCode(cep: string, type: 'shipping' | 'billing') {
    setLoading(true)
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (type === 'shipping') {
        setShippingAddress(state => ({
          ...state,
          cep: formatZipCode(response?.data?.cep ? response?.data?.cep : ''),
          state: response?.data?.uf,
          street: response?.data?.logradouro,
          district: response?.data?.bairro,
          city: response?.data?.localidade
        }))
      }
      if (type === 'billing') {
        setBillingAddress(state => ({
          ...state,
          cep: formatZipCode(response?.data?.cep ? response?.data?.cep : ''),
          state: response?.data?.uf,
          street: response?.data?.logradouro,
          district: response?.data?.bairro,
          city: response?.data?.localidade
        }))
      }
    } catch (err: any) {
      setLoading(false)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  async function finishOrder() {
    try {
      setLoading(true)
      const payload = {
        shippingAddress,
        billingAddress,
        documents,
        recipes: recipesDocuments,
        paymentMethod,
        creditCard
      }
      const response = await api
        .put(`/order_cart/${order.id}`, payload)
        .then(res => {
          setError(false)
          setStep(1)
          setMaxStep(2)
          setOrder({ recipes: [] })
          setCartItems([])
          setCreditCard({
            installments: 1,
            card_month_validate: '01',
            card_year_validate: '23'
          })
          setShippingAddress({})
          setRecipeSend(false)
          setDocuments([])
          setInputCoupon('')
          setCoupon({})
          setCouponIsValid(false)
          setCouponResponse({
            items: [],
            coupon: {
              type: '',
              type_shipping: '',
              value: '',
              value_shipping: '',
              name: ''
            }
          })
          setStatusDiscount('')
          setDiscount(0)

          return res
        })
        .catch(err => {
          setError(true)
          return err
        })

      return response?.data?.order
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      setLoading(false)
      setError(true)
      return error
    } finally {
      setLoading(false)
    }
  }

  const clearFinishOrderData = () => {
    setCartItems([])
    setRecipeSend(false)
    setShippingAddress({})
  }

  async function finishOrderByPyamentMethod({ type }: PaymentMethod) {
    try {
      setLoading(true)
      setPaymentMethod({ ...paymentMethod, type })
      const payload = {
        shippingAddress,
        billingAddress,
        documents,
        recipes: recipesDocuments,
        paymentMethod,
        creditCard
      }
      const response = await api
        .put(`/order_cart/${order.id}`, payload)
        .then(res => {
          setError(false)
          setStep(1)
          setMaxStep(2)
          setOrder({ recipes: [] })
          setCoupon({})
          setCartItems([])
          setCreditCard({
            installments: 1,
            card_month_validate: '01',
            card_year_validate: '23'
          })
          setDocuments([])
          setRecipeSend(false)
          setShippingAddress({})
          setInputCoupon('')
          setCoupon({})
          setCouponIsValid(false)
          setCouponResponse({
            items: [],
            coupon: {
              type: '',
              type_shipping: '',
              value: '',
              value_shipping: '',
              name: ''
            }
          })
          setStatusDiscount('')
          setDiscount(0)
          localStorage.setItem('@CANNECT:CHECKOUT_CART', '')
          localStorage.setItem('@CANNECT:ORDER_CHECKOUT_CART', '')
          localStorage.setItem('@CANNECT_ASSISTANT_PRESCRIPTION', '')
          return res
        })
        .catch(err => {
          setError(true)
          return err
        })

      return response?.data
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      setLoading(false)
      setError(true)
      return error
    } finally {
      setLoading(false)
    }
  }

  async function finishOrderByPaymentMethodPublic({ type }: PaymentMethod) {
    try {
      setLoading(true)
      setPaymentMethod({ ...paymentMethod, type })
      const payload = {
        shippingAddress,
        billingAddress,
        documents,
        recipes: order?.recipes,
        paymentMethod,
        creditCard,
        person_id: order?.patient_id
      }
      const response = await api
        .put(`/order_cart_public/${order.id}`, payload)
        .then(res => {
          setError(false)
          setOrder({ recipes: [] })
          setCoupon({})
          setCartItems([])
          setCreditCard({
            installments: 1,
            card_month_validate: '01',
            card_year_validate: '23'
          })
          setDocuments([])
          setRecipeSend(false)
          setShippingAddress({})
          setInputCoupon('')
          setCoupon({})
          setCouponIsValid(false)
          setCouponResponse({
            items: [],
            coupon: {
              type: '',
              type_shipping: '',
              value: '',
              value_shipping: '',
              name: ''
            }
          })
          setStatusDiscount('')
          setDiscount(0)
          localStorage.setItem('@CANNECT:CHECKOUT_CART', '')
          localStorage.setItem('@CANNECT:ORDER_CHECKOUT_CART', '')
          localStorage.setItem('@CANNECT_ASSISTANT_PRESCRIPTION', '')
          return res
        })
        .catch(err => {
          setError(true)
          return err
        })

      return response?.data
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      setLoading(false)
      setError(true)
      return error
    } finally {
      setLoading(false)
    }
  }

  async function getOrder(uuid: string) {
    try {
      setLoading(true)
      const { data } = await api.get<Response>(`/order_b2c/${uuid}`)

      if (data) {
        setCartItems([])

        data?.order.items?.map(product => {
          setCartItems(currItems => {
            if (currItems.find(item => item.id === product.id) == null) {
              return [
                ...currItems,
                {
                  id: product.product_id,
                  quantity: product.quantity,
                  name: product.product.name,
                  image:
                    product.product.media.length > 0
                      ? product.product.media[0].path
                      : 'https://s3.amazonaws.com/cannect.portal.upload/produtos/14517/foto%201.jpg',
                  real_price: parseFloat(product.unitPrice),
                  translated_name: product.product.translated_name
                }
              ]
            }
            return currItems.map(item => {
              if (item.id === product.product_id) {
                return { ...item, quantity: item.quantity + 1 }
              }
              return item
            })
          })

          return product
        })

        setOrder({ ...order, originCheckout: data?.order?.originCheckout, recipes: [], amount: data?.order?.amount })

        if (data?.order?.coupon) {
          const items = data?.order?.items?.map(item => {
            return {
              id: item.product_id,
              name: item.product.name,
              real_price: parseFloat(item.unitPrice),
              image: item.product.media.length > 0 ? item.product.media[0].path : '',
              quantity: item.quantity,
              translated_name: item.product.translated_name
            }
          })
          await getDiscount(items, data?.order?.coupon?.name)
          setInputCoupon({ couponValue: data?.order?.coupon?.name })
        }
      }
      setLoading(false)
      return data
    } catch (error) {
      setCartItems([])
      setLoading(false)
      return error
    }
  }

  async function getOrderPublic(uuid: string) {
    try {
      setLoading(true)
      const { data } = await api.get<Response>(`/order_b2c_public/${uuid}`)
      if (data) {
        setCartItems([])

        data?.order.items?.map(product => {
          setCartItems(currItems => {
            if (currItems.find(item => item.id === product.id) == null) {
              return [
                ...currItems,
                {
                  id: product.product_id,
                  quantity: product.quantity,
                  name: product.product.name,
                  image:
                    product.product.media.length > 0
                      ? product.product.media[0].path
                      : 'https://s3.amazonaws.com/cannect.portal.upload/produtos/14517/foto%201.jpg',
                  real_price: parseFloat(product.unitPrice),
                  translated_name: product.product.translated_name
                }
              ]
            }
            return currItems.map(item => {
              if (item.id === product.product_id) {
                return { ...item, quantity: item.quantity + 1 }
              }
              return item
            })
          })

          return product
        })

        setOrder({
          ...order,
          originCheckout: data?.order?.originCheckout,
          recipes: data?.order?.recipes,
          amount: data?.order?.amount,
          patient_id: data?.order?.patient_id,
          id: data?.order.id,
          uuid: data?.order?.uuid
        })

        if (data?.order?.coupon) {
          const items = data?.order?.items?.map(item => {
            return {
              id: item.product_id,
              name: item.product.name,
              real_price: parseFloat(item.unitPrice),
              image: item.product.media.length > 0 ? item.product.media[0].path : '',
              quantity: item.quantity,
              translated_name: item.product.translated_name
            }
          })
          await getDiscountPublic(items, data?.order?.coupon?.name)
          setInputCoupon({ couponValue: data?.order?.coupon?.name })
        }
      }
      setLoading(false)
      return data
    } catch (error) {
      setCartItems([])
      setLoading(false)
      return error
    }
  }

  async function checkPixPaiment(orderId: number | string) {
    try {
      const { data } = await api.get<Response>(`/order/${orderId}`)

      return data?.order?.status?.payment_status?.status
    } catch (error) {
      setCartItems([])
      setLoading(false)
      return error
    }
  }

  async function getInstallments() {
    try {
      setLoading(true)
      const { data } = await api.get(`/payments/installments/${order.id}`)
      setLoading(false)
      setInstallments(data?.installments)
      return data
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  async function getInstallmentsPublic() {
    try {
      setLoading(true)
      const { data } = await api.get(`/payments/installments_public/${order.id}`)
      setLoading(false)
      setInstallments(data?.installments)
      return data
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  async function sendDataFormPartnerEmail(dataForm: DataForm) {
    try {
      setLoading(true)
      const result = await api.post(`/send_email_parthner`, dataForm)

      return result
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  useEffect(() => {
    if (order?.id) {
      if (order?.id) {
        setStep(2)
        setMaxStep(3)
      } else {
        setStep(1)
      }
    } else {
      setStep(1)
    }
  }, [])

  useEffect(() => {
    if (order?.id && isStart) {
      const newOrderRequestItems: any[] = []

      cartItems?.forEach(product => {
        const newOrderRequestItem: any = {}

        newOrderRequestItem.product_id = product.id
        newOrderRequestItem.quantity = product.quantity

        newOrderRequestItems.push(newOrderRequestItem)
      })
      updateOrder(order?.id, newOrderRequestItems)
    }
    setIsStart(true)
  }, [setCartItems, cartItems])

  useEffect(() => {
    if (cartItems.length === 0 && order?.id) {
      setOrder({ recipes: [] })
      setInputCoupon({
        couponValue: ''
      })
      setCoupon({})
      setCouponIsValid(false)
      setCouponResponse({
        items: [],
        coupon: {
          type: '',
          type_shipping: '',
          value: '',
          value_shipping: '',
          name: ''
        }
      })
      setStatusDiscount('')
      setDiscount(0)
      setStep(1)
    }
    if (cartItems.length === 0) {
      setStep(1)
      setInputCoupon({
        couponValue: ''
      })
      setCoupon({})
      setCouponIsValid(false)
      setCouponResponse({
        items: [],
        coupon: {
          type: '',
          type_shipping: '',
          value: '',
          value_shipping: '',
          name: ''
        }
      })
      setStatusDiscount('')
      setDiscount(0)
      setMaxStep(2)
    }
  }, [cartItems])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CartCheckoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        itens: cartItems,
        addToCart,
        removeCart,
        setQuantity,
        createOrder,
        createOrderPublic,
        step,
        maxStep,
        setStep,
        loading,
        setLoading,
        getDiscount,
        getDiscountPublic,
        couponResponse,
        changeStepUrl,
        order,
        setOrder,
        setMaxStep,
        shippingAddress,
        setShippingAddress,
        changeShippingAddress,
        paymentMethod,
        setPaymentMethod,
        creditCard,
        changeCreditCardInfo,
        finishOrder,
        finishOrderByPyamentMethod,
        finishOrderByPaymentMethodPublic,
        recipeSend,
        setRecipeSend,
        documents,
        setDocuments,
        statusDiscount,
        couponIsValid,
        fetchZipCode,
        activeBilling,
        setActiveBilling,
        changeBillingAddress,
        billingAddress,
        setBillingAddress,
        discount,
        couponValue,
        changeValueCoupon,
        error,
        setError,
        recipesDocuments,
        setRecipeDocuments,
        getOrder,
        getOrderPublic,
        checkPixPaiment,
        patientIsUser,
        setPatientIsUser,
        clearFinishOrderData,
        getInstallments,
        getInstallmentsPublic,
        installments,
        sendDataFormPartnerEmail
      }}
    >
      {children}
    </CartCheckoutContext.Provider>
  )
}
export function useCartCheckout() {
  return useContext(CartCheckoutContext)
}
