import InvisibleButton from 'components/InvisibleButton'
import { FormEvent, useEffect, useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import { MdEdit } from 'react-icons/md'
import * as Styles from './styles'

interface TemplateNameInputProps {
  changeTemplateName: (newName: string) => void
  templateName: string
}

export default function TemplateNameInput({ templateName, changeTemplateName }: TemplateNameInputProps) {
  const [isEditingName, setIsEditingName] = useState(false)

  const handleEditNameConfirmation = (event: FormEvent) => {
    event.preventDefault()
    setIsEditingName(state => !state)
  }

  useEffect(() => {
    if (!isEditingName) return
    const templateNameInput = document.getElementById('template-name-input')
    if (!templateNameInput) return
    templateNameInput.focus()
  }, [isEditingName])

  return (
    <Styles.TemplateNameInputContainer onSubmit={handleEditNameConfirmation}>
      {isEditingName ? (
        <input
          value={templateName}
          onChange={event => changeTemplateName(event.target.value)}
          id="templateName-name-input"
        />
      ) : (
        <span>&quot;{templateName}&quot;</span>
      )}
      <InvisibleButton>{isEditingName ? <FiCheck color="#21976E" /> : <MdEdit color="#21976E" />}</InvisibleButton>
    </Styles.TemplateNameInputContainer>
  )
}
