import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const PixContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 28px;

  @media ${MOBILE_WIDTH} {
    margin-top: 0px;
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;

  .ant-spin {
    margin-right: 8px;
  }

  .ant-spin-dot-item {
    background-color: ${temporaryTheme.colors.lightDark};
  }

  span:first-child {
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: ${temporaryTheme.colors.darkGrey};
  }

  span:last-child {
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: ${temporaryTheme.colors.lightDark};
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 0px;
  }
`

export const QrCodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const QrCodeTutorial = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding-bottom: 8px;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;

    color: ${temporaryTheme.colors.darkGrey};
    span {
      font-weight: 700;
    }
  }
`

export const QrCode = styled.div`
  display: flex;
  width: auto;
  height: 35px;
  background-color: #fff;
  border: 1px solid ${temporaryTheme.colors.whiteBorder};
  border-radius: 8px;
  padding: 6px 30px;
  cursor: pointer;
  margin: 20px 0;

  p {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
`
