import { banner01, banner02, banner03, banner04, banner05, bannerPromotional } from 'assets/img'

export const BANNER_CRM_CRO = [
  {
    id: 1,
    alt: 'Profissional da saude',
    img: banner01,
    link: 'https://api.whatsapp.com/send?phone=5511950811547&text=Olá! Vi que a Cannect oferece o BirdID, gostaria de saber como funciona e conhecer os demais benefícios para profissionais da saúde'
  },
  {
    id: 2,
    alt: 'Faça seu onboarding com nosso time !',
    img: banner02,
    link: 'https://api.whatsapp.com/send?phone=5511950811547&text=Olá! Vi que a Cannect oferece benefícios para profissionais da saúde e gostaria de saber mais a respeito'
  },
  { id: 3, alt: 'Profissional da saude', img: banner03, link: 'https://www.drcannabis.com.br/' },
  {
    id: 4,
    alt: 'Venha para o nosso ecossistema',
    img: banner04,
    link: 'https://api.whatsapp.com/send?phone=5511950811547&text=Olá! Vi que a Cannect oferece a possibilidade de listagem para receber agendamentos de consulta através da plataforma e gostaria de saber mais'
  },
  {
    id: 5,
    alt: 'Nosso assistente de prescrição atualizada',
    img: banner05,
    link: 'https://www.cannect.life/assistente'
  }
]

export const BANNER_PROFESSIONAL = [
  { id: 1, alt: 'Profissional da saude', img: banner03, link: 'https://www.drcannabis.com.br/' }
]

export const PATIENT_BANNERS = [
  { id: 1, alt: 'Promoção de consultas', img: bannerPromotional, link: 'https://whts.co/SiteCannect175/' }
]
