/* eslint-disable react/jsx-no-useless-fragment */

import React from 'react'

type Props = {
  expr: any | (() => boolean)
  children: React.ReactNode | any
}

export default function When(props: Props) {
  const { expr, children } = props
  if (typeof expr === 'function' && !expr()) return <></>
  if (!expr) return <></>

  return typeof children === 'function' ? children() : children
}
