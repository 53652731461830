import { z } from 'zod'

export default {
  nonEmptyStringSchema: z.string({ required_error: 'Campo obrigatório' }).min(1, 'Campo obrigatório'),
  requiredField: 'Campo obrigatório.',
  invalidField: 'Campo inválido.',
  invalidEmail: 'Digite um e-mail válido.',
  invalidCep: 'Digite um CEP válido.',
  invalidPhone: 'Digite um telefone válido.',
  invalidCpf: 'Digite um CPF válido.',
  invalidName: 'Digite um nome válido.',
  invalidQuantity: 'Selecione uma quantidade válida para o produto.',
  invalidDate: 'Digite uma data válida.'
}
