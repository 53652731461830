import { MyOrdersProps } from '../../../..'
import { OrderItem, OrderPackage as OrderPackageType } from '../../../../types'
import ItemsTable from './components/ItemsTable'
import OrderPackageHeader from './components/OrderPackageHeader'
import * as Styles from './styles'

interface OrderPackageProps {
  package?: OrderPackageType
  items?: OrderItem[]
  packageNumber: number
}
export default function OrderPackage({ package: orderPackage, items = [], packageNumber }: OrderPackageProps) {
  return (
    <Styles.OrderPackageContainer>
      <OrderPackageHeader package={orderPackage} packageNumber={packageNumber} />
      <ItemsTable orderItems={orderPackage ? orderPackage.items : items} />
    </Styles.OrderPackageContainer>
  )
}
