import { createGlobalStyle } from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'
import FontName400 from 'assets/fonts/itc-avant-garde-gothic-demi.ttf'
import FontName600 from 'assets/fonts/ITCAvantGardeStdBold.ttf'
import FontName300 from 'assets/fonts/ITCAvantGardeStd-Bk.ttf'
import FontName100 from 'assets/fonts/SegoeUI.ttf'
import FontName200 from 'assets/fonts/SegoeUiBold.ttf'
import SpartanBold from 'assets/fonts/Spartan-Bold.ttf'
import SpartanMedium from 'assets/fonts/Spartan-Medium.ttf'
import SpartanRegular from 'assets/fonts/Spartan-Regular.ttf'
import SpartanSemiBold from 'assets/fonts/Spartan-SemiBold.ttf'

import 'react-toastify/dist/ReactToastify.css'
import { temporaryTheme } from './theme'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Nunito';
    scroll-behavior: smooth;

    &::selection {
      background: rgba(30, 140, 99, 0.7);
      color: #fff;
    }


  }

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
  }

  #page-wrapper {
    scroll-behavior: smooth;
  }



  @font-face {
    font-family: ITC Avant Garde Gothic;
    font-style: normal;
    font-weight: 400;
    src: url(${FontName400});
    font-display: swap;
  }
  @font-face {
    font-family: ITC Avant Garde Gothic;
    font-style: normal;
    font-weight: 300;
    src: url(${FontName300});
    font-display: swap;
  }
  @font-face {
    font-family: ITC Avant Garde Gothic;
    font-style: normal;
    font-weight: 600;
    src: url(${FontName600});
    font-display: swap;
  }

  @font-face {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 300;
    src: url(${FontName100});
    font-display: swap;
  }
  @font-face {
    font-family: SegoeUiBold;
    font-style: normal;
    font-weight: 500;
    src: url(${FontName200});
    font-display: swap;
  }
  @font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 400;
    src: url(${SpartanRegular});
    font-display: swap;
  }
  @font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    src: url(${SpartanMedium});
    font-display: swap;
  }
  @font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 600;
    src: url(${SpartanSemiBold});
    font-display: swap;
  }
  @font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 700;
    src: url(${SpartanBold});
    font-display: swap;
  }


  :root {
    --shadow-300: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    --shadow-700: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  }

  body {
    font-family: ITC Avant Garde Gothic;
    -webkit-font-smoothing: antialiased;
  }

  html {
     overscroll-behavior: none;
    font-size: 14px;

    @media ${MOBILE_WIDTH} {
      font-size: 12px;
    }
  }

  button {
    cursor: pointer;
  }

  .Toastify__toast-body *  {
    font-family: 'Poppins', sans-serif;
  }

  .Toastify__toast-body {
    font-family: 'Poppins', sans-serif;
  }

  .Toastify__toast-body > div:last-child {
    font-family: 'Poppins', sans-serif;

  }

  .ant-tooltip-inner, .ant-dropdown-menu  {
    * {
    font-family: 'Poppins', sans-serif;
    }
  }

  .ant-float-btn-icon {
   svg{
    color: ${temporaryTheme.colors.tertiary};
   }
  }

  .ant-dropdown-menu-item-selected{
    background-color: #21976E !important;
    color: white !important;
  }


  .ant-picker-panel-focused {
    *{
      font-family: 'Poppins', sans-serif;
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {

      background: ${temporaryTheme.colors.sitePrimary} !important;
    }
  }

  .ant-picker-cell-today {
    .ant-picker-cell-inner {
      &::before {
        border-color: ${temporaryTheme.colors.sitePrimary} !important;

      }
    }
  }

  .ant-upload, .ant-upload-select {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;

  }

  body > form:not(div form) {

      display: none;
    }


`
