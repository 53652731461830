import Texts from 'components/Text'
import * as Styles from './styles'
import { term } from '../../usecases/terms-of-use'

function TermsOfUse() {
  return (
    <Styles.Container>
      <Texts type="h1" color="tertiary">
        Termos de uso
      </Texts>
      <div dangerouslySetInnerHTML={{ __html: term }} />
    </Styles.Container>
  )
}

export default TermsOfUse
