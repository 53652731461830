import { useTokenApi } from 'hooks/useTokenApi'
import React from 'react'
import { CiSliderHorizontal } from 'react-icons/ci'
import { IoIosArrowDown } from 'react-icons/io'
import * as Styles from './styles'

interface DataDropdown {
  id: number
  title: string
  type?: string
  value?: string
  enableWithLogin?: boolean
}

export interface FilterDropdownProps {
  icon?: React.ReactNode
  title: string
  data?: DataDropdown[]
  selectedValue: { title: string; type?: string; value?: string }
  onChangeValue?: any
  hiddenMobile?: boolean
  imageIcon?: string
  hiddenIconMobile?: boolean
}

export default function FilterDropdown({
  title,
  icon = <CiSliderHorizontal />,
  data = [],
  selectedValue,
  onChangeValue = () => null,
  hiddenMobile = false,
  imageIcon,
  hiddenIconMobile
}: FilterDropdownProps) {
  const { tokenApi } = useTokenApi()

  const validateAction = ({ enableLogin, title, value }: { enableLogin?: boolean; title: string; value: string }) => {
    if (enableLogin) {
      if (tokenApi) {
        onChangeValue({ title, type: value, value })
      }
    } else {
      onChangeValue({ title, type: value, value })
    }
  }

  return (
    <Styles.Wrapper hiddenMobile={hiddenMobile}>
      <Styles.DropdownRoot>
        <Styles.DropdownTrigger asChild>
          <Styles.ButtonDropdown className="button_dropdown">
            {!hiddenIconMobile && (
              <Styles.IconTypeBox>{imageIcon ? <Styles.ImageIcon src={imageIcon} /> : icon}</Styles.IconTypeBox>
            )}
            <p className="text">{selectedValue.title || title}</p>
            <IoIosArrowDown />
          </Styles.ButtonDropdown>
        </Styles.DropdownTrigger>

        <Styles.DropdownPortal>
          <Styles.DropdownContent>
            {data.length > 0 &&
              data.map(item => (
                <Styles.DropdownItem
                  disabled={item?.enableWithLogin ? !tokenApi : false}
                  key={item.id}
                  onSelect={e =>
                    validateAction({
                      enableLogin: item?.enableWithLogin,
                      title: item.title,
                      value: item.value || item.type || ''
                    })
                  }
                >
                  {item.title}
                </Styles.DropdownItem>
              ))}
          </Styles.DropdownContent>
        </Styles.DropdownPortal>
      </Styles.DropdownRoot>
    </Styles.Wrapper>
  )
}
