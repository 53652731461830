import { theme } from 'design-cannect'
import styled from 'styled-components'

export const PathButtonContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 335px;
  min-width: 335px;
  height: 345px;
  min-height: 345px;

  border-radius: 0.5rem;
  border: 2px solid #ecece2;
  background: transparent;

  padding: 3rem;
  transition: transform 0.2s;

  img {
    margin-bottom: 2.5rem;
  }

  span {
    color: ${theme.colors.tertiary};
    font-weight: 700;
    font-size: 1.2rem;
    max-width: 215px;
    text-align: center;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`
