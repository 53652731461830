import { useForm } from 'react-hook-form'
import { FormSteps, stepOneSchema, stepThreeSchema, stepTwoSchema, TFormValues } from './types'
import { useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  TWebinarSubscriptionPayload,
  useFindAlreadySubscribedUserMutation,
  useGetWebinarDetail,
  useSubscribeUserMutation,
  useValidateCRMMutation
} from '@cannect/services/resources/webinars'
import { useParams } from 'react-router'

export const useWebinarSubscription = () => {
  const { webinar_title } = useParams<{ webinar_title: string }>()
  const { data, isLoading: isWerbinarDetailsLoading } = useGetWebinarDetail(webinar_title)
  const { mutateAsync: validateCRMMutation } = useValidateCRMMutation()
  const { mutateAsync: findAlreadySubscribedUser, data: alreadySubscribedData } = useFindAlreadySubscribedUserMutation()
  const { mutateAsync: subscribeUserMutation } = useSubscribeUserMutation()

  const [currentStep, setCurrentStep] = useState<FormSteps>(FormSteps.EMAIL_STEP)

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const handlePreviousStep = () => {
    if (currentStep === FormSteps.EMAIL_STEP) return

    setCurrentStep(currentStep - 1)
  }

  const handleGoToStep = (index: FormSteps) => {
    setCurrentStep(index)
  }

  const stepsSchema = [stepOneSchema, stepTwoSchema, stepThreeSchema]
  const currentSchema = useMemo(() => stepsSchema[currentStep], [currentStep])

  const webinarSubscriptionForm = useForm<TFormValues>({
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      expertise: '',
      council: undefined,
      uf_council: undefined,
      nr_council: '',
      whatsapp: '',
      terms_privacy: false,
      terms_comunication_accepted: false
    },
    resolver: zodResolver(currentSchema)
  })

  const { setError, setValue, reset, getValues } = webinarSubscriptionForm

  const webinarItem = data?.webinar
  const alreadySubscribedUser = alreadySubscribedData?.usersWebinar

  const handleStepOneSubmit = async (formData: TFormValues) => {
    if (!data?.webinar?.id) return null

    return findAlreadySubscribedUser(
      {
        email: formData.email,
        webinar_id: data?.webinar.id
      },
      {
        onSuccess: () => {
          handleGoToStep(FormSteps.CONCLUSION_STEP)
        },
        onError: () => {
          handleNextStep()
        }
      }
    )
  }

  const handleStepTwoSubmit = async (formData: TFormValues) => {
    if (formData?.council?.value === 'CRM') {
      return validateCRMMutation(
        {
          uf_council: formData.uf_council.label,
          nr_council: formData.nr_council
        },
        {
          onSuccess: data => {
            setValue('first_name', data?.prescriber?.first_name || '')
            setValue('last_name', data?.prescriber?.last_name || '')
            handleNextStep()
          },
          onError: () => {
            setError('nr_council', { message: 'Informe um número de conselho válido.' })
          }
        }
      )
    }
    return handleNextStep()
  }

  const handleStepThreeSubmit = async () => {
    if (!webinarItem?.id) return null

    const { council, uf_council, nr_council, ...rest } = getValues()
    getValues()

    const payload: TWebinarSubscriptionPayload = {
      webinar_id: webinarItem?.id,
      nr_council,
      council: council.value,
      uf_council: uf_council.label,
      is_doctor: council.value === 'CRM',
      ...rest
    }

    return subscribeUserMutation(
      {
        ...payload
      },
      {
        onSuccess: () => {
          reset()
          handleNextStep()
        }
      }
    )
  }

  const onSubmit = async (formData: TFormValues) => {
    if (currentStep === FormSteps.EMAIL_STEP) {
      await handleStepOneSubmit(formData)
      return
    }
    if (currentStep === FormSteps.COUNCIL_VALIDATION_STEP) {
      await handleStepTwoSubmit(formData)
      return
    }
    await handleStepThreeSubmit()
  }

  const isPracticeWebinar = useMemo(() => {
    // practice webinars allow only doctors with CRM
    if (webinarItem?.category.toLowerCase().includes('prática')) return true
    return false
  }, [webinarItem?.category])

  useEffect(() => {
    if (isPracticeWebinar) {
      setValue('council', {
        value: 'CRM',
        label: 'CRM'
      })
    }
  }, [isPracticeWebinar])

  const isLastStep = currentStep === FormSteps.CONCLUSION_STEP
  const isFirstStep = currentStep === FormSteps.EMAIL_STEP
  const isAlreadySubscribedUser = useMemo(() => {
    if (alreadySubscribedUser?.email) return true
    return false
  }, [alreadySubscribedUser])

  return {
    webinarSubscriptionForm,
    currentStep,
    handleNextStep,
    handlePreviousStep,
    handleGoToStep,
    webinarItem,
    onSubmit,
    isPracticeWebinar,
    isWerbinarDetailsLoading,
    isLastStep,
    isFirstStep,
    isAlreadySubscribedUser
  }
}
