import { IGetAllCategoriesResponse } from 'dtos/Category'
import { createContext, useContext, useState } from 'react'
import { getAllCategories } from 'usecases/categories'

const BlogFilterContext = createContext<any>(null)
BlogFilterContext.displayName = 'BlogFilterContext'

function BlogFilterProvider({ children }: any) {
  const [categories, setCategories] = useState<IGetAllCategoriesResponse>({} as IGetAllCategoriesResponse)
  const [categorySelected, setCategorySelected] = useState('')
  const [slugCategory, setSlugCategory] = useState('')
  const [searchHome, setSearchHome] = useState('')

  const getCategories = async () => {
    try {
      const responseCategories = await getAllCategories()
      if (responseCategories) {
        setCategories(responseCategories)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSelectedCategoryName = (category: string) => {
    setCategorySelected(category)
  }

  return (
    <BlogFilterContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        categories,
        getCategories,
        categorySelected,
        handleSelectedCategoryName,
        searchHome,
        setSearchHome,
        slugCategory,
        setSlugCategory
      }}
    >
      {children}
    </BlogFilterContext.Provider>
  )
}

const useBlogFilter = () => useContext(BlogFilterContext)

export { BlogFilterProvider, useBlogFilter }
