import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  margin: 6rem 0;
  padding: 0 3rem;
`

export const CardAuthor = styled.div`
  display: flex;
  align-items: center;
  gap: ${temporaryTheme.spacing.space4};

  @media (${MOBILE_WIDTH}) {
    flex-direction: column;
  }

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    p {
      span {
        color: ${theme.colors.tertiary} !important;
        font-weight: 300;
      }
    }
  }
`

export const InfoBox = styled.div`
  width: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space4};

  @media (${MOBILE_WIDTH}) {
    width: 100%;
  }
`
