import { QueryCache, QueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { meta, queryKey } = query || {}
      if (meta?.errorMessage && queryKey) {
        toast.error(meta?.errorMessage as string, {
          toastId: queryKey?.toString()
        })
      }
    }
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 2, // 2 minutes
      retry: false
    },
    mutations: {
      retry: false
    }
  }
})
