import { theme } from 'design-cannect'
import styled from 'styled-components'

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  transition: transform 0.2s;

  label {
    font-weight: 300;
    font-size: 1.1rem;
    cursor: pointer;
  }

  input {
    display: none;
  }

  &:hover {
    transform: scale(1.05);
  }
`

interface VisualInputProps {
  checked: boolean
}

export const VisualInput = styled.label<VisualInputProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;

  border-radius: 999px;
  border: 1px solid ${theme.colors.tertiary};
  cursor: pointer;

  ${props =>
    props.checked &&
    `&:after {
      content: '';

      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;

      top: 0;
      left: 0;

      background: ${theme.colors.tertiary};
      border-radius: 999px;
    }`}
`
