import { useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { CiTrash } from 'react-icons/ci'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'

import { noop } from 'lodash'
import * as S from './styles'
import { temporaryTheme } from 'styles/theme'
import { DocumentVisualization } from '../DocumentVisualization'

interface ListDocumentTypes {
  documents: any[]
  onRemove: (documentId: number) => void
}

export function ListDocuments({ documents = [], onRemove = noop }: ListDocumentTypes) {
  const [visualizationModal, setVisualizationModal] = useState(false)
  const [documentVisualization, setDocumentVisualization] = useState({ url: '', extension: '' })

  const handleTrashClick = ({ e, itemId }: any) => {
    e.stopPropagation()
    onRemove(itemId)
  }

  const openVisualizationModal = ({ url, extension }: { url?: string; extension?: string }) => {
    if (url && extension) {
      setDocumentVisualization({ url, extension })
      setVisualizationModal(true)
    }
  }

  const menu = (
    <Menu>
      {documents.map(item => (
        <Menu.Item key={item.id} onClick={() => openVisualizationModal({ url: item.url, extension: item.extension })}>
          <S.MenuItem>
            <S.NameFile>{item?.name_file || item.id}</S.NameFile>
            <S.ActionsFile>
              <CiTrash color={temporaryTheme.colors.red} onClick={e => handleTrashClick({ e, itemId: item.id })} />
              <S.VisualizationAction>visualizar</S.VisualizationAction>
            </S.ActionsFile>
          </S.MenuItem>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <S.Wrapper>
      <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
        <S.SpanDropdown>
          <p>
            Lista de documentos
            <HiOutlineClipboardDocumentList />
          </p>

          <p>
            {documents.length} {documents.length > 1 ? 'arquivos anexados' : 'arquivo anexado'}
          </p>
        </S.SpanDropdown>
      </Dropdown>

      <DocumentVisualization
        openVisualization={visualizationModal}
        file={documentVisualization}
        closeVisualization={() => setVisualizationModal(false)}
      />
    </S.Wrapper>
  )
}
