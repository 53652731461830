import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useHistory } from 'react-router'
import { DIGITAL_JOURNEY_ROUTES } from '../../constants'
import { useGeneratePowerOfAttorneyMutation } from '@cannect/services/resources/digitalJourney/hooks'
import { useState } from 'react'

declare global {
  interface Window {
    Clicksign: any
  }
}

export const useGenerateAndSignProcurationStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()

  const [widget, setWidget] = useState<any>(null)
  const generatePowerOfAttorneyMutation = useGeneratePowerOfAttorneyMutation()

  const initializeWidget = (signerId: string) => {
    if (!signerId) return

    // @ts-expect-error - widget is not defined
    // eslint-disable-next-line no-undef
    const generatedWidget = new Clicksign(signerId)

    generatedWidget.endpoint = import.meta.env.VITE_APP_CLICK_SIGN_URL
    generatedWidget.origin = window?.location?.origin
    generatedWidget.mount('clicksign-container')

    setWidget(generatedWidget || null)

    generatedWidget.on('signed', () => {
      setTimeout(() => {
        updateFormData({
          common_fields: {
            ...formData.common_fields,
            status: 'finished'
          }
        })

        history.push(DIGITAL_JOURNEY_ROUTES.journey_finished)
        setWidget(null)
        widget.unmount()
      }, 1000)
    })

    widget.on('loaded', () => {
      setWidget(widget || null)
    })
  }

  const handleGenerateProcuration = async () => {
    if (!formData?.common_fields?.digital_journey_id) return
    generatePowerOfAttorneyMutation.mutate(formData.common_fields.digital_journey_id, {
      onSuccess: (response) => {
        setWidget(null)
        setTimeout(() => {
          initializeWidget(response.form_data.generate_power_of_attorney_document.signer_id)
        }, 200)
      }
    })
  }

  const handleGoToSendAnvisaAuthorization = () => {
    history.push(DIGITAL_JOURNEY_ROUTES.send_anvisa_authorization)
  }

  return {
    handleGoToSendAnvisaAuthorization,
    handleGenerateProcuration,

    isLoading: generatePowerOfAttorneyMutation.isPending,
    orderId: formData?.common_fields?.order_id,
    widget
  }
}
