import styled from 'styled-components'

export const Container = styled.div<any>`
  background: #f6f6f6;
  border-radius: 4px;
  margin-top: 1rem;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  padding: 25.13px 27.45px 30.82px 33.45px;
  font-family: 'Poppins', sans-serif;

 input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #A4C55E;
    transition: opacity .2s;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #4A596C;
    cursor: none;
  }

  input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #4A596C;
    cursor: pointer;
  }

  p {
    color: #605f5e;
    width: 80%;
    margin: 16px 0;
  }
`
export const Title = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;

  text-transform: uppercase;
`
