import { theme } from 'design-cannect'
import styled from 'styled-components'

export const PrescriberTermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  padding: 44px 55px;

  .ant-typography {
    color: ${theme.colors.darkGrey};
  }

  button,
  span,
  h5 {
    font-size: 20px;
  }

  a {
    text-decoration: underline;
  }
  * {
    font-family: 'Nunito', sans-serif;
  }
`

export const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  outline: 2px solid #333639;
  margin: 24px 0 40px 0;

  overflow: auto;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
`
