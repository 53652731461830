import { cannectCannabisMedicinal, cannectCannabisMedicinal2 } from 'assets/img'
import useMediaQuery from 'hooks/useMediaQuery'
import { DESKTOP_WIDTH } from 'utils/constants'
import When from 'utils/when'
import * as Styles from './style'

function SecondSession() {
  const isDesktop = useMediaQuery(DESKTOP_WIDTH)
  return (
    <Styles.Container>
      <Styles.LeftWrapper>
        <Styles.TextDiv>
          <Styles.Title>Queremos superar a falta de conhecimento e o preconceito.</Styles.Title>
          <Styles.Subtitle>
            Focados na experiência do cliente, obcecados em incorporar de forma acelerada o que existe de mais avançado
            em tecnologia e processos de atendimento para oferecermos uma experiência personalizada.
            <br /> <br />
            <When expr={!isDesktop}>
              <Styles.RightWrapper>
                <img src={cannectCannabisMedicinal2} alt="banner" style={{ width: '200px', height: '190px' }} />
                <img src={cannectCannabisMedicinal} alt="banner" style={{ width: '120px', height: '190px' }} />
              </Styles.RightWrapper>
            </When>
            Nos propomos a ser um agente disruptivo e facilitador, utilizando muita tecnologia e conhecimento científico
            para democratizar o acesso aos tratamentos à base de cannabis medicinal.
          </Styles.Subtitle>
        </Styles.TextDiv>
      </Styles.LeftWrapper>
      <When expr={isDesktop}>
        <Styles.RightWrapper>
          <img src={cannectCannabisMedicinal2} alt="banner" />
          <img src={cannectCannabisMedicinal} alt="banner" />
        </Styles.RightWrapper>
      </When>
    </Styles.Container>
  )
}

export default SecondSession
