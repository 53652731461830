import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

export const Container = styled.div`
  margin: 6rem 0 4rem 0;
  padding: 0 3rem 0 3rem;

  ul,
  ol,
  li {
    font-weight: 300;
    color: ${theme.colors.tertiary};
    padding: 0.25rem;
    padding: 0.25rem;
    font-size: 1rem;
  }

  .author-date {
    display: flex;
    color: ${theme.colors.black};
    margin-top: 1rem;
    font-weight: 300;
    align-items: center;
    margin-left: 5px;

    img {
      border-radius: 50%;
      margin-bottom: 10px;
      margin-right: 10px;
      height: 45px;
      width: 45px;
    }

    span {
      margin-right: 20px;
    }

    span:last-child {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-bottom: 1px;
        margin-right: 10px;
      }
    }

    span:first-child {
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
`

export const ContainerPost = styled.div<any>`
  margin-top: 2rem;

  img {
    max-width: 1200px;
    max-height: 1200px;
  }

  @media ${TABLET_WIDTH} {
    img {
      max-width: 800px;
      max-height: 800px;
    }
  }

  @media ${MOBILE_WIDTH} {
    img {
      max-width: 400px;
      max-height: 400px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.tertiary};
    padding: 0.25rem;
  }

  p {
    font-weight: 300;
    padding: 0.25rem;
    font-size: 1rem;
    color: ${theme.colors.tertiary};

    a {
      text-decoration: underline;
      color: ${theme.colors.tertiary};
    }
  }

  a {
    text-decoration: underline;
    color: ${theme.colors.tertiary};
  }
`

export const Heading = styled.h3`
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-family: 'Spartan', sans-serif;
  font-size: 30px;
  margin: 16px 0;
  color: ${temporaryTheme.colors.dark};

  @media (${MOBILE_WIDTH}) {
    font-size: 22px;
  }
`
