import { IconePerfilPaciente, IconeProfissionalSaude } from 'assets/svg'
import { useState } from 'react'
import * as Styles from './styles'

interface RegistrationPathButtonProps {
  icon: 'patient' | 'professional'
  title: string
  text: string
  onClick: () => void
}

export default function RegistrationPathButton({ icon, title, text, onClick }: RegistrationPathButtonProps) {
  return (
    <Styles.RegistrationPathButtonContainer onClick={onClick}>
      {icon === 'patient' ? <IconePerfilPaciente /> : <IconeProfissionalSaude />}
      <div>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </Styles.RegistrationPathButtonContainer>
  )
}
