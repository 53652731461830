import { useMarketPlace } from 'hooks/useMarketPlace'
import { MedicalDataType } from 'pages/ProductDetails/typeProduct'
import * as Styles from './styles'
import { TabsPrimitive } from '@cannect/new-components/atoms'

export interface TabComponentProps {
  data: MedicalDataType
  product?: any
}

export default function TabComponent({ data, product }: TabComponentProps) {
  const { coaFile } = useMarketPlace()
  const validateText = (name: string, value?: string, format?: string) => {
    return (
      !!value && (
        <>
          {name}: <span>{format ? `${value} ${format}` : value}</span>
        </>
      )
    )
  }

  return (
    <Styles.Wrapper>
      <TabsPrimitive.Root defaultValue="value1">
        <TabsPrimitive.List className="ml-8 mt-10">
          <TabsPrimitive.Trigger className="px-[6px] py-[6px] text-secondary-800 lg:p-[8px]" value="value1">
            Ficha técnica
          </TabsPrimitive.Trigger>
          <TabsPrimitive.Trigger className="px-[6px] py-[6px] text-secondary-800 lg:p-[8px]" value="value2">
            COA
          </TabsPrimitive.Trigger>
        </TabsPrimitive.List>

        <Styles.ContentContainer>
          <Styles.TabContent value="value1">
            <Styles.Description>{product?.details}</Styles.Description>

            <Styles.InfoBox>
              <Styles.TextInfo>{validateText('Nome', product?.translated_name)}</Styles.TextInfo>

              <Styles.TextInfo>{validateText('Marca', product?.brand?.name)}</Styles.TextInfo>

              <Styles.TextInfo>{validateText('Forma', product?.shape?.name)}</Styles.TextInfo>

              <Styles.TextInfo>{validateText('Via de administração', product?.administration?.name)}</Styles.TextInfo>

              <Styles.TextInfo>{validateText('Classe', product?.class?.name)}</Styles.TextInfo>

              <Styles.TextInfo>{validateText('Sub-classe', product?.subclass?.name)}</Styles.TextInfo>

              <Styles.TextInfo>{validateText('Volume', `${data?.volume}`, data?.unit_of_measurement)}</Styles.TextInfo>

              {data?.principio_ativo ? (
                <Styles.TextInfo>{validateText('Princípio Ativo', data.principio_ativo)}</Styles.TextInfo>
              ) : (
                <>
                  {data?.cbc && <Styles.TextInfo>{validateText('CBC', data?.cbc, 'mg')}</Styles.TextInfo>}

                  {data?.cbc_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'CBC concentração',
                        data?.cbc_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.cbd && <Styles.TextInfo>{validateText('CBD', data?.cbd.toString(), 'mg')}</Styles.TextInfo>}

                  {data?.cbd_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'CBD concentração',
                        data?.cbd_concentration.toString(),
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.cbda && <Styles.TextInfo>{validateText('CBDA', data?.cbda, 'mg')}</Styles.TextInfo>}

                  {data?.cbda_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'CBDA concentração',
                        data?.cbda_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.cbdv && <Styles.TextInfo>{validateText('CBDV', data?.cbdv, 'mg')}</Styles.TextInfo>}

                  {data?.cbdv_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'CBDV concentração',
                        data?.cbdv_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.cbg && <Styles.TextInfo>{validateText('CBG', data?.cbg, 'mg')}</Styles.TextInfo>}

                  {data?.cbg_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'CBG concentração',
                        data?.cbg_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.cbga && <Styles.TextInfo>{validateText('CBGA', data?.cbga, 'mg')}</Styles.TextInfo>}

                  {data?.cbga_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'CBGA concentração',
                        data?.cbga_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.cbn && <Styles.TextInfo>{validateText('CBN', data?.cbn, 'mg')}</Styles.TextInfo>}

                  {data?.cbn_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'CBN concentração',
                        data?.cbn_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.delta8 && <Styles.TextInfo>{validateText('Delta-8', data?.delta8, 'mg')}</Styles.TextInfo>}

                  {data?.delta8_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'Delta-8 concentração',
                        data?.delta8_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.delta8_thcv && (
                    <Styles.TextInfo>{validateText('Delta-8 THCV', data?.delta8_thcv, 'mg')}</Styles.TextInfo>
                  )}

                  {data?.delta8_thcv_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'Delta-8 THCV concentração',
                        data?.delta8_thcv_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.delta9_thcv && (
                    <Styles.TextInfo>{validateText('Delta-9 THCV', data?.delta9_thcv, 'mg')}</Styles.TextInfo>
                  )}

                  {data?.delta9_thcv_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'Delta-9 THCV concentração',
                        data?.delta9_thcv_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.delta10 && <Styles.TextInfo>{validateText('Delta-10', data?.delta10, 'mg')}</Styles.TextInfo>}

                  {data?.delta10_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'Delta-10 concentração',
                        data?.delta10_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}

                  {data?.thc && <Styles.TextInfo>{validateText('THC', data?.thc, 'mg')}</Styles.TextInfo>}

                  {data?.thc_concentration && (
                    <Styles.TextInfo>
                      {validateText(
                        'THC concentração',
                        data?.thc_concentration,
                        `mg/${data?.unit_of_measurement.toLowerCase()}`
                      )}
                    </Styles.TextInfo>
                  )}
                </>
              )}
            </Styles.InfoBox>
          </Styles.TabContent>

          <Styles.TabContent value="value2">
            <Styles.ViewPdf src={coaFile[0]?.path} title={coaFile[0]?.name} />
          </Styles.TabContent>
        </Styles.ContentContainer>
      </TabsPrimitive.Root>
    </Styles.Wrapper>
  )
}
