import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'

export const BreadCrumbContainer = styled.section<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding: ${(props) => (props.isMobile ? '4rem' : '1rem')} 1rem 0 0 ${(props) => (props.isMobile ? '2rem' : '1rem')};
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? '1rem' : '3rem')};
  margin-top: ${(props) => (props.isMobile ? '1rem' : '3rem')};

  ${media.mobileWithTablet`
    display: none;
  `}
`

export const PathContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  svg {
    font-size: 3rem;
    color: red;
  }

  .cart-content {
    background-color: ${theme.colors.tertiary};
    color: white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    background-color: transparent;
    border: none;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      font-size: 1.1rem;
      font-weight: 700;
      color: red;
    }

    button {
      font-size: 0.8rem;
      color: red;
    }
  }
`
