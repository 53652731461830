import styled from 'styled-components'

export const StepButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 5rem;
  height: 5rem;
  border-radius: 999px;

  font-size: 1.5rem;
  outline: none;
  border: none;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    cursor: not-allowed;
  }
`
