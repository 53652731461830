import { Icons } from '@cannect/new-components/atoms'
import { LuCreditCard } from 'react-icons/lu'

export const paymentOptions = [
  {
    value: 'credit_card',
    label: 'Cartão de crédito',
    description: (
      <>
        Parcele em até <strong>6x sem juros</strong>
      </>
    ),
    icon: (
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-50">
        <LuCreditCard className="size-5 text-primary-700" />
      </div>
    )
  },
  {
    value: 'pix',
    label: 'Pix',
    description: (
      <>
        Aprovação <strong>imediata</strong>
      </>
    ),
    icon: (
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-50">
        <Icons.Pix className="size-5 text-primary-700" />
      </div>
    )
  },
  {
    value: 'boleto',
    label: 'Boleto',
    description: (
      <>
        Aprovado em até <strong>3 dias úteis</strong>
      </>
    ),
    icon: (
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-50">
        <Icons.BarCode className="size-5 text-primary-700" />
      </div>
    )
  }
]
