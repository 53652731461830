/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

export const MinimalistInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  & * {
    color: #3d3d3d;
  }
`

export const MinimalistInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  label {
    font-size: 1rem;
    color: #115850 !important;
  }
`

interface MinimalistInputItselfProps {
  hasError: boolean
}
export const MinimalistInputItself = styled.input<MinimalistInputItselfProps>`
  border: none;
  border-bottom: 1px solid ${({ hasError }) => (hasError ? '#e24444' : '#d9d9d9')};
  padding: 0.5rem;

  background: transparent;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &::placeholder {
    color: #d9d9d9;
    font-weight: 300;
  }
`

export const MinimalistInputErrorMessage = styled.p`
  font-size: 1rem;
  color: #e24444 !important;
  font-weight: 300;
`
