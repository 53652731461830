import { useEffect, useState } from 'react'

import { getAllPostsByCategories } from 'usecases/categories'

import { useHistory } from 'react-router'
import StoryCard from './Card'

import * as Style from './styles'

function RealStories() {
  const [story, setStories] = useState<any>({})
  const history = useHistory()

  useEffect(() => {
    const loadStories = async () => {
      try {
        const responsePosts = await getAllPostsByCategories({
          category: 'historias-reais'
        })

        setStories(responsePosts)
      } catch (e) {
        console.log(e)
      }
    }
    loadStories()
  }, [])

  return (
    <Style.Container className="real_history">
      <Style.Title>
        <span>Histórias reais</span> de quem <span>confia na Cannect</span>
      </Style.Title>
      <Style.StoriesContainer>
        {story?.data?.slice(0, 5).map((story: any) => (
          <StoryCard key={story.id} story={story} />
        ))}
      </Style.StoriesContainer>
      <Style.MoreInfo onClick={() => history.push('/comunidade')}> Leia outras histórias</Style.MoreInfo>
    </Style.Container>
  )
}

export default RealStories
