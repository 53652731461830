import React from 'react'
import { Container } from './styles'
import { alert, caixa, caixaaberta, caminhao, money } from '../../../assets/svg'
import Text from '../../Text'

function DemandStatus() {
  // eslint-disable-next-line react/no-unstable-nested-components
  function Badge({ title, status, img }: any) {
    return (
      <div className="wrapper">
        <div className={status ? 'img-active' : 'img-deactive'}>
          <img src={img} alt="alert" />
        </div>
        <Text type="md" bold color={status ? 'secondary' : 'gray'}>
          {title}
        </Text>
      </div>
    )
  }

  return (
    <Container>
      <div className="secession">
        <Badge title="Faltam documentos" img={alert} status />
        <div className="barra" />
        <Badge title="Pagamento pendente" img={money} />
        <div className="barra" />
        <Badge title="Pedido em separação" img={caixaaberta} />
        <div className="barra" />
        <Badge title="Pedido a caminho" img={caminhao} />
        <div className="barra" />
        <Badge title="Pedido entregue" img={caixa} />
      </div>
    </Container>
  )
}

export default DemandStatus
