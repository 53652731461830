import LinkAsButton from 'new-components/LinkAsButton'
import * as Styles from './styles'

interface NewAddressButtonProps {
  typeOfAddress: 'delivery' | 'billing'
}
export default function NewAddressButton({ typeOfAddress }: NewAddressButtonProps) {
  const onClick = () => {
    localStorage.setItem('@CANNECT_NEW_ADDRESS_TYPE', typeOfAddress)
    localStorage.setItem(
      '@CANNECT_NEW_ADDRESS_FROM',
      `/conta?pagina=enderecos-${typeOfAddress === 'delivery' ? 'entrega' : 'cobranca'}`
    )
  }

  return (
    <Styles.NewAddressButtonContainer>
      <LinkAsButton to="/conta?pagina=enderecos-novo" onClick={onClick} style={{ alignSelf: 'center' }}>
        NOVO ENDEREÇO
      </LinkAsButton>
    </Styles.NewAddressButtonContainer>
  )
}
