/* eslint-disable no-nested-ternary */
import InvisibleButton from 'components/InvisibleButton'
import { svgArrow } from 'assets/svg'
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import * as Style from './styles'

interface PostProps {
  url?: string
  img?: string
  title: string
  actionButtonText?: string
  description: string
  externalRedirect?: boolean
  inLine?: boolean
}

export default function TabContentPost({
  url,
  img,
  title,
  description,
  externalRedirect,
  actionButtonText,
  inLine = false
}: PostProps) {
  const history = useHistory()
  const [formattedDescription, setFormattedDescription] = useState('')
  const [formattedTitle, setFormattedTitle] = useState('')

  useEffect(() => {
    if (description.length > 80) {
      if (inLine) {
        setFormattedDescription(`${description.slice(0, 300)}...`)
      } else {
        setFormattedDescription(`${description.slice(0, 80)}...`)
      }
    } else {
      setFormattedDescription(description)
    }
    if (title.length > 40) {
      if (inLine) {
        setFormattedTitle(`${title.slice(0, 300)}...`)
      } else {
        setFormattedTitle(`${title.slice(0, 55)}...`)
      }
    } else {
      setFormattedTitle(title)
    }
  }, [title])

  function handleRedirect() {
    history.push(url || '')
  }

  return (
    <Style.PostContainer inLine={inLine}>
      <Style.ContentWrapper inLine={inLine}>
        {img && <img src={img} alt="Post Logo" />}
        {inLine ? (
          <Link to={url || '/'}>
            <h1>{formattedTitle}</h1>
          </Link>
        ) : (
          <h1>{formattedTitle}</h1>
        )}

        {/* <p title={description}>{formattedDescription}</p> */}
        <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
      </Style.ContentWrapper>

      {!externalRedirect && !inLine ? (
        <InvisibleButton onClick={() => handleRedirect()} color="tertiary" alignSelf="flex-start">
          {actionButtonText} <img src={svgArrow} alt="->" />
        </InvisibleButton>
      ) : inLine ? (
        ''
      ) : (
        <a href={url} target="_blank" rel="noreferrer">
          {actionButtonText} <img src={svgArrow} alt="->" />
        </a>
      )}
    </Style.PostContainer>
  )
}
