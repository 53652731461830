import { Button } from 'design-cannect'
import { useHistory, useLocation } from 'react-router-dom'
import * as Style from './styles'

const tabs = [
  {
    path: '/assistente',
    label: 'Cannect Assistente'
  },
  {
    path: '/cuida',
    label: 'Cannect Cuida'
  },
  {
    path: '/produtos/1',
    label: 'Cannect Produtos'
  },
  {
    path: '/educa',
    label: 'Cannect Educa'
  },
  {
    path: '/cannect-ciencia',
    label: 'Cannect Ciência'
  },
  {
    path: '/teste-genetico',
    label: 'Cannect Gene'
  }
]

export default function TabsNavigator() {
  const history = useHistory()
  const location = useLocation()

  function handleRedirect(path: string) {
    history.push(path)
  }

  return (
    <Style.TabsNavigatorContainer>
      {tabs.map(tab => (
        <Button
          key={tab.path}
          onClick={() => handleRedirect(tab.path)}
          secondary={location.pathname.includes(tab.path)}
        >
          {tab.label}
        </Button>
      ))}
    </Style.TabsNavigatorContainer>
  )
}
