import { ecosystem, screeningPhoto } from 'assets/img'
import RealStories from 'components/Home/RealStories'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { BiMessageRoundedDetail } from 'react-icons/bi'
import { redirectLink } from 'utils/redirectLink'
import { ABOUT_FUNCTION, ABOUT_SCREENING } from './screeningData'
import * as S from './styles'

export function Screening() {
  return (
    <S.Wrapper>
      <S.Banner>
        <S.ContentBanner>
          <S.Title>
            Cannect Triagem <br />
            <span>O primeiro passo para uma vida melhor</span>
          </S.Title>

          <S.ButtonAction onClick={() => redirectLink('https://atendimento.cannect.life/triagem')}>
            <BiMessageRoundedDetail /> Iniciar minha triagem
          </S.ButtonAction>
        </S.ContentBanner>
      </S.Banner>

      <S.Section>
        <S.ContentSection>
          <S.Box>
            <S.TextBox>
              <S.Heading>
                O que é nossa <span>Triagem</span>
              </S.Heading>

              <TextDanger text={ABOUT_SCREENING} fontFamily="Nunito" fontSize="20px" />
            </S.TextBox>
          </S.Box>

          <S.Box>
            <S.ImageBox>
              <S.Image src={ecosystem} alt="ecossistema Cannect" />
            </S.ImageBox>
          </S.Box>
        </S.ContentSection>
      </S.Section>

      <S.SectionImage>
        <S.ContentSection>
          <S.Box>
            <S.ImageHistory src={screeningPhoto} alt="screening history" />
          </S.Box>
          <S.Box>
            <S.TextBox>
              <S.Heading color="white">Como funciona</S.Heading>
              <TextDanger text={ABOUT_FUNCTION} fontFamily="Nunito" fontSize="20px" color="white" />
            </S.TextBox>
          </S.Box>
        </S.ContentSection>
      </S.SectionImage>

      <S.Section>
        <S.ContentSection>
          <RealStories />
        </S.ContentSection>
      </S.Section>
    </S.Wrapper>
  )
}
