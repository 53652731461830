import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  padding: 76px;

  h1 {
    text-align: center;
  }
`
