import * as Styles from './styles'

interface StatusIndicatorProps {
  label: string
  color: string
}

export default function StatusIndicator({ label, color }: StatusIndicatorProps) {
  return (
    <Styles.StatusIndicatorContainer background={color}>
      <label htmlFor="status">{label}</label>
    </Styles.StatusIndicatorContainer>
  )
}
