import { ProgressBar, Typography } from '@cannect/new-components/atoms'
import { TStepName } from '@cannect/pages/DigitalJourney/types'
import { calculateGroupedProgress, GROUPED_STEPS, shouldShowProgressBar } from './utils'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'

export const Progress = ({ currentStep }: { currentStep: TStepName }) => {
  const { formData } = useDigitalJourney()
  const currentStepTitle = GROUPED_STEPS.find(({ steps }) => steps.includes(currentStep))?.title || ''
  const canShowProgressBar = shouldShowProgressBar(currentStep, formData?.common_fields?.status)

  if (!canShowProgressBar) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <Typography.Text type="captionOne" className="text-neutral-300">
        {currentStepTitle}
      </Typography.Text>

      <div className="flex w-full gap-1">
        {GROUPED_STEPS.map(({ key, steps }) => (
          <ProgressBar
            value={calculateGroupedProgress(steps, currentStep)}
            className="h-[6px] bg-secondary-200"
            key={key}
            indicatorClassName="bg-primary-700"
          />
        ))}
      </div>
    </div>
  )
}
