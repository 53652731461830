import BreadCrumb from 'components/BreadCrumb'
import LoadingContainer from 'components/LoadingContainer'
import Heading from 'new-components/Heading'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import * as Styles from './styles'
import { useGetStrapiBrands } from '@cannect/services/resources/strapi'

type TBrandDetailData = {
  about: string
  logo: { id: number; url: string }
  slug: string
  title: string
}

type TLogoItem = {
  id?: number
  data: TBrandDetailData
}

function Brands() {
  const history = useHistory()
  const { data, isLoading } = useGetStrapiBrands({
    filterQuery: 'populate=logo&pagination[pageSize]=100'
  })

  const formattedItems: TLogoItem[] = useMemo(() => {
    // @TODO: refactor to serialize inside the hook
    return (
      data?.data?.map((item: any) => ({
        id: item?.id,
        data: {
          about: item?.attributes?.about,
          logo: {
            id: item?.attributes?.logo?.data?.id,
            url: `${import.meta.env.VITE_APP_STRAPI_URL}${item?.attributes?.logo?.data?.attributes?.url}`
          },
          slug: item?.attributes?.slug,
          title: item?.attributes?.title
        }
      })) || []
    )
  }, [data])

  return (
    <Styles.Wrapper>
      <BreadCrumb paths={[{ label: 'Marcas Parceiras', link: '/marcas' }]} />
      <Heading fontSize="40px" verticalSpace="xlarge">
        <span>Parceiros</span> Cannect
      </Heading>
      {isLoading ? (
        <LoadingContainer loading />
      ) : (
        <Styles.ContainerBrands>
          {formattedItems.map((item) => (
            <Styles.BoxBrands key={item.id} onClick={() => history.push(`/marcas/${item.data.slug}`)}>
              <Styles.BrandsImage src={item.data.logo.url} alt={item.data.title} />
            </Styles.BoxBrands>
          ))}
        </Styles.ContainerBrands>
      )}
    </Styles.Wrapper>
  )
}

export default Brands
