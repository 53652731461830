/* eslint-disable prettier/prettier */
import { AxiosResponse } from 'axios'
import { IGetHistoriesParams } from 'dtos/History'

import strapi from 'services/strapi'

export const getAllHistories = async (): Promise<any> => {
  const result: AxiosResponse<any> = await strapi.get(
    `/api/historias?populate=image`
  )

  return result.data
}

export const getHistoryById = async (params: IGetHistoriesParams): Promise<any> => {
  const result: AxiosResponse<any> = await strapi.get(
    `/api/posts/${params.id}?populate=image`
  )

  return result.data
}
