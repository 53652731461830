import styled from 'styled-components'

interface FakeFetchSelectProps {
  hasError: boolean
}
export const FakeFetchSelect = styled.input<FakeFetchSelectProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;
  border-radius: 0.5rem;
  border: ${({ hasError }) => (hasError ? '1px solid #e24444' : '1px solid #d9d9d9')};

  color: #3d3d3d;
  height: 3.7rem;
  z-index: 5;

  background: #ffffff;

  .fake-select-placeholder {
    color: #d9d9d9;
    opacity: 1;
    font-weight: 300;
  }

  &:focus {
    border: 1px solid #3d3d3d;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
