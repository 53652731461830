/* eslint-disable prettier/prettier */
import { AxiosResponse } from 'axios'
import {
  IGetAllCategoriesByPostResponse,
  IGetAllCategoriesHome,
  IGetAllCategoriesResponse,
  IPostsByCategoryStrapiRequest
} from 'dtos/Category'
import strapi from 'services/strapi'

export const getAllCategories = async (): Promise<IGetAllCategoriesResponse> => {
  const result: AxiosResponse<IGetAllCategoriesResponse> = await strapi.get(`/api/categories`)

  return result.data
}

export const getAllPostsByCategories = async (
  params: IPostsByCategoryStrapiRequest
): Promise<IGetAllCategoriesByPostResponse> => {
  let apiUrl = `/api/posts?filters[$and][0][categories][slug][$eq]=${params.category}&populate=image`

  if (params.limit !== undefined) {
    apiUrl += `&pagination[limit]=${params.limit}`
  }

  const result: AxiosResponse<IGetAllCategoriesByPostResponse> = await strapi.get(apiUrl)

  return result.data
}

export const getAllPostsPaginateByCategories = async (): Promise<IGetAllCategoriesHome[]> => {
  const result: AxiosResponse<IGetAllCategoriesHome[]> = await strapi.get(`/api/category/test`)

  return result.data
}
