import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import When from 'utils/when'
import * as Styles from './styles'

export interface ResumeTreatmentsProps {
  title: string
  categoryTitle: string
  categoryIcon?: string
  image: string
  text: string
  onClickPrimary?: () => void
  onClickSecondary?: () => void
}

export default function ResumeTreatments({
  title,
  categoryTitle,
  categoryIcon,
  image,
  text,
  onClickPrimary,
  onClickSecondary
}: ResumeTreatmentsProps) {
  return (
    <Styles.Wrapper>
      <Heading imageIcon={`${import.meta.env.VITE_APP_STRAPI_URL}${categoryIcon}`}>
        <TextDanger text={categoryTitle} fontSize="30px" fontFamily="Spartan" fontWeight={700} />
      </Heading>

      <Styles.Content>
        <Styles.ImageBox>
          <When expr={!!image}>
            <Styles.Image src={`${import.meta.env.VITE_APP_STRAPI_URL}${image}`} alt={title} loading="lazy" />
          </When>
        </Styles.ImageBox>

        <Styles.TextContent>
          <Styles.Title>{title} </Styles.Title>
          <TextDanger text={text} justify="justify" />

          <Styles.ActionsContent>
            <Button background="black" onClick={onClickPrimary}>
              Agende sua consulta
            </Button>
            <Button background="green" isOutlined onClick={onClickSecondary}>
              Inicie seu tratamento
            </Button>
          </Styles.ActionsContent>
        </Styles.TextContent>
      </Styles.Content>
    </Styles.Wrapper>
  )
}
