import { FiSquare, FiCheckSquare } from 'react-icons/fi'
import { OrderStatus } from 'pages/Account/components/pages/MyOrders/types'
import {
  CardIcon,
  CircleWavyCheckIcon,
  FileIcon,
  FlagIcon,
  GlobeHemisphereWestIcon,
  ListChecksIcon,
  MagnifyingGlassIcon,
  PackageIcon
} from 'assets/svg/StatusRuleIcons'

export const getStatusIcon = (status: OrderStatus, isChecked: boolean) => {
  switch (status) {
    case 'paid':
      return <CardIcon isChecked={isChecked} />
    case 'approved_documents':
      return <FileIcon isChecked={isChecked} />
    case 'finished_items':
      return <GlobeHemisphereWestIcon isChecked={isChecked} />
    case 'dispatched':
      return <PackageIcon isChecked={isChecked} />
    case 'anvisa_br':
      return <FlagIcon isChecked={isChecked} />
    case 'delay_release':
      return <MagnifyingGlassIcon isChecked={isChecked} />
    case 'release_anvisa':
      return <ListChecksIcon isChecked={isChecked} />
    case 'delivered':
      return <CircleWavyCheckIcon isChecked={isChecked} />
    default:
      if (isChecked) return <FiCheckSquare />
      return <FiSquare />
  }
}
