import BreadCrumb from 'components/BreadCrumb'
import { noop } from 'lodash'
import React from 'react'
import Header from '../Header'
import * as Styles from './styles'

interface BradCrumb {
  label: string
  link: string
}

export interface BaseProps {
  children: React.ReactNode
  selectCategoryBlog?: (slug: string) => void
  pathBreadCrumb?: BradCrumb[]
  handleSearch?: (val: string) => void
}

export default function Base({
  children,
  selectCategoryBlog = () => null,
  pathBreadCrumb = [],
  handleSearch = noop
}: BaseProps) {
  return (
    <Styles.Wrapper>
      <BreadCrumb paths={[{ label: 'Blog', link: '/blog' }, ...pathBreadCrumb]} />
      <Styles.HeaderSection>
        <Header selectCategoryBlog={selectCategoryBlog} handleSearch={handleSearch} />
      </Styles.HeaderSection>
      {children}
    </Styles.Wrapper>
  )
}

export function regexInContent(content: string): any {
  try {
    const regex = /<figure\b[^>]*>[\s\S]*?<\/figure>|Imagem:\s*[\w\s]+|Foto:\s*[\w\s]+/gi
    const cleanContent = content.replace(regex, '')
    return cleanContent
  } catch {
    return content
  }
}
