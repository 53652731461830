import RadioInput from 'components/RadioInput'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import Section from 'pages/CannectAssistant/components/SectionsWrapper/Section'
import * as NeedHelpStyle from '../../NeedHelp/styles'

export default function ThcForm() {
  const { canContainCbd, setCanContainCbd, setIsChosenQuestionsFinished } = useCannectAssistant()

  function handleLastQuestionAnswer(newValue: boolean) {
    setCanContainCbd(newValue)
    setIsChosenQuestionsFinished(true)
  }

  return (
    <Section>
      <h2>Pode conter CBD?</h2>
      <NeedHelpStyle.InputsWrapper>
        <RadioInput
          name="canContainCbd-yes"
          id="canContainCbd-yes"
          label="Sim"
          checked={canContainCbd === true}
          onChange={() => handleLastQuestionAnswer(true)}
        />
        <RadioInput
          name="canContainCbd-no"
          id="canContainCbd-no"
          label="Não"
          checked={canContainCbd === false}
          onChange={() => handleLastQuestionAnswer(false)}
        />
      </NeedHelpStyle.InputsWrapper>
    </Section>
  )
}
