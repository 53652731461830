/* eslint-disable jsx-a11y/label-has-associated-control */
import Button from 'components/Button'
import { Modal } from 'components/Modal'
import { Input } from 'design-cannect'
import { Dispatch, FormEvent, SetStateAction, useState } from 'react'
import api from 'services/api'
import { DialogueReason, FetchRecipeResponse } from '..'
import * as Style from './styles'

type PasswordModalProps = {
  isOpen: boolean
  onRecipeFetch: (dialogueReason: DialogueReason, fetchResult?: FetchRecipeResponse) => void
  onRequestClose: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  dialogueReason: DialogueReason
  token: string
}

export default function PasswordModal({
  isOpen,
  onRecipeFetch,
  onRequestClose,
  setLoading,
  dialogueReason,
  token
}: PasswordModalProps) {
  const [password, setPassword] = useState('')
  const [showIsPasswordIncorrect, setShowIsPasswordIncorrect] = useState(false)

  async function fetchRecipe(event: FormEvent) {
    event.preventDefault()
    setLoading(true)
    try {
      const response = await api.get<FetchRecipeResponse>(`recipe/download-pdf/${token}`, {
        params: {
          pin: password
        }
      })
      onRecipeFetch(dialogueReason, response.data)
    } catch (err: any) {
      if (err?.response?.data?.message === 'Senha incorreta') {
        setShowIsPasswordIncorrect(true)
        return
      }
      onRecipeFetch(dialogueReason, undefined)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpened={isOpen} onRequestClose={onRequestClose}>
      <Style.PasswordModalContainer onSubmit={event => fetchRecipe(event)}>
        <h1>Baixar PDF</h1>
        <label htmlFor="password-input">
          <b>Insira os 4 últimos dígitos do seu telefone.</b> <br />
          Este processo é necessário para segurança dos <br />
          seus dados e identificação correta do titular da receita.
        </label>
        {showIsPasswordIncorrect && <span>Senha incorreta</span>}
        <Input
          id="password-input"
          placeholder="Senha"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        <Button type="submit">Confirmar</Button>
        <span className="link_whats">
          Não conseguiu acesso? <a href="https://whts.co/Cannect">Fale com um atendente.</a>
        </span>
      </Style.PasswordModalContainer>
    </Modal>
  )
}
