import styled from 'styled-components'
import { theme } from 'design-cannect'

export const PostContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    text-decoration: none;
    color: ${theme.colors.tertiary};
  }
`

export const ContentWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: ${props => (props.inLine ? '10px 0' : '')};
  margin-top: 1.5rem;

  div {
    font-weight: 300;
  }

  h1 {
    font-size: 1.3rem;
    color: ${theme.colors.tertiary};
    :hover {
      color: ${props => (props.inLine ? theme.colors.primary : '')};
    }
  }

  p {
    font-weight: 300;
  }

  max-width: ${props => (props.inLine ? '100%' : '307px')};

  img {
    width: 100%;
    max-height: 307px;
    border-radius: 1rem;
  }
`
