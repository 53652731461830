import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const ItemsTableWrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
`

export const ItemsTableContainer = styled.table`
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;

  width: 100%;

  thead {
    display: flex;
    margin-bottom: 0.5rem;
    width: 100%;

    tr > th {
      font-weight: 300;
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }

  tr {
    width: 100%;

    th,
    td {
      width: 100%;
    }
  }
`

export const ProductImageWrapper = styled.div`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;

  margin-right: 0.5rem;

  img {
    max-width: 2.5rem;
    max-height: 2.5rem;
    aspect-ratio: auto;
  }
`

export const FirstCell = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`

export const SecondCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 50px;
`

export const ThirdCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 100px;

  @media ${MOBILE_WIDTH} {
    min-width: 70px;
  }
`
