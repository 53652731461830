/* eslint-disable no-nested-ternary */
import { theme } from 'design-cannect'
import styled, { keyframes } from 'styled-components'

import { StylesConfig } from 'react-select'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const FlexContainer = styled.div<{ isMulti: boolean }>`
  display: flex;
  align-items: ${({ isMulti }) => (isMulti ? 'flex-start' : 'center')};
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const Spinner = styled.div<{ isMulti?: boolean }>`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation: ${spin} 0.6s linear infinite;
  margin-top: ${({ isMulti }) => (isMulti ? '8px' : '0')};
`

export const Container = styled.div<{ maxWidth?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  min-height: 85px;

  * {
    font-family: 'Lato', sans-serif;
    color: #777777;
  }
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

export const EndAdornmentWrapper = styled.div`
  position: absolute;
  right: 8px;
  pointer-events: none;
  color: rgba(100, 100, 100, 0.55);
`

export const Label = styled.label`
  font-size: 14px;
  color: '#777777';
  margin-left: 10px;
`

export const ErrorMessage = styled.span`
  color: red !important;
  font-size: 12px;
  margin-top: -4px;
  margin-left: 10px;
`
export const HelperText = styled(ErrorMessage)`
  color: rgba(100, 100, 100, 0.55);
`

export const Input = styled.input<{
  hasEndAdornment?: boolean
  disabled?: boolean
  outlined?: boolean
}>`
  width: 100%;
  height: 45px;
  border-radius: 8px;
  padding-left: 14px;
  padding-right: ${({ hasEndAdornment }) => (hasEndAdornment ? '30px' : '0')};
  color: ${({ disabled }) => (disabled ? '#909090' : '#3d3d3d')};
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border: ${({ outlined }) => (outlined ? '1px solid #DDDDDD' : 'none')};
  background-color: ${({ outlined, disabled }) => (disabled ? '#DDDDDD' : outlined ? '#fff' : '#f3f3f3')};
  font-size: 14px;
`

export const selectStyles: StylesConfig<any> = {
  control: (provided: any, state) => {
    let outlinedStyle = {}
    if (state.selectProps.classNamePrefix === 'outlined') {
      outlinedStyle = {
        border: '1px solid #DDDDDD',
        backgroundColor: state.isDisabled ? '#DDDDDD' : '#fff'
      }
    }

    return {
      ...provided,
      width: '100%',
      height: '45px',
      maxHeight: '45px',
      backgroundColor: state.isDisabled ? '#DDDDDD' : '#f3f3f3',
      borderRadius: '8px',
      transition: 'all 0.2s ease-in-out',
      border: 'none',

      ...outlinedStyle
    }
  },
  menuList: (styles: any) => ({
    ...styles,
    padding: '6px'
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: 100
  }),

  valueContainer: (styles: any) => ({
    ...styles,

    overflowY: 'auto',
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease-in-out',

    ':hover': {
      ...styles[':hover'],
      maxHeight: 'none',
      borderRadius: '4px'
    }
  }),

  option: (styles: any, { isSelected }) => {
    return {
      ...styles,
      cursor: 'pointer',
      color: theme.colors.darkGrey,
      borderRadius: '4px',
      backgroundColor: isSelected ? '#C4C4C4' : 'transparent',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#f3f3f3',
        color: '#3d3d3d',
        fontWeight: 500
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#C4C4C4'
      }
    }
  },
  input: (styles: any) => ({
    ...styles,
    background: 'transparent',
    width: '100%',
    order: '1',
    color: '#3d3d3d',
    fontWeight: 500
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: 'rgba(100, 100, 100, 0.55)',
    height: 'auto'
  }),
  singleValue: (styles: any, { isDisabled }) => ({
    ...styles,
    background: ' transparent',
    color: isDisabled ? '#5c5c5c' : '#3d3d3d',
    fontWeight: 600
  }),

  indicatorsContainer: (styles: any) => ({
    ...styles,
    alignItems: 'center'
  })
}
