import { IconeCasa } from 'assets/svg'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'
import AddressCard from '../ProfessionalAddresses/components/AddressCard'
import { Address, FetchAddressesResponse } from '../ProfessionalAddresses/types'
import NewAddressButton from './components/NewAddressButton'
import * as Styles from './styles'

interface PatientAddressesProps {
  typeOfAddress: 'billing' | 'delivery'
}

export default function PatientAddresses({ typeOfAddress }: PatientAddressesProps) {
  const [addresses, setAddresses] = useState<Address[]>([])
  const [loading, setLoading] = useState(false)

  const fetchAddresses = async () => {
    setLoading(true)
    try {
      const {
        data: { address, success }
      } = await api.get<FetchAddressesResponse>('/my-account/address')

      if (!success) {
        toast.error('Houve um erro ao buscar os endereços cadastrados. Tente novamente mais tarde.')
        return
      }
      const newAddresses = []
      if (typeOfAddress === 'delivery') {
        newAddresses.push(...address.filter((address: Address) => address.isDelivery))
      } else {
        newAddresses.push(...address.filter((address: Address) => address.isBilling))
      }
      setAddresses(newAddresses)
    } catch (err) {
      toast.error('Houve um erro ao buscar os endereços cadastrados. Tente novamente mais tarde.')
      console.error(err)
    }
    setLoading(false)
  }

  const onSelectedAsMain = useCallback(() => {
    fetchAddresses()
  }, [])

  useEffect(() => {
    fetchAddresses()
  }, [typeOfAddress])

  return (
    <Styles.PatientAddressesContainer>
      <Loading loading={loading} />
      <PageTitle>
        <IconeCasa style={{ width: '4rem', height: '4rem', minWidth: '4rem', minHeight: '4rem' }} />
        Endereços de {typeOfAddress === 'delivery' ? 'entrega' : 'cobrança'}
      </PageTitle>

      <Styles.AllAddressesWrapper>
        {addresses.map(address => (
          <AddressCard address={address} onSelectedAsMain={onSelectedAsMain} usage="patient" />
        ))}
        <NewAddressButton typeOfAddress={typeOfAddress} />
      </Styles.AllAddressesWrapper>
    </Styles.PatientAddressesContainer>
  )
}
