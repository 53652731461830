import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Container = styled.div`
  margin: 1rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
  }

  span {
    h2 {
      margin-left: auto;
    }
  }
`

export const ContainerNews = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  flex-wrap: wrap;
  gap: 4px;
  padding: 0 1.5rem;
`

export const Heading = styled.h3`
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-family: 'Spartan', sans-serif;
  font-size: 30px;
  margin: 16px 0;
  color: ${temporaryTheme.colors.dark};
  padding: 0 3rem;
`
