import { SVGProps } from 'react'

interface FlagIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function FlagIcon({ isChecked, ...rest }: FlagIconProps) {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M4.97412 24.6961V6.11719"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97412 19.3894C12.0518 14.0812 17.36 24.6977 24.4377 19.3894V6.11879C17.36 11.427 12.0518 0.810535 4.97412 6.11879"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
