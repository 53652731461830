import Texts from 'components/Text'
import { IStrapiPostContent } from 'dtos/Posts'
import { FaCircle } from 'react-icons/fa'

import { insertUrlImage } from 'utils/replace'
import * as Styles from './styles'
import { formatDate } from '@cannect/utils/date'

interface IPostContent {
  postContent?: IStrapiPostContent[]
}

function PostContent({ postContent }: IPostContent) {
  return (
    <Styles.Container>
      {postContent?.map(content => {
        return (
          <>
            <Texts color="tertiary" type="lg" bold>
              {content.attributes.categories?.data[0].attributes.name}
            </Texts>

            <Styles.Heading> {content.attributes.title}</Styles.Heading>

            <div className="author-date">
              <img src={content.attributes.author.data.attributes?.thumb} alt="" />
              <span>
                <Texts type="md" color="black" bold>
                  Por {content.attributes.author.data.attributes.username}
                </Texts>
              </span>
              <span>
                <Texts type="md" color="black">
                  <FaCircle size={8} />
                  {formatDate(content.attributes.createdAt, "d 'de' MMMM")}
                </Texts>
              </span>
            </div>
            <Styles.ContainerPost dangerouslySetInnerHTML={{ __html: insertUrlImage(content.attributes.content) }} />
          </>
        )
      })}
    </Styles.Container>
  )
}

export default PostContent
