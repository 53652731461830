export const desktopContainerVariants = {
  initial: { flex: '1 0 242px' },
  hover: {
    flex: '2 0 502px',
    transition: { type: 'spring', stiffness: 300, damping: 30, delay: 0.1 }
  },
  animate: { transition: { duration: 0.1, ease: 'easeInOut' } },
  open: {
    flex: '2 0 502px',
    transition: { type: 'spring', stiffness: 300, damping: 30, delay: 0.1 }
  },
  closed: {
    flex: '1 0 242px',
    transition: { duration: 0.1, ease: 'easeInOut' }
  }
}

export const mobileContainerVariants = {
  initial: { flex: '1 0 242px' },
  hover: {
    flex: '1 0 400px',
    transition: { type: 'spring', stiffness: 300, damping: 30 }
  },
  animate: { transition: { duration: 0.1, ease: 'easeInOut' } },
  open: {
    flex: '1 0 400px',
    transition: { type: 'spring', stiffness: 300, damping: 30 }
  },
  closed: {
    flex: '1 0 242px',
    transition: { duration: 0.1, ease: 'easeInOut' }
  }
}

export const blurVariants = {
  initial: { opacity: 0 },
  hover: { opacity: 1, transition: { type: 'spring', stiffness: 300, damping: 30, delay: 0.1 } },
  animate: { opacity: 0, transition: { duration: 0.1, ease: 'easeInOut' } },
  open: { opacity: 1, transition: { type: 'spring', stiffness: 300, damping: 30, delay: 0.1 } },
  closed: { opacity: 0, transition: { duration: 0.1, ease: 'easeInOut' } }
}

export const contentVariants = {
  initial: { opacity: 0, y: 50 },
  hover: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, ease: 'easeInOut', delay: 0.2 }
  },
  animate: {
    opacity: 0,
    y: 50,
    transition: { duration: 0.2, ease: 'easeInOut' }
  },
  open: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, ease: 'easeInOut', delay: 0.2 }
  },
  closed: {
    opacity: 0,
    y: 50,
    transition: { duration: 0.2, ease: 'easeInOut' }
  }
}

export const titleVariants = {
  initial: { opacity: 1, y: 0 },
  hover: {
    opacity: 0,
    y: -50,
    transition: { duration: 0.4, ease: 'linear' }
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, ease: 'easeInOut', delay: 0.1 }
  },
  open: {
    opacity: 0,
    y: -50,
    transition: { duration: 0.4, ease: 'linear' }
  },
  closed: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, ease: 'easeInOut', delay: 0.1 }
  }
}
