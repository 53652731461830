/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactNode } from 'react'
import { tv } from 'tailwind-variants'
import TableSkeletonLoading from './components/TableSkeletonLoading'
import TableNoResultsFound from './components/TableNoResultsFound'
import Pagination from 'components/Pagination'

interface ColDefBase {
  id: number | string
  label: string
  className?: string
}

interface ColDefField extends ColDefBase {
  field: string
}

export interface CellRendererProps<T> {
  row: RowData<T>
}
interface ColDefCellRenderer<T> extends ColDefBase {
  cellRenderer: ({ row }: CellRendererProps<T>) => ReactNode
}

export type ColDef<T> = ColDefField | ColDefCellRenderer<T>

export type RowData<T> = T & { id: number | string; text?: string; className?: string }

export interface TableProps<T> {
  colDefs: ColDef<T>[]
  data?: RowData<T>[]
  isLoading?: boolean
  pagination?: {
    page: number
    onPageChange: (page: number) => void
    totalItems: number
    // Defaults to 10
    itemsPerPage?: number
  }
}

export const table = tv({
  slots: {
    header: 'px-4 text-left text-xs font-semibold text-gray-600 xl:px-2',
    row: 'h-12 min-h-12 bg-gray-50 px-4 text-left xl:px-2'
  }
})

const Table = <T,>({ colDefs, data, isLoading = false, pagination }: TableProps<T>) => {
  const { header, row: rowClassName } = table()

  const renderTableRows = () => {
    if (isLoading) return <TableSkeletonLoading colSpan={colDefs.length} />
    if (!data?.length) return <TableNoResultsFound colSpan={colDefs.length} />

    return data.map((row) => (
      <tr key={row.id}>
        {colDefs.map((col, index) => {
          let borderRadius = ''
          if (index === 0) {
            borderRadius = 'rounded-l-lg pl-4'
          } else if (index === colDefs.length - 1) {
            borderRadius = 'rounded-r-lg pr-4'
          }

          const firstColClassName = index === 0 ? '!pl-4' : ''
          const lastColClassName = index === colDefs.length - 1 ? '!pr-4' : ''

          if ('field' in col) {
            return (
              <td
                key={col.id}
                className={` ${rowClassName()} ${borderRadius} ${col.className} ${row.className} ${firstColClassName} ${lastColClassName} `}>
                {/* @ts-ignore */}
                {row[col.field] as keyof T}
              </td>
            )
          }

          return (
            <td
              key={col.id}
              className={` ${rowClassName()} ${borderRadius} ${col.className} ${row.className} ${firstColClassName} ${lastColClassName} `}>
              {col.cellRenderer?.({ row })}
            </td>
          )
        })}
      </tr>
    ))
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full min-w-full overflow-auto">
        <table className="w-full min-w-full border-spacing-y-4">
          <thead>
            <tr>
              {colDefs.map((col) => {
                const firstColClassName = colDefs[0].id === col.id ? '!pl-4' : ''
                const lastColClassName = colDefs[colDefs.length - 1].id === col.id ? '!pr-4' : ''

                return (
                  <th key={col.id} className={`${header()} ${col.className} ${firstColClassName} ${lastColClassName}`}>
                    {col.label}
                  </th>
                )
              })}
            </tr>
          </thead>

          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>

      {pagination && !isLoading && pagination.totalItems > 1 && (
        <Pagination
          forcePage={(pagination.page ?? 1) - 1}
          onPageChange={({ selected }: any) => pagination.onPageChange(selected + 1)}
          pageCount={Math.ceil(pagination.totalItems / (pagination.itemsPerPage ?? 10))}
        />
      )}
    </div>
  )
}

export default Table
