import NeedHelp from '../NeedHelp'
import SendApproval from './UploadApproval'

export default function AnvisaConfirmation() {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <SendApproval />
      <NeedHelp />
    </>
  )
}
