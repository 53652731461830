import { ReactNode, useEffect, useRef, useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import { toast } from 'react-toastify'
import api from 'services/api'
import cannectLoading from 'assets/gifs/cannect-loading.gif'
import { useHistory } from 'react-router'
import DebouncedSearchInput from './components/DebouncedSearchInput'
import { getValueFromField } from './helpers'
import { useTokenApi } from '../../hooks/useTokenApi'
import * as Styles from './styles'

interface FetchSelectProps {
  label: string | ReactNode
  id: string
  endpoint: string
  value?: FetchSelectOption
  onChange: (newValue: FetchSelectOption) => void
  width?: string
  maxWidth?: string
  placeholder?: string
  errorMessage?: string
  disabled?: boolean
  /** Default `searchParam` is `search` */
  searchParam?: string
  /** Default `field` is an empty string, which means the component will receive an array straight away from the API's response */
  field?: string
  /** Default `countField` is an empty string, which means the component consider the requests as non-paginated */
  countField?: string
  /** Default `labelsField` is `title`, which means the component will try to get the labels of the options from an attribute called "title" */
  labelsField?: string
  /** Default `valuesField` is `id`, which means the component will try to get the values of the options from an attribute called "id" */
  valuesField?: string
  /** Default `pageSize` is `30`. Ignore this prop if the response is not paginated */
  pageSize?: number
  httpMethod?: 'get' | 'post' | 'put' | 'delete'
}

export interface FetchSelectOption {
  label: string
  value: string
}

export default function FetchSelect({
  id,
  label,
  endpoint,
  value: selectedOption,
  onChange,
  width,
  maxWidth,
  errorMessage,
  disabled = false,
  searchParam = 'search',
  field = '',
  countField = '',
  labelsField = 'title',
  valuesField = 'id',
  httpMethod = 'get',
  pageSize = 30,
  placeholder = ''
}: FetchSelectProps) {
  const history = useHistory()
  const { deleteTokenApi } = useTokenApi()
  const fakeFetchSelectRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState<FetchSelectOption[]>([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState<number>()

  useEffect(() => {
    if (isOpen) {
      setPage(0)
      fetchOptions(0, search)
    }
  }, [search])

  useEffect(() => {
    if (isOpen && search.length === 0) {
      setPage(0)
      fetchOptions(0, '')
    }
  }, [isOpen])

  const fetchOptions = async (page: number, search: string) => {
    setLoading(true)
    try {
      let url = `${endpoint}?${searchParam}=${search}`
      if (countField) {
        url += `&page=${page}&size=${pageSize}`
      }
      const response = await api[httpMethod](url)
      const optionsList = getValueFromField(field, response.data)
      const count = getValueFromField(countField, response.data)
      if (count) {
        setTotalPages(Math.floor((count as number) / pageSize))
      } else {
        setTotalPages(undefined)
      }
      // Sort optionsList by createdAt field in descending order
      const sortedOptionsList = optionsList.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      setOptions(sortedOptionsList.map(row => ({ label: String(row[labelsField]), value: String(row[valuesField]) })))
    } catch (err: any) {
      console.error(err)
      if (err.response?.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.')
        deleteTokenApi()
        history.push('/login')
        return
      }
      toast.error('Houve um erro ao buscar as opções selecionáveis. Tente novamente mais tarde.')
    }
    setLoading(false)
  }

  const fetchNextPage = async (page: number, search: string) => {
    try {
      const response = await api.get(`${endpoint}?${searchParam}=${search}&page=${page}&size=${pageSize}`)
      const optionsList = getValueFromField(field, response.data)
      // Sort optionsList by createdAt field in descending order
      const sortedOptionsList = optionsList.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      setOptions(state => [
        ...state,
        ...sortedOptionsList.map(row => ({ label: String(row[labelsField]), value: String(row[valuesField]) }))
      ])
    } catch (err) {
      console.error(err)
      toast.error('Houve um erro ao buscar as opções selecionáveis. Tente novamente mais tarde.')
    }
  }

  const onNextPage = () => {
    if (page < (totalPages ?? 0)) {
      setPage(page + 1)
      fetchNextPage(page + 1, search)
    }
  }

  const onScroll = () => {
    if (!totalPages) return

    const searchSelectOptionsContainer = document.getElementById('fake-fetch-select-options-container')
    if (!searchSelectOptionsContainer) return

    const CONTAINER_HEIGHT = 250
    const scrollReason =
      (searchSelectOptionsContainer.scrollTop + CONTAINER_HEIGHT) / searchSelectOptionsContainer.scrollHeight

    if (scrollReason >= 0.9) {
      onNextPage()
    }
  }

  const onOptionClick = (newValue: FetchSelectOption) => {
    onChange(newValue)
    setIsOpen(false)
  }

  return (
    <Styles.FetchSelectContainer style={{ width: width ?? '100%', maxWidth: maxWidth ?? '100%' }}>
      {isOpen && <Styles.FetchSelectBackground onClick={() => setIsOpen(false)} />}
      <label htmlFor={id}>{label}</label>

      <DebouncedSearchInput
        onInputChange={newValue => setSearch(newValue)}
        hasError={errorMessage !== undefined && errorMessage.length > 0}
        onClick={() => setIsOpen(true)}
        isOpen={isOpen}
        selectedOption={selectedOption}
        disabled={disabled}
        ref={fakeFetchSelectRef}
        placeholder={placeholder}
      />
      {errorMessage !== undefined && errorMessage.length > 0 && (
        <Styles.InputErrorMessage>{errorMessage}</Styles.InputErrorMessage>
      )}

      {isOpen && (
        <Styles.FakeFetchSelectOptionsContainer
          top={fakeFetchSelectRef.current?.getBoundingClientRect().top ?? 0}
          left={fakeFetchSelectRef.current?.getBoundingClientRect().left ?? 0}
          maxWidth={fakeFetchSelectRef.current?.getBoundingClientRect().width ?? 0}
          onScroll={onScroll}
          id="fake-fetch-select-options-container"
        >
          {!loading ? (
            options.map(option => {
              const isOptionSelected = selectedOption?.value === option.value
              return (
                <Styles.FakeFetchSelectOption
                  isFetchSelected={isOptionSelected}
                  key={option.value}
                  onClick={() => onOptionClick(option)}
                  type="button"
                >
                  <span>{option.label}</span>
                  {isOptionSelected && <FiCheck />}
                </Styles.FakeFetchSelectOption>
              )
            })
          ) : (
            <img
              src={cannectLoading}
              alt="Carregando..."
              width="150px"
              height="150px"
              style={{ alignSelf: 'center' }}
            />
          )}
        </Styles.FakeFetchSelectOptionsContainer>
      )}
    </Styles.FetchSelectContainer>
  )
}
