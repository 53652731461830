import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { DESKTOP_WIDTH, MOBILE_TABLET, MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

interface PriceProps {
  withDiscount?: boolean
}

export const Wrapper = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #858585;
  padding: ${temporaryTheme.spacing.space2};
  border-radius: 15px;
  gap: 8px;
  position: relative;
  max-width: 300px;
  min-height: 400px;

  @media (${TABLET_WIDTH}) {
    max-width: 400px;
    min-height: 450px;
  }

  @media (${MOBILE_WIDTH}) {
    max-width: 300px;
    min-height: 350px;
  }

  @media (${DESKTOP_WIDTH}) {
    flex-direction: row;
    gap: 16px;
    max-width: none;
    min-height: auto;
    button {
      width: auto;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  @media (${MOBILE_TABLET}) {
    padding: 15px 10px;
    padding-top: 20px;
    justify-content: space-between;
    padding-bottom: 5px;
    gap: 6px;
  }

  @media (${DESKTOP_WIDTH}) {
    width: auto;
  }
`

export const ImageBox = styled.div`
  width: 100%;
  max-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (${MOBILE_TABLET}) {
    width: 45%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 180px;
  @media (${MOBILE_TABLET}) {
    width: 80%;
    max-height: 120px;
  }
`

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  gap: 16px;
  width: 100%;
  flex: 1;

  @media (${MOBILE_TABLET}) {
    width: 55%;
    text-align: left;
  }
`

export const Description = styled.p`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #3d3d3d;
  cursor: pointer;

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }

  @media screen and ${MOBILE_WIDTH} {
    font-size: 12px;
  }

  @media screen and ${TABLET_WIDTH} {
    font-size: 14px;
  }
`

export const ShippingMessage = styled.span`
  font-family: 'Spartan', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #858585;
  @media (${MOBILE_WIDTH}) {
    font-size: 12px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: ${temporaryTheme.spacing.space2};
  flex: 1;

  @media (${MOBILE_TABLET}) {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media (${DESKTOP_WIDTH}) {
    justify-content: flex-end;
  }
`

export const ActionBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;

  @media (${MOBILE_TABLET}) {
    width: 100%;
  }
`

export const FavoriteBox = styled.div`
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 28px;
    height: 28px;
  }

  &:hover {
    cursor: pointer;
  }

  @media (${MOBILE_TABLET}) {
    position: absolute;
    top: 5px;
    left: 15px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`

export const ButtonProduct = styled.button`
  padding: 7px;
  border-radius: 5px;
  background: ${temporaryTheme.colors.secondary};
  min-width: 205px;
  outline: none;
  border: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${temporaryTheme.spacing.space2};
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${temporaryTheme.colors.white};
  min-height: 45px;
  svg {
    width: 14px;
    height: 14px;
  }

  @media (${MOBILE_TABLET}) {
    width: calc(100% - ${temporaryTheme.spacing.space4} * 2); /* full width minus padding */
  }
`

export const PriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space3};
`

export const Price = styled.span<PriceProps>`
  ${({ withDiscount }) => css`
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    font-size: ${withDiscount ? '14px' : '16px'};
    color: ${withDiscount ? temporaryTheme.colors.red : temporaryTheme.colors.darkGrey};
    text-decoration: ${withDiscount && 'line-through'};

    span {
      font-family: 'Spartan', sans-serif;
      font-weight: 400;
      font-size: 16px;
    }

    @media screen and ${MOBILE_WIDTH} {
      font-size: 12px;
    }

    @media screen and ${TABLET_WIDTH}) {
      font-size: 14px;
    }
  `}
`
