import { Button } from 'design-cannect'
import { useHistory } from 'react-router'
import { Container } from './styles'

interface AccountHeaderProps {
  title: string
}
function AccountHeader({ title }: AccountHeaderProps) {
  const detalhes = title === 'Detalhes de conta'
  const receitas = title === 'Receitas'

  const history = useHistory()

  function handleRedirect(path: string) {
    history.push(path)
  }
  return (
    <Container>
      <div className="links">
        <Button secondary={detalhes} onClick={() => handleRedirect('/conta')}>
          Detalhes de conta
        </Button>
        <Button secondary={receitas} onClick={() => handleRedirect('/minhas-receitas')}>
          Receitas
        </Button>
      </div>
    </Container>
  )
}

export default AccountHeader
