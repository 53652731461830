import api from '@cannect/services/api'
import { TResponseStandard } from '@cannect/services/types'
import {
  TCheckIfAlreadySubscribedParams,
  TConsultCFMPayload,
  TConsultCFMResponse,
  TUserAlreadySubscribed,
  TWebinarItem,
  TWebinarSubscriptionPayload
} from './types'

export const getWebinarDetail = async (title: string) => {
  const titleToFind = title.toLowerCase()
  return api.get<TResponseStandard<TWebinarItem, 'webinar'>>(`/webinar/title/${titleToFind}`).then(({ data }) => data)
}

export const subscribe = async (data: TWebinarSubscriptionPayload) => {
  return api
    .post(`/webinar/subscription`, {
      ...data
    })
    .then(({ data }) => data)
}

export const findAlreadySubscribedUser = async (params: TCheckIfAlreadySubscribedParams) => {
  return api
    .get<
      TResponseStandard<TUserAlreadySubscribed, 'usersWebinar'>
    >(`/user-webinar/${params.email}/${params.webinar_id}`)
    .then(({ data }) => data)
}

export const validateCRM = async (payload: TConsultCFMPayload) => {
  return api
    .get<
      TResponseStandard<TConsultCFMResponse, 'prescriber'>
    >(`/prescribers/council/${payload.nr_council}/uf/${payload.uf_council}`)
    .then(({ data }) => {
      if (data?.prescriber?.name && data?.prescriber?.active) {
        const fullName = data.prescriber.name.split(' ')
        const firstName = fullName?.slice(0, 1)?.join(' ') || ''
        const lastName = fullName?.slice(1)?.join(' ') || ''

        return {
          ...data,
          prescriber: {
            ...data.prescriber,
            first_name: firstName,
            last_name: lastName
          }
        }
      }
      return data
    })
}
