import styled from 'styled-components'
import { theme } from 'design-cannect'

interface CheckboxProps {
  checked?: boolean
}

export const TitleWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;

  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'null')};

  padding-top: 36px;
  padding-bottom: 36px;
  gap: 5px;

  .href {
    text-decoration: underline;
  }
`

export const UploadContainer = styled.div<any>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  margin-bottom: 64px;

  gap: 100px;
`

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 15px;
`

export const RefButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  color: ${theme.colors.secondary};
  font-weight: bold;
  font-size: 15px;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  gap: 15px;

  width: 100%;
`

export const SubmitButton = styled.button`
  display: flex;

  background-color: ${theme.colors.tertiary};
  color: ${theme.colors.white};

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: ${theme.colors.tertiary};

  margin-top: 50px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`

export const SubmitAfterButton = styled.button`
  display: flex;

  background-color: #DADAC2;
  color: ${theme.colors.tertiary};

  border-radius: 0.5rem;
  border: 1px solid;
  border-bottom-width: 5px;
  border-color: #DADAC2;

  margin-top: 50px;
  padding: 1rem;

  font-weight: bold;
  letter-spacing: 0.5px;

  cursor: pointer;

  height: 48px
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.03);
  }
`

export const CheckboxContainer = styled.div<any>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  align-items: center;

  gap: 20px;
  margin-bottom: 75px;
`

export const Checkbox = styled.div<CheckboxProps>`
  height: 30px;
  width: 30px;

  background: ${({ checked }) => (checked ? '#C5581B' : 'white')};
  box-shadow: 0 0 0 2.5px ${({ checked }) => (checked ? '#C5581B' : '#115850')};

  border: 6px solid white;
  border-radius: 50%;

  cursor: pointer;
`
