import { useQuery } from '@tanstack/react-query'

import * as requests from './requests'
import { TStrapiParams } from './types'

export const KEY_GET_STRAPI_POSTS = 'getStrapiPosts'
export const KEY_GET_STRAPI_BRANDS_LIST = 'getStrapiBrandsList'

export const useGetStrapiPosts = (params: TStrapiParams) =>
  useQuery({
    queryKey: [KEY_GET_STRAPI_POSTS, { ...params }],
    queryFn: () => requests.getStrapiPostsPaginated(params),
    staleTime: Infinity,
    meta: {
      errorMessage: 'Erro ao buscar os posts.'
    }
  })

export const useGetCannectPosts = (params: TStrapiParams) =>
  useQuery({
    queryKey: [KEY_GET_STRAPI_POSTS, { ...params }],
    queryFn: () => requests.getCannectPostsPaginated(params),
    staleTime: Infinity,
    meta: {
      errorMessage: 'Erro ao buscar os posts.'
    }
  })

export const useGetStrapiBrands = (params: TStrapiParams) =>
  useQuery({
    queryKey: [KEY_GET_STRAPI_BRANDS_LIST, { ...params }],
    queryFn: () => requests.getStrapiBrandsList(params),
    staleTime: Infinity,
    meta: {
      errorMessage: 'Erro ao buscar as marcas.'
    }
  })
