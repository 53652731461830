import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonStyle } from '.'
import { getBackgroundColor, getBorderColor, getTextColor } from './helpers'

interface LinkAsButtonContainerProps {
  background: string
  buttonStyle: ButtonStyle
  disabled: boolean
}
export const LinkAsButtonContainer = styled(Link)<LinkAsButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: ${({ background, buttonStyle }) => `1px solid ${getBorderColor(buttonStyle, background)}`};
  background: ${({ background, buttonStyle }) => getBackgroundColor(buttonStyle, background)};
  color: ${({ background, buttonStyle }) => getTextColor(buttonStyle, background)};

  height: 45px;
  min-height: 45px;
  padding: ${({ buttonStyle }) => (buttonStyle === 'ghost' ? 'none' : '0 20.5px')};
  border-radius: 8px;

  width: fit-content;
  text-decoration: none;
  text-align: center;

  transition: transform 0.3s;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    transform: ${({ disabled }) => (disabled ? 'none' : 'scale(1.03)')};
  }
`
