import api from '@cannect/services/api'
import { TResponseStandard } from '@cannect/services/types'
import { THighlitedPrescriberItem } from './types'
import { TDefaultOptionResponse } from '@cannect/services/common'

export const getHighlitedPrescribers = async () => {
  return api
    .get<TResponseStandard<TDefaultOptionResponse[], 'prescribers'>>(`/scheduling/filters/prescribers`)
    .then(({ data }) => {
      const prescribers = data.prescribers.slice(0, 4)
      const prescribersDetails = prescribers.map((prescriber) => {
        return api.get<TResponseStandard<THighlitedPrescriberItem, 'prescriber'>>(
          `/scheduling/prescriber/${prescriber.id}`
        )
      })

      return Promise.all(prescribersDetails).then((prescribersDetails) => {
        return prescribersDetails.map((prescriber) => {
          return {
            id: prescriber.data?.prescriber?.id,
            professional_name: prescriber.data?.prescriber?.professional_name,
            online_appointment_value: prescriber?.data.prescriber?.online_appointment_value,
            appointment_duration: prescriber.data?.prescriber?.appointment_duration,
            prescriber_expertises: prescriber.data?.prescriber?.prescriber_expertises,
            photo: prescriber.data?.prescriber?.photo
          }
        })
      })
    })
}
