import styled, { css } from 'styled-components'
import { theme } from 'design-cannect'
import { temporaryTheme } from 'styles/theme'

export type InputProps = {
  width?: string
  icon?: any
}

// eslint-disable-next-line prettier/prettier
export const WrapperInput = styled('div')<InputProps>`
  ${({ width }) => css`
    width: ${width || 'auto'};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    position: relative;
    .wrapper-icon {
      font-size: ${theme.spacing.space4};
      position: absolute;
      transition: transform 0.3s;
      right: ${theme.spacing.space3};
      top: 12px;
      padding-left: ${theme.spacing.space3};
      color: ${theme.colors.tertiary};
    }
  `}
`

export const WrapperEye = styled('div')`
  position: absolute;
  right: ${theme.spacing.space3};
  top: ${theme.spacing.space4};
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    color: ${theme.colors.tertiary};
  }
`

export const ContainerInput = styled.input<any>`
  min-height: 48px;
  border-radius: ${theme.spacing.space2};
  padding: ${({ icon }) => `2px ${icon ? theme.spacing.space7 : theme.spacing.space3} 0 ${theme.spacing.space3}`};
  border: 2px solid #948b72;

  color: ${temporaryTheme.colors.darkGrey};

  font-weight: 300;
  min-width: 210px;
  width: 100%;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${theme.colors.tertiary};
    transition: all 100ms ease;
  }

  &:-webkit-autofill {
    background: -internal-light-dark(black, white) !important;
  }

  ::placeholder {
    color: ${temporaryTheme.colors.darkGrey};
    font-weight: lighter;
  }
`
