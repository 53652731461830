import { OrderStatus } from '../../../MyOrders/types'

export const getGeneralStatus = (status: OrderStatus): string => {
  switch (status) {
    case 'created':
    case 'finished_items':
    case 'waiting_documentation':
    case 'waiting_anvisa':
    case 'waiting_approval':
    case 'awaiting_payment':
    case 'waiting_payment':
      return 'Pedido em análise'
    case 'delivered':
      return 'Pedido finalizado'
    default:
      return 'Pedido em processamento'
  }
}
