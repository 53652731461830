import Loading from 'components/Loading'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as Style from './styles'

export default function QrCode() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    const code = queryParams.get('code')

    if (code) {
      const message = {
        type: 'success',
        data: code
      }
      window.parent.postMessage(message, '*')
    } else {
      const message = {
        type: 'fail'
      }
      window.parent.postMessage(message, '*')
    }
  }, [])

  return (
    <Style.VidaasContainer>
      <Loading loading />
    </Style.VidaasContainer>
  )
}
