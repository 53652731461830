import React from 'react'

export default function IconeAjuda({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 36C25.2426 36 26.25 34.9926 26.25 33.75C26.25 32.5074 25.2426 31.5 24 31.5C22.7574 31.5 21.75 32.5074 21.75 33.75C21.75 34.9926 22.7574 36 24 36Z"
        fill="#3D3D3D"
      />
      <path
        d="M24 27V25.5C25.0384 25.5 26.0534 25.1921 26.9167 24.6152C27.7801 24.0383 28.453 23.2184 28.8504 22.2591C29.2477 21.2998 29.3517 20.2442 29.1491 19.2258C28.9466 18.2074 28.4465 17.2719 27.7123 16.5377C26.9781 15.8035 26.0426 15.3035 25.0242 15.1009C24.0058 14.8983 22.9502 15.0023 21.9909 15.3996C21.0316 15.797 20.2117 16.4699 19.6348 17.3333C19.0579 18.1966 18.75 19.2117 18.75 20.25"
        stroke="#3D3D3D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
