import Receipt from 'assets/receita-medica-exemplo.json'
import Loading from 'components/Loading'
import { Whitepaper } from 'pages/WhitepapersHome'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import * as Style from './styles'

interface SelectWhitepaperParams {
  id: string
}

export interface FetchSelectedWhitepaperResponse {
  whitepaper: Whitepaper
  success: boolean
}

export default function SelectWhitepaper() {
  const { id } = useParams<SelectWhitepaperParams>()
  const [loading, setLoading] = useState(true)
  const [selectedWhitepaper, setSelectedWhitepaper] = useState<Whitepaper>()

  useEffect(() => {
    async function fetchSelectedWhitepaper() {
      try {
        const response = await api.get<FetchSelectedWhitepaperResponse>(`/whitepaper/${id}`)
        setSelectedWhitepaper(response.data.whitepaper)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    fetchSelectedWhitepaper()
  }, [])

  return (
    <Style.Container>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!loading && selectedWhitepaper ? (
        <object data={selectedWhitepaper.path} width="100%" height="100%" aria-label="Prescrição" />
      ) : loading ? (
        <Loading loading={loading} />
      ) : (
        <h1>Não foi possível encontrar um Whitepaper com o ID informado</h1>
      )}
    </Style.Container>
  )
}
