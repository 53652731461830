import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const StartYouJourneySection = styled.div`
  margin: 37px 0;
  width: 100%;
  text-align: left;
`

export const CardBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space4};
  flex-wrap: wrap;
  cursor: pointer;
  overflow-x: auto;

  @media ${MOBILE_WIDTH} {
    flex-wrap: unset;
  }
`
