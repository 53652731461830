import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 450px;
  min-width: 300px;
  color: #545252;
  .constainer-image {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
  .image {
    border: 1px solid #d9d9cf;
    padding: 5px;
    border-radius: 16px;
  }
  img {
    width: 160px;
    height: auto;
  }
  .quantity {
    margin-bottom: 16px;
  }
`
export const ButtonAction = styled.button<any>`
  width: 100%;
  background: #4579ff;
  border: none;
  color: #fff;
  padding: 16px 0;
`
export const ProductSabor = styled.span<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${props => (props.isActive ? '#6CC3AE' : '#fff')};
  color: ${props => (props.isActive ? '#fff' : '#545252')} !important;
  align-items: center;
  padding: 5px 12px;
  font-family: 'Lato', sans-serif;
  border-radius: 16px;
  border: 1px solid #d9d9cf;
  min-width: 100px;
  cursor: pointer;
`
export const ContainerSabor = styled.span<any>`
  margin: 16px 0;
  p {
    margin-bottom: 8px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
`
