import { card1, card2, card4 } from 'assets/img/drCannabis'
import { cannabicastIcon, svgWhitepapers, webinarIcon } from 'assets/svg'

export const descriptionIntrodutorio = `
Se você está <strong>começando sua jornada clínica</strong> e quer
aprender mais sobre a prescrição e aplicação corretas
da Cannabis medicinal, este curso é para você. Fornecemos
informações importantes para ajudá-lo a implementar o uso
de canabinoides em sua prática clínica de forma eficaz.
`

export const CategoriesEducate = [
  {
    id: 1,
    category: 'course',
    title: 'Cursos introdutórios ',
    subTitle: '',
    icon: '',
    image: '#',
    description: `
    Se você está <strong>começando sua jornada clínica</strong> e quer
    aprender mais sobre a prescrição e aplicação corretas
    da Cannabis medicinal, este curso é para você. Fornecemos
    informações importantes para ajudá-lo a implementar o uso
    de canabinoides em sua prática clínica de forma eficaz.
    `,
    redirectLink: '/educa/cursos-introdutorios'
  },
  {
    id: 2,
    page: 1,
    limit: 4,
    category: 'dr_cannabis',
    title: 'Cursos indicados',
    subTitle: 'Dr. Cannabis',
    icon: '',
    image: '#',
    description: `<strong>Prescrever canabinoides é legal desde 2015, </strong>
    nossa vertical de educação, <a href="https://www.drcannabis.com.br/course/cannabis-medicinal-zero-psicologos?utm_source=pagina-educa&utm_medium=cta&utm_id=cannect" target="_blank" rel="noreferrer"> Dr. Cannabis,</a> oferece cursos
    práticos para que você possa desenvolver as habilidades que
     precisa para dar seu próximo passo em direção à prática
     clínica associada ao uso medicinal da cannabis.`,
    redirectLink: 'https://www.drcannabis.com.br/?utm_source=pagina-educa&utm_medium=cta&utm_id=cannect',
    externalLink: true
  },
  {
    id: 3,
    page: 1,
    limit: 4,
    category: 'webinars',
    title: 'Webinarios',
    subTitle: '',
    icon: webinarIcon,
    image: '',
    redirectLink: '/educa/webnars'
  },
  {
    id: 4,
    page: 1,
    limit: 4,
    category: 'whitepapers',
    title: 'Whitepapers',
    subTitle: '',
    icon: svgWhitepapers,
    image: '',
    redirectLink: '/whitepapers'
  },
  {
    id: 5,
    page: 1,
    limit: 4,
    category: 'podcasts',
    title: 'Podcast da Cannect - Cannabicast',
    subTitle: '',
    icon: cannabicastIcon,
    image: '',
    redirectLink: '/educa/podcasts'
  }
]

export const DrCannabisCourses = [
  {
    id: 1,
    title: 'Cannabis Medicinal do Zero: Ciclo Básico',
    description: '',
    image: card1,
    link: 'https://www.drcannabis.com.br/course/cannabis-zero?utm_source=pagina-educa&utm_medium=cta&utm_id=cannect'
  },
  {
    id: 2,
    title: 'Cannabis Medicinal do Zero: Dor',
    description: '',
    image: card2,
    link: 'https://www.drcannabis.com.br/course/cannabis-medicinal-zero-dor?utm_source=pagina-educa&utm_medium=cta&utm_id=cannect'
  },

  {
    id: 4,
    title: 'Cannabis Medicinal do Zero: Psicólogos',
    description: '',
    image: card4,
    link: 'https://www.drcannabis.com.br/course/cannabis-medicinal-zero-psicologos?utm_source=pagina-educa&utm_medium=cta&utm_id=cannect'
  }
]
