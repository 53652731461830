import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { DESKTOP_WIDTH, MAX_PAGE_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'
import bannerPartnesVideo from 'assets/video/banner-partnes.mp4'

type SectionStylesProps = {
  background?: string
  invertRow?: boolean
  lastSection?: boolean
  fullWidth?: boolean
}

export type CardImageProps = {
  image: string
}

type BoxStyleProps = {
  justify?: 'start' | 'center' | 'end'
  fullWidth?: boolean
}

const modifierStyles = {
  start: () => css`
    align-items: flex-start;
  `,
  center: () => css`
    align-items: center;
  `,
  end: () => css`
    align-items: flex-end;
  `
}

export const Wrapper = styled.main`
  width: 100%;
  background: linear-gradient(338deg, rgba(223, 255, 251, 1) 42%, rgba(249, 249, 249, 1) 84%);
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    font-family: 'Spartan', sans-serif;
  }

  @media ${MOBILE_WIDTH} {
    button {
      width: 100%;
    }
  }
`

export const Section = styled.section<SectionStylesProps>`
  ${({ invertRow, background, lastSection, fullWidth }) => css`
    display: flex;
    gap: ${temporaryTheme.spacing.space6};
    flex-direction: ${!!invertRow && ' row-reverse'};
    background: ${background};
    max-width: ${fullWidth ? '100%' : MAX_PAGE_WIDTH};
    width: 100%;

    .action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 13px;
    }

    .action-link {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        color: #4579ff;
      }
    }

    .card-box {
      width: 100%;
    }

    .content-box {
      display: flex;
      align-items: stretch;
      gap: ${temporaryTheme.spacing.space3};
      justify-content: space-between;
      margin-bottom: ${temporaryTheme.spacing.space3};
    }

    @media ${DESKTOP_WIDTH} {
      padding: ${temporaryTheme.spacing.space10} 26px;
      width: ${fullWidth && '100%'};
    }
    @media ${TABLET_WIDTH} {
      padding: ${temporaryTheme.spacing.space10} ${temporaryTheme.spacing.space4};
      width: 100%;

      .card-box {
        overflow-x: auto;
      }

      .content-box {
        width: ${MAX_PAGE_WIDTH};
      }
    }
    @media ${MOBILE_WIDTH} {
      padding: ${temporaryTheme.spacing.space7} ${temporaryTheme.spacing.space5} ${temporaryTheme.spacing.space7}
        ${temporaryTheme.spacing.space5};
      flex-direction: column-reverse;
      flex-direction: ${invertRow ? 'column' : ' column-reverse'};
      width: 100%;

      &.withCard {
        padding: 48px 10px;
      }

      .content-box {
        width: 950px;
      }

      .card-box {
        overflow-x: auto;
        width: 100%;
      }

      .action-link {
        justify-content: flex-start;
      }
    }
  `}
`

export const SectionForm = styled(Section)`
  margin-bottom: ${temporaryTheme.spacing.space7};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Box = styled.div<BoxStyleProps>`
  ${({ justify, fullWidth }) => css`
    width: ${fullWidth ? '100%' : '50%'};
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media ${MOBILE_WIDTH} {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: left;
    }
    ${!!justify && modifierStyles[justify]()};
  `}
`

export const Image = styled.img`
  width: 600px;

  @media ${TABLET_WIDTH} {
    width: 100%;
  }
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const CardImage = styled.div<CardImageProps>`
  ${({ image }) => css`
    width: 544px;
    height: 344px;
    border-radius: 22px;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .complement-logo {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
    }

    @media ${TABLET_WIDTH} {
      width: 100%;
    }
    @media ${MOBILE_WIDTH} {
      width: 100%;
    }
  `}
`

export const PartnesLogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${temporaryTheme.spacing.space4};
  flex-wrap: wrap;
  margin: 83px 0;
  @media ${DESKTOP_WIDTH} {
    width: calc(1366px - 76px);
  }
  @media ${TABLET_WIDTH} {
    justify-content: space-evenly;
  }
  @media ${MOBILE_WIDTH} {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: ${temporaryTheme.spacing.space3} 0;
    margin: 50px 0;

    justify-content: flex-start;
  }
`

export const PartnesLogo = styled.img`
  filter: grayscale(1);
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    filter: grayscale(0);
    transform: scale(1.05);
  }
`

export const LinkText = styled.p`
  all: unset;
  text-decoration: underline;
  cursor: pointer;
  color: ${temporaryTheme.colors.link};
`

export const BannerContent = styled.div`
  position: relative;
  width: 100%;
  max-height: 384px;
  min-height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .content {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: ${MAX_PAGE_WIDTH};
    padding: 0 26px;
  }

  .title {
    color: ${temporaryTheme.colors.white};
    font-family: 'Spartan', sans-serif;
    font-size: 37px;
    font-weight: 700;
  }

  .subTitle {
    color: ${temporaryTheme.colors.white};
    font-family: 'Spartan', sans-serif;
    font-size: 28px;
    font-weight: 400;
  }
`

export const VideoFrame = styled.video`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 384px;
  object-fit: cover;
`
export const SourceFrame = styled.source``
export const ActionsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space3};

  button {
    width: 100%;
    max-width: 529px;
  }
`

export const ModalEmailBox = styled.div`
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const ModalEmailContent = styled.div`
  width: 350px;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 500;
`

export const LinkEmail = styled.a`
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: ${temporaryTheme.colors.link};

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`
