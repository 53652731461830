import { SVGProps } from 'react'

export const FileCheckIcon = ({ width = '40', height = '48', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 14C14.0002 11.4227 16.0895 9.33333 18.6668 9.33333H29.1668V17.7917C29.1668 20.369 31.2562 22.4583 33.8335 22.4583H42.0002V29.8958C42.0002 31.1845 43.0448 32.2292 44.3335 32.2292C45.6222 32.2292 46.6668 31.1845 46.6668 29.8958V20.125C46.6668 19.5152 46.4281 18.9297 46.0018 18.4937L33.1685 5.36872C32.7295 4.91975 32.1281 4.66666 31.5002 4.66666H18.6668C13.5122 4.66666 9.3335 8.84534 9.3335 14V42C9.3335 47.1547 13.5122 51.3333 18.6668 51.3333H23.3335C24.6222 51.3333 25.6668 50.2887 25.6668 49C25.6668 47.7113 24.6222 46.6667 23.3335 46.6667H18.6668C16.0895 46.6667 14.0002 44.5773 14.0002 42V14ZM38.7886 17.7917L33.8335 12.7239V17.7917H38.7886ZM48.3167 38.9832C49.228 38.072 49.228 36.5946 48.3167 35.6834C47.4055 34.7722 45.9281 34.7722 45.0169 35.6834L35.0002 45.7002L31.9834 42.6834C31.0722 41.7722 29.5948 41.7722 28.6836 42.6834C27.7724 43.5946 27.7724 45.072 28.6836 45.9832L33.3502 50.6499C34.2615 51.5611 35.7389 51.5611 36.6501 50.6499L48.3167 38.9832Z"
      fill="#748351"
    />
  </svg>
)
