import styled, { css, keyframes } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const ContainerContent = styled.div<{ isSmallScreen: boolean }>`
  height: 100%;
  width: 100%;
  padding: 0 100px;
  .button {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ${({ isSmallScreen }) =>
    isSmallScreen &&
    `
    padding: 0;
  `}
`
type statusType = 'success' | 'danger' | 'default' | 'pending'

const colorVariant = {
  success: () => css`
    background-color: #2c9463;
    svg {
      color: white;
    }
  `,
  danger: () => css`
    background-color: #c2344f;
  `,
  pending: () => css`
    background-color: rgb(235 245 255);
    border: 2px solid rgb(0 87 181);
    color: rgb(0 91 189);

    svg {
      color: rgb(0 91 189);
    }
  `,
  default: () => css`
    border: 2px solid rgb(151 151 151);
    background-color: rgb(255 255 255);
    color: black;

    svg {
      color: black;
    }
  `
}

export const Button = styled.button<{ isActive: boolean }>`
  background-color: white;
  color: ${temporaryTheme.colors.dark};
  border-top: solid 0px;
  border-right: solid 0px;
  border-left: solid 0px;
  border-bottom: solid 1px ${temporaryTheme.colors.gray};
  padding: 10px 0px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-bottom: solid 2px ${temporaryTheme.colors.gray};
    transition: transform 0.3s ease;
    transform: translateX(100%);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${temporaryTheme.colors.sitePrimary};
      &:before {
        transform: translateX(0%);
        border-bottom: solid 2px ${temporaryTheme.colors.sitePrimary};
      }
    `}
`

export const lineDivisor = styled.div`
  width: 100%;
  height: 1px;
  border-top: solid 1px ${temporaryTheme.colors.gray};
  margin-top: -1px;
`

export const Header = styled.div`
  color: ${temporaryTheme.colors.darkGrey};
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  padding: 8px 0px;
  font-weight: bold;

  span {
    margin-left: 10px;
  }
`

export const Wrapper = styled.div<{ status: statusType; isActive: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ isActive }) =>
    isActive &&
    css`
      .title {
        color: #007bff;
      }
      .line {
        background-color: #007bff;
      }
    `}

  .title_label {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 12px;

    flex-wrap: wrap;
    flex-direction: column;
    p {
      margin-right: 6px;
    }

    a {
      border: none;
      color: darkblue;
      font-size: 12px;
      margin-right: 4px;

      cursor: pointer;

      :hover {
        color: #0b54c2;
      }
    }
  }

  .label {
    color: #828a84;
    font-weight: 300;
    font-size: 12px;
    height: auto;
  }

  .line {
    width: 3px;
    margin-right: 24px;
    background: #a4a9a5;
    height: 60px;
    margin-left: -6px;
  }

  .title {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .badge {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 10px;
    ${({ status }) => colorVariant[status]};
    z-index: 50;
  }

  .wrapper {
    display: flex;
    align-items: center;
    width: 260px;
    justify-content: space-between;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #fff;
    ${({ status }) => colorVariant[status]};
    border-radius: 50%;
    margin: 0 16px;
  }

  svg {
    width: 50px;
    color: white;
  }
`

export const ContentTimeLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0;
`

export const BadgeIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(2px);
  .spin {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;
      color: rgb(255 255 255);
    }
  }
`

export const LinkLabel = styled.a`
  font-size: 14px;
  color: ${temporaryTheme.colors.link};
`

export const spacingDiv = styled.div<{ isSmallScreen: boolean }>`
  width: 100%;
  margin-left:0px;

  ${({ isSmallScreen }) =>
    isSmallScreen &&
    `
    margin-left: 10px;
  `}
`