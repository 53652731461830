/* eslint-disable no-nested-ternary */
import { Modal } from 'antd'
import * as S from './styles'

interface fileTypes {
  url: string
  extension: string
}

interface DocumentVisualizationTypes {
  file: fileTypes
  openVisualization: boolean
  closeVisualization: () => void
}

export function DocumentVisualization({ file, openVisualization, closeVisualization }: DocumentVisualizationTypes) {
  const url = file.url || ''
  const isPdf = file.extension === 'application/pdf'
  const isImage = file.extension.startsWith('image/')
  const isJpegOrPng = isImage && (file.extension.includes('/jpeg') || file.extension.includes('/png'))
  return (
    <Modal
      open={openVisualization}
      onCancel={closeVisualization}
      footer={null}
      style={{ maxWidth: '900px' }}
      width="auto"
    >
      <S.Wrapper>
        <div>
          {isPdf ? (
            <div className="viewPdfBox">
              <iframe
                src={url}
                width="100%"
                height="100%"
                style={{ minHeight: '800px' }}
                title="Preview do documento"
              />
            </div>
          ) : isJpegOrPng ? (
            <S.Image src={url || ''} alt="imagem preview do documento." />
          ) : (
            <p>
              Arquivos do formato
              <i>
                <strong>{`'${file.extension}'`}</strong>
              </i>
              são inválidos. O cliente deve reenviar este documento em JPG, PNG ou PDF.
            </p>
          )}
        </div>
      </S.Wrapper>
    </Modal>
  )
}
