import { formatReal } from 'utils/formatters'
import * as S from './styles'
import { MOBILE_WIDTH } from '@cannect/utils/constants'
import useMediaQuery from '@cannect/hooks/useMediaQuery'

interface GenericItem<T = Record<string, any>> {
  price?: number
  real_price?: number
  quantity: number
  data: T
  product?: any
  unitPrice?: string
}

interface PaymentProps {
  instalments: number
  method: 'boleto' | 'pix' | 'credit_card'
  installment_value: string
  interest_amount: string
  link: null | string
  installments?: number
}

interface ResumeOrderProps {
  items?: GenericItem[]
  freight?: number
  reshipping_value?: string
  subtotal?: number
  discountFreight?: number
  discountProduct?: number
  paymentData?: PaymentProps
  amount?: number
  title?: string
  originType?: string
}

export function ResumeOrder({
  items = [],
  reshipping_value,
  freight,
  subtotal,
  discountFreight,
  discountProduct,
  paymentData,
  amount,
  title,
  originType
}: ResumeOrderProps) {
  const not_value = 'R$ 0,00'
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const product_total = items.reduce((acc, item) => {
    const price = item?.unitPrice ?? item.price ?? item.real_price ?? item?.product?.real_price ?? 0
    return acc + price * item.quantity
  }, 0)

  const validatePrice = (price?: number | string) => {
    if (price) {
      return formatReal(price)
    }

    return not_value
  }

  console.log(discountFreight)
  console.log(discountProduct)

  const newAmount = Number(product_total) + Number(('140')) - Number((discountFreight ? discountFreight : 0)) - Number((discountProduct ? discountProduct : 0))

  console.log(freight)

  return (
    <S.Wrapper className="component_resume-wrapper">
      <S.Heading>{title}</S.Heading>

      <S.ResumeList>
        <S.ResumeLine>
          <S.PriceTitle>Produtos ({items.length}) </S.PriceTitle>
          <S.PriceValue>{validatePrice(product_total)}</S.PriceValue>
        </S.ResumeLine>
        <S.ResumeLine>
          <S.PriceTitle>Custo de importação </S.PriceTitle>
          <S.PriceValue>R$ 140,00</S.PriceValue>
        </S.ResumeLine>
        <S.ResumeLine>
          <S.PriceTitle>Descontos nos produtos </S.PriceTitle>
          <S.PriceValue>- {validatePrice(discountProduct)}</S.PriceValue>
        </S.ResumeLine>
        <S.ResumeLine>
          {isMobile ? (
            <S.PriceTitle>Descontos no custo<br /> de importação </S.PriceTitle>
          ) : (
            <S.PriceTitle>Descontos no custo de importação </S.PriceTitle>
          )}
          
          <S.PriceValue>- {validatePrice(discountFreight)}</S.PriceValue>
        </S.ResumeLine>
        <S.ResumeLine>
          <S.PriceTitle>Subtotal </S.PriceTitle>
          <S.PriceValue>{validatePrice(product_total)}</S.PriceValue>
        </S.ResumeLine>
        {!!paymentData?.installment_value && (  
          <>
            <S.Separator />
            <S.ResumeLine>
              <S.PriceTitle>Pagamento </S.PriceTitle>
              <S.PriceValue>
                {paymentData.instalments || paymentData?.installments || 1}x
                {validatePrice(Number(paymentData.installment_value))}
              </S.PriceValue>
            </S.ResumeLine>
          </>
        )}
        <S.Separator />
        <S.ResumeLine>
          <S.PriceTitle isTotal>
            <strong>{originType === 'REENVIO' ? 'Total do Reenvio' : 'Total'}</strong>
          </S.PriceTitle>
          <S.PriceValue>{validatePrice(originType === 'REENVIO' ? reshipping_value : newAmount)}</S.PriceValue>
        </S.ResumeLine>
      </S.ResumeList>
    </S.Wrapper>
  )
}
