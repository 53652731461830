import { IRecipeProduct, LogicalOperatorsBetweenProducts, Product } from 'hooks/useCannectAssistant/types'
import Button from 'new-components/Button'
import Input from 'new-components/Input'
import { ChangeEvent, FormEvent, useState } from 'react'
import { fromPrescriptionToApiProducts } from '../../helpers'
import * as Styles from './styles'

interface SaveTemplateModalProps {
  isOpen: boolean
  onRequestClose: () => void
  prescriptionProducts: Product[]
  logicalOperators: LogicalOperatorsBetweenProducts[]
  recommendation: string
  saveTemplate: (name: string, recommendation: string, products: IRecipeProduct[]) => void
}

export default function SaveTemplateModal({
  isOpen,
  onRequestClose,
  recommendation,
  prescriptionProducts,
  logicalOperators,
  saveTemplate
}: SaveTemplateModalProps) {
  const [name, setName] = useState('')
  const [isNameInvalid, setIsNameInvalid] = useState(false)

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (name.trim().length === 0) {
      setIsNameInvalid(true)
      return
    }
    const products = fromPrescriptionToApiProducts(prescriptionProducts, logicalOperators)
    saveTemplate(name, recommendation, products)
    onRequestClose()
  }

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setIsNameInvalid(false)
  }

  return (
    <Styles.SaveTemplateModalContainer isOpened={isOpen} onRequestClose={onRequestClose}>
      <Styles.SaveTemplateForm onSubmit={onSubmit}>
        <h1>Cadastro de modelos</h1>
        <Input
          label="Nome do modelo"
          placeholder="Digite aqui um nome para seu modelo de receita"
          value={name}
          errorMessage={isNameInvalid ? 'O nome do modelo é obrigatório' : ''}
          onChange={onNameChange}
        />
        <Styles.SaveTemplateButtons>
          <Button background="green" type="button" isOutlined onClick={onRequestClose}>
            CANCELAR
          </Button>
          <Button background="green" type="submit">
            SALVAR
          </Button>
        </Styles.SaveTemplateButtons>
      </Styles.SaveTemplateForm>
    </Styles.SaveTemplateModalContainer>
  )
}
