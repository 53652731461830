import styled from 'styled-components'

interface IsMobile {
  isMobile: boolean
}

export const Container = styled.div<IsMobile>`
  display: flex;
  align-items: ${props => (props.isMobile ? 'flex-start' : 'center')};
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  padding: 16px;
  border: 1px solid #ecece2;
  border-radius: 8px;
  margin: 8px 8px;
  background: #fff;
  color: #484545;
  font-size: ${props => (props.isMobile ? '10px' : '1rem')};

  img {
    width: 67.08px;
    height: 70px;
  }
`
export const Products = styled.div`
  background: #f6f6f6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`

export const TableHeader = styled.div`
  background-color: #f6f6f6;
  border-radius: 4px;
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 12px 48px 12px 33px;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #433F3E;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
  }

  .product {
    width: 45%;
  }
  .sabor {
    margin-left: 5%;
    width: 90px;
  }

  .product-properties {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 54%;
  }
`

export const Line = styled.span`
  margin-left: 5%;
  width: 90%;
  height: 1px;
  background: rgba(119, 119, 119, 0.5);
`

export const ProductInfos = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  text-align: ${props => props.isMobile && 'center'};
  align-items: center;
  width: ${props => (props.isMobile ? '100%' : '45%')};

  img {
    height: auto;
    mix-blend-mode: multiply;
  }
`

export const ProductName = styled.span<any>`
  font-size: ${props => (props.isMobile ? '12px' : '1rem')};
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 8px;
`

export const Quantity = styled.div<any>`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 8px;

  .qtd {
    margin-left: 24px;
  }

  .remove-button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #c5581b;
    cursor: pointer;
    transition: color 0.6s;
    margin-top: 8px;
    border: none;
    background: transparent;
  }

  .remove-button:hover {
    color: #115850;
  }

  span {
    font-size: 12px;
  }
`

export const ProductPrice = styled.span<any>`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  margin: 20px;

  .unit_price {
    width: 130px;
    min-width: 130px;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    display: flex;
    flex-direction: column;
  `}
`
export const DeliveryWarning = styled.div`
  color: #858585;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
`

export const ProductSabor = styled.span<any>`
  display: flex;
  flex-direction: column; /* Adjusted for vertical layout */
  align-items: center;
  padding: 5px 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  border-radius: 16px;
  border: 1px solid #433F3E;
  min-width: 100px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px; /* Space between flavors */
  }
`

export const LowStockWarning = styled.span`
  color: red;
  font-size: 10px;
  text-align: center;
  padding-top: 4px; /* Space between flavor name and warning */
`

export const ProductTotal = styled(ProductPrice)`
  min-width: 75px;
`

export const ProductProperties = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.isMobile ? '100%' : '53%')};
`
