import styled, { css } from 'styled-components'
import { theme } from 'design-cannect'

export type InputProps = {
  width?: string
}

export const WrapperInput = styled('div')<InputProps>`
  ${({ width }) => css`
    width: ${width || 'auto'};
    min-height: 48px;
    position: relative;
    .wrapper-icon {
      position: absolute;
      transition: transform 0.3s;
      right: ${theme.spacing.space3};
      top: ${theme.spacing.space3};
      padding-left: ${theme.spacing.space3};
      color: ${theme.colors.black};
    }
  `}
`

export const ContainerTextArea = styled.textarea<any>`
  min-height: 48px;
  border-radius: ${theme.spacing.space2};
  padding: 15px ${theme.spacing.space3} 0 ${theme.spacing.space3};
  border: 2px solid ${theme.colors.tertiary};
  color: ${theme.colors.tertiary};
  font-weight: 300;
  min-width: 210px;
  width: 100%;
  min-height: 250px;

  &:focus {
    outline: none;
    border-color: ${theme.colors.secondary};
    transition: all 100ms ease;
  }

  &:-webkit-autofill {
    background: -internal-light-dark(black, white) !important;
  }

  ::placeholder {
    color: ${theme.colors.tertiary};
    font-weight: lighter;
  }
`
