import { theme } from 'design-cannect'
import { useEffect, useState } from 'react'
import * as Style from './styles'

interface ConnectorProps {
  active?: boolean
  disabled?: boolean
}

export function Connector({ active = false, disabled = false }: ConnectorProps) {
  const [background, setBackground] = useState('#ecece2')

  useEffect(() => {
    if (active) {
      setBackground(`${theme.colors.tertiary}`)
    } else if (!active) {
      setBackground(`${theme.colors.primary}`)
    }

    if (disabled) {
      setBackground(`#ecece2`)
    }
  }, [active, disabled])

  return <Style.ConnectorContainer style={{ background }} />
}
