import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { fetchPatientHistory } from '../../services'
import { TAchievementCategory } from '../../../MyPatients/types'
import { Tabs } from 'new-components/atoms'
import Table from 'new-components/atoms/Table'
import { formatPatientHistory } from './helpers'
import { CATEGORIES, COL_DEFS } from './constants'

interface PatientHistoryProps {
  patientId: number
}

const PatientHistory = ({ patientId }: PatientHistoryProps) => {
  const [category, setCategory] = useState<TAchievementCategory>()
  const [page, setPage] = useState(1)

  const { data, isFetching } = useQuery({
    queryKey: ['history', patientId, category, page],
    queryFn: () => fetchPatientHistory({ patientId, page, categories: category }).then(res => formatPatientHistory(res))
  })

  const onCategoryChange = (newValue: TAchievementCategory) => {
    setCategory(newValue)
    setPage(1)
  }

  return (
    <div className="flex w-full flex-col">
      <Tabs
        tabs={CATEGORIES}
        value={category}
        onValueChange={newValue => onCategoryChange(newValue as TAchievementCategory)}
        className="mb-4 mt-12"
      />
      <Table
        colDefs={COL_DEFS}
        data={data?.achievements.rows}
        isLoading={isFetching}
        pagination={{ page, onPageChange: setPage, totalItems: data?.achievements?.count ?? 0 }}
      />
    </div>
  )
}

export default PatientHistory
