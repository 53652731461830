import { Modal } from 'components/Modal'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const TemplatesModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 100%;
  max-width: 600px;

  @media ${MOBILE_WIDTH} {
    max-width: 95%;
  }
`

export const TemplatesModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  margin-top: 1.5rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`
