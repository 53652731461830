import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const RegisterAddressContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background: #f8f8f8;
  padding: 2rem;

  border-radius: 0.5rem;
  height: fit-content;
`

export const FieldsContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background: #e0e7ec;
  border-radius: 0.5rem;
  padding: 2rem;

  width: 100%;
`

export const FieldsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

interface ButtonsWrapperProps {
  isEditingAddress: boolean
}
export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isEditingAddress }) => (isEditingAddress ? 'space-between' : 'flex-end')};

  margin-top: 1rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column-reverse;
    gap: 1.5rem;

    button {
      width: 100%;
    }
  }
`
