import * as Dropdown from '@radix-ui/react-dropdown-menu'
import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export interface RootPropsStyle {
  hiddenMobile?: boolean
}

export const Wrapper = styled.main<RootPropsStyle>`
  ${({ hiddenMobile }) => css`
    @media (${MOBILE_WIDTH}) {
      display: ${!!hiddenMobile && 'none'};
    }
  `}
`

export const DropdownRoot = styled(Dropdown.Root)`
  width: 170px;
`

export const DropdownTrigger = styled(Dropdown.Trigger)``

export const ButtonDropdown = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 170px;
  height: 33px;
  background: #ffffff;
  border: 1.61812px solid #d9d9d9;
  border-radius: 5px;
  gap: 8px;
  svg {
    color: #3d3d3d;
  }

  .text {
    font-family: 'Spartan', sans-serif;
    font-weight: 400;
    font-size: 12px;
  }
`

export const IconTypeBox = styled.div`
  svg {
    width: 22px;
    height: 22px;
  }
`
export const IconArrow = styled.div`
  svg {
    width: 12px;
    height: 12px;
  }
`

export const ImageIcon = styled.img``

export const DropdownPortal = styled(Dropdown.Portal)``
export const DropdownContent = styled(Dropdown.Content)`
  padding: 10px 16px;
  margin-top: ${temporaryTheme.spacing.space1};
  width: 168px;
  background: #ffffff;
  border: 1.61812px solid #d9d9d9;
  border-radius: 4px;
`

export const DropdownItem = styled(Dropdown.Item)`
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: ${temporaryTheme.colors.darkGrey};
  margin: ${temporaryTheme.spacing.space2} 0;
  cursor: pointer;

  &[aria-disabled='true'] {
    color: #d9d9d9;
    pointer-events: none;
    &:hover {
      color: #d9d9d9;
    }
  }

  &:hover {
    color: ${temporaryTheme.colors.sitePrimary};
  }
`
