/* eslint-disable react/function-component-definition */
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp, AiOutlineInfoCircle } from 'react-icons/ai'
import { FaUserCircle } from 'react-icons/fa'
import { IPrescriberInfo } from 'hooks/useScheduling/types'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import {
  AboutContainer,
  AboutContent,
  Card,
  CardContent,
  ConsultationContainer,
  Expandable,
  Name,
  NameRow,
  PathologyContainer,
  PathologyTag,
  PhotoRow,
  PrescriberContainer,
  Separator,
  Text
} from './styles'
import AvailableTimes from './AvailableTimes'
import { Avatar, Row, Tooltip } from 'antd'
import { formatAgeGroups, returnVisitText } from 'pages/AppointmentScheduling/schedulingUtils'

const PrescriberCard: React.FC<{
  prescriberInfo: IPrescriberInfo | null
  displayAvailableTimes?: boolean
}> = ({ prescriberInfo, displayAvailableTimes = true }) => {
  const [expanded, setExpanded] = useState(false)

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  if (!prescriberInfo) return null

  const formattedAgeGroups = formatAgeGroups(prescriberInfo.prescriber_age_groups || [])

  return (
    <Card>
      <CardContent>
        <PhotoRow>
          {prescriberInfo.photo ? (
            <Avatar
              src={prescriberInfo.photo}
              size={isMobile ? 46 : 120}
              shape="circle"
              alt={prescriberInfo.professional_name}
            />
          ) : (
            <FaUserCircle size={isMobile ? 46 : 120} color="#ccc" />
          )}
        </PhotoRow>
        <PrescriberContainer>
          <NameRow>
            <Name>{prescriberInfo.professional_name}</Name>
          </NameRow>
          <Text fontWeight="700" marginBottom="-16px">
            {(prescriberInfo.prescriber_expertises || []).map((expertise: any) => expertise.name).join(' • ')}
          </Text>

          {formattedAgeGroups && (
            <Text color="#433F3E" >
              Atende {formattedAgeGroups}
              <Tooltip
                color="#E3E3E3"
                trigger={['click', 'hover']}
                placement="bottomLeft"
                title={
                  <div>
                    {prescriberInfo.prescriber_age_groups?.map((group: any) => (
                      <Text key={group.id}>• {group.name}</Text>
                    ))}
                  </div>
                }
                overlayInnerStyle={{ fontFamily: 'Poppins', color: '#433F3E' }}
              >
                <AiOutlineInfoCircle size={16} />
              </Tooltip>
            </Text>
          )}
          <ConsultationContainer>
            <span className="weight-600">
              {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                Number(prescriberInfo.online_appointment_value)
              )}{' '}
              <small className="weight-600">(no pix)</small>
            </span>{' '}
            <Separator />
            <span className="weight-700">{prescriberInfo?.appointment_duration} min</span>
          </ConsultationContainer>
        </PrescriberContainer>
        {displayAvailableTimes && !isMobile && <AvailableTimes prescriberInfo={prescriberInfo} />}
      </CardContent>
      {displayAvailableTimes && isMobile && <AvailableTimes prescriberInfo={prescriberInfo} />}
      <AboutContainer>
        <Expandable onClick={toggleExpanded}>
          <p>Sobre {expanded ? <AiFillCaretDown /> : <AiFillCaretUp />}</p>
        </Expandable>
        <motion.div
          initial={{ opacity: 0, height: 0, display: 'none' }}
          animate={
            expanded ? { opacity: 1, height: 'auto', display: 'block' } : { opacity: 0, height: 0, display: 'none' }
          }
          transition={{
            opacity: { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] },
            height: { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98], delay: expanded ? 0 : 0.2 },
            display: { duration: 0.5, ease: [0.04, 0.66, 0.26, 0.108], delay: expanded ? 0 : 0.5 }
          }}
        >
          <AboutContent>
            <p className="weight-600 return-text">{returnVisitText(prescriberInfo)}</p>
            <p className="weight-600">
              {prescriberInfo.council} {prescriberInfo.nr_council} {prescriberInfo.uf_council}
            </p>
            <p dangerouslySetInnerHTML={{ __html: prescriberInfo.biography }} />
            <p className="weight-700">Patologias que atende:</p>
            <PathologyContainer>
              {(prescriberInfo.prescriber_pathologies || []).map((pathology: any) => (
                <PathologyTag key={pathology.id}>{pathology.name}</PathologyTag>
              ))}
            </PathologyContainer>
          </AboutContent>
        </motion.div>
      </AboutContainer>
    </Card>
  )
}

export default PrescriberCard
