export type TDefaultOptionResponse = {
  id: number
  name: string
}

export const serializeOptions = (
  data: TDefaultOptionResponse[] & { [key: string]: any }[]
): { label: string; value: string | number }[] => {
  return data.map(({ id, name }) => ({
    label: name,
    value: id
  }))
}

/**
 * Calculates the next page number based on the current page, total items, and items per page.
 * Returns the next page number or undefined if there are no more pages.
 * This function is used to determine the next page parameter for useInfiniteQuery.
 */

type TNextPageParam = {
  currentPage: number
  totalItems: number
  itemsPerPage: number
}
export const calculateNextPageParam = ({
  currentPage,
  totalItems,
  itemsPerPage
}: TNextPageParam): number | undefined => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const nextPage = currentPage + 1
  return nextPage <= totalPages ? nextPage : undefined
}
