import { svgBasesCientificas } from 'assets/svg'
import CheckboxInput from 'components/CheckboxInput'
import EducateAside from 'components/CannectEducate/EducateAside'
import EducateNavigator from 'components/CannectEducate/EducateNavigator'
import FilterCard from 'components/CannectEducate/FilterCard'
import FilterSuggestionDropDown from 'components/FilterSuggestionDropDown'
import Suggestion from 'components/FilterSuggestionDropDown/Suggestion'
import SocialMedia from 'components/SocialMedia'
import TabContent, { Post } from 'components/CannectEducate/TabContent'
import TabContentTitle from 'components/CannectEducate/TabContentTitle'
import TabsNavigator from 'components/TabsNavigator'
import { Input } from 'design-cannect'
import { useEffect, useState } from 'react'
import * as Style from 'styles/cannectEducate'
import api from 'services/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'
import Pagination from 'components/Pagination'
import { Article, FetchArticlesResponse } from 'types/ArticlesTypes'

interface Pathology {
  id: number
  formattedId: string
  label: string
}

interface CheckFilter {
  id?: number
  formattedId: string
  label: string
  checked: boolean
}

interface ApiFilter {
  id: number
  name: string
}

interface FetchClassFilter {
  classes: ApiFilter[]
}

interface FetchPathologyFilter {
  pathologies: ApiFilter[]
}

export function formatArticles(articles: Article[]): Post[] {
  const newArticles: Post[] = []
  articles.forEach(article => {
    newArticles.push({
      id: article.id,
      description: article.synopsis,
      // img: pngCannabis,
      title: article.name,
      url: `/bases-cientificas/${article.id}`,
      externalUrl: article.link
    })
  })

  return newArticles
}

export default function ScientificBasesHome() {
  const [page, setPage] = useState(1)
  // const [count, setCount] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<Post[]>([])
  const [postsQuantity, setPostsQuantity] = useState(0)
  const [listOfAllPathologies, setListOfAllPathologies] = useState<Pathology[]>([])
  const [pathologyInput, setPathologyInput] = useState('')
  const [pathologiesThatMatchesSearch, setPathologiesThatMatchesSearch] = useState<Pathology[]>([])
  const [selectedPathologies, setSelectedPathologies] = useState<Pathology[]>([])
  const [pharmacologicalClasses, setPharmacologicalClasses] = useState<CheckFilter[]>([])
  const [levelOfEvidences, setLevelOfEvidences] = useState<CheckFilter[]>([
    {
      formattedId: 'levelOfEvidence-1a',
      label: '1A',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-1b',
      label: '1B',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-1c',
      label: '1C',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-2a',
      label: '2A',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-2b',
      label: '2B',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-2c',
      label: '2C',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-3a',
      label: '3A',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-3b',
      label: '3B',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-4',
      label: '4',
      checked: false
    },
    {
      formattedId: 'levelOfEvidence-5',
      label: '5',
      checked: false
    }
  ])

  useEffect(() => {
    async function fetchFilters() {
      const classesRequest = api.get<FetchClassFilter>(`/classes`)
      const pathologiesRequest = api.get<FetchPathologyFilter>(`/pathologies`)

      await Promise.all([classesRequest, pathologiesRequest]).then(results => {
        const classesResponse = results[0]
        const pathologiesResponse = results[1]

        // Formatting Pharmacological Classes
        const newClasses: CheckFilter[] = []
        classesResponse.data.classes.forEach(classFilter => {
          newClasses.push({
            id: classFilter.id,
            formattedId: `pharmacologicalClasses-${classFilter.id}`,
            label: classFilter.name,
            checked: false
          })
        })
        setPharmacologicalClasses(newClasses)

        // Formatting Pathologies
        const newPathologies: Pathology[] = []
        pathologiesResponse.data.pathologies.forEach(pathology => {
          newPathologies.push({
            id: pathology.id,
            formattedId: `pathology-${pathology.id}`,
            label: pathology.name
          })
        })
        setListOfAllPathologies(newPathologies)
      })
    }
    fetchFilters()
  }, [])

  useEffect(() => {
    fetchPosts()
  }, [page])

  useEffect(() => {
    setPosts([])
    setPage(1)
    if (page === 1) {
      fetchPosts()
    }
  }, [levelOfEvidences, pharmacologicalClasses, selectedPathologies])

  async function fetchPosts() {
    setLoading(true)
    try {
      const params = formatParams(levelOfEvidences, pharmacologicalClasses, selectedPathologies)

      const response = await api.get<FetchArticlesResponse>(`/articles/${page}?${params}&limit=12`)
      const newPosts = formatArticles(response.data.result.items)

      if (page === 1) {
        setPosts(newPosts)
        setPostsQuantity(response.data.result.totalItems)
        setTotalPages(response.data.result.totalPages)
      } else {
        setPosts([...newPosts])
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  function formatParams(
    levelOfEvidences: CheckFilter[],
    pharmacologicalClasses: CheckFilter[],
    selectedPathologies: Pathology[]
  ): string {
    let params = ''
    levelOfEvidences.forEach(level => {
      if (level.checked) {
        params += `level=${level.label}`
        params += `&`
      }
    })

    pharmacologicalClasses.some(pClass => {
      if (pClass.checked) {
        params += `Class=${pClass.id}`
        params += `&`
        return true
      }
      return false
    })

    selectedPathologies.forEach(pathology => {
      params += `pathology_id=${pathology.id}`
      params += `&`
    })

    params = params.slice(0, params.length - 1)
    return params
  }

  useEffect(() => {
    if (pathologyInput.trim().length === 0) {
      setPathologiesThatMatchesSearch([])
      return
    }

    const newPathologiesThatMatchesSearch: Pathology[] = []
    listOfAllPathologies.forEach(pathology => {
      const pathologyAlreadySelected = selectedPathologies.find(p => p.id === pathology.id)

      if (
        !pathologyAlreadySelected &&
        pathology.label.toLocaleLowerCase().includes(pathologyInput.trim().toLocaleLowerCase())
      ) {
        newPathologiesThatMatchesSearch.push(pathology)
      }
    })

    setPathologiesThatMatchesSearch(newPathologiesThatMatchesSearch)
  }, [pathologyInput, selectedPathologies])

  function handlePharmacologicalClassChange(id: number) {
    setPharmacologicalClasses(
      pharmacologicalClasses.map(p =>
        p.id === id
          ? {
              ...p,
              checked: true
            }
          : {
              ...p,
              checked: false
            }
      )
    )
  }

  function handleCancelPharmacologicalClassFilter(id: number) {
    setPharmacologicalClasses(
      pharmacologicalClasses.map(p =>
        p.id === id
          ? {
              ...p,
              checked: false
            }
          : p
      )
    )
  }

  const handlePage = (selected: any) => {
    setPage(selected + 1)
  }

  function handleLevelOfEvidenceChange(formattedId: string) {
    setLevelOfEvidences(
      levelOfEvidences.map(levelOfEvidence =>
        levelOfEvidence.formattedId === formattedId
          ? {
              ...levelOfEvidence,
              checked: !levelOfEvidence.checked
            }
          : levelOfEvidence
      )
    )
  }

  function handleAddPathology(pathology: Pathology) {
    setSelectedPathologies([
      ...selectedPathologies,
      { id: pathology.id, formattedId: `pathology=${pathology.id}`, label: pathology.label }
    ])
  }

  function handleRemovePathology(id: number) {
    setSelectedPathologies(selectedPathologies.filter(pathology => pathology.id !== id))
  }

  return (
    <Style.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Educa', link: '/educa' },
          { label: 'Bases Científicas', link: `/bases-cientificas` }
        ]}
      />
      <TabsNavigator />
      <EducateNavigator />

      <Style.Content>
        <EducateAside>
          <Style.AsideInputsWrapper>
            <Input
              placeholder="Patologia"
              value={pathologyInput}
              onChange={event => setPathologyInput(event.target.value)}
              color="tertiary"
            />
            {pathologiesThatMatchesSearch.length !== 0 && (
              <FilterSuggestionDropDown>
                {pathologiesThatMatchesSearch.map(pathology => (
                  <Suggestion key={pathology.id} onClick={() => handleAddPathology(pathology)}>
                    {pathology.label}
                  </Suggestion>
                ))}
              </FilterSuggestionDropDown>
            )}
          </Style.AsideInputsWrapper>

          <Style.FiltersWrapper>
            <span>Nível de evidência</span>
            {levelOfEvidences.map(levelOfEvidence => (
              <CheckboxInput
                key={levelOfEvidence.formattedId}
                id={levelOfEvidence.formattedId}
                label={levelOfEvidence.label}
                checked={levelOfEvidence.checked}
                onChange={() => handleLevelOfEvidenceChange(levelOfEvidence.formattedId)}
              />
            ))}
          </Style.FiltersWrapper>
        </EducateAside>

        <Style.Wrapper>
          <SocialMedia message="Acesse centenas de artigos sobre o uso da Cannabis medicinal!" />

          <TabContentTitle
            title="Bases Científicas"
            titleImg={svgBasesCientificas}
            postsCountText={`${postsQuantity} artigos encontrados`}
          />
          <Style.FiltersCardsContainer>
            {selectedPathologies.map(pathology => (
              <FilterCard
                key={pathology.formattedId}
                background="green"
                onRemoveClick={() => handleRemovePathology(pathology.id)}
              >
                {pathology.label}
              </FilterCard>
            ))}
            {pharmacologicalClasses.map(
              pharmacologicalClass =>
                pharmacologicalClass.checked && (
                  <FilterCard
                    key={pharmacologicalClass.formattedId}
                    background="blue"
                    onRemoveClick={() => handleCancelPharmacologicalClassFilter(pharmacologicalClass.id as number)}
                  >
                    {pharmacologicalClass.label}
                  </FilterCard>
                )
            )}
            {levelOfEvidences.map(
              levelOfEvidence =>
                levelOfEvidence.checked && (
                  <FilterCard
                    key={levelOfEvidence.formattedId}
                    background="yellow"
                    onRemoveClick={() => handleLevelOfEvidenceChange(levelOfEvidence.formattedId)}
                  >
                    {levelOfEvidence.label}
                  </FilterCard>
                )
            )}
          </Style.FiltersCardsContainer>
          <InfiniteScroll
            dataLength={posts.length}
            next={() => setPage(state => state + 1)}
            hasMore={page !== totalPages}
            loader={<div />}
            endMessage={<div />}
          >
            <TabContent posts={posts} actionButtonText="LER" inLine />
          </InfiniteScroll>

          {loading && <Loading loading={loading} />}
          <Pagination
            forcePage={page - 1}
            onPageChange={({ selected }: any) => handlePage(selected)}
            pageCount={totalPages}
          />
        </Style.Wrapper>
      </Style.Content>
    </Style.Container>
  )
}
