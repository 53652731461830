/* eslint-disable no-debugger */
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { generateRandomNumber } from 'utils/randomNumberGenerator'
import api from 'services/api'
import { useTokenApi } from './useTokenApi'

const CannectCareContext = createContext<CannectCareContextValues>({} as CannectCareContextValues)

interface CannectCareProviderProps {
  children: ReactNode
}

export type Path = 'lifeQuality' | 'symptoms' | 'sideEffects' | ''

interface CannectCareContextValues {
  loading: boolean
  path: Path
  setPath: Dispatch<SetStateAction<Path>>
  step: number
  setStep: Dispatch<SetStateAction<number>>
  lifeQualityQuestionsAnswers: LifeQualityQuestionAnswer[]
  qualityOfLife: BadToGood
  setQualityOfLife: Dispatch<SetStateAction<BadToGood>>
  healthSatisfaction: Satisfaction
  setHealthSatisfaction: Dispatch<SetStateAction<Satisfaction>>
  physicalPain: NothingToExtremely
  setPhysicalPain: Dispatch<SetStateAction<NothingToExtremely>>
  medialTreatment: NothingToExtremely
  setMedialTreatment: Dispatch<SetStateAction<NothingToExtremely>>
  enjoyLife: NothingToExtremely
  setEnjoyLife: Dispatch<SetStateAction<NothingToExtremely>>
  lifeMakesSense: NothingToExtremely
  setLifeMakesSense: Dispatch<SetStateAction<NothingToExtremely>>
  concentration: NothingToExtremely
  setConcentration: Dispatch<SetStateAction<NothingToExtremely>>
  safety: NothingToExtremely
  setSafety: Dispatch<SetStateAction<NothingToExtremely>>
  physicalEnvironment: NothingToExtremely
  setPhysicalEnvironment: Dispatch<SetStateAction<NothingToExtremely>>
  energy: NothingToCompletely
  setEnergy: Dispatch<SetStateAction<NothingToCompletely>>
  physicalAppearanceAcceptance: NothingToCompletely
  setPhysicalAppearanceAcceptance: Dispatch<SetStateAction<NothingToCompletely>>
  moneyNeed: NothingToCompletely
  setMoneyNeed: Dispatch<SetStateAction<NothingToCompletely>>
  informationAvailability: NothingToCompletely
  setInformationAvailability: Dispatch<SetStateAction<NothingToCompletely>>
  leisureActivities: NothingToCompletely
  setLeisureActivities: Dispatch<SetStateAction<NothingToCompletely>>
  locomotionAbility: BadToGood
  setLocomotionAbility: Dispatch<SetStateAction<BadToGood>>
  sleepSatisfaction: Satisfaction
  setSleepSatisfaction: Dispatch<SetStateAction<Satisfaction>>
  routineActivitiesCapability: Satisfaction
  setRoutineActivitiesCapability: Dispatch<SetStateAction<Satisfaction>>
  workCapability: Satisfaction
  setWorkCapability: Dispatch<SetStateAction<Satisfaction>>
  selfSatisfaction: Satisfaction
  setSelfSatisfaction: Dispatch<SetStateAction<Satisfaction>>
  personalRelationship: Satisfaction
  setPersonalRelationship: Dispatch<SetStateAction<Satisfaction>>
  sexualLife: Satisfaction
  setSexualLife: Dispatch<SetStateAction<Satisfaction>>
  friendsSupport: Satisfaction
  setFriendsSupport: Dispatch<SetStateAction<Satisfaction>>
  livingPlace: Satisfaction
  setLivingPlace: Dispatch<SetStateAction<Satisfaction>>
  healthAccess: Satisfaction
  setHealthAccess: Dispatch<SetStateAction<Satisfaction>>
  transportAccess: Satisfaction
  setTransportAccess: Dispatch<SetStateAction<Satisfaction>>
  badFeelingsFrequency: Frequency
  setBadFeelingsFrequency: Dispatch<SetStateAction<Frequency>>
  symptoms: Symptom[]
  setSymptoms: Dispatch<SetStateAction<Symptom[]>>
  sideEffects: SideEffect[]
  setSideEffects: Dispatch<SetStateAction<SideEffect[]>>
  otherSideEffects: SideEffect[]
  setOtherSideEffects: Dispatch<SetStateAction<SideEffect[]>>
  otherSymptoms: Symptom[]
  addOtherSysmptom: () => void
  removeOtherSysmptom: (id: number) => void
  changeOtherSymptomName: (id: number, newName: string) => void
  toggleSymptomSelection: (id: number) => void
  toggleSideEffectSelection: (id: number) => void
  changeSymptomOccurrences: (id: number, newOccurrencesValue: number, isExistingSymptom: boolean) => void
  changeOtherSymptomType: (id: number, newType: SymptomType) => void
  fetchSymptoms: () => Promise<boolean>
  sendSymptoms: () => Promise<boolean>
  fetchLifeQualityQuestions: () => Promise<boolean>
  fetchLifeQualityQuestionsAnswers: () => Promise<boolean>
  sendLifeQualityQuestions: () => Promise<boolean>
  fetchSideEffects: () => Promise<boolean>
  sendSideEffects: () => Promise<boolean>
}

// Life Quality types
export type BadToGood = 'veryBad' | 'bad' | 'neitherBadNorGood' | 'good' | 'veryGood' | ''
export type NothingToExtremely = 'nothing' | 'veryLittle' | 'moreOrLess' | 'quite' | 'extremely' | ''
export type NothingToCompletely = 'nothing' | 'veryLittle' | 'medium' | 'quite' | 'completely' | ''
export type Frequency = 'never' | 'sometimes' | 'frequently' | 'veryFrequently' | 'always' | ''
export type Satisfaction =
  | 'veryUnsatisfied'
  | 'unsatisfied'
  | 'neitherSatisfiedNorUnsatisfied'
  | 'satisfied'
  | 'verySatisfied'
  | ''

type LifeQualityAnswersTypes = 'badToGood' | 'nothingToExtremely' | 'nothingToCompletely' | 'frequency' | 'satisfaction'

interface LifeQualityQuestion {
  id: number
  priority: number
  question: string
}

interface FetchLifeQualityQuestionsResponse {
  success: boolean
  quality_life_questions: LifeQualityQuestion[]
}

interface LifeQualityQuestionAnswer {
  id: number
  symptom_intensity: `${number}`
  care_line_steps_tasks_id: number
  user_id: number
  symptom_id: number
}

interface FetchLifeQualityQuestionsAnswersResponse {
  success: boolean
  users_quality_life_answers: LifeQualityQuestionAnswer[]
}

interface SendLifeQualityQuestionsBody {
  quality_life_question_id: number
  answer: number
}

// Symptoms types
export interface Symptom {
  id: number
  label: string
  imgChecked: string
  imgUnchecked: string
  selected: boolean
  type: SymptomType
  occurrences: number
  cameFromApi: boolean
}

export type SymptomType = 'episodes' | 'intensity'

interface FetchSymptomsResponse {
  success: boolean
  symptoms: {
    id: number
    image_checked: string
    image_unchecked: string
    name: string
    typeIntensity: 'Episódios' | 'Intensidade'
  }[]
}

interface SendSymptomsBody {
  symptom_id: number
  symptom_intensity: number
}

interface FetchUserSymptomsResponse {
  success: boolean
  symptoms: {
    care_line_steps_tasks_id: number
    id: number
    symptom_id: number
    symptom_intensity: `${number}`
    user_id: number
  }[]
}

// Side Effects types
interface FetchSideEffectsResponse {
  success: boolean
  side_effects: {
    id: number
    image_checked: string
    image_unchecked: string
    name: string
  }[]
}

interface FetchUserSideEffectsResponse {
  success: boolean
  side_effects: {
    care_line_steps_tasks_id: number
    id: number
    side_effect_id: number
    user_id: number
  }[]
}

interface SideEffect {
  id: number
  label: string
  imgChecked: string
  imgUnchecked: string
  selected: boolean
}

interface SendSideEffectsBody {
  side_effect_id: number
}

export function CannectCareProvider({ children }: CannectCareProviderProps) {
  const { deleteTokenApi } = useTokenApi()
  const [loading, setLoading] = useState(false)
  const [path, setPath] = useState<Path>('')
  const [step, setStep] = useState(0)
  const [lifeQualityQuestionsAnswers, setLifeQualityQuestionsAnswers] = useState<LifeQualityQuestionAnswer[]>([])
  const [qualityOfLife, setQualityOfLife] = useState<BadToGood>('')
  const [healthSatisfaction, setHealthSatisfaction] = useState<Satisfaction>('')
  const [physicalPain, setPhysicalPain] = useState<NothingToExtremely>('')
  const [medialTreatment, setMedialTreatment] = useState<NothingToExtremely>('')
  const [enjoyLife, setEnjoyLife] = useState<NothingToExtremely>('')
  const [lifeMakesSense, setLifeMakesSense] = useState<NothingToExtremely>('')
  const [concentration, setConcentration] = useState<NothingToExtremely>('')
  const [safety, setSafety] = useState<NothingToExtremely>('')
  const [physicalEnvironment, setPhysicalEnvironment] = useState<NothingToExtremely>('')
  const [energy, setEnergy] = useState<NothingToCompletely>('')
  const [physicalAppearanceAcceptance, setPhysicalAppearanceAcceptance] = useState<NothingToCompletely>('')
  const [moneyNeed, setMoneyNeed] = useState<NothingToCompletely>('')
  const [informationAvailability, setInformationAvailability] = useState<NothingToCompletely>('')
  const [leisureActivities, setLeisureActivities] = useState<NothingToCompletely>('')
  const [locomotionAbility, setLocomotionAbility] = useState<BadToGood>('')
  const [sleepSatisfaction, setSleepSatisfaction] = useState<Satisfaction>('')
  const [routineActivitiesCapability, setRoutineActivitiesCapability] = useState<Satisfaction>('')
  const [workCapability, setWorkCapability] = useState<Satisfaction>('')
  const [selfSatisfaction, setSelfSatisfaction] = useState<Satisfaction>('')
  const [personalRelationship, setPersonalRelationship] = useState<Satisfaction>('')
  const [sexualLife, setSexualLife] = useState<Satisfaction>('')
  const [friendsSupport, setFriendsSupport] = useState<Satisfaction>('')
  const [livingPlace, setLivingPlace] = useState<Satisfaction>('')
  const [healthAccess, setHealthAccess] = useState<Satisfaction>('')
  const [transportAccess, setTransportAccess] = useState<Satisfaction>('')
  const [badFeelingsFrequency, setBadFeelingsFrequency] = useState<Frequency>('')
  const [symptoms, setSymptoms] = useState<Symptom[]>([])
  const [otherSymptoms, setOtherSymptoms] = useState<Symptom[]>([])
  const [sideEffects, setSideEffects] = useState<SideEffect[]>([])
  const [otherSideEffects, setOtherSideEffects] = useState<SideEffect[]>([])

  useEffect(() => {
    const element = document.getElementById('page-wrapper')

    if (element) {
      element.scrollTo(0, 0)
    }
  }, [step])

  // Life Quality methods
  async function fetchLifeQualityQuestions(): Promise<boolean> {
    setLoading(true)
    try {
      await api.get<FetchLifeQualityQuestionsResponse>(`/cannect-cuida/quality_life_questions`)
      return true
    } catch (err: any) {
      if (err?.response?.status === 401) {
        deleteTokenApi()
      }
      console.error(err)
      return false
    } finally {
      setLoading(false)
    }
  }

  async function fetchLifeQualityQuestionsAnswers(): Promise<boolean> {
    setLoading(true)
    try {
      const response = await api.get<FetchLifeQualityQuestionsAnswersResponse>(
        `/cannect-cuida/user_quality_life_answers`
      )
      setLifeQualityQuestionsAnswers(response.data.users_quality_life_answers)

      return true
    } catch (err: any) {
      if (err?.response?.status === 401) {
        deleteTokenApi()
      }
      console.error(err)
      return false
    } finally {
      setLoading(false)
    }
  }

  async function sendLifeQualityQuestions(): Promise<boolean> {
    setLoading(true)
    try {
      await api.post(`/cannect-cuida/send_quality_life_answers`, {
        care_line_steps_tasks_id: 2,
        answers: formatSendLifeQualityBody()
      })

      clearCurrentLifeQualityAnswers()

      return true
    } catch (err: any) {
      if (err?.response?.status === 401) {
        deleteTokenApi()
      }
      console.error(err)
      return false
    } finally {
      setLoading(false)
    }
  }

  function clearCurrentLifeQualityAnswers() {
    setQualityOfLife('')
    setHealthSatisfaction('')
    setPhysicalPain('')
    setMedialTreatment('')
    setEnjoyLife('')
    setLifeMakesSense('')
    setConcentration('')
    setSafety('')
    setPhysicalEnvironment('')
    setEnergy('')
    setPhysicalAppearanceAcceptance('')
    setMoneyNeed('')
    setInformationAvailability('')
    setLeisureActivities('')
    setLocomotionAbility('')
    setSleepSatisfaction('')
    setRoutineActivitiesCapability('')
    setWorkCapability('')
    setSelfSatisfaction('')
    setPersonalRelationship('')
    setSexualLife('')
    setFriendsSupport('')
    setLivingPlace('')
    setHealthAccess('')
    setTransportAccess('')
    setBadFeelingsFrequency('')
  }

  function formatSendLifeQualityBody(): SendLifeQualityQuestionsBody[] {
    const formattedBody: SendLifeQualityQuestionsBody[] = []

    formattedBody.push({
      quality_life_question_id: 1,
      answer: compareLifeQualityTypes('badToGood', qualityOfLife)
    })
    formattedBody.push({
      quality_life_question_id: 2,
      answer: compareLifeQualityTypes('satisfaction', healthSatisfaction)
    })
    formattedBody.push({
      quality_life_question_id: 3,
      answer: compareLifeQualityTypes('nothingToExtremely', physicalPain)
    })
    formattedBody.push({
      quality_life_question_id: 4,
      answer: compareLifeQualityTypes('nothingToExtremely', medialTreatment)
    })
    formattedBody.push({
      quality_life_question_id: 5,
      answer: compareLifeQualityTypes('nothingToExtremely', enjoyLife)
    })
    formattedBody.push({
      quality_life_question_id: 6,
      answer: compareLifeQualityTypes('nothingToExtremely', lifeMakesSense)
    })
    formattedBody.push({
      quality_life_question_id: 7,
      answer: compareLifeQualityTypes('nothingToExtremely', concentration)
    })
    formattedBody.push({
      quality_life_question_id: 8,
      answer: compareLifeQualityTypes('nothingToExtremely', safety)
    })
    formattedBody.push({
      quality_life_question_id: 9,
      answer: compareLifeQualityTypes('nothingToExtremely', physicalEnvironment)
    })
    formattedBody.push({
      quality_life_question_id: 10,
      answer: compareLifeQualityTypes('nothingToCompletely', energy)
    })
    formattedBody.push({
      quality_life_question_id: 11,
      answer: compareLifeQualityTypes('nothingToCompletely', physicalAppearanceAcceptance)
    })
    formattedBody.push({
      quality_life_question_id: 12,
      answer: compareLifeQualityTypes('nothingToCompletely', moneyNeed)
    })
    formattedBody.push({
      quality_life_question_id: 13,
      answer: compareLifeQualityTypes('nothingToCompletely', informationAvailability)
    })
    formattedBody.push({
      quality_life_question_id: 14,
      answer: compareLifeQualityTypes('nothingToCompletely', leisureActivities)
    })
    formattedBody.push({
      quality_life_question_id: 15,
      answer: compareLifeQualityTypes('badToGood', locomotionAbility)
    })
    formattedBody.push({
      quality_life_question_id: 16,
      answer: compareLifeQualityTypes('satisfaction', sleepSatisfaction)
    })
    formattedBody.push({
      quality_life_question_id: 17,
      answer: compareLifeQualityTypes('satisfaction', routineActivitiesCapability)
    })
    formattedBody.push({
      quality_life_question_id: 18,
      answer: compareLifeQualityTypes('satisfaction', workCapability)
    })
    formattedBody.push({
      quality_life_question_id: 19,
      answer: compareLifeQualityTypes('satisfaction', selfSatisfaction)
    })
    formattedBody.push({
      quality_life_question_id: 20,
      answer: compareLifeQualityTypes('satisfaction', personalRelationship)
    })
    formattedBody.push({
      quality_life_question_id: 21,
      answer: compareLifeQualityTypes('satisfaction', sexualLife)
    })
    formattedBody.push({
      quality_life_question_id: 22,
      answer: compareLifeQualityTypes('satisfaction', friendsSupport)
    })
    formattedBody.push({
      quality_life_question_id: 23,
      answer: compareLifeQualityTypes('satisfaction', livingPlace)
    })
    formattedBody.push({
      quality_life_question_id: 24,
      answer: compareLifeQualityTypes('satisfaction', healthAccess)
    })
    formattedBody.push({
      quality_life_question_id: 25,
      answer: compareLifeQualityTypes('satisfaction', transportAccess)
    })
    formattedBody.push({
      quality_life_question_id: 26,
      answer: compareLifeQualityTypes('frequency', badFeelingsFrequency)
    })

    return formattedBody
  }

  function compareLifeQualityTypes(type: LifeQualityAnswersTypes, value: string): number {
    if (type === 'badToGood') {
      switch (value) {
        case 'veryBad':
          return 1
        case 'bad':
          return 2
        case 'neitherBadNorGood':
          return 3
        case 'good':
          return 4
        case 'veryGood':
          return 5
        default:
          break
      }
    } else if (type === 'nothingToExtremely') {
      switch (value) {
        case 'nothing':
          return 1
        case 'veryLittle':
          return 2
        case 'moreOrLess':
          return 3
        case 'quite':
          return 4
        case 'extremely':
          return 5
        default:
          break
      }
    } else if (type === 'nothingToCompletely') {
      switch (value) {
        case 'nothing':
          return 1
        case 'veryLittle':
          return 2
        case 'medium':
          return 3
        case 'quite':
          return 4
        case 'completely':
          return 5
        default:
          break
      }
    } else if (type === 'frequency') {
      switch (value) {
        case 'never':
          return 1
        case 'sometimes':
          return 2
        case 'frequently':
          return 3
        case 'veryFrequently':
          return 4
        case 'always':
          return 5
        default:
          break
      }
    } else {
      switch (value) {
        case 'veryUnsatisfied':
          return 1
        case 'unsatisfied':
          return 2
        case 'neitherSatisfiedNorUnsatisfied':
          return 3
        case 'satisfied':
          return 4
        case 'verySatisfied':
          return 5
        default:
          break
      }
    }
    return 0
  }

  // Symptoms methods
  async function fetchSymptoms(): Promise<boolean> {
    setLoading(true)
    try {
      const symptomsResponse = await api.get<FetchSymptomsResponse>(`/cannect-cuida/symptoms`)
      const userSymptomsResponse = await api.get<FetchUserSymptomsResponse>(`/cannect-cuida/user_symptoms`)

      const formattedSymptoms: Symptom[] = []
      symptomsResponse.data.symptoms.forEach(symptom => {
        formattedSymptoms.push({
          id: symptom.id,
          label: symptom.name,
          imgChecked: symptom.image_checked,
          imgUnchecked: symptom.image_unchecked,
          occurrences: 0,
          selected: userSymptomsResponse.data.symptoms.some(s => s.symptom_id === symptom.id),
          type: symptom.typeIntensity === 'Intensidade' ? 'intensity' : 'episodes',
          cameFromApi: userSymptomsResponse.data.symptoms.some(s => s.symptom_id === symptom.id)
        })
      })

      setSymptoms(formattedSymptoms)
      return true
    } catch (err: any) {
      if (err?.response?.status === 401) {
        deleteTokenApi()
      }
      console.error(err)
      return false
    } finally {
      setLoading(false)
    }
  }

  async function sendSymptoms(): Promise<boolean> {
    setLoading(true)
    try {
      await api.post(`/cannect-cuida/send_symptom_answers`, {
        care_line_steps_tasks_id: 1,
        symptoms: formatSendSymptomsBody(symptoms)
      })

      return true
    } catch (err: any) {
      if (err?.response?.status === 401) {
        deleteTokenApi()
      }
      console.error(err)
      return false
    } finally {
      setLoading(false)
    }
  }

  function formatSendSymptomsBody(symptoms: Symptom[]): SendSymptomsBody[] {
    const formattedBody: SendSymptomsBody[] = []

    symptoms.forEach(symptom => {
      if (symptom.selected) {
        formattedBody.push({
          symptom_id: symptom.id,
          symptom_intensity: symptom.occurrences
        })
      }
    })

    return formattedBody
  }

  function toggleSymptomSelection(id: number) {
    setSymptoms(
      symptoms.map(symptom =>
        symptom.id === id
          ? {
              ...symptom,
              selected: !symptom.selected
            }
          : symptom
      )
    )
  }

  function addOtherSysmptom() {
    setOtherSymptoms([
      ...otherSymptoms,
      {
        id: parseInt(generateRandomNumber(), 10),
        label: '',
        imgChecked: '',
        imgUnchecked: '',
        selected: false,
        type: 'episodes',
        occurrences: 0,
        cameFromApi: false
      }
    ])
  }

  function removeOtherSysmptom(id: number) {
    setOtherSymptoms(otherSymptoms.filter(symptom => symptom.id !== id))
  }

  function changeOtherSymptomName(id: number, newName: string) {
    setOtherSymptoms(
      otherSymptoms.map(symptom =>
        symptom.id === id
          ? {
              ...symptom,
              label: newName
            }
          : symptom
      )
    )
  }

  function changeOtherSymptomType(id: number, newType: SymptomType) {
    setOtherSymptoms(
      otherSymptoms.map(symptom =>
        symptom.id === id
          ? {
              ...symptom,
              type: newType,
              occurrences: 0
            }
          : symptom
      )
    )
  }

  function changeSymptomOccurrences(id: number, newOccurrencesValue: number, isExistingSymptom: boolean) {
    if (isExistingSymptom) {
      setSymptoms(
        symptoms.map(symptom =>
          symptom.id === id
            ? {
                ...symptom,
                occurrences: newOccurrencesValue
              }
            : symptom
        )
      )
    } else {
      setOtherSymptoms(
        otherSymptoms.map(symptom =>
          symptom.id === id
            ? {
                ...symptom,
                occurrences: newOccurrencesValue
              }
            : symptom
        )
      )
    }
  }

  // Side Effects methods
  async function fetchSideEffects(): Promise<boolean> {
    setLoading(true)
    try {
      const sideEffectsResponse = await api.get<FetchSideEffectsResponse>(`/cannect-cuida/side_effects`)

      const formattedSideEffects: SideEffect[] = []
      sideEffectsResponse.data.side_effects.forEach(sideEffect => {
        formattedSideEffects.push({
          id: sideEffect.id,
          label: sideEffect.name,
          imgChecked: sideEffect.image_checked,
          imgUnchecked: sideEffect.image_unchecked,
          selected: false
        })
      })

      setSideEffects(formattedSideEffects)
      return true
    } catch (err: any) {
      if (err?.response?.status === 401) {
        deleteTokenApi()
      }
      console.error(err)
      return false
    } finally {
      setLoading(false)
    }
  }

  async function sendSideEffects(): Promise<boolean> {
    setLoading(true)
    try {
      await api.post(`/cannect-cuida/send_side_effects_answers`, {
        care_line_steps_tasks_id: 1,
        effects: formatSendSideEffectsBody(sideEffects)
      })
      clearCurrentSideEffects()

      return true
    } catch (err: any) {
      if (err?.response?.status === 401) {
        deleteTokenApi()
      }
      console.error(err)
      return false
    } finally {
      setLoading(false)
    }
  }

  function clearCurrentSideEffects() {
    setSideEffects(
      sideEffects.map(sideEffect => ({
        ...sideEffect,
        selected: false
      }))
    )
  }

  function formatSendSideEffectsBody(sideEffects: SideEffect[]): SendSideEffectsBody[] {
    const formattedBody: SendSideEffectsBody[] = []

    sideEffects.forEach(sideEffect => {
      if (sideEffect.selected) {
        formattedBody.push({
          side_effect_id: sideEffect.id
        })
      }
    })

    return formattedBody
  }

  function toggleSideEffectSelection(id: number) {
    setSideEffects(
      sideEffects.map(sideEffect =>
        sideEffect.id === id
          ? {
              ...sideEffect,
              selected: !sideEffect.selected
            }
          : sideEffect
      )
    )
  }

  return (
    <CannectCareContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        loading,
        path,
        setPath,
        step,
        setStep,
        lifeQualityQuestionsAnswers,
        qualityOfLife,
        setQualityOfLife,
        healthSatisfaction,
        setHealthSatisfaction,
        physicalPain,
        setPhysicalPain,
        medialTreatment,
        setMedialTreatment,
        enjoyLife,
        setEnjoyLife,
        lifeMakesSense,
        setLifeMakesSense,
        concentration,
        setConcentration,
        safety,
        setSafety,
        physicalEnvironment,
        setPhysicalEnvironment,
        energy,
        setEnergy,
        physicalAppearanceAcceptance,
        setPhysicalAppearanceAcceptance,
        moneyNeed,
        setMoneyNeed,
        informationAvailability,
        setInformationAvailability,
        leisureActivities,
        setLeisureActivities,
        locomotionAbility,
        setLocomotionAbility,
        sleepSatisfaction,
        setSleepSatisfaction,
        routineActivitiesCapability,
        setRoutineActivitiesCapability,
        workCapability,
        setWorkCapability,
        selfSatisfaction,
        setSelfSatisfaction,
        personalRelationship,
        setPersonalRelationship,
        sexualLife,
        setSexualLife,
        friendsSupport,
        setFriendsSupport,
        livingPlace,
        setLivingPlace,
        healthAccess,
        setHealthAccess,
        transportAccess,
        setTransportAccess,
        badFeelingsFrequency,
        setBadFeelingsFrequency,
        symptoms,
        setSymptoms,
        otherSymptoms,
        addOtherSysmptom,
        removeOtherSysmptom,
        changeOtherSymptomName,
        toggleSymptomSelection,
        changeSymptomOccurrences,
        changeOtherSymptomType,
        fetchSymptoms,
        sendSymptoms,
        fetchLifeQualityQuestions,
        fetchLifeQualityQuestionsAnswers,
        sendLifeQualityQuestions,
        fetchSideEffects,
        sendSideEffects,
        sideEffects,
        setSideEffects,
        otherSideEffects,
        setOtherSideEffects,
        toggleSideEffectSelection
      }}
    >
      {children}
    </CannectCareContext.Provider>
  )
}

export function useCannectCare(): CannectCareContextValues {
  return useContext(CannectCareContext)
}
