import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { FetchSelectOption } from 'new-components/FetchSelect'
import * as Styles from './styles'

interface DebouncedSearchInputProps {
  disabled: boolean
  hasError: boolean
  isOpen: boolean
  selectedOption?: FetchSelectOption
  placeholder?: string
  onInputChange: (value: string) => void
  onClick: () => void
}

const DebouncedSearchInput = forwardRef<any, DebouncedSearchInputProps>(
  ({ onInputChange, isOpen, selectedOption, placeholder, ...rest }, ref) => {
    const [search, setSearch] = useState('')

    const placeHolderProps = placeholder || 'Busque e selecione...'

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value)
    }

    useEffect(() => {
      const debounced = debounce(() => {
        onInputChange(search)
      }, 500)

      debounced()

      return () => {
        debounced.cancel()
      }
    }, [onInputChange, search])

    useEffect(() => {
      if (isOpen) {
        setSearch('')
        return
      }
      setSearch(selectedOption?.label ?? '')
    }, [isOpen])

    return (
      <Styles.FakeFetchSelect
        value={search}
        onChange={onChange}
        placeholder={placeHolderProps}
        autoComplete="off"
        ref={ref}
        {...rest}
      />
    )
  }
)

export default DebouncedSearchInput
