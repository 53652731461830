import { useEffect, useState } from 'react'
import { PatientListTypes } from 'hooks/useCannectAssistant/types'
import { BiLogInCircle } from 'react-icons/bi'
import When from 'utils/when'
import Pagination from 'components/Pagination'
import noop from 'lodash/noop'
import { HiddenCpfDigits } from 'utils/formatters'
import { Input } from 'antd'
import * as Styles from './styles'
import { toast } from 'react-toastify'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { debounce } from 'lodash'

export interface PatientProps {
  onClick?: (document: string) => void
}
interface TGetPatientListParams {
  limit?: number
  page?: number
  search?: string
}

export function PatientList({ onClick = noop }: PatientProps) {
  const { getPatientList } = useCannectAssistant()

  const [isLoadingSearch, setLoadingSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [pagePatientList, setPagePatientList] = useState(1)
  const [patientList, setPatientList] = useState({ count: 0, data: [] as PatientListTypes[], countPage: 0 })

  useEffect(() => {
    if (pagePatientList === 1 && searchTerm.length >= 3) return
    fetchPatient({ page: pagePatientList })
  }, [pagePatientList])

  const fetchPatient = async (params?: TGetPatientListParams) => {
    const data = await getPatientList({ ...params })
    if (data.success) {
      const countPage = Math.ceil(data.patients.count / 6)
      setPagePatientList(params?.page || 1)
      setPatientList({ countPage, count: data.patients.count, data: data.patients.rows })
    } else {
      toast.error(data?.message)
    }
  }

  const performSearch = debounce(async (term: string) => {
    if (term.length < 3) return
    setLoadingSearch(true)

    try {
      const data = await getPatientList({ search: term, page: 1 })

      if (data.success) {
        const countPage = Math.ceil(data.patients.count / 6)
        setPagePatientList(1)
        setPatientList({
          countPage,
          count: data.patients.count,
          data: data.patients.rows
        })
      } else {
        toast.error(data?.message)
      }
    } finally {
      setLoadingSearch(false)
    }
  }, 500)

  const handleSearchChange = (value: string) => {
    setSearchTerm(value)
    if (value.length === 0) {
      fetchPatient()
    } else {
      performSearch(value)
    }
  }

  const formatCountText = patientList?.count
    ? `${patientList?.data?.length} de ${patientList?.count} resultados`
    : '0 resultados'

  useEffect(() => {
    fetchPatient()
  }, [])

  return (
    <Styles.Wrapper>
      <Styles.Header>
        <Styles.TitleBox>
          <Styles.Title>Meus pacientes</Styles.Title>
          <Styles.Count>{formatCountText}</Styles.Count>
        </Styles.TitleBox>
        <Styles.SearchBox>
          <Input.Search loading={isLoadingSearch} allowClear onChange={(e) => handleSearchChange(e.target.value)} />
        </Styles.SearchBox>
      </Styles.Header>

      <Styles.ListContent>
        <When expr={patientList?.data.length > 0}>
          {patientList?.data?.map((item) => (
            <Styles.CardItem key={item?.id}>
              <Styles.CardContent>
                <Styles.CardName>{item?.name}</Styles.CardName>
                <Styles.CardDocument>{HiddenCpfDigits(item?.document)}</Styles.CardDocument>
              </Styles.CardContent>

              <Styles.CardIcon>
                <BiLogInCircle onClick={() => onClick(item?.document)} />
              </Styles.CardIcon>
            </Styles.CardItem>
          ))}
        </When>

        <When expr={patientList?.data?.length === 0}>
          <Styles.MessageError>
            <strong>Nenhum registro de paciente encontrado.</strong>
            Após sua primeira prescrição, os detalhes dos pacientes serão exibidos aqui.
          </Styles.MessageError>
        </When>
      </Styles.ListContent>

      <Styles.PaginateBox>
        <When expr={patientList?.countPage > 1}>
          <Pagination
            forcePage={pagePatientList - 1}
            onPageChange={({ selected }: any) => setPagePatientList(selected + 1)}
            pageCount={patientList?.countPage}
          />
        </When>
      </Styles.PaginateBox>
    </Styles.Wrapper>
  )
}
