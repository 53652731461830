import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

interface GridProps {
  justifySpace?: boolean
}

export const Wrapper = styled.main`
  width: 100%;
  margin-top: 90px;
`

export const GridProductList = styled.div<GridProps>`
  ${({ justifySpace }) => css`
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: ${justifySpace ? 'space-between' : 'flex-start'};
    flex-wrap: wrap;
    gap: ${temporaryTheme.spacing.space5};

    @media (${MOBILE_WIDTH}) {
      justify-content: center;
    }
  `}
`
