export const acceptDefaults = {
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'text/csv': ['.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'video/mp4': ['.mp4'],
  'application/zip': ['.zip'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/plain': ['.txt']
}

export type TAcceptDefaults = typeof acceptDefaults

export type TAcceptFormats = keyof TAcceptDefaults

export type TFormats =
  | '.pdf'
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.csv'
  | '.xlsx'
  | '.mp4'
  | '.zip'
  | '.doc'
  | '.docx'
  | '.txt'

export type TFileItem = {
  id?: number | string
  file?: File
  name?: string
  size: number
  type: TAcceptFormats
  file_url?: string
  createdAt?: string
}

export type TFileItemProps = {
  fileItem: TFileItem
  download?: boolean
  view?: boolean
  remove?: boolean
  dashed?: boolean
  animate?: boolean
  onRemove?(): void
}
