import React from 'react'
import { emptyNoContent } from 'assets/img'
import NoProductState from 'assets/gifs/no-product.gif'
import { Container, ContainerEmpty } from './styles'
import Text from '../Text'

interface EmptyStateTypes {
  title?: string
  subTitle?: string
  withAnimation?: boolean
}

function EmptyState({ title, subTitle, withAnimation }: EmptyStateTypes) {
  return (
    <Container>
      <ContainerEmpty>
        {withAnimation ? (
          <img src={NoProductState} className="animationState" alt="Empty no Content Animation" />
        ) : (
          <img src={emptyNoContent} width="50px" alt="Empty No Content" />
        )}
        <Text type="lg" color="sitePrimary" bold>
          {title}
        </Text>
        <Text type="sml" color="sitePrimary">
          {subTitle}
        </Text>
      </ContainerEmpty>
    </Container>
  )
}

export default EmptyState
