import styled, { css } from 'styled-components'
import { ButtonAnimatedProps } from '.'

type WrapperProps = Pick<ButtonAnimatedProps, 'bgColor'>

export const Wrapper = styled.div<WrapperProps>`
  ${({ bgColor }) => css`
    width: auto;
    button {
      background: ${bgColor};
      border: none;
      outline: none;
    }
  `}
`
