/* eslint-disable jsx-a11y/label-has-associated-control */
import { forwardRef, ElementRef, ComponentPropsWithoutRef } from 'react'
import { Typography } from '../Typography'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn } from '@cannect/lib/utils'
import { LuCheck } from 'react-icons/lu'
import { tv, VariantProps } from 'tailwind-variants'

const checkboxVariants = tv({
  slots: {
    container: 'flex w-full flex-col',
    wrapper: 'flex items-center gap-2',
    checkboxRoot:
      'data-[state=checked]:bg-gray50 peer shrink-0 rounded-md border-2 border-solid border-primary-700 bg-white outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:text-primary-700',
    iconIndicator: '',
    label: 'font-sans font-medium leading-none text-current peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
  },
  variants: {
    size: {
      md: {
        checkboxRoot: 'h-6 w-6',
        iconIndicator: 'h-4 w-4',
        label: 'text-base'
      },
      sm: {
        checkboxRoot: 'border-1 h-5 w-5',
        iconIndicator: 'h-3 w-3',
        label: 'text-[13px] font-normal'
      }
    },
    labelColor: {
      dark: {
        label: 'text-gray-700'
      },
      light: {
        label: 'text-gray-50'
      }
    },
    justify: {
      start: {
        wrapper: 'justify-start'
      },
      between: {
        wrapper: 'justify-between'
      }
    }
  },
  defaultVariants: {
    size: 'md',
    labelColor: 'dark',
    justify: 'start'
  }
})
type TCheckboxProps = {
  label: string
  message?: string
  isInvalid?: boolean
  inverted?: boolean
} & VariantProps<typeof checkboxVariants>

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  Omit<ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>, 'onChange'> & TCheckboxProps
>(({ className, label, isInvalid, message, size, labelColor, inverted = false, justify, ...props }, ref) => {
  const {
    container,
    wrapper,
    checkboxRoot,
    iconIndicator,
    label: labelClass
  } = checkboxVariants({
    size,
    labelColor,
    className,
    justify
  })

  return (
    <div className={container()}>
      <div className={wrapper()}>
        {inverted && <label className={labelClass()}>{label}</label>}
        <CheckboxPrimitive.Root ref={ref} className={checkboxRoot()} {...props}>
          <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
            <LuCheck className={iconIndicator()} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {!inverted && <label className={labelClass()}>{label}</label>}
      </div>

      {message && (
        <div className="w-full">
          <Typography.Text
            type="captionOne"
            weight="medium"
            className={`${isInvalid ? 'text-red-700' : 'text-gray-700'} mt-1 text-left text-sm`}>
            {message}
          </Typography.Text>
        </div>
      )}
    </div>
  )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
