import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;

  .detalhes {
    display: flex;
    align-items: center;
    p {
      margin-left: 24px;
    }
    img {
      width: 80px;
    }
  }
`
