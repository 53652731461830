import styled, { css } from 'styled-components'
import { theme } from 'design-cannect'
import { FaCheck } from 'react-icons/fa'
import { InputHTMLAttributes } from 'react'

export const Container = styled('div')<{ wrap?: boolean }>`
  ${({ wrap }) => css`
    display: flex;
    margin: 0 10px 10px 10px;
    align-items: flex-start;
    font-weight: 300;
    min-height: 23px;

    div.label {
      margin-left: 7px;
      display: flex;
      white-space: ${wrap ? 'normal' : 'nowrap'};
    }

    p {
      margin-bottom: 10px;
    }
  `}
`

const CheckboxContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  border-radius: 5px;
  border: 2px solid ${theme.colors.tertiary};
  cursor: ${props => !props.disabled && ' pointer'};
  margin-top: 3px;

  svg {
    margin-left: 1px;
    color: ${theme.colors.tertiary};
  }
`

export interface CheckboxType extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean
  onClick: () => void
  label: string
  wrap?: boolean
}

function Checkbox(props: CheckboxType) {
  const { checked, label, onClick, wrap = true, ...rest } = props

  return (
    <Container wrap={wrap} {...rest}>
      <CheckboxContainer onClick={onClick}>{checked ? <FaCheck /> : null}</CheckboxContainer>
      <div className="label">{label}</div>
    </Container>
  )
}

export default Checkbox
