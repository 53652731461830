import React, { useState } from 'react'
import { Button, ButtonProps } from '@cannect/new-components/atoms/Button'
import { motion } from 'framer-motion'
import { cn } from '@cannect/lib/utils'

type TCopyButton = ButtonProps & {
  textToCopy?: string
  copiedMessage?: string
}

export const CopyButton = React.forwardRef<HTMLButtonElement, TCopyButton>(
  ({ textToCopy, copiedMessage = 'Código copiado!', onClick, children, ...buttonProps }, ref) => {
    const [isCopied, setIsCopied] = useState(false)

    const handleCopy = async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (isCopied || !textToCopy) return

      try {
        await navigator.clipboard.writeText(textToCopy)
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 2000)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Erro ao copiar o texto: ', err)
      }

      if (onClick) {
        onClick(event)
      }
    }

    return (
      <Button
        {...buttonProps}
        ref={ref}
        onClick={handleCopy}
        className={cn(buttonProps.className, `${isCopied ? 'bg-primary-700' : ''} select-none`)}>
        <motion.span
          className="select-none font-sans"
          key={isCopied ? 'copied' : 'default'}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}>
          {isCopied ? copiedMessage : children}
        </motion.span>
      </Button>
    )
  }
)

CopyButton.displayName = 'CopyButton'
