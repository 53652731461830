import { SVGProps } from 'react'

export const ShoppingBagIcon = ({ width = '32', height = '32', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.66675 11.3334C6.66675 10.2288 7.56218 9.33337 8.66675 9.33337H23.3334C24.438 9.33337 25.3334 10.2288 25.3334 11.3334V25C25.3334 26.6569 23.9903 28 22.3334 28H9.66675C8.00989 28 6.66675 26.6569 6.66675 25V11.3334Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 13.3333V7C20 5.34315 18.6569 4 17 4H15C13.3431 4 12 5.34314 12 7V13.3333"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
