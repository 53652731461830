import { Button, Image } from 'antd'
import { CERTIFICATE, RG_ICON, RG_ICON2 } from 'assets/svg/documents'

import { Content, Heading, TextDocument, ImageExempleBox, HorizontalContent, Box, AcceptTypeFiles } from './styles'

export function TipResponsible() {
  return (
    <Content>
      <Heading>Comprovante de vínculo ou dependente</Heading>

      <HorizontalContent>
        <Box>
          <TextDocument>
            <strong>1. Relação entre pais e filhos</strong>
            <br />
            RG/CNH do responsável.
            <br /> <br />
            <strong>2. Relação entre Cônjuges/relações estáveis:</strong>
            <br />
            certidão de casamento + RG do titular do comprovante.
            <br /> <br />
            <strong>3. Relações entre Avós e netos:</strong>
            <br />
            certidão de nascimentos dos netos + documentos de identidades dos avós.
            <br /> <br />
          </TextDocument>
        </Box>

        <Box>
          <ImageExempleBox>
            <Image src={RG_ICON} alt="imagem exemplo do envio de documento." preview={false} width={200} />
            <Image src={CERTIFICATE} alt="imagem exemplo do envio de documento." preview={false} width={200} />
          </ImageExempleBox>
        </Box>
      </HorizontalContent>
      <AcceptTypeFiles>
        Formatos permitidos: <strong>jpg, png, jpeg e pdf.</strong>
      </AcceptTypeFiles>
    </Content>
  )
}
