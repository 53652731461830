import { theme } from 'design-cannect'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;

  input {
    /* width: 100%; */
    margin: 15px 0px;
    min-height: 48px;
    border-radius: ${theme.spacing.space2};
    padding: 2px 20px;
    border: 2px solid ${theme.colors.tertiary};
    color: ${theme.colors.tertiary};
    font-weight: 300;
    min-width: 210px;
    width: 100%;

    &:focus {
      /* outline: none; */
      border-color: ${theme.colors.secondary};
      transition: all 100ms ease;
    }

    &:-webkit-autofill {
      background: -internal-light-dark(black, white) !important;
    }

    ::placeholder {
      color: ${theme.colors.tertiary};
      font-weight: lighter;
    }
  }

  button {
    width: 35%;
    margin-left: auto;
  }
`
export const StatusMessage = styled.div<any>`
  width: 100%;
  height: 40px;
  font-size: ${theme.fontSize.md};
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  ${props => {
    if (props.status) {
      return css`
        background-color: rgba(255, 0, 0, 0.4);
        color: ${theme.colors.tertiary};
      `
    }
    return css`
      display: none;
    `
  }};
`
