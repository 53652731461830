import { media } from 'helpers/mediaQueryStyle'
import styled, { css } from 'styled-components'
import { TextDangerProps } from '.'
import { temporaryTheme } from '../../../styles/theme'

type TextDangerStyleProps = Pick<
  TextDangerProps,
  'width' | 'withMaxLengthText' | 'maxLineText' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'justify' | 'color'
>

const textDangerStylesModifier = {
  withMaxLengthText: (maxLineText?: number) => css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${maxLineText || 7};
    -webkit-box-orient: vertical;
  `
}

export const Container = styled.div<TextDangerStyleProps>`
  ${({
    width,
    withMaxLengthText,
    maxLineText,
    fontSize = '16px',
    fontFamily = 'Nunito',
    fontWeight = 400,
    justify,
    color
  }) => css`
    width: ${width};
    color: ${color || '#706f6f'};
    text-align: ${justify};
    line-height: 1.5;
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    font-family: ${fontFamily}, sans-serif;
    strong {
      font-weight: 700;

      color: ${color || temporaryTheme.colors.darkGrey};
    }

    p {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: ${temporaryTheme.colors.darkGrey};
    }

    margin-bottom: ${temporaryTheme.spacing.space4};

    * {
      font-weight: ${fontWeight};
      font-family: ${fontFamily}, sans-serif;
    }

    ${media.tablet`
      width: 100%;
    `}

    ${media.mobile`
      flex-direction: column;
      font-size: 14px;
      width: 100%;

      * {
        font-size: 14px;
      }
    `}

    a {
      font-weight: inherit;
      font-family: inherit;
      color: ${temporaryTheme.colors.link};
      cursor: pointer;
      &:hover {
        color: ${temporaryTheme.colors.linkHover};
      }
    }

    ${!!withMaxLengthText && textDangerStylesModifier.withMaxLengthText(maxLineText)}
  `}
`
