import styled, { css } from 'styled-components'
import { theme } from 'design-cannect'

export type InputProps = {
  width?: string
}

export type WrapperProps = {
  outlined?: boolean
}

export const ContainerInput = styled('div')<InputProps>`
  ${({ width }) => css`
    width: ${width || 'auto'};
    min-height: 47px;
    position: relative;
    .wrapper-icon {
      position: absolute;
      transition: transform 0.3s;
      right: 16px;
      top: 16px;
      padding-left: ${theme.spacing.space3};
      color: ${theme.colors.black};
    }
  `}
`

export const WrapperInput = styled('input')<WrapperProps>`
  ${({ outlined }) => css`
    border-radius: ${theme.spacing.space2};
    padding: 0 ${theme.spacing.space7} 0 ${theme.spacing.space3};
    border: ${outlined ? `2px solid #115850` : '1px solid #115850'};
    min-height: 48px;
    box-shadow: 0 0 0 0.2px inset;
    color: #115850;
    font-weight: 300;
    width: 100%;

    &:focus {
      outline: none;
      background: ${theme.colors.white};
      border-color: ${theme.colors.secondary};
      box-shadow: 0 0 2px ${theme.colors.secondary};
    }

    &:-webkit-autofill {
      background: -internal-light-dark(black, white) !important;
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.primary} inset;
      -webkit-text-fill-color: ${theme.colors.primary}} !important;
    }

    ::placeholder {
      color: ${theme.colors.tertiary};
      font-weight: lighter;
    }
  `}
`
