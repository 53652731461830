import styled from 'styled-components'

export const NeedHelpContainer = styled.div`
  display: flex;
`

export const InputsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
