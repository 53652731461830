/* eslint-disable consistent-return */
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useSendAnvisaDocumentMutation } from '@cannect/services/resources/digitalJourney'
import { useHistory } from 'react-router'
import { TDigitalJourneyFormData } from '../../types'
import { useForm } from 'react-hook-form'
import { DIGITAL_JOURNEY_ROUTES, FORM_SCHEMAS } from '../../constants'
import { zodResolver } from '@hookform/resolvers/zod'

type TFormFields = TDigitalJourneyFormData['send_anvisa_authorization']
export const useSendAnvisaAuthorizationStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()
  const sendAnvisaDocumentMutation = useSendAnvisaDocumentMutation()

  const sendAnvisaDocumentForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.send_anvisa_authorization
    },
    resolver: zodResolver(FORM_SCHEMAS.send_anvisa_authorization)
  })

  const onSubmit = (data: TFormFields) => {
    if (!formData.common_fields?.digital_journey_id) return

    return sendAnvisaDocumentMutation.mutateAsync(
      {
        digital_journey_id: formData.common_fields?.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            common_fields: {
              ...formData.common_fields,
              status: 'finished'
            },
            send_anvisa_authorization: {
              anvisa_authorization_file: [
                ...(response?.form_data?.send_anvisa_authorization?.anvisa_authorization_file || [])
              ]
            }
          })

          history.push(DIGITAL_JOURNEY_ROUTES.journey_finished)
        }
      }
    )
  }

  return {
    onSubmit,
    sendAnvisaDocumentForm
  }
}
