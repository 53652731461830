import * as TabRadix from '@radix-ui/react-tabs'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  min-height: 650px;
`

export const TabRoot = styled(TabRadix.Root)`
  display: flex;
  flex-direction: column;
`

export const TabList = styled(TabRadix.List)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TabTrigger = styled(TabRadix.Trigger)``

export const ButtonBase = styled.button`
  all: unset;
  padding: 11px 22px;
  cursor: pointer;
  font-family: 'Spartan', sans-serif;
  color: #3d3d3d;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`

export const TabButtonprimary = styled(ButtonBase)`
  border: 1.1085px solid #3d3d3d;
  border-radius: 2.93059px 0px 0px 2.93059px;
`
export const TabButtonsecondary = styled(ButtonBase)`
  border: 1.1085px solid #3d3d3d;
  border-radius: 0px 2.93059px 2.93059px 0px;
`
export const ContentContainer = styled.div`
  width: 100%;
  padding: 50px 31px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const TabContent = styled(TabRadix.Content)`
  width: 100%;
`

export const Description = styled.div`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: ${temporaryTheme.colors.darkGrey};
  background: #fff;
  width: 100%;
  margin-bottom: 45px;
  border: 1px solid #B5B3B3;
  border-radius: 8px;
  padding: 20px;
`

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`
export const TextInfo = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #433F3E;

  span {
    margin-left: 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #433F3E;
  }
`

export const ViewPdf = styled.iframe`
  width: 100%;
  min-width: 100%;
  min-height: 650px;
`
