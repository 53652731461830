/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ZodIssue } from 'zod'
import { FIELDS_LABELS } from './constants'

export const getFieldError = (fieldName: string, errors?: ZodIssue[]) => {
  if (!errors) return undefined

  const fieldError = errors.find(error => error.path.includes(fieldName))
  if (!fieldError) return undefined

  return getErrorMessage(fieldError.code, fieldError.message)
}

export const getErrorMessage = (code: ZodIssue['code'], message: string) => {
  switch (code) {
    case 'invalid_type':
      return 'Campo obrigatório'
    case 'too_small':
      return getTooSmallOrTooBigMessage(message)
    case 'invalid_string':
      return getInvalidStringMessage(message)
    default:
      return getErrorThroughMessageAttribute(message)
  }
}

export const getTooSmallOrTooBigMessage = (message: string) => {
  if (message.includes('String must contain at least')) return 'Campo obrigatório'
  if (message === 'Too small' || message === 'Too big') return 'O campo deve conter entre 8 e 16 caracteres'
  return 'Erro no campo'
}

export const getInvalidStringMessage = (message: string) => {
  switch (message) {
    case 'Invalid email':
      return 'Por favor, digite um e-mail válido'
    case 'One uppercase character':
      return 'Insira pelo menos 1 letra maiúscula'
    case 'One lowercase character':
      return 'Insira pelo menos 1 letra minúscula'
    case 'One number':
      return 'Insira pelo menos 1 número'
    case 'One special character':
      return 'Insira pelo menos 1 caractere especial'
    default:
      return 'Erro no campo'
  }
}

export const getErrorThroughMessageAttribute = (message: string) => {
  switch (message) {
    case 'Field is required':
      return 'Campo obrigatório'
    case 'CPF is invalid':
      return 'CPF inválido'
    case 'Passwords must be equals':
      return 'As senhas não coincidem'
    case 'Emails must be equals':
      return 'Os e-mails não coincidem'
    case 'Invalid date':
      return 'Data de nascimento inválida'
    default:
      return 'Erro no campo'
  }
}

export const getFieldsWithErrorsMessage = (errors: ZodIssue[]) => {
  let message = 'Os seguintes campos estão inválidos: '
  const uniqueFieldsWithErrors = [...new Set(errors.map(error => error.path[0]))]

  uniqueFieldsWithErrors.forEach((field, index) => {
    if (index === uniqueFieldsWithErrors.length - 1) {
      // @ts-ignore
      message += FIELDS_LABELS[field]
    } else {
      // @ts-ignore
      message += `${FIELDS_LABELS[field]}, `
    }
  })

  return message
}
