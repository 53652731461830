import styled, { css } from 'styled-components'

interface TagProps {
  variant?: 'positive' | 'negative' | 'warning' | 'info'
}

const colorVariant = {
  positive: () => css`
    background-color: #b8dcb8;
    color: #0f5132;
  `,
  negative: () => css`
    background-color: #efc6c6;
    color: #9f0b26;
  `,
  warning: () => css`
    background-color: #f1e8c9;
    color: #735e0d;
  `,
  info: () => css`
    background-color: #d1e7f3;
    color: #1a699f;
  `
}
export const Container = styled.div<TagProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;

  .tag {
    width: fit-content;
    height: 30px;
    font-size: 13px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    ${({ variant }) => colorVariant[variant || 'info']};
    border-radius: 4px;
    font-weight: 500;

    p {
      margin-right: 16px;
      color: inherit;
    }
    svg {
      margin-left: 16px;
    }
  }

  .action-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
