import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const SpanDropdown = styled.span`
  width: 100%;
  background: rgb(231 231 231);
  padding: 4px 12px;
  /*   border-radius: 4px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  color: #3d3d3d;
  justify-content: space-between;
  border-bottom-left-radius: 8px;

  border-bottom-right-radius: 8px;

  p {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`

export const MenuItem = styled.div`
  /* width: 391px; */
  display: flex;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
`

export const NameFile = styled.div`
  flex: 1;
`
