/* eslint-disable import/no-duplicates */
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

/**
 * Formats a date string in Brazilian date format.
 * @param dateString - The date string to format (in ISO 8601 format).
 * @returns The formatted date string in the format "Dia da semana, DD/MM".
 */
export function formatBrazilianDate(dateString: string | null): string {
  const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

  if (!dateString) return ''
  const date = new Date(dateString)
  const dayOfWeek = days[date.getUTCDay()]
  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = months[date.getUTCMonth()]

  return `${dayOfWeek}, ${day}/${month}`
}

/**
 * Formats a date string in Brazilian date format and capitalizes the first letter of the weekday.
 * @param dateString - The date string to format (in ISO 8601 format).
 * @returns The formatted date string with the first letter of the weekday capitalized in the format "Dia da semana, DD/MM".
 */
export function formatAndCapitalizeDate(dateString: string | null): string {
  if (!dateString) return ''
  const parsedDate = parseISO(dateString || '')
  const formattedDate = format(parsedDate, 'EEEE, dd/MM', { locale: ptBR })
  const capitalizedFormattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
  return capitalizedFormattedDate
}
