import Button from 'new-components/Button'
import { useQueries } from 'pages/MyQueries/hooks/useQueries'
import { useValidations } from 'pages/MyQueries/hooks/useValidations'
import When from 'utils/when'
import { ButtonAnimated } from './ButtonAnimated'
import * as Styles from './styles'

interface CancelProps {
  date: string
  hour: string
  onReschedule?: () => void
  onCancel?: () => void
  onClose?: () => void
}

export function CancelWithin24hours({ date, hour, onReschedule, onCancel, onClose }: CancelProps) {
  const { isWithinByHours } = useValidations()
  const { loadingConsult } = useQueries()

  return (
    <Styles.Wrapper>
      <Styles.Heading>Parece que você deseja cancelar a consulta.</Styles.Heading>

      <When expr={isWithinByHours(date, hour, 6).within}>
        <Styles.Description>
          Faltam <strong> menos de 24h </strong> para a consulta, <strong> o valor não será estornado. </strong>
        </Styles.Description>
        <Styles.ActionsBox>
          <p>Como deseja prosseguir?</p>

          <div className="content_button">
            <ButtonAnimated
              bgColor="#E24444"
              text="Cancelar a consulta"
              textLoading="Cancelando a consulta..."
              isLoading={loadingConsult.loadCancel}
              disabled={loadingConsult.loadCancel}
              onClick={onCancel}
            />
            <Button background="black" onClick={onClose}>
              Manter o agendamento
            </Button>
          </div>
        </Styles.ActionsBox>
      </When>
      <When expr={!isWithinByHours(date, hour, 6).within}>
        <Styles.Description>
          Faltam <strong> menos de 24h </strong> para a consulta, o valor{' '}
          <strong>não será estornado. Porém, você pode reagendar sua consulta de forma gratuita.</strong>
        </Styles.Description>
        <Styles.ActionsBox>
          <p>Como deseja prosseguir?</p>

          <div className="content_button">
            <ButtonAnimated
              bgColor="#E24444"
              text="Cancelar a consulta"
              textLoading="Cancelando a consulta..."
              isLoading={loadingConsult.loadCancel}
              disabled={loadingConsult.loadCancel}
              onClick={onCancel}
            />

            <Button background="green" onClick={onReschedule}>
              Reagendar a consulta
            </Button>
          </div>
        </Styles.ActionsBox>
      </When>
    </Styles.Wrapper>
  )
}
