import styled from 'styled-components'

export const ProgressBarContainer = styled.div`
  height: 40px;
  min-height: 40px;
  width: 100%;

  border-radius: 0.5rem;
  background: #c4c4c4;
  margin-bottom: 1.5rem;
`

export const CurrentProgress = styled.div`
  height: 40px;
  min-height: 40px;
  width: 50px;

  border-radius: 0.5rem;
`
