import styled from 'styled-components'
import { theme } from 'design-cannect'

interface LinkProps {
  underline?: boolean
}

export const TextLink = styled.div<LinkProps>`
  font-family: 'Segoe UI';

  a {
    text-decoration: none;
    display: flex;
    cursor: pointer;

    svg {
      margin-left: ${theme.spacing.space2};
      color: ${theme.colors.tertiary};
    }
  }
`
