/* eslint-disable jsx-a11y/label-has-associated-control */
import { getBrazilianFormatDate } from 'utils/formatters'
import * as Styles from './styles'

interface PaymentStatusCardProps {
  status: string
  color: string
  createdAt: string
}

export default function PaymentStatusCard({ status, color, createdAt }: PaymentStatusCardProps) {
  return (
    <Styles.PaymentStatusCardContainer>
      <Styles.PaymentStatusWrapper>
        <label>Status do pagamento:</label>
        <Styles.PaymentStatusSpan color={color}>{status}</Styles.PaymentStatusSpan>
      </Styles.PaymentStatusWrapper>
      <span>Data da compra: {getBrazilianFormatDate(createdAt)}</span>
    </Styles.PaymentStatusCardContainer>
  )
}
