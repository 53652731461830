import { IStrapiPostSimiliar } from 'dtos/Posts'
import { Card } from 'new-components/blogComponents/Card'
import { Link, useParams } from 'react-router-dom'
import * as Styles from './styles'
import { regexInContent } from '@cannect/pages/NewBlog/Base'

interface ISimiliarPost {
  posts: IStrapiPostSimiliar[]
}

interface PostContentParams {
  category: string
}

function SimiliarPost({ posts }: ISimiliarPost) {
  const { category }: PostContentParams = useParams()

  return (
    <Styles.Container>
      <Styles.Heading>Talvez você goste</Styles.Heading>
      <Styles.ContainerNews>
        {posts?.map(post => {
          return (
            <Link to={`/blog/${category}/${post.attributes.slug}`}>
              <Card
                key={post.id}
                // eslint-disable-next-line prettier/prettier
                image={`${import.meta.env.VITE_APP_STRAPI_URL}${
                  post.attributes.image.data?.attributes?.formats?.large?.url ||
                  post.attributes.image.data?.attributes?.formats?.thumbnail?.url
                  // eslint-disable-next-line prettier/prettier
                }`}
                title={post.attributes.title}
                text={regexInContent(post.attributes.content)}
              />
            </Link>
          )
        })}
      </Styles.ContainerNews>
    </Styles.Container>
  )
}

export default SimiliarPost