
interface PriceSpanProps {
  value: number
  type: 'USD' | 'BRL'
  fontSize?: `${number}rem`
}

export default function PriceSpan({ value, type, fontSize = '1rem' }: PriceSpanProps) {
  const commonStyles = {
    fontSize,
    fontWeight: 600
  }

  return (
    <span style={type === 'USD' ? { color: 'black', ...commonStyles } : { color: 'black', ...commonStyles }}>
      {new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: type
      }).format(value)}
    </span>
  )
}
