import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  min-height: 100vh;
`

export const Heading = styled.h1`
  font-weight: 600;
  font-size: ${temporaryTheme.fontSize.h2};
  font-family: 'Spartan', sans-serif;
  color: ${temporaryTheme.colors.dark};
  margin: ${temporaryTheme.spacing.space8} 0 ${temporaryTheme.spacing.space9} 0;

  span {
    color: ${temporaryTheme.colors.sitePrimary};
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const ContainerBrands = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${temporaryTheme.spacing.space9};
`
export const BoxBrands = styled.div`
  filter: grayscale(1);
  transition: 0.5s;
  cursor: pointer;
  max-width: 210px;
  &:hover {
    filter: grayscale(0);
    transform: scale(1.05);
  }
`
export const BrandsImage = styled.img`
  width: 100%;
  object-fit: cover;
  transition: 0.5s;
`
