import styled from 'styled-components'

export const HelloMessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  width: 100%;
  font-size: 1.5rem;
  margin-bottom: 3rem;
`

export const ImageWithChangeButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProfileImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.25rem;
  min-width: 3.25rem;
  height: 3.25rem;
  min-height: 3.25rem;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 999px;
  transition: 0.3s;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    aspect-ratio: auto;
    border-radius: 999px;
  }
`

export const ChangeImageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  color: #3d3d3d;
  font-size: 0.65rem;

  background: #fff;
  padding: 0.25rem;
  border-radius: 999px;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  margin-top: -0.5rem;
  z-index: 1;

  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
