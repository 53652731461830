import { semImagem } from 'assets/svg'
import { PrescriberInfoTypes } from './PrescriberInfo'

export interface PrescriberApiTypes {
  expertises: PrescriberExpertises[]
  address: string
  biography: string
  council: string
  cpf: string
  createdAt: string
  email: string
  id: number
  listed_prescriber: boolean
  name: string
  nr_council: string
  phone: string
  prescriber: boolean
  registration_type: number
  uf_concil: string
  updatedAt: string
  health_insurance: boolean
  presencial_appointment: boolean
  presencial_appointment_value: string
  online_appointment: boolean
  photo: string
}

export interface PrescriberExpertises {
  id: string
  name: string
}

function formatApiData(data: PrescriberApiTypes): PrescriberInfoTypes {
  Object.assign(data, {
    // photo: semImagem
  })

  let serviceType: PrescriberInfoTypes['serviceType']
  if (data.presencial_appointment && data.online_appointment) serviceType = 'Presencial/Telemedicina'
  else if (data.presencial_appointment) serviceType = 'Presencial'
  else serviceType = 'Telemedicina'

  return {
    about: data.biography,
    fullName: data.name,
    price: data.presencial_appointment_value,
    imgSrc: data.photo || semImagem,
    occupation: data.expertises,
    whatsapp: data.phone,
    crm: data.council,
    healthInsurance: data.health_insurance,
    serviceType,
    crmNumber: data.nr_council,
    id: data.id
  }
}

export default formatApiData
