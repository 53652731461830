import { Modal } from 'components/Modal'
import React from 'react'

interface ModalFilterProps {
  isOpenFilterModal: boolean
  children: React.ReactNode
}

export default function ModalFilter({ isOpenFilterModal, children }: ModalFilterProps) {
  return (
    <Modal
      isOpened={isOpenFilterModal}
      onRequestClose={() => {
        console.log('a')
      }}
      className="modal-produtos">
      {children}
    </Modal>
  )
}
