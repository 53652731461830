import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

interface AddressCardContainerProps {
  usage: 'professional' | 'patient'
}
export const AddressCardContainer = styled.div<AddressCardContainerProps>`
  display: flex;
  flex-direction: column;

  background: #e0e7ec;
  max-width: ${({ usage }) => (usage === 'professional' ? '24rem' : '30rem')};
  width: 100%;

  border-radius: 0.5rem;

  @media ${MOBILE_WIDTH} {
    max-width: 100%;
  }
`

export const AddressCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #bbbebd;

  svg {
    width: 1.75rem;
    min-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
  }
`

interface AddressCardBodyProps {
  useDivider: boolean
}
export const AddressCardBody = styled.div<AddressCardBodyProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 1rem 1.5rem;
  border-bottom: ${({ useDivider }) => (useDivider ? `1px solid #bbbebd` : 'none')};
`

export const AddressCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem 0;

  * {
    text-align: center;
    font-weight: 600;
  }
`
