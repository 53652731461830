import BreadCrumb from 'components/BreadCrumb'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import Loading from 'components/Loading'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { useState, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import * as Styles from './styles'
import { useGetStrapiBrands } from '@cannect/services/resources/strapi'

type TBrandDetailData = {
  about: string
  logo: { id: number; url: string }
  slug: string
  title: string
}

type TBrandItem = {
  id: number
  data: TBrandDetailData
}

function BrandsDetails() {
  const param = useParams() as { brandName?: string }
  const [modal, setModal] = useState(false)
  const history = useHistory()
  const { data, isLoading } = useGetStrapiBrands({
    filterQuery: `filters%5B$and%5D%5B0%5D%5Bslug%5D%5B$eq%5D=${param.brandName}&populate=logo`
  })

  const brandDetailItem: TBrandItem = useMemo(() => {
    const item = data?.data[0]
    const serializedItem = {
      id: item?.id || 0,
      data: {
        about: item?.attributes?.about || '',
        logo: {
          id: item?.attributes?.logo?.data?.id || 0,
          url: `${import.meta.env.VITE_APP_STRAPI_URL}${item?.attributes?.logo?.data?.attributes?.url}` || ''
        },
        slug: item?.attributes?.slug || '',
        title: item?.attributes?.title || ''
      }
    }
    return serializedItem
  }, [data])

  return (
    <Styles.Wrapper>
      <BreadCrumb
        paths={[
          { label: 'Marcas Parceiras', link: '/marcas' },
          { label: brandDetailItem.data.title || '', link: `/marcas/${param?.brandName}` }
        ]}
      />

      <Styles.HeadingBox>
        <Heading fontSize="40px" verticalSpace="xlarge">
          <span>Parceiros</span> Cannect
        </Heading>
        <Button background="green" onClick={() => history.goBack()}>
          Todas as marcas
        </Button>
      </Styles.HeadingBox>

      <Styles.BrandsDetailsContent loading={isLoading}>
        <Styles.BrandsCardDetails>
          <Styles.CardContent>
            <Styles.CardImage src={brandDetailItem.data.logo.url} alt={`logo of brand ${brandDetailItem.data.title}`} />
          </Styles.CardContent>
        </Styles.BrandsCardDetails>

        <Styles.CardDetails>
          <Styles.CardTitle>{brandDetailItem.data.title}</Styles.CardTitle>
          <Styles.CardText>
            <TextDanger text={brandDetailItem.data.about} fontWeight={500} />
          </Styles.CardText>

          <Styles.CardActions>
            <Button background="black" onClick={() => history.push('/agendamento')}>
              Agende sua consulta
            </Button>
            <Button isOutlined background="green" onClick={() => setModal(true)}>
              Inicie seu tratamento
            </Button>
          </Styles.CardActions>
        </Styles.CardDetails>
      </Styles.BrandsDetailsContent>

      {/*    <RelatedProduct titleSection="Produtos Relacionados:" limit={8} filters={`brand_id=${brandsItems.id}`} /> */}

      <ModalHome isOpen={modal} setOpenModal={setModal}>
        <BeginCheckout />
      </ModalHome>

      {!!isLoading && <Loading loading />}
    </Styles.Wrapper>
  )
}

export default BrandsDetails
