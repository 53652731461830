import { theme } from 'design-cannect'
import styled from 'styled-components'

export const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  max-width: 600px;

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.tertiary};
    text-align: center;
  }
`
