import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { InputField, SelectField } from '@cannect/new-components/molecules'
import { useConfirmProofOfAddressStep } from './useConfirmProofOfAddressStep'
import { BRAZIL_STATES } from '@cannect/pages/Registration/components/Forms/components/ValidationForm/constants'

export const ConfirmProofOfAddressStepUI = () => {
  const { confirmProofOfAddressForm, onSubmit, control, isSubmitting, handleSubmit, isLoadingAddress } =
    useConfirmProofOfAddressStep()

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex flex-col items-center gap-3">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Os dados estão certos?
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Você pode corrigir qualquer equívoco nos campos abaixo.
        </Typography.Text>
      </div>
      <FormController.Form {...confirmProofOfAddressForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 rounded bg-white p-4 shadow-md">
            <Typography.Text type="paragraphTwo" className="!mb-3 text-primary-900" weight="medium">
              Insira os dados nos campos abaixo:
            </Typography.Text>
            <InputField
              variant="filledLight"
              name="address_holder_full_name"
              label="Nome do titular"
              control={control}
            />
            <InputField
              variant="filledLight"
              name="address_holder_cpf"
              label="CPF do titular"
              control={control}
              mask="cpf"
            />
            <InputField
              variant="filledLight"
              name="address_cep"
              label="CEP"
              control={control}
              mask="cep"
              isLoading={isLoadingAddress}
            />
            <SelectField
              variant="outlined"
              name="address_state"
              label="Estado"
              control={control}
              options={BRAZIL_STATES}
              isLoading={isLoadingAddress}
            />
            <InputField
              variant="filledLight"
              name="address_city"
              label="Cidade"
              control={control}
              isLoading={isLoadingAddress}
            />
            <InputField
              variant="filledLight"
              name="address_neighborhood"
              label="Bairro"
              control={control}
              isLoading={isLoadingAddress}
            />
            <InputField
              variant="filledLight"
              name="address_street"
              label="Rua"
              control={control}
              isLoading={isLoadingAddress}
            />
            <InputField
              variant="filledLight"
              name="address_number"
              label="Número"
              control={control}
              mask="onlyNumbers"
            />
            <InputField variant="filledLight" name="address_complement" label="Complemento" control={control} />
          </div>
          <Button className="mt-8 font-medium" full type="submit" isLoading={isSubmitting} size="lg">
            Confirmar
          </Button>
        </form>
      </FormController.Form>
    </div>
  )
}
