import { ButtonHTMLAttributes } from 'react'
import * as Style from './styles'

interface ButtonProps {
  outlined?: boolean
  isThinButton?: boolean
  width?: `${number}%` | `${number}rem` | `${number}px`
  alignSelf?: 'center' | 'flex-start' | 'flex-end'
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}
export default function Button({
  children,
  outlined,
  isThinButton,
  width,
  style,
  type,
  alignSelf,
  ...rest
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <Style.ButtonContainer
      outlined={outlined}
      isThinButton={isThinButton}
      type={!type ? 'button' : type}
      style={{ ...style, width, alignSelf }}
      {...rest}
    >
      {children}
    </Style.ButtonContainer>
  )
}
