import { useState } from 'react'
import { RegistrationPath } from 'pages/Registration/hooks/useRegistration'
import PageTitle from 'components/PageTitle'
import * as Styles from './styles'
import ValidationForm from './components/ValidationForm'
import RegistrationForm from './components/RegistrationForm'

interface FormsProps {
  onGoBack: () => void
  path: RegistrationPath
}
type FormsStep = 'validation' | 'registration'

export default function Forms({ onGoBack, path }: FormsProps) {
  const [step, setStep] = useState<FormsStep>('validation')

  const onRegistrationFormsGoBack = () => {
    if (path === 'professional') {
      setStep('validation')
      return
    }
    onGoBack()
  }

  return (
    <Styles.FormsContainer>
      <PageTitle>{path === 'professional' ? 'Cadastro Profissional da Saúde' : 'Cadastro Paciente'}</PageTitle>

      {path === 'professional' && step === 'validation' && (
        <ValidationForm onGoBack={onGoBack} onValidationSuccess={() => setStep('registration')} />
      )}
      {(path === 'patient' || step === 'registration') && (
        <RegistrationForm onGoBack={onRegistrationFormsGoBack} path={path} />
      )}
    </Styles.FormsContainer>
  )
}
