/* eslint-disable react/jsx-no-useless-fragment */
import { Navigation } from '@cannect/new-components/atoms'
import { cloneElement } from 'react'

import { useLocation } from 'react-router'
import { SubMenuItem } from './SubMenuItem'
import { TLinkItem } from './types'
import { cn } from '@cannect/lib/utils'

export const LinkItem = ({
  title,
  path,
  icon: Icon,
  submenu,
  onClick,
  external,
  hidden = false,
  iconPlacement = 'left',
  className,
  withSheetClose
}: TLinkItem) => {
  const location = useLocation()

  const currentRoute = location.pathname ?? window.location.pathname.split('/')[0].replace('/', '')

  const isActive = path === currentRoute

  const hasSubMenuActive = submenu?.filter((item) => {
    return currentRoute && item.path === currentRoute
  })

  if (hidden) return null

  return (
    <>
      {submenu && submenu?.length > 0 ? (
        <>
          <Navigation.MenuTrigger className={cn(`${hasSubMenuActive?.length && 'font-bold underline'}`, className)}>
            {title}
          </Navigation.MenuTrigger>
          <SubMenuItem withSheetClose={withSheetClose} submenu={submenu} currentRoute={currentRoute} />
        </>
      ) : (
        <Navigation.MenuLink
          withSheetClose={withSheetClose}
          active={isActive}
          to={path ?? '#'}
          onClick={() => {
            onClick?.()
          }}
          isExternal={external}
          className={className}>
          {Icon && iconPlacement === 'left' && cloneElement(Icon)}
          {title}
          {Icon && iconPlacement === 'right' && cloneElement(Icon)}
        </Navigation.MenuLink>
      )}
    </>
  )
}
