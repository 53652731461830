import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
`

export const BoletoRequest = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #777777;
  text-align: center;

  h1 {
    padding: 10px;
    font-size: 24px;
  }
  h2 {
    padding: 10px;
    font-size: 18px;
  }
  h3 {
    padding: 20px;
    font-size: 14px;
  }
  b {
    color: #748351;
  }
  span {
    color: #3d3d3d;
  }
`

export const DataBoleto = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    padding-bottom: 10px;
    text-align: left;
    color: #777777;
  }
`

export const ButtonCopy = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  margin-top: 20px;
  background-color: #748351;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
  cursor: pointer;
  text-align: center;
  border: none;
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const CopyCode = styled.div`
  color: #3483fa;
  padding: 5px;
  margin-left: calc(100% - 120px);
  cursor: pointer;
  &:hover {
    color: #0b58b7;
    transition: color 300ms;
  }
`

export const CodeCopied = styled.div`
  color: #777777;
  padding: 5px;
  margin-left: calc(100% - 120px);
`

export const DataFavored = styled.div`
  color: #777777;

  h1 {
    font-size: 18px;
    font-weight: 400;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }

  b {
    color: #41ac47;
  }

  span {
    color: #3d3d3d;
    font-weight: 500;
  }
`

export const LinkBoleto = styled.a`
  color: #3483fa;
  cursor: pointer;
  &:hover {
    color: #0b58b7;
    transition: color 300ms;
  }
`
export const ModalContent = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    color: red;
  }

  button {
    border: none;
    background: #c5581b;
    border-radius: 2px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    height: 40px;
    color: white;
    margin-top: 16px;
  }
`
