import BreadCrumb from 'components/BreadCrumb'
import SectionCategory from 'new-components/CannectEducate/SectionCategory'
import Heading from 'new-components/Heading'
import * as Style from './styles'
import { CategoriesEducate } from './textMock'

export default function CannectEducate() {
  return (
    <Style.Wrapper>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Educa', link: '/educa' }
        ]}
      />
      <Heading defaultColor="darkGrey">
        <span>Cannect</span> Educa
      </Heading>
      <Heading elementType="p" fontSize="22px" fontWeight={400}>
        Oferecemos materiais exclusivos para ajudar sua prática clínica diária; informando sobre os benefícios
        terapêuticos da cannabis medicinal para seus pacientes.
      </Heading>

      {CategoriesEducate.map(item => (
        <Style.SectionBase key={item.id}>
          <SectionCategory
            title={item.title}
            subTitle={item?.subTitle}
            category={item.category}
            page={item.page}
            limit={item.limit}
            image={item?.image}
            icon={item?.icon}
            description={item?.description}
            redirectLink={item?.redirectLink}
            externalLink={item?.externalLink}
          />
        </Style.SectionBase>
      ))}
    </Style.Wrapper>
  )
}
