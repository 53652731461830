import { THistoryListItem } from 'pages/Account/components/pages/MyPatients/types'

interface CategoryCellProps {
  row: THistoryListItem
}

const CATEGORY_LABEL = {
  consulta: 'consulta',
  pedido: 'pedido',
  prescricao: 'prescrição'
}

const CategoryCell = ({ row }: CategoryCellProps) => {
  return <span className="text-xs">{CATEGORY_LABEL[row.category]}</span>
}

export default CategoryCell
