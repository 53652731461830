import { theme } from 'design-cannect'
import styled, { css } from 'styled-components'
import { BadgeTreatmentLineProps } from '../../types/BadgeLineTtpes'

export const changeBackgroundType = {
  cronica: css`
    background: ${theme.colors.special1};
  `,
  mental: css`
    background: ${theme.colors.special2};
  `,
  oncologia: css`
    background: ${theme.colors.special3};
  `,
  neurologia: css`
    background: ${theme.colors.special4};
  `,
  pediatria: css`
    background: ${theme.colors.special5};
  `,
  intimo: css`
    background: ${theme.colors.special6};
  `,
  pele: css`
    background: ${theme.colors.special7};
  `,
  esportiva: css`
    background: ${theme.colors.special8};
  `,
  odontologia: css`
    background: #c0c0c0;
  `
}

export const changeBackground = {
  special1: css`
    background: ${theme.colors.special1};
  `,
  special2: css`
    background: ${theme.colors.special2};
  `,
  special3: css`
    background: ${theme.colors.special3};
  `,
  special4: css`
    background: ${theme.colors.special4};
  `,
  special5: css`
    background: ${theme.colors.special5};
  `,
  special6: css`
    background: ${theme.colors.special6};
  `,
  special7: css`
    background: ${theme.colors.special7};
  `,
  special8: css`
    background: ${theme.colors.special8};
  `
}

interface InterfaceContainer {
  active?: boolean
}

export const Container = styled.section<InterfaceContainer>`
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.active ? 1 : 0.5)};
  width: 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: transform 0.2s;
  :hover {
    cursor: pointer;

    img {
      filter: invert(43%) sepia(34%) saturate(876%) hue-rotate(108deg) brightness(104%) contrast(86%);
    }
  }
  a {
    text-decoration: none;
  }
  button {
    background: transparent !important;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  img {
    filter: ${props =>
      props.active
        ? 'invert(24%) sepia(13%) saturate(3875%) hue-rotate(131deg) brightness(95%) contrast(87%)'
        : 'filter: invert(55%) sepia(0%) saturate(1%) hue-rotate(340deg) brightness(95%) contrast(92%);'};
    width: 100%;
    object-fit: cover;
  }
`

export const Wrapper = styled.div<BadgeTreatmentLineProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;

  border-radius: 100%;
  background: transparent !important;
  ${({ type, color }) => (color ? changeBackground[color] : changeBackgroundType[type])}
  margin-bottom: ${theme.spacing.space3};
  :hover {
    cursor: pointer;
    box-shadow: ${theme.shadow.shad1};
  }
`
