import { ButtonStyle } from './index'

export const getBackgroundColor = (buttonStyle: ButtonStyle, background: string) => {
  if (buttonStyle === 'outlined' || buttonStyle === 'ghost') {
    return 'transparent'
  }

  return background
}

export const getBorderColor = (buttonStyle: ButtonStyle, background: string) => {
  if (buttonStyle === 'outlined') {
    return background
  }

  if (buttonStyle === 'ghost') {
    return 'transparent'
  }

  return 'transparent'
}

export const getTextColor = (buttonStyle: ButtonStyle, background: string) => {
  if (buttonStyle === 'outlined' || buttonStyle === 'ghost') {
    return background
  }

  return '#ffffff'
}
