import { secondCardImage } from 'assets/img'
import { appointCalendar, appointHealth, appointUser, appointList } from 'assets/svg'

export const CARD_MOCK = [
  { id: 1, text: 'Escolha especialidade, data e horário;', icon: appointCalendar },
  { id: 2, text: 'Cadastre-se no site da Cannect e pague com segurança online;', icon: appointUser },
  {
    id: 3,
    text: 'Realize sua consulta na data marcada através de videochamada  com seu médico ou odontologista;',
    icon: appointHealth
  },
  {
    id: 4,
    text: 'Receba sua prescrição por WhatsApp e e-mail e comece seu tratamento com cannabis medicinal.',
    icon: appointList
  }
]

export const ONBOARDING_MOCK = {
  text: ` Nosso time está disponível para <strong>auxiliar você nos principais desafios do dia a dia no consultório. </strong><br/>
  Desenvolvemos uma jornada personalizada para auxiliar durante todo o seu processo, oferecendo ferramentas
  para facilitar seu entendimento e acolher seus pacientes.`,
  image: secondCardImage
}
