import { useEffect } from 'react'

/**
 * Sets the page title; shown in the browser tab.
 */
function usePageTitle(title: string) {
  useEffect(() => {
    if (title) {
      document.title = `${title} – Cannect`
    }

    return () => {
      document.title = 'Cannect'
    }
  }, [title])
}

export default usePageTitle
