import CommunityComponent from 'components/CommunityComponents'
import { useLocation } from 'react-router'

function Community() {
  const location = useLocation<any>()
  const storyId = location?.state?.id

  return <CommunityComponent storyId={storyId} />
}

export default Community
