import { useGetProductById } from '@cannect/services/resources/products'
import { useMemo } from 'react'
import { TProductDetailsDialog } from './types'

export const useProductDetailsDialog = ({ productId }: TProductDetailsDialog) => {
  const { data, isLoading } = useGetProductById(productId)

  const productDetails = useMemo(() => data?.product, [data?.product])

  const generateTechnicalList = () => {
    if (!productDetails) return []

    const { medical_data } = productDetails

    const formatValue = (value?: string | number, unit?: string) => {
      if (!value) return undefined
      return unit ? `${value} ${unit?.toUpperCase()}` : value.toString()
    }

    const technicalDetails = [
      { title: 'Nome:', description: productDetails?.translated_name },
      { title: 'Marca:', description: productDetails?.brand?.name },
      { title: 'Forma:', description: productDetails?.shape?.name },
      { title: 'Via de administração:', description: productDetails?.administration?.name },
      { title: 'Classe:', description: productDetails?.class?.name },
      { title: 'Sub-classe:', description: productDetails?.subclass?.name },
      { title: 'Princípio Ativo:', description: medical_data?.principio_ativo },
      { title: 'CBC:', description: formatValue(medical_data?.cbc, 'mg') },
      {
        title: 'CBC concentração:',
        description: formatValue(medical_data?.cbc_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'CBD:', description: formatValue(medical_data?.cbd, 'mg') },
      {
        title: 'CBD concentração:',
        description: formatValue(medical_data?.cbd_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'CBDA:', description: formatValue(medical_data?.cbda, 'mg') },
      {
        title: 'CBDA concentração:',
        description: formatValue(medical_data?.cbda_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'CBDV:', description: formatValue(medical_data?.cbdv, 'mg') },
      {
        title: 'CBDV concentração:',
        description: formatValue(medical_data?.cbdv_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'CBG:', description: formatValue(medical_data?.cbg, 'mg') },
      {
        title: 'CBG concentração:',
        description: formatValue(medical_data?.cbg_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'CBGA:', description: formatValue(medical_data?.cbga, 'mg') },
      {
        title: 'CBGA concentração:',
        description: formatValue(medical_data?.cbga_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'CBN:', description: formatValue(medical_data?.cbn, 'mg') },
      {
        title: 'CBN concentração:',
        description: formatValue(medical_data?.cbn_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'Delta-8:', description: formatValue(medical_data?.delta8, 'mg') },
      {
        title: 'Delta-8 concentração:',
        description: formatValue(medical_data?.delta8_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'Delta-8 THCV:', description: formatValue(medical_data?.delta8_thcv, 'mg') },
      {
        title: 'Delta-8 THCV concentração:',
        description: formatValue(medical_data?.delta8_thcv_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'Delta-9 THCV:', description: formatValue(medical_data?.delta9_thcv, 'mg') },
      {
        title: 'Delta-9 THCV concentração:',
        description: formatValue(medical_data?.delta9_thcv_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'Delta-10:', description: formatValue(medical_data?.delta10, 'mg') },
      {
        title: 'Delta-10 concentração:',
        description: formatValue(medical_data?.delta10_concentration, `mg/${medical_data?.unit_of_measurement}`)
      },
      { title: 'THC:', description: formatValue(medical_data?.thc, 'mg') },
      {
        title: 'THC concentração:',
        description: formatValue(medical_data?.thc_concentration, `mg/${medical_data?.unit_of_measurement}`)
      }
    ]

    return technicalDetails.filter((item) => item?.description)
  }

  const productTechnicalList = useMemo(() => generateTechnicalList(), [productDetails])

  return {
    productDetails,
    isLoading,
    productTechnicalList
  }
}
