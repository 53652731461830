import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const EducateNavigatorContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  margin-top: 1rem;
  margin-bottom: 1.5rem;

  @media ${MOBILE_WIDTH} {
    width: 99%;
    padding: 0 1rem 0.5rem 1rem;
    overflow-x: scroll;
  }
`
