import React from 'react'

export default function IconeNovaConsulta({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M26.75 5.5H6.75C6.19772 5.5 5.75 5.94772 5.75 6.5V26.5C5.75 27.0523 6.19772 27.5 6.75 27.5H26.75C27.3023 27.5 27.75 27.0523 27.75 26.5V6.5C27.75 5.94772 27.3023 5.5 26.75 5.5Z"
        stroke="#3D3D3D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M22.75 3.5V7.5" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.75 3.5V7.5" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.75 11.5H27.75" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.25 19.5H13.25" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.75 16V23" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
