import { SVGProps } from 'react'

interface FileIconProps extends SVGProps<SVGSVGElement> {
  isChecked: boolean
}
export default function FileIcon({ isChecked, ...rest }: FileIconProps) {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M22.3587 25.5852H6.43124C6.19657 25.5852 5.9715 25.492 5.80556 25.326C5.63961 25.1601 5.54639 24.935 5.54639 24.7004V5.23349C5.54639 4.99881 5.63961 4.77374 5.80556 4.6078C5.9715 4.44186 6.19657 4.34863 6.43124 4.34863H17.0495L23.2435 10.5426V24.7004C23.2435 24.935 23.1503 25.1601 22.9844 25.326C22.8184 25.492 22.5934 25.5852 22.3587 25.5852Z"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0493 4.34863V10.5426H23.2433"
        stroke={isChecked ? 'white' : '#8F8F8F'}
        strokeWidth="1.95246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
