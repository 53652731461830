import { cn } from '@cannect/lib/utils'
import { ComponentProps, ComponentPropsWithoutRef, ElementRef, forwardRef, HTMLAttributes } from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'

const Root = ({ shouldScaleBackground = true, ...props }: ComponentProps<typeof DrawerPrimitive.Root>) => {
  return <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
}
Root.displayName = 'Root'

const { Trigger } = DrawerPrimitive

const { Portal } = DrawerPrimitive

const { Close } = DrawerPrimitive

const Overlay = forwardRef<
  ElementRef<typeof DrawerPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay ref={ref} className={cn('fixed inset-0 z-50 bg-black/80', className)} {...props} />
))
Overlay.displayName = DrawerPrimitive.Overlay.displayName

const Content = forwardRef<
  ElementRef<typeof DrawerPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <Portal>
    <Overlay />
    <DrawerPrimitive.Content
      ref={ref}
      className={cn(
        'fixed inset-x-0 bottom-0 left-0 right-0 z-50 mt-12 flex max-h-[97%] flex-col rounded-t-[10px] border bg-white',
        className
      )}
      {...props}>
      <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />
      {children}
    </DrawerPrimitive.Content>
  </Portal>
))
Content.displayName = 'Content'

const Header = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('grid gap-2 py-4 text-center md:text-left', className)} {...props} />
)
Header.displayName = 'Header'

const Footer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto flex flex-col gap-2 py-4', className)} {...props} />
)
Footer.displayName = 'Footer'

const Title = forwardRef<
  ElementRef<typeof DrawerPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn('font-sans text-lg font-semibold leading-none tracking-tight', className)}
    {...props}
  />
))
Title.displayName = DrawerPrimitive.Title.displayName

const Description = forwardRef<
  ElementRef<typeof DrawerPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description ref={ref} className={cn('text-sm text-gray-500', className)} {...props} />
))
Description.displayName = DrawerPrimitive.Description.displayName

export { Root, Portal, Overlay, Trigger, Close, Content, Header, Footer, Title, Description }
