import React, { InputHTMLAttributes } from 'react'
import { FiSearch } from 'react-icons/fi'
import * as Style from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  props?: any
  width?: string
  outlined?: boolean
  zIndex?: number
}

export function InputSearch({ value, width, zIndex, ...props }: InputProps) {
  return (
    <Style.ContainerInput width={width} style={{ zIndex }}>
      <Style.WrapperInput type="text" value={value} {...props} />
      <span className="wrapper-icon">
        <FiSearch />
      </span>
    </Style.ContainerInput>
  )
}
