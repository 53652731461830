import InputFile from 'components/InputFile'
import Text from 'components/Text'
import { useState } from 'react'

import useMediaQuery from 'hooks/useMediaQuery'
import { toast } from 'react-toastify'
import { MOBILE_WIDTH } from 'utils/constants'

import { recipeBox } from 'assets/svg'
import Loading from 'components/Loading'
import { useHistory } from 'react-router'
import api from 'services/api'

import { useTokenApi } from 'hooks/useTokenApi'
import UploadedFileInfo from '../UploadedFileInfo'
import * as Style from './styles'

export default function PersonalDocsUpload() {
  const { deleteTokenApi } = useTokenApi()
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const orderId = localStorage.getItem('@CANNECT:ORDERID')
  const isDependent = localStorage.getItem('@CANNECT:ISDEPENDENT')
  const basePathDocuments = `/order/document`

  const [loading, setLoading] = useState(false)

  const [documents, setDocuments] = useState<{ documents: { id: number | null }[] }>({
    documents: []
  })

  const [getIdDocument, setGetIdDocument] = useState<{ name: string }[]>([])
  const [getAddressDocument, setGetAddressDocument] = useState<{ name: string }[]>([])
  const [getBondDocument, setGetBondDocument] = useState<{ name: string }[]>([])

  const handleIdUpload = async (files: Array<Blob>) => {
    const data = files as unknown as { name: string }[]
    setGetIdDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      formData.append('file', file)
    }
    try {
      const { data } = await api.post(`${basePathDocuments}/${orderId}?type=IDENTIDADE`, formData)

      const holdData = documents
      if (data.id) {
        holdData?.documents.push({
          id: data.id
        })

        setDocuments(holdData)
      } else {
        handleRemoveId(data.name)
        toast.error('Erro, insira novamente seu documento por favor')
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    }
  }

  const handleAddressUpload = async (files: Array<Blob>) => {
    const data = files as unknown as { name: string }[]
    setGetAddressDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      formData.append('file', file)
    }
    try {
      const { data } = await api.post(`${basePathDocuments}/${orderId}?type=RESIDENCIAL`, formData)
      const holdData = documents

      if (data.id) {
        holdData?.documents.push({
          id: data.id
        })

        setDocuments(holdData)
      } else {
        handleRemoveAddress(data.name)
        toast.error('Erro, insira novamente seu documento por favor')
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    }
  }

  const handleBondUpload = async (files: Array<Blob>) => {
    const data = files as unknown as { name: string }[]
    setGetBondDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      formData.append('file', file)
    }
    try {
      const { data } = await api.post(`${basePathDocuments}/${orderId}?type=VINCULO`, formData)
      const holdData = documents

      if (data.id) {
        holdData?.documents.push({
          id: data.id
        })

        setDocuments(holdData)
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    }
  }

  const handleRemoveId = (file: string) => {
    const fixFiles = getIdDocument?.filter((e) => e.name !== file)

    setGetIdDocument(fixFiles)
  }

  const handleRemoveAddress = (file: string) => {
    const fixFiles = getAddressDocument?.filter((e) => e.name !== file)

    setGetAddressDocument(fixFiles)
  }
  const handleRemoveBond = (file: string) => {
    const fixFiles = getAddressDocument?.filter((e) => e.name !== file)

    setGetBondDocument(fixFiles)
  }

  const handleSubmit = async () => {
    if (getIdDocument.length === 0 || getAddressDocument.length === 0) {
      alertDocument()
      return
    }
    try {
      setLoading(true)
      await api.put(`/order/${orderId}`, documents)

      history.push('/receita-branca/aprovacao-anvisa')
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSendLater = async () => {
    history.push('/receita-branca/aprovacao-anvisa')
  }

  const alertDocument = async () => {
    toast.error('Insira seus documentos para continuar')
  }

  return (
    <>
      <Loading loading={loading} />
      <Text type="lg" bold color="tertiary">
        Os documentos abaixo são necessários para confirmar a compra
      </Text>
      <Style.TitleWrapper isMobile={isMobile}>
        <Text type="md">Caso você não tenha em mãos os documentos necessários, você poderá envia-los depois</Text>
        <Style.RefButton onClick={() => console.log('button')}>através de sua conta</Style.RefButton>
      </Style.TitleWrapper>
      <Style.UploadContainer isMobile={isMobile}>
        <Style.FileWrapper>
          <Text type="lg" bold>
            Documentos de identificação
          </Text>

          <Text type="md">Pode ser o RG ou CNH</Text>

          <InputFile handleUpload={handleIdUpload} updateComponent={getIdDocument} type="IDENTIDADE" />

          {getIdDocument.length === 0 ? (
            <>
              <img src={recipeBox} alt="" style={{ height: '120px' }} />

              <Text type="md" bold>
                Você ainda não enviou nenhum documento de identificação
              </Text>
            </>
          ) : (
            <UploadedFileInfo files={getIdDocument} removeFile={handleRemoveId} />
          )}
        </Style.FileWrapper>
        <Style.FileWrapper>
          <Text type="lg" bold>
            Comprovante de endereço
          </Text>
          <Text type="md">Com o máximo de três meses</Text>

          <InputFile handleUpload={handleAddressUpload} updateComponent={getAddressDocument} type="RESIDENCIAL" />

          {getAddressDocument.length === 0 ? (
            <>
              <img src={recipeBox} alt="" style={{ height: '120px' }} />
              <Text type="md" bold>
                Você ainda não enviou nenhum comprovante de endereço
              </Text>
            </>
          ) : (
            <UploadedFileInfo files={getAddressDocument} removeFile={handleRemoveAddress} />
          )}
        </Style.FileWrapper>
      </Style.UploadContainer>
      &nbsp;
      {isDependent === 'true' ? (
        <Style.FileWrapper>
          <Text type="lg" bold>
            Comprovante de vínculo
          </Text>
          <Text type="md">Necessário apenas em casos de dependentes</Text>

          <InputFile handleUpload={handleBondUpload} updateComponent={getBondDocument} type="VINCULO" />
          {getBondDocument.length === 0 ? (
            <>
              <img src={recipeBox} alt="" style={{ height: '120px' }} />

              <Text type="md" bold>
                Você ainda não enviou nenhum comprovante de vínculo
              </Text>
            </>
          ) : (
            <UploadedFileInfo files={getBondDocument} removeFile={handleRemoveBond} />
          )}
        </Style.FileWrapper>
      ) : null}
      <Style.SubmitButtonWrapper>
        <Style.SubmitAfterButton
          disabled={getAddressDocument.length > 0 && getIdDocument.length > 0}
          onClick={handleSendLater}>
          Enviar os documentos depois
        </Style.SubmitAfterButton>
        <Style.SubmitButton onClick={handleSubmit}>Continuar</Style.SubmitButton>
      </Style.SubmitButtonWrapper>
    </>
  )
}
