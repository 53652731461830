/* eslint-disable import/no-extraneous-dependencies */
import * as Dialog from '@radix-ui/react-dialog'
import styled, { keyframes } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.86);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const DialogRoot = styled(Dialog.Root)``
export const DialogTrigger = styled(Dialog.Trigger)``

export const DialogPortal = styled(Dialog.Portal)``

export const DialogOverlay = styled(Dialog.Overlay)`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  inset: 0;
  animation: ${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1001;
`

export const DialogContent = styled(Dialog.Content)`
  background: white;
  border-radius: 12px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 80vw;
  max-height: 85vh;
  padding: 25px;
  padding-top: 50px;
  animation: ${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow-x: auto;
  z-index: 1002;
  &:focus {
    outline: none;
  }
`

export const ContentChildren = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DialogClose = styled(Dialog.Close)``

export const ButtonClose = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${temporaryTheme.colors.darkGrey};
  padding: 4px;
  border: none;
  position: absolute;
  top: 24px;
  right: 24px;
  svg {
    color: white;
    width: 100%;
    height: 100%;
  }
`

export const ButtonOpen = styled.button`
  width: 312px;
  height: 50px;
  background: transparent;
  border: 1.1085px solid #21976e;
  border-radius: 5px;
  font-family: 'Spartan', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #424242;
`
