import React, { ComponentProps, ReactElement, cloneElement, forwardRef } from 'react'
import { VariantProps, tv } from 'tailwind-variants'
import { ImSpinner2 } from 'react-icons/im'
import { cn } from '@cannect/lib/utils'
import { Label } from './Label'

export const inputVariants = tv({
  slots: {
    container: 'flex w-full flex-col justify-center space-y-1',
    containerInput: 'border-1 relative flex w-full items-center gap-4 rounded border-solid border-gray-300 px-4',
    labelItem: 'text-sm',
    input:
      'peer block w-full appearance-none rounded border-none bg-transparent pr-4 text-sm text-gray-900 placeholder:text-gray-500 focus:border-blue-600 focus:outline-none focus:ring-0',
    containerIcon: 'text-gray-900'
  },
  variants: {
    variant: {
      filled: {
        containerInput: 'border-transparent bg-gray-100'
      },
      filledDark: {},
      filledLight: {
        containerInput: 'border border-gray-300 bg-white',
        labelItem: 'text-neutral-500'
      }
    },
    size: {
      default: {
        containerInput: 'h-[45px]',
        input: 'text-sm',
        containerIcon: 'text-lg'
      },
      sm: {
        containerInput: 'h-8',
        input: 'text-sm',
        containerIcon: 'text-xs'
      },
      md: {
        containerInput: 'h-10',
        input: 'text-sm',
        containerIcon: 'text-[26px]',
        labelItem: 'text-lg'
      }
    },
    isInvalid: {
      true: {
        containerInput: 'ring-1 ring-error-700'
      }
    },
    isDisabled: {
      true: {
        containerInput: 'cursor-not-allowed opacity-60',
        input: 'cursor-not-allowed text-gray-400'
      }
    }
  },

  defaultVariants: {
    variant: 'filled',
    size: 'default'
  }
})

export type InputProps = Omit<ComponentProps<'input'>, 'size' | 'disabled'> &
  VariantProps<typeof inputVariants> & {
    label?: string
    rigthElement?: ReactElement
    leftElement?: ReactElement
    isLoading?: boolean
  }

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      isDisabled = false,
      isInvalid,
      leftElement: IconLeft,
      placeholder,
      isLoading = false,
      rigthElement: IconRigth,
      variant,
      className,
      size,
      name,
      label,
      ...props
    },
    ref
  ) => {
    const { containerInput, input, containerIcon, container, labelItem } = inputVariants({
      isDisabled: isDisabled || isLoading,
      isInvalid,
      variant,
      size
    })

    return (
      <div className={cn(container(), className)}>
        {label && <Label className={labelItem()}>{label}</Label>}
        <div className={cn(containerInput(), className)}>
          {IconLeft &&
            cloneElement(IconLeft as ReactElement, {
              className: containerIcon()
            })}

          <input
            name={name ?? undefined}
            data-testid="input"
            ref={ref}
            type="text"
            className={input()}
            placeholder={placeholder}
            disabled={isDisabled || isLoading}
            {...props}
          />
          {isLoading && <ImSpinner2 className="animate-spin text-base text-gray-900" />}
          {IconRigth && !isLoading && cloneElement(IconRigth as ReactElement, { className: containerIcon() })}
        </div>
      </div>
    )
  }
)
