import * as Style from './styles'

interface TabContentTitleProps {
  title: string
  titleImg: string
  postsCountText?: string
  newStyle?: boolean
}

export default function TabContentTitle({ title, titleImg, postsCountText, newStyle }: TabContentTitleProps) {
  return (
    <Style.TabContentTitleContainer newStyle={newStyle}>
      <Style.TitleContent newStyle={newStyle}>
        <img src={titleImg} alt="❏" />
        <h1>{title}</h1>
      </Style.TitleContent>

      {postsCountText && (
        <Style.TitleContent newStyle={newStyle}>
          <span>{postsCountText}</span>
        </Style.TitleContent>
      )}
    </Style.TabContentTitleContainer>
  )
}
