import { ButtonHTMLAttributes, ReactNode } from 'react'
import * as Style from './styles'

type DefaultButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
interface FilterButtonProps {
  children: ReactNode
  active: boolean
}
export default function FilterButton({ children, active, ...rest }: FilterButtonProps & DefaultButtonProps) {
  return (
    <Style.FilterButtonContainer active={active} {...rest}>
      {children}
    </Style.FilterButtonContainer>
  )
}
