import styled from 'styled-components'
import { theme } from 'design-cannect'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray};
  padding: 24px;
  margin-bottom: 24px;

  .acount {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 180px;

    .descripiton {
      max-width: 300px;
      p {
        margin-bottom: 16px;
      }
    }
  }

  .product {
    margin-top: 24px;
    width: 99%;
  }
`
