import { Button, Illustrations, Typography } from '@cannect/new-components/atoms'
import { useGenerateAndSignProcurationStep } from './useGenerateAndSignProcurationStep'

export const GenerateAndSignProcurationStepUI = ({
  isLoading,
  handleGenerateProcuration,
  handleGoToSendAnvisaAuthorization,
  widget
}: ReturnType<typeof useGenerateAndSignProcurationStep>) => {
  return (
    <div className={`flex flex-col items-center gap-3 ${widget ? 'pt-4' : 'pt-14'} `}>
      <Typography.Heading type="headingSix" className="text-center text-primary-700">
        {widget ? 'Confira sua procuração' : 'Não tem a autorização de importação da Anvisa? Deixa com a gente!'}
      </Typography.Heading>
      <div id="clicksign-container" className={widget ? 'h-[70dvh] w-full' : 'hidden h-full w-full'} />

      {!widget && (
        <>
          <Illustrations.RequestAnvisa />
          <Typography.Text type="paragraphTwo" muted weight="semibold" className="text-center">
            Com uma procuração, nossa equipe pode emitir esse documento por você. Não se preocupe! Só leva dois cliques
            e é exclusiva para este fim.
          </Typography.Text>

          <Button
            size="lg"
            isLoading={isLoading}
            full
            className="mt-auto font-medium"
            onClick={handleGenerateProcuration}>
            Gerar procuração
          </Button>
          <Button
            size="lg"
            full
            className="font-medium"
            variant="outline_light"
            onClick={handleGoToSendAnvisaAuthorization}>
            Já tenho a autorização
          </Button>
        </>
      )}
    </div>
  )
}
