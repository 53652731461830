import { IconeCasa } from 'assets/svg'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import LinkAsButton from 'new-components/LinkAsButton'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'
import AddressCard from './components/AddressCard'
import * as Styles from './styles'
import { Address, FetchAddressesResponse, PersonalAddresses } from './types'

export default function ProfessionalAddresses() {
  const [addresses, setAddresses] = useState<PersonalAddresses>()
  const [loading, setLoading] = useState(false)

  const fetchAddresses = async () => {
    setLoading(true)
    try {
      const {
        data: { address, success }
      } = await api.get<FetchAddressesResponse>('/my-account/address')

      if (!success) {
        toast.error('Houve um erro ao buscar os endereços cadastrados. Tente novamente mais tarde.')
        return
      }
      setAddresses({
        billing: address.filter((address: Address) => address.isBilling),
        delivery: address.filter((address: Address) => address.isDelivery)
      })
    } catch (err) {
      toast.error('Houve um erro ao buscar os endereços cadastrados. Tente novamente mais tarde.')
      console.error(err)
    }
    setLoading(false)
  }

  const onSelectedAsMain = useCallback(() => {
    fetchAddresses()
  }, [])

  useEffect(() => {
    fetchAddresses()
  }, [])

  return (
    <Styles.ProfessionalAddressesContainer>
      <Loading loading={loading} />
      <PageTitle>
        <IconeCasa style={{ width: '4rem', height: '4rem', minWidth: '4rem', minHeight: '4rem' }} />
        Endereços
      </PageTitle>

      <Styles.AllAddressesWrapper>
        <Styles.AddressesSection>
          <h2>Endereços de entrega</h2>
          {addresses?.delivery?.map(address => (
            <AddressCard address={address} onSelectedAsMain={onSelectedAsMain} />
          ))}
          <Styles.NewAddressButtonWrapper>
            <LinkAsButton
              to="/conta?pagina=perfil-enderecos-novo"
              onClick={() => localStorage.setItem('@CANNECT_NEW_ADDRESS_TYPE', 'delivery')}
              style={{ alignSelf: 'center' }}
            >
              NOVO ENDEREÇO
            </LinkAsButton>
          </Styles.NewAddressButtonWrapper>
        </Styles.AddressesSection>
        <Styles.AddressesSection>
          <h2>Endereços de cobrança</h2>
          {addresses?.billing?.map(address => (
            <AddressCard address={address} onSelectedAsMain={onSelectedAsMain} />
          ))}
          <Styles.NewAddressButtonWrapper>
            <LinkAsButton
              to="/conta?pagina=perfil-enderecos-novo"
              onClick={() => localStorage.setItem('@CANNECT_NEW_ADDRESS_TYPE', 'billing')}
              style={{ alignSelf: 'center' }}
            >
              NOVO ENDEREÇO
            </LinkAsButton>
          </Styles.NewAddressButtonWrapper>
        </Styles.AddressesSection>
      </Styles.AllAddressesWrapper>
    </Styles.ProfessionalAddressesContainer>
  )
}
