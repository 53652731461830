import { theme } from 'design-cannect'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;

  h2 {
    text-align: center;
    margin: 20px 0;
  }
`

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    margin-bottom: 25px;
    border: 1px solid #3d3d3d;
    height: 55px;
  }

  textarea {
    border: 1px solid #3d3d3d;
  }

  button {
    background-color: ${theme.colors.secondary};
    color: white;
    border: none;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    margin-left: auto;
    margin-top: 10px;
  }
`
export const Button = styled.button<any>`
  color: white;
  height: 48px;
  font-weight: 700;
  border-radius: ${theme.spacing.space2};
  border: none;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  align-items: center;
  justify-items: center;
  line-height: 1;
  font-size: 14px;
  padding: 12px ${theme.spacing.space3};
  background-color: ${theme.colors.tertiary};

  .spinner {
    margin-right: 10px;
    svg {
      animation: ${({ loading }) => loading && 'lds-dual-ring 1.2s linear infinite'};
      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`
