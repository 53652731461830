/* eslint-disable no-irregular-whitespace */
export const term = `
<h4>Seja bem-vindo (a)!</h4>
Ficamos muito felizes por <strong>Você </strong>escolher a Plataforma <strong>CANNECT!</strong>

A Plataforma <strong>CANNECT </strong>oferece<strong> </strong>a <strong>Você</strong>, através de seus sites na internet e seus subdomínios, consultoria para acesso ao produto derivado de cannabis, mediante solicitação de autorização para a importação junto a ANVISA (Agência Nacional de Vigilância Sanitária) e intermediação com o fornecedor, para acompanhar o procedimento do pedido de compra e coordenar e rastrear a entrega.
<br>
Por isso, criamos este Termo, que irá regular a relação para utilização da Plataforma<strong>, </strong>permitindo que <strong>Você </strong>tenha acesso ao conteúdo e serviços da <strong>CANNECT</strong>, disponibilizada via Internet para determinados dispositivos conectados, tais como computadores, aparelhos móveis e/ou outros aparelhos compatíveis (“Aparelhos Compatíveis”).
<br>
A <strong>CANNECT</strong> segue as determinações legais vigentes, bem como as determinações da

ANVISA (Agência Nacional de Vigilância Sanitária), nos termos da RDC 335/2020 e demais aplicáveis.
Ao utilizar a Plataforma, <strong>Você</strong> está concordando com este Termo. Por isso, é importante que <strong>Você</strong> o leia com muita atenção.
<br>
<br>
<strong>Capítulo I – CONDIÇÕES GERAIS</strong>
<br>
<br>
<strong>1.1. Âmbito</strong>. O presente Termo e Condições Gerais de Uso (denominado somente “Termo” ou “Termo de Uso”) vinculam todas as atividades desenvolvidas e serviços oferecidos pela <strong>CANNECT </strong>(doravante assim denominada ou somente “Plataforma”), que engloba o website e seus subdomínios, aplicativos, programas e demais extensões. A Plataforma é de propriedade de <strong>CANNECT SERVIÇOS DE INTERNET LTDA.</strong>, pessoa jurídica inscrita no CNPJ sob o nº 41.418.943/0001-16, com sede na Avenida Pastor Martin Luther King Júnior, n.º 126, bloco 9, sala 817, torre 3, Bairro Del Castilho, Rio de Janeiro/RJ, CEP 20.765-000 (“<strong>CANNECT</strong>”), e está sob sua responsabilidade e gestão.
<br>
<br>
<strong>1.2. Atuação</strong>. A Plataforma desenvolvida é de propriedade exclusiva da <strong>CANNECT</strong> e oferece consultoria para acesso ao produto derivado de cannabis, mediante solicitação de autorização para a importação junto a ANVISA (Agência Nacional de Vigilância Sanitária) e intermediação com o fornecedor, para acompanhar o procedimento do pedido de compra e coordenar e rastrear a entrega.
<br>
<br>
<strong>1.3. Possibilidade de Integração.</strong> A<strong> CANNECT </strong>pode, a qualquer momento, integrar a sua Plataforma com outras, podendo o Usuário utilizar o mesmo cadastro em cada uma delas.
<br>
<br>
<strong>1.4. Definições. </strong>As palavras e expressões abaixo descritas, quando empregadas nestes Termos de Uso, terão os seguintes significados:
<br>
<br>
“<strong>USUÁRIO” (Você)</strong>: Pessoas físicas de todas as idades, que possuem prescrição emitida por profissional legalmente habilitado para uso de produto derivado de cannabis e que se cadastram, gratuitamente, na Plataforma. No caso de pessoas físicas menores de idade e incapazes, o uso da plataforma<strong> </strong>deverá ser restrito, sob as condições de ser assistido ou representado, conforme norma civil aplicável.
<br>
<br>
“<strong>ANVISA</strong>”: significa a Agência Nacional de Vigilância Sanitária, agência reguladora, sob a forma de autarquia de regime especial, vinculada ao Ministério da Saúde do Brasil;
<br>
<br>
“<strong>RDC 335/2020</strong>”: significa a Resolução ANVISA nº. 335, de 24 de janeiro de 2020 e correspondentes alterações;
<br>
<br>
“<strong>CPF</strong>”: significa o Cadastro da Pessoa Física perante a Secretaria da Receita Federal brasileira;
<br>
<br>
“<strong>AUTORIZAÇÃO”</strong>: ato exercido pela ANVISA, por meio da emissão de documento que autoriza a importação de Produto derivado de Cannabis por pessoa física, para uso próprio para tratamento de saúde, além do seu respectivo cadastro na ANVISA;
<br>
<br>
“<strong>PRODUTO DERIVADO DE CANNABIS”</strong>: produto industrializado, destinado à finalidade medicinal, contendo derivados da planta Cannabis spp.
<br>
<br>
<strong>1.5. Adesão e Aceite</strong>. Pelo presente instrumento, <strong>Você</strong> concorda que:
<br>
<br>

<ul>
 	<li>Deverá aceitar os Termos da <strong>CANNECT</strong>, para fins de cadastro, controle de acesso e execução de melhorias na Plataforma;</li>
</ul>
<br>
<ul>
 	<li>Clicando na caixa de aceitação dos Termos deve aderir e concordar em se submeter ao disposto no presente instrumento.</li>
</ul>
<strong>1.6. Violação das Condições</strong>. Caso <strong>Você </strong>venha a descumprir qualquer das condições aqui previstas, a <strong>CANNECT </strong>reserva-se o direito de encerrar sua conta e suspender ou anular seu acesso à Plataforma, sem necessidade de aviso prévio.
<br>
<br>
<strong>1.7. Modificações</strong>. A <strong>CANNECT </strong>se reserva o direito de, a qualquer momento, modificar o Termo de Uso, sempre com o envio de avisos a <strong>Você</strong>, para ciência, sem qualquer responsabilização da <strong>CANNECT </strong>devido a quaisquer modificações efetuadas na Plataforma, que entrarão em vigor imediatamente após sua publicação <em>online</em>.
<br>
<br>
<strong>1.8. Alterações e descontinuidade</strong>. A <strong>CANNECT </strong>poderá, a qualquer momento, de acordo com a sua disponibilidade negocial, alterar qualquer aspecto da Plataforma, inclusive quanto às suas características ou funcionalidades, bem como suspender, cancelar ou descontinuá-lo, no todo ou em parte, temporariamente ou em caráter definitivo, a seu critério exclusivo, mediante comunicação a <strong>Você</strong>, sem que isso caracterize inadimplemento deste Termo, ou direito de indenização ou multa em favor do mesmo, salvo estorno de valores que porventura tenham sido adimplidos antecipadamente.
<br>
<br>
<strong>1.8.1</strong>. O serviço que Você escolher permanecerá sem alterações até a data de encerramento de sua vigência.
<br>
<br>
<strong>1.9. Procuração para Solicitação de Autorização junto a ANVISA. Você </strong>deverá outorgar procuração com poderes especiais para a<strong> CANNECT </strong>representá-lo perante Instituições Governamentais, mais especificamente para<strong> </strong>acessar o Portal de Serviços do Governo Federal, obter e consultar informações necessárias à obtenção dos documentos requeridos para importação dos produtos pretendidos, como também para<strong> </strong>solicitar a autorização de importação de produto derivado de cannabis em seu nome.
<br>
<br>
<strong>1.9.1</strong>. <strong>Você</strong> irá outorgar poderes específicos para que a <strong>CANNECT</strong> informe, no Portal de Serviços do Governo Federal, os dados solicitados no formulário de “Autorização para Importação de Canabidiol”, tais como:
<br>
<br>
<strong>(i)</strong> Dados pessoais do Paciente/Responsável Legal (quando houver), como nome completo, número do documento de identificação, data de nascimento e endereço e demais informações que venham a ser solicitadas;
<br>
<br>
<strong>(ii)</strong> Dados do produto, como nome comercial, composição e empresa fabricante, e demais informações que venham a ser solicitadas;
<br>
<br>
<strong>(iii)</strong> Dados do prescritor do produto, tais como nome do profissional legalmente habilitado, especialidade, número de cadastro no Conselho/UF, e-mail, telefone e anexação da receita médica, e demais informações que venham a ser solicitadas; e
<br>
<br>
<strong>(iv)</strong> <strong>Você </strong>outorga poderes específicos para que a<strong> CANNECT</strong> proceda à assinatura do termo de responsabilidade, solicitado no formulário de “Autorização para Importação de Canabidiol”, e demais assinaturas que venham a ser solicitadas.
<br>
<br>
<strong>1.9.2.</strong> <strong>Você</strong> concorda que, ao outorgar poderes acima especificados, por uma limitação tecnológica do Portal de Serviços do Governo Federal, é possível que haja o acesso, por parte da <strong>CANNECT</strong>, de outros dados ali cadastrados, mas que não serão utilizados, para nenhum outro fim diverso do que o já exposto.
<br>
<br>
<strong>Capítulo II – MODO DE UTILIZAÇÃO</strong>
<br>
<br>
<strong>2.1. Cadastro</strong>. A Plataforma <strong>CANNECT</strong> ficará disponível para a <strong>Você </strong>através de seus websites e subdomínios. Ao acessar, <strong>Você </strong>se cadastrará, colocando as informações necessárias para o uso, tais como: nome, e-mail e senha.
<br>
<br>
<strong>2.2. Dinâmica.</strong> Após <strong>Você </strong>realizar o Cadastro, terá acesso a Plataforma da <strong>CANNECT, </strong>que dispõe de um número limitado de conteúdos gratuitos. Caso queira, <strong>Você </strong>poderá realizar o pagamento para ter acesso à consultoria para acesso ao produto derivado de cannabis.
<br>
<br>
<strong>2.2.1. Comunicação. </strong>As notificações e comunicação entre <strong>Você </strong>e a <strong>CANNECT </strong>será realizada pelo e-mail cadastrado por <strong>Você</strong> e <em>push notification</em>.
<br>
<br>
<strong>2.3. Incorreção de Informações Cadastrais</strong>. Para acessar e utilizar as funcionalidades da Plataforma, <strong>Você </strong>deverá prestar as informações exigidas no momento do cadastro, conforme disposto na Política de Privacidade.
<br>
<br>
<strong>2.3.1.</strong> <strong>Critérios de recusa</strong>. A Plataforma<strong> CANNECT</strong> se reserva o direito de impedir, a seu critério, novos cadastros, ou cancelar os já efetuados, em caso de ser detectada anomalia que considere, em sua análise, revestida de erro grave ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todo Usuário, sem que este seja ressarcido das quantias já pagas.
<br>
<br>
<strong>2.4. Uso de Cadastro por Terceiros</strong>. Os cadastros na Plataforma são individuais e intransferíveis, assim, <strong>Você </strong>deverá imediatamente notificar a <strong>CANNECT </strong>sobre qualquer uso não autorizado de sua conta, em tempo hábil, não sendo a <strong>CANNECT </strong>responsável por quaisquer prejuízos ou danos oriundos do uso de “login” e senha por terceiro, com ou sem o seu consentimento.
<br>
<br>
<strong>2.4.1.</strong> <strong>Cuidados a serem observados</strong>. Para utilização adequada da Plataforma, <strong>Você </strong>deverá: (i) Ser cuidadoso com os dados de sua identificação individual sempre que acessar a Internet; (ii) Tomar outras medidas necessárias para se protegerem de danos, inclusive fraudes <em>online</em> e <em>off-line</em>; (iii) Ser diligente no uso do conteúdo disponibilizado na Plataforma da<strong> CANNECT</strong>, sabendo de suas responsabilidades; e (iv) Cumprir rigorosamente todas as determinações deste Termo.
<br>
<br>
<strong>2.5. Limitações de Uso</strong>.<strong> </strong>A licença de uso da Plataforma é concedida de forma limitada, não customizável, não exclusiva, intransferível e não passível de sublicenciamento, cessão, de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, os serviços contratados através destes Termos, incluindo acessar e visualizar os conteúdos pela internet, exclusivamente para uso pessoal, sem fins comerciais e desde que integralmente atendidas às condições previstas neste Termo.
<br>
<br>
<strong>2.5.1. Finalidade.</strong> Dessa forma, <strong>Você </strong>não poderá utilizar nem permitir o uso da Plataforma para qualquer outra finalidade que não seja aquela descrita no presente Termo. Caso seja constatada alguma ofensa ao Termo e a Política de Privacidade, a Plataforma da<strong> CANNECT </strong>poderá suspender o seu acesso por completo, sem que isso implique no inadimplemento deste Termo, de modo que <strong>Você </strong>deverá<strong> </strong>continuar cumprindo com todas as suas obrigações, até que se verifique ou sane, no prazo de 15 (quinze) dias, a irregularidade constatada.
<br>
<br>
<strong>2.6. Obrigações do Usuário</strong>. <strong>Você </strong>tem as seguintes responsabilidades:
<br>
<br>
<ol>
 	<li>Não utilizar a Plataforma <strong>CANNECT</strong> para qualquer propósito que seja ilícito ou proibido pelos Termos e/ou pelas normas aplicáveis;</li>
 	<li>Autorizar que a <strong>CANNECT </strong>possa enviar comunicações por e-mail, telefone ou mensagens instantâneas, inclusive de caráter publicitário;</li>
 	<li>Apresentar comportamento honesto e de boa fé;</li>
 	<li>Informar corretamente e com precisão todos os seus dados, para fins cadastrais;</li>
 	<li>Responder pelas informações inseridas e mantidas na Plataforma, pelo cadastramento, permissões, senhas e modo de utilização, isentando a <strong>CANNECT </strong>de toda e qualquer responsabilidade com relação ao conteúdo inserido e sua forma de utilização;</li>
 	<li>Fornecer todas as informações e documentos necessários para a realização da consultoria para acesso ao produto derivado de cannabis;</li>
 	<li>Assinar a procuração anexa a estes Termos, no intuito de outorgar poderes especiais a <strong>CANNECT</strong>para o pedido de autorização para importação junto a ANVISA;</li>
 	<li>Pagar o valor referente aos serviços contratados.</li>
</ol>
<br>
<strong>2.7.</strong> Obrigações da <strong>CANNECT</strong>. A <strong>CANNECT</strong> tem as seguintes responsabilidades:
<br>
<br>
<ol>
 	<li>Requisitar a autorização para a importação do produto derivado de cannabis junto a ANVISA;</li>
 	<li>Intermediar a relação do Usuário com uma clínica médica / médico, para este realizar a consulta e, caso o profissional entenda adequado, a prescrição de produto derivado de cannabis;</li>
 	<li>Intermediar a relação do Usuário com o fornecedor, para efetuar o pedido de compra do produto derivado de cannabis; e</li>
 	<li>Coordenar e rastrear o pedido.</li>
</ol>
<br>
<strong>2.7.1.</strong> A <strong>CANNECT </strong><u>não</u> realiza consulta médica, e nem possui médicos que revisam as prescrições emitidas aos Usuários.
<br>
<br>
<strong>2.7.2.</strong> A <strong>CANNECT </strong><u>não</u> vende o produto derivado de cannabis. O fornecedor fatura o pedido diretamente para o Usuário.
<br>
<br>
<strong>2.8. Idoneidade</strong>. <strong>Você</strong> declara, ao consentir com este documento para utilizar a Plataforma, que detém idoneidade incólume, ficando ciente, desde já, que as informações cadastrais falsas ou inverídicas registradas ou fornecidas durante o processo eletrônico de contratação de serviços podem constituir crime.
<br>
<br>
<strong>Capítulo III – ACESSO E RESTRIÇÕES DE FUNCIONALIDADE</strong>
<br>
<br>
<strong>3.1. Restrições</strong>. Não é permitido acessar as áreas de programação da Plataforma <strong>CANNECT</strong>, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de <em>webmastering</em>, realizar ou permitir que se realize qualquer tipo de engenharia reversa, tradução, decompilação, cópia, modificação, reprodução, locação, sublocação, sublicenciamento, publicação, divulgação, transmissão, empréstimo, distribuição ou, de qualquer outra forma, a disposição de ferramentas de consulta deste website, aplicativos, programas e de suas funcionalidades para terceiros sem a prévia e expressa anuência da <strong>CANNECT</strong>, ficando sujeito quem o fizer a penalidades da legislação brasileira, sem prejuízo da obrigação de reparar os danos que causarem. Esta restrição inclui qualquer tentativa de incorporar quaisquer informações da plataforma em qualquer outro diretório, produto ou serviço.
<br>
<br>
<strong>Capítulo IV – REMUNERAÇÃO E CANCELAMENTO –</strong>
<br>
<br>
<strong>4.1. Remuneração</strong>. Para utilização do serviço de consultoria para acesso ao produto derivado de cannabis, <strong>Você </strong>poderá realizar o pagamento através da plataforma Pagar.me.
<br>
<br>
<strong>4.1.1. Responsabilidade de pagamento.</strong> Desde já, <strong>Você </strong>está ciente que o pagamento e a aprovação deste se dará pela plataforma Pagar.me, não havendo qualquer ingerência e, consequentemente, responsabilidade da <strong>CANNECT</strong>.
<br>
<br>
<strong>4.2. Cancelamento do serviço</strong>. <strong>Você </strong>poderá cancelar seu cadastro na Plataforma a qualquer tempo por meio do endereço eletrônico faleconosco@cannect.com.br.
<br>
<br>
<strong>4.2.1. Desobrigação de reter dados</strong>. <strong>Você </strong>reconhece e concorda que a Plataforma <strong>CANNECT </strong>não possui obrigação de reter ou manter arquivados quaisquer dados/conteúdo de sua propriedade armazenados na Plataforma <strong>CANNECT</strong> após o término da relação contratual.
<br>
<br>
<strong>4.2.2</strong>. <strong>Pendências</strong>. Caso haja valores em aberto, o interessado no cancelamento deverá efetuar a sua quitação para que seja efetuado. Se, a qualquer tempo, <strong>Você</strong> deixar de efetuar o pagamento na data devida, o seu acesso será bloqueado a qualquer momento e seu Plano poderá ser cancelado.
<br>
<br>
<strong>Capítulo V – EXCLUSÃO DE GARANTIAS</strong>
<br>
<br>
<strong>5.1. Disponibilidade</strong>. A Plataforma está disponível para o seu uso e é oferecida “no estado em que se encontra” e “conforme disponível”, de acordo com o Plano que <strong>Você</strong> escolher. A <strong>CANNECT </strong>declina qualquer responsabilidade por garantias implícitas de comerciabilidade, adequação a um determinado fim, de retorno financeiro e qualquer responsabilidade pelas perdas de dados em caso de problemas no backup efetuado por <strong>Você</strong>. Além disso, a <strong>CANNECT </strong>não garante que os serviços serão fornecidos sem interrupções ou erros. Sendo assim, <strong>Você </strong>aceita que todo o risco decorrente da utilização da Plataforma <strong>CANNECT</strong> é de sua exclusiva responsabilidade.
<br>
<br>
<strong>Capítulo VI – LIMITAÇÃO DE RESPONSABILIDADE –</strong>
<br>
<br>
<strong>6.1. Limitações e Vedações. </strong>A relação entre as partes<strong> </strong>é pautada pelas seguintes limitações de responsabilidades e vedações:
<br>
<br>
<ol>
 	<li>A <strong>CANNECT </strong>NÃO SE RESPONSABILIZA PELO CUMPRIMENTO, PELO USUÁRIO, DE EXIGÊNCIAS, LIMITAÇÕES OU RESTRIÇÕES LEGAIS E/OU VEDAÇÕES IMPOSTAS PELA LEGISLAÇÃO APLICÁVEL QUANTO AOS PRODUTOS DERIVADOS DE CANNABIS.</li><br>
 	<li>A <strong>CANNECT </strong>NÃO FORNECE QUALQUER ASSISTÊNCIA MÉDICA, NÃO VIABILIZA A REALIZAÇÃO DE CONSULTAS MÉDICAS PRESENCIAIS E NÃO PRATICA A TELEMEDICINA, COMO TAMBÉM NÃO SE RESPONSABILIZA PELA RECOMENDAÇÃO DE USO DE PRODUTOS DERIVADOS DE CANNABIS EM PRESCRIÇÕES EMITIDAS POR QUAISQUER PROFISSIONAIS DE SAÚDE.</li><br>
 	<li>A <strong>CANNECT</strong>NÃO COMERCIALIZA NENHUM PRODUTO DERIVADO DE CANNABIS, COMO TAMBÉM NÃO SE RESPONSABILIZA PELA LOGÍSTICA DE IMPORTAÇÃO, EXPEDIÇÃO, APROVAÇÃO, E ENTREGA DO PRODUTO, QUE SERÁ REALIZADA DIRETAMENTE PELO FORNECEDOR, SENDO A <strong>CANNECT</strong> RESPONSÁVEL APENAS PELA REQUISIÇÃO DO PEDIDO DE COMPRA E RASTREAMENTO DO PEDIDO.</li><br>
 	<li>A <strong>CANNECT </strong>NÃO SERÁ RESPONSÁVEL POR QUAISQUER DANOS, PREJUÍZOS OU PERDAS RELACIONADAS AO PRODUTO DERIVADO DE CANNABIS DURANTE O SERVIÇO DE TRANSPORTE EXECUTADO PELO FORNECEDOR.</li><br>
 	<li>EM TODA A EXTENSÃO PERMITIDA NOS TERMOS DA LEI APLICÁVEL, A <strong>CANNECT </strong>NÃO CONFERE QUAISQUER GARANTIAS, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO SE LIMITANDO A GARANTIAS DE COMERCIABILIDADE, QUALIDADE, ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA, VALIDADE, EFICÁCIA DOS PRODUTOS DERIVADOS DE CANNABIS.</li><br>
 	<li>EM NENHUMA HIPÓTESE A<strong>CANNECT</strong> SERÁ RESPONSÁVEL POR QUAISQUER DANOS DE QUALQUER NATUREZA DECORRENTES DO USO ABUSIVO, INCAPACIDADE DE USO, RESULTADOS DO USO OU QUAISQUER EFEITOS COLATERAIS EM RAZÃO DO USO DE PRODUTO DERIVADO DE CANNABIS.</li><br>
 	<li>A <strong>CANNECT </strong>RESSALTA, NOS TERMOS DO ARTIGO 15 DA RDC 335/2020, QUE É VEDADA A ALTERAÇÃO DE FINALIDADE DA IMPORTAÇÃO DE PRODUTOS DERIVADOS DO CANABIDIOL. O USUÁRIO RECONHECE QUE O USO DE TAIS PRODUTOS É ESTRITAMENTE PESSOAL E INTRANSFERÍVEL, SENDO PROIBIDA A ENTREGA A TERCEIROS, DOAÇÃO, VENDA OU QUALQUER OUTRA UTILIZAÇÃO DIFERENTE DA INDICADA.</li><br>
</ol>
<strong>6.2. Atendimento integral</strong>.<strong> </strong>A <strong>CANNECT </strong>não garante a <strong>Você </strong>que: (a) as funções contidas na Plataforma atenderão plenamente as suas necessidades; (b) que a operação da Plataforma será ininterrupta ou livre de erros; ou (c) que a Plataforma será compatível ou funcione com qualquer outro software, aplicações ou serviços de terceiros. Dessa forma, a <strong>CANNECT </strong>responderá exclusiva e limitadamente pelos serviços por si prestados.
<br>
<br>
<strong>6.2.1.</strong> <strong>Independência entre as partes</strong>. A <strong>CANNECT </strong>reitera que não constitui com <strong>Você</strong> nenhum tipo de: (i) sociedade, associação ou representação; (ii) prestação de qualquer serviço diverso do objeto deste instrumento particular, e/ou (iii) relação empregatícia.
<br>
<br>
<strong>6.3. Responsabilidade</strong>. A <strong>CANNECT </strong>não será responsável por quaisquer perdas ou danos sofridos, resultantes de: (i) qualquer informação errada ou incompleta que seja fornecida por<strong> Você</strong>; (ii) qualquer fraude, declaração fraudulenta ou violação do dever, ou violação de qualquer uma das condições por outros<strong> </strong>Usuários; (iii) falhas nas conexões; (iv) problemas no processamento de dados por culpa de terceiros; (v) produtos ou serviços de terceiros, mesmo que adquiridos através da Plataforma; (vi) pelo conteúdo inserido por <strong>Você</strong>; (vii) por danos ou prejuízos decorrentes de decisões tomadas com base nas informações fornecidas pela Plataforma; e (viii) por problemas definidos como “caso fortuito” ou “força maior” contemplados pelo artigo 393 do Código Civil Brasileiro.
<br>
<br>
<strong>6.4. Violação de Direito de Terceiros</strong>. A <strong>CANNECT </strong>não se responsabilizará perante qualquer Usuário<strong> </strong>em relação a qualquer violação de direito de terceiros.
<br>
<br>
<strong>6.5. Danos</strong>. Em nenhum caso, a <strong>CANNECT </strong>será responsável por danos indiretos, incidentais, especiais, exemplares, punitivos ou consequentes, incluindo, danos pessoais ou à propriedade, relacionados com, ou de outra forma resultantes, de qualquer uso da Plataforma que <strong>Você </strong>possa sofrer devido às ações realizadas ou não realizadas através desta, ainda que decorrentes da conduta de terceiros.
<br>
<br>
<strong>6.6. Indenização</strong>. <strong>Você </strong>deverá indenizar a <strong>CANNECT</strong>, seus diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por terceiros decorrentes de suas atividades na Plataforma, e pelo descumprimento dos termos de uso e condições e demais políticas da mesma, e ainda pela violação de qualquer lei ou direitos de terceiros, incluindo honorários advocatícios.
<br>
<br>
<strong>Capítulo VII – INATIVAÇÃO DO CADASTRO, DÚVIDAS E SUGESTÕES</strong>
<br>
<br>
<strong>7.1. Inativação Do Cadastro, Dúvidas e Sugestões</strong>. Em caso de dúvidas ou sugestões relacionadas a este Termo de Uso da Plataforma ou quaisquer outras informações, <strong>Você</strong> pode entrar em contato com os administradores através do email faleconosco@cannect.com.br,  disponível na Plataforma, que será respondido em horário comercial, de segunda-feira a sexta-feira, das 9h00 às 18h00.
<br>
<br>
<strong>7.2.</strong> <strong>Vedação e inativação de cadastro</strong>. Como forma de manter a confiança e respeito nesta Plataforma, serão vedados atos ilegais ou incompatíveis com a proposta de zelar pela prestação dos serviços de qualidade. Assim, poderá resultar em suspensão ou inativação do seu cadastro caso <strong>Você </strong>incorra em qualquer das hipóteses elencadas abaixo:
<br>
<br>
<ol>
 	<li>Promoção da violência e/ou discriminação, baseada em questões de raça, sexo, religião, nacionalidade, orientação sexual ou de qualquer outro tipo;</li><br>
 	<li>Violação de quais leis, principalmente no que tange aos direitos autorais, propriedade intelectual, tais como cópias não autorizadas, utilização de imagens, sons, movimentos ou textos sem anuência do autor, sejam elas marcas, réplicas e/ou falsificações;</li><br>
 	<li>Violação do disposto na Política de Privacidade da Plataforma;</li><br>
 	<li>Não utilização da Plataforma por mais de 6 (seis) meses.</li><br>
</ol>
<strong>Capítulo VIII – ENGENHARIA DA PLATAFORMA</strong>
<br>
<br>
<strong>8.1. Aperfeiçoamento</strong>. <strong>Você </strong>concorda que todo e qualquer sistema, plataforma ou serviço, independentemente de seu produtor ou características, é uma espécie de produto que está sempre em atualização e aperfeiçoamento, possuindo, constante e incondicionalmente, aspectos a serem melhorados, o que não pode ser considerado em si como falha ou defeito.
<br>
<br>
<strong>8.2. Erros de Funcionamento</strong>. Eventuais erros no funcionamento da Plataforma serão corrigidos o mais breve possível, durante o período que for necessário para manutenção. A <strong>CANNECT </strong>não pretende que o funcionamento do servidor da Plataforma, bem como de seu sistema, do seu banco de dados, software e de seu website, sejam livres de erros, falhas ou de interrupções.
<br>
<br>
<strong>Capítulo IX – PROPRIEDADE INTELECTUAL –</strong>
<br>
<br>
<strong>9.1. Utilização da Propriedade Intelectual</strong>. O uso comercial da expressão “<strong>CANNECT</strong>“, como marca, nome empresarial ou nome de domínio, e o logotipo são de propriedade exclusiva dos proprietários da <strong>CANNECT</strong>, conforme disposto no respectivo ato constitutivo da organização. Da mesma forma, direitos autorais e outros direitos passíveis de proteção por propriedade industrial como estrutura, os conteúdos das telas relativas à Plataforma, assim como os programas relacionados a mesma, software, website, bancos de dados, redes e arquivos de todos dos domínios da <strong>CANNECT</strong>. Todos os direitos estão protegidos, no Brasil e internacionalmente por leis e tratados internacionais de direito autoral, marcas, patentes e desenhos industriais. Aos proprietários da <strong>CANNECT </strong>reservam-se todos os direitos relativos a autorias, marcas, patentes e desenhos industriais, quer sejam de sua propriedade ou licenciados em seu favor, a si.
<br>
<br>
<strong>9.1.1.</strong> <strong>Vedação</strong>. Não é permitido reproduzir, duplicar, copiar, vender, revender, visitar, ou de qualquer outra forma explorar para fins comerciais o conteúdo da Plataforma sem o prévio consentimento escrito dos proprietários da <strong>CANNECT</strong>. Particularmente, é expressamente proibida a utilização de mineração de dados, robôs, ou outras ferramentas de coleta e extração de dados para extrair, de forma isolada ou recorrente, qualquer parte substancial da Plataforma visando sua reutilização.
<br>
<br>
<strong>9.2. Relações com Terceiros. </strong>A Plataforma poderá fornecer links de acesso a outros websites, o que não significa que esses sites sejam de propriedade ou operados pela <strong>CANNECT</strong>. Como não possui controle sobre esses sites, a <strong>CANNECT </strong>não será responsável pelos conteúdos, práticas e serviços ofertados nos mesmos. A presença de links para outros sites não implica relação de sociedade, de supervisão, de cumplicidade ou solidariedade da <strong>CANNECT </strong>para com esses sites e seus conteúdos, salvo exceção que será expressamente divulgada.
<br>
<br>
<strong>Capítulo X – CONDIÇÕES FINAIS </strong>
<br>
<br>
<strong>10.1. Suspensão e Cancelamento do Acesso</strong>. Na eventualidade de <strong>Você </strong>descumprir qualquer disposição do Termo, desde já, reconhece e aceita que a <strong>CANNECT </strong>pode, sem aviso prévio, interromper ou suspender, temporária ou permanentemente, parte ou a totalidade, do seu acesso à Plataforma.
<br>
<br>
<strong>10.2. Advertências e suspensão</strong>. Sem prejuízo de outras medidas cabíveis, a <strong>CANNECT </strong>poderá te advertir, suspender, temporária ou definitivamente seu acesso, a qualquer tempo, se <strong>Você</strong>:
<br><br>
<ol>
 	<li>não cumprir com qualquer dispositivo do Termo ou da Política de Privacidade;</li><br>
 	<li>descumprir com quaisquer de suas obrigações ou proibições assumidas ao utilizar os serviços da plataforma;</li><br>
 	<li>utilizar, sem autorização, nome e/ou marca da <strong>CANNECT</strong>, em parte ou na sua totalidade, e violar direitos de propriedade intelectual da <strong>CANNECT</strong>;</li><br>
 	<li>não oferecer condições que possibilitem a verificação da sua identidade ou se qualquer informação fornecida estiver incorreta.</li><br>
</ol>
<br>
<br>
<strong>10.3. Tratamento dos Dados Pessoais</strong>. Para que a Plataforma tenha qualidade e para que <strong>Você </strong>possa obter resultados de forma rápida e segura, é necessário o fornecimento de dados pessoais, nos termos dispostos na Política de Privacidade da Plataforma <strong>CANNECT</strong>.
<br>
<br>
<strong>10.4. Nulidades</strong>. Se qualquer cláusula deste instrumento for considerada ilegal, inválida ou inaplicável, no seu todo ou em parte, ao abrigo de qualquer lei, essa cláusula ou parte do mesmo será nesta exata medida entendida como não fazendo parte dos Termos de Uso, sendo que a legalidade, validade e aplicabilidade das demais cláusulas não serão afetadas.
<br>
<br>
<strong>Capítulo XI – DA PROCURAÇÃO</strong>
<br>
<br>
<strong>11.1.</strong> Você autoriza a CANNECT a lhe representar  perante Instituições governamentais, com poderes especiais para acessar o Portal de Serviços do Governo Federal, obter e consultar informações necessárias referentes à obtenção dos documentos requeridos para a importação dos produtos pretendidos e para solicitar a autorização de importação de produto derivado de cannabis no Portal de Serviços do Governo Federal em seu nome.
<br>
<br>
<strong>11.2.</strong> Você confere poderes específicos para que a CANNECT informe, no Portal de Serviços do Governo Federal, os dados solicitados no formulário de “Autorização para Importação de Canabidiol” tais como:
<br>
<br>
(i) Dados pessoais do Paciente/Responsável Legal (quando houver), como nome completo, número do documento de identificação, data de nascimento e endereço e demais informações que venham a ser solicitadas;
<br>
<br>
(ii) Dados do produto, como nome comercial, composição e empresa fabricante, e demais informações que venham a ser solicitadas;
<br>
<br>
(iii) Dados do prescritor do produto, tais como nome do profissional legalmente habilitado, especialidade, número de cadastro no Conselho/UF,   e-mail, telefone e anexação da receita médica, e demais informações que venham a ser solicitadas.
<br>
<br>
<strong>11.3.</strong> Você confere, ainda, poderes específicos para que a CANNECT proceda à assinatura do termo de responsabilidade, solicitado no formulário de “Autorização para Importação de Canabidiol”, e demais assinaturas que venham a ser solicitadas.
<br>
<br>
<strong>11.4</strong>. Pode, ainda, a CANNECT realizar todos os atos necessários para o bom cumprimento desta procuração, não podendo ser substabelecido.
<br>
<br>
<strong>11.5.</strong> A presente procuração possui validade de 12 (doze) meses a contar da data do aceite deste Termo de Uso.<strong> </strong>
<br>
<br>
<strong>Capítulo XII – LEI APLICÁVEL E JURISDIÇÃO</strong>
<br>
<br>
<strong>11.1.</strong> <strong>Foro</strong>. Os presentes Termos de Uso são regidos pelas leis brasileiras, tendo como definido o foro da Rio de Janeiro/RJ, como competente para dirimir eventuais controvérsias oriundas destes, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser, devendo-se dar prioridade, sempre, às tentativas de resolução por autocomposição.`
