import { ReactNode, useMemo, useRef, useState } from 'react'
import { BsSliders } from 'react-icons/bs'
import { FiCheck, FiChevronDown } from 'react-icons/fi'
import * as Styles from './styles'

interface SelectProps {
  label: string | ReactNode
  id: string
  options: {
    label: string
    value: string
  }[]
  value?: string
  onChange: (newValue: string) => void
  width?: string
  maxWidth?: string
  height?: string
  placeholder?: string
  errorMessage?: string
  disabled?: boolean
  gap?: string
  withFilterIcon?: boolean
  canSearch?: boolean
  isLoading?: boolean
}

export default function Select({
  id,
  label,
  options,
  value,
  onChange,
  placeholder = 'selecione',
  width,
  maxWidth,
  height,
  errorMessage,
  disabled = false,
  gap,
  withFilterIcon,
  canSearch = false,
  isLoading = false
}: SelectProps) {
  const fakeSelectRef = useRef<HTMLButtonElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === value)
  }, [options, value])

  const filteredOptions = useMemo(() => {
    if (canSearch && search.trim() !== '') {
      return options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
    }
    return options
  }, [canSearch, options, search])

  const onOptionClick = (newValue: string, newLabel: string) => {
    onChange(newValue)
    setIsOpen(false)
    setSearch('')
  }

  const handleOpen = (e: any) => {
    if (e.detail === 1) {
      setIsOpen((state) => !state)
      if (canSearch) {
        setTimeout(() => {
          searchRef.current?.focus()
        }, 100)
      }
    }
  }

  if (isLoading) {
    return (
      <Styles.SelectContainer gap={gap} style={{ width: width ?? '100%', maxWidth: maxWidth ?? '100%' }}>
        {label && <label htmlFor={id}>{label}</label>}
        <Styles.FakeSelect
          isLoading={isLoading}
          type="button"
          height={height}
          hasError={errorMessage !== undefined && errorMessage.length > 0}
          onClick={(e: any) => handleOpen(e)}
          disabled>
          <Styles.Spinner />
        </Styles.FakeSelect>
      </Styles.SelectContainer>
    )
  }

  return (
    <Styles.SelectContainer gap={gap} style={{ width: width ?? '100%', maxWidth: maxWidth ?? '100%' }}>
      {isOpen && <Styles.SelectBackground onClick={() => setIsOpen(false)} />}
      {label && <label htmlFor={id}>{label}</label>}

      <Styles.FakeSelect
        type="button"
        height={height}
        hasError={errorMessage !== undefined && errorMessage.length > 0}
        onClick={(e: any) => handleOpen(e)}
        disabled={disabled}
        ref={fakeSelectRef}>
        <span className={!selectedOption ? 'fake-select-placeholder' : ''}>
          {withFilterIcon && !selectedOption && <BsSliders size="18px" />}
          {isOpen && canSearch ? (
            <Styles.SearchInputContainer>
              <input ref={searchRef} type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            </Styles.SearchInputContainer>
          ) : (
            (selectedOption?.label ?? placeholder)
          )}
        </span>
        <FiChevronDown
          style={{
            transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
            transition: 'transform 150ms linear'
          }}
        />
      </Styles.FakeSelect>
      {errorMessage !== undefined && errorMessage.length > 0 && (
        <Styles.InputErrorMessage>{errorMessage}</Styles.InputErrorMessage>
      )}

      {isOpen && (
        <Styles.FakeSelectOptionsContainer
          top={fakeSelectRef.current?.getBoundingClientRect().top ?? 0}
          left={fakeSelectRef.current?.getBoundingClientRect().left ?? 0}
          maxWidth={fakeSelectRef.current?.getBoundingClientRect().width ?? 0}>
          {filteredOptions.map((option) => {
            const isOptionSelected = selectedOption?.value === option.value
            return (
              <Styles.FakeSelectOption
                isSelected={isOptionSelected}
                key={option.value}
                onClick={() => onOptionClick(option.value, option.label)}
                type="button">
                <span>{option.label}</span>
                {isOptionSelected && <FiCheck />}
              </Styles.FakeSelectOption>
            )
          })}
        </Styles.FakeSelectOptionsContainer>
      )}
    </Styles.SelectContainer>
  )
}
