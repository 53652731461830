import styled from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
`

export const Heading = styled.h2`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
`

export const ResumeList = styled.div`
  width: 100%;
`

export const ResumeLine = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 4px 0;
`

export const Separator = styled.hr`
  border: none !important;
  width: 100%;
  height: 1px;
  background: #c3c3c3;
  margin: 6px 0;
`

const BaseText = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #3D3D3D;
`
const BaseTextValue = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #3D3D3D;
`
export const PriceTitle = styled(BaseText)<{ isTotal?: boolean }>`
  ${isTotal => {
    if (isTotal) {
      return `color: #111`
    }
    return ``
  }}
`
export const PriceValue = styled(BaseTextValue)``
export const Payment = styled(BaseText)``
