import { Symptom } from 'hooks/useCannectCare'
import EpisodesSelector from '../EpisodesSelector'
import IntensitySelector from '../IntensitySelector'
import * as Style from './styles'

interface DiseaseOccurrenceProps {
  symptom: Symptom
}

export default function DiseaseOccurrence({ symptom }: DiseaseOccurrenceProps) {
  return (
    <Style.DiseaseOccurrenceContainer>
      <h4>{symptom.label}</h4>
      {symptom.type === 'episodes' ? (
        <>
          <p>
            Quantos episódios você experimentou <strong>ontem</strong>?
          </p>
          <EpisodesSelector symptom={symptom} isExistingSymptom />
        </>
      ) : (
        <>
          <p>
            Em uma escala de 0, que significa nenhuma a 10, alta intensidade, como você classifica seu sintoma{' '}
            <strong>ontem</strong>?
          </p>
          <IntensitySelector symptom={symptom} isExistingSymptom />
        </>
      )}
    </Style.DiseaseOccurrenceContainer>
  )
}
