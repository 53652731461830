import { Form, Typography } from 'new-components/atoms'
import { BiSearchAlt2 } from 'react-icons/bi'
import useQueryString from 'hooks/useQueryString'
import { debounce } from 'lodash'
import { ChangeEvent } from 'react'

interface MyPatientsFiltersProps {
  totalResults?: number
}

const MyPatientsFilters = ({ totalResults }: MyPatientsFiltersProps) => {
  const { filters, changeFilters } = useQueryString()

  const onSearchChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    changeFilters({ search: event.target.value, page: 1 })
  }, 300)

  return (
    <div className="xl:center mt-2 flex flex-col gap-2 xl:flex-row xl:justify-between xl:gap-0">
      <div className="w-full xl:max-w-sm">
        <Form.Input
          variant="filledDark"
          size="md"
          defaultValue={filters.search || ''}
          className="w-full [&>svg]:text-primary-700"
          rigthElement={<BiSearchAlt2 />}
          onChange={onSearchChange}
          placeholder="Pesquisar"
        />
      </div>
      <Typography.Text type="captionOne" className="text-center font-serif xl:text-right">
        total de {totalResults || 0} pacient{(totalResults || 0) !== 1 ? 'es' : 'e'}
      </Typography.Text>
    </div>
  )
}

export default MyPatientsFilters
