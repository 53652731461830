import { theme } from 'design-cannect'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const AllWrapp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  align-items: flex-end;
`

export const TabContentContainer = styled.section<any>`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  gap: 0.5rem;
  width: 100%;

  button {
    justify-content: ${({ isDesktop }) => (isDesktop ? 'center' : 'center')};
    align-self: ${({ isDesktop }) => (isDesktop ? 'flex-end' : 'center')};
    width: ${({ isDesktop }) => (isDesktop ? '270px' : '120px')};
    height: auto;
  }
`

export const CardContainer = styled.div<any>`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  gap: 32px;
  a {
    color: ${theme.colors.secondary} !important;
  }
`

export const ContentItself = styled.div<any>`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;

  button {
    margin-bottom: 0;
  }

  height: auto;

  @media ${MOBILE_WIDTH} {
    align-items: center;
    justify-content: center;
  }
`
