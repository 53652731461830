/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
export function validateCpf(typedCpf: string): boolean {
  typedCpf = typedCpf.replace(/[^\d]+/g, '')

  if (
    !typedCpf ||
    typedCpf.length !== 11 ||
    typedCpf === '00000000000' ||
    typedCpf === '11111111111' ||
    typedCpf === '22222222222' ||
    typedCpf === '33333333333' ||
    typedCpf === '44444444444' ||
    typedCpf === '55555555555' ||
    typedCpf === '66666666666' ||
    typedCpf === '77777777777' ||
    typedCpf === '88888888888' ||
    typedCpf === '99999999999'
  )
    return false

  // Definindo variáveis necessárias
  let sum = 0
  let rest

  // Validando DVs
  for (let i = 1; i <= 9; i++) sum += parseInt(typedCpf.substring(i - 1, i)) * (11 - i)
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(typedCpf.substring(9, 10))) return false
  sum = 0
  for (let i = 1; i <= 10; i++) sum += parseInt(typedCpf.substring(i - 1, i)) * (12 - i)
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0

  if (rest !== parseInt(typedCpf.substring(10, 11))) return false

  return true
}

export function generateUniqueId(existingId?: number | string): number {
  const id = typeof existingId === 'string' ? Number(existingId) : existingId
  if (id !== undefined) {
    return (id % 1000) * 100 + Math.floor(Math.random() * 100)
  }
  const randomDigits1 = Math.floor(Math.random() * 900) + 100
  const randomDigits2 = Math.floor(Math.random() * 100)
  return randomDigits1 * 100 + randomDigits2
}

export function verifyTypeById(order_id: string) {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  const idRegex = /^\d{1,5}$/

  if (uuidRegex.test(order_id)) {
    return {
      type: 'UUID',
      order_id
    } as const
  }
  if (idRegex.test(order_id)) {
    return {
      type: 'ID',
      order_id: parseInt(order_id, 10)
    } as const
  }
  return null
}

/**
 * Validates a credit card expiration date.
 *
 * @param {string} value - The expiration date in the format "MM/YYYY".
 * @returns {boolean} True if the expiration date is valid and not expired, false otherwise.
 *
 * @description
 * This function validates a credit card expiration date by:
 * 1. Checking if the input format is correct (MM/YYYY).
 * 2. Verifying that the month is a valid number (1-12).
 * 3. Ensuring the expiration date is not in the past.)
 */

export const validateCardExpirationDate = (value: string): boolean => {
  const [month, year] = value.split('/')
  if (!month || !year || month.length !== 2 || year.length !== 4) return false

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const intMonth = parseInt(month, 10)
  const intYear = parseInt(year, 10)

  if (Number.isNaN(intMonth) || Number.isNaN(intYear)) return false
  if (intMonth < 1 || intMonth > 12) return false
  if (intYear < currentYear || (intYear === currentYear && intMonth < currentMonth)) return false

  return true
}
