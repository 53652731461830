import * as React from 'react'
import { SVGProps } from 'react'

const RefusedPaymentIllustration = ({ width = '182', height = '204', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 182 204" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="96.5" cy="84.5" r="78.5" fill="#F5ECD3" />
    <g clipPath="url(#clip0_3294_11281)">
      <path d="M128.594 48.7166L16.649 117.848L22.9342 127.986L134.879 58.8541L128.594 48.7166Z" fill="#4F4A3E" />
      <path
        d="M45.2522 164.487L10.2772 108.075C10.0179 107.657 9.93602 107.153 10.0496 106.674C10.1631 106.195 10.4627 105.78 10.8826 105.521L120.114 38.0652C120.534 37.8064 121.039 37.7241 121.52 37.8365C122 37.9488 122.415 38.2466 122.675 38.6645L153.66 88.6413L153.208 88.9207L122.222 38.9439C122.037 38.6456 121.74 38.433 121.397 38.3528C121.054 38.2725 120.693 38.3311 120.393 38.5157L11.1619 105.971C10.862 106.157 10.648 106.453 10.5669 106.795C10.4858 107.137 10.5443 107.497 10.7295 107.796L45.7045 164.208L45.2522 164.487Z"
        fill="#464F31"
      />
      <path
        d="M127.436 96.1882C127.075 96.2878 126.692 96.2684 126.344 96.1328C125.996 95.9972 125.701 95.7528 125.504 95.4361L119.638 85.9744C119.398 85.5859 119.322 85.118 119.428 84.6733C119.533 84.2286 119.811 83.8435 120.2 83.6024L129.699 77.7366C130.089 77.4964 130.558 77.4201 131.004 77.5245C131.45 77.6288 131.835 77.9053 132.077 78.2933L137.943 87.755C138.183 88.1435 138.259 88.6114 138.153 89.0561C138.048 89.5008 137.77 89.8859 137.381 90.127L127.882 95.9927C127.743 96.0787 127.593 96.1445 127.436 96.1882Z"
        fill="#777B3C"
      />
      <path
        d="M27.9982 103.364C27.9379 103.267 27.8722 103.173 27.8015 103.083C27.3189 102.465 26.6237 102.046 25.8495 101.908C25.0753 101.77 24.2768 101.922 23.6074 102.335C22.938 102.749 22.445 103.394 22.2229 104.147C22.0009 104.9 22.0657 105.707 22.4047 106.415C22.4534 106.518 22.508 106.619 22.5682 106.716C22.9319 107.3 23.4759 107.751 24.1187 108.001C24.7615 108.251 25.4686 108.287 26.1341 108.103C26.4248 108.023 26.7025 107.901 26.9584 107.742C27.6773 107.297 28.1906 106.587 28.3855 105.766C28.5804 104.945 28.4411 104.081 27.9982 103.364ZM26.6789 107.291C26.079 107.661 25.3569 107.779 24.6711 107.618C23.9853 107.458 23.3917 107.033 23.0205 106.436C22.9611 106.339 22.9073 106.238 22.8594 106.134C22.5932 105.548 22.5506 104.885 22.7393 104.268C22.9281 103.652 23.3354 103.125 23.8851 102.786C24.4347 102.447 25.0892 102.319 25.7259 102.425C26.3627 102.532 26.9382 102.867 27.3449 103.367C27.419 103.454 27.4862 103.546 27.5459 103.644C27.9154 104.241 28.0315 104.961 27.869 105.645C27.7064 106.329 27.2784 106.921 26.6789 107.291Z"
        fill="#777B3C"
      />
      <path
        d="M21.0648 107.644C20.7451 107.129 20.595 106.526 20.6351 105.921C20.6753 105.315 20.9038 104.737 21.2889 104.267C20.8343 104.369 20.4108 104.579 20.0535 104.877C19.6962 105.176 19.4156 105.556 19.2349 105.984C19.0542 106.413 18.9788 106.878 19.0149 107.341C19.0509 107.804 19.1975 108.252 19.4423 108.646C19.6871 109.041 20.0231 109.372 20.4222 109.611C20.8214 109.85 21.2722 109.99 21.737 110.02C22.2019 110.05 22.6673 109.968 23.0947 109.782C23.522 109.597 23.8988 109.312 24.1936 108.952C23.6 109.086 22.9795 109.032 22.4187 108.797C21.8579 108.563 21.3846 108.16 21.0648 107.644Z"
        fill="#777B3C"
      />
      <path
        d="M104.216 98.2073C104.108 98.027 103.964 97.8699 103.794 97.745C103.625 97.6201 103.432 97.5299 103.227 97.4794C103.022 97.429 102.809 97.4194 102.6 97.4511C102.392 97.4828 102.191 97.5552 102.01 97.6642L86.3169 107.124L84.6268 108.143L96.1299 104.952L97.82 103.933L103.671 100.407C104.035 100.187 104.297 99.8316 104.4 99.4191C104.502 99.0066 104.436 98.5708 104.216 98.2073Z"
        fill="#9F9374"
      />
      <path
        d="M91.2005 98.0034C90.9803 97.64 90.6243 97.379 90.2107 97.2776C89.7971 97.1763 89.3598 97.2429 88.995 97.463L65.4465 111.657C65.1178 111.856 64.8713 112.166 64.7513 112.531C64.6314 112.896 64.6459 113.291 64.7923 113.645L71.3995 111.812L73.0921 110.793L90.6555 100.206C91.0202 99.9853 91.2825 99.6294 91.3847 99.2164C91.4869 98.8034 91.4206 98.3672 91.2005 98.0034Z"
        fill="#9F9374"
      />
      <path
        d="M158.905 86.9877L35.1635 121.314C34.6882 121.446 34.2846 121.761 34.0414 122.189C33.7981 122.618 33.735 123.124 33.866 123.598L54.1246 196.629C54.2566 197.103 54.5717 197.505 55.0009 197.747C55.43 197.988 55.9382 198.05 56.4138 197.919L180.155 163.593C180.631 163.461 181.034 163.146 181.278 162.717C181.521 162.289 181.584 161.782 181.453 161.308L161.194 88.2777C161.062 87.8039 160.747 87.4021 160.318 87.1602C159.889 86.9184 159.381 86.8564 158.905 86.9877ZM180.941 161.45C181.034 161.789 180.989 162.151 180.816 162.457C180.642 162.763 180.353 162.988 180.014 163.083L56.2721 197.408C55.9324 197.502 55.5693 197.458 55.2627 197.285C54.9561 197.113 54.731 196.825 54.637 196.487L34.3783 123.456C34.2846 123.118 34.3295 122.756 34.5033 122.45C34.6771 122.144 34.9655 121.919 35.3051 121.824L159.047 87.4984C159.387 87.4043 159.75 87.4485 160.056 87.6213C160.363 87.7941 160.588 88.0813 160.682 88.4198L180.941 161.45Z"
        fill="#464F31"
      />
      <path
        d="M49.9664 127.933C49.3583 128.102 48.7141 128.087 48.1151 127.89C47.5161 127.694 46.9893 127.325 46.6013 126.829C46.2133 126.334 45.9815 125.735 45.9353 125.107C45.889 124.48 46.0304 123.853 46.3414 123.305C46.6525 122.757 47.1193 122.313 47.6829 122.03C48.2465 121.746 48.8814 121.635 49.5075 121.711C50.1336 121.787 50.7227 122.047 51.2003 122.457C51.6779 122.867 52.0226 123.41 52.1907 124.016C52.4151 124.828 52.307 125.697 51.89 126.432C51.473 127.166 50.7812 127.706 49.9664 127.933ZM48.408 122.315C47.9013 122.456 47.4475 122.743 47.1041 123.14C46.7606 123.538 46.5428 124.028 46.4784 124.548C46.4139 125.068 46.5056 125.596 46.7418 126.064C46.9781 126.532 47.3483 126.919 47.8057 127.177C48.263 127.434 48.787 127.551 49.3113 127.512C49.8356 127.472 50.3367 127.279 50.7512 126.956C51.1657 126.633 51.475 126.194 51.6401 125.696C51.8051 125.198 51.8184 124.663 51.6783 124.158C51.4897 123.481 51.0395 122.907 50.4264 122.562C49.8133 122.216 49.0875 122.127 48.408 122.315Z"
        fill="#777B3C"
      />
      <path
        d="M44.3378 126.194C44.1757 125.61 44.2006 124.989 44.409 124.419C44.6174 123.849 44.9989 123.357 45.5004 123.012C45.0354 122.984 44.5702 123.067 44.1436 123.254C43.7169 123.442 43.3412 123.728 43.0476 124.089C42.754 124.45 42.5511 124.876 42.4558 125.331C42.3606 125.786 42.3756 126.257 42.4998 126.704C42.6239 127.152 42.8536 127.563 43.1696 127.904C43.4856 128.245 43.8788 128.505 44.3165 128.664C44.7543 128.822 45.2237 128.874 45.6859 128.814C46.1481 128.755 46.5895 128.587 46.9735 128.323C46.3663 128.286 45.786 128.061 45.3136 127.68C44.8411 127.298 44.5 126.779 44.3378 126.194Z"
        fill="#777B3C"
      />
      <path
        d="M71.6213 157.615C71.0098 157.784 70.3565 157.704 69.8048 157.393C69.253 157.082 68.8478 156.565 68.6781 155.956L66.7656 149.062C66.5965 148.452 66.6773 147.8 66.9901 147.249C67.303 146.699 67.8223 146.294 68.4338 146.124C69.0453 145.954 69.699 146.034 70.2509 146.345C70.8029 146.656 71.208 147.173 71.377 147.783L73.2896 154.677C73.4579 155.287 73.3768 155.938 73.064 156.489C72.7513 157.04 72.2325 157.445 71.6213 157.615Z"
        fill="#777B3C"
      />
      <path
        d="M77.7699 155.909C77.1584 156.078 76.5051 155.998 75.9533 155.687C75.4016 155.377 74.9964 154.86 74.8266 154.251L72.9141 147.356C72.745 146.747 72.8258 146.095 73.1387 145.544C73.4515 144.993 73.9708 144.588 74.5823 144.418C75.1939 144.249 75.8475 144.328 76.3995 144.639C76.9514 144.95 77.3565 145.468 77.5256 146.077L79.4381 152.972C79.6064 153.581 79.5253 154.233 79.2126 154.783C78.8998 155.334 78.381 155.739 77.7699 155.909Z"
        fill="#777B3C"
      />
      <path
        d="M83.9186 154.204C83.3071 154.373 82.6538 154.293 82.102 153.982C81.5503 153.671 81.145 153.154 80.9753 152.545L79.0628 145.651C78.8937 145.041 78.9745 144.389 79.2874 143.838C79.6002 143.287 80.1195 142.883 80.731 142.713C81.3426 142.543 81.9962 142.623 82.5482 142.934C83.1001 143.245 83.5052 143.762 83.6743 144.371L85.5868 151.266C85.7551 151.875 85.674 152.527 85.3613 153.078C85.0485 153.629 84.5297 154.033 83.9186 154.204Z"
        fill="#777B3C"
      />
      <path
        d="M93.3977 151.574C92.7862 151.743 92.1329 151.663 91.5812 151.353C91.0294 151.042 90.6242 150.525 90.4545 149.916L88.5419 143.021C88.3729 142.412 88.4536 141.76 88.7665 141.209C89.0793 140.658 89.5986 140.253 90.2102 140.084C90.8217 139.914 91.4753 139.993 92.0273 140.304C92.5793 140.615 92.9843 141.133 93.1534 141.742L95.0659 148.637C95.2343 149.246 95.1532 149.898 94.8404 150.448C94.5277 150.999 94.0088 151.404 93.3977 151.574Z"
        fill="#777B3C"
      />
      <path
        d="M99.5464 149.869C98.9349 150.038 98.2816 149.958 97.7298 149.647C97.1781 149.336 96.7729 148.819 96.6031 148.21L94.6906 141.316C94.5215 140.706 94.6023 140.054 94.9152 139.503C95.228 138.952 95.7473 138.548 96.3588 138.378C96.9704 138.208 97.624 138.288 98.176 138.599C98.7279 138.91 99.133 139.427 99.3021 140.037L101.215 146.931C101.383 147.541 101.302 148.192 100.989 148.743C100.676 149.294 100.158 149.699 99.5464 149.869Z"
        fill="#777B3C"
      />
      <path
        d="M105.695 148.163C105.084 148.332 104.43 148.252 103.879 147.941C103.327 147.63 102.922 147.114 102.752 146.505L100.839 139.61C100.67 139.001 100.751 138.349 101.064 137.798C101.377 137.247 101.896 136.842 102.508 136.672C103.119 136.503 103.773 136.582 104.325 136.893C104.877 137.204 105.282 137.721 105.451 138.331L107.363 145.225C107.532 145.835 107.451 146.487 107.138 147.037C106.825 147.588 106.306 147.993 105.695 148.163Z"
        fill="#777B3C"
      />
      <path
        d="M115.174 145.533C114.563 145.702 113.909 145.622 113.358 145.312C112.806 145.001 112.401 144.484 112.231 143.875L110.318 136.98C110.149 136.371 110.23 135.719 110.543 135.168C110.856 134.617 111.375 134.212 111.987 134.043C112.598 133.873 113.252 133.952 113.804 134.263C114.356 134.574 114.761 135.092 114.93 135.701L116.842 142.596C117.011 143.205 116.93 143.857 116.617 144.407C116.304 144.958 115.785 145.363 115.174 145.533Z"
        fill="#777B3C"
      />
      <path
        d="M121.323 143.828C120.711 143.997 120.058 143.917 119.506 143.606C118.955 143.295 118.549 142.778 118.38 142.169L116.467 135.275C116.298 134.665 116.379 134.013 116.692 133.462C117.005 132.911 117.524 132.507 118.135 132.337C118.747 132.167 119.4 132.247 119.952 132.558C120.504 132.869 120.91 133.386 121.079 133.996L122.991 140.89C123.159 141.499 123.078 142.151 122.766 142.702C122.453 143.253 121.934 143.658 121.323 143.828Z"
        fill="#777B3C"
      />
      <path
        d="M127.472 142.122C126.86 142.291 126.207 142.211 125.655 141.9C125.103 141.589 124.698 141.073 124.528 140.464L122.616 133.569C122.447 132.96 122.527 132.308 122.84 131.757C123.153 131.206 123.672 130.801 124.284 130.631C124.896 130.462 125.549 130.541 126.101 130.852C126.653 131.163 127.058 131.68 127.227 132.29L129.14 139.184C129.308 139.794 129.227 140.446 128.914 140.996C128.602 141.547 128.083 141.952 127.472 142.122Z"
        fill="#777B3C"
      />
      <path
        d="M136.951 139.493C136.339 139.662 135.686 139.582 135.134 139.271C134.582 138.96 134.177 138.443 134.007 137.834L132.095 130.94C131.926 130.33 132.007 129.678 132.319 129.127C132.632 128.576 133.152 128.172 133.763 128.002C134.375 127.832 135.028 127.912 135.58 128.223C136.132 128.534 136.537 129.051 136.706 129.661L138.619 136.555C138.787 137.165 138.706 137.816 138.393 138.367C138.081 138.918 137.562 139.323 136.951 139.493Z"
        fill="#777B3C"
      />
      <path
        d="M143.099 137.787C142.488 137.956 141.835 137.876 141.283 137.565C140.731 137.254 140.326 136.738 140.156 136.129L138.244 129.234C138.075 128.625 138.155 127.973 138.468 127.422C138.781 126.871 139.3 126.466 139.912 126.296C140.523 126.127 141.177 126.206 141.729 126.517C142.281 126.828 142.686 127.345 142.855 127.955L144.768 134.849C144.936 135.459 144.855 136.111 144.542 136.661C144.229 137.212 143.71 137.617 143.099 137.787Z"
        fill="#777B3C"
      />
      <path
        d="M149.248 136.082C148.636 136.251 147.983 136.171 147.431 135.86C146.88 135.549 146.474 135.032 146.305 134.423L144.392 127.529C144.223 126.919 144.304 126.267 144.617 125.716C144.93 125.165 145.449 124.76 146.06 124.591C146.672 124.421 147.326 124.501 147.878 124.812C148.429 125.123 148.835 125.64 149.004 126.249L150.916 133.144C151.084 133.753 151.003 134.405 150.691 134.956C150.378 135.506 149.859 135.911 149.248 136.082Z"
        fill="#777B3C"
      />
      <path
        d="M94.3489 178.857L61.1871 188.056C60.7762 188.17 60.337 188.116 59.9662 187.907C59.5953 187.698 59.3231 187.351 59.2095 186.941C59.0959 186.532 59.1502 186.094 59.3604 185.723C59.5706 185.353 59.9195 185.081 60.3304 184.967L93.4922 175.768C93.9031 175.654 94.3423 175.708 94.7132 175.917C95.0841 176.126 95.3563 176.473 95.4699 176.883C95.5835 177.292 95.5292 177.73 95.319 178.1C95.1088 178.471 94.7598 178.743 94.3489 178.857Z"
        fill="#9F9374"
      />
      <path
        d="M81.8753 175.167L69.9776 178.467C69.5667 178.581 69.1275 178.528 68.7566 178.319C68.3857 178.11 68.1135 177.762 67.9999 177.353C67.8863 176.943 67.9406 176.505 68.1508 176.135C68.361 175.765 68.7099 175.493 69.1208 175.379L81.0185 172.078C81.4294 171.964 81.8686 172.018 82.2395 172.227C82.6104 172.436 82.8826 172.783 82.9962 173.193C83.1098 173.602 83.0555 174.04 82.8453 174.411C82.6351 174.781 82.2862 175.053 81.8753 175.167Z"
        fill="#9F9374"
      />
      <path d="M164.328 101.923L38.0248 136.959L38.1664 137.47L164.47 102.434L164.328 101.923Z" fill="#464F31" />
    </g>
    <path
      d="M55 66C73.2254 66 88 51.2254 88 33C88 14.7746 73.2254 0 55 0C36.7746 0 22 14.7746 22 33C22 51.2254 36.7746 66 55 66Z"
      fill="#E87883"
    />
    <path
      d="M65.7201 41.1589L46.8408 22.2796C46.1339 21.5727 44.9878 21.5727 44.2809 22.2796C43.574 22.9865 43.574 24.1326 44.2809 24.8395L63.1602 43.7188C63.8671 44.4257 65.0132 44.4257 65.7201 43.7188C66.427 43.0119 66.427 41.8658 65.7201 41.1589Z"
      fill="white"
    />
    <path
      d="M46.8411 43.7201L65.7204 24.8408C66.4273 24.1339 66.4273 22.9878 65.7204 22.2809C65.0135 21.574 63.8674 21.574 63.1605 22.2809L44.2812 41.1602C43.5743 41.8671 43.5743 43.0132 44.2812 43.7201C44.9881 44.427 46.1342 44.427 46.8411 43.7201Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_3294_11281">
        <rect width="150.793" height="137.084" fill="white" transform="translate(0 71.3076) rotate(-15.504)" />
      </clipPath>
    </defs>
  </svg>
)
export const RefusedPayment = React.memo(RefusedPaymentIllustration)
