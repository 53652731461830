import styled from 'styled-components'

export const Container = styled.main`
  position: fixed;
  height: calc(100vh - 90px);
  width: 100vw;

  top: 90px;
  left: 0;
  z-index: 9999;
`
