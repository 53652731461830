import Slider from 'new-components/Slider'
import { Settings } from 'react-slick'
import * as Styles from './styles'

export type BannerDataProps = {
  id: number
  alt: string
  img: string
  link: string
}

export type BannerProps = {
  data: BannerDataProps[]
}

export default function Banner({ data = [] }: BannerProps) {
  const settings: Settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4000,
    cssEase: 'ease'
  }

  const redirectLink = (link = '') => {
    window.open(link, '_blank', 'noopener')
  }

  return (
    <Styles.Wrapper>
      <Slider settings={settings}>
        {data.map(item => (
          <Styles.BannersContainer key={item.id} onClick={() => redirectLink(item.link)}>
            <Styles.ImageBanner src={item.img} alt={item.alt} />
          </Styles.BannersContainer>
        ))}
      </Slider>
    </Styles.Wrapper>
  )
}
