import { FiPlus, FiMinus } from 'react-icons/fi'
import * as Style from './styles'

interface SelectorButtonProps {
  type: 'increase' | 'decrease'
  disabled?: boolean
  onClick: () => void
}

export default function SelectorButton({ type, disabled, onClick }: SelectorButtonProps) {
  return (
    <Style.SelectorButtonContainer disabled={disabled} onClick={onClick}>
      {type === 'increase' ? <FiPlus /> : <FiMinus />}
    </Style.SelectorButtonContainer>
  )
}
