import { ReactNode, useEffect, useState } from 'react'
import Button from './Button'
import * as Style from './styles'

interface FilterCardProps {
  children: ReactNode
  onRemoveClick: () => void
  background: 'green' | 'yellow' | 'blue' | 'pink'
}

type ColorPossibilities = '#AFDDAE' | '#AEC6DD' | '#D9AEDD' | '#DDCDAE'

export default function FilterCard({ children, onRemoveClick, background }: FilterCardProps) {
  const [formattedBackground, setFormattedBackground] = useState<ColorPossibilities>()

  useEffect(() => {
    switch (background) {
      case 'green':
        setFormattedBackground('#AFDDAE')
        break
      case 'yellow':
        setFormattedBackground('#DDCDAE')
        break
      case 'blue':
        setFormattedBackground('#AEC6DD')
        break
      case 'pink':
        setFormattedBackground('#D9AEDD')
        break
      default:
        break
    }
  }, [background])

  function handleRemoveClick() {
    onRemoveClick()
  }

  return (
    <Style.FilterCardContainer style={{ background: formattedBackground }}>
      <span>{children}</span>
      <Button onClick={() => handleRemoveClick()} />
    </Style.FilterCardContainer>
  )
}
