import styled from 'styled-components'
import { theme } from 'design-cannect'

export const RefButton = styled.a`
  border: none;
  background: none;
  text-decoration: underline;
  color: ${theme.colors.secondary};
  font-weight: bold;
  font-size: 15px;
`

export const Footer = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;

  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'null')};

  padding-top: 36px;
  padding-bottom: 36px;
  gap: 5px;

  .href {
    text-decoration: underline;
  }
`
