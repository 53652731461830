import React from 'react'
import { formatZipCode } from 'utils/formatters'
import { STATE_LIST } from 'utils/constants'
import * as Style from './styles'

type Address = {
  cep?: string
  state?: string
  city?: string
  address_number?: string
  street?: string
  district?: string
  complement?: string
}
interface FormAddressProps {
  isMobile: boolean
  changeAddress: ({ fieldToChange, newValue }: { fieldToChange: string; newValue: string }) => void
  address: Address
}

function FormAddress({ changeAddress, address }: FormAddressProps) {
  function onlyNumbers(input: string) {
    return input.replace(/[^0-9]/g, '')
  }

  return (
    <Style.FormContainer>
      <div className="input-column">
        <span>CEP</span>
        <input
          type="text"
          id="cep"
          required
          value={formatZipCode(address?.cep)}
          onChange={event => changeAddress({ fieldToChange: 'cep', newValue: event.target.value })}
          maxLength={9}
        />

        <span>Endereço</span>
        <input
          type="text"
          id="street"
          required
          value={address?.street}
          onChange={event => changeAddress({ fieldToChange: 'street', newValue: event.target.value })}
        />
      </div>

      <div className="input-groups">
        <div>
          <span>N°</span>
          <input
            type="text"
            id="address_number"
            required
            value={address?.address_number}
            onChange={event => {
              const onlyNumValue = onlyNumbers(event.target.value)
              changeAddress({ fieldToChange: 'address_number', newValue: onlyNumValue })
            }}
          />
        </div>
        <div>
          <span>Complemento</span>
          <input
            type="text"
            id="complement"
            required
            value={address?.complement}
            onChange={event => changeAddress({ fieldToChange: 'complement', newValue: event.target.value })}
          />
        </div>
        <div>
          <span>Bairro</span>
          <input
            type="text"
            id="district"
            required
            value={address?.district}
            onChange={event => changeAddress({ fieldToChange: 'district', newValue: event.target.value })}
          />
        </div>
      </div>

      <div className="input-groups">
        <div>
          <span>Cidade</span>
          <input
            type="text"
            id="city"
            required
            value={address?.city}
            onChange={event => changeAddress({ fieldToChange: 'city', newValue: event.target.value })}
          />
        </div>
        <div>
          <span>Estado</span>
          <select
            required
            value={address?.state}
            onChange={event => changeAddress({ fieldToChange: 'state', newValue: event.target.value })}
          >
            <option>Selecione um estado</option>
            {STATE_LIST.map(uf => (
              <option key={uf}>{uf}</option>
            ))}
          </select>
        </div>
      </div>
    </Style.FormContainer>
  )
}

export default FormAddress
