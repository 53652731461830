import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import * as requests from './requests'

export const KEY_GET_WEBINAR_DETAIL = 'getWebinarDetail'
export const KEY_FIND_ALREADY_SUBSCRIBED_USER = 'findAlreadySubscribedUser'

export const useGetWebinarDetail = (title: string) =>
  useQuery({
    queryKey: [KEY_GET_WEBINAR_DETAIL, title],
    queryFn: () => requests.getWebinarDetail(title),
    meta: {
      errorMessage: 'Nenhum Webinar encontrado.'
    }
  })

export const useFindAlreadySubscribedUserMutation = () => {
  return useMutation({
    mutationFn: requests.findAlreadySubscribedUser
  })
}

export const useSubscribeUserMutation = () => {
  return useMutation({
    mutationFn: requests.subscribe,
    onSuccess: () => {
      toast.success('Inscrição realizada com sucesso!')
    },
    onError: error => {
      toast.error(
        `Erro ao realizar a inscrição, vefique os campos obrigatórios. Erro: ${error?.response?.data?.message || error?.response?.data?.error}`
      )
    }
  })
}

export const useValidateCRMMutation = () => {
  return useMutation({
    mutationFn: requests.validateCRM,
    onSuccess: () => {
      toast.success('CRM validado com sucesso!')
    },
    onError: () => {
      toast.error(`CRM informado não existe ou é inválido.`)
    }
  })
}
