import React, { useEffect, useState } from 'react'
import BreadCrumb from 'components/BreadCrumb'
import MyCart from './MyCart'
import * as Styles from './styles'
import { deleteProductByID, getAllProduct, IncrementProductByID } from '../../hooks/useShoppingCart'
import useMediaQuery from '../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../utils/constants'
import api from '../../services/api'
import Pagination from '../Pagination'
import EmptyState from '../EmptyState'

const limitPage = 15
function ShoppingCartComponent() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const data = getAllProduct()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [products, setProducts] = useState(data)

  const deleteProduct = (id: number) => {
    deleteProductByID(id)
    setProducts(products.filter((product: any) => product.id !== id))
  }

  const IncrementProduct = (id: any) => {
    IncrementProductByID(id)
    setProducts(
      products.map((product: any) => {
        if (product.id === id) {
          return {
            ...product,
            amount: product.amount + 1
          }
        }
        return product
      })
    )
  }

  const getProductsFavoritos = async () => {
    try {
      const {
        data: {
          products: { rows, count }
        }
      } = await api.get(`/product/favorites/?page=${page}&limit=${limitPage}`)
      setCount(Math.ceil(count / limitPage))
      setProducts(rows)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProductsFavoritos()
  }, [page])

  const DecrementProduct = async (id: any) => {
    removeFavorite(id)
    try {
      await api.post(`/product/favorite/${id}`)
    } catch (error) {
      console.log(error)
    }
  }

  const removeFavorite = (id: any) => {
    setProducts(products.filter((product: any) => product.id !== id))
  }

  const DeleteProducts = async () => {
    try {
      await api.delete(`/product/favorites`)
      setProducts([])
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Styles.BreadCrumpWrapper>
      <BreadCrumb paths={[{ label: 'Favoritos', link: '/meus-favoritos' }]} />
      <Styles.Container isMobile={isMobile}>
        {products.length > 0 ? (
          <MyCart
            products={products}
            IncrementProduct={IncrementProduct}
            DecrementProduct={DecrementProduct}
            DeleteProducts={DeleteProducts}
            deleteProduct={deleteProduct}
          />
        ) : (
          <span className="empty">
            <EmptyState title="Não localizamos produtos " subTitle="Não localizamos nenhum produtos favoritado!" />
          </span>
        )}
      </Styles.Container>
      <div className="page">
        {products.length > 0 && count > 1 && (
          <Pagination
            forcePage={page - 1}
            onPageChange={({ selected }: any) => setPage(selected + 1)}
            pageCount={count}
          />
        )}
      </div>
    </Styles.BreadCrumpWrapper>
  )
}

export default ShoppingCartComponent
