import { createContext, ReactNode, useContext, useState } from 'react'

const MiniCheckoutContext = createContext(null as any)

function MiniCheckoutProvider({ children }: { children: ReactNode }) {
  const [modalCheckout, setModalCheckout] = useState(false)

  const closeCheckout = () => {
    setModalCheckout(false)
  }

  const openCheckout = () => {
    setModalCheckout(true)
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MiniCheckoutContext.Provider value={{ modalCheckout, closeCheckout, openCheckout }}>
      {children}
    </MiniCheckoutContext.Provider>
  )
}

function useMiniCheckout() {
  return useContext(MiniCheckoutContext)
}

export { MiniCheckoutProvider, useMiniCheckout }

