import styled from 'styled-components'
import { theme } from 'design-cannect'
import { MOBILE_WIDTH } from 'utils/constants'
import { temporaryTheme } from 'styles/theme'
import { media } from 'helpers/mediaQueryStyle'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    font-family: 'Poppins', sans-serif;
  }
`

export const Main = styled.main<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  padding: 1.5rem;
  //border: 1px solid #ecece2;
  border-radius: 0.25rem;

  width: 90%;
  margin-bottom: 5rem;

  h2 {
    color: #424242;
    font-weight: 500;
    font-size: ${props => (props.isMobile ? '27px' : '32px')};
    text-align: ${props => (props.isMobile ? 'left' : 'center')};
  }
`

export const WhatToPrescribeContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  a {
    margin: 1rem 0;
    color: ${theme.colors.tertiary};
    text-decoration: none;
  }

  ${media.tablet`
      flex-direction: column;
      width: 100%;

      button {
      width: 100%;
    }
    `}

  ${media.mobile`
      width: 100%;
      flex-direction: column;

      button {
      width: 100%;
    }
    `}
`

export const PrescriptionOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  width: 100%;
  margin-bottom: 68px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    width: 100%;

    ${media.tablet`
      flex-direction: column;
      width: 100%;
    `}

    ${media.mobile`
      width: 100%;
      flex-direction: column;
    `}
  }

  button {
    width: 100%;

    text-transform: uppercase;

    ${media.desktop`
      max-width: 360px;
    `}
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;

  ${media.tablet`
    width: 100%;
      button{
        width: 50%;
      }
  `}

  ${media.mobile`
    flex-direction: column-reverse;
    width: 100%;
      button{
        width: 100%;
      }
  `}
`

export const InfoContainer = styled.div<any>`
  display: flex;
  background: #f8fafd;
  border-radius: 15.0757px;
  justify-content: center;
  align-items: center;
  padding: 48px 51px;
  margin-top: 70px;
  margin-bottom: 70px;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    padding: 28px 30px;
    margin-top: 50px;
    gap: 12px;
  }
`

export const InfoContent = styled.div<any>`
  display: flex;
  flex-direction: column !important;
  align-items: baseline !important;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: left;
    color: #115850;
  }

  p {
    color: #1e1e1e;
    font-style: normal;
    font-weight: 300;
    font-size: ${props => (props.isMobile ? '11px' : '13px')};
    line-height: 20px;
    margin-top: 10px;
  }
`

export const InfoButton = styled.button<any>`
  border: 1.50757px solid #21976e;
  border-radius: 12.0605px;
  background-color: transparent;
  width: 400px;
  height: 45px;
  margin-left: ${props => (props.isMobile ? '10px' : '60px')};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #21976e;

  @media ${MOBILE_WIDTH} {
    margin-left: 0;
    margin-top: 20px;
  }
`

export const ContentModalNotTemplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${temporaryTheme.spacing.space3};

  @media ${MOBILE_WIDTH} {
    padding: 0;
    h2 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    main {
      margin: 0;
      margin-bottom: 14px;
    }
    #no-template-video {
      height: auto !important;
      padding-top: 40px;
    }
  }

  .action-box {
    margin: 34px 0 12px 0;

    button {
      font-family: 'Nunito', sans-serif;
      font-size: 16px;
      min-width: 200px;
    }
  }
`

export const ModalPatient = styled.div`
  min-width: 500px;
  text-align: center;
  .actions_box {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-direction: column;
  }

  button {
    min-width: unset;
    min-height: 35px;
    height: unset;
    width: 100%;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
  }
`
