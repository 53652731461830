import styled from 'styled-components'

export const Container = styled.div``

export const ModalContent = styled.form<any>`
  width: ${props => (props.isMobile ? '100%' : '600px')};
  overflow-x: hidden;

  h3 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 24px;
    text-align: center;
    color: #115850;
  }

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    text-align: center;

    color: #000000;
  }

  .fields {
    h2 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 16px;
      color: #115850;
    }
    .fields-container {
      display: flex;
      flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
      flex-wrap: wrap;
      width: 100%;
      margin-left: 50px;
      gap: 10px;
      margin-top: 16px;
    }
    .field {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: ${props => (props.isMobile ? '80%' : '40%')};
      margin-bottom: 8px;

      select {
        height: 40px;
        border: 1px solid black;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }

      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #000000;
        margin-bottom: 8px;
      }

      input {
        width: 100%;
        font-weight: 300 !important;
        margin-right: auto;
        height: 40px;
        border-radius: 4px;
        border: 1px solid black;
        padding-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 10px;

    button {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: ${props => (props.isMobile ? '12px' : '14px')};
      line-height: 24px;
      margin-top: 20px;
      text-align: center;
      background-color: #c5581b;
      color: white;
      border: none;
      border-radius: 4px;
      width: 40%;
      height: 50px;
      text-transform: uppercase;
    }

    .no-account-btn {
      color: #c5581b;
      background-color: white;
    }

    .account-btn:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background-color: #c5581b;
    }
  }
`
