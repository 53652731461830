export function VideoMp4() {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      data-testid="file_icon_video_mp4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9747 0.208008H4.12508C3.07527 0.208008 2.06845 0.625045 1.32612 1.36738C0.583785 2.10971 0.166748 3.11653 0.166748 4.16634V35.833C0.166748 36.8828 0.583785 37.8896 1.32612 38.632C2.06845 39.3743 3.07527 39.7913 4.12508 39.7913H27.8751C28.9249 39.7913 29.9317 39.3743 30.674 38.632C31.4164 37.8896 31.8334 36.8828 31.8334 35.833V13.0668C31.8334 12.4369 31.5832 11.8328 31.1378 11.3874L20.654 0.903629C20.2086 0.45823 19.6045 0.208008 18.9747 0.208008ZM17.9792 14.0622V4.16639L27.8751 14.0622H17.9792Z"
        fill="#151439"
      />
      <path
        d="M6.32273 30.0188C6.32273 29.8457 6.38226 29.7009 6.50132 29.5845C6.62308 29.4682 6.78408 29.41 6.98432 29.41C7.18456 29.41 7.3442 29.4682 7.46326 29.5845C7.58503 29.7009 7.64591 29.8457 7.64591 30.0188C7.64591 30.192 7.58503 30.3368 7.46326 30.4531C7.3442 30.5695 7.18456 30.6277 6.98432 30.6277C6.78408 30.6277 6.62308 30.5695 6.50132 30.4531C6.38226 30.3368 6.32273 30.192 6.32273 30.0188ZM9.62264 24.6733H10.6536L12.1716 29.0122L13.6896 24.6733H14.7205L12.5856 30.583H11.7576L9.62264 24.6733ZM9.06658 24.6733H10.0935L10.2802 28.9026V30.583H9.06658V24.6733ZM14.2497 24.6733H15.2807V30.583H14.063V28.9026L14.2497 24.6733ZM18.9662 28.4765H17.4603V27.5267H18.9662C19.1989 27.5267 19.3883 27.4888 19.5344 27.4131C19.6805 27.3346 19.7874 27.2263 19.855 27.0883C19.9227 26.9503 19.9565 26.7948 19.9565 26.6216C19.9565 26.4457 19.9227 26.282 19.855 26.1305C19.7874 25.9789 19.6805 25.8572 19.5344 25.7652C19.3883 25.6732 19.1989 25.6272 18.9662 25.6272H17.8825V30.583H16.6648V24.6733H18.9662C19.4289 24.6733 19.8253 24.7572 20.1554 24.925C20.4882 25.09 20.7426 25.3187 20.9185 25.6109C21.0943 25.9032 21.1823 26.2373 21.1823 26.6135C21.1823 26.995 21.0943 27.3251 20.9185 27.6038C20.7426 27.8825 20.4882 28.0976 20.1554 28.2492C19.8253 28.4007 19.4289 28.4765 18.9662 28.4765ZM26.3574 28.375V29.2923H22.0997L22.0428 28.582L24.5066 24.6733H25.436L24.4294 26.3578L23.2037 28.375H26.3574ZM25.6877 24.6733V30.583H24.5187V24.6733H25.6877Z"
        fill="white"
      />
    </svg>
  )
}
