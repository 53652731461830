import { SVGProps } from 'react'

export const BarCodeIcon = ({ width = '22', height = '22', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 6.55556V4.77778C3 4.30628 3.1873 3.8541 3.5207 3.5207C3.8541 3.1873 4.30628 3 4.77778 3H6.55556"
      fill="#748351"
    />
    <path
      d="M3 6.55556V4.77778C3 4.30628 3.1873 3.8541 3.5207 3.5207C3.8541 3.1873 4.30628 3 4.77778 3H6.55556"
      stroke="#748351"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4443 3H17.2221C17.6936 3 18.1458 3.1873 18.4792 3.5207C18.8126 3.8541 18.9999 4.30628 18.9999 4.77778V6.55556"
      stroke="#748351"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9999 15.4443V17.2221C18.9999 17.6936 18.8126 18.1458 18.4792 18.4792C18.1458 18.8126 17.6936 18.9999 17.2221 18.9999H15.4443"
      stroke="#748351"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.55556 18.9999H4.77778C4.30628 18.9999 3.8541 18.8126 3.5207 18.4792C3.1873 18.1458 3 17.6936 3 17.2221V15.4443"
      stroke="#748351"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.44434 6.55566V15.4446" stroke="#748351" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 6.55566V15.4446" stroke="#748351" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.4443 6.55566V15.4446" stroke="#748351" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
