import Loading from 'components/Loading'
import Button from 'new-components/Button'
import Input from 'new-components/Input'
import Select from 'new-components/Select'
import {
  GENDER_IDENTITY_LIST,
  NATIONALITY_LIST
} from 'pages/Registration/components/Forms/components/RegistrationForm/constants'
import { getFieldError } from 'pages/Registration/components/Forms/helpers'
import { MdInfoOutline } from 'react-icons/md'
import { dateMask, formatCpf, formatPhone } from 'utils/formatters'
import usePersonalData from './hooks/usePersonalData'
import * as Styles from './styles'

function PersonalData() {
  const {
    name,
    nationality,
    preferredName,
    setPreferredName,
    genderIdentity,
    cpf,
    rg,
    rne,
    birthdate,
    telephone,
    setTelephone,
    cellphone,
    email,
    specialties,
    hasPrescribedBefore,
    changeFieldValue,
    changeSpecialties,
    errors,
    specialtiesList,
    onRegistrationFormSubmit,
    signedUser,
    loading
  } = usePersonalData()

  return (
    <Styles.PersonalDataContainer onSubmit={onRegistrationFormSubmit}>
      <Loading loading={loading} />

      <Styles.InputsContainer>
        <Styles.VariableWidthContainer width="67%">
          <Input
            id="name"
            label="Nome completo*"
            placeholder="Digite seu nome completo"
            value={name}
            errorMessage={getFieldError('name', errors)}
            onChange={(event) => changeFieldValue('name', event.target.value)}
            disabled
          />
        </Styles.VariableWidthContainer>
        <Styles.VariableWidthContainer width="33%">
          <Select
            id="nationality"
            label="Nacionalidade*"
            options={NATIONALITY_LIST}
            value={nationality}
            errorMessage={getFieldError('nationality', errors)}
            onChange={(newValue) => changeFieldValue('nationality', newValue)}
          />
        </Styles.VariableWidthContainer>
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        <Input
          id="preferred-name"
          label="Nome de preferência"
          placeholder="Escolha como prefere ser chamado"
          value={preferredName}
          onChange={(event) => setPreferredName(event.target.value)}
        />
        <Select
          id="gender-identity"
          label="Identidade de gênero*"
          options={GENDER_IDENTITY_LIST}
          value={genderIdentity}
          errorMessage={getFieldError('genderIdentity', errors)}
          onChange={(newValue) => changeFieldValue('genderIdentity', newValue)}
        />
        <Input
          id="cpf"
          label="CPF*"
          placeholder="Digite seu CPF"
          value={cpf}
          errorMessage={getFieldError('cpf', errors)}
          onChange={(event) => changeFieldValue('cpf', formatCpf(event.target.value))}
          disabled
        />
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        {nationality === 'estrangeira' ? (
          <Input
            id="rne"
            label="RNE*"
            placeholder="Digite seu RNE"
            value={rne}
            errorMessage={getFieldError('rne', errors)}
            onChange={(event) => changeFieldValue('rne', event.target.value)}
          />
        ) : (
          <Input
            id="rg"
            label="RG*"
            placeholder="Digite seu RG"
            value={rg}
            errorMessage={getFieldError('rg', errors)}
            onChange={(event) => changeFieldValue('rg', event.target.value)}
          />
        )}
        <Input
          id="birthdate"
          label="Data de nascimento*"
          placeholder="dd/mm/aaaa"
          value={birthdate}
          errorMessage={getFieldError('birthdate', errors)}
          onChange={(event) => changeFieldValue('birthdate', dateMask(event.target.value))}
          disabled
        />
        {(signedUser.registration_type === 1 ||
          signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
          signedUser.prescriber?.council.toUpperCase() === 'CRM') && (
          <Input
            id="telephone"
            label="Telefone"
            placeholder="(99) 9999-9999"
            value={telephone}
            onChange={(event) => setTelephone(formatPhone(event.target.value))}
          />
        )}
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        {signedUser.registration_type === 2 ? (
          <>
            {signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
            signedUser.prescriber?.council.toUpperCase() === 'CRM' ? (
              <Styles.VariableWidthContainer width="67%">
                <Input
                  id="email"
                  label="E-mail*"
                  placeholder="Digite seu e-mail"
                  value={email}
                  errorMessage={getFieldError('email', errors)}
                  onChange={(event) => changeFieldValue('email', event.target.value)}
                  disabled
                />
              </Styles.VariableWidthContainer>
            ) : (
              <Input
                id="telephone"
                label="Telefone"
                placeholder="(99) 9999-9999"
                value={telephone}
                onChange={(event) => setTelephone(formatPhone(event.target.value))}
              />
            )}
            <Styles.VariableWidthContainer
              width={
                signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
                signedUser.prescriber?.council.toUpperCase() === 'CRM'
                  ? '33%'
                  : '100%'
              }>
              <Input
                id="cellphone"
                label="Celular/WhatsApp*"
                placeholder="(99) 99999-9999"
                value={cellphone}
                errorMessage={getFieldError('cellphone', errors)}
                onChange={(event) => changeFieldValue('cellphone', formatPhone(event.target.value))}
              />
            </Styles.VariableWidthContainer>
          </>
        ) : (
          <>
            <Input
              id="cellphone"
              label="Celular/WhatsApp*"
              placeholder="(99) 99999-9999"
              value={cellphone}
              errorMessage={getFieldError('cellphone', errors)}
              onChange={(event) => changeFieldValue('cellphone', formatPhone(event.target.value))}
            />
            <Styles.VariableWidthContainer width="200%">
              <Input
                id="email"
                label="E-mail*"
                placeholder="Digite seu e-mail"
                value={email}
                errorMessage={getFieldError('email', errors)}
                onChange={(event) => changeFieldValue('email', event.target.value)}
                disabled
              />
            </Styles.VariableWidthContainer>
          </>
        )}
      </Styles.InputsContainer>
      <Styles.VariableWidthContainer width="100%">
        <Input
          id="email"
          label="E-mail*"
          placeholder="Digite seu e-mail"
          value={email}
          errorMessage={getFieldError('email', errors)}
          onChange={(event) => changeFieldValue('email', event.target.value)}
          disabled
        />
      </Styles.VariableWidthContainer>
      <Styles.ConfirmChangesContainer>
        <Styles.ChangeSensibleDataContainer>
          <MdInfoOutline />
          <p>
            Se deseja alterar CPF, nome, data de nascimento ou email, por favor entre em contato com o{' '}
            <a href="https://atendimento.cannect.life/atendimento-cannect" target="_blank" rel="noreferrer">
              atendimento Cannect
            </a>
            .
          </p>
        </Styles.ChangeSensibleDataContainer>
        <Button type="submit">ATUALIZAR MEUS DADOS</Button>
      </Styles.ConfirmChangesContainer>
    </Styles.PersonalDataContainer>
  )
}

export default PersonalData
