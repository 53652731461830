import React, { createContext, useContext, useState, useMemo, useEffect, PropsWithChildren, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'
import useSignedUser from '../useSignedUser'
import { TDigitalJourneyFormData } from '@cannect/pages/DigitalJourney/types'
import { DIGITAL_JOURNEY_ROUTES, FORM_DEFAULT_VALUES, STEPS_ENUM } from '@cannect/pages/DigitalJourney/constants'
import { TFindLastJourneyResponse } from '@cannect/services/resources/digitalJourney'
import { TDigitalJourneyContext } from './types'
import { undefined } from 'zod'

const STEPS_ALLOWING_BACK = [
  STEPS_ENUM.SEND_IDENTITY_DOCUMENT,
  STEPS_ENUM.CONFIRM_IDENTITY_INFORMATIONS,
  STEPS_ENUM.SEND_PROOF_OF_ADDRESS_DOCUMENT,
  STEPS_ENUM.CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS,
  STEPS_ENUM.ORDER_SUMMARY,
  STEPS_ENUM.ORDER_PAYMENT,
  STEPS_ENUM.SEND_ANVISA_AUTHORIZATION
]

const ALLOWED_STEPS_AFTER_PAYMENT = [
  STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION,
  STEPS_ENUM.SEND_ANVISA_AUTHORIZATION,
  STEPS_ENUM.JOURNEY_FINISHED
]

const journeyFirstStepRoute = DIGITAL_JOURNEY_ROUTES[STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT]

const DigitalJourneyContext = createContext<TDigitalJourneyContext | undefined>(undefined as any)

export const DigitalJourneyProvider = ({ children }: PropsWithChildren) => {
  const history = useHistory()
  const location = useLocation()
  const { signedUser } = useSignedUser()

  const [formData, setFormData] = useState<TDigitalJourneyFormData>(FORM_DEFAULT_VALUES)
  const [showPaymentWarningModal, setShowPaymentWarningModal] = useState(false)

  const isDigitalJourneyFlow = useMemo(() => location.pathname.includes('jornada-digital'), [location.pathname])

  const updateFormData = (newData: Partial<TDigitalJourneyFormData>) => {
    setFormData((prevData) => {
      return { ...prevData, ...newData }
    })
  }

  const resetFormData = () => {
    setFormData({
      ...FORM_DEFAULT_VALUES,
      common_fields: {
        ...FORM_DEFAULT_VALUES.common_fields,
        user_id: signedUser?.id || null
      }
    })
  }

  const currentStep = useMemo(() => {
    return Object.entries(DIGITAL_JOURNEY_ROUTES).find(([_, path]) => path === location?.pathname)?.[0]
  }, [location?.pathname])
  const formStepsOrder = useMemo(() => formData?.common_fields?.form_steps_order || [], [formData])
  const isJourneyPaid = useMemo(() => formData.common_fields.status === 'paid', [formData.common_fields.status])
  const isJourneyFinished = useMemo(() => formData.common_fields.status === 'finished', [formData.common_fields.status])

  const canGoBack = useMemo(() => {
    if (!currentStep || formStepsOrder?.length === 0) return false

    if (currentStep === STEPS_ENUM.SEND_ANVISA_AUTHORIZATION) return true

    return STEPS_ALLOWING_BACK.includes(currentStep) && !isJourneyPaid
  }, [currentStep, formStepsOrder, isJourneyPaid])

  const goBack = useCallback(() => {
    if (!currentStep) return
    if (!canGoBack) {
      if (isJourneyPaid) {
        setShowPaymentWarningModal(true)
      }
      return
    }

    const currentIndex = formStepsOrder.indexOf(currentStep)
    let previousStep = formStepsOrder[currentIndex - 1]

    if (currentStep === STEPS_ENUM.SEND_ANVISA_AUTHORIZATION) {
      previousStep = STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION
    }

    history.push(DIGITAL_JOURNEY_ROUTES[previousStep])
  }, [canGoBack, currentStep, formStepsOrder, isJourneyPaid, history])

  // Handle continuing a previous journey
  const continueJourney = (journeyData: TFindLastJourneyResponse) => {
    const updatedFormData = {
      ...journeyData.form_data,
      common_fields: {
        ...formData.common_fields,
        digital_journey_id: journeyData?.digital_journey_id,
        order_id: journeyData?.order_id,
        user_id: journeyData?.user_id,
        form_steps_order: journeyData?.form_steps_order
      }
    }
    updateFormData(updatedFormData)

    if (journeyData.current_step) {
      if (journeyData?.current_step === 'send_prescription_document' && journeyData?.next_step) {
        history.push(journeyData.next_step)
        return
      }
      history.push(DIGITAL_JOURNEY_ROUTES[journeyData.current_step])
    }
  }

  // Effect to update user ID in form data
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      common_fields: {
        ...prevData.common_fields,
        user_id: signedUser?.id
      }
    }))
  }, [signedUser?.id])

  // Effect to redirect user to first step if digital_journey_id is dont exist or if the journey is finished
  useEffect(() => {
    if (!isDigitalJourneyFlow) return
    const shouldResetFormData =
      (currentStep !== STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT && !formData.common_fields?.digital_journey_id) ||
      (isJourneyFinished && currentStep !== STEPS_ENUM.JOURNEY_FINISHED)

    if (shouldResetFormData) {
      resetFormData()
      history.push(journeyFirstStepRoute)
    }
  }, [currentStep, formData?.common_fields?.digital_journey_id, isDigitalJourneyFlow])

  useEffect(() => {
    if (!isDigitalJourneyFlow) return

    // Effect to handle back when the journey is paid
    if ((isJourneyPaid || isJourneyFinished) && currentStep && !ALLOWED_STEPS_AFTER_PAYMENT.includes(currentStep)) {
      setShowPaymentWarningModal(true)

      history.push(DIGITAL_JOURNEY_ROUTES[ALLOWED_STEPS_AFTER_PAYMENT[0]])
    }
  }, [isJourneyPaid, currentStep, isDigitalJourneyFlow, isJourneyFinished])

  const contextValue = useMemo(
    () => ({
      formData,
      resetFormData,
      updateFormData,
      currentStep,
      goBack,
      canGoBack,
      isJourneyPaid,
      showPaymentWarningModal,
      setShowPaymentWarningModal,
      continueJourney,
      formStepsOrder
    }),
    [
      formData,
      resetFormData,
      currentStep,
      updateFormData,
      goBack,
      canGoBack,
      isJourneyPaid,
      showPaymentWarningModal,
      continueJourney,
      formStepsOrder
    ]
  )

  return <DigitalJourneyContext.Provider value={contextValue}>{children}</DigitalJourneyContext.Provider>
}

export const useDigitalJourney = (): TDigitalJourneyContext => {
  const context = useContext(DigitalJourneyContext)
  if (!context) {
    throw new Error('useDigitalJourney must be used within a DigitalJourneyProvider')
  }
  return context
}
