import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main``

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${temporaryTheme.spacing.space4};

  @media (${MOBILE_WIDTH}) {
    flex-direction: column;
  }
`

export const ImageBox = styled.div`
  background: ${temporaryTheme.colors.whiteBorder};
  width: 50%;
  overflow: hidden;
  border-radius: 22px;

  @media (${MOBILE_WIDTH}) {
    width: 100%;
  }
`
export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`

export const TextContent = styled.div`
  width: 50%;
  @media (${MOBILE_WIDTH}) {
    width: 100%;
  }

  span,
  p {
    background-color: transparent !important;
    color: ${temporaryTheme.colors.darkGrey} !important;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
  }
`
export const Title = styled.h2`
  color: ${temporaryTheme.colors.dark};
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: ${temporaryTheme.spacing.space3};
`
export const ActionsContent = styled.div`
  margin-top: ${temporaryTheme.spacing.space4};
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${temporaryTheme.spacing.space3};
`
