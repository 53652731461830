import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const ValidationFormContainer = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const WhyWeNeedTheseData = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-weight: 300;
  margin-bottom: 2rem;

  svg {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
  }
`

export const InvisibleContainer = styled.div`
  height: 1px;
  width: 100%;

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`
