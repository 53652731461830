/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef } from 'react'
import { format, formatISO, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { IoCloseCircleSharp } from 'react-icons/io5'

import { useFormContext, Controller } from 'react-hook-form'
import { Container, Header, AvailableDates, MonthSelector, DatePickerStyles, PopupContainer } from './styles'

interface DatePickerPopupProps {
  onClose?: () => void
  onSelectDate: (date: string) => void
  availableDates: string[]
  withHeading?: boolean
  isPopup?: boolean
}

const DatePickerPopup: React.FC<DatePickerPopupProps> = ({
  onClose,
  availableDates,
  onSelectDate,
  withHeading = true,
  isPopup = true
}) => {
  const { control } = useFormContext()

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const isDateAvailable = (date: Date) => {
    const formattedDate = formatISO(date, { representation: 'date' })
    const availableDatesFormatted = availableDates.map(dateString =>
      formatISO(parseISO(dateString), { representation: 'date' })
    )
    return availableDatesFormatted.includes(formattedDate)
  }

  const containerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (onClose && containerRef.current && !containerRef.current.contains(event.target as Node)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const datePickerContent = (
    <>
      {!!withHeading && (
        <Header>
          <span>Selecione a data</span>
          <IoCloseCircleSharp color="#748351" size={30} onClick={onClose} />
        </Header>
      )}
      <AvailableDates>
        <div className="circle" />
        <span>Datas disponíveis</span>
      </AvailableDates>
      <Controller
        control={control}
        name="scheduling_date"
        render={({ field: { onChange, value } }) => (
          <DatePicker
            selected={value ? parseISO(value) : null}
            onChange={date => {
              if (date) {
                const dateIso = format(date, 'yyyy-MM-dd')
                onChange(dateIso)
                if (date) {
                  onSelectDate(dateIso)
                }
              }
            }}
            inline
            monthsShown={1}
            locale={ptBR}
            dateFormat="dd/MM/yyyy"
            filterDate={date => isDateAvailable(date)}
            dayClassName={(date: Date) => (isDateAvailable(date) ? 'available' : '')}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth, nextMonthButtonDisabled }) => {
              const currentDate = new Date()
              const currentMonthOrPast =
                date.getMonth() <= currentDate.getMonth() && date.getFullYear() <= currentDate.getFullYear()
              return (
                <>
                  <DatePickerStyles />
                  <MonthSelector>
                    <BsFillArrowLeftCircleFill
                      onClick={() => {
                        if (!currentMonthOrPast) decreaseMonth()
                      }}
                      size={22}
                      color={currentMonthOrPast ? '#C9C5B8' : '#7D755F'}
                      style={{
                        cursor: currentMonthOrPast ? 'not-allowed' : 'pointer'
                      }}
                    />
                    <span>
                      {capitalize(format(date, 'MMMM', { locale: ptBR }))} {format(date, 'yyyy')}
                    </span>
                    <BsFillArrowRightCircleFill
                      onClick={increaseMonth}
                      size={22}
                      color={nextMonthButtonDisabled ? '#C9C5B8' : '#7D755F'}
                      style={{
                        cursor: nextMonthButtonDisabled ? 'not-allowed' : 'pointer'
                      }}
                    />
                  </MonthSelector>
                </>
              )
            }}
          />
        )}
      />
    </>
  )

  return isPopup ? (
    <PopupContainer id="datePick_custom" ref={containerRef}>
      {datePickerContent}
    </PopupContainer>
  ) : (
    <Container>{datePickerContent}</Container>
  )
}

export default DatePickerPopup
