/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'
import { HeadingProps } from '.'

type WrapperStylesProps = Pick<HeadingProps, 'fontSize' | 'verticalSpace' | 'fontWeight' | 'defaultColor' | 'imageIcon'>

const modifierWrapper = {
  xsmall: () => css`
    margin-top: ${temporaryTheme.spacing.space2};
    margin-bottom: ${temporaryTheme.spacing.space2};
  `,
  small: () => css`
    margin-top: ${temporaryTheme.spacing.space4};
    margin-bottom: ${temporaryTheme.spacing.space4};
  `,
  medium: () => css`
    margin-top: ${temporaryTheme.spacing.space6};
    margin-bottom: ${temporaryTheme.spacing.space6};
  `,
  large: () => css`
    margin-top: ${temporaryTheme.spacing.space8};
    margin-bottom: ${temporaryTheme.spacing.space8};
  `,
  xlarge: () => css`
    margin-top: ${temporaryTheme.spacing.space9};
    margin-bottom: ${temporaryTheme.spacing.space9};
  `,
  withIcon: () => css`
    ${Title} {
      display: flex;
      align-items: flex-end;
      gap: ${temporaryTheme.spacing.space3};
    }
  `
}

export const Title = styled.h1`
  font-family: Poppins;
  font-weight: 600;
  font-size: inherit;

  span {
    color: ${temporaryTheme.colors.sitePrimary};
    font-weight: inherit;
  }

  strong {
    font-weight: 600;
  }

  span,
  strong {
    font-family: inherit;
    font-size: inherit;
  }
`

export const IconBox = styled.img`
  margin-bottom: 22px;
  width: 90px;
  filter: invert(43%) sepia(34%) saturate(876%) hue-rotate(108deg) brightness(104%) contrast(86%);
`

export const Wrapper = styled.main<WrapperStylesProps>`
  ${({ verticalSpace, fontSize, fontWeight, defaultColor, imageIcon}) => css`
    width: 100%;
    font-size: ${fontSize};
    font-family: 'Spartan', sans-serif;
    font-weight: ${fontWeight};

    strong {
      font-weight: ${fontWeight ? 700 : fontWeight};
    }

    @media ${MOBILE_WIDTH} {
      font-size: 26px;
    }

    ${Title} {
      color: ${temporaryTheme.colors[defaultColor!]};
    }

    ${!!verticalSpace && modifierWrapper[verticalSpace]}
    ${!!imageIcon && modifierWrapper.withIcon}
  `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

export const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  width: 100%;
`

export const ButtonProduct = styled.button`
  padding: 7px;
  border-radius: 5px;
  background: ${temporaryTheme.colors.secondary};
  min-width: 125px;
  outline: none;
  border: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${temporaryTheme.spacing.space2};
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${temporaryTheme.colors.white};
  min-height: 45px;
  svg {
    width: 14px;
    height: 14px;
  }
`
