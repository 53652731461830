import { cannectDefaultProduct } from 'assets/img'
import { useCartCheckout } from 'hooks/useCartCheckout'
import useMediaQuery from 'hooks/useMediaQuery'
import { noop } from 'lodash'

import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { useHistory } from 'react-router'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'
import { formatPrice } from 'utils/formatters'
import When from 'utils/when'
import * as Styles from './styles'

export interface CardItemTypes {
  isFavorite: boolean
  image: any[]
  name: string
  shippingType: number
  product_id: string | number
  product?: any
  removeFavorite: any
  price: number
}

export function CardItem({
  image,
  isFavorite,
  name,
  product_id,
  shippingType,
  product,
  removeFavorite = noop,
  price
}: CardItemTypes) {
  const imagePath = image?.filter((item) => item?.format === 'IMG') || []
  const { addToCart } = useCartCheckout()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const isTablet = useMediaQuery(TABLET_WIDTH)
  const history = useHistory()

  const ShippingText = product.stock <= 0 ? 'possível maior tempo de entrega' : 'importado - entrega em até 25 dias'
  const ButtonText = shippingType === 18 || shippingType === 27 ? 'Comprar' : 'Importar'

  const getProductDetails = () => {
    history.push(`/detalhes-produtos/${product_id}`)
  }

  return (
    <Styles.Wrapper>
      <Styles.Content>
        <Styles.FavoriteBox onClick={() => removeFavorite(product_id, product)}>
          {isFavorite ? <AiFillHeart color={temporaryTheme.colors.red} /> : <AiOutlineHeart color="#858585" />}
        </Styles.FavoriteBox>
        <Styles.ImageBox>
          <Styles.Image src={imagePath[0]?.path || cannectDefaultProduct} alt="Imagem do produto" />
        </Styles.ImageBox>
        <Styles.DetailsBox>
          <Styles.Description onClick={getProductDetails}>{name}</Styles.Description>
          <Styles.PriceBox>
            <Styles.Price>
              {formatPrice({ value: price, currencyDisplay: 'symbol', currencyStyle: 'BRL', type: 'pr-br' })}
            </Styles.Price>
            <When expr={!isMobile && !isTablet}>
              <Styles.ShippingMessage>{ShippingText}</Styles.ShippingMessage>
            </When>
          </Styles.PriceBox>
        </Styles.DetailsBox>
      </Styles.Content>

      <Styles.ActionsWrapper>
        <Styles.ActionBox>
          <Styles.ButtonProduct onClick={() => addToCart(product?.id, product)}>
            {ButtonText} <FiShoppingCart />
          </Styles.ButtonProduct>
        </Styles.ActionBox>

        <When expr={isMobile || isTablet}>
          <Styles.ShippingMessage>{ShippingText}</Styles.ShippingMessage>
        </When>
      </Styles.ActionsWrapper>
    </Styles.Wrapper>
  )
}
