export const moreThan24HoursText = `
• <strong>Em até 24 horas</strong> antes da consulta, pedindo o cancelamento, <strong>você recebe de volta o valor total pago.</strong>
<br/>

• <strong>Para caso o profissional cancele a consulta:</strong> o paciente sempre tem direito ao reembolso.
<br/>
• <strong>Para caso o profissional reagende a consulta: </strong>o paciente pode solicitar o reembolso ou o pagamento já realizado é considerado para a nova consulta.
<br/>
• <strong>Para caso o paciente não compareça à consulta:</strong> não é realizado o reembolso ao paciente. O valor da consulta será enviado ao profissional.
<br/>
• <strong>Caso você atrase mais do que 10 (dez) minutos, a consulta será cancelada e não terá direito à reembolso.
<br/>
• Para ver as condições em mais detalhes, você pode <a href="https://www.cannect.life/politica-de-agendamento" target="_blank">consultar os termos de uso.</a>
<br/>
`

export const lessThan24HoursText = `
• Como esse agendamento ocorrerá nas <strong>próximas 24 horas, não poderemos oferecer cancelamento para ele.</strong>
<br/>
• <strong>Para caso o profissional cancele a consulta:</strong> o paciente sempre tem direito ao reembolso.
<br/>
• <strong>Para caso o profissional reagende a consulta:</strong> o paciente pode solicitar o reembolso ou o pagamento já realizado é considerado para a nova consulta.
<br/>
• <strong>Para caso o paciente não compareça à consulta:</strong> não é realizado o reembolso ao paciente. O valor da consulta será enviado ao profissional.
<br/>
• <strong>Para caso o profissional não compareça à consulta:</strong>  o paciente deve solicitar o reembolso.
<br/>
• <strong>Caso você atrase mais do que 10 (dez) minutos, a consulta será cancelada e não terá direito à reembolso.
<br/>
• Para ver as condições em mais detalhes, você pode <a href="https://www.cannect.life/politica-de-agendamento" target="_blank">consultar os termos de uso.</a>
<br/>
`
