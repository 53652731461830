import styled from 'styled-components'

export const PatientAddressesContainer = styled.main`
  display: flex;
  flex-direction: column;

  width: 100%;
  background: #f8f8f8;
  padding: 2rem;

  border-radius: 0.5rem;
  height: fit-content;
`

export const AllAddressesWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;

  width: 100%;
`
