import { productBluebird, productex01, productex02 } from 'assets/img'
import {
  svgBemEstar,
  svgCronica,
  svgEsportiva,
  svgMental,
  svgNeurologia,
  svgOdontologia,
  svgOncologia,
  svgPediatria,
  svgPele
} from 'assets/svg'

export const TextTreatmentSection = `
Se você está em busca de soluções para problemas de saúde como dores crônicas, ansiedade, epilepsia, entre outros, talvez os tratamentos com cannabis medicinal sejam uma opção para você.
<br />
<br />
A cannabis medicinal é uma alternativa natural e eficaz para tratar diversas condições médicas. Além de aliviar sintomas, também pode melhorar a qualidade de vida dos pacientes.
`

export const TextWellBeingSection = `
A cannabis medicinal é uma alternativa natural para o tratamento de diversos problemas de saúde, incluindo condições de pele. Seus compostos ativos, como o THC e o CBD, possuem propriedades anti-inflamatórias e analgésicas, que podem ajudar a aliviar sintomas associados a doenças dermatológicas, tais como eczema, psoríase, queimaduras e acne.
`

export const TextStartTreatment = `
A Cannect pode te ajudar em seu caminho para uma vida melhor. Cuidamos de você em todo o processo, faça parte da revolução verde!
<br/>
<br/>
Entre em contato com a Cannect para agendar uma consulta e saber mais sobre o uso da cannabis medicinal no tratamento de diferentes condições de saúde.
`

export const BadgesTreatmentsWellBeing = [
  { id: 6, title: 'Pediatria', icon: svgPediatria, slug: '/tratamentos/pediatria', type: 'pediatria' },
  { id: 7, title: 'Cuidados da Pele', icon: svgPele, slug: '/tratamentos/cuidados-da-pele', type: 'cuidados-da-pele' },
  {
    id: 2,
    title: 'Bem-Estar íntimo',
    icon: svgBemEstar,
    slug: '/tratamentos/bem-estar-intimo',
    type: 'bem-estar-intimo'
  },
  {
    id: 3,
    title: 'Medicina Esportiva',
    icon: svgEsportiva,
    slug: '/tratamentos/medicina-esportiva',
    type: 'medicina-esportiva'
  }
]

export const BadgesMedicalTreatments = [
  { id: 1, title: 'Dor Crônica', icon: svgCronica, slug: '/tratamentos/dor-cronica', type: 'dor-cronica' },
  { id: 8, title: 'Saúde mental', icon: svgMental, slug: '/tratamentos/saude-mental', type: 'saude-mental' },
  { id: 9, title: 'Odontologia', icon: svgOdontologia, slug: '/tratamentos/odontologia', type: 'odontologia' },
  {
    id: 5,
    title: 'Oncologia',
    icon: svgOncologia,
    slug: '/tratamentos/oncologia-cuidados-paliativos',
    type: 'oncologia-cuidados-paliativos'
  },
  { id: 4, title: 'Neurologia', icon: svgNeurologia, slug: '/tratamentos/neurologia', type: 'neurologia' }
]

export const TitleResumeTreatmentMock = {
  title: 'Sobre o tratamento',
  text: `A dor é um sinal de que algo aconteceu, causando uma sensação desconfortável ou desagradável. A presença de dor geralmente significa que algo está errado. O melhor juiz de sua dor é você.
  <br/>
  <br/>
  <br/>
  A dor aguda geralmente surge repentinamente e é causada por algo específico, desaparecendo quando não há mais uma causa subjacente para a dor. Já a dor crônica é uma dor contínua e geralmente dura mais de três meses. Esse tipo de dor pode continuar mesmo depois que a lesão ou doença que a causou foi curada ou desapareceu. Algumas pessoas sofrem de dores crônicas mesmo quando não há ferimentos anteriores ou danos corporais aparentes, enfatizando a importância do componente psicológico.
  <br/>
  <br/>
  <br/>
  De acordo com um estudo recente realizado pela USP e UFRJ, a dor crônica afeta 28% da população brasileira e é a principal causa de anos vividos com incapacidade de todas as doenças em todo o mundo. A dor crônica tem um grande potencial para limitar a capacidade de retornar ao trabalho ou às atividades de lazer.`,
  image: '',
  categoryTitle: `<span> Dor </span>Crônica`
}

export const SymptomsMock = `
Os sintomas associados à dor crônica dependem da causa subjacente da dor. A dor pode ser leve ou intensa e contínua ou esporádica. A dor crônica está ligada a condições que incluem:
<br/>
<br/>

• Dor de cabeça <br/>
• Artrite <br/>
• Câncer <br/>
• Dor no nervo <br/>
• Dor nas costas <br/>
• Fibromialgia <br/>

<br/>
Se você tem dor crônica, outras condições podem estar presentes, como:
<br/>
<br/>
• Músculos tensos <br/>
• Capacidade limitada de movimentação <br/>
• Falta de energia <br/>
• Mudanças no apetite <br/>
• Ansiedade <br/>
• Insônia <br/>
• Irritabilidade <br/>

<br/>
Todos esses sintomas devem ser avaliados por um profissional médico qualificado, em consulta médica, para uma melhor elucidação do quadro e definição, junto ao paciente, das melhores opções terapêuticas.
`

export const TextTreatMentSymptom = `
O tratamento com Cannabis medicinal consiste no alívio da dor crônica destes pacientes. Muitos canabinoides (como CBD e THC), têm sido associados ao controle dador ou analgesia. Depois de muitos estudos pré-clínicos e ensaios clínicos, os canabinoides estão surgindo como alternativas mais seguras e potentes aos opioides no controle da dor, com menos efeitos colaterais. Dada a natureza do distúrbio, a aplicação oral ou sublingual pode ser benéfica, mas dependendo da origem da dor, a aplicação tópica na pele também pode funcionar.
`

export const RelatedProductsTreatments = [
  {
    id: 1,
    description: `
      <strong> Lazarus Naturals <span>Espectro Completo </span> </strong> <span>CBG/CBD Oil Tincture </span> <p>High Potency 375mg CBG/CBD 15ml </p>
      `,
    real_price: 200,
    usd_price: 50,
    image: productex01,
    isFavorite: true
  },
  {
    id: 2,
    description: `
      <strong> Lazarus Naturals <span>Espectro Completo </span> </strong> <span>CBG/CBD Oil Tincture </span> <p>High Potency 375mg CBG/CBD 15ml </p>
      `,
    real_price: 200,
    usd_price: 50,
    image: productex02,
    isFavorite: false
  },
  {
    id: 3,
    description: `
      <strong> Lazarus Naturals <span>Espectro Completo </span> </strong> <span>CBG/CBD Oil Tincture </span> <p>High Potency 375mg CBG/CBD 15ml </p>
      `,
    real_price: 200,
    usd_price: 50,
    image: productBluebird,
    isFavorite: false
  },
  {
    id: 4,
    description: `
      <strong> Lazarus Naturals <span>Espectro Completo </span> </strong> <span>CBG/CBD Oil Tincture </span> <p>High Potency 375mg CBG/CBD 15ml </p>
      `,
    real_price: 200,
    usd_price: 50,
    image: productBluebird,
    isFavorite: true
  },
  {
    id: 5,
    description: `
      <strong> Lazarus Naturals <span>Espectro Completo </span> </strong> <span>CBG/CBD Oil Tincture </span> <p>High Potency 375mg CBG/CBD 15ml </p>
      `,
    real_price: 200,
    usd_price: 50,
    image: productex02,
    isFavorite: false
  }
]
