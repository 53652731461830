import { Skeleton } from 'antd'

interface TableSkeletonLoadingProps {
  colSpan: number
}

const TableSkeletonLoading = ({ colSpan }: TableSkeletonLoadingProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="flex w-full flex-col gap-4">
          <Skeleton.Input block active style={{ height: '49px' }} />
          <Skeleton.Input block active style={{ height: '49px' }} />
          <Skeleton.Input block active style={{ height: '49px' }} />
        </div>
      </td>
    </tr>
  )
}

export default TableSkeletonLoading
