import { svgNotAuthorized } from 'assets/svg'
import Texts from 'components/Text'
import { Link } from 'react-router-dom'
import * as Styles from './styles'

function NotAuthorized() {
  return (
    <Styles.Container>
      <img src={svgNotAuthorized} alt="" />
      <Texts type="md" color="tertiary" bold>
        Você não tem permissão para acessar essa página!
      </Texts>
      <button className="voltar-home">
        <Link to="/">Voltar para página inicial</Link>
      </button>
    </Styles.Container>
  )
}

export default NotAuthorized
