import { CollapsableMenuProps } from '../components/CollapsableMenu'
import { PATH_SELECTOR_ICONS } from './icons'
import { SHARED_OPTIONS_DESCRIPTIONS, SIDE_PANEL_MY_ORDERS } from './shared'

const OPTIONS_LIST = {
  profile: [
    {
      title: 'Dados pessoais',
      to: '/conta?pagina=perfil-dados-pessoais',
      enabled: true
    },
    {
      title: 'Alterar senha',
      to: '/conta?pagina=perfil-alterar-senha',
      enabled: true
    },
    {
      title: 'Endereços',
      to: '/conta?pagina=perfil-enderecos',
      enabled: true
    },
    {
      title: 'Config. de prescrições',
      to: '/conta?pagina=perfil-config-prescricoes',
      enabled: true
    },
    {
      title: 'Documentos',
      to: '/conta?pagina=perfil-documentos',
      enabled: false
    },
    {
      title: 'Receitas',
      to: '/conta?pagina=perfil-receitas',
      enabled: false
    },
    {
      title: 'Meus cartões',
      to: '/conta?pagina=perfil-cartoes',
      enabled: false
    }
  ]
}

export const PROFESSIONAL_SIDE_PANEL: CollapsableMenuProps[] = [
  {
    title: {
      label: 'Meu perfil',
      subItemsId: 'perfil',
      icon: PATH_SELECTOR_ICONS.profile,
      enabled: true
    },
    subItems: OPTIONS_LIST.profile
  },
  SIDE_PANEL_MY_ORDERS,
  {
    title: {
      label: 'Minhas consultas',
      to: '/conta?pagina=consultas',
      icon: PATH_SELECTOR_ICONS.consult,
      enabled: true
    }
  }
  /* SIDE_PANEL_HELP */
]

export const CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL: CollapsableMenuProps[] = [
  {
    title: {
      label: 'Meu perfil',
      subItemsId: 'perfil',
      icon: PATH_SELECTOR_ICONS.profile,
      enabled: true
    },
    subItems: OPTIONS_LIST.profile
  },
  {
    title: {
      label: 'Nova prescrição',
      to: '/assistente',
      icon: PATH_SELECTOR_ICONS.prescription,
      enabled: true
    }
  },
  {
    title: {
      label: 'Cannect Ciência',
      to: '/cannect-ciencia',
      icon: PATH_SELECTOR_ICONS.science,
      enabled: true
    }
  },
  {
    title: {
      label: 'Meus pacientes',
      to: '/conta?pagina=pacientes',
      icon: PATH_SELECTOR_ICONS.patients,
      enabled: true
    }
  },
  SIDE_PANEL_MY_ORDERS,
  {
    title: {
      label: 'Minhas consultas',
      to: '/conta?pagina=consultas',
      icon: PATH_SELECTOR_ICONS.consult,
      enabled: true
    }
  }
  /* SIDE_PANEL_HELP */
]

export const PROFESSIONAL_LINKS_DESCRIPTIONS = {
  'Meu perfil':
    'Altere ou consulte seus dados de acesso, dados pessoais, documentos e receitas, configurações de prescrições, cartões e endereços',
  'Nova prescrição': 'Acesse o assitente para criar uma nova prescrição',
  'Meus pacientes': 'Consulte seus pacientes e acesse suas prescrições',
  'Minhas consultas': 'Verifique sua agenda de consultas',
  'Cannect Ciência': 'Artigos científicos sobre a cannabis medicinal e seu potencial terapêutico.',
  ...SHARED_OPTIONS_DESCRIPTIONS
}
