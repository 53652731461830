import AnvisaConfirmation from '../AnvisaConfirmation'
import Footer from '../Footer'
import NavigationHandler from '../NavigationHandler'
import * as Style from './styles'

export default function OrderConfirmation() {
  return (
    <>
      <NavigationHandler step={4} />

      <Style.Container>
        <AnvisaConfirmation />
      </Style.Container>

      <Footer />
    </>
  )
}
