import { TFileItem, TFormats } from '@cannect/new-components/molecules/FileItem/types'
import { ReactNode } from 'react'

export const typeErrors: Record<TErrorType, TItemError> = {
  DEFAULT: {
    title: 'Erro ao fazer upload',
    subtitle: 'Tente novamente mais tarde'
  },
  UPLOAD: {
    title: 'Arquivo não aceito',
    subtitle: 'Não foi possível fazer o upload deste tipo de arquivo.'
  },
  SIZE: {
    title: 'Limite ultrapassado',
    subtitle: 'Não é possível enviar arquivos com mais de 20MB'
  }
}

export type InputFileProps = {
  onUpload?: (fileItem: TFileItem) => void
  label?: string
  isInvalid?: boolean
  isDisabled?: boolean
  canRemove?: boolean
  canDownload?: boolean
  canView?: boolean
  message?: string
  acceptFormats?: TFormats[]
  showDescriptionFilesAccept?: boolean
  showDescriptionLimit?: boolean
  showListFiles?: boolean
  maxFiles?: number
  value?: TFileItem[]
  onChange(value: TFileItem[]): void
  CustomInputComponent?: ReactNode
}

export type TErrorType = 'DEFAULT' | 'UPLOAD' | 'SIZE'

export type TItemError = {
  title: string
  subtitle: string
}
