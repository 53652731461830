import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 24px;

  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  button {
    margin: 8px;
  }
`
