import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 48px 0px 48px;
  gap: 28px;

  :before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0 0 100% 0;
    background-color: #78d8b7;
    width: 30%;
    height: 500px;
    transform: translate(0px, 20px) scale(1.2);
    z-index: -1;
    opacity: 0.2;
    filter: blur(80px);
    @media (max-width: 900px) {
      width: 20%;
    }
  }
  @media (min-width: 900px) and (max-width: 1249px) {
    gap: 0px;
  }

  @media (max-width: 900px) {
    padding: 40px 30px 0px 30px;
  }
`
export const LeftWrapper = styled.div`
  max-width: 660px;
  min-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 900px) {
    min-width: auto;
  }
  @media (min-width: 900px) and (max-width: 1249px) {
    min-width: 598px;
  }
`
export const RightWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding-right: 48px;

  img {
    max-inline-size: 100%;
    block-size: auto;
    height: 310px;
    object-fit: contain;
    min-width: min-content;
  }

  img:first-child {
    margin-top: 0px;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`
export const Title = styled.h1<any>`
  font-size: 21px;
  font-weight: 700;
  font-family: 'Spartan', sans-serif;
  line-height: 140.5%;
  color: ${theme.colors.darkGrey};
  span {
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
  }
  span:last-child {
    font-weight: 600;
    color: ${temporaryTheme.colors.sitePrimary};
  }
  @media (min-width: 900px) and (max-width: 1249px) {
    font-size: 21px;
  }
`
export const Subtitle = styled.p`
  line-height: 155%;
  font-size: 19px;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  color: ${theme.colors.darkGrey};
  @media (max-width: 900px) {
    font-size: 15px;
  }
`
export const TextDiv = styled.div`
  text-align: start;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  a {
    text-decoration: none;
  }
  margin-bottom: 32px;
`
export const VideoDiv = styled.div`
  border-radius: 10px;
`
export const ButtonText = styled.p<{ color?: string }>`
  font-size: 17px;
  font-family: 'Nunito', sans-serif;
  color: ${({ color }) => color || theme.colors.darkGrey};
  font-weight: 600;
`
export const WhiteWrapp = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  align-items: center;
  gap: 32px;

  margin-bottom: 30px;

  text-decoration: none !important;
`
