import styled, { createGlobalStyle } from 'styled-components'

interface DatePickerStylesProps {
  isPopup?: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  * {
    font-family: 'Inter', sans-serif;
  }
`

export const PopupContainer = styled.div`
  width: 100%;
  height: max-content;
  max-width: 320px;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: absolute;
  top: 76px;
  left: 0;
  opacity: 1;
  visibility: visible;

  * {
    font-family: 'Inter', sans-serif;
  }

  z-index: 2000;

  @media screen and (max-width: 900px) {
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;

    position: fixed;
    top: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 16px;
    font-family: 'Spartan', sans-serif;
    color: #3d3d3d;
  }
`

export const AvailableDates = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  gap: 8px;
  span {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.3px;
  }

  .circle {
    width: 13px;
    height: 13px;
    background-color: #A4C55E;
    border-radius: 50%;
  }
`

export const MonthSelector = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;

  span {
    font-size: 20px;
    color: #3d3d3d;
    @media screen and (min-width: 900px) {
      font-size: 16px;
    }
  }
`

export const DatePickerStyles = createGlobalStyle<DatePickerStylesProps>`

.react-datepicker {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    border: none !important;
    font-weight: 400;
    color: #3d3d3d;;


  .react-datepicker__month-container {
    width: 100%;
    background-color: #fff ;
    border-radius: 0 !;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.react-datepicker__navigation {
  display: none;
}


.react-datepicker__month {
  text-align: start;
  width: 100%;
  margin: 0;
}

  .react-datepicker__day--disabled {
    color: #3d3d3d;
  }

  .react-datepicker__day-names {
    display: contents;
    color: #3d3d3d;
  }

  .react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
  justify-items: center;
  font-size: 13px;
  margin-top: 16px;
  @media screen and (min-width: 900px) {
    font-size: 10px;
  }
}

  .react-datepicker__day {
    cursor: pointer;
    font-weight: 400;
    font-size: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    padding: 8px;

    @media screen and    (max-width: 400px) {
      min-width: 36px;
      width: 36px;
    height: 36px;
    }

    @media screen and (min-width: 900px) {
    font-size: 15px;
    height: ${props => (props.isPopup ? '32px' : '40px')};
    width: ${props => (props.isPopup ? '40px' : '40px')};
    padding: 6px
    }
  }
  .react-datepicker__day--keyboard-selected {
    background: transparent;

  }
  .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {

    border-radius: 50% ;
    background-color: #748351;


  }
  .react-datepicker__day--selected {
    border-radius: 50% ;
    background-color: #769536;
    color: #fff;
    font-weight: 500;

  }

  .react-datepicker__week {
    display: flex ;
    align-items: center ;
    justify-content: space-between ;
    width: 100%;
    height: 55px;


    @media screen and (min-width: 900px) {
      height: ${props => (props.isPopup ? '34px' : '52px')};
      gap: ${props => (props.isPopup ? '0px' : '8px')};
    }
  }

  .available:not(.react-datepicker__day--selected) {
    border-radius: 100% ;
    background-color: #C2D894;
  }
  }
`
