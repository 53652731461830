import { svgBaseRecipeProduct } from 'assets/svg'
import { BaseRecipeProduct } from '..'
import * as Style from './styles'

type RecipeProductProps = {
  product: BaseRecipeProduct
}

export default function RecipeProduct({ product }: RecipeProductProps) {
  return (
    <Style.RecipeProductContainer>
      <img src={svgBaseRecipeProduct} alt="Miniature" />
      <Style.ProductMainInfo>
        <p>
          <strong>{product.translated_name}</strong>
        </p>
        <p>{product.prescription}</p>
        <span>1 frasco(s)</span>
      </Style.ProductMainInfo>
      <Style.PriceContainer>
        <p>
          <strong>Unidade: </strong>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(product.price)}
        </p>
        <p>
          <strong>Total: </strong>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(product.price)}
        </p>
      </Style.PriceContainer>
    </Style.RecipeProductContainer>
  )
}
