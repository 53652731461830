import useQueryString from 'hooks/useQueryString'
import { ReactNode, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { getIsOpenInitialValue } from './helpers'
import * as Styles from './styles'

export interface CollapsableMenuTitle {
  label: string
  icon: ReactNode
  subItemsId?: string
  to?: string
  enabled: boolean
}

export interface CollapsableMenuProps {
  title: CollapsableMenuTitle
  subItems?: {
    title: string
    to: string
    enabled: boolean
  }[]
}

function CollapsableMenu({ title, subItems = [] }: CollapsableMenuProps) {
  const history = useHistory()
  const { filters } = useQueryString()
  const [isOpen, setIsOpen] = useState(getIsOpenInitialValue(title, filters))

  const subItemsContainerHeight = useMemo(() => {
    if (title.to) return 0
    return subItems.length * (21 + 7) + 7
  }, [subItems, title])

  const isSelected = useMemo(() => {
    const selectedPage = filters.pagina ?? 'perfil-dados-pessoais'

    const selectedTitle = selectedPage.split('-')[0]
    if (!title.subItemsId) {
      const itemPath = title.to?.split('?pagina=')[1]
      return itemPath === selectedTitle
    }
    return selectedTitle === title.subItemsId
  }, [filters, title])

  const onCollapseButtonClick = () => {
    if (title.to) {
      history.push(title.to)
      return
    }
    setIsOpen(state => !state)
  }

  const isOptionSelected = (optionPath: string) => {
    const subItemPath = optionPath.split('?pagina=')[1]
    return subItemPath === (filters.pagina ?? 'perfil-dados-pessoais')
  }

  return (
    <Styles.CollapsableMenuContainer animationHeight={subItemsContainerHeight}>
      <Styles.LabelContainer isSelected={isSelected} onClick={onCollapseButtonClick} disabled={!title.enabled}>
        {title.icon}
        <p>{title.label}</p>
        <p>{!title.enabled && '(em breve)'}</p>
      </Styles.LabelContainer>

      <Styles.OptionsWrapper className={isOpen ? 'slide-down' : 'slide-up'}>
        {subItems?.map(option => (
          <Styles.MenuOption
            isSelected={isOptionSelected(option.to)}
            onClick={() => history.push(option.to)}
            disabled={!option.enabled}
          >
            {option.title}
            {!option.enabled && '(em breve)'}
          </Styles.MenuOption>
        ))}
      </Styles.OptionsWrapper>
    </Styles.CollapsableMenuContainer>
  )
}

export default CollapsableMenu
