import { cn } from '@cannect/lib/utils'
import * as SheetPrimitive from '@radix-ui/react-dialog'
import { ComponentPropsWithoutRef, ElementRef, forwardRef, HTMLAttributes } from 'react'

import { LuX } from 'react-icons/lu'
import { tv, VariantProps } from 'tailwind-variants'

const { Root } = SheetPrimitive

const { Trigger } = SheetPrimitive

const { Close } = SheetPrimitive

const { Portal } = SheetPrimitive

const Overlay = forwardRef<
  ElementRef<typeof SheetPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
    ref={ref}
  />
))
Overlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = tv({
  // only global styles
  base: 'fixed z-50 gap-4 bg-neutral-50 p-6 shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out',
  variants: {
    side: {
      top: 'inset-x-0 top-0 max-h-[90dvh] rounded-b-3xl border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
      bottom:
        'inset-x-0 bottom-0 max-h-[90dvh] rounded-t-3xl border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
      left: 'md:w-screen-md lg:w-screen-lg inset-y-0 left-0 top-0 h-full w-[90vw] max-w-[90vw] rounded-r-3xl border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left md:max-w-md lg:max-w-lg',
      right:
        'md:w-screen-md lg:w-screen-lg inset-y-0 right-0 top-0 h-full w-[90vw] max-w-[90vw] rounded-l-3xl border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right md:max-w-md lg:max-w-lg'
    }
  },
  defaultVariants: {
    side: 'right'
  }
})

interface SheetContentProps
  extends ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  hideOverlay?: boolean
}

const Content = forwardRef<ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
  ({ side = 'right', className, children, hideOverlay = false, ...props }, ref) => (
    <Portal>
      {!hideOverlay && (
        <>
          <Overlay />
          <div
            className={cn(
              'fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0'
            )}
          />
        </>
      )}
      <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({ side }), className)} {...props}>
        {children}

        <SheetPrimitive.Close
          className={`absolute top-2 flex items-center rounded-full border-none bg-transparent p-1 text-current opacity-70 ring-offset-primary-700 transition-opacity hover:bg-gray-100 hover:text-gray-800 hover:opacity-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none ${side === 'right' ? 'left-2' : 'right-2'}`}>
          <LuX className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </SheetPrimitive.Close>
      </SheetPrimitive.Content>
    </Portal>
  )
)
Content.displayName = SheetPrimitive.Content.displayName

const Header = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-4 flex flex-col space-y-1 text-left', className)} {...props} />
)
Header.displayName = 'SheetHeader'

const Footer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col', className)} {...props} />
)
Footer.displayName = 'SheetFooter'

const Title = forwardRef<
  ElementRef<typeof SheetPrimitive.Title>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('font-sans text-lg font-semibold text-primary-700', className)}
    {...props}
  />
))
Title.displayName = SheetPrimitive.Title.displayName

const Description = forwardRef<
  ElementRef<typeof SheetPrimitive.Description>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description ref={ref} className={cn('font-sans text-sm text-neutral-800', className)} {...props} />
))
Description.displayName = SheetPrimitive.Description.displayName

export { Root, Portal, Overlay, Trigger, Close, Content, Header, Footer, Title, Description }
