import * as Styles from './styles'

interface IImagesHistories {
  url: string
}

function OtherHistories({ url }: IImagesHistories) {
  return (
    <Styles.Container>
      <div className="section">
        <div className="client">
          <img src={`${import.meta.env.VITE_APP_STRAPI_URL}${url}`} alt="client" width="185px" height="185px" />
        </div>
      </div>
    </Styles.Container>
  )
}

export default OtherHistories
