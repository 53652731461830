import { useBlogFilter } from 'hooks/useBlogFilter'
import { noop } from 'lodash'
import Button from 'new-components/Button'
import SearchInput from 'new-components/SearchInput'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import * as Styles from './styles'

export interface HeaderProps {
  selectCategoryBlog: (slug: string) => void
  handleSearch: (val: string) => void
}

export default function Header({ selectCategoryBlog = () => null, handleSearch = noop }: HeaderProps) {
  const history = useHistory()
  const location = useLocation()

  const { categories, getCategories, handleSelectedCategoryName, categorySelected, slugCategory, setSlugCategory } =
    useBlogFilter()

  useEffect(() => {
    getCategories()
  }, [])

  const selectCategory = ({ slug, name }: { slug: string; name: string }) => {
    if (categorySelected === name) {
      history.push(`/blog`)
      handleSelectedCategoryName('')
      setSlugCategory('')
    } else {
      history.push(`/blog/${slug}`)
      selectCategoryBlog(slug)
      setSlugCategory(slug)
      handleSelectedCategoryName(name)
    }
  }

  const handleChangeSearch = (e: any) => {
    handleSearch(e.target.value)
    if (e.target.value) {
      const formatedValue = location.pathname === '/blog' ? e.target.value : `Pesquisa: "${e.target.value}"`
      handleSelectedCategoryName(formatedValue)
    } else {
      handleSelectedCategoryName('')
    }
  }

  return (
    <Styles.Wrapper>
      <Styles.HeaderContainer>
        <Styles.TitleHeader>
          Blog <span> Cannect</span>
        </Styles.TitleHeader>
        <Styles.TextHeader>
          Bem-vindos ao blog da Cannect, a empresa líder em cannabis medicinal. Aqui você encontrará informações sobre
          produtos, aplicações, usos e benefícios da cannabis medicinal. Além disso, nós oferecemos notícias sobre a
          indústria de cannabis medicinal.
          <br />
          <br />
          Nosso objetivo é fornecer aos nossos leitores um recurso confiável para suas necessidades relacionadas à
          cannabis medicinal. Não importa se você é novo no mundo da cannabis, aqui você poderá encontrar o conhecimento
          que precisa. Aproveite sua leitura!
        </Styles.TextHeader>
      </Styles.HeaderContainer>

      <Styles.SearchContainer>
        <div className="input-box">
          <SearchInput width="100%" placeholder="Pesquisar..." onChange={handleChangeSearch} />
        </div>

        <Styles.ButtonsContainers>
          {categories?.data?.map((item: any) => (
            <Button
              key={item.id}
              background="black"
              isOutlined={item.attributes.slug !== slugCategory}
              onClick={() => selectCategory({ slug: item.attributes.slug, name: item.attributes.name })}
            >
              {item.attributes.name}
            </Button>
          ))}
        </Styles.ButtonsContainers>
      </Styles.SearchContainer>
    </Styles.Wrapper>
  )
}
