import React, { useEffect } from 'react'
import LoadingContainer from 'components/LoadingContainer'
import { usePrepareCart } from 'hooks/usePrepareCart'
import PageCartProducts from './pageCartProducts'

function NewCart() {
  const { getLastOrder, loading } = usePrepareCart()

  useEffect(() => {
    getLastOrder()
  }, [])

  if (loading) return <LoadingContainer loading />

  return <PageCartProducts />
}

export default NewCart
