import { theme } from 'design-cannect'
import styled, { css } from 'styled-components'

interface Props {
  isMobile: boolean
  isList: boolean
}

interface ProductListContainerProps {
  tableFormat?: 'list' | 'table'
}

const wrapperModifiers = {
  list: () => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
  table: () => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.9rem;
  `
}

export const Container = styled.div<Props>`
  ${({ isMobile, isList }) => css`
    .title {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: ${isMobile ? 'center' : 'space-between'};
      padding: 0 1rem;
      padding-left: ${isList ? '7.5rem' : '1rem'};
      margin-bottom: ${theme.spacing.space3};
      div {
        div {
          display: flex;
          gap: 1rem;
        }
      }
    }

    .loading {
      position: absolute;
      top: 30%;
      left: 50%;
    }
    #container {
      overflow-x: auto;
    }
  `}
`

export const ProductListContainer = styled.div<ProductListContainerProps>`
  ${({ tableFormat }) => css`
    width: 100% ${!!tableFormat && wrapperModifiers[tableFormat]};
  `}
`
