export const TYPE_OF_BOARD_LIST = [
  {
    label: 'CRM',
    value: 'CRM'
  },
  {
    label: 'CRO',
    value: 'CRO'
  },
  {
    label: 'CREFITO',
    value: 'CREFITO'
  },
  {
    label: 'CRBM',
    value: 'CRBM'
  },
  {
    label: 'CRMV',
    value: 'CRMV'
  },
  {
    label: 'COFITTO',
    value: 'COFITTO'
  },
  {
    label: 'CRN',
    value: 'CRN'
  },
  {
    label: 'CRP',
    value: 'CRP'
  },
  {
    label: 'RMS',
    value: 'RMS'
  },
  {
    label: 'CRF',
    value: 'CRF'
  },
  {
    label: 'COREN',
    value: 'COREN'
  },
  {
    label: 'OUTRO',
    value: 'OTHER'
  }
]

export const FU_LIST = [
  {
    label: 'AC',
    value: 'AC'
  },
  {
    label: 'AL',
    value: 'AL'
  },
  {
    label: 'AP',
    value: 'AP'
  },
  {
    label: 'AM',
    value: 'AM'
  },
  {
    label: 'BA',
    value: 'BA'
  },
  {
    label: 'CE',
    value: 'CE'
  },
  {
    label: 'ES',
    value: 'ES'
  },
  {
    label: 'GO',
    value: 'GO'
  },
  {
    label: 'MA',
    value: 'MA'
  },
  {
    label: 'MT',
    value: 'MT'
  },
  {
    label: 'MS',
    value: 'MS'
  },
  {
    label: 'MG',
    value: 'MG'
  },
  {
    label: 'PA',
    value: 'PA'
  },
  {
    label: 'PB',
    value: 'PB'
  },
  {
    label: 'PR',
    value: 'PR'
  },
  {
    label: 'PE',
    value: 'PE'
  },
  {
    label: 'PI',
    value: 'PI'
  },
  {
    label: 'RJ',
    value: 'RJ'
  },
  {
    label: 'RN',
    value: 'RN'
  },
  {
    label: 'RS',
    value: 'RS'
  },
  {
    label: 'RO',
    value: 'RO'
  },
  {
    label: 'RR',
    value: 'RR'
  },
  {
    label: 'SC',
    value: 'SC'
  },
  {
    label: 'SP',
    value: 'SP'
  },
  {
    label: 'SE',
    value: 'SE'
  },
  {
    label: 'TO',
    value: 'TO'
  },
  {
    label: 'DF',
    value: 'DF'
  }
]

export const BRAZIL_STATES = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' }
]

export const HAS_PRESCRIBED_BEFORE_LIST = [
  {
    label: 'Sim, menos de 10 pacientes',
    value: '1'
  },
  {
    label: 'Sim, entre 10 e 100 pacientes',
    value: '2'
  },
  {
    label: 'Sim, mais de 100 pacientes',
    value: '3'
  },
  {
    label: 'Não, nunca prescrevi',
    value: '4'
  }
]
