import { bgTriagem } from 'assets/img'
import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'

export const Wrapper = styled.main`
  width: 100%;
  padding: 0;
  margin-bottom: -20px;

  h3 strong,
  h4 strong {
    font-weight: 800;
  }

  .font-family-poppins {
    font-family: 'Poppins', sans-serif;
  }

  @media screen and ${MOBILE_WIDTH} {
    bottom: -20px;
  }
`

export const SectionBackground = styled.div`
  width: 100%;
  padding: 0;
  padding-bottom: ${temporaryTheme.spacing.space5};
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(338deg, rgb(223, 255, 251) 42%, rgb(249, 249, 249) 84%);

  @media screen and ${MOBILE_WIDTH} {
    margin-top: -10px;
  }
`

export const Section = styled.div`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH};
  padding: ${temporaryTheme.spacing.space5} ${temporaryTheme.spacing.space4};
`

export const SectionHorizontal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media screen and ${MOBILE_WIDTH} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
`

export const SectionHorizontalContent = styled.div`
  max-width: 50%;
  padding: 25px;

  img {
    width: 100%;
  }

  &.cannect-cuida-video {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-content-head {
    padding: 20px 0;
    line-height: 22px;

    h5 {
      font-size: 15px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }

    span {
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
    }

    &::after {
      content: '';
      margin-top: ${temporaryTheme.spacing.space2};
      display: block;
      background: #7ec8ad;
      width: 100%;
      height: 1px;
    }
  }

  @media screen and ${MOBILE_WIDTH} {
    max-width: 100%;
  }
`

export const SectionHorizontalContentButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const SectionButtonPrimary = styled.button`
  background-color: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  font-size: ${theme.fontSize.md};
  width: 100%;
  height: 35px;
  font-weight: 600;
  border-radius: ${temporaryTheme.border.radius.pill};
`
export const SectionContentButtonSecondary = styled.button`
  background-color: ${theme.colors.white};
  font-size: ${theme.fontSize.md};
  width: 100%;
  margin: 0 ${theme.spacing.space2};
  height: 35px;
  font-weight: 600;
  border-radius: ${temporaryTheme.border.radius.pill};
`

export const SectionHorizontalContentVideo = styled.div`
  width: 412px;
  height: 280px;
  background-color: ${theme.colors.darkGrey};
`

export const SectionContentCard = styled.div`
  width: 100%;
  height: auto;
  margin: 25px;
  padding: 0 ${temporaryTheme.spacing.space4} ${temporaryTheme.spacing.space4} ${temporaryTheme.spacing.space4};
  border-radius: ${temporaryTheme.border.radius.large};
  background: #f8f9fa;
  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.17);
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 440px;

  h5 {
    font-family: 'Poppins', sans-serif;
  }

  @media screen and ${MOBILE_WIDTH} {
    max-width: 480px;
    margin: 25px 0;
  }
`

export const SectionBenefits = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
`

export const SectionBenefitsCards = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  top: -20px;

  @media screen and ${MOBILE_WIDTH} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const SectionBenefitsCardList = styled.div`
  .benefits-list-text {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 15px 0;

    :last-child {
      margin-bottom: 40px;
    }
  }

  .benefits-list-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: ${theme.colors.darkGrey};
    border-radius: 50%;
    margin-right: ${temporaryTheme.spacing.space3};
  }
`

export const SectionQuestion = styled.div`
  width: 100%;
  background-image: url(${bgTriagem});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  padding: 12px 30px;
  /* margin-top: ${temporaryTheme.spacing.space6}; */

  span {
    color: ${theme.colors.darkGrey};
  }
`

export const SectionQuestionColumns = styled.div`
  max-width: 50%;
  padding: ${temporaryTheme.spacing.space3} 0;

  p {
    font-size: 16px;
    padding: 10px 0;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;

    :last-child {
      font-weight: 700;
    }
  }

  img {
    width: 100%;
  }

  button {
    display: flex;
    max-width: 190px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #212529;
    margin: 0;
    margin-top: ${temporaryTheme.spacing.space5};

    img {
      width: auto;
      margin-right: ${temporaryTheme.spacing.space2};
    }
  }
`

export const SectionCollaborators = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    text-align: center;
    padding-top: ${temporaryTheme.spacing.space3};
  }
`

export const SectionCollaboratorsColumns = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  .section-column {
    width: 33%;
    max-width: 385px;

    @media screen and ${MOBILE_WIDTH} {
      width: 100%;
      max-width: 100%;
      text-align: center;

      img {
        max-width: 220px;
      }
    }
  }

  @media screen and ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const SectionReportsCards = styled.div``

export const SectionReportsCard = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  padding: ${temporaryTheme.spacing.space2} 0;
`

export const SectionReportsCardHead = styled.div`
  display: flex;
  align-items: end;
`

export const SectionReportsCardBody = styled.div`
  padding: 0 ${temporaryTheme.spacing.space3};

  .circle-icon {
    max-width: 20px;
    position: relative;
    margin-left: ${temporaryTheme.spacing.space1};
    top: ${temporaryTheme.spacing.space1};
  }

  .danger-text {
    padding-top: ${temporaryTheme.spacing.space2};
  }
`

export const SectionReportsFooter = styled.div`
  display: flex;
  position: relative;
  bottom: 0;
  justify-content: end;
  flex-direction: column;
  text-align: center;
  justify-self: end;
  height: auto;

  &.patient-footer {
    bottom: -78px;
  }

  button {
    margin-top: ${temporaryTheme.spacing.space2};
  }

  @media screen and ${MOBILE_WIDTH} {
    &.patient-footer {
      bottom: 0;
    }
  }
`
