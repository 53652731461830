import { theme } from 'design-cannect'
import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapp = styled.div<any>`
  background: antiquewhite;
  border: 1px solid ${theme.colors.gray};
  border-radius: 10px;
`

export const Container = styled.div<any>`
  width: 100%;
  padding: 1rem;
  height: ${(props) => (props.isMobile ? '500px' : '400px')};
  overflow-y: auto;
  background: linear-gradient(360deg, rgb(255, 255, 255) 31%, transparent);

  .title {
    margin-bottom: ${theme.spacing.space3};
  }
`

export const CheckFilter = styled.div<any>`
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`

export const Wrapper = styled.div<any>`
  width: 100%;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  height: auto;
  display: flex;
  flex-wrap: ${(props) => (!props.isMobile ? 'wrap' : 'nowrap')};
  justify-content: space-around;
  overflow: auto;
  button {
    background: transparent;
    margin-left: 10px;
    margin-top: ${theme.spacing.space1};
    margin-bottom: ${theme.spacing.space1};
    border: none;
  }
  .title-session {
    margin-left: 10px;
    margin-top: ${theme.spacing.space4};
    margin-bottom: ${theme.spacing.space3};
  }
  .sub-session {
    width: 300px;
  }
  #importacao {
    width: 100%;
    .sub-session {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      align-content: center;
      div {
        width: 100%;
      }
    }
  }
  overflow: auto;
  align-content: center;
  align-items: stretch;
`
export const CardPharma = styled.div<any>`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 305px;
  gap: 16px;
  box-shadow: 0px 20px 13px -16px rgba(46, 90, 53, 0.25);
  border-radius: 8px;

  p {
    font-size: 1rem;
  }

  img {
    width: 89px;
    height: 36px;
  }
`

export const CheckBoxWrapper = styled.div`
  position: relative;
  margin-right: 20px;
`

export const CheckBoxLabel = styled.label`
  position: absolute;
  left: calc(50% - 32.5px);
  width: 65px;
  height: 32px;
  border-radius: 15px;
  background: ${theme.colors.white};
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 3px;
    background: ${theme.colors.tertiary};
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${theme.colors.primary};
    &::after {
      content: '';
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-left: 35px;
      transition: 0.2s;
    }
  }
`

export const BadgeCard = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    opacity: ${(props) => (props.isChecked ? '0.5' : '1')};
    margin: 1rem 0;
  }

  & + div {
    margin-left: 16px;
  }

  h2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    opacity: ${(props) => (props.isChecked ? '0.5' : '1')};
    margin-bottom: 10px;
    color: #115850;
  }

  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    width: 130px;
    text-align: center;
    opacity: ${(props) => (props.isChecked ? '0.5' : '1')};
    color: #000000;
  }
`

export const Badges = styled.div`
  display: flex;
  align-items: center;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

interface CheckBoxContainerProps {
  withDescription?: boolean
}

export const CheckBoxContainer = styled.div<CheckBoxContainerProps>`
  ${({ withDescription }) => css`
    min-height: ${!!withDescription && '95px'};
    .checkbox-filter {
      margin: 0 0 10px 0;
    }
    .checkbox-filter > div:first-child {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      border: 1px solid #21976e;
      background-color: #f3f3f3;
      margin-right: 2px;
    }

    @media (max-width: 900px) {
      min-height: ${!!withDescription && '70px'};

      .danger-text {
        font-size: 10px;
      }
    }
  `}
`

export const TextBox = styled.span`
  font-family: 'Spartan', sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: ${temporaryTheme.colors.darkGrey};
`

export const NewBadge = styled.span`
  font-family: 'Spartan', sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: royalblue;
  margin-left: 5px;
  top: -30px;
`
