import React, { ReactNode } from 'react'
import { CSSProperties } from 'styled-components'
import * as Styles from './styles'

export type ButtonStyle = 'default' | 'outlined' | 'ghost'

interface LinkAsButtonProps {
  children: ReactNode
  to: string
  background?: 'default' | 'grey' | 'green'
  buttonStyle?: ButtonStyle
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  style?: CSSProperties
}

const POSSIBLE_BACKGROUNDS = {
  default: '#f0780a',
  grey: '#8F8F8F',
  green: '#21976e'
}

export default function LinkAsButton({
  children,
  to,
  background = 'default',
  buttonStyle = 'default',
  disabled = false,
  onClick,
  style
}: LinkAsButtonProps) {
  const { pathname, search } = document.location
  return (
    <Styles.LinkAsButtonContainer
      to={disabled ? `${pathname}${search}` : to}
      background={POSSIBLE_BACKGROUNDS[background]}
      buttonStyle={buttonStyle}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {children}
    </Styles.LinkAsButtonContainer>
  )
}
